import React, { useRef, useState, useEffect, useContext } from 'react'
import { CourseContext } from '../context/CourseContext';
import CourseCard from '../components/CourseCard';
import { Link } from 'react-router-dom';
import AccessCourse from './AccessCourse';
import CourseCardCompornant from './CourseCardCompornant';
import { CourseCardSkeleton } from './SkeletonLayouts';
import DynamicPagination from '../components/DynamicPagination'

const MyBookmarks = ({ data, loderFilter }) => {

  const { listCourse, currentPage, setCurrentPage, pageSize, setPageSize, getCourses, mainLoder, setMainLoder } = useContext(CourseContext);


  return (
    <>
        <div className="row">


        {
          loderFilter ?

            <>
              <div className="col-12 mb-5"><CourseCardSkeleton /></div>
              <div className="col-12 mb-5"><CourseCardSkeleton /></div>
              <div className="col-12 mb-5"><CourseCardSkeleton /></div>
            </>
            :
            data?.results?.length > 0 && (
              <>
                {data?.results && data?.results?.map((data, index) => (
                  <div key={index} className="col-12 mb-5">

                    {data?.is_accessible ? (
                        <CourseCardCompornant url={`/course-details/${data.course_id}`} data={data} />
                    ) : (
                      <CourseCardCompornant data={data} />
                    )}
                  </div>
                ))}
                {data.total_pages >= 2 &&
                                                <DynamicPagination
                                                    currentPage={currentPage}
                                                    totalPages={data.total_pages}
                                                    onPageChange={(newPage) => {
                                                        setCurrentPage(newPage);
                                                    }}
                                                />
                                            }
              </>
            )}
      </div>
    </>
  )
}

export default MyBookmarks