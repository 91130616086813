// import React from 'react'
import React, { useImperativeHandle, forwardRef, useEffect, useState, useContext } from 'react';
import BusinessSidebar from '../components/BusinessSidebar'
import AdminHeader from '../layout/AdminHeader';
import Footer from '../layout/Footer';
import ProgressBarChart from '../components/ProgressBarChart';
import { Link, useNavigate } from 'react-router-dom';
import AlertSuccess from '../components/assign-course-main/AlertSuccess';
import { CourseContext } from '../context/CourseContext';
import { decryptFromStoreLoc } from '../helper/localStore';

const EmployeeReport = () => {

    const history = useNavigate(); // Get the history object for redirection
    const { setShowSuccess, ShowSuccess, message } = useContext(CourseContext);
    const [user, setUser] = useState({});

    useEffect(() => {
        let timer;
        if (ShowSuccess) {
            timer = setTimeout(() => {
                setShowSuccess(false);
            }, 3000); // Hide after 3 seconds
        }
        return () => clearTimeout(timer); // Clean up the timer
    }, [ShowSuccess]);

    useEffect(() => {

        const decryptedInfoResult = decryptFromStoreLoc('user');

        if (decryptedInfoResult?.role == 6) {
            setUser(decryptedInfoResult)
        } else {
            history("/")
        }

    }, []);


    return (
        <>
            <AdminHeader />
            <section>
                <div className="dashbaord-home-main">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-3 col-md-4 col-lg-3 col-xxl-3 d-none d-xl-block px-4 bg-white sidebar-mian">
                                <BusinessSidebar />
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 col-xxl-9 bg-body">
                                <div className="row ps-0 ps-lg-4 pe-lg-4 ps-xl-5 dashbaord-right-side justify-content-md-center justify-content-lg-between pt-5">
                                    <div className="col-12 col-md-11 col-lg-12 right-side">
                                        <div className="d-md-flex justify-content-between align-items-center gap-3 pt-5 pt-md-0 pb-0 pb-md-5">
                                            <h2 className="text-black fw-600">Employee Report</h2>
                                            <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center gap-3 mt-4 mt-md-0">
                                                <Link
                                                    className="border-primary-btn d-flex align-items-center gap-2 docs-creator"
                                                    to="/bulk-upload"
                                                >
                                                    Download Report
                                                    <svg
                                                        width={24}
                                                        height={24}
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M7.83333 12L12 16.375M12 16.375L16.1667 12M12 16.375V5M7 19H17"
                                                            stroke="#002FA7"
                                                            strokeWidth={2}
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="row mb-4 mb-md-5">
                                            <div className="col-lg-8">
                                                <div className="row justify-content-between p-4 pb-0 rounded-2">
                                                    <div className="col-12">
                                                        <div className="mb-4">
                                                            <p className="fs-18 text-bl fw-600 mb-4">Courses Mandatory Done</p>
                                                            <div className="row align-items-center justify-content-center justify-content-md-between bg-white p-2 p-md-3 rounded-3 mb-4">
                                                                <div className="col-5 col-md-4 col-lg-3 col-xl-2">
                                                                    <div className="d-flex align-items-center gap-4">
                                                                        <img
                                                                            src="./admin-images/png/animation-img.png"
                                                                            className="animation-img"
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-5 col-md-8 col-lg-9 col-xl-10">
                                                                    <div className="d-flex justify-content-between align-items-center px-0 px-lg-3">
                                                                        <div>
                                                                            <p className="mb-0 fs-6 fw-400 text-black">Strategies for Selling Success</p>
                                                                            <p className="mb-0 fs-12 fw-400 text-muted">
                                                                                12 Mini Chapters
                                                                            </p>
                                                                            <p className="mb-0 fs-12 fw-400 text-muted">
                                                                                Due Date - Apr 10
                                                                            </p>
                                                                        </div>
                                                                        <Link to="" className="docs-creator">
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width={5}
                                                                                height={17}
                                                                                viewBox="0 0 5 17"
                                                                                fill="none"
                                                                            >
                                                                                <path
                                                                                    d="M2.17341 3.85287C2.57546 3.85287 2.96848 3.74427 3.30277 3.5408C3.63706 3.33733 3.8976 3.04813 4.05146 2.70977C4.20532 2.37141 4.24557 1.99909 4.16714 1.63989C4.0887 1.28069 3.8951 0.950743 3.61081 0.691774C3.32652 0.432805 2.96431 0.256445 2.56999 0.184995C2.17567 0.113546 1.76694 0.150216 1.3955 0.290369C1.02406 0.430522 0.706578 0.667863 0.483212 0.972378C0.259847 1.27689 0.140626 1.63491 0.140626 2.00114C0.140626 2.49225 0.354794 2.96325 0.736016 3.31051C1.11724 3.65778 1.63428 3.85287 2.17341 3.85287ZM2.17341 13.1115C1.77136 13.1115 1.37835 13.2201 1.04406 13.4236C0.709767 13.6271 0.449219 13.9163 0.295363 14.2546C0.141506 14.593 0.10125 14.9653 0.179685 15.3245C0.258121 15.6837 0.451725 16.0136 0.736016 16.2726C1.02031 16.5316 1.38251 16.7079 1.77683 16.7794C2.17116 16.8508 2.57988 16.8142 2.95132 16.674C3.32277 16.5339 3.64025 16.2965 3.86361 15.992C4.08698 15.6875 4.2062 15.3295 4.2062 14.9632C4.2062 14.4721 3.99203 14.0011 3.61081 13.6539C3.22959 13.3066 2.71254 13.1115 2.17341 13.1115ZM2.17341 6.63047C1.77136 6.63047 1.37835 6.73907 1.04406 6.94254C0.709767 7.14601 0.449219 7.43521 0.295363 7.77357C0.141506 8.11193 0.10125 8.48425 0.179685 8.84345C0.258121 9.20265 0.451725 9.5326 0.736016 9.79157C1.02031 10.0505 1.38251 10.2269 1.77683 10.2983C2.17116 10.3698 2.57988 10.3331 2.95132 10.193C3.32277 10.0528 3.64025 9.81548 3.86361 9.51096C4.08698 9.20645 4.2062 8.84843 4.2062 8.4822C4.2062 7.99109 3.99203 7.52009 3.61081 7.17283C3.22959 6.82556 2.71254 6.63047 2.17341 6.63047Z"
                                                                                    fill="#646C78"
                                                                                />
                                                                            </svg>
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row align-items-center justify-content-center justify-content-md-between bg-white  p-2 p-md-3 rounded-3 mb-4">
                                                                <div className="col-5 col-md-4 col-lg-3 col-xl-2">
                                                                    <div className="d-flex align-items-center gap-4">
                                                                        <img
                                                                            src="./admin-images/png/animation-img.png"
                                                                            className="animation-img"
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-5 col-md-8 col-lg-9 col-xl-10">
                                                                    <div className="d-flex justify-content-between align-items-center px-0 px-lg-3">
                                                                        <div>
                                                                            <p className="mb-0 fs-6 fw-400 text-black">Strategies for Selling Success</p>
                                                                            <p className="mb-0 fs-12 fw-400 text-muted">
                                                                                12 Mini Chapters
                                                                            </p>
                                                                            <p className="mb-0 fs-12 fw-400 text-muted">
                                                                                Due Date - Apr 10
                                                                            </p>
                                                                        </div>
                                                                        <Link to="" className="docs-creator">
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width={5}
                                                                                height={17}
                                                                                viewBox="0 0 5 17"
                                                                                fill="none"
                                                                            >
                                                                                <path
                                                                                    d="M2.17341 3.85287C2.57546 3.85287 2.96848 3.74427 3.30277 3.5408C3.63706 3.33733 3.8976 3.04813 4.05146 2.70977C4.20532 2.37141 4.24557 1.99909 4.16714 1.63989C4.0887 1.28069 3.8951 0.950743 3.61081 0.691774C3.32652 0.432805 2.96431 0.256445 2.56999 0.184995C2.17567 0.113546 1.76694 0.150216 1.3955 0.290369C1.02406 0.430522 0.706578 0.667863 0.483212 0.972378C0.259847 1.27689 0.140626 1.63491 0.140626 2.00114C0.140626 2.49225 0.354794 2.96325 0.736016 3.31051C1.11724 3.65778 1.63428 3.85287 2.17341 3.85287ZM2.17341 13.1115C1.77136 13.1115 1.37835 13.2201 1.04406 13.4236C0.709767 13.6271 0.449219 13.9163 0.295363 14.2546C0.141506 14.593 0.10125 14.9653 0.179685 15.3245C0.258121 15.6837 0.451725 16.0136 0.736016 16.2726C1.02031 16.5316 1.38251 16.7079 1.77683 16.7794C2.17116 16.8508 2.57988 16.8142 2.95132 16.674C3.32277 16.5339 3.64025 16.2965 3.86361 15.992C4.08698 15.6875 4.2062 15.3295 4.2062 14.9632C4.2062 14.4721 3.99203 14.0011 3.61081 13.6539C3.22959 13.3066 2.71254 13.1115 2.17341 13.1115ZM2.17341 6.63047C1.77136 6.63047 1.37835 6.73907 1.04406 6.94254C0.709767 7.14601 0.449219 7.43521 0.295363 7.77357C0.141506 8.11193 0.10125 8.48425 0.179685 8.84345C0.258121 9.20265 0.451725 9.5326 0.736016 9.79157C1.02031 10.0505 1.38251 10.2269 1.77683 10.2983C2.17116 10.3698 2.57988 10.3331 2.95132 10.193C3.32277 10.0528 3.64025 9.81548 3.86361 9.51096C4.08698 9.20645 4.2062 8.84843 4.2062 8.4822C4.2062 7.99109 3.99203 7.52009 3.61081 7.17283C3.22959 6.82556 2.71254 6.63047 2.17341 6.63047Z"
                                                                                    fill="#646C78"
                                                                                />
                                                                            </svg>
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row align-items-center justify-content-center justify-content-md-between bg-white  p-2 p-md-3 rounded-3 mb-4">
                                                                <div className="col-5 col-md-4 col-lg-3 col-xl-2">
                                                                    <div className="d-flex align-items-center gap-4">
                                                                        <img
                                                                            src="./admin-images/png/animation-img.png"
                                                                            className="animation-img"
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-5 col-md-8 col-lg-9 col-xl-10">
                                                                    <div className="d-flex justify-content-between align-items-center px-0 px-lg-3">
                                                                        <div>
                                                                            <p className="mb-0 fs-6 fw-400 text-black">Strategies for Selling Success</p>
                                                                            <p className="mb-0 fs-12 fw-400 text-muted">
                                                                                12 Mini Chapters
                                                                            </p>
                                                                            <p className="mb-0 fs-12 fw-400 text-muted">
                                                                                Due Date - Apr 10
                                                                            </p>
                                                                        </div>
                                                                        <Link to="" className="docs-creator">
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width={5}
                                                                                height={17}
                                                                                viewBox="0 0 5 17"
                                                                                fill="none"
                                                                            >
                                                                                <path
                                                                                    d="M2.17341 3.85287C2.57546 3.85287 2.96848 3.74427 3.30277 3.5408C3.63706 3.33733 3.8976 3.04813 4.05146 2.70977C4.20532 2.37141 4.24557 1.99909 4.16714 1.63989C4.0887 1.28069 3.8951 0.950743 3.61081 0.691774C3.32652 0.432805 2.96431 0.256445 2.56999 0.184995C2.17567 0.113546 1.76694 0.150216 1.3955 0.290369C1.02406 0.430522 0.706578 0.667863 0.483212 0.972378C0.259847 1.27689 0.140626 1.63491 0.140626 2.00114C0.140626 2.49225 0.354794 2.96325 0.736016 3.31051C1.11724 3.65778 1.63428 3.85287 2.17341 3.85287ZM2.17341 13.1115C1.77136 13.1115 1.37835 13.2201 1.04406 13.4236C0.709767 13.6271 0.449219 13.9163 0.295363 14.2546C0.141506 14.593 0.10125 14.9653 0.179685 15.3245C0.258121 15.6837 0.451725 16.0136 0.736016 16.2726C1.02031 16.5316 1.38251 16.7079 1.77683 16.7794C2.17116 16.8508 2.57988 16.8142 2.95132 16.674C3.32277 16.5339 3.64025 16.2965 3.86361 15.992C4.08698 15.6875 4.2062 15.3295 4.2062 14.9632C4.2062 14.4721 3.99203 14.0011 3.61081 13.6539C3.22959 13.3066 2.71254 13.1115 2.17341 13.1115ZM2.17341 6.63047C1.77136 6.63047 1.37835 6.73907 1.04406 6.94254C0.709767 7.14601 0.449219 7.43521 0.295363 7.77357C0.141506 8.11193 0.10125 8.48425 0.179685 8.84345C0.258121 9.20265 0.451725 9.5326 0.736016 9.79157C1.02031 10.0505 1.38251 10.2269 1.77683 10.2983C2.17116 10.3698 2.57988 10.3331 2.95132 10.193C3.32277 10.0528 3.64025 9.81548 3.86361 9.51096C4.08698 9.20645 4.2062 8.84843 4.2062 8.4822C4.2062 7.99109 3.99203 7.52009 3.61081 7.17283C3.22959 6.82556 2.71254 6.63047 2.17341 6.63047Z"
                                                                                    fill="#646C78"
                                                                                />
                                                                            </svg>
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row align-items-center justify-content-center justify-content-md-between bg-white  p-2 p-md-3 rounded-3 mb-4">
                                                                <div className="col-5 col-md-4 col-lg-3 col-xl-2">
                                                                    <div className="d-flex align-items-center gap-4">
                                                                        <img
                                                                            src="./admin-images/png/animation-img.png"
                                                                            className="animation-img"
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-5 col-md-8 col-lg-9 col-xl-10">
                                                                    <div className="d-flex justify-content-between align-items-center px-0 px-lg-3">
                                                                        <div>
                                                                            <p className="mb-0 fs-6 fw-400 text-black">Strategies for Selling Success</p>
                                                                            <p className="mb-0 fs-12 fw-400 text-muted">
                                                                                12 Mini Chapters
                                                                            </p>
                                                                            <p className="mb-0 fs-12 fw-400 text-muted">
                                                                                Due Date - Apr 10
                                                                            </p>
                                                                        </div>
                                                                        <Link to="" className="docs-creator">
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width={5}
                                                                                height={17}
                                                                                viewBox="0 0 5 17"
                                                                                fill="none"
                                                                            >
                                                                                <path
                                                                                    d="M2.17341 3.85287C2.57546 3.85287 2.96848 3.74427 3.30277 3.5408C3.63706 3.33733 3.8976 3.04813 4.05146 2.70977C4.20532 2.37141 4.24557 1.99909 4.16714 1.63989C4.0887 1.28069 3.8951 0.950743 3.61081 0.691774C3.32652 0.432805 2.96431 0.256445 2.56999 0.184995C2.17567 0.113546 1.76694 0.150216 1.3955 0.290369C1.02406 0.430522 0.706578 0.667863 0.483212 0.972378C0.259847 1.27689 0.140626 1.63491 0.140626 2.00114C0.140626 2.49225 0.354794 2.96325 0.736016 3.31051C1.11724 3.65778 1.63428 3.85287 2.17341 3.85287ZM2.17341 13.1115C1.77136 13.1115 1.37835 13.2201 1.04406 13.4236C0.709767 13.6271 0.449219 13.9163 0.295363 14.2546C0.141506 14.593 0.10125 14.9653 0.179685 15.3245C0.258121 15.6837 0.451725 16.0136 0.736016 16.2726C1.02031 16.5316 1.38251 16.7079 1.77683 16.7794C2.17116 16.8508 2.57988 16.8142 2.95132 16.674C3.32277 16.5339 3.64025 16.2965 3.86361 15.992C4.08698 15.6875 4.2062 15.3295 4.2062 14.9632C4.2062 14.4721 3.99203 14.0011 3.61081 13.6539C3.22959 13.3066 2.71254 13.1115 2.17341 13.1115ZM2.17341 6.63047C1.77136 6.63047 1.37835 6.73907 1.04406 6.94254C0.709767 7.14601 0.449219 7.43521 0.295363 7.77357C0.141506 8.11193 0.10125 8.48425 0.179685 8.84345C0.258121 9.20265 0.451725 9.5326 0.736016 9.79157C1.02031 10.0505 1.38251 10.2269 1.77683 10.2983C2.17116 10.3698 2.57988 10.3331 2.95132 10.193C3.32277 10.0528 3.64025 9.81548 3.86361 9.51096C4.08698 9.20645 4.2062 8.84843 4.2062 8.4822C4.2062 7.99109 3.99203 7.52009 3.61081 7.17283C3.22959 6.82556 2.71254 6.63047 2.17341 6.63047Z"
                                                                                    fill="#646C78"
                                                                                />
                                                                            </svg>
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='d-flex justify-content-center'>
                                                            <button className='bg-transparent border-0 text-bl fw-600 fs-18'>View All</button>
                                                        </div>
                                                        { }
                                                    </div>
                                                </div>
                                                <div className="row justify-content-between p-4 pb-0 rounded-2">
                                                    <div className="col-12">
                                                        <div className="mb-4">
                                                            <p className="fs-18 text-bl fw-600 mb-4">Courses Voluntary Done</p>
                                                            <div className="row align-items-center justify-content-center justify-content-md-between bg-white p-2 p-md-3 rounded-3 mb-4">
                                                                <div className="col-5 col-md-4 col-lg-3 col-xl-2">
                                                                    <div className="d-flex align-items-center gap-4">
                                                                        <img
                                                                            src="./admin-images/png/animation-img.png"
                                                                            className="animation-img"
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-5 col-md-8 col-lg-9 col-xl-10">
                                                                    <div className="d-flex justify-content-between align-items-center px-0 px-lg-3">
                                                                        <div>
                                                                            <p className="mb-0 fs-6 fw-400 text-black">Strategies for Selling Success</p>
                                                                            <p className="mb-0 fs-12 fw-400 text-muted">
                                                                                12 Mini Chapters
                                                                            </p>
                                                                            <p className="mb-0 fs-12 fw-400 text-muted">
                                                                                Due Date - Apr 10
                                                                            </p>
                                                                        </div>
                                                                        <Link to="" className="docs-creator">
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width={5}
                                                                                height={17}
                                                                                viewBox="0 0 5 17"
                                                                                fill="none"
                                                                            >
                                                                                <path
                                                                                    d="M2.17341 3.85287C2.57546 3.85287 2.96848 3.74427 3.30277 3.5408C3.63706 3.33733 3.8976 3.04813 4.05146 2.70977C4.20532 2.37141 4.24557 1.99909 4.16714 1.63989C4.0887 1.28069 3.8951 0.950743 3.61081 0.691774C3.32652 0.432805 2.96431 0.256445 2.56999 0.184995C2.17567 0.113546 1.76694 0.150216 1.3955 0.290369C1.02406 0.430522 0.706578 0.667863 0.483212 0.972378C0.259847 1.27689 0.140626 1.63491 0.140626 2.00114C0.140626 2.49225 0.354794 2.96325 0.736016 3.31051C1.11724 3.65778 1.63428 3.85287 2.17341 3.85287ZM2.17341 13.1115C1.77136 13.1115 1.37835 13.2201 1.04406 13.4236C0.709767 13.6271 0.449219 13.9163 0.295363 14.2546C0.141506 14.593 0.10125 14.9653 0.179685 15.3245C0.258121 15.6837 0.451725 16.0136 0.736016 16.2726C1.02031 16.5316 1.38251 16.7079 1.77683 16.7794C2.17116 16.8508 2.57988 16.8142 2.95132 16.674C3.32277 16.5339 3.64025 16.2965 3.86361 15.992C4.08698 15.6875 4.2062 15.3295 4.2062 14.9632C4.2062 14.4721 3.99203 14.0011 3.61081 13.6539C3.22959 13.3066 2.71254 13.1115 2.17341 13.1115ZM2.17341 6.63047C1.77136 6.63047 1.37835 6.73907 1.04406 6.94254C0.709767 7.14601 0.449219 7.43521 0.295363 7.77357C0.141506 8.11193 0.10125 8.48425 0.179685 8.84345C0.258121 9.20265 0.451725 9.5326 0.736016 9.79157C1.02031 10.0505 1.38251 10.2269 1.77683 10.2983C2.17116 10.3698 2.57988 10.3331 2.95132 10.193C3.32277 10.0528 3.64025 9.81548 3.86361 9.51096C4.08698 9.20645 4.2062 8.84843 4.2062 8.4822C4.2062 7.99109 3.99203 7.52009 3.61081 7.17283C3.22959 6.82556 2.71254 6.63047 2.17341 6.63047Z"
                                                                                    fill="#646C78"
                                                                                />
                                                                            </svg>
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row align-items-center justify-content-center justify-content-md-between bg-white  p-2 p-md-3 rounded-3 mb-4">
                                                                <div className="col-5 col-md-4 col-lg-3 col-xl-2">
                                                                    <div className="d-flex align-items-center gap-4">
                                                                        <img
                                                                            src="./admin-images/png/animation-img.png"
                                                                            className="animation-img"
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-5 col-md-8 col-lg-9 col-xl-10">
                                                                    <div className="d-flex justify-content-between align-items-center px-0 px-lg-3">
                                                                        <div>
                                                                            <p className="mb-0 fs-6 fw-400 text-black">Strategies for Selling Success</p>
                                                                            <p className="mb-0 fs-12 fw-400 text-muted">
                                                                                12 Mini Chapters
                                                                            </p>
                                                                            <p className="mb-0 fs-12 fw-400 text-muted">
                                                                                Due Date - Apr 10
                                                                            </p>
                                                                        </div>
                                                                        <Link to="" className="docs-creator">
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width={5}
                                                                                height={17}
                                                                                viewBox="0 0 5 17"
                                                                                fill="none"
                                                                            >
                                                                                <path
                                                                                    d="M2.17341 3.85287C2.57546 3.85287 2.96848 3.74427 3.30277 3.5408C3.63706 3.33733 3.8976 3.04813 4.05146 2.70977C4.20532 2.37141 4.24557 1.99909 4.16714 1.63989C4.0887 1.28069 3.8951 0.950743 3.61081 0.691774C3.32652 0.432805 2.96431 0.256445 2.56999 0.184995C2.17567 0.113546 1.76694 0.150216 1.3955 0.290369C1.02406 0.430522 0.706578 0.667863 0.483212 0.972378C0.259847 1.27689 0.140626 1.63491 0.140626 2.00114C0.140626 2.49225 0.354794 2.96325 0.736016 3.31051C1.11724 3.65778 1.63428 3.85287 2.17341 3.85287ZM2.17341 13.1115C1.77136 13.1115 1.37835 13.2201 1.04406 13.4236C0.709767 13.6271 0.449219 13.9163 0.295363 14.2546C0.141506 14.593 0.10125 14.9653 0.179685 15.3245C0.258121 15.6837 0.451725 16.0136 0.736016 16.2726C1.02031 16.5316 1.38251 16.7079 1.77683 16.7794C2.17116 16.8508 2.57988 16.8142 2.95132 16.674C3.32277 16.5339 3.64025 16.2965 3.86361 15.992C4.08698 15.6875 4.2062 15.3295 4.2062 14.9632C4.2062 14.4721 3.99203 14.0011 3.61081 13.6539C3.22959 13.3066 2.71254 13.1115 2.17341 13.1115ZM2.17341 6.63047C1.77136 6.63047 1.37835 6.73907 1.04406 6.94254C0.709767 7.14601 0.449219 7.43521 0.295363 7.77357C0.141506 8.11193 0.10125 8.48425 0.179685 8.84345C0.258121 9.20265 0.451725 9.5326 0.736016 9.79157C1.02031 10.0505 1.38251 10.2269 1.77683 10.2983C2.17116 10.3698 2.57988 10.3331 2.95132 10.193C3.32277 10.0528 3.64025 9.81548 3.86361 9.51096C4.08698 9.20645 4.2062 8.84843 4.2062 8.4822C4.2062 7.99109 3.99203 7.52009 3.61081 7.17283C3.22959 6.82556 2.71254 6.63047 2.17341 6.63047Z"
                                                                                    fill="#646C78"
                                                                                />
                                                                            </svg>
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row align-items-center justify-content-center justify-content-md-between bg-white  p-2 p-md-3 rounded-3 mb-4">
                                                                <div className="col-5 col-md-4 col-lg-3 col-xl-2">
                                                                    <div className="d-flex align-items-center gap-4">
                                                                        <img
                                                                            src="./admin-images/png/animation-img.png"
                                                                            className="animation-img"
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-5 col-md-8 col-lg-9 col-xl-10">
                                                                    <div className="d-flex justify-content-between align-items-center px-0 px-lg-3">
                                                                        <div>
                                                                            <p className="mb-0 fs-6 fw-400 text-black">Strategies for Selling Success</p>
                                                                            <p className="mb-0 fs-12 fw-400 text-muted">
                                                                                12 Mini Chapters
                                                                            </p>
                                                                            <p className="mb-0 fs-12 fw-400 text-muted">
                                                                                Due Date - Apr 10
                                                                            </p>
                                                                        </div>
                                                                        <Link to="" className="docs-creator">
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width={5}
                                                                                height={17}
                                                                                viewBox="0 0 5 17"
                                                                                fill="none"
                                                                            >
                                                                                <path
                                                                                    d="M2.17341 3.85287C2.57546 3.85287 2.96848 3.74427 3.30277 3.5408C3.63706 3.33733 3.8976 3.04813 4.05146 2.70977C4.20532 2.37141 4.24557 1.99909 4.16714 1.63989C4.0887 1.28069 3.8951 0.950743 3.61081 0.691774C3.32652 0.432805 2.96431 0.256445 2.56999 0.184995C2.17567 0.113546 1.76694 0.150216 1.3955 0.290369C1.02406 0.430522 0.706578 0.667863 0.483212 0.972378C0.259847 1.27689 0.140626 1.63491 0.140626 2.00114C0.140626 2.49225 0.354794 2.96325 0.736016 3.31051C1.11724 3.65778 1.63428 3.85287 2.17341 3.85287ZM2.17341 13.1115C1.77136 13.1115 1.37835 13.2201 1.04406 13.4236C0.709767 13.6271 0.449219 13.9163 0.295363 14.2546C0.141506 14.593 0.10125 14.9653 0.179685 15.3245C0.258121 15.6837 0.451725 16.0136 0.736016 16.2726C1.02031 16.5316 1.38251 16.7079 1.77683 16.7794C2.17116 16.8508 2.57988 16.8142 2.95132 16.674C3.32277 16.5339 3.64025 16.2965 3.86361 15.992C4.08698 15.6875 4.2062 15.3295 4.2062 14.9632C4.2062 14.4721 3.99203 14.0011 3.61081 13.6539C3.22959 13.3066 2.71254 13.1115 2.17341 13.1115ZM2.17341 6.63047C1.77136 6.63047 1.37835 6.73907 1.04406 6.94254C0.709767 7.14601 0.449219 7.43521 0.295363 7.77357C0.141506 8.11193 0.10125 8.48425 0.179685 8.84345C0.258121 9.20265 0.451725 9.5326 0.736016 9.79157C1.02031 10.0505 1.38251 10.2269 1.77683 10.2983C2.17116 10.3698 2.57988 10.3331 2.95132 10.193C3.32277 10.0528 3.64025 9.81548 3.86361 9.51096C4.08698 9.20645 4.2062 8.84843 4.2062 8.4822C4.2062 7.99109 3.99203 7.52009 3.61081 7.17283C3.22959 6.82556 2.71254 6.63047 2.17341 6.63047Z"
                                                                                    fill="#646C78"
                                                                                />
                                                                            </svg>
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row align-items-center justify-content-center justify-content-md-between bg-white  p-2 p-md-3 rounded-3 mb-4">
                                                                <div className="col-5 col-md-4 col-lg-3 col-xl-2">
                                                                    <div className="d-flex align-items-center gap-4">
                                                                        <img
                                                                            src="./admin-images/png/animation-img.png"
                                                                            className="animation-img"
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-5 col-md-8 col-lg-9 col-xl-10">
                                                                    <div className="d-flex justify-content-between align-items-center px-0 px-lg-3">
                                                                        <div>
                                                                            <p className="mb-0 fs-6 fw-400 text-black">Strategies for Selling Success</p>
                                                                            <p className="mb-0 fs-12 fw-400 text-muted">
                                                                                12 Mini Chapters
                                                                            </p>
                                                                            <p className="mb-0 fs-12 fw-400 text-muted">
                                                                                Due Date - Apr 10
                                                                            </p>
                                                                        </div>
                                                                        <Link to="" className="docs-creator">
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width={5}
                                                                                height={17}
                                                                                viewBox="0 0 5 17"
                                                                                fill="none"
                                                                            >
                                                                                <path
                                                                                    d="M2.17341 3.85287C2.57546 3.85287 2.96848 3.74427 3.30277 3.5408C3.63706 3.33733 3.8976 3.04813 4.05146 2.70977C4.20532 2.37141 4.24557 1.99909 4.16714 1.63989C4.0887 1.28069 3.8951 0.950743 3.61081 0.691774C3.32652 0.432805 2.96431 0.256445 2.56999 0.184995C2.17567 0.113546 1.76694 0.150216 1.3955 0.290369C1.02406 0.430522 0.706578 0.667863 0.483212 0.972378C0.259847 1.27689 0.140626 1.63491 0.140626 2.00114C0.140626 2.49225 0.354794 2.96325 0.736016 3.31051C1.11724 3.65778 1.63428 3.85287 2.17341 3.85287ZM2.17341 13.1115C1.77136 13.1115 1.37835 13.2201 1.04406 13.4236C0.709767 13.6271 0.449219 13.9163 0.295363 14.2546C0.141506 14.593 0.10125 14.9653 0.179685 15.3245C0.258121 15.6837 0.451725 16.0136 0.736016 16.2726C1.02031 16.5316 1.38251 16.7079 1.77683 16.7794C2.17116 16.8508 2.57988 16.8142 2.95132 16.674C3.32277 16.5339 3.64025 16.2965 3.86361 15.992C4.08698 15.6875 4.2062 15.3295 4.2062 14.9632C4.2062 14.4721 3.99203 14.0011 3.61081 13.6539C3.22959 13.3066 2.71254 13.1115 2.17341 13.1115ZM2.17341 6.63047C1.77136 6.63047 1.37835 6.73907 1.04406 6.94254C0.709767 7.14601 0.449219 7.43521 0.295363 7.77357C0.141506 8.11193 0.10125 8.48425 0.179685 8.84345C0.258121 9.20265 0.451725 9.5326 0.736016 9.79157C1.02031 10.0505 1.38251 10.2269 1.77683 10.2983C2.17116 10.3698 2.57988 10.3331 2.95132 10.193C3.32277 10.0528 3.64025 9.81548 3.86361 9.51096C4.08698 9.20645 4.2062 8.84843 4.2062 8.4822C4.2062 7.99109 3.99203 7.52009 3.61081 7.17283C3.22959 6.82556 2.71254 6.63047 2.17341 6.63047Z"
                                                                                    fill="#646C78"
                                                                                />
                                                                            </svg>
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='d-flex justify-content-center'>
                                                            <button className='bg-transparent border-0 text-bl fw-600 fs-18'>View All</button>
                                                        </div>
                                                        { }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="row row-cols-1 row-cols-md-2 row-cols-lg-1 g-4 pt-md-5">
                                                    <div className="col pt-4 pt-md-0 pt-lg-4">
                                                        <div className="card border-0 h-100">
                                                            <div className="card-body p-4 pb-0">
                                                                <p className="fs-16 fw-600 text-black">
                                                                    Courses Mandatory Done
                                                                </p>
                                                            </div>
                                                            <div className="card-footer bg-transparent border-0 pt-0 px-4">
                                                                <h2 className="fw-600 text-black">20</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="card border-0 h-100">
                                                            <div className="card-body p-4 pb-0">
                                                                <p className="fs-16 fw-600 text-black">
                                                                    Courses Voluntary Done
                                                                </p>
                                                            </div>
                                                            <div className="card-footer bg-transparent border-0 pt-0 px-4">
                                                                <h2 className="fw-600 text-black">15</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="card border-0 h-100">
                                                            <div className="card-body p-4 pb-0">
                                                                <p className="fs-16 fw-600 text-black">Completion Rate</p>
                                                            </div>
                                                            <div className="card-footer bg-transparent border-0 pt-0 px-4">
                                                                <h2 className="fw-600 text-black">90%</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="card border-0 h-100">
                                                            <div className="card-body p-4 pb-0">
                                                                <p className="fs-16 fw-600 text-black">Compilance Rate</p>
                                                            </div>
                                                            <div className="card-footer bg-transparent border-0 pt-0 px-4">
                                                                <h2 className="fw-600 text-black">90%</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            <Footer />
        </>
    )
}

export default EmployeeReport
