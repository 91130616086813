import { useState, useRef, useEffect } from "react";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import {
    Card,
    Col,
    Overlay,
    OverlayTrigger,
    Popover,
    Row,
    Tooltip,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { decryptFromStoreLoc } from "../../helper/localStore";
import { toast } from "react-toastify";

function FamilyUserPlans() {
    const navigate = useNavigate();
    const [user, setUser] = useState({});

    const trailsData = [
        {
            id: 1,
            title: "Data Privacy",
            description: "How to keep personal data safe",
            imageSrc: "/png/trails-1.png",
        },
        {
            id: 2,
            title: "Information Security",
            description: "How to keep company information safe",
            imageSrc: "/png/trails-2.png",
        },
        {
            id: 3,
            title: "Compliance",
            description: "How to stay compliant as a company",
            imageSrc: "/png/trails-3.png",
        },
        {
            id: 4,
            title: "Sustainability",
            description: "How to stay sustainable as a company",
            imageSrc: "/png/trails-4.png",
        },
        {
            id: 5,
            title: "What should we do",
            description: "What should we do",
            imageSrc: "/png/trails-5.png",
        },
        {
            id: 6,
            title: "Mental health",
            description: "How to stay sane",
            imageSrc: "/png/trails-6.png",
        },
        {
            id: 7,
            title: "Diversity",
            description: "How to have a diverse work force",
            imageSrc: "/png/trails-7.png",
        },
        {
            id: 8,
            title: "Communication",
            description: "How to communicate well",
            imageSrc: "/png/trails-8.png",
        },
        {
            id: 9,
            title: "Happiness",
            description: "How to be happy",
            imageSrc: "/png/trails-9.png",
        },
        {
            id: 10,
            title: "Health",
            description: "How to live a healthy life",
            imageSrc: "/png/trails-10.png",
        },
        {
            id: 11,
            title: "Time Management",
            description: "How to work effectively",
            imageSrc: "/png/trails-11.png",
        },
        {
            id: 12,
            title: "Wealth",
            description: "How to be wealthy",
            imageSrc: "/png/trails-12.png",
        },
        {
            id: 13,
            title: "Career",
            description: "How to progress in your job",
            imageSrc: "/png/trails-13.png",
        },
        {
            id: 14,
            title: "Confidence",
            description: "How to be confident",
            imageSrc: "/png/trails-14.png",
        },
        {
            id: 15,
            title: "Food",
            description: "How to have healthy eating habits",
            imageSrc: "/png/trails-15.png",
        },
        {
            id: 16,
            title: "Social Media",
            description: "How to master social media safely",
            imageSrc: "/png/trails-16.png",
        },
        // Add more items as needed
    ];

    const employeesPricing = [
        { min: 0, max: 50, price: 72 },
        { min: 51, max: 100, price: 69 },
        { min: 101, max: 500, price: 66 },
        { min: 501, max: 1000, price: 63 },
        { min: 1001, max: 2000, price: 60 },
        { min: 2001, max: 3000, price: 56 },
        { min: 3001, max: 5000, price: 52 },
        { min: 5001, max: Infinity, price: 48 },
    ];

    useEffect(() => {
        const decryptedInfoResult = decryptFromStoreLoc("user");
        setUser(decryptedInfoResult);
        localStorage.removeItem("state");
    }, []);

    const handleNavigate = (data) => {
        if (user?.role === 13) {
            if (data === "one") {
                navigate("/course-trails", {
                    state: {
                        name: "one",
                        plan: "Tier One- One trail",
                        userPlan: {
                            employee: 0,
                            perEmployeePrice: 0,
                            subscriptionAmount: 0,
                        },
                    },
                });
            } else if (data === "two") {
                navigate("/course-trails", {
                    state: {
                        name: "two",
                        plan: "Tier Two- Two Trails",
                        userPlan: {
                            employee: 0,
                            perEmployeePrice: 12,
                        },
                    },
                });
            } else {
                navigate("/family-blended-learning", {
                    state: {
                        name: "three",
                        plan: "Tier Three-Gold - All trails",
                        userPlan: {
                            employee: 0,
                            perEmployeePrice: 72,
                        },
                        courseTrails: [],
                    },
                });
            }
        }
    };

    return (
        <>
            <Header />
            <section className="payment-flow-section">
                <div className="container">
                    <h3 className="welcome-title max-width-100">
                        Choose Your Subscription Plan
                    </h3>
                    {user?.role === 13 && (
                        <p className="welcome-description plan-description">
                            Select a plan that fits your business needs. Start
                            with a free trial, then choose a subscription that
                            scales with your team. You will be billed yearly for
                            your selected plan.
                        </p>
                    )}
                    <div className="row plans-card g-4">
                        <div className="col-md-6 col-lg-6 col-xl-4">
                            <div className="card border-0 h-100">
                                <div className="card-body p-0">
                                    <h3>Tier One- One trail</h3>
                                    <p>
                                        Single
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip id="tooltip-top">
                                                    Trails are life long
                                                    educational courses with
                                                    fun. A trail is a
                                                    combination of bronze
                                                    courses and case studies for
                                                    one topic. It will give you
                                                    an overview about one topic
                                                    (e.g. data privacy) and then
                                                    you will receive further
                                                    courses on this topic for
                                                    you whole life time until
                                                    you de-register from
                                                    unicorso. It is life long
                                                    education with fun.
                                                </Tooltip>
                                            }
                                        >
                                            <span className="tooltip-hover px-1 fw-600">
                                                trail
                                            </span>
                                        </OverlayTrigger>
                                        with the basic Data Privacy and
                                        Compliance courses
                                    </p>
                                    <h2>Free</h2>
                                </div>
                                <div className="card-footer bg-transparent border-0 p-0 d-flex justify-content-center">
                                    <button
                                        className="w-auto text-decoration-none"
                                        onClick={() => handleNavigate("one")}
                                    >
                                        Get Started
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-4">
                            <div className="card border-0 h-100">
                                <div className="card-body p-0">
                                    <h3>Tier Two- Two Trails</h3>
                                    <p>
                                        Several
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip id="tooltip-top">
                                                    Trails are life long
                                                    educational courses with
                                                    fun. A trail is a
                                                    combination of bronze
                                                    courses and case studies for
                                                    one topic. It will give you
                                                    an overview about one topic
                                                    (e.g. data privacy) and then
                                                    you will receive further
                                                    courses on this topic for
                                                    you whole life time until
                                                    you de-register from
                                                    unicorso. It is life long
                                                    education with fun.
                                                </Tooltip>
                                            }
                                        >
                                            <span className="tooltip-hover px-1 fw-600">
                                                trails
                                            </span>
                                        </OverlayTrigger>
                                        with basic courses on Compliance, Data
                                        Privacy and Information Security
                                    </p>
                                    {user?.role === 13 && <h2>€12</h2>}
                                    <p className="employee mb-0 h-auto">
                                        per year
                                    </p>
                                </div>
                                <div className="card-footer bg-transparent border-0 p-0">
                                    <div className="card-footer bg-transparent border-0 p-0 d-flex justify-content-center">
                                        <button
                                            className="w-auto text-decoration-none"
                                            onClick={() =>
                                                handleNavigate("two")
                                            }
                                        >
                                            Get Started
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-4">
                            <div className="card border-0 h-100">
                                <div className="card-body p-0">
                                    <h3 className="max-width-100">
                                        Tier Three-Gold - All trails
                                    </h3>
                                    <p>
                                        All the courses with a lot more courses
                                        on Compliance, Data Privacy, Information
                                        Security and also on skills and fun
                                        topics
                                    </p>
                                    {user?.role === 13 && (
                                        <>
                                            <h2>€72</h2>
                                            <p className="employee mb-0 h-auto">
                                                per year
                                            </p>
                                        </>
                                    )}
                                </div>
                                <div className="card-footer bg-transparent border-0 p-0">
                                    <div className="d-flex justify-content-center">
                                        <button
                                            className="w-auto text-decoration-none"
                                            onClick={() =>
                                                handleNavigate("all")
                                            }
                                        >
                                            Get Started
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="available-trails pb-md-5">
                        <h3 className="welcome-title max-width-100">
                            Available trails
                        </h3>
                        <p className="welcome-description plan-description">
                            After purchasing a subscription, you will receive
                            the following trails:
                        </p>

                        <Row className="available-trails-cards g-3">
                            {trailsData.map((trail) => (
                                <Col key={trail.id} md={6} lg={6} xl={4}>
                                    <Card>
                                        <Card.Body>
                                            <div className="d-flex align-items-center gap-3">
                                                <img
                                                    width={100}
                                                    height={100}
                                                    src={trail.imageSrc}
                                                    alt={trail.title}
                                                />
                                                <div>
                                                    <h5>{trail.title}</h5>
                                                    <p>{trail.description}</p>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default FamilyUserPlans;
