import React, { useState } from 'react'

function SelectUserStatusModal({ setUserStatusFilter, handleToggle }) {
    // State to keep track of selected statuses
    const [selectedStatuses, setSelectedStatuses] = useState("");

    // Function to handle button clicks
    const handleClick = (status) => {
        if (selectedStatuses === status) {
            setSelectedStatuses("");
        } else  {
            setSelectedStatuses(status)
        }
    };

    const onApplyClick = () => {
        setUserStatusFilter(selectedStatuses);
        handleToggle("userStatus")
    }

    return (
        <>
            <div className="select-user-status-modal">
                <div className='p-4'>
                    <h2>Select User Status</h2>

                    <div className='d-flex justify-content-between align-items-center user-status-buttons gap-3 mt-4'>
                        <button
                            className={selectedStatuses === "active" ? 'active w-100' : 'w-100'}
                            onClick={() => handleClick('active')}
                        >
                            Active
                        </button>
                        <button
                            className={selectedStatuses === "inactive" ? 'active w-100' : 'w-100'}
                            onClick={() => handleClick('inactive')}
                        >
                            Inactive
                        </button>
                        <button
                            className={selectedStatuses === "suspend" ? 'active w-100' : 'w-100'}
                            onClick={() => handleClick('suspend')}
                        >
                           Suspended
                        </button>
                    </div>
                </div>
                <div className="p-4 d-flex justify-content-between align-items-start border-dark-top-100">
                    <p className='pb-0 text-light-300 fw-400 fs-7'>*You can choose single User Status</p>
                    <button className='apply-btn' onClick={onApplyClick}>
                        Apply
                    </button>
                </div>
            </div>
        </>
    )
}

export default SelectUserStatusModal
