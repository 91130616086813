import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import AuthModal from './AuthModal';
import { encryptAndStoreLoc,decryptFromStoreLoc } from './localStore';
const ProtectedRouteTemp = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isChecking, setIsChecking] = useState(true);

  useEffect(() => {
    const storedUsername = decryptFromStoreLoc('username');
    const storedPassword = decryptFromStoreLoc('password');
    if (storedUsername ===  process.env.REACT_APP_USER && storedPassword === process.env.REACT_APP_PASSWORD) {
      setIsAuthenticated(true);
    }
    setIsChecking(false);
  }, []);

  const handleLogin = (username, password) => {
    
    if (username === process.env.REACT_APP_USER && password === process.env.REACT_APP_PASSWORD) {
      encryptAndStoreLoc('username', username);
      encryptAndStoreLoc('password', password);
      setIsAuthenticated(true);
    } else {
      alert('Invalid credentials');
      window.location.reload()
    }
  };

  if (isChecking) {
    return <div>Loading...</div>; // Or some loading indicator
  }

  return isAuthenticated ? children : <AuthModal onLogin={handleLogin} />;
};

export default ProtectedRouteTemp;
