import React from 'react'

const ComparisonTable = () => {
    return (
        <>
            <section>
                <div className="pricing-main position-relative">
                    <div className="container">
                        <div className="section-title col-md-12 col-lg-7 m-auto">
                            <span className="d-block">Comparison Table</span>
                            <h2>Find Your Perfect Fit!</h2>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-12 col-xl-11">
                                <div className="card border-0">
                                    <div className="card-body px-4 px-lg-5">
                                        <div className="table-responsive table-responsive-xxl pricing-table">
                                            <table className="table align-middle table-sm">
                                                <thead>
                                                    <tr className="border-bottom-0 border-white">
                                                        <th />
                                                        <th>Team Mastery</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <p className="fw-600">Courses</p>
                                                            <p className="fw-400">
                                                                Dive into bite-sized lessons designed to introduce
                                                                you to exciting topics and kickstart your learning
                                                                journey.
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={41}
                                                                height={40}
                                                                viewBox="0 0 41 40"
                                                                fill="none"
                                                            >
                                                                <mask
                                                                    id="mask0_257_338"
                                                                    style={{ maskType: "alpha" }}
                                                                    maskUnits="userSpaceOnUse"
                                                                    x={0}
                                                                    y={0}
                                                                    width={41}
                                                                    height={40}
                                                                >
                                                                    <rect
                                                                        x="0.166748"
                                                                        width={40}
                                                                        height={40}
                                                                        fill="#D9D9D9"
                                                                    />
                                                                </mask>
                                                                <g mask="url(#mask0_257_338)">
                                                                    <path
                                                                        d="M16.0835 30.0002L6.5835 20.5002L8.9585 18.1252L16.0835 25.2502L31.3752 9.9585L33.7502 12.3335L16.0835 30.0002Z"
                                                                        fill="#D2E23D"
                                                                    />
                                                                </g>
                                                            </svg>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p className="fw-600">LinkedIn Achievement Badge</p>
                                                            <p className="fw-400">
                                                                The right to publish course completion on LinkedIn
                                                                for one year, highlighting your commitment to
                                                                lifelong learning and compliance.
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={41}
                                                                height={40}
                                                                viewBox="0 0 41 40"
                                                                fill="none"
                                                            >
                                                                <mask
                                                                    id="mask0_257_338"
                                                                    style={{ maskType: "alpha" }}
                                                                    maskUnits="userSpaceOnUse"
                                                                    x={0}
                                                                    y={0}
                                                                    width={41}
                                                                    height={40}
                                                                >
                                                                    <rect
                                                                        x="0.166748"
                                                                        width={40}
                                                                        height={40}
                                                                        fill="#D9D9D9"
                                                                    />
                                                                </mask>
                                                                <g mask="url(#mask0_257_338)">
                                                                    <path
                                                                        d="M16.0835 30.0002L6.5835 20.5002L8.9585 18.1252L16.0835 25.2502L31.3752 9.9585L33.7502 12.3335L16.0835 30.0002Z"
                                                                        fill="#D2E23D"
                                                                    />
                                                                </g>
                                                            </svg>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p className="fw-600">
                                                                Compliance Excellence Certificate
                                                            </p>
                                                            <p className="fw-400">
                                                                A personal compliance score and certificate,
                                                                showcasing your commitment to regulatory adherence
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={41}
                                                                height={40}
                                                                viewBox="0 0 41 40"
                                                                fill="none"
                                                            >
                                                                <mask
                                                                    id="mask0_257_338"
                                                                    style={{ maskType: "alpha" }}
                                                                    maskUnits="userSpaceOnUse"
                                                                    x={0}
                                                                    y={0}
                                                                    width={41}
                                                                    height={40}
                                                                >
                                                                    <rect
                                                                        x="0.166748"
                                                                        width={40}
                                                                        height={40}
                                                                        fill="#D9D9D9"
                                                                    />
                                                                </mask>
                                                                <g mask="url(#mask0_257_338)">
                                                                    <path
                                                                        d="M16.0835 30.0002L6.5835 20.5002L8.9585 18.1252L16.0835 25.2502L31.3752 9.9585L33.7502 12.3335L16.0835 30.0002Z"
                                                                        fill="#D2E23D"
                                                                    />
                                                                </g>
                                                            </svg>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p className="fw-600">Silver Courses</p>
                                                            <p className="fw-400">
                                                                Take a step further on your educational journey as
                                                                you dive into in-depth and comprehensive lessons
                                                                designed to expand your knowledge and skills.&nbsp;
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={41}
                                                                height={40}
                                                                viewBox="0 0 41 40"
                                                                fill="none"
                                                            >
                                                                <mask
                                                                    id="mask0_257_338"
                                                                    style={{ maskType: "alpha" }}
                                                                    maskUnits="userSpaceOnUse"
                                                                    x={0}
                                                                    y={0}
                                                                    width={41}
                                                                    height={40}
                                                                >
                                                                    <rect
                                                                        x="0.166748"
                                                                        width={40}
                                                                        height={40}
                                                                        fill="#D9D9D9"
                                                                    />
                                                                </mask>
                                                                <g mask="url(#mask0_257_338)">
                                                                    <path
                                                                        d="M16.0835 30.0002L6.5835 20.5002L8.9585 18.1252L16.0835 25.2502L31.3752 9.9585L33.7502 12.3335L16.0835 30.0002Z"
                                                                        fill="#D2E23D"
                                                                    />
                                                                </g>
                                                            </svg>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p className="fw-600">Gold Courses</p>
                                                            <p className="fw-400">
                                                                These advanced courses provide top-tier expertise
                                                                and cutting-edge insights into specialized fields
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={41}
                                                                height={40}
                                                                viewBox="0 0 41 40"
                                                                fill="none"
                                                            >
                                                                <mask
                                                                    id="mask0_257_338"
                                                                    style={{ maskType: "alpha" }}
                                                                    maskUnits="userSpaceOnUse"
                                                                    x={0}
                                                                    y={0}
                                                                    width={41}
                                                                    height={40}
                                                                >
                                                                    <rect
                                                                        x="0.166748"
                                                                        width={40}
                                                                        height={40}
                                                                        fill="#D9D9D9"
                                                                    />
                                                                </mask>
                                                                <g mask="url(#mask0_257_338)">
                                                                    <path
                                                                        d="M16.0835 30.0002L6.5835 20.5002L8.9585 18.1252L16.0835 25.2502L31.3752 9.9585L33.7502 12.3335L16.0835 30.0002Z"
                                                                        fill="#D2E23D"
                                                                    />
                                                                </g>
                                                            </svg>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p className="fw-600">Leaderboard and Analytics</p>
                                                            <p className="fw-400">
                                                                Stay inspired by monitoring your progress and
                                                                comparing your performance with fellow learners.
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={41}
                                                                height={40}
                                                                viewBox="0 0 41 40"
                                                                fill="none"
                                                            >
                                                                <mask
                                                                    id="mask0_257_338"
                                                                    style={{ maskType: "alpha" }}
                                                                    maskUnits="userSpaceOnUse"
                                                                    x={0}
                                                                    y={0}
                                                                    width={41}
                                                                    height={40}
                                                                >
                                                                    <rect
                                                                        x="0.166748"
                                                                        width={40}
                                                                        height={40}
                                                                        fill="#D9D9D9"
                                                                    />
                                                                </mask>
                                                                <g mask="url(#mask0_257_338)">
                                                                    <path
                                                                        d="M16.0835 30.0002L6.5835 20.5002L8.9585 18.1252L16.0835 25.2502L31.3752 9.9585L33.7502 12.3335L16.0835 30.0002Z"
                                                                        fill="#D2E23D"
                                                                    />
                                                                </g>
                                                            </svg>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p className="fw-600">
                                                                Allow access to multiple users
                                                            </p>
                                                            <p className="fw-400">
                                                                All the benefits of paid access to every member of
                                                                your family.&nbsp;
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={41}
                                                                height={40}
                                                                viewBox="0 0 41 40"
                                                                fill="none"
                                                            >
                                                                <mask
                                                                    id="mask0_257_338"
                                                                    style={{ maskType: "alpha" }}
                                                                    maskUnits="userSpaceOnUse"
                                                                    x={0}
                                                                    y={0}
                                                                    width={41}
                                                                    height={40}
                                                                >
                                                                    <rect
                                                                        x="0.166748"
                                                                        width={40}
                                                                        height={40}
                                                                        fill="#D9D9D9"
                                                                    />
                                                                </mask>
                                                                <g mask="url(#mask0_257_338)">
                                                                    <path
                                                                        d="M16.0835 30.0002L6.5835 20.5002L8.9585 18.1252L16.0835 25.2502L31.3752 9.9585L33.7502 12.3335L16.0835 30.0002Z"
                                                                        fill="#D2E23D"
                                                                    />
                                                                </g>
                                                            </svg>
                                                        </td>
                                                    </tr>
                                                    <tr className="border-bottom-0 border-white">
                                                        <td />
                                                        <td>
                                                            <button
                                                                type="button"
                                                                className="btn-primary rounded-2"
                                                            >
                                                                Get One Month Free Access
                                                            </button>
                                                            <p className="text-primary text-center mt-3">
                                                                Starting €‎1 Per Month / User
                                                            </p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ComparisonTable