import { Navigate, useLocation } from 'react-router-dom';

// Assuming decryptFromStoreLoc is properly imported and working
import { decryptFromStoreLoc } from './localStore';

function PrivateCourse({ children }) {
    const location = useLocation();

    // Retrieve and decrypt the user data from localStorage
    const encryptedUser = localStorage.getItem('user');
    const user = encryptedUser ? decryptFromStoreLoc('user') : null;

    // Check if the user object has a valid user_id
    const hasUserId = !!user?.user_id;

    // Retrieve token from localStorage
    const token = localStorage.getItem('token');

    // Validate the presence of both token and user_id
    const isAuthenticated = !!token && hasUserId;

    // Redirect to sign-in if authentication fails
    return isAuthenticated ? (
        children
    ) : (
        <Navigate to="/sign-in" state={{ from: location }} replace />
    );
}

export default PrivateCourse;
