import React from 'react'
import AdminHeader from './../layout/AdminHeader';
import Footer from '../layout/Footer';
import CustomerSidbar from '../components/CustomerSidbar';
import SliderHomeSec from '../components/SliderHomeSec';

function CustomerHomeExplore() {
    return (
        <>
            <AdminHeader />
            <section>
                <div className="dashbaord-home-main">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-3 col-md-4 col-lg-3 col-xxl-3 d-none d-xl-block px-4 bg-white sidebar-mian">
                                <CustomerSidbar />
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 col-xxl-9 bg-body">
                                <div className="row ps-0 ps-lg-5 dashbaord-right-side justify-content-md-center justify-content-lg-between pt-5">
                                    <div className="col-12 col-md-11 col-lg-12 right-side">
                                        <div className="row justify-content-between align-items-center">
                                            <div className="col-12 col-md-6 col-lg-8">
                                                <div className="section-title col-md-12 col-lg-12 m-auto">
                                                    <span className="d-block text-start">Featured Courses</span>
                                                    <h2 className="text-start text-black display-6 sm-mb-3">
                                                        Our Top Picks for You
                                                    </h2>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row align-items-center mb-5 g-4">
                                            <div className="col-12 col-md-6 col-lg-2">
                                                <select className="form-select p-3 border-0 w-100">
                                                    <option selected="">Bronze</option>
                                                    <option value={1}>One</option>
                                                    <option value={2}>Two</option>
                                                    <option value={3}>Three</option>
                                                </select>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-4">
                                                <select className="form-select p-3 border-0">
                                                    <option selected="">Technology and Innovation</option>
                                                    <option value={1}>One</option>
                                                    <option value={2}>Two</option>
                                                    <option value={3}>Three</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row row-cols-md-1 row-cols-lg-2 row-cols-xl-2 g-4 pb-5">
                                            <div className="col">
                                                <div className="card featured-card overflow-hidden border-0 position-relative accordion-items-shadow">
                                                    <img
                                                        src="./featured-courses/featured01.jpg"
                                                        className="featured-courses-img"
                                                        alt=""
                                                    />
                                                    <span className="badge badge-warning bg-warning position-absolute top-0 start-0">
                                                        Bronze
                                                    </span>
                                                    <div className="card-body">
                                                        <span className="badge badge-light rounded-2 fw-400 fs-6">
                                                            Marketing and Sales
                                                        </span>
                                                        <h5>Strategies for Selling Success</h5>
                                                        <p className="d-flex align-items-center gap-2 fw-400 text-black mt-3 mb-4 fs-6">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={30}
                                                                height={30}
                                                                viewBox="0 0 30 30"
                                                                fill="none"
                                                            >
                                                                <circle cx={15} cy={15} r={15} fill="#D9D9D9" />
                                                            </svg>
                                                            Professor Sophia Lee
                                                        </p>
                                                        <div className="row justify-content-between align-items-center mt-4 mb-4 mb-md-1">
                                                            <p className="fw-500 text-black mb-4 mb-md-0 col-12 col-md-8 col-lg-6 col-xl-7">
                                                                Beginner Boost
                                                            </p>
                                                            <div className="col-12 col-md-4 col-lg-6 col-xl-5 d-flex justify-content-between align-items-center">
                                                                <ul className="rectangle d-flex align-items-center gap-2  flex-wrap mb-0 p-0">
                                                                    <li className="active" />
                                                                    <li className="active" />
                                                                    <li className="active" />
                                                                    <li />
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="row justify-content-between align-items-center">
                                                            <p className="fw-500 text-black col-12 col-md-8 col-lg-6 col-xl-7 mb-3 mb-md-0">
                                                                Starter Pack
                                                            </p>
                                                            <div className="col-12 col-md-4 col-lg-6 col-xl-5 d-flex align-items-center gap-2">
                                                                <img
                                                                    src="./featured-courses/piggy-bank.jpg"
                                                                    className="wh-25"
                                                                    alt=""
                                                                />
                                                                <p className="mb-0 text-black fw-600">Explore Free</p>
                                                            </div>
                                                        </div>
                                                        <button
                                                            type="button"
                                                            className="btn-primary d-flex align-items-center gap-3 rounded-2 mt-4"
                                                        >
                                                            Enroll Today
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={22}
                                                                height={14}
                                                                viewBox="0 0 22 14"
                                                                fill="none"
                                                            >
                                                                <path
                                                                    d="M1 6.99854H21L16 1"
                                                                    stroke="white"
                                                                    strokeWidth="1.5"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                                <path
                                                                    d="M21 6.99854L16 13"
                                                                    stroke="white"
                                                                    strokeWidth="1.5"
                                                                    strokeLinecap="round"
                                                                />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="card featured-card overflow-hidden border-0 position-relative accordion-items-shadow">
                                                    <img
                                                        src="./featured-courses/featured02.jpg"
                                                        className="featured-courses-img"
                                                        alt=""
                                                    />
                                                    <span className="badge badge-warning bg-warning position-absolute top-0 start-0">
                                                        Silver
                                                    </span>
                                                    <div className="card-body">
                                                        <span className="badge badge-light rounded-2 fw-400 fs-6">
                                                            Marketing and Sales
                                                        </span>
                                                        <h5>Strategies for Selling Success</h5>
                                                        <p className="d-flex align-items-center gap-2 fw-400 text-black mt-3 mb-4 fs-6">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={30}
                                                                height={30}
                                                                viewBox="0 0 30 30"
                                                                fill="none"
                                                            >
                                                                <circle cx={15} cy={15} r={15} fill="#D9D9D9" />
                                                            </svg>
                                                            Professor Sophia Lee
                                                        </p>
                                                        <div className="row justify-content-between align-items-center mt-4 mb-4 mb-md-1">
                                                            <p className="fw-500 text-black mb-4 mb-md-0 col-12 col-md-8 col-lg-6 col-xl-7">
                                                                Beginner Boost
                                                            </p>
                                                            <div className="col-12 col-md-4 col-lg-6 col-xl-5 d-flex justify-content-between align-items-center">
                                                                <ul className="rectangle d-flex align-items-center gap-2  flex-wrap mb-0 p-0">
                                                                    <li className="active" />
                                                                    <li className="active" />
                                                                    <li className="active" />
                                                                    <li />
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="row justify-content-between align-items-center">
                                                            <p className="fw-500 text-black col-12 col-md-8 col-lg-6 col-xl-7 mb-3 mb-md-0">
                                                                Starter Pack
                                                            </p>
                                                            <div className="col-12 col-md-4 col-lg-6 col-xl-5 d-flex align-items-center gap-2">
                                                                <img
                                                                    src="./featured-courses/piggy-bank.jpg"
                                                                    className="wh-25"
                                                                    alt=""
                                                                />
                                                                <p className="mb-0 text-black fw-600">Explore Free</p>
                                                            </div>
                                                        </div>
                                                        <button
                                                            type="button"
                                                            className="btn-secondary text-primary fw-600 d-flex align-items-center gap-3 rounded-2 mt-4"
                                                        >
                                                            Unlock It
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={23}
                                                                height={14}
                                                                viewBox="0 0 23 14"
                                                                fill="none"
                                                            >
                                                                <path
                                                                    d="M1.3335 6.99854H21.3335L16.3335 1"
                                                                    stroke="#00237D"
                                                                    strokeWidth="1.5"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                                <path
                                                                    d="M21.3335 6.99854L16.3335 13"
                                                                    stroke="#00237D"
                                                                    strokeWidth="1.5"
                                                                    strokeLinecap="round"
                                                                />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="card featured-card overflow-hidden border-0 position-relative accordion-items-shadow">
                                                    <img
                                                        src="./featured-courses/featured01.jpg"
                                                        className="featured-courses-img"
                                                        alt=""
                                                    />
                                                    <span className="badge badge-warning bg-warning position-absolute top-0 start-0">
                                                        Bronze
                                                    </span>
                                                    <div className="card-body">
                                                        <span className="badge badge-light rounded-2 fw-400 fs-6">
                                                            Marketing and Sales
                                                        </span>
                                                        <h5>Strategies for Selling Success</h5>
                                                        <p className="d-flex align-items-center gap-2 fw-400 text-black mt-3 mb-4 fs-6">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={30}
                                                                height={30}
                                                                viewBox="0 0 30 30"
                                                                fill="none"
                                                            >
                                                                <circle cx={15} cy={15} r={15} fill="#D9D9D9" />
                                                            </svg>
                                                            Professor Sophia Lee
                                                        </p>
                                                        <div className="row justify-content-between align-items-center mt-4 mb-4 mb-md-1">
                                                            <p className="fw-500 text-black mb-4 mb-md-0 col-12 col-md-8 col-lg-6 col-xl-7">
                                                                Beginner Boost
                                                            </p>
                                                            <div className="col-12 col-md-4 col-lg-6 col-xl-5 d-flex justify-content-between align-items-center">
                                                                <ul className="rectangle d-flex align-items-center gap-2  flex-wrap mb-0 p-0">
                                                                    <li className="active" />
                                                                    <li className="active" />
                                                                    <li className="active" />
                                                                    <li />
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="row justify-content-between align-items-center">
                                                            <p className="fw-500 text-black col-12 col-md-8 col-lg-6 col-xl-7 mb-3 mb-md-0">
                                                                Starter Pack
                                                            </p>
                                                            <div className="col-12 col-md-4 col-lg-6 col-xl-5 d-flex align-items-center gap-2">
                                                                <img
                                                                    src="./featured-courses/piggy-bank.jpg"
                                                                    className="wh-25"
                                                                    alt=""
                                                                />
                                                                <p className="mb-0 text-black fw-600">Explore Free</p>
                                                            </div>
                                                        </div>
                                                        <button
                                                            type="button"
                                                            className="btn-primary d-flex align-items-center gap-3 rounded-2 mt-4"
                                                        >
                                                            Enroll Today
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={22}
                                                                height={14}
                                                                viewBox="0 0 22 14"
                                                                fill="none"
                                                            >
                                                                <path
                                                                    d="M1 6.99854H21L16 1"
                                                                    stroke="white"
                                                                    strokeWidth="1.5"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                                <path
                                                                    d="M21 6.99854L16 13"
                                                                    stroke="white"
                                                                    strokeWidth="1.5"
                                                                    strokeLinecap="round"
                                                                />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="card featured-card overflow-hidden border-0 position-relative accordion-items-shadow">
                                                    <img
                                                        src="./featured-courses/featured02.jpg"
                                                        className="featured-courses-img"
                                                        alt=""
                                                    />
                                                    <span className="badge badge-warning bg-warning position-absolute top-0 start-0">
                                                        Silver
                                                    </span>
                                                    <div className="card-body">
                                                        <span className="badge badge-light rounded-2 fw-400 fs-6">
                                                            Marketing and Sales
                                                        </span>
                                                        <h5>Strategies for Selling Success</h5>
                                                        <p className="d-flex align-items-center gap-2 fw-400 text-black mt-3 mb-4 fs-6">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={30}
                                                                height={30}
                                                                viewBox="0 0 30 30"
                                                                fill="none"
                                                            >
                                                                <circle cx={15} cy={15} r={15} fill="#D9D9D9" />
                                                            </svg>
                                                            Professor Sophia Lee
                                                        </p>
                                                        <div className="row justify-content-between align-items-center mt-4 mb-4 mb-md-1">
                                                            <p className="fw-500 text-black mb-4 mb-md-0 col-12 col-md-8 col-lg-6 col-xl-7">
                                                                Beginner Boost
                                                            </p>
                                                            <div className="col-12 col-md-4 col-lg-6 col-xl-5 d-flex justify-content-between align-items-center">
                                                                <ul className="rectangle d-flex align-items-center gap-2  flex-wrap mb-0 p-0">
                                                                    <li className="active" />
                                                                    <li className="active" />
                                                                    <li className="active" />
                                                                    <li />
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="row justify-content-between align-items-center">
                                                            <p className="fw-500 text-black col-12 col-md-8 col-lg-6 col-xl-7 mb-3 mb-md-0">
                                                                Starter Pack
                                                            </p>
                                                            <div className="col-12 col-md-4 col-lg-6 col-xl-5 d-flex align-items-center gap-2">
                                                                <img
                                                                    src="./featured-courses/piggy-bank.jpg"
                                                                    className="wh-25"
                                                                    alt=""
                                                                />
                                                                <p className="mb-0 text-black fw-600">Explore Free</p>
                                                            </div>
                                                        </div>
                                                        <button
                                                            type="button"
                                                            className="btn-secondary text-primary fw-600 d-flex align-items-center gap-3 rounded-2 mt-4"
                                                        >
                                                            Unlock It
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={23}
                                                                height={14}
                                                                viewBox="0 0 23 14"
                                                                fill="none"
                                                            >
                                                                <path
                                                                    d="M1.3335 6.99854H21.3335L16.3335 1"
                                                                    stroke="#00237D"
                                                                    strokeWidth="1.5"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                                <path
                                                                    d="M21.3335 6.99854L16.3335 13"
                                                                    stroke="#00237D"
                                                                    strokeWidth="1.5"
                                                                    strokeLinecap="round"
                                                                />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="card featured-card overflow-hidden border-0 position-relative accordion-items-shadow">
                                                    <img
                                                        src="./featured-courses/featured01.jpg"
                                                        className="featured-courses-img"
                                                        alt=""
                                                    />
                                                    <span className="badge badge-warning bg-warning position-absolute top-0 start-0">
                                                        Bronze
                                                    </span>
                                                    <div className="card-body">
                                                        <span className="badge badge-light rounded-2 fw-400 fs-6">
                                                            Marketing and Sales
                                                        </span>
                                                        <h5>Strategies for Selling Success</h5>
                                                        <p className="d-flex align-items-center gap-2 fw-400 text-black mt-3 mb-4 fs-6">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={30}
                                                                height={30}
                                                                viewBox="0 0 30 30"
                                                                fill="none"
                                                            >
                                                                <circle cx={15} cy={15} r={15} fill="#D9D9D9" />
                                                            </svg>
                                                            Professor Sophia Lee
                                                        </p>
                                                        <div className="row justify-content-between align-items-center mt-4 mb-4 mb-md-1">
                                                            <p className="fw-500 text-black mb-4 mb-md-0 col-12 col-md-8 col-lg-6 col-xl-7">
                                                                Beginner Boost
                                                            </p>
                                                            <div className="col-12 col-md-4 col-lg-6 col-xl-5 d-flex justify-content-between align-items-center">
                                                                <ul className="rectangle d-flex align-items-center gap-2  flex-wrap mb-0 p-0">
                                                                    <li className="active" />
                                                                    <li className="active" />
                                                                    <li className="active" />
                                                                    <li />
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="row justify-content-between align-items-center">
                                                            <p className="fw-500 text-black col-12 col-md-8 col-lg-6 col-xl-7 mb-3 mb-md-0">
                                                                Starter Pack
                                                            </p>
                                                            <div className="col-12 col-md-4 col-lg-6 col-xl-5 d-flex align-items-center gap-2">
                                                                <img
                                                                    src="./featured-courses/piggy-bank.jpg"
                                                                    className="wh-25"
                                                                    alt=""
                                                                />
                                                                <p className="mb-0 text-black fw-600">Explore Free</p>
                                                            </div>
                                                        </div>
                                                        <button
                                                            type="button"
                                                            className="btn-primary d-flex align-items-center gap-3 rounded-2 mt-4"
                                                        >
                                                            Enroll Today
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={22}
                                                                height={14}
                                                                viewBox="0 0 22 14"
                                                                fill="none"
                                                            >
                                                                <path
                                                                    d="M1 6.99854H21L16 1"
                                                                    stroke="white"
                                                                    strokeWidth="1.5"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                                <path
                                                                    d="M21 6.99854L16 13"
                                                                    stroke="white"
                                                                    strokeWidth="1.5"
                                                                    strokeLinecap="round"
                                                                />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="card featured-card overflow-hidden border-0 position-relative accordion-items-shadow">
                                                    <img
                                                        src="./featured-courses/featured02.jpg"
                                                        className="featured-courses-img"
                                                        alt=""
                                                    />
                                                    <span className="badge badge-warning bg-warning position-absolute top-0 start-0">
                                                        Silver
                                                    </span>
                                                    <div className="card-body">
                                                        <span className="badge badge-light rounded-2 fw-400 fs-6">
                                                            Marketing and Sales
                                                        </span>
                                                        <h5>Strategies for Selling Success</h5>
                                                        <p className="d-flex align-items-center gap-2 fw-400 text-black mt-3 mb-4 fs-6">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={30}
                                                                height={30}
                                                                viewBox="0 0 30 30"
                                                                fill="none"
                                                            >
                                                                <circle cx={15} cy={15} r={15} fill="#D9D9D9" />
                                                            </svg>
                                                            Professor Sophia Lee
                                                        </p>
                                                        <div className="row justify-content-between align-items-center mt-4 mb-4 mb-md-1">
                                                            <p className="fw-500 text-black mb-4 mb-md-0 col-12 col-md-8 col-lg-6 col-xl-7">
                                                                Beginner Boost
                                                            </p>
                                                            <div className="col-12 col-md-4 col-lg-6 col-xl-5 d-flex justify-content-between align-items-center">
                                                                <ul className="rectangle d-flex align-items-center gap-2  flex-wrap mb-0 p-0">
                                                                    <li className="active" />
                                                                    <li className="active" />
                                                                    <li className="active" />
                                                                    <li />
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="row justify-content-between align-items-center">
                                                            <p className="fw-500 text-black col-12 col-md-8 col-lg-6 col-xl-7 mb-3 mb-md-0">
                                                                Starter Pack
                                                            </p>
                                                            <div className="col-12 col-md-4 col-lg-6 col-xl-5 d-flex align-items-center gap-2">
                                                                <img
                                                                    src="./featured-courses/piggy-bank.jpg"
                                                                    className="wh-25"
                                                                    alt=""
                                                                />
                                                                <p className="mb-0 text-black fw-600">Explore Free</p>
                                                            </div>
                                                        </div>
                                                        <button
                                                            type="button"
                                                            className="btn-secondary text-primary fw-600 d-flex align-items-center gap-3 rounded-2 mt-4"
                                                        >
                                                            Unlock It
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={23}
                                                                height={14}
                                                                viewBox="0 0 23 14"
                                                                fill="none"
                                                            >
                                                                <path
                                                                    d="M1.3335 6.99854H21.3335L16.3335 1"
                                                                    stroke="#00237D"
                                                                    strokeWidth="1.5"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                                <path
                                                                    d="M21.3335 6.99854L16.3335 13"
                                                                    stroke="#00237D"
                                                                    strokeWidth="1.5"
                                                                    strokeLinecap="round"
                                                                />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            <Footer />
        </>
    )
}

export default CustomerHomeExplore