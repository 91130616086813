import React, { useState, useEffect } from 'react';
import {
    MultipleFileUpload,
    MultipleFileUploadMain,
    MultipleFileUploadStatus,
    MultipleFileUploadStatusItem,
    Modal,
    Checkbox,
} from '@patternfly/react-core';
import "@patternfly/react-core/dist/styles/base.css";
import { getServerURL } from '../helper/envConfig';
import { toast } from 'react-toastify';



function DragAndDrop({ currentFiles, setCurrentFiles, setIsFileLoading, isFileLoading ,fileProgress, setFileProgress}) {
  
    const serverURL = getServerURL();
    const [isHorizontal, setIsHorizontal] = useState(false);
    const [readFileData, setReadFileData] = useState([]);
    const [showStatus, setShowStatus] = useState(false);
    const [statusIcon, setStatusIcon] = useState('inProgress');
    const [modalText, setModalText] = useState('');
    const buttonText = currentFiles.length > 0 ? "Select File" : "Upload Exel file"
    const titleIcon = currentFiles.length > 0 ? "feather_upload-cloud.png" : "xls.png"


    useEffect(() => {
        if (!showStatus && currentFiles.length > 0) {
            setShowStatus(true);
        }

        if (readFileData.length < currentFiles.length) {
            setStatusIcon('inProgress');
        } else if (readFileData.every((file) => file.loadResult === 'success')) {
            setStatusIcon('success');
        } else {
            setStatusIcon('danger');
        }
    }, [readFileData, currentFiles, showStatus]);

    const removeFiles = (namesOfFilesToRemove) => {
        const newCurrentFiles = currentFiles.filter(
            (currentFile) => !namesOfFilesToRemove.some((fileName) => fileName === currentFile.name)
        );

        setCurrentFiles(newCurrentFiles);

        const newReadFiles = readFileData.filter(
            (readFile) => !namesOfFilesToRemove.some((fileName) => fileName === readFile.fileName)
        );

        setReadFileData(newReadFiles);
    };

    const handleFileDrop = (_event, droppedFiles) => {
        droppedFiles.forEach(file => {
            handleFileRead(file); // Read each file
        });
        const currentFileNames = currentFiles.map((file) => file.name);
        const reUploads = droppedFiles.filter((droppedFile) => currentFileNames.includes(droppedFile.name));

        Promise.resolve()
            .then(() => removeFiles(reUploads.map((file) => file.name)))
            .then(() => setCurrentFiles((prevFiles) => [...prevFiles, ...droppedFiles]));
    };

        const handleFileRead = (file) => {
            const reader = new FileReader();

            // Listen for progress updates
            reader.onprogress = (event) => {
                if (event.lengthComputable) {
                    // Calculate the progress as a percentage
                    const progress = (event.loaded / event.total) * 100;
                    setFileProgress(prevProgress => ({
                        ...prevProgress,
                        ["parentage"]: progress
                    }));
                }
            };

        reader.onloadend = () => {
            // Handle the completion of the file read
            setFileProgress(prevProgress => ({
                ...prevProgress,
                [file.name]: 100 // Mark as complete
            }));
            // Process the file content using reader.result
        };

        reader.onerror = () => {
            console.error("Error reading file:", file.name);
            // Handle the error, possibly updating progress to indicate failure
        };

        // Initiate reading the file (as text, for example)
        reader.readAsText(file);
    };

    const handleReadSuccess = (data, file) => {
        setReadFileData((prevReadFiles) => [...prevReadFiles, { data, fileName: file.name, loadResult: 'success' }]);
    };

    const handleReadFail = (error, file) => {
        setReadFileData((prevReadFiles) => [
            ...prevReadFiles,
            { loadError: error, fileName: file.name, loadResult: 'danger' }
        ]);
    };

    const handleDropRejected = (fileRejections) => {
        if (fileRejections.length === 1) {
            // setModalText(`${fileRejections[0].file.name} is not an accepted file type`);
            toast.error(`${fileRejections[0].file.name} is not an accepted file type`);
        } else {
            const rejectedMessages = fileRejections.reduce(
                (acc, fileRejection) => (acc += `${fileRejection.file.name}, `),
                ''
            );
            // setModalText(`${rejectedMessages}are not accepted file types`);
            toast.error(`${rejectedMessages}are not accepted file types`);

        }

    };

    useEffect(() => {

        // Check if there are files and all files have reached 100%
        const allFilesLoaded = currentFiles.length > 0 && currentFiles.every(file => fileProgress[file.name] === 100);
        // Update isFileLoading based on whether all files are loaded
        setIsFileLoading(!allFilesLoaded);

    }, [fileProgress, currentFiles]);
    

    return (
        <>
            <MultipleFileUpload
                onFileDrop={handleFileDrop}
                dropzoneProps={{
                    accept: {
                        // 'image/jpeg': ['.jpg', '.jpeg'],
                        // 'application/msword': ['.doc'],
                        // 'application/pdf': ['.pdf'],
                        // 'image/png': ['.png'],
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx', '.xls'],
                    },
                    onDropRejected: handleDropRejected
                }}
                isHorizontal={currentFiles.length > 0 ? true : false}
            >
                <MultipleFileUploadMain
                    titleIcon={<img src={"./png/" + titleIcon} alt="Icon" />}
                    titleText="Select a file or drag and drop here"
                    titleTextSeparator="Upload filled-in template here"
                    // infoText="Accepted file types: JPEG, Doc, PDF, PNG"
                    browseButtonText={buttonText}
                />
                {currentFiles.length > 0 && <p className='fw-500 fs-7 text-bl mb-0'>File added</p>}
                {currentFiles.map((file) => (
                    <MultipleFileUploadStatusItem
                        fileIcon={<img src={"./png/xls2.png"} className='xls-png' alt="Icon" />}
                        file={file}
                        key={file.name}
                        onClearClick={() => removeFiles([file.name])}
                        onReadSuccess={handleReadSuccess}
                        onReadFail={handleReadFail}
                    />
                ))}

                {/* {showStatus && (
                    <MultipleFileUploadStatus
                        statusToggleText={`File added`}
                        statusToggleIcon={statusIcon}
                    >
                        {currentFiles.map((file) => (
                            <MultipleFileUploadStatusItem
                                file={file}
                                key={file.name}
                                onClearClick={() => removeFiles([file.name])}
                                onReadSuccess={handleReadSuccess}
                                onReadFail={handleReadFail}
                            />
                        ))}
                    </MultipleFileUploadStatus>
                )} */}
                {/* <Modal
                    isOpen={!!modalText}
                    title="Unsupported file"
                    titleIconVariant="warning"
                    showClose
                    aria-label="unsupported file upload attempted"
                    onClose={() => setModalText('')}
                    size="md"
                >
                    {modalText}
                </Modal> */}
            </MultipleFileUpload>
            {/* <Checkbox
                id="horizontal-checkbox"
                label="Show as horizontal"
                isChecked={isHorizontal}
                onChange={() => setIsHorizontal(!isHorizontal)}
            /> */}
        </>
    );
}

export default DragAndDrop;
