import React, { useEffect, useState, useContext } from 'react';
import Form from 'react-bootstrap/Form';
import { getServerURL } from '../../helper/envConfig';
import api from '../../helper/api';
import DynamicPagination from "../../components/DynamicPagination.js"
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { CourseContext } from '../../context/CourseContext.js';

const SetCourse = (({setCurrentStep, selectedCourses, currentStep, shouldSubmit,employeeCounts, setEmployeeCounts }) => {

    const {setShowSuccess,message, setMessage} = useContext(CourseContext);
    const navigate = useNavigate();
    // const [selectedCourses] = useState([]);
    const [coursesList, setCoursesList] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedEmployee, setSelectedEmployee] = useState(0);
    const [selectedDepartments, setSelectedDepartments] = React.useState(selectedEmployee == 1 ? departments?.[0]?.department_id : "");


    const initialValues = {
        assignment_name: "",
        completion_date: "",
        type: "0",
        department_id: selectedEmployee == 1 ? departments?.[0]?.department_id : "",
    };

    const [values, setValues] = useState(initialValues);
    const serverURL = getServerURL();

    const getCourses = async () => {
        try {
            const response = await api.postWithToken(`${serverURL}users/business-owner/selected-courses/?page=${currentPage}&page_size=10`, { courses: selectedCourses });
            setCoursesList(response.data)

        } catch (error) {
            console.error(error);
        }
    }

    const getDepartmant = async () => {
        try {
            const response = await api.getWithToken(`${serverURL}users/employee/departments/`);
            setDepartments(response.data.data)
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        getCourses()
    }, [currentPage]);

    useEffect(() => {
        getDepartmant();
    }, [selectedEmployee]);

    const selectEmployee = (e) => {
        setSelectedEmployee(e.target.value)
    }

    const selectDepartments = (e) => {
        setSelectedDepartments(e.target.value)
    }

    React.useEffect(() => {
        if (departments?.length > 0 && selectedEmployee == 1) {
            setSelectedDepartments(departments[0].department_id);
            setValues((prevValues) => ({
                ...prevValues,
                "department_id": departments[0].department_id,
            }));
        }
    }, [departments]);


    const handleChange = (e) => {
        const { name, value, type } = e.target;

        // Check if the input is of type date and needs special handling
        if (type === 'date') {
            // Assuming the input value is in YYYY-MM-DD format,
            // and you want to store it in YYYY-DD-MM format internally
            const [year, month, day] = value.split('-');
            const formattedValue = `${year}-${month}-${day}`;

            setValues((prevValues) => ({
                ...prevValues,
                [name]: formattedValue,
            }));
        } else {
            // Handle all other input types normally
            setValues((prevValues) => ({
                ...prevValues,
                [name]: value,
            }));
        }
    };
    
    const validateAndAdvanceStep = () => {
        // Check if all required conditions are met
        if (values.assignment_name && values.completion_date && values.type && (values.type == 1 ? values.department_id : true)) {
            setCurrentStep(3);
        } else {
            setCurrentStep(2);
        }
    };

    useEffect(() => {
        validateAndAdvanceStep();
    }, [values]); // Depend on values to re-run validation
    

    const submitData = async () => {
        if (values.assignment_name && values.completion_date && values.type && (values.type == 1 ? values.department_id : values.type)) {

            try {
                const response = await api.postWithToken(`${serverURL}users/business-owner/assign-courses/`, {
                    "courses": selectedCourses,
                    "type": Number(values.type) || 0,
                    "department_id": Number(values.department_id) || 0,
                    "assignment_name": values.assignment_name,
                    "completion_date": values.completion_date
                });
                if (response.data.success) {
                    // toast.success(response.data.message);
                    setMessage(response.data.message)
                    setShowSuccess(true)
                    navigate("/business-progress")

                } else {
                    toast.error(response.data.message);
                }

            } catch (error) {
                console.error(error);
            }

        } else {
            toast.error("Please fill out all felids");
        }
    };


    const employeeCount = async () => {

        try {

            const response = await api.postWithToken(`${serverURL}users/employee/count/`, {
                "type": Number(values.type) || 0,
                "department_id": Number(values.department_id) || 0,
            });
            setEmployeeCounts(response.data.total_employees)


        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        employeeCount()
    }, [selectedDepartments,selectedEmployee]);

    useEffect(() => {
        if (shouldSubmit) {
            submitData();
            // Reset shouldSubmit state via a callback to the parent or another mechanism
        }
    }, [shouldSubmit]);


    return (
        <>
            <div className="bg-white rounded-3">
                <div className="row justify-content-center mt-5 g-4 select-course-row">
                    {/* Map loop to generate course cards */}
                    <p className='text-end col-11 col-md-9 mb-0 text-primary fw-400 fs-6'>Preview</p>
                    {coursesList?.results?.selected_courses?.map(course => (
                        <div className={`col-11 col-md-9`} key={course.course_id}>
                            <div className="form-check select-course px-0">
                                <label className="form-check-label card border-0 shadow" >
                                    <div className="card-body">
                                        <div className='d-flex align-items-start gap-3'>
                                            <div className='d-flex flex-column justify-content-between align-items-start w-100 gap-3'>
                                                <div>
                                                    {/* <p>Duration 5 hours</p> */}
                                                    <h2>{course.name}</h2>
                                                </div>
                                                <div>
                                                    <div className='badge badge-warning bg-warning border-0 rounded-2'>{course.type_name}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    ))}
                    {coursesList.total_pages >= 2 &&
                        <DynamicPagination
                            currentPage={currentPage}
                            totalPages={coursesList.total_pages}
                            onPageChange={(newPage) => {
                                setCurrentPage(newPage);
                            }}
                        />
                    }
                    <div className='col-11 col-md-9'>
                        <form className="row g-3 form-main mt-4">
                            <div className="col-md-12">
                                <div className="col-md-12 col-lg-12 col-xl-12 mb-3">
                                    <label className="form-label">Assignment Name</label>
                                    <input
                                        className="form-control"
                                        placeholder="Enter assignment name"
                                        name="assignment_name"
                                        type="text"
                                        defaultValue=""
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-md-12 col-lg-12 col-xl-6 mb-3 mt-3">
                                    <label className="form-label">Date</label>
                                    <input
                                        type="date"
                                        name='completion_date'
                                        className="form-control date-select"
                                        defaultValue=""
                                        min={new Date().toISOString().split('T')[0]}
                                        onChange={handleChange}

                                    />
                                </div>

                                <div className="col-md-12 col-lg-12 col-xl-6 mb-3 mt-3">
                                    <label className="form-label">Select Employees</label>
                                    <select onChange={(e) => (selectEmployee(e), handleChange(e))} name='type' className="form-select employees-selection" aria-label="Default select example">
                                        <option selected value="0">All Employees</option>
                                        <option value="1">By Department</option>
                                    </select>
                                </div>
                                {selectedEmployee == 1 &&

                                    <div className="col-md-12 col-lg-12 col-xl-6 mb-3 mt-3">
                                        <select name='department_id' className="form-select employees-selection" onChange={(e) => (selectDepartments(e), handleChange(e))} aria-label="Default select example">
                                            {departments?.map((data, index) => (
                                                <option key={data.department_id} value={data.department_id} selected={index === 0}>{data.name}</option>
                                            ))}
                                            {/* <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option> */}
                                        </select>
                                    </div>
                                }
                            </div>

                            {employeeCounts !== 0 ?
                                <p className='fw-400 fs-6 mt-2'>This course would assign <b>{employeeCounts}</b> employees</p>
                                :
                                <p className='fw-400 fs-6 mt-2'>No employees assigned to this course.</p>
                            }
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
})

export default SetCourse;
