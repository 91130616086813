import React, { useEffect } from 'react';
import Chart from 'chart.js/auto';

function AreaChart({labels =[], values=[]}) {
    useEffect(() => {
        const data = {
            labels: labels,
            datasets: [
                {
                    label: 'Monthly Sign Ups',
                    data: values,
                    borderColor: 'rgba(253, 201, 58, 1)',
                    borderWidth: 2,
                    pointRadius: 0,
                    fill: {
                        target: 'origin',
                        above: 'rgba(253, 201, 58, 0.2)',
                    },
                },
                // {
                //     label: 'Total Sign Ups',
                //     data: [1000, 1100, 550, 1000],
                //     borderColor: 'rgba(255, 186, 204, 1)',
                //     borderWidth: 2,
                //     pointRadius: 0,
                //     fill: {
                //         target: 'origin',
                //         above: 'rgba(255, 99, 132, 0.2)',
                //     },
                // },
            ],
        };

        const config = {
            type: 'line',
            data: data,
            options: {
                scales: {
                    y: {
                        beginAtZero: true,
                        suggestedMin: 0,
                        suggestedMax: Math.max(...values),
                        stepSize: 500,
                        position: 'right',
                        border: {
                            dash: [10, 10],
                            color: '#ffff',
                        },
                        grid: {
                            color: '#B8CCFF',
                        },
                    },
                    x: {
                        grid: {
                            display: false,
                        },
                    },
                },
                plugins: {
                    legend: {
                        position: 'bottom',
                    },
                },
            },
        };

        const areaChart = document.getElementById('areachart');
        const myAreaChart = new Chart(areaChart, config);

        return () => {
            myAreaChart.destroy();
        };
    }, [labels,values]);
    return (
        <>
            <canvas id="areachart" height="67px" width="100%" />
        </>
    )
}

export default AreaChart