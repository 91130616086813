import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function SliderHomeSec() {
    const featuredresponsiveOptions = {
        0: {
            items: 1,
        },
    };
    return (
        <>
            <div className="courses-main">
                <div className="courses-categories d-flex justify-content-center align-items-center align-items-md-start  align-items-xl-center rounded-4 position-relative overflow-hidden pt-lg-5 pt-xl-0">
                    <OwlCarousel className='owl-theme featured-courses-carousel-2 home-text-slide' loop margin={10} nav={false} responsive={featuredresponsiveOptions}>
                        <div className="item">
                            <div className="courses-home z-3">
                                <h2 className="text-white text-center fw-400 mb-3 col-10 col-md-10 col-lg-12 col-xl-12 m-auto">
                                    Explore Our Extensive Course Categories
                                </h2>
                                <p className="text-white text-center fw-400 fs-6 pb-2 col-10 col-md-10 col-lg-12 col-xl-12 m-auto">
                                    Expand your knowledge and skills through our
                                    comprehensive courses.
                                </p>
                            </div>
                        </div>
                        <div className="item">
                            <div className="courses-home z-3">
                                <h2 className="text-white text-center fw-400 mb-3 col-10 col-md-10 col-lg-12 col-xl-12 m-auto">
                                    Explore Our Extensive Course Categories
                                </h2>
                                <p className="text-white text-center fw-400 fs-6 pb-2 col-10 col-md-10 col-lg-12 col-xl-12 m-auto">
                                    Expand your knowledge and skills through our
                                    comprehensive courses.
                                </p>
                            </div>
                        </div>
                        <div className="item">
                            <div className="courses-home z-3">
                                <h2 className="text-white text-center fw-400 mb-3 col-10 col-md-10 col-lg-12 col-xl-12 m-auto">
                                    Explore Our Extensive Course Categories
                                </h2>
                                <p className="text-white text-center fw-400 fs-6 pb-2 col-10 col-md-10 col-lg-12 col-xl-12 m-auto">
                                    Expand your knowledge and skills through our
                                    comprehensive courses.
                                </p>
                            </div>
                        </div>
                    </OwlCarousel>
                    <img
                        src="./gif/ezgif.com-rotate.gif"
                        className="courses-gift"
                        alt=""
                    />
                    <img
                        src="./admin-images/png/dashbaord-vector-1.png"
                        className="dashbaord-vector-1 d-none d-md-block"
                        alt=""
                    />
                    <img
                        src="./admin-images/png/dashbaord-vector-2.png"
                        className="dashbaord-vector-2 d-none d-md-block"
                        alt=""
                    />
                    <img
                        src="./svg/juicy-online-presentation.svg"
                        className="juicy-online"
                        alt=""
                    />
                </div>
            </div>
        </>
    )
}

export default SliderHomeSec