import React from 'react';
import { Bar } from 'react-chartjs-2';

function ProgressBarChart() {
  const labels = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
  const bar_data = {
    labels: labels,
    datasets: [
      {
        label: 'My First Dataset',
        data: [19, 16, 19, 16, 15, 24, 30, 10, 5, 15, 8, 19, 22, 5, 17, 5, 10, 30, 8, 6],
        backgroundColor: [
          '#6F52ED',
          '#FF7A00',
          '#6F52ED',
          '#FF7A00',
          '#6F52ED',
          '#FF7A00',
          '#6F52ED',
          '#6F52ED',
          '#FF7A00',
          '#6F52ED',
          '#FF7A00',
          '#6F52ED',
          '#FF7A00',
          '#6F52ED',
          '#6F52ED',
          '#FF7A00',
          '#6F52ED',
          '#FF7A00',
          '#6F52ED',
          '#FF7A00',
        ],
        borderRadius: 100,
        barThickness: 20,
        borderSkipped: false,
        barPercentage: 0.25,
        categoryPercentage: 0.5,
      },
    ],
  };

  const bar_config = {
    type: 'bar',
    data: bar_data,
    options: {
      scales: {
        y: {
          beginAtZero: true,
          stepSize: 5,
          border: {
            dash: [10, 10],
            color: '#ffff',
          },
          grid: {
            color: '#B8CCFF',
          },
        },
        x: {
          ticks: {
            display: false,
          },
          grid: {
            display: false,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
    },
  };

  return (
    <>
      <Bar height={100} data={bar_data} options={bar_config.options} />
    </>
  )
}

export default ProgressBarChart 