import React, { useState } from "react";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import { Card, Col, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

function BusinessUserIndividualizedCourses() {
    const location = useLocation();
    const navigate = useNavigate();
    const [activeTrails, setActiveTrails] = useState([]);

    const handleCheckboxChange = (id) => {
        // Check if the checkbox is already active
        if (activeTrails.includes(id)) {
            // If it's active, remove it from the list
            setActiveTrails(activeTrails.filter((trailId) => trailId !== id));
        } else {
            // If not, check if we can add it
            if (activeTrails.length < 2) {
                setActiveTrails([...activeTrails, id]);
            }
        }
    };

    const trailsData = [
        {
            id: 1,
            title: "Targeted Skill Development",
            description:
                "Customized courses ensure that training is directly relevant to the specific skills and knowledge needed within each department, leading to more effective learning outcomes.",
            imageSrc: "/png/trails1.png",
        },
        {
            id: 2,
            title: "Increased Employee Engagement",
            description:
                "Tailoring content to the unique challenges and goals of each team fosters higher levels of engagement, as employees see the direct applicability of their training.",
            imageSrc: "/png/trails2.png",
        },
        {
            id: 3,
            title: "Enhanced Productivity",
            description:
                "By aligning training with industry-specific demands, employees can apply their new skills more immediately, resulting in improved productivity and performance.",
            imageSrc: "/png/trails3.png",
        },
        {
            id: 4,
            title: "Better Retention of Knowledge",
            description:
                "Individualized courses can incorporate real-world scenarios and case studies relevant to the organization, helping employees retain information and apply it effectively.",
            imageSrc: "/png/trails4.png",
        },
        {
            id: 5,
            title: "Flexibility and Adaptability",
            description:
                "Custom courses can evolve with changing industry trends and departmental needs, ensuring ongoing relevance and maximizing return on investment in training.",
            imageSrc: "/png/trails5.png",
        },
        {
            id: 6,
            title: "Stronger Organizational Culture",
            description:
                "Investing in tailored learning opportunities demonstrates a commitment to employee development, fostering a positive workplace culture and improving retention rates.",
            imageSrc: "/png/trails6.png",
        },
    ];

    const handleNavigate = (to) => {
        if (to === "activate") {
            navigate("/business-blended-learning", {
                state: {
                    name: location?.state?.name,
                    plan: location?.state?.plan,
                    userPlan: location?.state?.userPlan,
                    courseTrails: location?.state?.courseTrails,
                    supportPlan: location?.state?.supportPlan,
                    sharedCourses: location?.state?.sharedCourses,
                    // individualizedCourses: {
                    //     name: "Completely Individualized Courses for Your Company and Industry",
                    //     amount: 10000,
                    //     additional: 10,
                    // },
                },
            });
        } else {
            navigate("/business-user-payment", {
                state: {
                    name: location?.state?.name,
                    plan: location?.state?.plan,
                    userPlan: location?.state?.userPlan,
                    courseTrails: location?.state?.courseTrails,
                    supportPlan: location?.state?.supportPlan,
                    sharedCourses: location?.state?.sharedCourses,
                    blendedLearning: [],
                },
            });
            // navigate("/business-home");
        }
    };

    return (
        <>
            <Header />
            <section className="payment-flow-section">
                <div className="container">
                    <div className="available-trails pb-0 pb-lg-5 pt-0">
                        <div className="row">
                            <div className="col-12 col-xl-8">
                                <h3 className="welcome-title max-width-100">
                                    Completely Individualized Courses for Your
                                    Company and Industry
                                </h3>
                            </div>
                        </div>
                        <p className="welcome-description plan-description">
                            Take your company's training to the next level with
                            courses designed specifically for your industry,
                            departments, and unique challenges. Our fully
                            customized courses provide targeted skill
                            development to ensure maximum engagement and better
                            learning outcomes.
                        </p>

                        <h2 className="text-black fw-500">Pricing</h2>
                        <div>
                            <h4 className="fw-300 text-black mb-3">
                                Initial Setup:{" "}
                                <span className="fs-2 fw-500">
                                    €10,000{" "}
                                    <span className="fw-400">per year</span>
                                </span>
                            </h4>
                            <h4 className="fw-300 text-black ">
                                Additional:{" "}
                                <span className="fs-2 fw-500">
                                    €10{" "}
                                    <span className="fw-400">
                                        per employee per year
                                    </span>
                                </span>
                            </h4>
                        </div>
                        <form className="row form-main payment-buttons my-4 my-md-5">
                            <div className="col-md-12">
                                <div className="d-md-flex align-items-center gap-3">
                                    <button
                                        type="button"
                                        className="btn-primary rounded-2 fw-400"
                                        onClick={() =>
                                            handleNavigate("activate")
                                        }
                                    >
                                        Activate Individualized Courses
                                    </button>

                                    <button
                                        className="btn-explore rounded-2 fw-400 d-inline-block mt-3 mt-md-0"
                                        onClick={() => handleNavigate("skip")}
                                    >
                                        Skip to Payment Details
                                    </button>
                                </div>
                            </div>
                        </form>
                        <h2 className="fw-500 text-black">
                            Benefits of Individualized Courses for Companies
                        </h2>
                        <Row className="available-trails-cards g-3 pt-4 individualized-courses">
                            {trailsData.map((trail) => (
                                <Col key={trail.id} md={6} xl={4}>
                                    <Card className="bg-transparent shadow-none border-0">
                                        <Card.Body className="p-0">
                                            <img
                                                className="m-auto d-flex"
                                                width={130}
                                                height={130}
                                                src={trail.imageSrc}
                                                alt={trail.title}
                                            />
                                            <div>
                                                <h5>{trail.title}</h5>
                                                <p>{trail.description}</p>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default BusinessUserIndividualizedCourses;
