import React from "react";
import ReactApexChart from "react-apexcharts";

const ColumnChart = ({ data , label}) => {

     console.log("data",data)
    const series = [
        {
            name: label[0],
            group: label[0],
            data: data.compliance,
        },
        {
            group: label[0],
            data: [100, 100, 100, 100, 100, 100, 100],
        },
        {
            name: label[1],
            group: label[1],
            data: data.skills,
        },
        {
            group: label[1],
            data: [100, 100, 100, 100, 100, 100, 100],
        },
        {
            name: label[2],
            group: label[2],
            data: data.fun
        },
        {
            group: label[2],
            data: [100, 100, 100, 100, 100, 100, 100],
        },
    ];

    const options = {
        chart: {
            type: "bar",
            height: 300,
            stacked: true,
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                columnWidth: "30%",
                borderRadius: 5,
                borderRadiusWhenStacked: "all",
            },
        },
        dataLabels: {
            enabled: false,
        },
        colors: [
            "#d2e23d",
            "#EDF2F7",
            "#b8ccff",
            "#EDF2F7",
            "#ff5a59",
            "#EDF2F7",
        ],
        grid: {
            show: false,
        },
        xaxis: {
            categories: data.months,
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            labels: {
                style: {
                    colors: "#718096",
                    fontSize: "12px",
                    fontWeight: 600,
                },
            },
        },
        yaxis: {
            title: {
                show: false,
            },
            labels: {
                formatter: function (value) {
                    return `${value}%`;
                },
                style: {
                    colors: "#425466",
                    fontSize: "11px",
                    fontWeight: 600,
                },
            },
            min: 0,
            max: 100,
            tickAmount: 5,
        },
        fill: {
            opacity: 1,
        },
        legend: {
            position: "top",
            horizontalAlign: "left",
            fontSize: "14px",
            fontWeight: 500,
            fontFamily: "Poppins, sans-serif",
            labels: {
                colors: "#425466",
            },
            markers: {
                size: 6,
                shape: "round",
            },
        },
    };

    return (
        <div className="course-chart">
            <ReactApexChart
                options={options}
                series={series}
                type="bar"
                height={350}
            />
        </div>
    );
};

export default ColumnChart;
