import React from 'react'
import AccessCourse from './AccessCourse'
import { getImageBaseUrl } from '../helper/envConfig'

const CourseCardCompornant = ({ data,url }) => {
   const imageUrl = getImageBaseUrl()
  
  return (
    <div className="card featured-card border-0 position-relative accordion-items-shadow">
      <div className="row g-0">
        <div className="col-12 col-lg-6">
          <div className="card h-100 border-0 rounded-end-0">
            <img
              src={imageUrl + data?.image || "./featured-courses/featured01.jpg"}
              className="featured-courses2-img rounded-start-2"
              alt=""
            />

            {/* <span className="badge badge-warning bg-warning position-absolute top-0 start-0">
                Mini Courses
              </span> */}

            <span className="badge badge-warning bg-warning position-absolute top-0 start-0">
              {data.type_name}
            </span>

          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="card h-100 border-0 rounded-end-2 rounded-start-0">
            <div className="card-body p-3 p-md-4 p-lg-4 p-xl-5">
              <span className="badge badge-light rounded-2 fw-400 fs-6">
                {data?.category_name}
              </span>
              <h5>{data?.name}</h5>
              {/* <p className="d-flex align-items-center gap-2 fw-500 text-dark mt-3 mb-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={30}
            height={30}
            viewBox="0 0 30 30"
            fill="none"
          >
            <circle cx={15} cy={15} r={15} fill="#D9D9D9" />
          </svg>
          Professor Sophia Lee
        </p> */}
              <div className="row justify-content-between align-items-center mt-4 mb-2">
                <p className="fw-500 text-black mb-0 col-7 col-md-6 col-lg-7">
                  Beginner Boost
                </p>
                <div className="col-5 col-md-6 col-lg-5 d-flex justify-content-between align-items-center">
                  <ul className="rectangle rectangle3 d-flex align-items-center gap-2 gap-md-3 flex-wrap mb-0 p-0">
                    <li className="active" />
                    <li />
                    <li />
                    <li />
                  </ul>
                </div>
              </div>
              {/* <div className="row justify-content-between align-items-center mb-2">
          <p className="fw-500 text-black col-md-6 col-7 col-lg-7 mb-0">
            Pro Membership
          </p>
          <div className="d-flex align-items-center gap-2 col-md-6 col-lg-5 col-5">
            <img
              src="./featured-courses/diamond.jpg"
              className="wh-25"
              alt=""
            />
            <p className="mb-0 text-black fw-600">Premium Pass</p>
          </div>
        </div>
        <div className="row justify-content-between align-items-center mb-2">
          <p className="fw-500 text-black col-7 col-md-6 col-lg-7 mb-0">
            Duration
          </p>
          <div className="d-flex align-items-center gap-2 col-md-6 col-lg-5 col-5">
            <img
              src="./featured-courses/diamond.jpg"
              className="wh-25"
              alt=""
            />
            <p className="mb-0 text-black fw-600">5 Hours</p>
          </div>
        </div> */}

              <AccessCourse url={url} is_accessible={data?.is_accessible} />

            </div>
          </div>
        </div>
        <img src="../admin-images/png/bookmark2.png" class="bookmark2" alt=""></img>
      </div>
    </div>
  )
}

export default CourseCardCompornant