import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
const CategoriesCard = () => {
    const responsiveOptions = {
        0: {
            items: 1,
        },

        600: {
            items: 2,
        },

        1000: {
            items: 2,
        },

        1440: {
            items: 4,
        },
    };
    return (
        <>
            <section>
                <div className="categories-main pt-0 pt-md-4">
                    <div className="container-fluid">
                        <div className="section-title col-md-8 col-lg-8 col-xl-6 m-auto">
                            <span className="d-block">Knowledge Hub</span>
                            <h2>Explore Our Extensive Course Categories</h2>
                        </div>
                        <OwlCarousel className='owl-theme owl-carousel categories-owl' loop margin={10} nav={false} center={true} responsive={responsiveOptions}>
                            <div className="item">
                                <div className="card border-0 shadow-sm">
                                    <div className="card-body p-4 p-md-5">
                                        <img
                                            src="./individual-pricing/categories-owl-img.png"
                                            className="card-img-top"
                                            alt="..."
                                        />
                                        <h5 className="text-primary fw-400 my-4">
                                            I absolutely love this platform!{" "}
                                        </h5>
                                        <p className="text-muted fw-400">
                                            The courses are engaging and well-structured, making learning
                                            enjoyable. The 'Personalized Learning' feature is fantastic;
                                            it tailored the content to my pace, and I felt supported
                                            throughout my learning journey. Highly recommend!
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="card border-0 shadow-sm">
                                    <div className="card-body p-4 p-md-5">
                                        <img
                                            src="./individual-pricing/categories-owl-img.png"
                                            className="card-img-top"
                                            alt="..."
                                        />
                                        <h5 className="text-primary fw-400 my-4">
                                            I absolutely love this platform!{" "}
                                        </h5>
                                        <p className="text-muted fw-400">
                                            The courses are engaging and well-structured, making learning
                                            enjoyable. The 'Personalized Learning' feature is fantastic;
                                            it tailored the content to my pace, and I felt supported
                                            throughout my learning journey. Highly recommend!
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="card border-0 shadow-sm">
                                    <div className="card-body p-4 p-md-5">
                                        <img
                                            src="./individual-pricing/categories-owl-img.png"
                                            className="card-img-top"
                                            alt="..."
                                        />
                                        <h5 className="text-primary fw-400 my-4">
                                            I absolutely love this platform!{" "}
                                        </h5>
                                        <p className="text-muted fw-400">
                                            The courses are engaging and well-structured, making learning
                                            enjoyable. The 'Personalized Learning' feature is fantastic;
                                            it tailored the content to my pace, and I felt supported
                                            throughout my learning journey. Highly recommend!
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="card border-0 shadow-sm">
                                    <div className="card-body p-4 p-md-5">
                                        <img
                                            src="./individual-pricing/categories-owl-img.png"
                                            className="card-img-top"
                                            alt="..."
                                        />
                                        <h5 className="text-primary fw-400 my-4">
                                            I absolutely love this platform!{" "}
                                        </h5>
                                        <p className="text-muted fw-400">
                                            The courses are engaging and well-structured, making learning
                                            enjoyable. The 'Personalized Learning' feature is fantastic;
                                            it tailored the content to my pace, and I felt supported
                                            throughout my learning journey. Highly recommend!
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="card border-0 shadow-sm">
                                    <div className="card-body p-4 p-md-5">
                                        <img
                                            src="./individual-pricing/categories-owl-img.png"
                                            className="card-img-top"
                                            alt="..."
                                        />
                                        <h5 className="text-primary fw-400 my-4">
                                            I absolutely love this platform!{" "}
                                        </h5>
                                        <p className="text-muted fw-400">
                                            The courses are engaging and well-structured, making learning
                                            enjoyable. The 'Personalized Learning' feature is fantastic;
                                            it tailored the content to my pace, and I felt supported
                                            throughout my learning journey. Highly recommend!
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="card border-0 shadow-sm">
                                    <div className="card-body p-4 p-md-5">
                                        <img
                                            src="./individual-pricing/categories-owl-img.png"
                                            className="card-img-top"
                                            alt="..."
                                        />
                                        <h5 className="text-primary fw-400 my-4">
                                            I absolutely love this platform!{" "}
                                        </h5>
                                        <p className="text-muted fw-400">
                                            The courses are engaging and well-structured, making learning
                                            enjoyable. The 'Personalized Learning' feature is fantastic;
                                            it tailored the content to my pace, and I felt supported
                                            throughout my learning journey. Highly recommend!
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </OwlCarousel>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CategoriesCard
