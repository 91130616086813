import React from 'react'
import Header from '../layout/Header'
import Footer from '../layout/Footer'
import CourseSidebar from '../components/CourseSidebar'
import { Link } from 'react-router-dom'

function SriLankaCourse() {
    return (
        <>
            <Header />
            <section>
                <div className="featured-main">
                    <div className="container">
                        <div className="breadcrumb-main">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-3 mb-md-5">
                                    <li className="breadcrumb-item">
                                        <Link to="">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="">Technology</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="">Mini Course</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        Fraud Prevention
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        <div className="row justify-content-between align-items-center mb-4 mb-md-5 mb-lg-6">
                            <div className="col-12 col-md-12 col-lg-6">
                                <div className="d-flex align-items-center gap-3">
                                    <span className="badge badge-black text-white rounded-2 fw-400 fs-7 mb-4">
                                        IT Security
                                    </span>
                                    <span class="badge badge-silver rounded-2 fw-400 fs-7 mb-4">Silver</span>
                                </div>
                                <h2 className="text-black fw-400">
                                    Sri Lanka: The Island of Serendipity
                                </h2>
                            </div>
                            <div className="col-12 col-md-12 col-lg-6 mt-4">
                                <div className="row justify-content-end">
                                    <div className="col-lg-12 col-xl-8">
                                        <div className="row justify-content-between align-items-center g-0 mb-2">
                                            <p className="col-6 col-md-7 fw-500 text-black mb-0 fs-7 fs-sm-7">
                                                Advanced Achievers
                                            </p>
                                            <div className="col-6 col-md-5 d-flex align-items-center">
                                                <ul className="rectangle rectangle2 d-flex align-items-center gap-2 gap-md-3 flex-wrap mb-0 p-0">
                                                    <li className="active" />
                                                    <li className="active" />
                                                    <li className="active" />
                                                    <li className="active" />
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="row justify-content-between align-items-center g-0 mb-2">
                                            <p className="col-6 col-md-7 fw-400 text-black mb-0 fs-7 fs-sm-7">
                                                Pro Membership
                                            </p>
                                            <div className="col-6 col-md-5 d-flex align-items-center gap-2">
                                                <img
                                                    src="featured-courses/diamond.jpg"
                                                    className="wh-20"
                                                    alt=""
                                                />
                                                <p className="mb-0 text-black fw-500 fs-7 fs-sm-7">
                                                    Premium Pass
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row justify-content-end align-items-center g-0 mb-2">
                                            <p className="col-6 col-md-7 fw-400 text-black mb-0 fs-7 fs-sm-7">
                                                Duration
                                            </p>
                                            <div className="col-6 col-md-5 d-flex align-items-center gap-2">
                                                <img
                                                    src="featured-courses/hourglass.png"
                                                    className="wh-20"
                                                    alt=""
                                                />
                                                <p className="mb-0 text-black fw-500 fs-7 fs-sm-7">5 Hours</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row g-4 g-md-3 g-lg-4 g-xl-5">
                            <div className="col-md-12 col-lg-8">
                                <div className="card featured-card overflow-hidden bg-transparent border-0 mb-0 mb-md-4 mb-lg-5">
                                    <img
                                        src="./featured-courses/featured-details-05.jpg"
                                        className="featured-details-img"
                                        alt=""
                                    />
                                    <p className="text-muted fw-500 fs-8 mb-6 mt-3">Credit : Janens Jacobs</p>
                                    <div className="card-body px-0 pt-4 pt-md-5">
                                        <h3 className="text-black fw-500 mb-4">The Tsunami Rescue of 2004</h3>
                                        <p className="text-muted fw-400 fs-6">
                                            &nbsp;In 2004, Sri Lanka was hit by a massive tsunami caused by the
                                            Indian Ocean earthquake. Despite the devastation, there were stories of
                                            resilience and humanity. For instance, a British tourist named Paul
                                            Evans credited a young Sri Lankan named Kamal Samarakoon with saving his
                                            life. Kamal warned Paul about the approaching wave and both ran to
                                            safety. Paul later started a fundraising campaign to help Kamal and his
                                            village rebuild their lives.
                                        </p>
                                    </div>
                                </div>
                                <div className="card featured-card overflow-hidden bg-transparent border-0 mb-0 mb-md-4 mb-lg-5">
                                    <img
                                        src="./featured-courses/featured-details-06.jpg"
                                        className="featured-details-img"
                                        alt=""
                                    />
                                    <div className="card-body px-0 pt-3">
                                        <p className="text-muted fw-500 fs-8 mb-6 mb-5">Credit : Farath Firav</p>
                                        <h3 className="text-black fw-500 mb-4 pt-3">
                                            The Tale of Elephant Nandi
                                        </h3>
                                        <p className="text-muted fw-400 fs-6">
                                            &nbsp;Nandi, an elephant, made international headlines in the 1990s when
                                            she fell into an open agricultural well in a remote Sri Lankan village.
                                            The rescue mission lasted for days and involved multiple agencies.
                                            Locals and international media watched with bated breath, and when she
                                            was finally pulled to safety, it was celebrated worldwide.
                                        </p>
                                    </div>
                                </div>
                                <h3 className="text-black fw-400 mb-4">Facts with Numbers</h3>
                                <div className="row g-4">
                                    <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                                        <div className="card numbers-card text-start rounded-3 shadow- border-0 mb-5 mb-lg-6 h-100">
                                            <div className="card-body p-4 p-md-5">
                                                <div>
                                                    <p className="fw-400 fs-6">Sri Lanka has a literacy rate of over</p>
                                                    <h2>91%,</h2>
                                                    <p className="fw-400 fs-6">
                                                        which is one of the highest rates in the South Asian region.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                                        <div className="card numbers-card text-start rounded-3 shadow- border-0 mb-5 mb-lg-6 h-100">
                                            <div className="card-body p-4 p-md-5">
                                                <div>
                                                    <p className="fw-400 fs-6">
                                                        The island nation boasts of eight UNESCO World Heritage Sites,
                                                        drawing tourists and history enthusiasts from all around the
                                                        world.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row justify-content-end mb-2 my-md-4 my-lg-5">
                                    <div className="col-6 col-md-4 col-lg-4 col-xl-3">
                                        <Link
                                            to="/course-details"
                                            type="button"
                                            className="btn-explore d-flex justify-content-between align-items-center gap-3 rounded-2 w-100 mt-4"
                                        >
                                            Next
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width={22}
                                                height={14}
                                                viewBox="0 0 22 14"
                                                fill="none"
                                            >
                                                <path
                                                    d="M1 6.99854H21L16 1"
                                                    stroke="white"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M21 6.99805L16 12.9995"
                                                    stroke="white"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                />
                                            </svg>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-4">
                                <CourseSidebar />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default SriLankaCourse