import React, { useEffect, useState } from 'react'
import { Table, Pagination, Form } from 'react-bootstrap';
import DynamicPagination from "../../components/DynamicPagination"
function PreviewTable({ record, deleterecord, currentPage, setCurrentPage, rowsPerPage, setRowsPerPage }) {

    const [goToPageNumber, setGoToPageNumber] = useState('');
    
    // const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);


    // State for tracking window width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const isMobile = windowWidth <= 768; // Or whatever your mobile breakpoint is

    // Update windowWidth when the window is resized
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        setTotalPages(Math.ceil(record.total / rowsPerPage));
    }, [record.total, rowsPerPage]);

    const handleSelectChange = (e) => {
        setRowsPerPage(Number(e.target.value));
        setCurrentPage(1); // Reset to first page after changing row size
    };


    const handleGoToPageChange = (e) => {
        setGoToPageNumber(e.target.value);
    };

    const handleGoToPage = (e) => {
        e.preventDefault();

        const pageNumber = parseInt(goToPageNumber, 10);
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }

    };

    return (
        <>
            <div className="row justify-content-center">
                <h4 className='fw-600 text-bl text-center mb-3'>Preview</h4>
                <p className='text-center text-muted fw-400 pb-1'>Confirm each item attribute is matched to the correct column in your file</p>
                <p className='text-center text-muted fw-400 pb-3'> <strong>Note : </strong> If any data appears highlighted in red, it indicates errors or inaccuracies. Please review and correct these entries before uploading the file again. Alternatively, if correction is not feasible, please delete the highlighted data before proceeding.</p>

                <div className="col-11 overflow-x-auto p-0">
                    <Table bordered className='preview-table table'>
                        <thead>
                            <tr>
                                <th>S No.</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Branch</th>
                                <th>Country</th>
                                <th>Department</th>
                                <th>Email</th>
                                <th>Employment Type</th>
                                <th>Contractor</th>
                                <th>Line Manager Email</th>
                                <th>Line Manager First Name</th>
                                <th>Line Manager Last Name</th>
                                <th>Date of Birth Year</th>
                                <th>Start Date Year</th>
                                <th>Is Exists</th>
                                <th className='detele-width'></th>

                            </tr>
                        </thead>
                        <tbody>
                            {record?.results?.employees.map((row, index) => (
                                <tr key={row.id || index}>
                                    <td>{(currentPage - 1) * rowsPerPage + index + 1}</td>
                                    <td className={(!row.is_user_exists && !row.status?.first_name) || row.is_user_exists ? 'red-background' : ''}>
                                        {row.first_name}
                                    </td>
                                    <td className={(!row.is_user_exists && !row.status?.last_name) || row.is_user_exists ? 'red-background' : ''}>
                                        {row.last_name}
                                    </td>
                                    <td className={(!row.is_user_exists && !row.status?.branch) || row.is_user_exists ? 'red-background' : ''}>
                                        {row.branch}
                                    </td>

                                    <td className={(!row.is_user_exists && !row.status?.country) || row.is_user_exists ? 'red-background' : ''}>
                                        {row.country}
                                    </td>

                                    <td className={(!row.is_user_exists && !row.status?.department) || row.is_user_exists ? 'red-background' : ''}>
                                        {row.department}
                                    </td>

                                    <td className={(!row.is_user_exists && !row.status?.email) || row.is_user_exists ? 'red-background' : ''}>
                                        {row.email}
                                    </td>

                                    <td className={(!row.is_user_exists && !row.status?.employment_type) || row.is_user_exists ? 'red-background' : ''}>
                                        {row.employment_type}
                                    </td>

                                    <td className={(!row.is_user_exists && !row.status?.contractor) || row.is_user_exists ? 'red-background' : ''}>
                                        {row.contractor}
                                    </td>


                                    <td className={(!row.is_user_exists && !row.status?.line_manager_email) || row.is_user_exists ? 'red-background' : ''}>
                                        {row.line_manager_email}
                                    </td>
                                    <td className={(!row.is_user_exists && !row.status?.line_manager_email) || row.is_user_exists ? 'red-background' : ''}>
                                        {row.line_manager_first_name}
                                    </td>
                                    <td className={(!row.is_user_exists && !row.status?.line_manager_email) || row.is_user_exists ? 'red-background' : ''}>
                                        {row.line_manager_last_name}
                                    </td>


                                    <td className={(!row.is_user_exists && !row.status?.date_of_birth_year) || row.is_user_exists ? 'red-background' : ''}>
                                        {row.date_of_birth_year}
                                    </td>

                                    <td className={(!row.is_user_exists && !row.status?.start_date_year) || row.is_user_exists ? 'red-background' : ''}>
                                        {row.start_date_year}
                                    </td>

                                    <td>
                                        <div className='d-flex justify-content-center align-items-center'>
                                            {row.is_user_exists ?
                                                <svg width="20" height="20" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill="#70bf2b" d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z" />
                                                </svg> :
                                                <svg width="20" height="20" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill="#dd4646" d="M1277 1122q0-26-19-45l-181-181 181-181q19-19 19-45 0-27-19-46l-90-90q-19-19-46-19-26 0-45 19l-181 181-181-181q-19-19-45-19-27 0-46 19l-90 90q-19 19-19 46 0 26 19 45l181 181-181 181q-19 19-19 45 0 27 19 46l90 90q19 19 46 19 26 0 45-19l181-181 181 181q19 19 45 19 27 0 46-19l90-90q19-19 19-46zm387-226q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z" />
                                                </svg>
                                            }
                                        </div>
                                    </td>
                                    <td>
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <button className='border-0 bg-transparent' type='button' onClick={() => deleterecord(row.email)}>
                                                <img src="./png/pajamas_remove.png" alt="Remove" />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
                <div className="col-12 d-flex justify-content-center mt-4">
                    <Pagination className='table-pagination align-items-center'>
                        {!isMobile && (
                            <li className="page-item">
                                <a className="page-link w-auto h-auto" role="button" tabIndex="0">
                                    Rows per page
                                    <Form.Select aria-label="Rows per page" className='select-number' value={rowsPerPage} onChange={handleSelectChange}>
                                        <option value="10">10</option>
                                        <option value="15">15</option>
                                        <option value="20">20</option>
                                        <option value="25">25</option>
                                    </Form.Select>
                                </a>
                            </li>
                        )}
                        {
                            totalPages >= 2 &&
                            <>
                                <DynamicPagination
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    onPageChange={(newPage) => {
                                        setCurrentPage(newPage);
                                    }}
                                />
                                <div className='ms-0 ms-md-5 d-flex'>
                                    <a class="page-link w-auto h-auto border-0 bg-transparent d-none d-lg-block" role="button" tabindex="0" >
                                        <span aria-hidden="true" className='text-muted fw-400 fs-7 text-nowrap'>Go to page</span>
                                    </a>
                                    <input className='form-control border-0 border-bottom rounded-0 w-50 d-none d-lg-block'
                                        type="number"
                                        min="1" // Minimum value as 1 since page numbers start from 1
                                        max={totalPages} // Maximum value as the total number of pages
                                        onChange={handleGoToPageChange} value={goToPageNumber}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                handleGoToPage(e);
                                            }
                                        }}
                                    />
                                    <div className='ms-3 cursor-pointer d-none d-lg-block'>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={(e) => handleGoToPage(e)}>
                                            <path d="M10.0037 6L8.59375 7.41L13.1737 12L8.59375 16.59L10.0037 18L16.0037 12L10.0037 6Z" fill="#242731" />
                                        </svg>
                                    </div>
                                </div>

                            </>


                        }



                    </Pagination>
                </div>
            </div>

        </>
    );
}

export default PreviewTable;