// import React, { useEffect, useRef } from 'react';
// import Chart from 'chart.js/auto'; // Import chart.js

// const ComplianceScoreChart = ({data}) => {
//  const chartRef = useRef(null);

//  useEffect(() => {
//     const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
//     const data = {
//       labels: labels,
//       datasets: [{
//         label: 'Sales',
//         data: [30, 59, 80, 81, 56, 55, 40, 30, 80, 50, 55, 25],
//         fill: false,
//         borderColor: '#5A6ACF',
//         tension: 0.1,
//         pointRadius: 0
//       }]
//     };

//     const config = {
//       type: 'line',
//       data: data,
//       options: {
//         scales: {
//           y: {
//             beginAtZero: true,
//             stepSize: 20,
//             max: 100,
//             border: {
//               dash: [5,5],
//               color:'#ffff'
//             },
//             grid:{
//               color: '#B8CCFF',
//             }
//           },
//           x: {
//             grid: {
//               display: false, // Hide X axis grid lines
//             },
//           }
//         },
//         plugins: {
//           legend: {
//             display: false // Set display to false to hide the datasets label
//           }
//         }
//       }
//     };

//     const ctx = chartRef.current.getContext("2d");
//     new Chart(ctx, config);

//  }, []); // Empty dependency array ensures that this effect runs only once after the initial render

//  return (
//     <div>
//       <canvas ref={chartRef}></canvas>
//     </div>
//  );
// }

// export default ComplianceScoreChart;

import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto'; // Import chart.js

const ComplianceScoreChart = ({ data }) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null); // To hold the chart instance

  useEffect(() => {
    if (!data || !data.completed_courses_month_wise) return;

    const monthNames = ["January", "February", "March", "April", "May", "June", 
                        "July", "August", "September", "October", "November", "December"];

    // Prepare the dataset
    const chartData = monthNames.map(month => data.completed_courses_month_wise[month] || 0);

    const chartLabels = monthNames;
    const dataset = [{
      label: 'Completed Courses',
      data: chartData,
      fill: false,
      borderColor: '#5A6ACF',
      tension: 0.1,
      pointRadius: 3
    }];

    const config = {
      type: 'line',
      data: {
        labels: chartLabels,
        datasets: dataset,
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
            stepSize: 1,
            max: Math.max(...chartData) + 1, // Ensure the maximum value is always above the highest point
          },
          x: {
            grid: {
              display: false, // Hide X axis grid lines
            },
          }
        },
        plugins: {
          legend: {
            display: false // Hide the legend
          }
        }
      }
    };

    // Destroy the old chart instance if exists and create a new one
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    const ctx = chartRef.current.getContext("2d");
    chartInstance.current = new Chart(ctx, config);

  }, [data]); // Dependency on data ensures effect runs when data changes

  return (
    <div>
      <canvas ref={chartRef}></canvas>
    </div>
  );
}

export default ComplianceScoreChart;
