import React from 'react'
import { Doughnut } from 'react-chartjs-2';
function DoughnutChart({ height, width , labels, data, centerFieldName = "", centerFieldValue = "", }) {
    const doughnutOptions = {
        plugins: {
            legend: {
                display: false,
            },
        },
        cutout: '80%',
        animation: {
            onComplete: function () {
              const ctx = this.ctx;
              ctx.font = '400 20px sans-serif'; // Reduced font size for visibility
              ctx.fillStyle = 'black';
              ctx.textAlign = 'center';
              ctx.textBaseline = 'middle';
              const totalText = centerFieldName;
              const textX = (this.chartArea.left + this.chartArea.right) / 2;
              const textY = (this.chartArea.top + this.chartArea.bottom) / 2;
              ctx.fillText(totalText, textX, textY);
              ctx.fillText(centerFieldValue, textX , textY + 23);
            }
          }
    };

    const doughnutData = {
        labels: labels,
        datasets: [
            {
                data: data,
                backgroundColor: ['#B8C4FF', '#D2E23D', '#FF5A59',],
                borderWidth: 2, // Set the borderWidth to add a gap between segments
            },
        ],
    };
    return (
        <>
            <Doughnut height={height} width={width} data={doughnutData} options={doughnutOptions} />
        </>
    )
}

export default DoughnutChart