import React, { useState } from "react";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import { Card, Col, Row } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function BusinessUserBlendedLearning() {
    const location = useLocation();
    const navigate = useNavigate();

    const trailsData = [
        {
            id: 1,
            title: "Enhanced Retention Rates",
            description:
                "Blended learning combines in-person interaction with online resources, leading to improved retention of key concepts and skills within your workforce.",
            imageSrc: "/png/trails7.png",
        },
        {
            id: 2,
            title: "Adaptability to Learning Styles",
            description:
                "This approach caters to diverse learning preferences, ensuring all employees—from visual learners to hands-on practitioners—benefit from a tailored educational experience.",
            imageSrc: "/png/trails8.png",
        },
        {
            id: 3,
            title: "Increased Employee Performance",
            description:
                "Research shows that organizations utilizing blended learning often see higher performance metrics, as employees engage more effectively and apply their knowledge with greater proficiency.",
            imageSrc: "/png/trails9.png",
        },
        {
            id: 4,
            title: "Real-Time Feedback and Support",
            description:
                "The integration of face-to-face sessions allows for immediate feedback, facilitating quicker problem-solving and skill mastery among team members.",
            imageSrc: "/png/trails10.png",
        },
        {
            id: 5,
            title: "Strengthened Team Collaboration",
            description:
                "Blended learning fosters social interaction, enhancing teamwork and communication skills that are critical for organizational success.",
            imageSrc: "/png/trails11.png",
        },
    ];

    const [quantity1, setQuantity1] = useState(0); // Default quantity
    const [quantity2, setQuantity2] = useState(0); // Default quantity
    const [quantity3, setQuantity3] = useState(0); // Default quantity

    const [total1, setTotal1] = useState(0);
    const [total2, setTotal2] = useState(0);
    const [total3, setTotal3] = useState(0);

    const price1 = 200;
    const price2 = 200;
    const price3 = 1000;

    const increaseQuantity = (index) => {
        if (index === 1) {
            const newQuantity = quantity1 + 1; // Increase quantity
            const total = newQuantity * price1; // Calculate total
            setQuantity1(newQuantity);
            setTotal1(total);
        } else if (index === 2) {
            const newQuantity = quantity2 + 1; // Increase quantity
            const total = newQuantity * price2; // Calculate total
            setQuantity2(newQuantity);
            setTotal2(total);
        } else {
            const newQuantity = quantity3 + 1; // Increase quantity
            const total = newQuantity * price3; // Calculate total
            setQuantity3(newQuantity);
            setTotal3(total);
        }
    };

    const decreaseQuantity = (index) => {
        if (index === 1) {
            const newQuantity = Math.max(quantity1 - 1, 0); // Decrease quantity with min 0
            const total = newQuantity * price1; // Calculate total
            setQuantity1(newQuantity);
            setTotal1(total);
        } else if (index === 2) {
            const newQuantity = Math.max(quantity2 - 1, 0); // Decrease quantity with min 0
            const total = newQuantity * price2; // Calculate total
            setQuantity2(newQuantity);
            setTotal2(total);
        } else {
            const newQuantity = Math.max(quantity3 - 1, 0); // Decrease quantity with min 0
            const total = newQuantity * price3; // Calculate total
            setQuantity3(newQuantity);
            setTotal3(total);
        }
    };

    const handleNavigate = (to) => {
        if (to === "activate") {
            if (total1 === 0 && total2 === 0 && total3 === 0) {
                toast.error("Please select at least one pricing.");
                return;
            }

            const blendedLearning = [];

            if (total1 > 0) {
                blendedLearning.push({
                    title: "Management Board Training on MS Teams",
                    name: "MBT",
                    perPrice : price1,
                    quantity: quantity1,
                    type: "hours",
                    total: total1,
                });
            }
            if (total2 > 0) {
                blendedLearning.push({
                    title: "Employee/Team Training on MS Teams",
                    name: "ETT",
                    perPrice : price2,
                    quantity: quantity2,
                    type: "hours",
                    total: total2,
                });
            }
            if (total3 > 0) {
                blendedLearning.push({
                    title: "One Full-Day Workshop",
                    name:"OFDW",
                    perPrice : price3,
                    quantity: quantity3,
                    type: "day",
                    total: total3,
                });
            }

            navigate("/business-user-payment", {
                state: {
                    name: location?.state?.name,
                    plan: location?.state?.plan,
                    userPlan: location?.state?.userPlan,
                    courseTrails: location?.state?.courseTrails,
                    supportPlan: location?.state?.supportPlan,
                    sharedCourses: location?.state?.sharedCourses,
                    blendedLearning: blendedLearning
                },
            });
        } else {
            navigate("/business-user-payment", {
                state: {
                    name: location?.state?.name,
                    plan: location?.state?.plan,
                    userPlan: location?.state?.userPlan,
                    courseTrails: location?.state?.courseTrails,
                    supportPlan: location?.state?.supportPlan,
                    sharedCourses: location?.state?.sharedCourses,
                    blendedLearning: [],
                },
            });
        }
    };

    return (
        <>
            <Header />
            <section className="payment-flow-section">
                <div className="container">
                    <div className="available-trails pb-0 pb-lg-5 pt-0">
                        <div className="row">
                            <div className="col-8">
                                <h3 className="welcome-title max-width-100">
                                    Blended Learning: Combine In-Person and
                                    Online Training for Maximum Impact
                                </h3>
                            </div>
                        </div>
                        <p className="welcome-description plan-description">
                            Enhance your company's training strategy by
                            combining the flexibility of online learning with
                            the effectiveness of in-person instruction. Our
                            blended learning approach ensures that all employees
                            benefit from tailored educational experiences,
                            regardless of their preferred learning style.
                        </p>

                        <h2 className="text-black fw-500">Pricing</h2>
                        <div className="row mt-4 g-4">
                            <div className="col-md-6 col-lg-4">
                                <h3 className="fw-500 text-bl mb-3">
                                    Management Board Training on MS Teams
                                </h3>
                                <h4 className="text-bl fw-400 mb-4">
                                    Price: <span className="fw-600">€200</span>{" "}
                                    per hour
                                </h4>
                                <p className="text-black fw-500">
                                    Select number of hours
                                </p>
                                <div className="price-quantity d-flex align-items-center gap-3 mb-4">
                                    <div
                                        className="quantity d-flex justify-content-center align-items-center cursor-pointer"
                                        onClick={() => decreaseQuantity(1)}
                                    >
                                        <img
                                            src="../png/minus.png"
                                            alt="Minus"
                                        />
                                    </div>
                                    <p className="fs-5 text-bl fw-400 mb-0 quantity bg-transparent d-flex justify-content-center align-items-center">
                                        {quantity1}
                                    </p>
                                    <div
                                        className="quantity d-flex justify-content-center align-items-center cursor-pointer"
                                        onClick={() => increaseQuantity(1)}
                                    >
                                        <img
                                            src="../png/plush.png"
                                            alt="Plus"
                                        />
                                    </div>
                                </div>
                                <h4 className="text-bl fw-400 mb-4">
                                    Total:{" "}
                                    <span className="fw-600">€{total1}</span>
                                </h4>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <h3 className="fw-500 text-bl mb-3">
                                    Employee/Team Training on MS Teams
                                </h3>
                                <h4 className="text-bl fw-400 mb-4">
                                    Price: <span className="fw-600">€200</span>{" "}
                                    per hour
                                </h4>
                                <p className="text-black fw-500">
                                    Select number of hours
                                </p>
                                <div className="price-quantity d-flex align-items-center gap-3 mb-4">
                                    <div
                                        className="quantity d-flex justify-content-center align-items-center cursor-pointer"
                                        onClick={() => decreaseQuantity(2)}
                                    >
                                        <img
                                            src="../png/minus.png"
                                            alt="Minus"
                                        />
                                    </div>
                                    <p className="fs-5 text-bl fw-400 mb-0 quantity bg-transparent d-flex justify-content-center align-items-center">
                                        {quantity2}
                                    </p>
                                    <div
                                        className="quantity d-flex justify-content-center align-items-center cursor-pointer"
                                        onClick={() => increaseQuantity(2)}
                                    >
                                        <img
                                            src="../png/plush.png"
                                            alt="Plus"
                                        />
                                    </div>
                                </div>
                                <h4 className="text-bl fw-400 mb-4">
                                    Total:{" "}
                                    <span className="fw-600">€{total2}</span>
                                </h4>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <h3 className="fw-500 text-bl mb-3">
                                    One Full-Day Workshop
                                </h3>
                                <h4 className="text-bl fw-400 mb-4">
                                    Price:{" "}
                                    <span className="fw-600">€1,000</span> per
                                    day
                                </h4>
                                <p className="text-black fw-500">
                                    Select number of day
                                </p>
                                <div className="price-quantity d-flex align-items-center gap-3 mb-4">
                                    <div
                                        className="quantity d-flex justify-content-center align-items-center cursor-pointer"
                                        onClick={() => decreaseQuantity(3)}
                                    >
                                        <img
                                            src="../png/minus.png"
                                            alt="Minus"
                                        />
                                    </div>
                                    <p className="fs-5 text-bl fw-400 mb-0 quantity bg-transparent d-flex justify-content-center align-items-center">
                                        {quantity3}
                                    </p>
                                    <div
                                        className="quantity d-flex justify-content-center align-items-center cursor-pointer"
                                        onClick={() => increaseQuantity(3)}
                                    >
                                        <img
                                            src="../png/plush.png"
                                            alt="Plus"
                                        />
                                    </div>
                                </div>
                                <h4 className="text-bl fw-400 mb-4">
                                    Total:{" "}
                                    <span className="fw-600">€{total3}</span>
                                </h4>
                            </div>
                        </div>
                        <form className="row form-main payment-buttons my-4 my-md-5">
                            <div className="col-md-12">
                                <div className="d-md-flex align-items-center gap-3">
                                    <button
                                        type="button"
                                        className="btn-primary rounded-2 fw-400"
                                        onClick={() =>
                                            handleNavigate("activate")
                                        }
                                    >
                                        Activate Blended Learning
                                    </button>
                                    <button
                                        to="/business-user-payment"
                                        className="btn-explore rounded-2 fw-400 d-inline-block mt-3 mt-md-0"
                                        onClick={() => handleNavigate("skip")}
                                    >
                                        Skip This Pricing Step
                                    </button>
                                </div>
                            </div>
                        </form>
                        <h2 className="fw-500 text-black">
                            Benefits of Blended Learning
                        </h2>
                        <Row className="available-trails-cards g-3 pt-4 individualized-courses">
                            {trailsData.map((trail) => (
                                <Col key={trail.id} md={6} xl={4}>
                                    <Card className="bg-transparent shadow-none border-0">
                                        <Card.Body className="p-0">
                                            <img
                                                className="m-auto d-flex"
                                                width={130}
                                                height={130}
                                                src={trail.imageSrc}
                                                alt={trail.title}
                                            />
                                            <div>
                                                <h5>{trail.title}</h5>
                                                <p>{trail.description}</p>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default BusinessUserBlendedLearning;
