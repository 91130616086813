import React, { forwardRef, useImperativeHandle, useState } from 'react'

const FeedbackScores =  forwardRef(({ setFilterFeedbackScore, handleToggle }, ref) => {
    // State to keep track of selected statuses
    const [selectedFeedBack, setSelectedFeedBack] = useState([]);

    // Function to handle button clicks
    const handleClick = (rating) => {
       if(selectedFeedBack.includes(rating)) {
         setSelectedFeedBack(selectedFeedBack.filter(score => score !== rating))
       } else {
         setSelectedFeedBack([...selectedFeedBack, rating])
       }
    };

    const statuses = [
        { key: '1', label: '5 Stars' , value: 5},
        { key: '2', label: '4 Stars' , value: 4},
        { key: '3', label: '3 Stars' , value: 3},
        { key: '4', label: '2 stars' , value: 2},
        { key: '5', label: '1 Star' , value: 1},
    ];

    const onApplyClick = () => {
        setFilterFeedbackScore(selectedFeedBack);
        handleToggle("feedbackScores")
    };


    useImperativeHandle(ref, () => ({
        resetFilter: () => {
            setFilterFeedbackScore([]);
            setSelectedFeedBack([]);
        }
    }));

    return (
        <>
            <div className="select-user-status-modal">
                <div className='p-4'>
                    <h2>Select Feedback Scores</h2>

                    <div className='d-flex align-items-center user-status-buttons gap-2 mt-4 flex-wrap'>
                        {statuses.map((status) => (
                            <button
                                key={status.key}
                                className={selectedFeedBack.includes(status.value) ? 'active' : ''}
                                onClick={() => handleClick(status.value)}
                            >
                                {status.label}
                            </button>
                        ))}
                    </div>


                </div>
                <div className="p-4 d-flex justify-content-between align-items-start border-dark-top-100">
                    <p className='pb-0 text-light-300 fw-400 fs-7'>*You can choose a multiple feedback score</p>
                    <button className='apply-btn' onClick={onApplyClick}>
                        Apply
                    </button>
                </div>
            </div>
        </>
    )
})

export default FeedbackScores
