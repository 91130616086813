import React, { useRef, useState, useEffect, useContext } from 'react'
import { CourseContext } from '../context/CourseContext';
import { getImageBaseUrl } from '../helper/envConfig';

const TopPerformers = () => {

    const { topPerformers, geTopPerformers } = useContext(CourseContext);

    useEffect(() => {
        // Call the function to get quiz results
        geTopPerformers();
    }, []); // Add serverURL to the dependency array

  return (
    <div className="col-12 col-lg-12">
    <div className="card border-0">
        <div className="card-body p-4 p-md-5">
            <p className="fw-600 fs-18 text-black mb-3">Top Performers</p>
            <div className="table-responsive">
                <table className="table top-performers-table-main align-middle border-secondary">
                    <thead>
                        <tr>
                            <th className="table-th-width-50 ps-0">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    defaultValue=""
                                    id="flexCheckDefault"
                                />
                            </th>
                            <th className="table-th-width-450">
                                <div className="d-flex justify-content-between align-items-center">
                                    <p className="ps-0 ps-md-2">Student</p>
                                    {/* <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                    >
                                        <path
                                            d="M12 5V19"
                                            stroke="black"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M19 12L12 19L5 12"
                                            stroke="black"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg> */}
                                </div>
                            </th>
                            {/* <th>
                                <p className="ps-0 ps-md-2">Status</p>
                            </th> */}
                            {/* <th>
                                <p className="ps-0 ps-md-2">Course</p>
                            </th> */}
                            <th className="table-th-width-100">
                                <p className="ps-0 ps-md-2">Points</p>
                            </th>
                            {/* <th className="table-th-width-167 text-center">
                                <p className="ps-0 ps-md-2">Rating</p>
                            </th> */}
                            <th className="table-th-width-152 text-center">
                                <p className="ps-0 ps-md-2">Badges</p>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {topPerformers && topPerformers.map((performerce, index) => (
                            <tr key={index}>
                                <th scope="row">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        defaultValue=""
                                        id="flexCheckDefault"
                                    />
                                </th>
                                <td>
                                    <div className="row">
                                        <div className="col-12 overflow-hidden">
                                            <div className="d-flex align-items-center gap-2">
                                                <img
                                                    src="admin-images/png/toppers1.png"
                                                    alt=""
                                                    className="toppers"
                                                />
                                                <div className="student-details">
                                                    <p className="fw-400 fs-7 text-bl mb-1">
                                                        {performerce.username}
                                                    </p>
                                                    <span>
                                                        I neglected to mention that I definitely won’t
                                                        be coming to the party on Saturday
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                {/* <td>
                                <button className="status-btn-1">Enrolled</button>
                            </td> */}
                                {/* <td>
                                <div className="course-content">
                                    <p>UI Masterclass</p>
                                </div>
                            </td> */}
                                <td>
                                    <div className="d-flex align-items-center gap-1">
                                        <p className="loder-text mb-0 pe-2">{performerce?.total_points === null ? 0 : performerce?.total_points}</p>
                                        {/* <svg
                                        role="progressbar"
                                        width={30}
                                        height={30}
                                        viewBox="0 0 100 100"
                                        aria-valuemin={0}
                                        aria-valuemax={100}
                                        aria-valuenow={75}
                                    >
                                        <circle
                                            cx="50%"
                                            cy="50%"
                                            r={42}
                                            shapeRendering="geometricPrecision"
                                            fill="none"
                                            stroke="#f2f7c5"
                                            strokeWidth={10}
                                        />
                                        <circle
                                            cx="50%"
                                            cy="50%"
                                            r={42}
                                            shapeRendering="geometricPrecision"
                                            className="global-circle-1"
                                            fill="none"
                                            strokeWidth={10}
                                            strokeDashoffset={66}
                                            strokeDasharray={264}
                                            strokeLinecap="round"
                                            style={{
                                                transform: "rotate(-90deg)",
                                                transformOrigin: "50% 50%",
                                                transition:
                                                    "stroke-dashoffset 1s ease-out"
                                            }}
                                            stroke="#D2E23D"
                                            data-angel={75}
                                        />
                                    </svg> */}
                                        <img
                                            src="admin-images/png/trophy.png"
                                            className=""
                                            alt=""
                                        />
                                    </div>
                                </td>
                                {/* <td className='ratingText'>
                                9.3
                            </td> */}
                                <td>
                                    <div className='image-stack'>
                                        {performerce?.badges && performerce?.badges.map((badge, index) => (
                                            <img key={index} src={getImageBaseUrl() + badge.image} alt={badge.name} />
                                        ))}
                                    </div>
                                </td>
                            </tr>
                        ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
  )
}

export default TopPerformers