import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import TostMessage from './TostMessage';
import { getServerURL } from '../../../helper/envConfig';
import api from '../../../helper/api';
import { toast } from 'react-toastify';

function UpdatePermissions({ user_ids, roles, getUserList}) {
    const serverURL = getServerURL();
    const [show, setShow] = useState(false);
    const [role, setRole] = useState(null)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [isTostMessageVisible, setIsTostMessageVisible] = useState(false);


    const onChangeRole = (e) => {
        setRole(e.target.value)
    }

    const handleSendCommunication = async() => {
        try {

            if (!role) {
                toast.error("please select role")
                return;
            }
            const res = await api.postWithToken(`${serverURL}admin-panel/user-management/roles-list`, {users_id_list: user_ids,  "role_id": role})
            setIsTostMessageVisible(true);
            toast.success(res.data.message)
            setShow(false)
            getUserList()
        } catch (error) {
            console.log(error)
        }
    };


    return (
        <>
            <button className='update-permissions' onClick={handleShow}>
                <img src="./admin/update-permissions.png" alt="" />
                Update Permissions
            </button>

            <Modal show={show} className='table-bottom-modal' onHide={handleClose} size="md"
                centered>
                <Modal.Header closeButton className='border-0 pb-0 px-4'>
                    <Modal.Title className='text-light-400 fs-18'>Change Role from Learner to</Modal.Title>
                </Modal.Header>
                <Modal.Body className='p-4 pt-0'>
                    <div className='format-export-radio mt-4'>
                        { roles.map((role, index) => {
                            return (
                                <label className='d-flex align-items-center mb-3' key={index}>
                                    <input type="radio" class="input-radio-box on" name="pilih" value={role.role_id} onChange={onChangeRole}  /> {role.role_name}
                                </label>
                            )
                        })}
                        {/* <label className='d-flex align-items-center mb-3'><input type="radio" class="input-radio-box on" name="pilih" /> Admin</label> */}
                        {/* <label className='d-flex align-items-center'><input type="radio" class="input-radio-box on" name="pilih" /> Educator</label> */}
                    </div>
                </Modal.Body>
                <Modal.Footer className='border-dark-top-100'>
                    <div className='d-flex justify-content-end gap-3'>
                        {/* <button className='apply-btn-outline ' onClick={handleSendCommunication}>
                            Cancel
                        </button> */}
                        <button className='apply-btn ' onClick={handleSendCommunication}>
                            Apply
                        </button>
                    </div>
                </Modal.Footer>
            </Modal >

            <div className={isTostMessageVisible ? 'd-block' : 'd-none'}>
                <TostMessage setShow={setIsTostMessageVisible} msg={"Roles are updated successfully"} />
            </div>
        </>
    )
}

export default UpdatePermissions