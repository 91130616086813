import React, { forwardRef, useImperativeHandle, useState } from "react";

const CourseManagement = forwardRef(({ courseCategory, setFilterCourseCategory ,handleToggle}, ref) => {
    const [selectedStatuses, setSelectedStatuses] = useState([]);

    const handleClick = (id) => {
        if (selectedStatuses.includes(id)) {
            const newIds = [...selectedStatuses];
            newIds.splice(newIds.indexOf(id), 1);
            setSelectedStatuses(newIds);
        } else {
            setSelectedStatuses([...selectedStatuses, id]);
        }
    };

    const onApplyClick = () => {
        setFilterCourseCategory(selectedStatuses);
        handleToggle("category")
    };

    useImperativeHandle(ref, () => ({
        resetFilter: () => {
            setFilterCourseCategory([]);
            setSelectedStatuses([null]);
        }
    }));

    return (
        <>
            <div className="select-user-status-modal select-category-modal">
                <div className="p-4">
                    <h2>Select Category</h2>

                    <div className="d-flex align-items-center user-status-buttons gap-2 mt-4 flex-wrap">
                        {courseCategory &&
                            courseCategory.length > 0 &&
                            courseCategory.map(
                                (sub, subIndex) =>
                                    sub.subcategories &&
                                    sub.subcategories.map(
                                        (status, statusIndex) => (
                                            <button
                                                key={statusIndex}
                                                className={
                                                    selectedStatuses.includes(
                                                        status.category_id
                                                    )
                                                        ? "active"
                                                        : ""
                                                }
                                                onClick={() =>
                                                    handleClick(
                                                        status.category_id
                                                    )
                                                }
                                            >
                                                {status.name}
                                            </button>
                                        )
                                    )
                            )}
                    </div>
                </div>
                <div className="p-4 d-flex justify-content-between align-items-start border-dark-top-100">
                    <p className="pb-0 text-light-300 fw-400 fs-7">
                        *You can choose multiple category
                    </p>
                    <button className="apply-btn" onClick={onApplyClick}>
                        Apply
                    </button>
                </div>
            </div>
        </>
    );
})

export default CourseManagement;
