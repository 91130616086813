import { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link } from 'react-router-dom';
import SidbarCourses from './SidbarCourses';

function CoursesListModal() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>

            <Link
                variant="primary" onClick={handleShow}
                className="fs-3 d-md-none offcanvasleft courses-list-left-sidebar rounded-2 col-2"
            >
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" fill="#000" y="0px" width="25" height="25" viewBox="0 0 50 50"><path d="M 5 8 A 2.0002 2.0002 0 1 0 5 12 L 45 12 A 2.0002 2.0002 0 1 0 45 8 L 5 8 z M 5 23 A 2.0002 2.0002 0 1 0 5 27 L 45 27 A 2.0002 2.0002 0 1 0 45 23 L 5 23 z M 5 38 A 2.0002 2.0002 0 1 0 5 42 L 45 42 A 2.0002 2.0002 0 1 0 45 38 L 5 38 z"></path></svg>
            </Link>

            <Offcanvas className='featured-courses-main p-0' show={show} onHide={handleClose}>
                <Offcanvas.Header className='border-bottom mx-3 px-2' closeButton>
                    <Offcanvas.Title>Featured Courses List</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <SidbarCourses/>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default CoursesListModal