import React from 'react'
import Header from '../layout/Header'
import Footer from '../layout/Footer'
import CourseSidebar from '../components/CourseSidebar'
import { Link } from 'react-router-dom'

const Compilance = () => {
    return (
        <>
            <Header />
            <section>
                <div className="featured-main">
                    <div className="container">
                        <div className="breadcrumb-main">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-3 mb-md-5">
                                    <li className="breadcrumb-item">
                                        <Link to="">Courses</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="">Technology</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="">Mini Course</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        Fraud Prevention
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        <div className="row justify-content-between align-items-center mb-4 mb-md-5">
                            <div className="col-12 col-md-12 col-lg-6">
                                <div className="d-flex align-items-center gap-3">
                                    <span className="badge badge-black text-white rounded-2 fw-400 fs-7 mb-4">
                                        IT Security
                                    </span>
                                    <span className="badge badge-gold rounded-2 fw-400 fs-7 mb-4">Gold</span>
                                </div>
                                <h2 className="text-black fw-400">Compliance in summer 2024</h2>
                            </div>
                            <div className="col-12 col-md-12 col-lg-6 mt-4">
                                <div className="row justify-content-end">
                                    <div className="col-lg-12 col-xl-8">
                                        <div className="row justify-content-between align-items-center g-0 mb-2">
                                            <p className="col-6 col-md-7 fw-500 text-black mb-0 fs-7 fs-sm-7">
                                                Advanced Achievers
                                            </p>
                                            <div className="col-6 col-md-5 d-flex align-items-center">
                                                <ul className="rectangle rectangle2 d-flex align-items-center gap-2 gap-md-3 flex-wrap mb-0 p-0">
                                                    <li className="active" />
                                                    <li className="active" />
                                                    <li className="active" />
                                                    <li className="active" />
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="row justify-content-between align-items-center g-0 mb-2">
                                            <p className="col-6 col-md-7 fw-400 text-black mb-0 fs-7 fs-sm-7">
                                                Pro Membership
                                            </p>
                                            <div className="col-6 col-md-5 d-flex align-items-center gap-2">
                                                <img
                                                    src="./featured-courses/diamond.jpg"
                                                    className="wh-20"
                                                    alt=""
                                                />
                                                <p className="mb-0 text-black fw-500 fs-7 fs-sm-7">Premium Pass</p>
                                            </div>
                                        </div>
                                        <div className="row justify-content-end align-items-center g-0 mb-2">
                                            <p className="col-6 col-md-7 fw-400 text-black mb-0 fs-7 fs-sm-7">
                                                Duration
                                            </p>
                                            <div className="col-6 col-md-5 d-flex align-items-center gap-2">
                                                <img
                                                    src="./featured-courses/hourglass.png"
                                                    className="wh-20"
                                                    alt=""
                                                />
                                                <p className="mb-0 text-black fw-500 fs-7 fs-sm-7">5 Hours</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row g-4 g-md-3  g-lg-4 g-xl-5">
                            <div className="col-md-12 col-lg-8">
                                <div className="card border-0 shadow-right">
                                    <div className="card-body p-4 p-md-5">
                                        <img
                                            src="./featured-courses/gift.gif"
                                            className="run-gift"
                                            alt=""
                                        />
                                        <h4 className="text-black fw-500 mt-4">How to Start</h4>
                                        <p className="text-muted fs-6 fw-400 mt-4 pb-4">
                                            You will be receiving a 1-week course in Munich in personam. You can
                                            also dial in <span className="text-primary fw-400 fs-7">online.</span>{" "}
                                            The course is limited to 20 people in-personam and 20 people online. You
                                            will receive the training materials. At the end there is a 2-hour
                                            test.&nbsp;&nbsp;
                                        </p>
                                        <Link
                                            to="/users-sign-up"
                                            className="btn-explore rounded-2 fs-18 fw-600"
                                        >
                                           <span>Get Started</span>
                                            <svg className='ms-2'
                                                xmlns="http://www.w3.org/2000/svg"
                                                width={22}
                                                height={14}
                                                viewBox="0 0 22 14"
                                                fill="none"
                                            >
                                                <path
                                                    d="M1 6.99854H21L16 1"
                                                    stroke="white"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M21 6.99805L16 12.9995"
                                                    stroke="white"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                />
                                            </svg>
                                        </Link>
                                    </div>
                                </div>
                                <div className="card border-0 mt-5">
                                    <div className="card-body px-0 px-md-0">
                                        <div className="table-responsive table-responsive-xxl pricing-table">
                                            <table className="table align-middle table-sm">
                                                <thead>
                                                    <tr className="border-bottom-0 border-white">
                                                        <th className="table-th-width-350" />
                                                        <th className="fw-600 fs-18 px-0">In Person</th>
                                                        <th className="fw-600 fs-18 px-0">Online</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="ps-4">
                                                            <p className="fw-600 fs-6">1 Week Course</p>
                                                            <p className="fw-400 fs-6">
                                                                Join us for a comprehensive one-week course designed to
                                                                immerse you in a rich learning experience whether you attend
                                                                in person in Munich or participate online.
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={41}
                                                                height={40}
                                                                viewBox="0 0 41 40"
                                                                fill="none"
                                                            >
                                                                <mask
                                                                    id="mask0_257_338"
                                                                    style={{ maskType: "alpha" }}
                                                                    maskUnits="userSpaceOnUse"
                                                                    x={0}
                                                                    y={0}
                                                                    width={41}
                                                                    height={40}
                                                                >
                                                                    <rect x="0.166748" width={40} height={40} fill="#D9D9D9" />
                                                                </mask>
                                                                <g mask="url(#mask0_257_338)">
                                                                    <path
                                                                        d="M16.0835 30.0002L6.5835 20.5002L8.9585 18.1252L16.0835 25.2502L31.3752 9.9585L33.7502 12.3335L16.0835 30.0002Z"
                                                                        fill="#D2E23D"
                                                                    />
                                                                </g>
                                                            </svg>
                                                        </td>
                                                        <td>
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={41}
                                                                height={40}
                                                                viewBox="0 0 41 40"
                                                                fill="none"
                                                            >
                                                                <mask
                                                                    id="mask0_257_338"
                                                                    style={{ maskType: "alpha" }}
                                                                    maskUnits="userSpaceOnUse"
                                                                    x={0}
                                                                    y={0}
                                                                    width={41}
                                                                    height={40}
                                                                >
                                                                    <rect x="0.166748" width={40} height={40} fill="#D9D9D9" />
                                                                </mask>
                                                                <g mask="url(#mask0_257_338)">
                                                                    <path
                                                                        d="M16.0835 30.0002L6.5835 20.5002L8.9585 18.1252L16.0835 25.2502L31.3752 9.9585L33.7502 12.3335L16.0835 30.0002Z"
                                                                        fill="#D2E23D"
                                                                    />
                                                                </g>
                                                            </svg>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="ps-4">
                                                            <p className="fw-600 fs-6">Training Materials</p>
                                                            <p className="fw-400 fs-6">
                                                                These materials are carefully curated to enhance your
                                                                understanding of the subject matter and serve as valuable
                                                                resources for future reference.
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={41}
                                                                height={40}
                                                                viewBox="0 0 41 40"
                                                                fill="none"
                                                            >
                                                                <mask
                                                                    id="mask0_257_338"
                                                                    style={{ maskType: "alpha" }}
                                                                    maskUnits="userSpaceOnUse"
                                                                    x={0}
                                                                    y={0}
                                                                    width={41}
                                                                    height={40}
                                                                >
                                                                    <rect x="0.166748" width={40} height={40} fill="#D9D9D9" />
                                                                </mask>
                                                                <g mask="url(#mask0_257_338)">
                                                                    <path
                                                                        d="M16.0835 30.0002L6.5835 20.5002L8.9585 18.1252L16.0835 25.2502L31.3752 9.9585L33.7502 12.3335L16.0835 30.0002Z"
                                                                        fill="#D2E23D"
                                                                    />
                                                                </g>
                                                            </svg>
                                                        </td>
                                                        <td>
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={41}
                                                                height={40}
                                                                viewBox="0 0 41 40"
                                                                fill="none"
                                                            >
                                                                <mask
                                                                    id="mask0_257_338"
                                                                    style={{ maskType: "alpha" }}
                                                                    maskUnits="userSpaceOnUse"
                                                                    x={0}
                                                                    y={0}
                                                                    width={41}
                                                                    height={40}
                                                                >
                                                                    <rect x="0.166748" width={40} height={40} fill="#D9D9D9" />
                                                                </mask>
                                                                <g mask="url(#mask0_257_338)">
                                                                    <path
                                                                        d="M16.0835 30.0002L6.5835 20.5002L8.9585 18.1252L16.0835 25.2502L31.3752 9.9585L33.7502 12.3335L16.0835 30.0002Z"
                                                                        fill="#D2E23D"
                                                                    />
                                                                </g>
                                                            </svg>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="ps-4">
                                                            <p className="fw-600 fs-6">2 Hour Test</p>
                                                            <p className="fw-400 fs-6">
                                                                The two-hour test is the concluding assessment of the course,
                                                                allowing you to demonstrate your proficiency in the subject
                                                                matter.
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={41}
                                                                height={40}
                                                                viewBox="0 0 41 40"
                                                                fill="none"
                                                            >
                                                                <mask
                                                                    id="mask0_257_338"
                                                                    style={{ maskType: "alpha" }}
                                                                    maskUnits="userSpaceOnUse"
                                                                    x={0}
                                                                    y={0}
                                                                    width={41}
                                                                    height={40}
                                                                >
                                                                    <rect x="0.166748" width={40} height={40} fill="#D9D9D9" />
                                                                </mask>
                                                                <g mask="url(#mask0_257_338)">
                                                                    <path
                                                                        d="M16.0835 30.0002L6.5835 20.5002L8.9585 18.1252L16.0835 25.2502L31.3752 9.9585L33.7502 12.3335L16.0835 30.0002Z"
                                                                        fill="#D2E23D"
                                                                    />
                                                                </g>
                                                            </svg>
                                                        </td>
                                                        <td>
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={41}
                                                                height={40}
                                                                viewBox="0 0 41 40"
                                                                fill="none"
                                                            >
                                                                <mask
                                                                    id="mask0_257_338"
                                                                    style={{ maskType: "alpha" }}
                                                                    maskUnits="userSpaceOnUse"
                                                                    x={0}
                                                                    y={0}
                                                                    width={41}
                                                                    height={40}
                                                                >
                                                                    <rect x="0.166748" width={40} height={40} fill="#D9D9D9" />
                                                                </mask>
                                                                <g mask="url(#mask0_257_338)">
                                                                    <path
                                                                        d="M16.0835 30.0002L6.5835 20.5002L8.9585 18.1252L16.0835 25.2502L31.3752 9.9585L33.7502 12.3335L16.0835 30.0002Z"
                                                                        fill="#D2E23D"
                                                                    />
                                                                </g>
                                                            </svg>
                                                        </td>
                                                    </tr>
                                                    <tr className="border-bottom-0 border-white">
                                                        <td />
                                                        <td>
                                                            <div className="d-flex justify-content-center">
                                                                <button type="button" className="btn-primary rounded-2 mx-2">
                                                                    5000 €
                                                                </button>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex justify-content-center">
                                                                <button type="button" className="btn-primary rounded-2 mx-2">
                                                                    3000 €
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-4">
                                <CourseSidebar />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Compilance
