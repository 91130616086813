import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, Title, Legend } from 'chart.js';

// Register required components from Chart.js
ChartJS.register(LineElement, CategoryScale, LinearScale, Title, Legend);

const AdminLineChart = ({values = []}) => {
    const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov'];

    const data = {
        labels: labels,
        datasets: [{
            label: 'My First Dataset', // Keep this if you need it for other purposes, but it won't be displayed
            data: values,
            fill: false,
            borderColor: '#FDC93A', // Line color
            borderWidth: 2, // Set to your preferred width
            tension: 0.1
        }]
    };

    const options = {
        plugins: {
            legend: {
                display: false // This hides the legend
            },
            tooltip: {
                callbacks: {
                    title: () => '', // Optional: Hide tooltip title if needed
                    label: (tooltipItem) => {
                        const { label, raw } = tooltipItem;
                        return `${label}: ${raw}`;
                    }
                }
            }
        },
        scales: {
            x: {
                grid: {
                    display: false // Remove vertical grid lines
                },
                ticks: {
                    display: true // Show x-axis labels if needed
                }
            },
            y: {
                // grid: {
                //     display: false // Remove horizontal grid lines
                // },
                ticks: {
                    display: true, // Show y-axis labels
                    stepSize: 20, // Step size between ticks
                    callback: function (value) {
                        // Custom tick labels
                        const ticks = [0, 20, 40, 60, 80];
                        return ticks.includes(value) ? value : '';
                    }
                },
                suggestedMin: 0, // Set minimum value for y-axis
                suggestedMax: 80 // Set maximum value for y-axis
            }
        }
    };

    return (
        <div>
            <Line data={data} height={120} options={options} />
        </div>
    );
};

export default AdminLineChart;
