import React, { useState } from 'react'

function SelectRoleModal({ roles, setFilterRoles, handleToggle}) {
    // State to keep track of selected roles
    const [selectedRoles, setSelectedRoles] = useState([]);

    // Function to handle button clicks
    const handleClick = (role) => {
        setSelectedRoles(prevSelectedRoles =>
            prevSelectedRoles.includes(role)
                ? prevSelectedRoles.filter(r => r !== role) // Remove role if already selected
                : [...prevSelectedRoles, role] // Add role if not selected
        );
    };

    const onApplyClick = () => {
        setFilterRoles(selectedRoles);
        handleToggle("userRole")
    }


    return (
        <>
            <div className="select-user-status-modal">
                <div className='p-4'>
                    <h2>Select Role</h2>

                    <div className='d-flex flex-wrap align-items-center user-status-buttons gap-3 mt-4'>
                        {roles.map((role, index)=> {
                            return (
                                <button
                                    key={index}
                                    className={selectedRoles.includes(role.role_id)? 'active' : ''}
                                    onClick={() => handleClick(role.role_id)}
                                >
                                    {role.role_name}
                                </button>
                            )
                        }) }
                    </div>
                </div>
                <div className="p-4 d-flex justify-content-between align-items-start border-dark-top-100">
                    <p className='pb-0 text-light-300 fw-400 fs-7'>*You can choose multiple User Roles</p>
                    <button className='apply-btn' onClick={onApplyClick}>
                        Apply
                    </button>
                </div>
            </div>
        </>
    )
}

export default SelectRoleModal
