
// // api.js
// export const getServerURL = () => {
//   return process.env.REACT_APP_API_BASE_URL_TEST;
// };

// //REACT_APP_API_BASE_URL                - local
// //REACT_APP_API_BASE_URL_TEST           - test dev
// //REACT_APP_API_BASE_URL_PRODUCTION     - live


// config.js or a similar utility file

export const getServerURL = () => {
  switch (process.env.REACT_APP_NODE_ENV) {
    case 'production':
      return `${process.env.REACT_APP_BASE_URL_PRODUCTION}/api/v1/`;
    case 'test':
      return `${process.env.REACT_APP_BASE_URL_TEST}/api/v1/`;
    default:
      return `${process.env.REACT_APP_BASE_URL}/api/v1/`;
  }
};

export const getImageBaseUrl = () => {
  // Assuming images are stored at the root in all environments
  // Adjust the path if images are stored at a different path
  switch (process.env.REACT_APP_NODE_ENV) {
    case 'production':
      return process.env.REACT_APP_BASE_URL_PRODUCTION;
    case 'test':
      return process.env.REACT_APP_BASE_URL_TEST;
    default:
      return process.env.REACT_APP_BASE_URL;
  }
};
