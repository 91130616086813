import React from 'react';

const StudentTestimonials = () => {
    return (
        <>
            <section>
                <div className="testimonials-main position-relative">
                    <div className="container">
                        <div className="section-title col-md-12 col-lg-6 m-auto">
                            <span className="d-block">Student Testimonials</span>
                            <h2> Hear from Our Learners!</h2>
                        </div>
                        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-3 g-4 mt-0 mt-md-5">
                            <div className="col">
                                <div className="card border-0 shadow-sm h-100">
                                    <div className="card-body">
                                        <img
                                            src="./png/twitter.png"
                                            className="card-img-top"
                                            alt="..."
                                        />
                                        <h5 className="text-black fw-600 my-4">
                                            I absolutely love this platform!{" "}
                                        </h5>
                                        <p className="text-muted fw-400">
                                            The courses are engaging and well-structured, making learning
                                            enjoyable. The 'Personalized Learning' feature is fantastic; it
                                            tailored the content to my pace, and I felt supported throughout
                                            my learning journey. Highly recommend!
                                        </p>
                                        <p className="text-primary fw-600 fs-18">Sara J.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card border-0 shadow-sm h-100">
                                    <div className="card-body">
                                        <img
                                            src="./png/twitter.png"
                                            className="card-img-top"
                                            alt="..."
                                        />
                                        <h5 className="text-black fw-600 my-4">
                                            Fun and Effective Learning
                                        </h5>
                                        <p className="text-muted fw-400">
                                            feature truly sets this platform apart. The gamified learning
                                            techniques made studying enjoyable, and I didn't feel
                                            overwhelmed with information. The instructors were
                                            knowledgeable, and the 'Stay Motivated' tools kept me on track.
                                            Thumbs up!
                                        </p>
                                        <p className="text-primary fw-600 fs-18">Sara J.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card border-0 shadow-sm h-100">
                                    <div className="card-body">
                                        <img
                                            src="./png/twitter.png"
                                            className="card-img-top"
                                            alt="..."
                                        />
                                        <h5 className="text-black fw-600 my-4">
                                            I can't say enough good things about this eLearning platform
                                        </h5>
                                        <p className="text-muted fw-400">
                                            The 'Stay Motivated' tools kept me inspired, and the positive
                                            community atmosphere encouraged me to keep pushing forward. The
                                            'Expert Excellence' courses challenged me in the best way
                                            possible. A+ platform!
                                        </p>
                                        <p className="text-primary fw-600 fs-18">Jennifer T.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card border-0 shadow-sm h-100">
                                    <div className="card-body">
                                        <img
                                            src="./png/twitter.png"
                                            className="card-img-top"
                                            alt="..."
                                        />
                                        <h5 className="text-black fw-600 my-4">
                                            I'm so glad I found this eLearning tool
                                        </h5>
                                        <p className="text-muted fw-400">
                                            The 'Stay Motivated' tools kept me inspired, and the positive
                                            community atmosphere encouraged me to keep pushing forward. The
                                            'Expert Excellence' courses challenged me in the best way
                                            possible. A+ platform!
                                        </p>
                                        <p className="text-primary fw-600 fs-18">Alex W.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card border-0 shadow-sm h-100">
                                    <div className="card-body">
                                        <img
                                            src="./png/twitter.png"
                                            className="card-img-top"
                                            alt="..."
                                        />
                                        <h5 className="text-black fw-600 my-4">
                                            As a busy professional, I appreciate the flexibility this
                                            platform offers
                                        </h5>
                                        <p className="text-muted fw-400">
                                            The 'Personalized Learning' feature allowed me to learn at my
                                            own pace, and the content was relevant and practical. The
                                            support from the community and instructors made my learning
                                            experience exceptional.
                                        </p>
                                        <p className="text-primary fw-600 fs-18">Mark L.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card border-0 shadow-sm h-100">
                                    <div className="card-body">
                                        <img
                                            src="./png/twitter.png"
                                            className="card-img-top"
                                            alt="..."
                                        />
                                        <h5 className="text-black fw-600 my-4">
                                            I absolutely love this platform!{" "}
                                        </h5>
                                        <p className="text-muted fw-400">
                                            The courses are engaging and well-structured, making learning
                                            enjoyable. The 'Personalized Learning' feature is fantastic; it
                                            tailored the content to my pace, and I felt supported throughout
                                            my learning journey. Highly recommend!
                                        </p>
                                        <p className="text-primary fw-600 fs-18">Sara J.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default StudentTestimonials;
