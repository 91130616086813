import React, { useState } from 'react';

function FileDrop() {

    return (
        <>
            <div class="row g-2 g-lg-4 pt-3">
                <div class="col-12 col-md-6 mb-4 mb-md-0 pe-0 pe-md-5">
                    <div class="drop-section p-4">
                        <div class="col">
                            <div class="file-selector">
                                <div class="cloud-icon d-flex justify-content-center">
                                    <img
                                        src="admin-images/png/upload-icon.png"
                                        class="upload-icon"
                                        alt=""
                                    />
                                </div>
                                <h4 class="text-black fw-700 fs-17 text-center mb-3">
                                    Drag &amp; drop files or
                                    <a class="border-bottom border-primary text-primary fw-700 fs-17 cursor-pointer ps-2">
                                        Browse
                                    </a>
                                </h4>
                                <p class="text-center text-muted fw-400 fs-12">
                                    Supported formats: Word
                                </p>
                            </div>
                            <input
                                type="file"
                                class="file-selector-input"
                                multiple=""
                            />
                        </div>
                        <div>
                            <div class="drop-here" />
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="list-section-main">
                        <div class="list-section">
                            <div class="list" />
                        </div>
                    </div>
                    <button
                        type="button"
                        class="btn-secondary text-secondary text-uppercase fw-700 fs-15 d-flex justify-content-center align-items-center gap-3 rounded-2 w-100"
                    >
                        Upload
                    </button>
                </div>
            </div>
        </>
    )
}

export default FileDrop