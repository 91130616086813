// import React from 'react'
import React, { useImperativeHandle, forwardRef, useEffect, useState, useContext } from 'react';
import BusinessSidebar from '../components/BusinessSidebar'
import AdminHeader from './../layout/AdminHeader';
import Footer from './../layout/Footer';
import ProgressBarChart from '../components/ProgressBarChart';
import { Link } from 'react-router-dom';
import AlertSuccess from '../components/assign-course-main/AlertSuccess';
import { CourseContext } from '../context/CourseContext';

const BusinessDashbaordHome = () => {

    const {setShowSuccess ,  ShowSuccess , message} = useContext(CourseContext);

    useEffect(() => {
        let timer;
        if (ShowSuccess) {
            timer = setTimeout(() => {
                setShowSuccess(false);
            }, 3000); // Hide after 3 seconds
        }
        return () => clearTimeout(timer); // Clean up the timer
    }, [ShowSuccess]);
    
    
    return (
        <>
            <AdminHeader />
            <section>
                <div className="dashbaord-home-main">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-3 col-md-4 col-lg-3 col-xxl-3 d-none d-xl-block px-4 bg-white sidebar-mian">
                                <BusinessSidebar />
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 col-xxl-9 bg-body">
                                <div className="row ps-0 ps-lg-4 pe-lg-4 ps-xl-5 dashbaord-right-side justify-content-md-center justify-content-lg-between pt-5">
                                    <div className="col-12 col-md-11 col-lg-12 right-side">
                                        <div className="row justify-content-between align-items-center mb-5">
                                            <div className="col-12 col-md-6 col-lg-8">
                                                <h2 className="fw-600 text-black">Your Team Progress</h2>
                                            </div>
                                        </div>

                                        <AlertSuccess message={message} show={ShowSuccess} setShow={setShowSuccess} />

                                        <div className="row justify-content-between bg-white rounded-2 mb-3 mb-md-5">
                                            <div className="col-12 px-0">
                                                <div className="row align-items-center border-bottom bg-white mb-3 p-3 g-0">
                                                    <div className="col-12 col-md-8 col-lg-9 mb-3 mb-md-0">
                                                        <p className="text-black fw-700 fs-18 mb-0">
                                                            Monthly Compliance Grade
                                                        </p>
                                                    </div>
                                                    <div className="col-12 col-md-4 col-lg-3">
                                                        <select
                                                            className="form-select w-75 rounded-5 border border-secondary f7 fw-400"
                                                            aria-label="Default select example"
                                                        >
                                                            <option selected="">October 2020</option>
                                                            <option value={1}>October 2022</option>
                                                            <option value={2}>October 2023</option>
                                                            <option value={3}>October 2024</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="card border-0">
                                                    <div className="card-body p-0 p-md-3">
                                                        <div className="overflow-auto">
                                                            <div className="barchart-progress-team">
                                                                <ProgressBarChart />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row justify-content-between bg-white p-4 pb-0 rounded-2">
                                            <div className="col-12 col-md-8 col-lg-8 col-xl-8">
                                                <div className="mb-4">
                                                    <p className="fs-18 text-bl fw-600 mb-4">Achieved</p>
                                                    <div className="row align-items-center justify-content-center justify-content-md-between animation-main p-2 p-md-3 rounded-3 zmb-4">
                                                        <div className="col-5 col-md-2 col-xl-2">
                                                            <div className="d-flex align-items-center gap-4">
                                                                <img
                                                                    src="./admin-images/png/animation-img.png"
                                                                    className="animation-img"
                                                                    alt=""
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-7 col-md-10 col-xl-10">
                                                            <div className="d-flex justify-content-between align-items-center px-0 ps-md-5 px-lg-3">
                                                                <div>
                                                                    <p className="mb-0 fs-6 fw-400 text-black">3D Animation</p>
                                                                    <p className="mb-0 fs-12 fw-400 text-muted">
                                                                        12 Mini Chapters
                                                                    </p>
                                                                </div>
                                                                <Link to="" className="docs-creator">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={5}
                                                                        height={17}
                                                                        viewBox="0 0 5 17"
                                                                        fill="none"
                                                                    >
                                                                        <path
                                                                            d="M2.17341 3.85287C2.57546 3.85287 2.96848 3.74427 3.30277 3.5408C3.63706 3.33733 3.8976 3.04813 4.05146 2.70977C4.20532 2.37141 4.24557 1.99909 4.16714 1.63989C4.0887 1.28069 3.8951 0.950743 3.61081 0.691774C3.32652 0.432805 2.96431 0.256445 2.56999 0.184995C2.17567 0.113546 1.76694 0.150216 1.3955 0.290369C1.02406 0.430522 0.706578 0.667863 0.483212 0.972378C0.259847 1.27689 0.140626 1.63491 0.140626 2.00114C0.140626 2.49225 0.354794 2.96325 0.736016 3.31051C1.11724 3.65778 1.63428 3.85287 2.17341 3.85287ZM2.17341 13.1115C1.77136 13.1115 1.37835 13.2201 1.04406 13.4236C0.709767 13.6271 0.449219 13.9163 0.295363 14.2546C0.141506 14.593 0.10125 14.9653 0.179685 15.3245C0.258121 15.6837 0.451725 16.0136 0.736016 16.2726C1.02031 16.5316 1.38251 16.7079 1.77683 16.7794C2.17116 16.8508 2.57988 16.8142 2.95132 16.674C3.32277 16.5339 3.64025 16.2965 3.86361 15.992C4.08698 15.6875 4.2062 15.3295 4.2062 14.9632C4.2062 14.4721 3.99203 14.0011 3.61081 13.6539C3.22959 13.3066 2.71254 13.1115 2.17341 13.1115ZM2.17341 6.63047C1.77136 6.63047 1.37835 6.73907 1.04406 6.94254C0.709767 7.14601 0.449219 7.43521 0.295363 7.77357C0.141506 8.11193 0.10125 8.48425 0.179685 8.84345C0.258121 9.20265 0.451725 9.5326 0.736016 9.79157C1.02031 10.0505 1.38251 10.2269 1.77683 10.2983C2.17116 10.3698 2.57988 10.3331 2.95132 10.193C3.32277 10.0528 3.64025 9.81548 3.86361 9.51096C4.08698 9.20645 4.2062 8.84843 4.2062 8.4822C4.2062 7.99109 3.99203 7.52009 3.61081 7.17283C3.22959 6.82556 2.71254 6.63047 2.17341 6.63047Z"
                                                                            fill="#646C78"
                                                                        />
                                                                    </svg>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row align-items-center justify-content-center justify-content-md-between animation-main p-2 p-md-3 rounded-3 zmb-4">
                                                        <div className="col-5 col-md-2 col-xl-2">
                                                            <div className="d-flex align-items-center gap-4">
                                                                <img
                                                                    src="./admin-images/png/animation-img.png"
                                                                    className="animation-img"
                                                                    alt=""
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-7 col-md-10 col-xl-10">
                                                            <div className="d-flex justify-content-between align-items-center px-0 ps-md-5 px-lg-3">
                                                                <div>
                                                                    <p className="mb-0 fs-6 fw-400 text-black">3D Animation</p>
                                                                    <p className="mb-0 fs-12 fw-400 text-muted">
                                                                        12 Mini Chapters
                                                                    </p>
                                                                </div>
                                                                <Link to="" className="docs-creator">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={5}
                                                                        height={17}
                                                                        viewBox="0 0 5 17"
                                                                        fill="none"
                                                                    >
                                                                        <path
                                                                            d="M2.17341 3.85287C2.57546 3.85287 2.96848 3.74427 3.30277 3.5408C3.63706 3.33733 3.8976 3.04813 4.05146 2.70977C4.20532 2.37141 4.24557 1.99909 4.16714 1.63989C4.0887 1.28069 3.8951 0.950743 3.61081 0.691774C3.32652 0.432805 2.96431 0.256445 2.56999 0.184995C2.17567 0.113546 1.76694 0.150216 1.3955 0.290369C1.02406 0.430522 0.706578 0.667863 0.483212 0.972378C0.259847 1.27689 0.140626 1.63491 0.140626 2.00114C0.140626 2.49225 0.354794 2.96325 0.736016 3.31051C1.11724 3.65778 1.63428 3.85287 2.17341 3.85287ZM2.17341 13.1115C1.77136 13.1115 1.37835 13.2201 1.04406 13.4236C0.709767 13.6271 0.449219 13.9163 0.295363 14.2546C0.141506 14.593 0.10125 14.9653 0.179685 15.3245C0.258121 15.6837 0.451725 16.0136 0.736016 16.2726C1.02031 16.5316 1.38251 16.7079 1.77683 16.7794C2.17116 16.8508 2.57988 16.8142 2.95132 16.674C3.32277 16.5339 3.64025 16.2965 3.86361 15.992C4.08698 15.6875 4.2062 15.3295 4.2062 14.9632C4.2062 14.4721 3.99203 14.0011 3.61081 13.6539C3.22959 13.3066 2.71254 13.1115 2.17341 13.1115ZM2.17341 6.63047C1.77136 6.63047 1.37835 6.73907 1.04406 6.94254C0.709767 7.14601 0.449219 7.43521 0.295363 7.77357C0.141506 8.11193 0.10125 8.48425 0.179685 8.84345C0.258121 9.20265 0.451725 9.5326 0.736016 9.79157C1.02031 10.0505 1.38251 10.2269 1.77683 10.2983C2.17116 10.3698 2.57988 10.3331 2.95132 10.193C3.32277 10.0528 3.64025 9.81548 3.86361 9.51096C4.08698 9.20645 4.2062 8.84843 4.2062 8.4822C4.2062 7.99109 3.99203 7.52009 3.61081 7.17283C3.22959 6.82556 2.71254 6.63047 2.17341 6.63047Z"
                                                                            fill="#646C78"
                                                                        />
                                                                    </svg>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                { }
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4 col-xl-4">
                                                <div className="row justify-content-md-between justify-content-xl-end gy-5">
                                                    <div className="col-12 col-xl-7">
                                                        <p className="fs-12 fw-500 text-black text-end pe-5">Week</p>
                                                        <div className="d-flex align-items-start gap-4 ga flex-wrap pt-4">
                                                            <img
                                                                src="./admin-images/png/avatar1.png"
                                                                alt=""
                                                                className="wh-40 mb-5"
                                                            />
                                                            <img
                                                                src="./admin-images/png/avatar2.png"
                                                                alt=""
                                                                className="wh-40 mb-5"
                                                            />
                                                            <img
                                                                src="./admin-images/png/avatar3.png"
                                                                alt=""
                                                                className="wh-40 mb-5"
                                                            />
                                                            <img
                                                                src="./admin-images/png/avatar4.png"
                                                                alt=""
                                                                className="wh-40 mb-5"
                                                            />
                                                            <img
                                                                src="./admin-images/png/avatar5.png"
                                                                alt=""
                                                                className="wh-40 mb-5"
                                                            />
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={11}
                                                                height={5}
                                                                viewBox="0 0 11 5"
                                                                fill="none"
                                                            >
                                                                <path
                                                                    d="M5.92239 4.73611L9.96595 1.75611C10.0553 1.69077 10.1263 1.61304 10.1747 1.52739C10.2231 1.44174 10.248 1.34988 10.248 1.2571C10.248 1.16432 10.2231 1.07245 10.1747 0.986806C10.1263 0.901159 10.0553 0.823426 9.96595 0.758089C9.78727 0.627186 9.54556 0.553711 9.29362 0.553711C9.04167 0.553711 8.79996 0.627186 8.62128 0.758089L5.24529 3.24611L1.8693 0.758088C1.69062 0.627186 1.44891 0.553711 1.19696 0.553711C0.945017 0.553711 0.703307 0.627186 0.524626 0.758088C0.436238 0.823761 0.366311 0.901646 0.318851 0.987277C0.271393 1.07291 0.247337 1.1646 0.248063 1.2571C0.247337 1.34959 0.271393 1.44129 0.318851 1.52692C0.366311 1.61255 0.436238 1.69043 0.524626 1.75611L4.56818 4.73611C4.65684 4.80198 4.76232 4.85427 4.87853 4.88995C4.99474 4.92563 5.11939 4.944 5.24529 4.944C5.37119 4.944 5.49583 4.92563 5.61205 4.88995C5.72826 4.85427 5.83374 4.80198 5.92239 4.73611Z"
                                                                    fill="#A5B4CB"
                                                                />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row justify-content-between bg-white p-4 pb-0 rounded-2">
                                            <div className="col-12 col-md-8 col-lg-8 col-xl-8">
                                                <div className="mb-4">
                                                    <p className="fs-18 text-bl fw-600 mb-4">In Progress</p>
                                                    <div className="row align-items-center justify-content-center justify-content-md-between animation-main p-2 p-md-3 rounded-3 zmb-4">
                                                        <div className="col-5 col-md-2 col-xl-2">
                                                            <div className="d-flex align-items-center gap-4">
                                                                <img
                                                                    src="./admin-images/png/animation-img.png"
                                                                    className="animation-img"
                                                                    alt=""
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-7 col-md-10 col-xl-10">
                                                            <div className="d-flex justify-content-between align-items-center px-0 ps-md-5 px-lg-3">
                                                                <div>
                                                                    <p className="mb-0 fs-6 fw-400 text-black">3D Animation</p>
                                                                    <p className="mb-0 fs-12 fw-400 text-muted">
                                                                        12 Mini Chapters
                                                                    </p>
                                                                </div>
                                                                <Link to="" className="docs-creator">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={5}
                                                                        height={17}
                                                                        viewBox="0 0 5 17"
                                                                        fill="none"
                                                                    >
                                                                        <path
                                                                            d="M2.17341 3.85287C2.57546 3.85287 2.96848 3.74427 3.30277 3.5408C3.63706 3.33733 3.8976 3.04813 4.05146 2.70977C4.20532 2.37141 4.24557 1.99909 4.16714 1.63989C4.0887 1.28069 3.8951 0.950743 3.61081 0.691774C3.32652 0.432805 2.96431 0.256445 2.56999 0.184995C2.17567 0.113546 1.76694 0.150216 1.3955 0.290369C1.02406 0.430522 0.706578 0.667863 0.483212 0.972378C0.259847 1.27689 0.140626 1.63491 0.140626 2.00114C0.140626 2.49225 0.354794 2.96325 0.736016 3.31051C1.11724 3.65778 1.63428 3.85287 2.17341 3.85287ZM2.17341 13.1115C1.77136 13.1115 1.37835 13.2201 1.04406 13.4236C0.709767 13.6271 0.449219 13.9163 0.295363 14.2546C0.141506 14.593 0.10125 14.9653 0.179685 15.3245C0.258121 15.6837 0.451725 16.0136 0.736016 16.2726C1.02031 16.5316 1.38251 16.7079 1.77683 16.7794C2.17116 16.8508 2.57988 16.8142 2.95132 16.674C3.32277 16.5339 3.64025 16.2965 3.86361 15.992C4.08698 15.6875 4.2062 15.3295 4.2062 14.9632C4.2062 14.4721 3.99203 14.0011 3.61081 13.6539C3.22959 13.3066 2.71254 13.1115 2.17341 13.1115ZM2.17341 6.63047C1.77136 6.63047 1.37835 6.73907 1.04406 6.94254C0.709767 7.14601 0.449219 7.43521 0.295363 7.77357C0.141506 8.11193 0.10125 8.48425 0.179685 8.84345C0.258121 9.20265 0.451725 9.5326 0.736016 9.79157C1.02031 10.0505 1.38251 10.2269 1.77683 10.2983C2.17116 10.3698 2.57988 10.3331 2.95132 10.193C3.32277 10.0528 3.64025 9.81548 3.86361 9.51096C4.08698 9.20645 4.2062 8.84843 4.2062 8.4822C4.2062 7.99109 3.99203 7.52009 3.61081 7.17283C3.22959 6.82556 2.71254 6.63047 2.17341 6.63047Z"
                                                                            fill="#646C78"
                                                                        />
                                                                    </svg>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row align-items-center justify-content-center justify-content-md-between animation-main p-2 p-md-3 rounded-3 zmb-4">
                                                        <div className="col-5 col-md-2 col-xl-2">
                                                            <div className="d-flex align-items-center gap-4">
                                                                <img
                                                                    src="./admin-images/png/animation-img.png"
                                                                    className="animation-img"
                                                                    alt=""
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-7 col-md-10 col-xl-10">
                                                            <div className="d-flex justify-content-between align-items-center px-0 ps-md-5 px-lg-3">
                                                                <div>
                                                                    <p className="mb-0 fs-6 fw-400 text-black">3D Animation</p>
                                                                    <p className="mb-0 fs-12 fw-400 text-muted">
                                                                        12 Mini Chapters
                                                                    </p>
                                                                </div>
                                                                <Link to="" className="docs-creator">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={5}
                                                                        height={17}
                                                                        viewBox="0 0 5 17"
                                                                        fill="none"
                                                                    >
                                                                        <path
                                                                            d="M2.17341 3.85287C2.57546 3.85287 2.96848 3.74427 3.30277 3.5408C3.63706 3.33733 3.8976 3.04813 4.05146 2.70977C4.20532 2.37141 4.24557 1.99909 4.16714 1.63989C4.0887 1.28069 3.8951 0.950743 3.61081 0.691774C3.32652 0.432805 2.96431 0.256445 2.56999 0.184995C2.17567 0.113546 1.76694 0.150216 1.3955 0.290369C1.02406 0.430522 0.706578 0.667863 0.483212 0.972378C0.259847 1.27689 0.140626 1.63491 0.140626 2.00114C0.140626 2.49225 0.354794 2.96325 0.736016 3.31051C1.11724 3.65778 1.63428 3.85287 2.17341 3.85287ZM2.17341 13.1115C1.77136 13.1115 1.37835 13.2201 1.04406 13.4236C0.709767 13.6271 0.449219 13.9163 0.295363 14.2546C0.141506 14.593 0.10125 14.9653 0.179685 15.3245C0.258121 15.6837 0.451725 16.0136 0.736016 16.2726C1.02031 16.5316 1.38251 16.7079 1.77683 16.7794C2.17116 16.8508 2.57988 16.8142 2.95132 16.674C3.32277 16.5339 3.64025 16.2965 3.86361 15.992C4.08698 15.6875 4.2062 15.3295 4.2062 14.9632C4.2062 14.4721 3.99203 14.0011 3.61081 13.6539C3.22959 13.3066 2.71254 13.1115 2.17341 13.1115ZM2.17341 6.63047C1.77136 6.63047 1.37835 6.73907 1.04406 6.94254C0.709767 7.14601 0.449219 7.43521 0.295363 7.77357C0.141506 8.11193 0.10125 8.48425 0.179685 8.84345C0.258121 9.20265 0.451725 9.5326 0.736016 9.79157C1.02031 10.0505 1.38251 10.2269 1.77683 10.2983C2.17116 10.3698 2.57988 10.3331 2.95132 10.193C3.32277 10.0528 3.64025 9.81548 3.86361 9.51096C4.08698 9.20645 4.2062 8.84843 4.2062 8.4822C4.2062 7.99109 3.99203 7.52009 3.61081 7.17283C3.22959 6.82556 2.71254 6.63047 2.17341 6.63047Z"
                                                                            fill="#646C78"
                                                                        />
                                                                    </svg>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4 col-xl-4">
                                                <div className="row justify-content-md-between justify-content-xl-end gy-5">
                                                    <div className="col-12 col-xl-7">
                                                        <p className="fs-12 fw-500 text-black text-end pe-5">Week</p>
                                                        <div className="d-flex align-items-start gap-4 ga flex-wrap pt-4">
                                                            <img
                                                                src="./admin-images/png/avatar1.png"
                                                                alt=""
                                                                className="wh-40 mb-5"
                                                            />
                                                            <img
                                                                src="./admin-images/png/avatar2.png"
                                                                alt=""
                                                                className="wh-40 mb-5"
                                                            />
                                                            <img
                                                                src="./admin-images/png/avatar3.png"
                                                                alt=""
                                                                className="wh-40 mb-5"
                                                            />
                                                            <img
                                                                src="./admin-images/png/avatar4.png"
                                                                alt=""
                                                                className="wh-40 mb-5"
                                                            />
                                                            <img
                                                                src="./admin-images/png/avatar5.png"
                                                                alt=""
                                                                className="wh-40 mb-5"
                                                            />
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={11}
                                                                height={5}
                                                                viewBox="0 0 11 5"
                                                                fill="none"
                                                            >
                                                                <path
                                                                    d="M5.92239 4.73611L9.96595 1.75611C10.0553 1.69077 10.1263 1.61304 10.1747 1.52739C10.2231 1.44174 10.248 1.34988 10.248 1.2571C10.248 1.16432 10.2231 1.07245 10.1747 0.986806C10.1263 0.901159 10.0553 0.823426 9.96595 0.758089C9.78727 0.627186 9.54556 0.553711 9.29362 0.553711C9.04167 0.553711 8.79996 0.627186 8.62128 0.758089L5.24529 3.24611L1.8693 0.758088C1.69062 0.627186 1.44891 0.553711 1.19696 0.553711C0.945017 0.553711 0.703307 0.627186 0.524626 0.758088C0.436238 0.823761 0.366311 0.901646 0.318851 0.987277C0.271393 1.07291 0.247337 1.1646 0.248063 1.2571C0.247337 1.34959 0.271393 1.44129 0.318851 1.52692C0.366311 1.61255 0.436238 1.69043 0.524626 1.75611L4.56818 4.73611C4.65684 4.80198 4.76232 4.85427 4.87853 4.88995C4.99474 4.92563 5.11939 4.944 5.24529 4.944C5.37119 4.944 5.49583 4.92563 5.61205 4.88995C5.72826 4.85427 5.83374 4.80198 5.92239 4.73611Z"
                                                                    fill="#A5B4CB"
                                                                />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row justify-content-between bg-white p-4 pb-0 rounded-2 mb-4 mb-md-5">
                                            <div className="col-12 col-md-8 col-lg-8 col-xl-8">
                                                <div className="mb-4">
                                                    <p className="fs-18 text-bl fw-600 mb-4">Pending</p>
                                                    <div className="row align-items-center justify-content-center justify-content-md-between animation-main p-2 p-md-3 rounded-3 zmb-4">
                                                        <div className="col-5 col-md-2 col-xl-2">
                                                            <div className="d-flex align-items-center gap-4">
                                                                <img
                                                                    src="./admin-images/png/animation-img.png"
                                                                    className="animation-img"
                                                                    alt=""
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-7 col-md-10 col-xl-10">
                                                            <div className="d-flex justify-content-between align-items-center px-0 ps-md-5 px-lg-3">
                                                                <div>
                                                                    <p className="mb-0 fs-6 fw-400 text-black">3D Animation</p>
                                                                    <p className="mb-0 fs-12 fw-400 text-muted">
                                                                        12 Mini Chapters
                                                                    </p>
                                                                </div>
                                                                <Link to="" className="docs-creator">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={5}
                                                                        height={17}
                                                                        viewBox="0 0 5 17"
                                                                        fill="none"
                                                                    >
                                                                        <path
                                                                            d="M2.17341 3.85287C2.57546 3.85287 2.96848 3.74427 3.30277 3.5408C3.63706 3.33733 3.8976 3.04813 4.05146 2.70977C4.20532 2.37141 4.24557 1.99909 4.16714 1.63989C4.0887 1.28069 3.8951 0.950743 3.61081 0.691774C3.32652 0.432805 2.96431 0.256445 2.56999 0.184995C2.17567 0.113546 1.76694 0.150216 1.3955 0.290369C1.02406 0.430522 0.706578 0.667863 0.483212 0.972378C0.259847 1.27689 0.140626 1.63491 0.140626 2.00114C0.140626 2.49225 0.354794 2.96325 0.736016 3.31051C1.11724 3.65778 1.63428 3.85287 2.17341 3.85287ZM2.17341 13.1115C1.77136 13.1115 1.37835 13.2201 1.04406 13.4236C0.709767 13.6271 0.449219 13.9163 0.295363 14.2546C0.141506 14.593 0.10125 14.9653 0.179685 15.3245C0.258121 15.6837 0.451725 16.0136 0.736016 16.2726C1.02031 16.5316 1.38251 16.7079 1.77683 16.7794C2.17116 16.8508 2.57988 16.8142 2.95132 16.674C3.32277 16.5339 3.64025 16.2965 3.86361 15.992C4.08698 15.6875 4.2062 15.3295 4.2062 14.9632C4.2062 14.4721 3.99203 14.0011 3.61081 13.6539C3.22959 13.3066 2.71254 13.1115 2.17341 13.1115ZM2.17341 6.63047C1.77136 6.63047 1.37835 6.73907 1.04406 6.94254C0.709767 7.14601 0.449219 7.43521 0.295363 7.77357C0.141506 8.11193 0.10125 8.48425 0.179685 8.84345C0.258121 9.20265 0.451725 9.5326 0.736016 9.79157C1.02031 10.0505 1.38251 10.2269 1.77683 10.2983C2.17116 10.3698 2.57988 10.3331 2.95132 10.193C3.32277 10.0528 3.64025 9.81548 3.86361 9.51096C4.08698 9.20645 4.2062 8.84843 4.2062 8.4822C4.2062 7.99109 3.99203 7.52009 3.61081 7.17283C3.22959 6.82556 2.71254 6.63047 2.17341 6.63047Z"
                                                                            fill="#646C78"
                                                                        />
                                                                    </svg>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row align-items-center justify-content-center justify-content-md-between animation-main p-2 p-md-3 rounded-3 zmb-4">
                                                        <div className="col-5 col-md-2 col-xl-2">
                                                            <div className="d-flex align-items-center gap-4">
                                                                <img
                                                                    src="./admin-images/png/animation-img.png"
                                                                    className="animation-img"
                                                                    alt=""
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-7 col-md-10 col-xl-10">
                                                            <div className="d-flex justify-content-between align-items-center px-0 ps-md-5 px-lg-3">
                                                                <div>
                                                                    <p className="mb-0 fs-6 fw-400 text-black">3D Animation</p>
                                                                    <p className="mb-0 fs-12 fw-400 text-muted">
                                                                        12 Mini Chapters
                                                                    </p>
                                                                </div>
                                                                <Link to="" className="docs-creator">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={5}
                                                                        height={17}
                                                                        viewBox="0 0 5 17"
                                                                        fill="none"
                                                                    >
                                                                        <path
                                                                            d="M2.17341 3.85287C2.57546 3.85287 2.96848 3.74427 3.30277 3.5408C3.63706 3.33733 3.8976 3.04813 4.05146 2.70977C4.20532 2.37141 4.24557 1.99909 4.16714 1.63989C4.0887 1.28069 3.8951 0.950743 3.61081 0.691774C3.32652 0.432805 2.96431 0.256445 2.56999 0.184995C2.17567 0.113546 1.76694 0.150216 1.3955 0.290369C1.02406 0.430522 0.706578 0.667863 0.483212 0.972378C0.259847 1.27689 0.140626 1.63491 0.140626 2.00114C0.140626 2.49225 0.354794 2.96325 0.736016 3.31051C1.11724 3.65778 1.63428 3.85287 2.17341 3.85287ZM2.17341 13.1115C1.77136 13.1115 1.37835 13.2201 1.04406 13.4236C0.709767 13.6271 0.449219 13.9163 0.295363 14.2546C0.141506 14.593 0.10125 14.9653 0.179685 15.3245C0.258121 15.6837 0.451725 16.0136 0.736016 16.2726C1.02031 16.5316 1.38251 16.7079 1.77683 16.7794C2.17116 16.8508 2.57988 16.8142 2.95132 16.674C3.32277 16.5339 3.64025 16.2965 3.86361 15.992C4.08698 15.6875 4.2062 15.3295 4.2062 14.9632C4.2062 14.4721 3.99203 14.0011 3.61081 13.6539C3.22959 13.3066 2.71254 13.1115 2.17341 13.1115ZM2.17341 6.63047C1.77136 6.63047 1.37835 6.73907 1.04406 6.94254C0.709767 7.14601 0.449219 7.43521 0.295363 7.77357C0.141506 8.11193 0.10125 8.48425 0.179685 8.84345C0.258121 9.20265 0.451725 9.5326 0.736016 9.79157C1.02031 10.0505 1.38251 10.2269 1.77683 10.2983C2.17116 10.3698 2.57988 10.3331 2.95132 10.193C3.32277 10.0528 3.64025 9.81548 3.86361 9.51096C4.08698 9.20645 4.2062 8.84843 4.2062 8.4822C4.2062 7.99109 3.99203 7.52009 3.61081 7.17283C3.22959 6.82556 2.71254 6.63047 2.17341 6.63047Z"
                                                                            fill="#646C78"
                                                                        />
                                                                    </svg>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4 col-xl-4">
                                                <div className="row justify-content-md-between justify-content-xl-end gy-5">
                                                    <div className="col-12 col-xl-7">
                                                        <p className="fs-12 fw-500 text-black text-end pe-5">Week</p>
                                                        <div className="d-flex align-items-start gap-4 ga flex-wrap pt-4">
                                                            <img
                                                                src="./admin-images/png/avatar1.png"
                                                                alt=""
                                                                className="wh-40 mb-5"
                                                            />
                                                            <img
                                                                src="./admin-images/png/avatar2.png"
                                                                alt=""
                                                                className="wh-40 mb-5"
                                                            />
                                                            <img
                                                                src="./admin-images/png/avatar3.png"
                                                                alt=""
                                                                className="wh-40 mb-5"
                                                            />
                                                            <img
                                                                src="./admin-images/png/avatar4.png"
                                                                alt=""
                                                                className="wh-40 mb-5"
                                                            />
                                                            <img
                                                                src="./admin-images/png/avatar5.png"
                                                                alt=""
                                                                className="wh-40 mb-5"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            <Footer />
        </>
    )
}

export default BusinessDashbaordHome
