import React, { useEffect, useState } from "react";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { decryptFromStoreLoc } from "../../helper/localStore";

function BusinessUserSupportPlans() {
    const location = useLocation();
    const navigate = useNavigate();
    const [user, setUser] = useState({});
    const plans = [
        {
            title: "Pay-as-you-go Hourly Support",
            price: "€ 0",
            amount: 0,
            details: [
                "On-demand technical support",
                "No monthly commitment",
                "You can request support for 300 Euros per hour",
                "Available during business hours, additional fees for after-hours",
            ],
        },
        {
            title: "Basic Email Support Plan",
            price: "€ 1,000",
            amount: 1000,
            details: [
                "Email support during business hours (e.g., 9:00 AM – 6:00 PM)",
                "Response time: 24-48 hours",
                "Any additional consultation or hands-on support: €200/hour",
            ],
        },
        {
            title: "24/7 Premium Support Plan",
            price: "€ 10,000",
            amount: 10000,
            details: [
                "24/7 support availability",
                "SLA guarantees with a 2-hour response time",
                "Email, phone, and chat support",
                "Dedicated support manager",
                "Any additional consultation or hands-on support: €150/hour",
            ],
        },
    ];

    useEffect(() => {
        const decryptedInfoResult = decryptFromStoreLoc("user");
        setUser(decryptedInfoResult);
    }, []);

    const handleNavigate = (plan, amount) => {
        if (user?.role === 12) {
            navigate("/business-shared-courses", {
                state: {
                    name: location?.state?.name,
                    plan: location?.state?.plan,
                    userPlan: location?.state?.userPlan,
                    courseTrails: location?.state?.courseTrails,
                    supportPlan: {
                        supportPlanName: plan,
                        supportPlanAmount: amount,
                    },
                },
            });
        }
    };

    return (
        <>
            <Header />
            <section className="payment-flow-section">
                <div className="container">
                    <h3 className="welcome-title max-width-100">
                        Select a Support Pricing Plan
                    </h3>
                    <p className="welcome-description plan-description">
                        Select a plan that fits your business needs.
                    </p>
                    <div className="row plans-card support-pricing-plan g-4">
                        {plans.map((plan, index) => (
                            <div
                                className="col-md-6 col-lg-6 col-xl-4"
                                key={index}
                            >
                                <div className="card border-0 h-100">
                                    <div className="card-header bg-transparent border-0 p-0">
                                        <h3 className="max-width-100">
                                            {plan.title}
                                        </h3>
                                        <h2 className="mb-0">{plan.price}</h2>
                                        <p className="employee h-auto">
                                            per month
                                        </p>
                                    </div>
                                    <div className="card-body p-0">
                                        <div className="d-flex justify-content-center align-items-center m-auto">
                                            <ul className="plan-details-list">
                                                {plan.details.map(
                                                    (detail, idx) => (
                                                        <li key={idx}>
                                                            <img
                                                                src="/admin/check.png"
                                                                alt=""
                                                            />
                                                            {detail}
                                                        </li>
                                                    )
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="card-footer bg-transparent border-0 p-0 d-flex justify-content-center">
                                        <button
                                            className="w-auto text-decoration-none"
                                            onClick={() =>
                                                handleNavigate(
                                                    plan.title,
                                                    plan.amount
                                                )
                                            }
                                        >
                                            Get Started
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default BusinessUserSupportPlans;
