import React from 'react'
import Header from '../layout/Header'
import Footer from '../layout/Footer'
import CourseSidebar from '../components/CourseSidebar'
import { Link } from 'react-router-dom'

function FeaturedGoldCourse() {
  return (
    <>
      <Header />
      <section>
        <div className="featured-main">
          <div className="container">
            <div className="breadcrumb-main">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-3 mb-md-5">
                  <li className="breadcrumb-item">
                    <Link to="">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="">Technology</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="">Mini Course</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Fraud Prevention
                  </li>
                </ol>
              </nav>
            </div>
            <div className="row justify-content-between align-items-center mb-4 mb-md-5 mb-lg-6">
              <div className="col-12 col-md-12 col-lg-6">
                <div className="d-flex align-items-center gap-3">
                  <span className="badge badge-black text-white rounded-2 fw-400 fs-7 mb-4">
                    IT Security
                  </span>
                  <span class="badge badge-light text-black rounded-2 fw-400 fs-7 mb-4">Free</span>
                </div>
                <h2 className="text-black fw-400">
                  Sri Lanka: The Island of Serendipity
                </h2>
              </div>
              <div className="col-12 col-md-12 col-lg-6 mt-4">
                <div className="row justify-content-end">
                  <div className="col-lg-12 col-xl-8">
                    <div className="row justify-content-between align-items-center g-0 mb-2">
                      <p className="col-6 col-md-7 fw-500 text-black mb-0 fs-7 fs-sm-7">
                        Advanced Achievers
                      </p>
                      <div className="col-6 col-md-5 d-flex align-items-center">
                        <ul className="rectangle rectangle2 d-flex align-items-center gap-2 gap-md-3 flex-wrap mb-0 p-0">
                          <li className="active" />
                          <li className="active" />
                          <li className="active" />
                          <li className="active" />
                        </ul>
                      </div>
                    </div>
                    <div className="row justify-content-between align-items-center g-0 mb-2">
                      <p className="col-6 col-md-7 fw-400 text-black mb-0 fs-7 fs-sm-7">
                        Pro Membership
                      </p>
                      <div className="col-6 col-md-5 d-flex align-items-center gap-2">
                        <img
                          src="./featured-courses/diamond.jpg"
                          className="wh-20"
                          alt=""
                        />
                        <p className="mb-0 text-black fw-500 fs-7 fs-sm-7">
                          Premium Pass
                        </p>
                      </div>
                    </div>
                    <div className="row justify-content-end align-items-center g-0 mb-2">
                      <p className="col-6 col-md-7 fw-400 text-black mb-0 fs-7 fs-sm-7">
                        Duration
                      </p>
                      <div className="col-6 col-md-5 d-flex align-items-center gap-2">
                        <img
                          src="./featured-courses/hourglass.png"
                          className="wh-20"
                          alt=""
                        />
                        <p className="mb-0 text-black fw-500 fs-7 fs-sm-7">5 Hours</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-4 g-md-3 g-lg-4 g-xl-5">
              <div className="col-md-12 col-lg-8">
                <div className="card featured-card overflow-hidden bg-transparent border-0 mb-0 mb-md-4 mb-lg-5">
                  <p className="text-muted fw-400 fs-6 mb-4 mb-md-5">
                    Figma is rapidly becoming the go-to tool for UI/UX design and
                    collaboration, with its intuitive features and real-time sharing
                    capabilities. The journey to mastering Figma has had its share of
                    interesting stories.
                  </p>
                  <img
                    src="./featured-courses/featured-details-03.jpg"
                    className="featured-details-img"
                    alt=""
                  />
                  <p className="text-muted fw-500 fs-8 mb-6 mt-3">Credit : Shubham Dhage</p>
                  <div className="card-body px-0 pt-4 pt-md-5">
                    <h3 className="text-black fw-400 mb-4">Learning to Work with Figma</h3>
                    <p className="text-muted fw-400 fs-6 mb-3">
                      In 2019, Uber’s design team transitioned their design system to Figma,
                      and in doing so, found a massive improvement in collaboration. They
                      could share design files instantly and get feedback immediately.
                    </p>
                    <p className="text-muted fw-400 fs-6 mb-3">
                      Dropbox, one of the leading cloud storage solutions, uses Figma to
                      accelerate their design process. They found Figma's vector networks and
                      constraints especially handy when dealing with complex illustrations and
                      responsive designs.
                    </p>
                    <p className="text-muted fw-400 fs-6 mb-3">
                      Microsoft’s design team, while creating the Fluent Design System, made
                      use of Figma's robust components, which significantly reduced the time
                      taken to make universal changes.
                    </p>
                  </div>
                </div>
                <h3 className="text-black fw-400 mb-4">Facts with Numbers</h3>
                <div className="row g-4">
                  <div className="col-12 col-md-6 col-lg-6 col-xl-4">
                    <div className="card numbers-card text-start rounded-3 shadow- border-0 mb-5 mb-lg-6 h-100">
                      <div className="card-body p-4 p-md-5">
                        <div>
                          <p className="fw-400 fs-6">
                            As of 2021, Figma is utilized by over a million designers
                            worldwide.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6 col-xl-4">
                    <div className="card numbers-card text-start rounded-3 shadow- border-0 mb-5 mb-lg-6 h-100">
                      <div className="card-body p-4 p-md-5">
                        <div>
                          <p className="fw-400 fs-6">Figma witnessed a</p>
                          <h2 className="fw-400">300%</h2>
                          <p className="fw-400 fs-6">user growth rate in 2020 alone.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6 col-xl-4">
                    <div className="card numbers-card text-start rounded-3 shadow- border-0 mb-5 mb-lg-6 h-100">
                      <div className="card-body p-4 p-md-5">
                        <div>
                          <p className="fw-400 fs-6">Over</p>
                          <h2 className="fw-400">90%</h2>
                          <p className="fw-400 fs-6">
                            of Fortune 500 companies have incorporated Figma into their design
                            workflows.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-end mb-2 my-md-4 my-lg-5">
                  <div className="col-6 col-md-4 col-lg-4 col-xl-3">
                    <Link
                      to="/gold-course-details"
                      type="button"
                      className="btn-explore d-flex justify-content-between align-items-center gap-3 rounded-2 w-100 mt-4"
                    >
                      Next
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={22}
                        height={14}
                        viewBox="0 0 22 14"
                        fill="none"
                      >
                        <path
                          d="M1 6.99854H21L16 1"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M21 6.99805L16 12.9995"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                      </svg>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-4">
                <CourseSidebar />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default FeaturedGoldCourse