export const validate = (values) => {
    let errors = {};


    // Validate old password field
    if (!values.old_password) {
        errors.old_password = "Old password is required";
    } else if (values.old_password.length < 3) {
        errors.old_password = "Old password must be at least 3 characters long";
    }

    // Validate new password field
    if (!values.new_password) {
        errors.new_password = "New password is required";
    } else if (values.new_password.length < 3) {
        errors.new_password = "New password must be at least 3 characters long";
    }

    // Validate confirm password field
    if (!values.confirm_password) {
        errors.confirm_password = "Confirm password is required";
    } else if (values.confirm_password !== values.new_password) {
        errors.confirm_password = "Confirm password does not match new password";
    }

    return errors;
};
