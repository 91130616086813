// ChildComponent.js
import React, { Fragment, useContext, useEffect } from 'react';
import { useAppLocation } from '../helper/customHook';
import { CourseContext } from '../context/CourseContext';

const ChildComponent = () => {
  const { setCurrentPage, setSearch, setSearchex, is_search, setIs_search } = useContext(CourseContext);

  const location = useAppLocation();

  useEffect(() => {

    if (location.pathname !== "/courses-explore" || location.pathname !== "/category") {
      setCurrentPage(1)
      setSearch("")
      setSearchex("")

      setIs_search(false)
    }
    if (location.pathname !== "/quize-results") {
      if (location.pathname !== "/quiz") {
        sessionStorage.removeItem('answers');
        sessionStorage.removeItem('answers_index');
        sessionStorage.removeItem('answers_count');
        sessionStorage.removeItem('quiz');
        sessionStorage.removeItem('isSubmit');
        sessionStorage.removeItem('isTrue');
        sessionStorage.removeItem('true_id');
        sessionStorage.removeItem('remainingPathColor');
      }
      sessionStorage.removeItem('FinalAnswers');
      sessionStorage.removeItem('chatGptResult');
      // if (location.pathname !== "/business-user-details" && location.pathname !== "/sign-up") {
      //   sessionStorage.removeItem('role');
      // }
    }


  }, [location.pathname]);

  return null; // This component doesn't render anything
};

export default ChildComponent;
