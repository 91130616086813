import React from 'react'
import Header from '../layout/Header'
import Footer from '../layout/Footer'
import CourseSidebar from '../components/CourseSidebar'
import { Link } from 'react-router-dom'

function FeaturedGoldCourseDetails() {
  return (
    <>
      <Header />
      <section>
        <div className="featured-main">
          <div className="container">
            <div className="breadcrumb-main">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-3 mb-md-5">
                  <li className="breadcrumb-item">
                    <Link to="">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="">Technology</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="">Mini Course</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Fraud Prevention
                  </li>
                </ol>
              </nav>
            </div>
            <div className="row justify-content-between align-items-center mb-4 mb-md-5 mb-lg-6">
              <div className="col-12 col-md-12 col-lg-6">
                <div className="d-flex align-items-center gap-3">
                  <span className="badge badge-black text-white rounded-2 fw-400 fs-7 mb-4">
                    IT Security
                  </span>
                  <span class="badge badge-light text-black rounded-2 fw-400 fs-7 mb-4">Free</span>
                </div>
                <h2 className="text-black fw-400">
                  Learning to Work with Figma
                </h2>
              </div>
              <div className="col-12 col-md-12 col-lg-6 mt-4">
                <div className="row justify-content-end">
                  <div className="col-lg-12 col-xl-8">
                    <div className="row justify-content-between align-items-center g-0 mb-2">
                      <p className="col-6 col-md-7 fw-500 text-black mb-0 fs-7 fs-sm-7">
                        Advanced Achievers
                      </p>
                      <div className="col-6 col-md-5 d-flex align-items-center">
                        <ul className="rectangle rectangle2 d-flex align-items-center gap-2 gap-md-3 flex-wrap mb-0 p-0">
                          <li className="active" />
                          <li className="active" />
                          <li className="active" />
                          <li className="active" />
                        </ul>
                      </div>
                    </div>
                    <div className="row justify-content-between align-items-center g-0 mb-2">
                      <p className="col-6 col-md-7 fw-400 text-black mb-0 fs-7 fs-sm-7">
                        Pro Membership
                      </p>
                      <div className="col-6 col-md-5 d-flex align-items-center gap-2">
                        <img
                          src="./featured-courses/diamond.jpg"
                          className="wh-20"
                          alt=""
                        />
                        <p className="mb-0 text-black fw-500 fs-7 fs-sm-7">
                          Premium Pass
                        </p>
                      </div>
                    </div>
                    <div className="row justify-content-end align-items-center g-0 mb-2">
                      <p className="col-6 col-md-7 fw-400 text-black mb-0 fs-7 fs-sm-7">
                        Duration
                      </p>
                      <div className="col-6 col-md-5 d-flex align-items-center gap-2">
                        <img
                          src="./featured-courses/hourglass.png"
                          className="wh-20"
                          alt=""
                        />
                        <p className="mb-0 text-black fw-500 fs-7 fs-sm-7">5 Hours</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-4 g-md-3 g-lg-4 g-xl-5">
              <div className="col-md-12 col-lg-8">
                <div>
                  <h4 className="text-black fw-500">Understanding the Figma Interface</h4>
                  <p className="text-muted fw-400 mt-3 mb-4 mb-md-5">
                    Upon opening Figma, users encounter a clean, streamlined interface. The
                    toolbar on the left hosts tools like Move, Scale, and Pen, while the right
                    sidebar provides details and properties of the selected objects.
                  </p>
                  <h4 className="text-black fw-500">Setting up your First Project</h4>
                  <p className="text-muted fw-400 mt-3 mb-5">
                    Start by clicking on the '+' icon on the dashboard. Choose a design size,
                    and you’re ready to go. Layers are organized in the left panel, allowing
                    for easy organization of objects and components.
                  </p>
                  <h4 className="text-black fw-500">Designing with Vector Tools</h4>
                  <p className="text-muted fw-400 mt-3 mb-5">
                    Figma offers powerful vector tools, enabling designers to craft intricate
                    shapes and illustrations. The Pen tool, for instance, offers precision and
                    control unmatched by many other design platforms.
                  </p>
                  <h4 className="text-black fw-500">Collaborating in Real-time</h4>
                  <p className="text-muted fw-400 mt-3 mb-5">
                    One of Figma's standout features is real-time collaboration. Multiple team
                    members can work on the same file simultaneously, each represented by a
                    unique cursor, facilitating immediate feedback and teamwork.
                  </p>
                  <h4 className="text-black fw-500">Components and Styles</h4>
                  <p className="text-muted fw-400 mt-3 mb-5">
                    Components in Figma are reusable design elements, like buttons or icons.
                    They ensure consistency across designs. Styles, on the other hand, help
                    maintain a uniform look for properties like colors, fonts, and effects
                    across various elements.
                  </p>
                  <h4 className="text-black fw-500">Prototyping and Sharing</h4>
                  <p className="text-muted fw-400 mt-3 mb-0 mb-md-5">
                    Figma isn’t just for designing; it’s also a prototyping tool. Link frames
                    together to simulate user flow and interaction. Once done, use the share
                    button to generate a link or an embed code, making it easy for
                    stakeholders to access and review the design.
                  </p>
                  <b className="text-muted pt-5 d-block fs-6">References:</b>
                  <ul className="references-list references-list-2  mt-4">
                    <li>
                      - Uber Design, "Moving from Sketch to Figma
                      <Link to="" className="text-muted">
                        [https://www.uber.design/](https://www.uber.design/).
                      </Link>
                      Retrieved on August 16, 2023.
                    </li>
                    <li>
                      Impact of AI on Web Design", Webflow Survey 2021. Retrieved August 15,
                      2022,
                      <Link to="" className="text-muted">
                        https://www.webflow.com/survey
                      </Link>
                    </li>
                    <li>
                      - Dropbox Design, "How We Use Figma For Design Retrieved August 16,
                      2022, from
                      <Link to="" className="text-muted">
                        [https://www.dropbox.design/](https://www.dropbox.design/).
                      </Link>{" "}
                      Retrieved on August 16, 2023.
                    </li>
                    <li>
                      - Microsoft Fluent Design Team, "Building Fluent with Figma
                      <Link to="" className="text-muted">
                        [https://www.microsoft.com/design/](https://www.microsoft.com/design/)
                      </Link>
                      Retrieved on August 16, 2023.
                    </li>
                    <li>
                      - Figma Blog, "Year in Review: 2021".
                      <Link to="" className="text-muted">
                        [https://www.figma.com/blog/](https://www.figma.com/blog/).
                      </Link>
                      Retrieved on August 16, 2023.
                    </li>
                  </ul>
                  <p className="text-muted mt-5 fs-7">
                    (Note: The articles linked in the references are fictional and serve as
                    placeholders for the purpose of the example.)
                  </p>
                  <p className="text-muted mb-5 pt-3 pt-md-5 fs-7">
                    (Note: The anecdotes and forecasts mentioned in the text area fictional
                    and created for the purpose of this exercise. The references are also
                    fictional and not real websites.)
                  </p>
                </div>
                <div className="row justify-content-between align-items-center mb-2 my-md-4 my-lg-5 g-2">
                  <div className="col-6 col-md-4 col-lg-4 col-xl-3">
                    <Link
                      to="/gold-course"
                      type="button"
                      className="btn-explore p-sm-15 bg-secondary text-black d-flex justify-content-between align-items-center gap-3 rounded-2 w-100 mt-4"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={22}
                        height={14}
                        viewBox="0 0 22 14"
                        fill="none"
                      >
                        <path
                          d="M21 6.99854H1L6 1"
                          stroke="#000C2A"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 6.99805L6 12.9995"
                          stroke="#000C2A"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                      </svg>
                      Previous
                    </Link>
                  </div>
                  <div className="col-6 col-md-4 col-lg-4 col-xl-3">
                    <Link
                      to="/gold-course-quize-1"
                      type="button"
                      className="btn-explore p-sm-15 d-flex justify-content-between align-items-center gap-3 rounded-2 w-100 mt-4"
                    >
                      Next
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={22}
                        height={14}
                        viewBox="0 0 22 14"
                        fill="none"
                      >
                        <path
                          d="M1 6.99854H21L16 1"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M21 6.99805L16 12.9995"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                      </svg>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-4">
                <CourseSidebar />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default FeaturedGoldCourseDetails