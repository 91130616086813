import React, { useEffect, useState } from "react";
import AdminHeader from "../../layout/AdminHeader";
import CustomerSidbar from "../../components/CustomerSidbar";
import Footer from "../../layout/Footer";
import { Card, Col, Modal, Row, Tab, Tabs } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { getServerURL } from "../../helper/envConfig";
import api from "../../helper/api";
import moment from "moment";
import { decryptFromStoreLoc } from "../../helper/localStore";
import { toast } from "react-toastify";
import BusinessSidebar from "../../components/BusinessSidebar";
function BusinessSetting() {
    const serverURL = getServerURL();
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [paymentDetails, setPaymentDetails] = useState(false);
    const [user, setUser] = useState({});
    const [reason, setReason] = useState("");

    const handleClose = () => {
        setReason("");
        setShow(false);
    };
    const handleShow = () => setShow(true);

    const getPaymentDetails = async () => {
        try {
            const response = await api.getWithToken(
                `${serverURL}payments/payment/`
            );
            if (response.data.success) {
                setPaymentDetails(response.data.data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        const decryptedInfoResult = decryptFromStoreLoc("user");
        setUser(decryptedInfoResult);
        getPaymentDetails();
    }, []);

    const handleUpdateReason = (event) => {
        setReason(event.target.value); // Update reason based on selected radio button value
    };

    const handleCancelSubscription = async () => {
        try {
            const response = await api.postWithToken(
                `${serverURL}payments/subscription-cancel/`,
                {
                    bill_id: paymentDetails.bill_id,
                    reason: reason,
                }
            );
            if (response.data.success) {
                toast.success(response.data.message);
                setShow(false);
                getPaymentDetails();
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleRedirect = () => {
        if (user?.role === 12) {
            navigate("/business-free-environment");
        }

        if (user?.role === 13) {
            navigate("/family-user-plan");
        }

        if (user?.role === 2) {
            navigate("/subscription-plans");
        }
    };

    return (
        <>
            <AdminHeader />
            <section>
                <div className="dashbaord-home-main">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-3 col-md-4 col-lg-3 col-xxl-3 d-none d-xl-block px-4 bg-white sidebar-mian">
                                {user?.role_name === "I’m a business owner" ||
                                user?.role_name ===
                                    "I’m a compliance manager" ||
                                user?.user_role === "I’m a line manager" ||
                                user?.role_name === "I’m a HR admin" ? (
                                    <BusinessSidebar />
                                ) : (
                                    <CustomerSidbar />
                                )}
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 col-xxl-9 bg-body">
                                <div className="row ps-0 ps-lg-4 pe-lg-4 ps-xl-5 dashbaord-right-side justify-content-md-center justify-content-lg-between pt-5">
                                    <div className="col-12 col-md-11 col-lg-12 right-side">
                                        <div className="row justify-content-between align-items-center">
                                            <div className="col-12 col-md-6 col-lg-8">
                                                <p className="subscription-setting-text">
                                                    Manage Your Subscription
                                                </p>
                                                <h2 className="fw-600 text-black mb-5">
                                                    Settings
                                                </h2>
                                            </div>
                                        </div>
                                        <div className="teblas-tabbar settings-tabs">
                                            <Tabs
                                                // defaultActiveKey="profile"
                                                // id="justify-tab-example"
                                                className="mb-3"
                                                variant="pills"
                                                justify
                                            >
                                                {/* <Tab
                                                    eventKey="home"
                                                    title="Edit profile"
                                                >
                                                    <Row className="g-3">
                                                        <Col xl={7}>
                                                            <Card className="payment-information-card">
                                                                <Card.Body>
                                                                    <div className="d-flex justify-content-between align-items-start">
                                                                        <div>
                                                                            <h5>
                                                                                Tier
                                                                                One
                                                                                -
                                                                                Single
                                                                            </h5>
                                                                            <p>
                                                                                Single
                                                                                eLearning
                                                                                with
                                                                                the
                                                                                basic
                                                                                Compliance
                                                                                courses
                                                                                and
                                                                                Information
                                                                                Security
                                                                                courses
                                                                            </p>
                                                                        </div>
                                                                        <div>
                                                                            <h4>
                                                                                <span>
                                                                                    €6
                                                                                </span>
                                                                                /year
                                                                            </h4>
                                                                        </div>
                                                                    </div>
                                                                </Card.Body>
                                                            </Card>
                                                        </Col>
                                                        <Col xl={4}></Col>
                                                    </Row>
                                                </Tab> */}
                                                <Tab
                                                    eventKey="profile"
                                                    title="Subscription"
                                                >
                                                    <Row className="g-3 payment-information-card">
                                                        <Col lg={7}>
                                                            <Card>
                                                                <Card.Body className="summary-details-list p-4">
                                                                    {user?.role ===
                                                                        2 && (
                                                                        <>
                                                                            {paymentDetails.plan_type ===
                                                                                "one-one" && (
                                                                                <div className="d-flex justify-content-between align-items-start border-bottom">
                                                                                    <div>
                                                                                        <h5>
                                                                                            Tier
                                                                                            One
                                                                                            -
                                                                                            One
                                                                                            Trails{" "}
                                                                                        </h5>
                                                                                        <p>
                                                                                            Single
                                                                                            trail
                                                                                            with
                                                                                            the
                                                                                            basic
                                                                                            Data
                                                                                            Privacy
                                                                                            and
                                                                                            Compliance
                                                                                            courses
                                                                                        </p>
                                                                                    </div>
                                                                                    <div>
                                                                                        <h4>
                                                                                            <span>
                                                                                                €0
                                                                                            </span>
                                                                                            /year
                                                                                        </h4>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                            {paymentDetails.plan_type ===
                                                                                "two-two" && (
                                                                                <div className="d-flex justify-content-between align-items-start border-bottom">
                                                                                    <div>
                                                                                        <h5>
                                                                                            Tier
                                                                                            Two
                                                                                            -
                                                                                            Two
                                                                                            Trails{" "}
                                                                                        </h5>
                                                                                        <p>
                                                                                            Several
                                                                                            trails
                                                                                            with
                                                                                            basic
                                                                                            courses
                                                                                            on
                                                                                            Compliance,
                                                                                            <br />{" "}
                                                                                            Data
                                                                                            Privacy
                                                                                            and
                                                                                            Information
                                                                                            Security
                                                                                        </p>
                                                                                    </div>
                                                                                    <div>
                                                                                        <h4>
                                                                                            <span>
                                                                                                €12
                                                                                            </span>
                                                                                            /year
                                                                                        </h4>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                            {paymentDetails.plan_type ===
                                                                                "three-three-gold" && (
                                                                                <div className="d-flex justify-content-between align-items-start border-bottom">
                                                                                    <div>
                                                                                        <h5>
                                                                                            {" "}
                                                                                            Tier
                                                                                            Three-Gold
                                                                                            -
                                                                                            All
                                                                                            trails
                                                                                        </h5>
                                                                                        <p>
                                                                                            All
                                                                                            the
                                                                                            courses
                                                                                            with
                                                                                            a
                                                                                            lot
                                                                                            more
                                                                                            courses
                                                                                            on
                                                                                            <br />
                                                                                            Compliance,
                                                                                            Data
                                                                                            Privacy,
                                                                                            Information
                                                                                            Security
                                                                                            <br />
                                                                                            and
                                                                                            also
                                                                                            on
                                                                                            skills
                                                                                            and
                                                                                            fun
                                                                                            topics
                                                                                        </p>
                                                                                    </div>
                                                                                    <div>
                                                                                        <h4>
                                                                                            <span></span>
                                                                                            €120
                                                                                            /year
                                                                                        </h4>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </>
                                                                    )}

                                                                    {user?.role ===
                                                                        13 && (
                                                                        <>
                                                                            <div className="d-flex justify-content-between align-items-start border-bottom">
                                                                                <div>
                                                                                    <h5>
                                                                                        Family
                                                                                        Plan
                                                                                    </h5>
                                                                                </div>
                                                                                <div>
                                                                                    <h5>
                                                                                        €72
                                                                                        /year
                                                                                    </h5>
                                                                                    <p>
                                                                                        per
                                                                                        Adult
                                                                                    </p>
                                                                                </div>
                                                                                <div>
                                                                                    <h5>
                                                                                        €36
                                                                                        /year
                                                                                    </h5>
                                                                                    <p>
                                                                                        per
                                                                                        Kid
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex justify-content-between align-items-start pt-4 border-bottom">
                                                                                <div>
                                                                                    <p className="fs-6">
                                                                                        {paymentDetails?.kids ||
                                                                                            0}{" "}
                                                                                        Kids
                                                                                    </p>
                                                                                </div>
                                                                                <div>
                                                                                    <p className="fs-6">
                                                                                        {paymentDetails?.adults ||
                                                                                            0}{" "}
                                                                                        Adult
                                                                                    </p>
                                                                                </div>
                                                                                <div>
                                                                                    <h4>
                                                                                        <span>
                                                                                            €
                                                                                            {(paymentDetails?.kids ||
                                                                                                0) *
                                                                                                36 +
                                                                                                (paymentDetails?.adults ||
                                                                                                    0) *
                                                                                                    72}
                                                                                        </span>
                                                                                        /year
                                                                                    </h4>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )}

                                                                    {user?.role ===
                                                                        13 && (
                                                                        <>
                                                                            {paymentDetails.plan_type ===
                                                                                "one-one" && (
                                                                                <div className="d-flex justify-content-between align-items-start mt-2">
                                                                                    <div>
                                                                                        <h5>
                                                                                            Tier
                                                                                            One
                                                                                            -
                                                                                            One
                                                                                            Trails{" "}
                                                                                        </h5>
                                                                                        <p>
                                                                                            Single
                                                                                            trail
                                                                                            with
                                                                                            the
                                                                                            basic
                                                                                            Data
                                                                                            <br />
                                                                                            Privacy
                                                                                            and
                                                                                            Compliance
                                                                                            courses
                                                                                        </p>
                                                                                    </div>
                                                                                    <div>
                                                                                        <h4>
                                                                                            <span>
                                                                                                €0
                                                                                            </span>
                                                                                            /year
                                                                                        </h4>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                            {paymentDetails.plan_type ===
                                                                                "two-two" && (
                                                                                <div className="d-flex justify-content-between align-items-start border-bottom mt-3">
                                                                                    <div>
                                                                                        <h5>
                                                                                            Tier
                                                                                            Two
                                                                                            -
                                                                                            Two
                                                                                            Trails{" "}
                                                                                        </h5>
                                                                                        <p>
                                                                                            Several
                                                                                            trails
                                                                                            with
                                                                                            basic
                                                                                            courses
                                                                                            on
                                                                                            <br />
                                                                                            Compliance,
                                                                                            Data
                                                                                            Privacy
                                                                                            and
                                                                                            Information
                                                                                            Security
                                                                                        </p>
                                                                                    </div>
                                                                                    <div>
                                                                                        <h4>
                                                                                            <span>
                                                                                                €12
                                                                                            </span>
                                                                                            /year
                                                                                        </h4>
                                                                                        <h4>
                                                                                            per
                                                                                            Employee
                                                                                        </h4>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                            {paymentDetails.plan_type ===
                                                                                "three-three-gold" && (
                                                                                <div className="d-flex justify-content-between align-items-start border-bottom mt-3">
                                                                                    <div>
                                                                                        <h5>
                                                                                            {" "}
                                                                                            Tier
                                                                                            Three-Gold
                                                                                            -
                                                                                            All
                                                                                            trails
                                                                                        </h5>
                                                                                        <p>
                                                                                            All
                                                                                            the
                                                                                            courses
                                                                                            with
                                                                                            a
                                                                                            lot
                                                                                            more
                                                                                            courses
                                                                                            on
                                                                                            <br />
                                                                                            Compliance,
                                                                                            Data
                                                                                            Privacy,
                                                                                            Information
                                                                                            <br />
                                                                                            Security
                                                                                            and
                                                                                            also
                                                                                            on
                                                                                            skills
                                                                                            and
                                                                                            fun
                                                                                            topics
                                                                                        </p>
                                                                                    </div>
                                                                                    <div>
                                                                                        <h4>
                                                                                            <span>
                                                                                                €72
                                                                                            </span>
                                                                                            /year
                                                                                        </h4>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </>
                                                                    )}

                                                                    {user?.role ===
                                                                        12 && (
                                                                        <>
                                                                            {paymentDetails.plan_type ===
                                                                                "one-one" && (
                                                                                <div className="d-flex justify-content-between align-items-start border-bottom">
                                                                                    <div>
                                                                                        <h5>
                                                                                            Tier
                                                                                            One
                                                                                            -
                                                                                            One
                                                                                            Trails{" "}
                                                                                        </h5>
                                                                                        <p>
                                                                                            Single
                                                                                            trail
                                                                                            with
                                                                                            the
                                                                                            basic
                                                                                            Data
                                                                                            Privacy
                                                                                            and
                                                                                            Compliance
                                                                                            courses
                                                                                        </p>
                                                                                    </div>
                                                                                    <div>
                                                                                        <h4>
                                                                                            <span>
                                                                                                €0
                                                                                            </span>
                                                                                            /year
                                                                                        </h4>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                            {paymentDetails.plan_type ===
                                                                                "two-two" && (
                                                                                <div className="d-flex justify-content-between align-items-start border-bottom">
                                                                                    <div>
                                                                                        <h5>
                                                                                            Tier
                                                                                            Two
                                                                                            -
                                                                                            Two
                                                                                            Trails{" "}
                                                                                        </h5>
                                                                                        <p>
                                                                                            Several
                                                                                            trails
                                                                                            with
                                                                                            basic
                                                                                            courses
                                                                                            on
                                                                                            Compliance,
                                                                                            <br />{" "}
                                                                                            Data
                                                                                            Privacy
                                                                                            and
                                                                                            Information
                                                                                            Security
                                                                                        </p>
                                                                                    </div>
                                                                                    <div>
                                                                                        <h4>
                                                                                            <span>
                                                                                                €
                                                                                                {
                                                                                                    paymentDetails?.price
                                                                                                }
                                                                                            </span>
                                                                                            /year
                                                                                        </h4>
                                                                                        <h4>
                                                                                            per
                                                                                            Employee
                                                                                        </h4>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                            {paymentDetails.plan_type ===
                                                                                "three-three-gold" && (
                                                                                <div className="d-flex justify-content-between align-items-start border-bottom">
                                                                                    <div>
                                                                                        <h5>
                                                                                            {" "}
                                                                                            Tier
                                                                                            Three-Gold
                                                                                            -
                                                                                            All
                                                                                            trails
                                                                                        </h5>
                                                                                        <p>
                                                                                            All
                                                                                            the
                                                                                            courses
                                                                                            with
                                                                                            a
                                                                                            lot
                                                                                            more
                                                                                            courses
                                                                                            on
                                                                                            <br />
                                                                                            Compliance,
                                                                                            Data
                                                                                            Privacy,
                                                                                            Information
                                                                                            Security
                                                                                            <br />
                                                                                            and
                                                                                            also
                                                                                            on
                                                                                            skills
                                                                                            and
                                                                                            fun
                                                                                            topics
                                                                                        </p>
                                                                                    </div>
                                                                                    <div>
                                                                                        <h4>
                                                                                            <span></span>
                                                                                            €120
                                                                                            /year
                                                                                        </h4>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </>
                                                                    )}

                                                                    {user?.role ===
                                                                        12 && (
                                                                        <div className="d-flex justify-content-between align-items-start pt-4">
                                                                            <div>
                                                                                <p className="fs-6">
                                                                                    {
                                                                                        paymentDetails?.employee
                                                                                    }{" "}
                                                                                    Employees
                                                                                </p>
                                                                            </div>
                                                                            <div>
                                                                                <h4>
                                                                                    <span>
                                                                                        €
                                                                                        {paymentDetails?.employee *
                                                                                            paymentDetails?.price ||
                                                                                            0}
                                                                                    </span>
                                                                                    /year
                                                                                </h4>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                    <div className="d-flex justify-content-end mt-4">
                                                                        <button
                                                                            className="btn-primary p-sm-15 rounded-2"
                                                                            onClick={
                                                                                handleRedirect
                                                                            }
                                                                        >
                                                                            Upgrade
                                                                            Plan
                                                                        </button>
                                                                    </div>
                                                                </Card.Body>
                                                            </Card>
                                                            {paymentDetails?.plan_type !== "one-one" &&<Card>
                                                                <Card.Body className="summary-details-list p-4">
                                                                    <p>
                                                                        You can
                                                                        cancel
                                                                        your
                                                                        subscription.
                                                                        You will
                                                                        have
                                                                        access
                                                                        until
                                                                        the end
                                                                        of the
                                                                        billing
                                                                        period.
                                                                    </p>
                                                                    <button
                                                                        className="btn-explore p-sm-15 rounded-2 mt-4"
                                                                        onClick={
                                                                            handleShow
                                                                        }
                                                                    >
                                                                        Cancel
                                                                        Your
                                                                        Subscription
                                                                    </button>
                                                                </Card.Body>
                                                            </Card>}
                                                        </Col>
                                                        {paymentDetails.plan_type !== "one-one" && <Col lg={5}>
                                                            <Card>
                                                                <Card.Body className="summary-details-list p-4">
                                                                    <p>
                                                                        Next
                                                                        payment
                                                                    </p>
                                                                    <h5>
                                                                        on{" "}
                                                                        {paymentDetails.next_payment_date
                                                                            ? moment(
                                                                                  paymentDetails.next_payment_date
                                                                              ).format(
                                                                                  "MMMM DD, YYYY"
                                                                              )
                                                                            : "00-00-0000"}
                                                                    </h5>
                                                                    {/* <p>
                                                                        Payment
                                                                        Method
                                                                    </p>
                                                                    <div className="d-flex align-items-start gap-3">
                                                                        <img
                                                                            src="/png/mastercard.png"
                                                                            alt=""
                                                                        />
                                                                        <div>
                                                                            <h5 className="fs-7">
                                                                                Master
                                                                                Card
                                                                            </h5>
                                                                            <p className="mastercard-number">
                                                                                1234
                                                                                1234
                                                                                1234
                                                                                2431
                                                                            </p>
                                                                        </div>
                                                                    </div> */}
                                                                   {paymentDetails?.is_expired &&
                                                                   <button className="btn-primary p-sm-15 rounded-2 mt-4"
                                                                            onClick={
                                                                                handleRedirect
                                                                            }
                                                                    >
                                                                        Renew Your Plan
                                                                    </button>}
                                                                </Card.Body>
                                                            </Card>
                                                        </Col>}
                                                    </Row>
                                                </Tab>
                                            </Tabs>
                                        </div>
                                        {/* course-trails */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />

            <Modal
                show={show}
                size="lg"
                onHide={handleClose}
                className="cancel-subscription"
            >
                <Modal.Header
                    closeButton
                    className="border-0 adjust-modal "
                ></Modal.Header>
                <div className="px-4 text-center">
                    <Modal.Title>We're sorry to see you go </Modal.Title>
                    <p className="cancel-subscription-description">
                        Your subscription will remain active until the end of
                        your current billing period. After that, you will no
                        longer be charged, and your account will be deactivated.
                    </p>
                </div>
                <Modal.Body className="cancel-subscription-details">
                    <p>Reason for Cancellation (optional)</p>
                    <div className="ps-3 pt-4">
                        <label className="d-flex align-items-center mb-3 input-radio-box-title">
                            <input
                                type="radio"
                                className="input-radio-box on"
                                name="feedback" // Use the same name for all radio buttons
                                value="Switching to another service" // Optional: You can add a value for identification
                                onChange={handleUpdateReason}
                            />{" "}
                            Switching to another service
                        </label>
                        <label className="d-flex align-items-center mb-3 input-radio-box-title">
                            <input
                                type="radio"
                                className="input-radio-box on"
                                name="feedback" // Same name as above
                                value="Too expensive" // Optional: Value for identification
                                onChange={handleUpdateReason}
                            />{" "}
                            Too expensive
                        </label>
                        <label className="d-flex align-items-center mb-3 input-radio-box-title">
                            <input
                                type="radio"
                                className="input-radio-box on"
                                name="feedback" // Same name as above
                                value="No longer needed" // Optional: Value for identification
                                onChange={handleUpdateReason}
                            />{" "}
                            No longer needed
                        </label>
                        <label className="d-flex align-items-center mb-3 input-radio-box-title">
                            <input
                                type="radio"
                                className="input-radio-box on"
                                name="feedback" // Same name as above
                                value="Other" // Optional: Value for identification
                                defaultChecked
                                onChange={handleUpdateReason}
                            />{" "}
                            Other
                        </label>
                    </div>

                    <div className="d-md-flex justify-content-end gap-3 pricing-management-buttons pt-4 pt-md-5">
                        <button onClick={handleClose}>Keep Subscription</button>
                        <button
                            className="mt-3 mt-md-0"
                            onClick={handleCancelSubscription}
                        >
                            Cancel Subscription
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default BusinessSetting;
