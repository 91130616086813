import React, { useEffect, useState } from "react";
import AdminLayout from "../../components/Admin/AdminLayout";
import ApexCharts from "react-apexcharts";
import api from "../../helper/api";
import { getServerURL } from "../../helper/envConfig";
import CommonLoader from "../../components/Admin/CommonLoader";

function AdminAnalytics() {
    const serverURL = getServerURL();
    const [companyData, setCompanyData] = useState({});
    const [employeeData, setEmployeeData] = useState({});
    const [familyData, setFamilyData] = useState({});
    const [kidsData, setKidsData] = useState({});
    const [showLoader, setShowLoader] = useState(true);

    const getAnalyticsData = async () => {
        try {
            const response = await api.getWithToken(
                `${serverURL}admin-panel/analytics-dashboard-data`
            );
            setCompanyData(response.data.data.companies);
            setEmployeeData(response.data.data.employees);
            setFamilyData(response.data.data.family);
            setKidsData(response.data.data.kids);
            setShowLoader(false)
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getAnalyticsData();
    }, []);

    const chartsData = [
        {
            series: [
                {
                    name: "Sales",
                    data: companyData?.companies_monthly_data
                        ? Object.values(companyData?.companies_monthly_data)
                        : [],
                },
            ],
            options: {
                chart: {
                    type: "area",
                    height: 350,
                    toolbar: {
                        show: false,
                    },
                    // background: 'linear-gradient(180deg, #F8DEBD 0%, rgba(251, 235, 214, 0) 100%)'
                },
                grid: {
                    show: false,
                },
                colors: ["#F3A53F"],
                dataLabels: {
                    enabled: false,
                },
                tooltip: {
                    enabled: false,
                },
                fill: {
                    type: "gradient",
                    gradient: {
                        shade: "dark",
                        type: "vertical",
                        shadeIntensity: 0.5,
                        gradientToColors: ["#F8DEBD"],
                        opacityFrom: 1,
                        opacityTo: 1,
                        stops: [0, 100],
                        colorStops: [
                            { offset: 0, color: "#F8DEBD", opacity: 1 },
                            {
                                offset: 100,
                                color: "rgba(251, 235, 214, 0)",
                                opacity: 1,
                            },
                        ],
                    },
                },
                stroke: {
                    width: 2,
                    colors: ["#f3a53f"],
                    curve: "smooth",
                },
                xaxis: {
                    categories: [
                        "Jan",
                        "Feb",
                        "Mar",
                        "Apr",
                        "May",
                        "Jun",
                        "Jul",
                        "Aug",
                        "Sep",
                    ],
                    labels: {
                        show: false,
                    },
                    axisBorder: {
                        show: false,
                    },
                    axisTicks: {
                        show: false,
                    },
                },
                yaxis: {
                    labels: {
                        show: false,
                    },
                    axisBorder: {
                        show: false,
                    },
                    axisTicks: {
                        show: false,
                    },
                },
            },
            name: "Number of Companies",
            value: companyData?.companies_count,
            imageUrl: "./admin/companies.png",
            imageUrl2: `${
                Number(companyData?.companies_percentage_change) < 0
                    ? "./admin/down-arrow.png"
                    : "./admin/uparrow.png"
            }`,
            percentChange: `${Math.abs(
                companyData?.companies_percentage_change
            )}%`,
            des: `${
                Number(companyData?.companies_percentage_change) < 0
                    ? "Down from last month"
                    : "Up from last month"
            }`,
            rangeclass: `${
                Number(companyData?.companies_percentage_change) < 0
                    ? "down-range"
                    : "up-range"
            }`,
        },
        {
            series: [
                {
                    name: "Revenue",
                    data: employeeData?.employees_monthly_data
                        ? Object.values(employeeData?.employees_monthly_data)
                        : [],
                },
            ],
            options: {
                chart: {
                    type: "area",
                    height: 350,
                    toolbar: {
                        show: false,
                    },
                    // background: 'linear-gradient(180deg, #D0FBFF 16.01%, rgba(221, 242, 244, 0) 100%)'
                },
                grid: {
                    show: false,
                },
                colors: ["#4A90E2"],
                dataLabels: {
                    enabled: false,
                },
                tooltip: {
                    enabled: false,
                },
                fill: {
                    type: "gradient",
                    gradient: {
                        shade: "light",
                        type: "vertical",
                        shadeIntensity: 0.5,
                        gradientToColors: ["#D0FBFF"],
                        opacityFrom: 1,
                        opacityTo: 1,
                        stops: [0, 100],
                        colorStops: [
                            { offset: 0, color: "#D0FBFF", opacity: 1 },
                            {
                                offset: 100,
                                color: "rgba(221, 242, 244, 0)",
                                opacity: 1,
                            },
                        ],
                    },
                },
                stroke: {
                    width: 2,
                    colors: ["#478F96"],
                    curve: "smooth",
                },
                xaxis: {
                    categories: [
                        "Jan",
                        "Feb",
                        "Mar",
                        "Apr",
                        "May",
                        "Jun",
                        "Jul",
                        "Aug",
                        "Sep",
                    ],
                    labels: {
                        show: false,
                    },
                    axisBorder: {
                        show: false,
                    },
                    axisTicks: {
                        show: false,
                    },
                },
                yaxis: {
                    labels: {
                        show: false,
                    },
                    axisBorder: {
                        show: false,
                    },
                    axisTicks: {
                        show: false,
                    },
                },
            },
            name: "Number of Employees",
            value: employeeData.employees_count,
            imageUrl: "./admin/employees.png",
            imageUrl2: `${
                Number(employeeData.employees_percentage_change) < 0
                    ? "./admin/down-arrow.png"
                    : "./admin/uparrow.png"
            }`,
            percentChange: `${Math.abs(
                employeeData?.employees_percentage_change
            )}%`,
            des: `${
                Number(employeeData.employees_percentage_change) < 0
                    ? "Down from last month"
                    : "Up from last month"
            }`,
            rangeclass: `${
                Number(employeeData?.employees_percentage_change) < 0
                    ? "down-range"
                    : "up-range"
            }`,
        },
        {
            series: [
                {
                    name: "Users",
                    data: familyData?.family_monthly_data
                        ? Object.values(familyData?.family_monthly_data)
                        : [],
                },
            ],
            options: {
                chart: {
                    type: "area",
                    height: 350,
                    toolbar: {
                        show: false,
                    },
                    // background: 'linear-gradient(180deg, rgba(130, 128, 255, 0.4) -28.37%, rgba(130, 128, 255, 0) 95.04%)'
                },
                grid: {
                    show: false,
                },
                colors: ["#7B9AC1"],
                dataLabels: {
                    enabled: false,
                },
                tooltip: {
                    enabled: false,
                },

                fill: {
                    type: "gradient",
                    gradient: {
                        shade: "light",
                        type: "vertical",
                        shadeIntensity: 0.5,
                        gradientToColors: ["#000"],
                        opacityFrom: 1,
                        opacityTo: 1,
                        stops: [0, 100],
                        colorStops: [
                            { offset: 0, color: "#8280FF66", opacity: 1 },
                            {
                                offset: 100,
                                color: "rgba(221, 242, 244, 0)",
                                opacity: 1,
                            },
                        ],
                    },
                },

                stroke: {
                    width: 2,
                    colors: ["#8280FF"],
                    curve: "smooth",
                },
                xaxis: {
                    categories: [
                        "Jan",
                        "Feb",
                        "Mar",
                        "Apr",
                        "May",
                        "Jun",
                        "Jul",
                        "Aug",
                        "Sep",
                    ],
                    labels: {
                        show: false,
                    },
                    axisBorder: {
                        show: false,
                    },
                    axisTicks: {
                        show: false,
                    },
                },
                yaxis: {
                    labels: {
                        show: false,
                    },
                    axisBorder: {
                        show: false,
                    },
                    axisTicks: {
                        show: false,
                    },
                },
            },
            name: "Number of Families",
            value: familyData.family_count,
            imageUrl: "./admin/families.png",
            imageUrl2: `${
                Number(familyData.family_percentage_change) < 0
                    ? "./admin/down-arrow.png"
                    : "./admin/uparrow.png"
            }`,
            percentChange: `${Math.abs(familyData.family_percentage_change)}%`,
            des: `${
                Number(familyData.family_percentage_change) < 0
                    ? "Down from last month"
                    : "Up from last month"
            }`,
            rangeclass: `${
                Number(familyData?.family_percentage_change) < 0
                    ? "down-range"
                    : "up-range"
            }`,
        },
        {
            series: [
                {
                    name: "Engagement",
                    data: kidsData?.kids_monthly_data
                        ? Object.values(kidsData?.kids_monthly_data)
                        : [],
                },
            ],
            options: {
                chart: {
                    type: "area",
                    height: 350,
                    toolbar: {
                        show: false,
                    },
                    // background: 'linear-gradient(180deg, rgba(218, 129, 132, 0.4) -28.37%, rgba(202, 107, 110, 0) 95.04%)'
                },
                grid: {
                    show: false,
                },
                colors: ["#D46A6A"],
                dataLabels: {
                    enabled: false,
                },
                tooltip: {
                    enabled: false,
                },
                fill: {
                    type: "gradient",
                    gradient: {
                        shade: "dark",
                        type: "vertical",
                        shadeIntensity: 0.5,
                        gradientToColors: ["#F9C4C4"],
                        opacityFrom: 1,
                        opacityTo: 1,
                        stops: [0, 100],
                        colorStops: [
                            { offset: 0, color: "#F9C4C4", opacity: 1 },
                            {
                                offset: 100,
                                color: "rgba(249, 196, 196, 0)",
                                opacity: 1,
                            },
                        ],
                    },
                },
                stroke: {
                    width: 2,
                    colors: ["#D46A6A"],
                    curve: "smooth",
                },
                xaxis: {
                    categories: [
                        "Jan",
                        "Feb",
                        "Mar",
                        "Apr",
                        "May",
                        "Jun",
                        "Jul",
                        "Aug",
                        "Sep",
                    ],
                    labels: {
                        show: false,
                    },
                    axisBorder: {
                        show: false,
                    },
                    axisTicks: {
                        show: false,
                    },
                },
                yaxis: {
                    labels: {
                        show: false,
                    },
                    axisBorder: {
                        show: false,
                    },
                    axisTicks: {
                        show: false,
                    },
                },
            },
            name: "Number of Kids",
            value: kidsData.kids_count,
            imageUrl: "./admin/kids.png",
            imageUrl2: `${
                Number(kidsData?.kids_percentage_change) < 0
                    ? "./admin/down-arrow.png"
                    : "./admin/uparrow.png"
            }`,
            percentChange: `${Math.abs(kidsData?.kids_percentage_change)} %`,
            des: `${
                Number(kidsData?.kids_percentage_change) < 0
                    ? "Down from last month"
                    : "Up from last month"
            }`,
            rangeclass: `${
                Number(kidsData?.kids_percentage_change) < 0
                    ? "down-range"
                    : "up-range"
            }`,
        },
    ];

    // ,
    // { name: 'Number of Employees', value: '2500', imageUrl: './admin/employees.png', percentChange: '8.5%', alt: 'Companies Image' },
    // { name: 'Number of Families', value: '80', imageUrl: './admin/families.png', percentChange: '8.5%', alt: 'Companies Image' },
    // { name: 'Number of Kids', value: '500', imageUrl: './admin/kids.png', percentChange: '8.5%', alt: 'Companies Image' },
    // // Add more objects if needed

    return (
        <AdminLayout>
            {showLoader ? (
                <CommonLoader />
            ) : (
                <>
                    <h2 className="page-title">Analytics</h2>
                    <div className="row mt-1 g-4 gx-xl-4">
                        {chartsData.map((chart, index) => (
                            <div className="col-12 col-md-6 col-xl-3" key={index}>
                                <div className="card border-0 rounded-4 analytics-card">
                                    <div className="card-body">
                                        <div>
                                            <div>
                                                <div
                                                    key={index}
                                                    className="d-flex justify-content-between align-items-start gap-3"
                                                >
                                                    <div>
                                                        <p>{chart.name}</p>
                                                        <h2>{chart.value}</h2>
                                                    </div>
                                                    <div>
                                                        <img
                                                            src={chart.imageUrl}
                                                            width={44}
                                                            height={44}
                                                            alt={chart.alt}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center gap-2 mt-4">
                                                    <img
                                                        src={chart.imageUrl2}
                                                        width={20}
                                                        height={12}
                                                        alt="Up Arrow"
                                                    />
                                                    <p className="mb-0">
                                                        <span
                                                            className={chart.rangeclass}
                                                        >
                                                            {chart.percentChange}
                                                        </span>{" "}
                                                        {chart.des}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <ApexCharts
                                            options={chart.options}
                                            series={chart.series}
                                            type="area"
                                            height={200}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            )}
        </AdminLayout>
    );
}

export default AdminAnalytics;
