import React, { useState, useEffect } from 'react';
import BusinessSidebar from '../components/BusinessSidebar'
import AdminHeader from './../layout/AdminHeader';
import Footer from '../layout/Footer';
import SliderHomeSec from './../components/SliderHomeSec';
import 'animate.css/animate.min.css';
import 'react-circular-progressbar/dist/styles.css';
import UploadFile from '../components/bulk-upload-mian/UploadFile';
import PreviewTable from '../components/bulk-upload-mian/PreviewTable';
import Import from '../components/bulk-upload-mian/Import';
import { getServerURL } from '../helper/envConfig';
import { toast } from 'react-toastify';
import api from '../helper/api';
import { MultiStepFormSkeleton, UploadFileSkeleton } from '../components/SkeletonLayouts';
import { useNavigate } from 'react-router-dom';

const BulkUpload = () => {

    const navigate = useNavigate();
    const [currentStep, setCurrentStep] = useState(1);
    const [isFileLoading, setIsFileLoading] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [fileProgress, setFileProgress] = useState({});
    const [record, setRecord] = useState([]);
    const serverURL = getServerURL();
    const [progressWidth, setProgressWidth] = useState('0%');
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isfileLode, setIsfileLode] = useState(false);
    const [progress, setProgress] = useState(0);

    const [stepClasses, setStepClasses] = useState({
        1: 'd-block',
        2: 'd-none',
        3: 'd-none'
    });

    const [currentFiles, setCurrentFiles] = useState([]);
    const [lastActiveStep, setLastActiveStep] = useState(1);

    useEffect(() => {
        updateStepClasses();
    }, [currentStep]);

    useEffect(() => {
        const progressPercentage = ((currentStep - 1) / 2) * 100;
        setProgressWidth(`${progressPercentage}%`);
    }, [currentStep]);

    const updateStepClasses = () => {
        setStepClasses({
            1: currentStep === 1 ? 'd-block' : 'd-none animate__animated animate__fadeInRight',
            2: currentStep === 2 ? 'd-block animate__animated animate__fadeInRight' : 'd-none',
            3: currentStep === 3 ? 'd-block animate__animated animate__fadeInRight' : 'd-none'
        });
        setLastActiveStep(currentStep);
    };


    const nextStep = () => {
        setCurrentPage(1)
        if (currentStep < 3) {

            if (currentStep + 1 == 2) {
                handleSubmit()
            } else if (currentStep + 1 == 3) {
                postData()
            }
            else {
                setCurrentStep(currentStep + 1);
            }
        }

    };

    const assignCourse = () => {
        navigate('/assign-course');
    };

    const prevStep = () => {
        if (currentStep > 1) {
            setCurrentStep(currentStep - 1);
            setCurrentPage(1)
        }
    };

    const [percentage, setPercentage] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            if (percentage < 100) {
                setPercentage(percentage + 1);
            }
        }, 50);
    }, [percentage]);


    const handleSubmit = async () => {

        setIsUploading(true)

        // Create an instance of FormData
        const formData = new FormData();
        for (let file of currentFiles) {
            formData.append('files', file); // 'files' as per your API specification
        }

        try {
            // Replace `serverURL` with your actual server URL and endpoint
            const response = await api.postWithToken(`${serverURL}users/business-owner/upload-excel/`, formData);
            toast.success(response?.data?.message || 'Files uploaded successfully');
            getData()
            setIsUploading(false)
            setCurrentStep(currentStep + 1)

        } catch (error) {
            setIsUploading(false)
            console.error(error);
            toast.error(error.response?.data.message || 'An error occurred during file upload.');
        }
    };

    const getData = async () => {

        setIsUploading(true)

        try {
            // Replace `serverURL` with your actual server URL and endpoint
            const response = await api.getWithToken(`${serverURL}users/business-owner/upload-excel/?page=${currentPage}&page_size=${rowsPerPage}`);
            setIsUploading(false)
            setRecord(response.data)

        } catch (error) {
            setIsUploading(false)
            console.error(error);
        }
    };

    const postData = async () => {
        setCurrentStep(currentStep + 1);
        setIsfileLode(true)
        try {
            // Replace `serverURL` with your actual server URL and endpoint
            const response = await api.postWithToken(`${serverURL}users/business-owner/import-excel-data/`);
            setIsfileLode(false)
            // setCurrentStep(4);
        } catch (error) {
            setIsfileLode(false)
            console.error(error);
        }
    };

    const deleterecord = async (email) => {
        // setIsUploading(true)
        try {
            const response = await api.postWithToken(`${serverURL}users/business-owner/delete-excel-employee/`, { email: email });
            // setIsUploading(false)
            toast.success(response?.data?.message);
           if(record.results?.employees.length == 1 && currentPage !== 1){
            setCurrentPage(currentPage - 1)
           } 

           if( currentPage == 1 && record.results?.employees.length == 1 ){
               setCurrentStep(1)
           }

            getData()
        } catch (error) {
            // setIsUploading(false)
            toast.error(error.response?.data.message);
            console.error(error);
        }
    };

    useEffect(() => {

        if (currentStep === 2) {
            getData()
        }

    }, [currentPage, rowsPerPage]);


    return (
        <>
            <AdminHeader />
            <section>
                <div className="dashbaord-home-main">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-3 col-md-4 col-lg-3 col-xxl-3 d-none d-xl-block px-4 bg-white sidebar-mian">
                                <BusinessSidebar />
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 col-xxl-9 bg-body">
                                <div className="row ps-0 ps-lg-4 pe-lg-4 ps-xl-5 dashbaord-right-side justify-content-md-center justify-content-lg-between">
                                    <div className="col-12 col-md-11 col-lg-12 right-side">
                                        <SliderHomeSec />
                                        <div className='pt-5 pt-md-0 pb-0 pb-md-5'>
                                            <h2 className="text-black fw-600">
                                                Bulk Upload
                                            </h2>
                                        </div>
                                        {isUploading ? <MultiStepFormSkeleton />
                                            :
                                            <div className="card border-0 mb-5">
                                                <div className="card-body">
                                                    <div className="row justify-content-center pt-4">
                                                        <div className="col-12 col-md-11 col-lg-7">
                                                            <div className="progress mx-4" style={{ height: '2px' }}>
                                                                <div className="progress-bar" role="progressbar" style={{ width: progressWidth }} aria-valuenow={progressWidth} aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                            <div className="step-container d-flex justify-content-between px-3">
                                                                <div
                                                                    className={`${lastActiveStep === 1 ? 'active-step' : lastActiveStep > 1 ? 'active-step confirm' : ''}`}
                                                                    onClick={() => { if (lastActiveStep === 2) setCurrentStep(1); setCurrentPage(1) }}
                                                                >
                                                                    <span>01</span>
                                                                </div>
                                                                <div className={lastActiveStep === 2 ? 'active-step' : lastActiveStep > 2 ? 'active-step confirm' : ''}
                                                                // onClick={() => { if (currentFiles.length > 1) setCurrentStep(2); }}
                                                                >
                                                                    <span>02</span>
                                                                </div>
                                                                <div className={lastActiveStep === 3 ? 'active-step' : lastActiveStep > 3 ? 'active-step confirm' : ''}
                                                                // onClick={() => setCurrentStep(3)}  
                                                                >
                                                                    <span>03</span>
                                                                </div>

                                                                {/* 
                                                                <div className="step-container d-flex justify-content-between px-3">
                                                                    <div
                                                                        className={lastActiveStep === 1 ? 'active-step' : lastActiveStep > 1 ? 'active-step confirm' : ''}
                                                                        onClick={() => lastActiveStep > 0 ? setCurrentStep(1) : null}
                                                                    >
                                                                        <span>01</span>
                                                                    </div>
                                                                    <div
                                                                        className={lastActiveStep === 2 ? 'active-step' : lastActiveStep > 2 ? 'active-step confirm' : ''}
                                                                        onClick={() => lastActiveStep > 1 ? setCurrentStep(2) : null}
                                                                    >
                                                                        <span>02</span>
                                                                    </div>
                                                                    <div
                                                                        className={lastActiveStep === 3 ? 'active-step' : lastActiveStep > 3 ? 'active-step confirm' : ''}
                                                                        onClick={() => lastActiveStep > 2 ? setCurrentStep(3) : null}
                                                                    >
                                                                        <span>03</span>
                                                                    </div>
                                                                </div> */}

                                                            </div>
                                                            <div className="d-flex justify-content-between text-center step-text-list">
                                                                <div className={lastActiveStep >= 1 ? 'active-step' : ''}>Upload file</div>
                                                                <div className={lastActiveStep >= 2 ? 'active-step pe-4' : ' pe-4'}>Preview</div>
                                                                <div className={lastActiveStep >= 3 ? 'active-step' : ''}>Import</div>
                                                            </div>

                                                            <p className='text-primary fw-400 fs-7 pt-5 text-center'>Step {currentStep} of 3</p>
                                                        </div>
                                                    </div>
                                                    <form id="multi-step-form">
                                                        <div className={`step step-1 ${stepClasses[1]}`}>
                                                            <UploadFile fileProgress={fileProgress} setFileProgress={setFileProgress} currentFiles={currentFiles} setCurrentFiles={setCurrentFiles} isFileLoading={isFileLoading} setIsFileLoading={setIsFileLoading} />
                                                        </div>

                                                        <div className={`step step-2 ${stepClasses[2]}`}>
                                                            <PreviewTable record={record} deleterecord={deleterecord} currentPage={currentPage} setCurrentPage={setCurrentPage} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} />
                                                        </div>

                                                        <div className={`step step-3 ${lastActiveStep === 3 ? stepClasses[3] : lastActiveStep === 4 ? stepClasses[4] : ''}`}>
                                                            <Import percentage={percentage} lastActiveStep={lastActiveStep} isfileLode={isfileLode} progress={progress} setProgress={setProgress} setCurrentStep={setCurrentStep} />
                                                        </div>

                                                        {/* <div className={`step step-3 ${stepClasses[3]}`}>
                                                            <Import percentage={percentage} lastActiveStep={lastActiveStep} />
                                                        </div> */}

                                                    </form>
                                                </div>
                                                <div className="card-footer button-footer pt-3 mt-5">
                                                    <div className='d-flex justify-content-end gap-3'>

                                                        {
                                                            currentStep === 1 ? (
                                                                <>
                                                                    <button type="button" className="btn-cancel" onClick={prevStep}>Cancel</button>

                                                                    <button
                                                                        type="button"
                                                                        className="btn-upload"
                                                                        onClick={nextStep}
                                                                        disabled={currentFiles.length < 1 || isFileLoading}
                                                                    >
                                                                        Upload
                                                                    </button>

                                                                </>
                                                            ) : currentStep === 2 ? (
                                                                <>
                                                                    <button type="button" className="btn-cancel" onClick={prevStep}>Back</button>
                                                                    <button type="button" className="btn-upload" onClick={nextStep} disabled={(record?.results?.is_confirmed)}>Confirm</button>
                                                                </>
                                                            ) :
                                                                <button type="button" className="btn-upload" onClick={assignCourse} disabled={(lastActiveStep < 4)}>Start Assigning Courses</button>
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default BulkUpload
