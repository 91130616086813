import React from 'react'
import Header from '../layout/Header'
import Footer from '../layout/Footer'
import { Link } from 'react-router-dom'

const Complete2 = () => {
    return (
        <>
            <Header />
            <section>
                <div className="container-fluid">
                    <div className="sign-up-main">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-12 col-md-6 position-relative bg-secondary d-flex justify-content-center align-items-center sign-up-col">
                                <img
                                    src="./login/individual-user-vector-3.png"
                                    className="sing-vector-1"
                                    alt=""
                                />
                                <img
                                    src="./login/man-delivers.png"
                                    className="project-team"
                                    alt=""
                                />
                                <img
                                    src="./login/individual-user-vector-4.png"
                                    className="sing-vector-2"
                                    alt=""
                                />
                            </div>
                            <div className="col-12 col-md-6 position-relative d-flex justify-content-center align-items-start align-items-md-end sign-up-col sign-up-col-sm">
                                <div className="col-12 col-md-11 col-lg-9">
                                    <h5 className="display-4 fw-400 text-black col-xl-7">Hurray!</h5>
                                    <p className="text-black fs-6 col-xl-7 mt-2">
                                        You are now receiving an email. You need to confirm that it is
                                        really you there. Then afterwards you get the certificate via
                                        email.
                                    </p>
                                    <div className="d-flex justify-content-start justify-content-md-end pb-0 pb-md-5 pt-mb-20">
                                        <Link
                                            to="/customer-home"
                                            className="btn-explore d-flex align-items-center gap-3 rounded-2 fw-400 docs-creator"
                                        >
                                            Start Learning Today
                                            <div className="ps-0 ps-md-5">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={22}
                                                    height={14}
                                                    viewBox="0 0 22 14"
                                                    fill="none"
                                                >
                                                    <path
                                                        d="M1 6.99854H21L16 1"
                                                        stroke="white"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path
                                                        d="M21 6.99854L16 13"
                                                        stroke="white"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                    />
                                                </svg>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Complete2