

import { Navigate, useLocation } from 'react-router-dom';
import { decryptFromStoreLoc } from './localStore';
function PaymentProtect({ children, role = [], ...rest }) {
    let location = useLocation();
    const user = decryptFromStoreLoc('user');

    // Check if the user is logged in
    let isLoggedIn = !!localStorage.getItem('token');

    // Determine the effective role of the user based on provided conditions
    let effectiveRole;
    if (user?.main_role === 2) {
        effectiveRole = user.main_role;
    } else if (user?.main_role === 1 && user?.role === 12) {
        effectiveRole = 12;
    } else if (user?.main_role === 7 && user?.role === 13) {
        effectiveRole = 13;
    } else {
        effectiveRole = user?.main_role || user?.role;
    }

    // Check if the user has any role assigned
    let hasRole = !!effectiveRole;

    // Check if the user has the required role
    let hasRequiredRole = role.length > 0 ? role.includes(effectiveRole) : hasRole;

    // Redirect to sign-in if not logged in or doesn't have the required role
    return isLoggedIn && hasRequiredRole ? children : <Navigate to="/sign-in" state={{ from: location }} replace />;
}

export default PaymentProtect;
