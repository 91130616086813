import React from 'react'
import Header from '../layout/Header'
import Footer from '../layout/Footer'
import CourseSidebar from '../components/CourseSidebar'
import { Link } from 'react-router-dom'

const FeaturedDetails = () => {
    return (
        <>
            <Header />
            <section>
                <div className="featured-main">
                    <div className="container">
                        <div className="breadcrumb-main">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-3 mb-md-5">
                                    <li className="breadcrumb-item">
                                        <Link to="">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="">Technology</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="">Mini Course</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        Fraud Prevention
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        <div className="row justify-content-between align-items-center mb-4 mb-md-5 mb-lg-6">
                            <div className="col-12 col-md-12 col-lg-6">
                                <div className="d-flex align-items-center gap-3">
                                    <span className="badge badge-black text-white rounded-2 fw-400 fs-7 mb-4">
                                        IT Security
                                    </span>
                                    <span className="badge badge-bronze rounded-2 fw-400 fs-7 mb-4">
                                        Bronze
                                    </span>
                                </div>
                                <h2 className="text-black fw-400">
                                    The Art of Outsmarting: A Guide to Fraud Prevention
                                </h2>
                            </div>
                            <div className="col-12 col-md-12 col-lg-6 mt-4">
                                <div className="row justify-content-end">
                                    <div className="col-lg-12 col-xl-8">
                                        <div className="row justify-content-between align-items-center g-0 mb-2">
                                            <p className="col-6 col-md-7 fw-500 text-black mb-0 fs-7 fs-sm-7">
                                                Advanced Achievers
                                            </p>
                                            <div className="col-6 col-md-5 d-flex align-items-center">
                                                <ul className="rectangle rectangle2 d-flex align-items-center gap-2 gap-md-3 flex-wrap mb-0 p-0">
                                                    <li className="active" />
                                                    <li className="active" />
                                                    <li className="active" />
                                                    <li className="active" />
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="row justify-content-between align-items-center g-0 mb-2">
                                            <p className="col-6 col-md-7 fw-400 text-black mb-0 fs-7 fs-sm-7">
                                                Pro Membership
                                            </p>
                                            <div className="col-6 col-md-5 d-flex align-items-center gap-2">
                                                <img
                                                    src="./featured-courses/diamond.jpg"
                                                    className="wh-20"
                                                    alt=""
                                                />
                                                <p className="mb-0 text-black fw-500 fs-7 fs-sm-7">
                                                    Premium Pass
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row justify-content-end align-items-center g-0 mb-2">
                                            <p className="col-6 col-md-7 fw-400 text-black mb-0 fs-7 fs-sm-7">
                                                Duration
                                            </p>
                                            <div className="col-6 col-md-5 d-flex align-items-center gap-2">
                                                <img
                                                    src="./featured-courses/hourglass.png"
                                                    className="wh-20"
                                                    alt=""
                                                />
                                                <p className="mb-0 text-black fw-500 fs-7 fs-sm-7">5 Hours</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row g-4 g-md-3  g-lg-4 g-xl-5">
                            <div className="col-md-12 col-lg-8">
                                <div className="card featured-card overflow-hidden bg-transparent border-0 mb-0 mb-md-4 mb-lg-5">
                                    <img
                                        src="./featured-courses/featured-details-01.jpg"
                                        className="featured-details-img"
                                        alt=""
                                    />
                                    <div className="card-body px-0 pt-4 pt-md-5">
                                        <h3 className="text-black fw-500 mb-4">Two Eiffel Towers</h3>
                                        <p className="text-muted fw-400 fs-6">
                                            Welcome to the captivating world of Data Analytics and
                                            Visualization! In this course, you'll embark on a thrilling
                                            journey through the realm of data, learning how to harness its
                                            power to uncover valuable insights and tell compelling stories.
                                            Whether you're a curious beginner or a seasoned data enthusiast,
                                            our course is designed to cater to all levels of learners.
                                        </p>
                                    </div>
                                </div>
                                <div className="card text-center rounded-3 shadow- border-0 mb-5 mb-lg-6">
                                    <div className="card-body p-4 p-md-5">
                                        <h3 className="text-black fw-500 mb-4">Do You Know?</h3>
                                        <p className="text-muted fw-400 fs-6">
                                            According to a 2022 report from the Association of Certified
                                            Fraud Examiners (ACFE), businesses worldwide lose an estimated
                                            5% of their annual revenues to fraud. That's potentially $4
                                            trillion globally!
                                        </p>
                                    </div>
                                </div>
                                <div className="card featured-card overflow-hidden bg-transparent border-0 mb-0 mb-md-4 mb-lg-5">
                                    <img
                                        src="./featured-courses/featured-details-02.jpg"
                                        className="featured-details-img"
                                        alt=""
                                    />
                                    <div className="card-body px-0 pt-3">
                                        <p className="text-muted fw-500 fs-8 mb-6 mb-5">
                                            Anthony De Angelis, a cross between Walter White, P.T. Barnum,
                                            and Genghis Khan, stole over $1 billion at today’s rates and
                                            ruled over the most outrageous soybean empire in farming
                                            history. (Photo courtesy of Wikimedia/public domain)
                                        </p>
                                        <h3 className="text-black fw-500 mb-4 pt-3">
                                            The Great salad oil swindle
                                        </h3>
                                        <p className="text-muted fw-400 fs-6">
                                            The "Great Salad Oil Swindle" of 1963 was orchestrated by
                                            Anthony "Tino" De Angelis. He had huge tanks filled with water,
                                            topped with a few inches of salad oil, convincing auditors he
                                            had much more oil than he did. His fraud led to a financial
                                            crash in the market. The funny part? De Angelis got the idea
                                            from seeing a fat-free salad dressing advertisement, which
                                            stated oil floats on water!
                                        </p>
                                    </div>
                                </div>
                                <div className="card text-center rounded-3 shadow- border-0 mb-6">
                                    <div className="card-body p-4 p-md-5">
                                        <h3 className="text-black fw-500 mb-4">Do You Know?</h3>
                                        <p className="text-muted fw-400 fs-6">
                                            According to a 2022 report from the Association of Certified
                                            Fraud Examiners (ACFE), businesses worldwide lose an estimated
                                            5% of their annual revenues to fraud. That's potentially $4
                                            trillion globally!
                                        </p>
                                    </div>
                                </div>
                                <div className="row justify-content-end mb-2 my-md-4 my-lg-5">
                                    <div className="col-6 col-md-4 col-lg-4 col-xl-3">
                                        <Link
                                            to="/featured-quize-1"
                                            type="button"
                                            className="btn-explore d-flex justify-content-between align-items-center gap-3 rounded-2 w-100 mt-4"
                                        >
                                            Next
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width={22}
                                                height={14}
                                                viewBox="0 0 22 14"
                                                fill="none"
                                            >
                                                <path
                                                    d="M1 6.99854H21L16 1"
                                                    stroke="white"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M21 6.99805L16 12.9995"
                                                    stroke="white"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                />
                                            </svg>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-4">
                                <CourseSidebar />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default FeaturedDetails
