import React, { useEffect, useState } from 'react';
import AdminLayout from '../../components/Admin/AdminLayout';
import AdminUpload from '../../components/Admin/CourseManagement/UploadFlow/AdminUpload';
import { ProgressBar } from 'react-bootstrap';
import PreviewoftheCourse from '../../components/Admin/CourseManagement/UploadFlow/PreviewoftheCourse';
import { getServerURL } from '../../helper/envConfig';
import api from '../../helper/api';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';

function CourseCreationWizard() {
    const serverURL = getServerURL();
    const navigate = useNavigate()
    const steps = [
        { number: 1, label: 'File Upload' },
        // { number: 2, label: 'Translate' },
        { number: 2, label: 'Review & Publish' },
    ];

    const [courseData, setCourseData] = useState()
    const [currentFiles, setCurrentFiles] = useState([])
    const [isEdit, setIsEdit] = useState(false)

    // State to manage current step
    const [currentStep, setCurrentStep] = useState(1);

    const [progress, setProgress] = useState(0);

    useEffect(() => {
        // Simulate progress update
        if (currentStep === 2) {
            const interval = setInterval(() => {
                setProgress(prevProgress => {
                    const newProgress = prevProgress + 1;
                    if (newProgress >= 100) {
                        clearInterval(interval);
                        return 100;
                    }
                    return newProgress;
                });
            }, 100);

            return () => clearInterval(interval);
        }
    }, [currentStep]);

    const handleNext = async () => {

        if (currentStep === 1) {
            await uploadCourseApi()
        }

        // if (currentStep < steps.length) {
        //     setCurrentStep(prevStep => prevStep + 1);
        // }
    };



    const handlePrev = () => {
        if (currentStep > 1) {
            setCurrentStep(prevStep => prevStep - 1);
        }
    };


    const uploadCourseApi = async () => {
        try {
            if (currentFiles.length === 0) {
                toast.error("Please select course file")
                return
            }
            const formData = new FormData();
            formData.append('files', currentFiles);

            const response = await api.postWithToken(
                `${serverURL}admin-panel/course-management/upload-course-excel`, formData
            );
            setCourseData(response.data.data[0]);
            setCurrentStep(prevStep => prevStep + 1);
        } catch (error) {
            toast.error(error.response.data.message);
        }
    }

    const handleSubmitCourse = async () => {
        try {
            const response = await api.postWithToken(
                `${serverURL}admin-panel/course-management/course-create`, { course_details: courseData }
            );
            toast.success(response.data.message)
            setIsEdit(false);
            setCurrentStep(1);
            setCurrentFiles([]);
            setCourseData(null);
            navigate("/admin-course-management")
        } catch (error) {
            toast.error(error.response.data.message);
        }
    }

    const handleCancel = () => {
        setIsEdit(false);
        setCurrentStep(1);
        setCurrentFiles([]);
        setCourseData(null);
    }

    return (
        <AdminLayout>
            <h2 className="page-title">Course Creation Wizard</h2>
            <nav className='admin-breadcrumb' aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/admin-course-management">Course Management</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Course Creation Wizard</li>
                </ol>
            </nav>
            <div className="row justify-content-center step-main-box pt-4 g-4">
                {steps.map((step, index) => (
                    <div className="col-4 col-md-4 col-lg-4 col-xl-3" key={step.number}>
                        <div className={`d-flex align-items-center gap-2 gap-md-3 ${currentStep >= step.number ? 'active' : ''}`}>
                            <div className="step-box">
                                {step.number}
                            </div>
                            <h2>{step.label}</h2>
                            <img src="./admin/step-arrow.png" alt="" />
                        </div>
                    </div>
                ))}
            </div>

            <div className="row pt-4 pt-md-5">
                <div className="col">
                    {currentStep === 1 && (
                        <div className="card border-0 rounded-3">
                            <div className="card-header bg-transparent fs-20 fw-600 text-bl p-3">
                                Course Details
                            </div>
                            <div className="card-body p-4 p-md-5">
                                <div className="row">
                                    <div className="col-12">
                                        {/* Assuming AdminUpload is another component */}
                                        <AdminUpload currentFiles={currentFiles} setCurrentFiles={setCurrentFiles} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* {currentStep === 2 && (
                        <div className="card border-0 rounded-3">
                            <div className="card-header bg-transparent fs-20 fw-600 text-bl p-3">
                                Translate
                            </div>
                            <div className="card-body p-5 text-center translate-box">
                                <h3>{progress === 100 ? 'Your files have been successfully translated into German' : 'Translating all files into German'}</h3>
                                <p>{progress === 100 ? 'Continue to review' : 'Please, wait...'}</p>

                                <div className='d-flex justify-content-center align-items-center gap-3 w-100 m-auto'>
                                    <img src={progress === 100 ? './admin/done-ring-round.png' : './admin/translate-duotone.png'} width={44} height={44} alt="Translation Progress" />
                                    <ProgressBar now={progress} />
                                    <span className='text-bl fs-12 fw-600 mb-0'>{progress}%</span>
                                </div>
                            </div>
                        </div>
                    )} */}

                    {currentStep === 2 && (
                        <PreviewoftheCourse courseData={courseData} setCourseData={setCourseData} isEdit={isEdit} setIsEdit={setIsEdit} handleCancel={handleCancel} />
                    )}

                    <div className='d-flex justify-content-end gap-3'>
                        {/* {currentStep > 1 && (
                            <button
                                className="btn-explore d-flex align-items-center gap-3 rounded-2 mt-4 fw-600"
                                onClick={handlePrev}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="14" viewBox="0 0 22 14" fill="none">
                                    <path d="M21 6.99854H1L6 1" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M1 6.99854L6 13" stroke="white" strokeWidth="1.5" strokeLinecap="round"></path>
                                </svg>
                                Back
                            </button>
                        )} */}
                        {currentStep === 1 && (
                            <>
                                <button
                                    className="btn-explore d-flex align-items-center gap-3 rounded-2 mt-4 fw-600"
                                    onClick={handleNext}
                                >
                                    {currentStep === 1 && 'Review'}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="14" viewBox="0 0 22 14" fill="none">
                                        <path d="M1 6.99854H21L16 1" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M21 6.99854L16 13" stroke="white" strokeWidth="1.5" strokeLinecap="round"></path>
                                    </svg>
                                </button>
                            </>
                        )}
                        {currentStep === 2 && !isEdit && (
                            <>
                                <button
                                    className="btn-explore d-flex align-items-center gap-3 rounded-2 mt-4 fw-600"
                                    onClick={handleSubmitCourse}
                                >
                                    Save and Continue
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="14" viewBox="0 0 22 14" fill="none">
                                        <path d="M1 6.99854H21L16 1" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M21 6.99854L16 13" stroke="white" strokeWidth="1.5" strokeLinecap="round"></path>
                                    </svg>
                                </button>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </AdminLayout>
    );
}

export default CourseCreationWizard;
