import React from 'react';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import SidbarCourses from '../components/SidbarCourses';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import CommunitySec from '../components/CommunitySec';
import { Link } from 'react-router-dom';
import CoursesListModal from '../components/CoursesListModal';
import GoldCoursesHome from '../components/GoldCoursesHome';

const GoldCourses = () => {
    const responsiveOptions = {
        0: {
            items: 1,
        },

        600: {
            items: 1,
        },

        1000: {
            items: 2,
        },

        1440: {
            items: 3,
        },
    };

    const featuredresponsiveOptions = {
        0: {
            items: 1,
        },
    };
    return (
        <div>
            <Header />
            <GoldCoursesHome />
            <section>
                <div className="featured-courses-main">
                    <div className="container">
                        <div className="row featured-courses-row">
                            <div className="col-md-4 col-lg-3 col-xl-2 d-none d-md-block">
                                <SidbarCourses />
                            </div>
                            <div className="col-md-8 col-lg-9 col-xl-10">
                                <div className="row justify-content-between align-items-center">
                                    <div className="col-12 col-md-6 col-lg-8">
                                        <div className="section-title col-md-12 col-lg-12 m-auto">
                                            <span className="d-block text-start">Featured Courses</span>
                                            <h2 className="text-start text-black display-6 sm-mb-3">
                                                Our Top Picks for You
                                            </h2>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-4 d-flex justify-content-md-end justify-content-between align-items-center mb-4 mb-md-0">
                                        <CoursesListModal />
                                        <Link
                                            to="/courses-explore"
                                            className="btn-explore d-flex align-items-center gap-3 rounded-2 docs-creator"
                                        >
                                            Explore More
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width={22}
                                                height={14}
                                                viewBox="0 0 22 14"
                                                fill="none"
                                            >
                                                <path
                                                    d="M1 6.99854H21L16 1"
                                                    stroke="white"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M21 6.99854L16 13"
                                                    stroke="white"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                />
                                            </svg>
                                        </Link>
                                    </div>
                                </div>
                                <OwlCarousel className='owl-theme featured-courses-carousel-1 pb-4' loop margin={10} nav={false} responsive={responsiveOptions}>
                                    <div className="item">
                                        <Link to="/compilance">
                                            <div className="card featured-card overflow-hidden border-0 position-relative accordion-items-shadow">
                                                <img
                                                    src="./featured-courses/featured01.jpg"
                                                    className="featured-courses-img"
                                                    alt=""
                                                />
                                                <span className="badge badge-warning bg-warning position-absolute top-0 start-0">
                                                    Bronze
                                                </span>
                                                <div className="card-body">
                                                    <span className="badge badge-light rounded-2 fw-400 fs-6">
                                                        Marketing and Sales
                                                    </span>
                                                    <h5>A Guide to Fraud Prevention</h5>
                                                    <p className="d-flex align-items-center gap-2 fw-500 text-dark mt-3 mb-4">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width={30}
                                                            height={30}
                                                            viewBox="0 0 30 30"
                                                            fill="none"
                                                        >
                                                            <circle cx={15} cy={15} r={15} fill="#D9D9D9" />
                                                        </svg>
                                                        Professor Sophia Lee
                                                    </p>
                                                    <div className="row justify-content-between align-items-center mt-4 mb-1">
                                                        <p className="fw-500 text-black mb-0 col-7">
                                                            Beginner Boost
                                                        </p>
                                                        <div className="col-5 d-flex justify-content-between align-items-center">
                                                            <ul className="rectangle d-flex align-items-center gap-2  flex-wrap mb-0 p-0">
                                                                <li className="active" />
                                                                <li className="active" />
                                                                <li className="active" />
                                                                <li />
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="row justify-content-between align-items-center">
                                                        <p className="fw-500 text-black col-6 mb-0">
                                                            Starter Pack
                                                        </p>
                                                        <div className="col-6 d-flex align-items-center gap-2">
                                                            <img
                                                                src="./featured-courses/piggy-bank.jpg"
                                                                className="wh-25"
                                                                alt=""
                                                            />
                                                            <p className="mb-0 text-black fw-600">Explore Free</p>
                                                        </div>
                                                    </div>
                                                    <button
                                                        type="button"
                                                        className="btn-primary d-flex align-items-center gap-3 rounded-2 mt-4"
                                                    >
                                                        Enroll Today
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width={22}
                                                            height={14}
                                                            viewBox="0 0 22 14"
                                                            fill="none"
                                                        >
                                                            <path
                                                                d="M1 6.99854H21L16 1"
                                                                stroke="white"
                                                                strokeWidth="1.5"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                            <path
                                                                d="M21 6.99854L16 13"
                                                                stroke="white"
                                                                strokeWidth="1.5"
                                                                strokeLinecap="round"
                                                            />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="item">
                                        <Link to="">
                                            <div className="card featured-card overflow-hidden border-0 position-relative accordion-items-shadow">
                                                <img
                                                    src="./featured-courses/featured02.jpg"
                                                    className="featured-courses-img"
                                                    alt=""
                                                />
                                                <span className="badge badge-warning bg-warning position-absolute top-0 start-0">
                                                    Silver
                                                </span>
                                                <div className="card-body">
                                                    <span className="badge badge-light rounded-2 fw-400 fs-6">
                                                        Marketing and Sales
                                                    </span>
                                                    <h5>Learning to Work with Figma</h5>
                                                    <p className="d-flex align-items-center gap-2 fw-500 text-dark mt-3 mb-4">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width={30}
                                                            height={30}
                                                            viewBox="0 0 30 30"
                                                            fill="none"
                                                        >
                                                            <circle cx={15} cy={15} r={15} fill="#D9D9D9" />
                                                        </svg>
                                                        Professor Sophia Lee
                                                    </p>
                                                    <div className="row justify-content-between align-items-center mt-4 mb-1">
                                                        <p className="fw-500 text-black mb-0 col-7">
                                                            Advanced Achievers
                                                        </p>
                                                        <div className="d-flex align-items-center col-5">
                                                            <ul className="rectangle d-flex align-items-center gap-2  flex-wrap mb-0 p-0">
                                                                <li className="active" />
                                                                <li className="active" />
                                                                <li className="active" />
                                                                <li />
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="row justify-content-between align-items-center">
                                                        <p className="fw-500 text-black col-6 mb-0">
                                                            Pro Membership
                                                        </p>
                                                        <div className="d-flex align-items-center gap-2 col-6">
                                                            <img
                                                                src="./featured-courses/diamond.jpg"
                                                                className="wh-25"
                                                                alt=""
                                                            />
                                                            <p className="mb-0 text-black fw-600">Premium Pass</p>
                                                        </div>
                                                    </div>
                                                    <button
                                                        type="button"
                                                        className="btn-secondary text-primary fw-600 d-flex align-items-center gap-3 rounded-2 mt-4"
                                                    >
                                                        Unlock It
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width={23}
                                                            height={14}
                                                            viewBox="0 0 23 14"
                                                            fill="none"
                                                        >
                                                            <path
                                                                d="M1.3335 6.99854H21.3335L16.3335 1"
                                                                stroke="#00237D"
                                                                strokeWidth="1.5"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                            <path
                                                                d="M21.3335 6.99854L16.3335 13"
                                                                stroke="#00237D"
                                                                strokeWidth="1.5"
                                                                strokeLinecap="round"
                                                            />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="item">
                                        <Link to="">
                                            <div className="card featured-card overflow-hidden border-0 position-relative accordion-items-shadow">
                                                <img
                                                    src="./featured-courses/featured03.jpg"
                                                    className="featured-courses-img"
                                                    alt=""
                                                />
                                                <span className="badge badge-warning bg-warning position-absolute top-0 start-0">
                                                    Gold
                                                </span>
                                                <div className="card-body">
                                                    <span className="badge badge-light rounded-2 fw-400 fs-6">
                                                        Marketing and Sales
                                                    </span>
                                                    <h5>Future of Web Design</h5>
                                                    <p className="d-flex align-items-center gap-2 fw-500 text-dark mt-3 mb-4">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width={30}
                                                            height={30}
                                                            viewBox="0 0 30 30"
                                                            fill="none"
                                                        >
                                                            <circle cx={15} cy={15} r={15} fill="#D9D9D9" />
                                                        </svg>
                                                        Professor Sophia Lee
                                                    </p>
                                                    <div className="row justify-content-between align-items-center mt-4 mb-1">
                                                        <p className="fw-500 text-black mb-0 col-6">
                                                            Beginner Boost
                                                        </p>
                                                        <div className="d-flex align-items-center col-6">
                                                            <ul className="rectangle d-flex align-items-center gap-2 gap-md-3 flex-wrap bm-0 p-0">
                                                                <li className="active" />
                                                                <li className="active" />
                                                                <li className="active" />
                                                                <li />
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="row justify-content-between align-items-center">
                                                        <p className="fw-500 text-black col-6 mb-0">
                                                            Starter Pack
                                                        </p>
                                                        <div className="d-flex col-6 align-items-center gap-2">
                                                            <img
                                                                src="./featured-courses/piggy-bank.jpg"
                                                                className="wh-25"
                                                                alt=""
                                                            />
                                                            <p className="mb-0 text-black fw-600">Explore Free</p>
                                                        </div>
                                                    </div>
                                                    <button
                                                        type="button"
                                                        className="btn-primary d-flex align-items-center gap-3 rounded-2 mt-4"
                                                    >
                                                        Enroll Today
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width={22}
                                                            height={14}
                                                            viewBox="0 0 22 14"
                                                            fill="none"
                                                        >
                                                            <path
                                                                d="M1 6.99854H21L16 1"
                                                                stroke="white"
                                                                strokeWidth="1.5"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                            <path
                                                                d="M21 6.99854L16 13"
                                                                stroke="white"
                                                                strokeWidth="1.5"
                                                                strokeLinecap="round"
                                                            />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="item">
                                        <Link to="">
                                            <div className="card featured-card overflow-hidden border-0 position-relative accordion-items-shadow">
                                                <img
                                                    src="./featured-courses/featured01.jpg"
                                                    className="featured-courses-img"
                                                    alt=""
                                                />
                                                <span className="badge badge-warning bg-warning position-absolute top-0 start-0">
                                                    Bronze
                                                </span>
                                                <div className="card-body">
                                                    <span className="badge badge-light rounded-2 fw-400 fs-6">
                                                        Marketing and Sales
                                                    </span>
                                                    <h5>A Guide to Fraud Prevention</h5>
                                                    <p className="d-flex align-items-center gap-2 fw-500 text-dark mt-3 mb-4">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width={30}
                                                            height={30}
                                                            viewBox="0 0 30 30"
                                                            fill="none"
                                                        >
                                                            <circle cx={15} cy={15} r={15} fill="#D9D9D9" />
                                                        </svg>
                                                        Professor Sophia Lee
                                                    </p>
                                                    <div className="row justify-content-between align-items-center mt-4 mb-1">
                                                        <p className="fw-500 text-black mb-0 col-7">
                                                            Beginner Boost
                                                        </p>
                                                        <div className="col-5 d-flex justify-content-between align-items-center">
                                                            <ul className="rectangle d-flex align-items-center gap-2  flex-wrap mb-0 p-0">
                                                                <li className="active" />
                                                                <li className="active" />
                                                                <li className="active" />
                                                                <li />
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="row justify-content-between align-items-center">
                                                        <p className="fw-500 text-black col-6 mb-0">
                                                            Starter Pack
                                                        </p>
                                                        <div className="col-6 d-flex align-items-center gap-2">
                                                            <img
                                                                src="./featured-courses/piggy-bank.jpg"
                                                                className="wh-25"
                                                                alt=""
                                                            />
                                                            <p className="mb-0 text-black fw-600">Explore Free</p>
                                                        </div>
                                                    </div>
                                                    <button
                                                        type="button"
                                                        className="btn-primary d-flex align-items-center gap-3 rounded-2 mt-4"
                                                    >
                                                        Enroll Today
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width={22}
                                                            height={14}
                                                            viewBox="0 0 22 14"
                                                            fill="none"
                                                        >
                                                            <path
                                                                d="M1 6.99854H21L16 1"
                                                                stroke="white"
                                                                strokeWidth="1.5"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                            <path
                                                                d="M21 6.99854L16 13"
                                                                stroke="white"
                                                                strokeWidth="1.5"
                                                                strokeLinecap="round"
                                                            />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="item">
                                        <Link to="">
                                            <div className="card featured-card overflow-hidden border-0 position-relative accordion-items-shadow">
                                                <img
                                                    src="./featured-courses/featured02.jpg"
                                                    className="featured-courses-img"
                                                    alt=""
                                                />
                                                <span className="badge badge-warning bg-warning position-absolute top-0 start-0">
                                                    Silver
                                                </span>
                                                <div className="card-body">
                                                    <span className="badge badge-light rounded-2 fw-400 fs-6">
                                                        Marketing and Sales
                                                    </span>
                                                    <h5>Learning to Work with Figma</h5>
                                                    <p className="d-flex align-items-center gap-2 fw-500 text-dark mt-3 mb-4">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width={30}
                                                            height={30}
                                                            viewBox="0 0 30 30"
                                                            fill="none"
                                                        >
                                                            <circle cx={15} cy={15} r={15} fill="#D9D9D9" />
                                                        </svg>
                                                        Professor Sophia Lee
                                                    </p>
                                                    <div className="row justify-content-between align-items-center mt-4 mb-1">
                                                        <p className="fw-500 text-black mb-0 col-7">
                                                            Advanced Achievers
                                                        </p>
                                                        <div className="d-flex align-items-center col-5">
                                                            <ul className="rectangle d-flex align-items-center gap-2  flex-wrap mb-0 p-0">
                                                                <li className="active" />
                                                                <li className="active" />
                                                                <li className="active" />
                                                                <li />
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="row justify-content-between align-items-center">
                                                        <p className="fw-500 text-black col-6 mb-0">
                                                            Pro Membership
                                                        </p>
                                                        <div className="d-flex align-items-center gap-2 col-6">
                                                            <img
                                                                src="./featured-courses/diamond.jpg"
                                                                className="wh-25"
                                                                alt=""
                                                            />
                                                            <p className="mb-0 text-black fw-600">Premium Pass</p>
                                                        </div>
                                                    </div>
                                                    <button
                                                        type="button"
                                                        className="btn-secondary text-primary fw-600 d-flex align-items-center gap-3 rounded-2 mt-4"
                                                    >
                                                        Unlock It
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width={23}
                                                            height={14}
                                                            viewBox="0 0 23 14"
                                                            fill="none"
                                                        >
                                                            <path
                                                                d="M1.3335 6.99854H21.3335L16.3335 1"
                                                                stroke="#00237D"
                                                                strokeWidth="1.5"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                            <path
                                                                d="M21.3335 6.99854L16.3335 13"
                                                                stroke="#00237D"
                                                                strokeWidth="1.5"
                                                                strokeLinecap="round"
                                                            />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="item">
                                        <Link to="">
                                            <div className="card featured-card overflow-hidden border-0 position-relative accordion-items-shadow">
                                                <img
                                                    src="./featured-courses/featured03.jpg"
                                                    className="featured-courses-img"
                                                    alt=""
                                                />
                                                <span className="badge badge-warning bg-warning position-absolute top-0 start-0">
                                                    Gold
                                                </span>
                                                <div className="card-body">
                                                    <span className="badge badge-light rounded-2 fw-400 fs-6">
                                                        Marketing and Sales
                                                    </span>
                                                    <h5>Future of Web Design</h5>
                                                    <p className="d-flex align-items-center gap-2 fw-500 text-dark mt-3 mb-4">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width={30}
                                                            height={30}
                                                            viewBox="0 0 30 30"
                                                            fill="none"
                                                        >
                                                            <circle cx={15} cy={15} r={15} fill="#D9D9D9" />
                                                        </svg>
                                                        Professor Sophia Lee
                                                    </p>
                                                    <div className="row justify-content-between align-items-center mt-4 mb-1">
                                                        <p className="fw-500 text-black mb-0 col-6">
                                                            Beginner Boost
                                                        </p>
                                                        <div className="d-flex align-items-center col-6">
                                                            <ul className="rectangle d-flex align-items-center gap-2 gap-md-3 flex-wrap bm-0 p-0">
                                                                <li className="active" />
                                                                <li className="active" />
                                                                <li className="active" />
                                                                <li />
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="row justify-content-between align-items-center">
                                                        <p className="fw-500 text-black col-6 mb-0">
                                                            Starter Pack
                                                        </p>
                                                        <div className="d-flex col-6 align-items-center gap-2">
                                                            <img
                                                                src="./featured-courses/piggy-bank.jpg"
                                                                className="wh-25"
                                                                alt=""
                                                            />
                                                            <p className="mb-0 text-black fw-600">Explore Free</p>
                                                        </div>
                                                    </div>
                                                    <button
                                                        type="button"
                                                        className="btn-primary d-flex align-items-center gap-3 rounded-2 mt-4"
                                                    >
                                                        Enroll Today
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width={22}
                                                            height={14}
                                                            viewBox="0 0 22 14"
                                                            fill="none"
                                                        >
                                                            <path
                                                                d="M1 6.99854H21L16 1"
                                                                stroke="white"
                                                                strokeWidth="1.5"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                            <path
                                                                d="M21 6.99854L16 13"
                                                                stroke="white"
                                                                strokeWidth="1.5"
                                                                strokeLinecap="round"
                                                            />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </OwlCarousel>
                                <CommunitySec />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default GoldCourses;

