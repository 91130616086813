import React from "react";

const Imprint = () => {
    return (
        <div className="col-12 col-lg-11 pb-5">
            <div className=" overflow-hidden order-3">
                <div className=" p-4 p-md-5">
                    <h2 className="fw-600 text-black mb-3">Imprint</h2>
                    <h4 className="fw-500 text-black mb-3">Responsible</h4>
                    <p className="fw-700 text-bl fs-7 mb-1">
                        UniCorso UG (haftungsbeschränkt)
                    </p>
                    <p className="mb-1 text-bl mb-1"> Alpenstrasse 18</p>
                    <p className="mb-1 text-bl mb-1"> 81541 Munich</p>
                    <p className="mb-1 text-bl mb-1"> Germany</p>
                    <h4 className="fw-500 pt-4">Represented by:</h4>
                    <p className="fw-600 text-muted mb-2 fs-7 text-bl mb-4">
                        Managing Director: Niels Beisinghoff
                    </p>

                    <h4>Contact:</h4>
                    <h5 className="mb-4">Email: info@unicorso.com</h5>
                    <h4>Register entry:</h4>
                    <p className="mb-1 text-bl mb-1">
                        Entry in the commercial register.
                    </p>
                    <p className="mb-1 text-bl mb-1">
                        Register Court: Amtsgericht München
                    </p>
                    <p className="mb-1 text-bl mb-4">
                        Register Number: HRB 291236
                    </p>

                    <h4>VAT ID:</h4>
                    <p className="mb-1 text-bl mb-4">
                        VAT identification number according to §27 a VAT Act:
                        DE367277219
                    </p>
                    <h4>Responsible for content according to MStV</h4>
                    <p className="mb-1 text-bl mb-4">See above</p>
                </div>
            </div>
        </div>
    );
};

export default Imprint;
