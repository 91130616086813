import React, { useRef, useState } from 'react';

function AdminimageUpload({ currentFiles,  setCurrentFiles } ) {
    const fileInputRef = useRef(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isUploaded, setIsUploaded] = useState(false);

    const handleClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
    
        // Validate the file type and extension
        if (!file) {
            console.error("No file selected.");
            return;
        }
    
        const validExtensions = ['.xlsx', '.xls'];
        const fileExtension = file.name.split('.').pop().toLowerCase();
    
        if (!validExtensions.includes(`.${fileExtension}`)) {
            console.error("Invalid file type. Please upload an XLSX or XLS file.");
            return;
        }
    
        setCurrentFiles(file);
        console.log(file); // Handle the file upload here
        // Simulate upload progress
        simulateUpload(file);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
    
        // Validate the file type and extension
        if (!file) {
            console.error("No file selected.");
            return;
        }
    
        const validExtensions = ['.xlsx', '.xls'];
        const fileExtension = file.name.split('.').pop().toLowerCase();
    
        if (!validExtensions.includes(`.${fileExtension}`)) {
            console.error("Invalid file type. Please upload an XLSX or XLS file.");
            return;
        }
    
        setCurrentFiles(file);
        console.log(file); // Handle the file upload here
        // Simulate upload progress
        simulateUpload(file);
    };
    

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const simulateUpload = (file) => {
        let progress = 0;
        const interval = setInterval(() => {
            progress += 10; // Increase progress by 10%
            setUploadProgress(progress);
            if (progress >= 100) {
                setIsUploaded(true); // Mark upload as complete
                clearInterval(interval);
            }
        }, 100); // Simulate upload time (100ms)
    };

    const handleDelete = () => {
        setCurrentFiles(null);
        setUploadProgress(0);
        setIsUploaded(false);
        fileInputRef.current.value = ''; // Clear the file input
    };

    const getFileSize = (file) => {
        return file ? (file.size / 1024).toFixed(2) + ' KB' : '0 KB'; // Convert to KB
    };

    return (
        <>
            <div className="row">
                <div className="col-12 col-lg-6">
                    <div
                        className="upload-file"
                        onClick={handleClick}
                        onDrop={handleDrop}
                        onDragOver={handleDragOver}
                    >
                        <div className="d-flex flex-column align-items-center">
                            <img src="../admin/upload-img.png" alt="Upload Icon" style={{ maxHeight: '100px' }} />
                            <div className='upload-text-open mt-4'>
                                <p> <span>Click to Upload</span> or drag file to this area</p>
                            </div>
                        </div>
                        <p className='mb-size-text'> (Max. File size: 25 MB)</p>
                    </div>
                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        accept=".xlsx"
                        onChange={handleFileChange}
                    />
                </div>
                <div className="col-12 col-lg-6 d-flex justify-content-end mt-4 mt-lg-0">
                    <div className='ps-0 ps-lg-5 w-100'>
                        {uploadProgress > 0 && (
                            <div className='uploadfile-box p-3'>
                                <div className='d-flex justify-content-between align-items-start gap-1 mt-2'>
                                    <div className='d-flex align-items-start gap-2'>
                                        <img src="../admin/document.png" alt="" />
                                        <div>
                                            <p className='filename'>{currentFiles.name}</p>
                                            <p className='text-uppercase mb-0 text-start file-size-name'>{getFileSize(currentFiles)}</p>
                                        </div>
                                    </div>
                                    <div className='d-flex'>
                                        {!isUploaded && (
                                            <button onClick={handleDelete} className='trash-btn'>
                                                <img src="../admin/trash.png" alt="" />
                                            </button>
                                        )}
                                        {isUploaded && (
                                                <img src="../admin/check-circle.png" width={20} height={20} alt="Uploaded" />
                                     
                                        )}
                                    </div>
                                </div>
                                <div className='d-flex align-items-center gap-3 mt-3 me-5'>
                                    <div className='upload-progress-box'>
                                        <div
                                            className='upload-progress'
                                            style={{
                                                width: `${uploadProgress}%`,
                                                backgroundColor: uploadProgress === 100 ? '#4caf50' : '#002FA7', // Change color on completion
                                            }}
                                        ></div>
                                    </div>
                                    <p className='filename mb-0 fs-12'>{uploadProgress}%</p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdminimageUpload;
