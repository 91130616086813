import React from 'react'

const OnlyMe = () => {
    return (
        <>
            <div className="table-responsive table-responsive-xxl pricing-table">
                <table className="table align-middle table-sm">
                    <thead>
                        <tr className="border-bottom-0 border-white">
                            <th />
                            <th>Stater Pack</th>
                            <th>Pro Mastery</th>
                            <th>Family Fusion</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <p className="fw-600">Courses</p>
                            </td>
                            <td>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={41}
                                    height={40}
                                    viewBox="0 0 41 40"
                                    fill="none"
                                >
                                    <mask
                                        id="mask0_257_338"
                                        style={{ maskType: "alpha" }}
                                        maskUnits="userSpaceOnUse"
                                        x={0}
                                        y={0}
                                        width={41}
                                        height={40}
                                    >
                                        <rect
                                            x="0.166748"
                                            width={40}
                                            height={40}
                                            fill="#D9D9D9"
                                        />
                                    </mask>
                                    <g mask="url(#mask0_257_338)">
                                        <path
                                            d="M16.0835 30.0002L6.5835 20.5002L8.9585 18.1252L16.0835 25.2502L31.3752 9.9585L33.7502 12.3335L16.0835 30.0002Z"
                                            fill="#D2E23D"
                                        />
                                    </g>
                                </svg>
                            </td>
                            <td>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={41}
                                    height={40}
                                    viewBox="0 0 41 40"
                                    fill="none"
                                >
                                    <mask
                                        id="mask0_257_338"
                                        style={{ maskType: "alpha" }}
                                        maskUnits="userSpaceOnUse"
                                        x={0}
                                        y={0}
                                        width={41}
                                        height={40}
                                    >
                                        <rect
                                            x="0.166748"
                                            width={40}
                                            height={40}
                                            fill="#D9D9D9"
                                        />
                                    </mask>
                                    <g mask="url(#mask0_257_338)">
                                        <path
                                            d="M16.0835 30.0002L6.5835 20.5002L8.9585 18.1252L16.0835 25.2502L31.3752 9.9585L33.7502 12.3335L16.0835 30.0002Z"
                                            fill="#D2E23D"
                                        />
                                    </g>
                                </svg>
                            </td>
                            <td>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={41}
                                    height={40}
                                    viewBox="0 0 41 40"
                                    fill="none"
                                >
                                    <mask
                                        id="mask0_257_338"
                                        style={{ maskType: "alpha" }}
                                        maskUnits="userSpaceOnUse"
                                        x={0}
                                        y={0}
                                        width={41}
                                        height={40}
                                    >
                                        <rect
                                            x="0.166748"
                                            width={40}
                                            height={40}
                                            fill="#D9D9D9"
                                        />
                                    </mask>
                                    <g mask="url(#mask0_257_338)">
                                        <path
                                            d="M16.0835 30.0002L6.5835 20.5002L8.9585 18.1252L16.0835 25.2502L31.3752 9.9585L33.7502 12.3335L16.0835 30.0002Z"
                                            fill="#D2E23D"
                                        />
                                    </g>
                                </svg>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p className="fw-600">
                                    LinkedIn Achievement Badge
                                </p>
                            </td>
                            <td>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={41}
                                    height={40}
                                    viewBox="0 0 41 40"
                                    fill="none"
                                >
                                    <mask
                                        id="mask0_257_338"
                                        style={{ maskType: "alpha" }}
                                        maskUnits="userSpaceOnUse"
                                        x={0}
                                        y={0}
                                        width={41}
                                        height={40}
                                    >
                                        <rect
                                            x="0.166748"
                                            width={40}
                                            height={40}
                                            fill="#D9D9D9"
                                        />
                                    </mask>
                                    <g mask="url(#mask0_257_338)">
                                        <path
                                            d="M16.0835 30.0002L6.5835 20.5002L8.9585 18.1252L16.0835 25.2502L31.3752 9.9585L33.7502 12.3335L16.0835 30.0002Z"
                                            fill="#D2E23D"
                                        />
                                    </g>
                                </svg>
                            </td>
                            <td>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={41}
                                    height={40}
                                    viewBox="0 0 41 40"
                                    fill="none"
                                >
                                    <mask
                                        id="mask0_257_338"
                                        style={{ maskType: "alpha" }}
                                        maskUnits="userSpaceOnUse"
                                        x={0}
                                        y={0}
                                        width={41}
                                        height={40}
                                    >
                                        <rect
                                            x="0.166748"
                                            width={40}
                                            height={40}
                                            fill="#D9D9D9"
                                        />
                                    </mask>
                                    <g mask="url(#mask0_257_338)">
                                        <path
                                            d="M16.0835 30.0002L6.5835 20.5002L8.9585 18.1252L16.0835 25.2502L31.3752 9.9585L33.7502 12.3335L16.0835 30.0002Z"
                                            fill="#D2E23D"
                                        />
                                    </g>
                                </svg>
                            </td>
                            <td>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={41}
                                    height={40}
                                    viewBox="0 0 41 40"
                                    fill="none"
                                >
                                    <mask
                                        id="mask0_257_338"
                                        style={{ maskType: "alpha" }}
                                        maskUnits="userSpaceOnUse"
                                        x={0}
                                        y={0}
                                        width={41}
                                        height={40}
                                    >
                                        <rect
                                            x="0.166748"
                                            width={40}
                                            height={40}
                                            fill="#D9D9D9"
                                        />
                                    </mask>
                                    <g mask="url(#mask0_257_338)">
                                        <path
                                            d="M16.0835 30.0002L6.5835 20.5002L8.9585 18.1252L16.0835 25.2502L31.3752 9.9585L33.7502 12.3335L16.0835 30.0002Z"
                                            fill="#D2E23D"
                                        />
                                    </g>
                                </svg>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p className="fw-600">
                                    Leaderboard and Analytics
                                </p>
                            </td>
                            <td>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={41}
                                    height={40}
                                    viewBox="0 0 41 40"
                                    fill="none"
                                >
                                    <mask
                                        id="mask0_257_368"
                                        style={{ maskType: "alpha" }}
                                        maskUnits="userSpaceOnUse"
                                        x={0}
                                        y={0}
                                        width={41}
                                        height={40}
                                    >
                                        <rect
                                            x="0.166748"
                                            width={40}
                                            height={40}
                                            fill="#D9D9D9"
                                        />
                                    </mask>
                                    <g mask="url(#mask0_257_368)">
                                        <path
                                            d="M10.8333 31.6668L8.5 29.3335L17.8333 20.0002L8.5 10.6668L10.8333 8.3335L20.1667 17.6668L29.5 8.3335L31.8333 10.6668L22.5 20.0002L31.8333 29.3335L29.5 31.6668L20.1667 22.3335L10.8333 31.6668Z"
                                            fill="#FFBACC"
                                        />
                                    </g>
                                </svg>
                            </td>
                            <td>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={41}
                                    height={40}
                                    viewBox="0 0 41 40"
                                    fill="none"
                                >
                                    <mask
                                        id="mask0_257_338"
                                        style={{ maskType: "alpha" }}
                                        maskUnits="userSpaceOnUse"
                                        x={0}
                                        y={0}
                                        width={41}
                                        height={40}
                                    >
                                        <rect
                                            x="0.166748"
                                            width={40}
                                            height={40}
                                            fill="#D9D9D9"
                                        />
                                    </mask>
                                    <g mask="url(#mask0_257_338)">
                                        <path
                                            d="M16.0835 30.0002L6.5835 20.5002L8.9585 18.1252L16.0835 25.2502L31.3752 9.9585L33.7502 12.3335L16.0835 30.0002Z"
                                            fill="#D2E23D"
                                        />
                                    </g>
                                </svg>
                            </td>
                            <td>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={41}
                                    height={40}
                                    viewBox="0 0 41 40"
                                    fill="none"
                                >
                                    <mask
                                        id="mask0_257_338"
                                        style={{ maskType: "alpha" }}
                                        maskUnits="userSpaceOnUse"
                                        x={0}
                                        y={0}
                                        width={41}
                                        height={40}
                                    >
                                        <rect
                                            x="0.166748"
                                            width={40}
                                            height={40}
                                            fill="#D9D9D9"
                                        />
                                    </mask>
                                    <g mask="url(#mask0_257_338)">
                                        <path
                                            d="M16.0835 30.0002L6.5835 20.5002L8.9585 18.1252L16.0835 25.2502L31.3752 9.9585L33.7502 12.3335L16.0835 30.0002Z"
                                            fill="#D2E23D"
                                        />
                                    </g>
                                </svg>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p className="fw-600">
                                    Allow access to multiple users
                                </p>
                            </td>
                            <td>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={41}
                                    height={40}
                                    viewBox="0 0 41 40"
                                    fill="none"
                                >
                                    <mask
                                        id="mask0_257_368"
                                        style={{ maskType: "alpha" }}
                                        maskUnits="userSpaceOnUse"
                                        x={0}
                                        y={0}
                                        width={41}
                                        height={40}
                                    >
                                        <rect
                                            x="0.166748"
                                            width={40}
                                            height={40}
                                            fill="#D9D9D9"
                                        />
                                    </mask>
                                    <g mask="url(#mask0_257_368)">
                                        <path
                                            d="M10.8333 31.6668L8.5 29.3335L17.8333 20.0002L8.5 10.6668L10.8333 8.3335L20.1667 17.6668L29.5 8.3335L31.8333 10.6668L22.5 20.0002L31.8333 29.3335L29.5 31.6668L20.1667 22.3335L10.8333 31.6668Z"
                                            fill="#FFBACC"
                                        />
                                    </g>
                                </svg>
                            </td>
                            <td>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={41}
                                    height={40}
                                    viewBox="0 0 41 40"
                                    fill="none"
                                >
                                    <mask
                                        id="mask0_257_368"
                                        style={{ maskType: "alpha" }}
                                        maskUnits="userSpaceOnUse"
                                        x={0}
                                        y={0}
                                        width={41}
                                        height={40}
                                    >
                                        <rect
                                            x="0.166748"
                                            width={40}
                                            height={40}
                                            fill="#D9D9D9"
                                        />
                                    </mask>
                                    <g mask="url(#mask0_257_368)">
                                        <path
                                            d="M10.8333 31.6668L8.5 29.3335L17.8333 20.0002L8.5 10.6668L10.8333 8.3335L20.1667 17.6668L29.5 8.3335L31.8333 10.6668L22.5 20.0002L31.8333 29.3335L29.5 31.6668L20.1667 22.3335L10.8333 31.6668Z"
                                            fill="#FFBACC"
                                        />
                                    </g>
                                </svg>
                            </td>
                            <td>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={41}
                                    height={40}
                                    viewBox="0 0 41 40"
                                    fill="none"
                                >
                                    <mask
                                        id="mask0_257_338"
                                        style={{ maskType: "alpha" }}
                                        maskUnits="userSpaceOnUse"
                                        x={0}
                                        y={0}
                                        width={41}
                                        height={40}
                                    >
                                        <rect
                                            x="0.166748"
                                            width={40}
                                            height={40}
                                            fill="#D9D9D9"
                                        />
                                    </mask>
                                    <g mask="url(#mask0_257_338)">
                                        <path
                                            d="M16.0835 30.0002L6.5835 20.5002L8.9585 18.1252L16.0835 25.2502L31.3752 9.9585L33.7502 12.3335L16.0835 30.0002Z"
                                            fill="#D2E23D"
                                        />
                                    </g>
                                </svg>
                            </td>
                        </tr>
                        <tr className="border-bottom-0 border-white">
                            <td />
                            <td>
                                <div className="d-flex justify-content-center">
                                    <button
                                        type="button"
                                        className="btn-primary rounded-2 mx-2"
                                    >
                                        Enroll Today
                                    </button>
                                </div>
                            </td>
                            <td>
                                <div className="d-flex justify-content-center">
                                    <button
                                        type="button"
                                        className="btn-primary rounded-2 mx-2"
                                    >
                                        3 €‎ / Month
                                    </button>
                                </div>
                            </td>
                            <td className="d-flex justify-content-center">
                                <div className="d-flex justify-content-center">
                                    <button
                                        type="button"
                                        className="btn-primary rounded-2 mx-2"
                                    >
                                        6 €‎ / Month
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default OnlyMe