import { useState } from "react";
import { Modal } from "react-bootstrap";
import TostMessage from "./TostMessage";
import api from "../../../helper/api";
import { getServerURL } from "../../../helper/envConfig";
import { toast } from "react-toastify";

function ExportDataModal({ user_ids }) {
    const serverURL = getServerURL();
    const [show, setShow] = useState(false);
    const [isTostMessageVisible, setIsTostMessageVisible] = useState(false);
    const [selectedField, setSelectedFields] = useState([]);
    const [fileType, setFileType] = useState("");

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleCheckBoxChange = (e) => {
        const field = e.target.name;
        if (selectedField.includes(field)) {
            setSelectedFields(selectedField.filter((item) => item !== field));
        } else {
            setSelectedFields([...selectedField, field]);
        }
    };

    const handleChangeFileType = (e) => {
        if (e.target.checked) {
            setFileType(e.target.value);
        } else {
            setFileType("");
        }
    };

    const handleSendCommunication = async () => {
        try {
            if (selectedField.length === 0) {
                toast.error("please select fields");
                return;
            }

            if (fileType === "") {
                toast.error("please select format type");
                return;
            }

            const response = await api.postWithToken(
                `${serverURL}admin-panel/user-management/export-data`,
                {
                    selected_user_id_list: user_ids,
                    column_list: selectedField,
                    file_type: fileType,
                }
            );

            const url = URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.download = `export-data.${fileType}`;
            link.click();
            URL.revokeObjectURL(url);
            setIsTostMessageVisible(true);
            setShow(false);
            setSelectedFields([]);
            setFileType("");
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            <button className="export-data" onClick={handleShow}>
                <img src="./admin/export.png" alt="" />
                Export Data
            </button>

            <Modal
                show={show}
                className="table-bottom-modal"
                onHide={handleClose}
                size="lg"
                centered
            >
                <Modal.Header closeButton className="border-0 pb-0 px-4">
                    <Modal.Title className="pt-4">Export Data</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-4 pt-0">
                    <p className="fs-8 fw-500 mt-3">
                        Select data fields to include
                    </p>
                    <div className="row format-export-radio">
                        <div className="col-4">
                            <div className="manage-family-checkbox">
                                <div class="form-check mb-2">
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        value="user_id"
                                        id="flexCheckChecked1"
                                        name="user_id"
                                        onChange={handleCheckBoxChange}
                                    />
                                    <label
                                        class="form-check-label"
                                        for="flexCheckChecked1"
                                    >
                                        ID
                                    </label>
                                </div>
                            </div>
                            <div className="manage-family-checkbox">
                                <div class="form-check mb-2">
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="flexCheckChecked2"
                                        name="username"
                                        onChange={handleCheckBoxChange}
                                    />
                                    <label
                                        class="form-check-label"
                                        for="flexCheckChecked2"
                                    >
                                        Name
                                    </label>
                                </div>
                            </div>
                            <div className="manage-family-checkbox">
                                <div class="form-check mb-2">
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="flexCheckChecked3"
                                        name="email"
                                        onChange={handleCheckBoxChange}
                                    />
                                    <label
                                        class="form-check-label"
                                        for="flexCheckChecked3"
                                    >
                                        Email
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="manage-family-checkbox">
                                <div class="form-check mb-2">
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="flexCheckChecked4"
                                        name="role"
                                        onChange={handleCheckBoxChange}
                                    />
                                    <label
                                        class="form-check-label"
                                        for="flexCheckChecked4"
                                    >
                                        Role
                                    </label>
                                </div>
                            </div>
                            <div className="manage-family-checkbox">
                                <div class="form-check mb-2">
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="flexCheckChecked5"
                                        name="is_active"
                                        onChange={handleCheckBoxChange}
                                    />
                                    <label
                                        class="form-check-label"
                                        for="flexCheckChecked5"
                                    >
                                        Status
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p className="fs-8 fw-500 mt-3">
                        Select the format of the export{" "}
                    </p>
                    <div className="d-flex align-items-center gap-3 format-export-radio">
                        <label className="d-flex align-items-center">
                            <input
                                type="radio"
                                class="input-radio-box on"
                                name="fileType"
                                value="csv"
                                onChange={handleChangeFileType}
                            />{" "}
                            CSV
                        </label>
                        <label className="d-flex align-items-center">
                            <input
                                type="radio"
                                class="input-radio-box on"
                                name="fileType"
                                value="pdf"
                                onChange={handleChangeFileType}
                            />{" "}
                            PDF
                        </label>
                    </div>

                    <div className="d-flex justify-content-end">
                        <button
                            className="apply-btn mt-4"
                            onClick={handleSendCommunication}
                        >
                            Export Data
                        </button>
                    </div>
                </Modal.Body>
            </Modal>

            <div className={isTostMessageVisible ? "d-block" : "d-none"}>
                <TostMessage setShow={setIsTostMessageVisible}  msg={"File download successfully"} />
            </div>
        </>
    );
}

export default ExportDataModal;
