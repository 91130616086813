import React, { useEffect, useState } from "react";
import AdminLayout from "../../components/Admin/AdminLayout";
import ApexCharts from "react-apexcharts";
import { getServerURL } from "../../helper/envConfig";
import api from "../../helper/api";
import CommonLoader from "../../components/Admin/CommonLoader";

function AdminFamily() {
    const serverURL = getServerURL();
    const [familyData, setFamilyData] = useState({
        family_count: 0,
        family_monthly_data: {},
        family_percentage_change: "0",
    });
    const [kidsData, setKidsData] = useState({
        kids_count: 0,
        kids_monthly_data: {},
        kids_percentage_change: "0",
    });

    const [showLoader, setShowLoader] = useState(true);


    const getFamilyData = async () => {
        try {
            const response = await api.getWithToken(
                `${serverURL}admin-panel/family-dashboard-data`
            );
            setFamilyData(response.data.data.family);
            setKidsData(response.data.data.kids);
            setShowLoader(false)
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getFamilyData();
    }, []);

    const chartsData = [
        {
            series: [
                {
                    name: "Users",
                    data: Object.values(familyData?.family_monthly_data),
                },
            ],
            options: {
                chart: {
                    type: "area",
                    height: 350,
                    toolbar: {
                        show: false,
                    },
                    // background: 'linear-gradient(180deg, rgba(130, 128, 255, 0.4) -28.37%, rgba(130, 128, 255, 0) 95.04%)'
                },
                grid: {
                    show: false,
                },
                colors: ["#7B9AC1"],
                dataLabels: {
                    enabled: false,
                },
                tooltip: {
                    enabled: false,
                },

                fill: {
                    type: "gradient",
                    gradient: {
                        shade: "light",
                        type: "vertical",
                        shadeIntensity: 0.5,
                        gradientToColors: ["#000"],
                        opacityFrom: 1,
                        opacityTo: 1,
                        stops: [0, 100],
                        colorStops: [
                            { offset: 0, color: "#8280FF66", opacity: 1 },
                            {
                                offset: 100,
                                color: "rgba(221, 242, 244, 0)",
                                opacity: 1,
                            },
                        ],
                    },
                },

                stroke: {
                    width: 2,
                    colors: ["#8280FF"],
                    curve: "smooth",
                },
                xaxis: {
                    categories: [
                        "Jan",
                        "Feb",
                        "Mar",
                        "Apr",
                        "May",
                        "Jun",
                        "Jul",
                        "Aug",
                        "Sep",
                        "Oct",
                        "Nov",
                        "Dec",
                    ],
                    labels: {
                        show: true,
                        style: {
                            colors: "#737B8B", // Text color for y-axis labels
                            fontSize: "10px", // Font size for y-axis labels
                            fontFamily: "Poppins, sans-serif", // Font family for y-axis labels
                            fontWeight: 400, // Font weight for y-axis labels
                            cssClass: "apexcharts-yaxis-label", // CSS class for further customization
                        },
                    },
                    axisBorder: {
                        show: true,
                    },
                    axisTicks: {
                        show: true,
                    },
                },
                yaxis: {
                    min: 0, // Minimum value on y-axis
                    max: Math.max(
                        ...Object.values(familyData?.family_monthly_data)
                    ), // Maximum value on y-axis
                    tickAmount: 3, // Set the number of ticks to match desired intervals
                    labels: {
                        // formatter: function (value) {
                        //     if ([0, 200, 400, 600].includes(value)) {
                        //         return value;  // Only show these specific values
                        //     }
                        //     return '';  // Hide other values
                        // },
                        show: true,
                        style: {
                            colors: "#737B8B", // Text color for y-axis labels
                            fontSize: "10px", // Font size for y-axis labels
                            fontFamily: "Poppins, sans-serif", // Font family for y-axis labels
                            fontWeight: 400, // Font weight for y-axis labels
                            cssClass: "apexcharts-yaxis-label", // CSS class for further customization
                        },
                    },
                    // labels: {
                    //     show: true,
                    // },
                    axisBorder: {
                        show: true,
                    },
                    axisTicks: {
                        show: true,
                    },
                },
            },
            name: "Number of Families",
            value: familyData?.family_count,
            imageUrl2: `${
                Number(familyData.family_percentage_change) < 0
                    ? "./admin/arrow-down.png"
                    : "./admin/up-down.png"
            }`,
            percentChange: `${
                Math.abs(familyData?.family_percentage_change) || 0
            }%`,
            des: "vs last month",
            rangeclass: `${
                Number(familyData.family_percentage_change) < 0
                    ? "down-range"
                    : "up-range"
            }`,
        },
        {
            series: [
                {
                    name: "Engagement",
                    data: Object.values(kidsData?.kids_monthly_data),
                },
            ],
            options: {
                chart: {
                    type: "area",
                    height: 350,
                    toolbar: {
                        show: false,
                    },
                    // background: 'linear-gradient(180deg, rgba(218, 129, 132, 0.4) -28.37%, rgba(202, 107, 110, 0) 95.04%)'
                },
                grid: {
                    show: false,
                },
                colors: ["#D46A6A"],
                dataLabels: {
                    enabled: false,
                },
                tooltip: {
                    enabled: false,
                },
                fill: {
                    type: "gradient",
                    gradient: {
                        shade: "dark",
                        type: "vertical",
                        shadeIntensity: 0.5,
                        gradientToColors: ["#F9C4C4"],
                        opacityFrom: 1,
                        opacityTo: 1,
                        stops: [0, 100],
                        colorStops: [
                            { offset: 0, color: "#F9C4C4", opacity: 1 },
                            {
                                offset: 100,
                                color: "rgba(249, 196, 196, 0)",
                                opacity: 1,
                            },
                        ],
                    },
                },
                stroke: {
                    width: 2,
                    colors: ["#D46A6A"],
                    curve: "smooth",
                },
                xaxis: {
                    categories: [
                        "Jan",
                        "Feb",
                        "Mar",
                        "Apr",
                        "May",
                        "Jun",
                        "Jul",
                        "Aug",
                        "Sep",
                        "Oct",
                        "Nov",
                        "Dec",
                    ],
                    labels: {
                        show: true,
                        style: {
                            colors: "#737B8B", // Text color for y-axis labels
                            fontSize: "10px", // Font size for y-axis labels
                            fontFamily: "Poppins, sans-serif", // Font family for y-axis labels
                            fontWeight: 400, // Font weight for y-axis labels
                            cssClass: "apexcharts-yaxis-label", // CSS class for further customization
                        },
                    },
                    axisBorder: {
                        show: true,
                    },
                    axisTicks: {
                        show: true,
                    },
                },
                yaxis: {
                    min: 0, // Minimum value on y-axis
                    max: Math.max(
                        ...Object.values(kidsData?.kids_monthly_data)
                    ), // Maximum value on y-axis
                    tickAmount: 3, // Set the number of ticks to match desired intervals
                    labels: {
                        // formatter: function (value) {
                        //     if ([0, 200, 400, 600].includes(value)) {
                        //         return value;  // Only show these specific values
                        //     }
                        //     return '';  // Hide other values
                        // },
                        show: true,
                        style: {
                            colors: "#737B8B", // Text color for y-axis labels
                            fontSize: "10px", // Font size for y-axis labels
                            fontFamily: "Poppins, sans-serif", // Font family for y-axis labels
                            fontWeight: 400, // Font weight for y-axis labels
                            cssClass: "apexcharts-yaxis-label", // CSS class for further customization
                        },
                    },
                    // labels: {
                    //     show: true,
                    // },
                    axisBorder: {
                        show: true,
                    },
                    axisTicks: {
                        show: true,
                    },
                },
            },
            name: "Number of Kids",
            value: kidsData.kids_count,
            imageUrl2: `${
                Number(kidsData.kids_percentage_change) < 0
                    ? "./admin/arrow-down.png"
                    : "./admin/up-down.png"
            }`,
            percentChange: `${Math.abs(kidsData.kids_percentage_change)}%`,
            des: "vs last month",
            rangeclass: `${
                Number(kidsData.kids_percentage_change) < 0
                    ? "down-range"
                    : "up-range"
            }`,
        },
    ];

    return (
        <AdminLayout>
            {showLoader ? (
                <CommonLoader />
            ) : (
                <>
                    <h2 className="page-title">Family </h2>
                    <div className="row mt-1 g-4">
                        {chartsData.map((chart, index) => (
                            <div className="col-12 col-lg-6" key={index}>
                                <div className="card border-0 rounded-4 analytics-card">
                                    <div className="card-body">
                                        <div>
                                            <div>
                                                <div
                                                    key={index}
                                                    className="d-flex justify-content-between align-items-start gap-3"
                                                >
                                                    <div>
                                                        <p className="fw-600 fs-6">
                                                            {chart.name}
                                                        </p>
                                                        <h2>{chart.value}</h2>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center gap-2 mt-3">
                                                    <img
                                                        src={chart.imageUrl2}
                                                        alt="Up Arrow"
                                                    />
                                                    <p className="mb-0 text-light-700">
                                                        <span
                                                            className={chart.rangeclass}
                                                        >
                                                            {chart.percentChange}
                                                        </span>{" "}
                                                        {chart.des}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <ApexCharts
                                            options={chart.options}
                                            series={chart.series}
                                            type="area"
                                            height={200}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            )}
        </AdminLayout>
    );
}

export default AdminFamily;
