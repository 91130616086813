import React, { useRef, useState, useEffect, useContext } from 'react'
import CourseSidebar from './CourseSidebar'
import Header from '../layout/Header'
import Footer from '../layout/Footer'
import { Link } from 'react-router-dom'
import { decryptFromStore, encryptAndStore } from '../helper/sessionStore'
import { decryptFromStoreLoc, encryptAndStoreLoc } from '../helper/localStore'
import api from "../helper/api";
import { getImageBaseUrl, getServerURL } from '../helper/envConfig';
import { useNavigate } from 'react-router-dom'; // Import useHistory for redirection
import { useLocation } from 'react-router-dom'
import { CourseContext } from '../context/CourseContext';
import { toast } from 'react-toastify'
import CertificateComponent from './CertificateComponent'
import { BreadcrumbSkeleton, ButtonSkeleton, ContentSkeleton, QuizResultsSkeleton, SidebarSkeleton, TitleBadgeSkeleton } from './SkeletonLayouts'
import { Modal } from 'react-bootstrap'
import { Is_Login } from '../helper/IsLogin'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const QuizeResults = () => {

    const [values, setValues] = useState();
    const { user, token, loder, setLoder, getCourses, setIs_search, setSearch } = useContext(CourseContext);
    const [loader, setLoader] = useState(true)
    const [result, setResult] = useState();
    const [QuizAns, setQuizAns] = useState([]);
    const [QuizData, setQuizData] = useState();
    const [modalShow, setModalShow] = useState(false)
    const [noteClose, setNoteClose] = useState(false)
    const [currentBadgeIndex, setCurrentBadgeIndex] = useState(0);
    const [badges, setBadges] = useState([]);
    const [closedBadges, setClosedBadges] = useState([]); // Tracks IDs of closed notifications
    const [chatGptAnswer, setChatGptAnswer] = useState({})
    const [showHorseGif, setShowHorseGif] = useState(false)

    const serverURL = getServerURL();
    const history = useNavigate();
    const { pathname } = useLocation()
    const isLoggedIn = Is_Login();


    useEffect(() => {
        // Retrieve and decrypt stored information
        const decryptedInfo = decryptFromStoreLoc('user');
        setValues(decryptedInfo)
    }, [pathname]);

    useEffect(() => {
        // Load stored answers from session storage
        const storedAnswers = decryptFromStore('FinalAnswers');

        if (storedAnswers?.quiz_id) {
            // Format the taken time
            // Assuming the stored time is in the format "HH : MM"
            // and you want it in the format "HH:MM:SS"

            // const timeParts = storedAnswers.taken_time.split(':').map(s => s.trim());
            // Parse the hours, minutes, and seconds into numbers
            // const hours = parseInt(timeParts[0], 10);
            // const minutes = parseInt(timeParts[1], 10);
            // const seconds = timeParts.length > 2 ? parseInt(timeParts[2], 10) : 0;

            // Format the time to "H:MM:SS"
            // const formattedTime = `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

            // Construct the new object with the desired format
            const formattedStoredAnswers = {
                course_name: storedAnswers.course_name,
                user_answer: storedAnswers.aiAnswer,
                result_id: storedAnswers.resultId,
            };

            const getQuizesAns = async () => {
                try {
                    setLoder(true)
                    setLoader(true)
                    const apiTyp = isLoggedIn ? api.postWithToken : api.post;
                    const response = await apiTyp(`${serverURL}quizzes/chat-gpt-answer/`, formattedStoredAnswers);
                    if (response?.data?.chat_gpt_answer) {
                        setChatGptAnswer(response?.data?.chat_gpt_answer)
                        setShowHorseGif(response?.data?.chat_gpt_answer.grade == "Fail" ? false : true)
                    }
                    if (response?.data?.badges?.length) {
                        setModalShow(true)
                        setBadges(response?.data?.badges)
                    }
                    setQuizAns(response?.data);
                    if (response.data?.result?.passed) {
                        setResult({ result_id: response.data?.result.result_id })
                        encryptAndStoreLoc("result", { result_id: response.data?.result.result_id })
                    }
                    if (response.data.retake) {
                        setQuizData({
                            retake: response.data?.retake,
                            course_details: response.data?.result?.course_details?.name
                        })
                    }
                    setLoder(false)
                    setLoader(false)
                    encryptAndStore('resultId', response.data?.result.result_id);
                    encryptAndStore('chatGptResult', response.data);

                    setTimeout(() => {
                        setShowHorseGif(false)
                    }, 2000);
                } catch (error) {
                    setLoder(false)
                    console.log(error);
                }
            };

            // Call the function to get quiz results
            if (storedAnswers?.result_api_data) {
                const data = storedAnswers?.result_api_data
                if (data?.chat_gpt_answer) {
                    setChatGptAnswer(data?.chat_gpt_answer)
                    setShowHorseGif(data?.chat_gpt_answer?.grade == "Fail" ? false : true)
                }
                if (data?.badges?.length) {
                    setModalShow(true)
                    setBadges(data?.badges)
                }
                setQuizAns(data);
                if (data?.result?.passed) {
                    setResult({ result_id: data?.result.result_id })
                    encryptAndStoreLoc("result", { result_id: data?.result.result_id })
                }
                if (data.retake) {
                    setQuizData({
                        retake: data?.retake,
                        course_details: data?.result?.course_details?.name
                    })
                }
                setLoder(false)
                setLoader(false)
            } else {
                const resultId = decryptFromStore('resultId');
                const chatGptResult = decryptFromStore('chatGptResult');
                if (resultId && chatGptResult) {
                    if (chatGptResult.chat_gpt_answer) {
                        setChatGptAnswer(chatGptResult.chat_gpt_answer)
                        setShowHorseGif(chatGptResult.chat_gpt_answer.grade == "Fail" ? false : true)
                    }
                    if (chatGptResult.badges?.length) {
                        setModalShow(true)
                        setBadges(chatGptResult.badges)
                    }
                    setQuizAns(chatGptResult);
                    if (chatGptResult?.result?.passed) {
                        setResult({ result_id: chatGptResult.result.result_id })
                        encryptAndStoreLoc("result", { result_id: chatGptResult.result.result_id })
                    }
                    if (chatGptResult.retake) {
                        setQuizData({
                            retake: chatGptResult.retake,
                            course_details: chatGptResult.result?.course_details?.name
                        })
                    }
                    setLoder(false)
                    setLoader(false)
                    setTimeout(() => {
                        setShowHorseGif(false)
                    }, 2000);
                } else {
                    getQuizesAns();
                }

            }
        }
    }, [isLoggedIn]); // Add serverURL to the dependency array

    const formatDuration = (timeString) => {

        if (typeof timeString !== 'string' || timeString.length === 0) {
            // Handle invalid input
            return 'Invalid time';
        }

        const parts = timeString.split(':').map(part => parseInt(part, 10));
        const hours = parts[0];
        const minutes = parts[1];
        const seconds = parts.length > 2 ? parts[2] : 0;

        let formattedTime = '';

        if (hours > 0) {
            formattedTime += `${hours} hour${hours > 1 ? 's' : ''} `;
        }
        if (minutes > 0 || (hours > 0 && seconds > 0)) {
            formattedTime += `${minutes} minute${minutes !== 1 ? 's' : ''} `;
        }
        if (seconds > 0) {
            formattedTime += `${seconds} second${seconds !== 1 ? 's' : ''}`;
        }

        return formattedTime.trim();
    };

    const retake = () => {
        encryptAndStore('quiz', {
            question_id: QuizData?.retake?.first_question_id,
            quiz_id: QuizData?.retake?.quiz_id,
            name: QuizData?.course_details
        });
        sessionStorage.removeItem('FinalAnswers');
        sessionStorage.removeItem('answers');
        sessionStorage.removeItem('chatGptResult');
        history("/quiz");
    }

    const getCertificate = async () => {
        if (result?.result_id) {
            var response2 = await api.postWithToken(`${serverURL}quizzes/send-certificate/`, {
                "user_id": values?.user_id,
                "result_id": result?.result_id
            });
            sessionStorage.removeItem('FinalAnswers');
            sessionStorage.removeItem('chatGptResult');
            sessionStorage.removeItem('quiz');
            sessionStorage.removeItem('answers');
            sessionStorage.removeItem('answers_index');
            sessionStorage.removeItem('answers_count');
            localStorage.removeItem("result")
            sessionStorage.removeItem('isSubmit');
            sessionStorage.removeItem('isTrue');
            sessionStorage.removeItem('true_id');
            sessionStorage.removeItem('remainingPathColor');
            toast(response2?.data?.message);
            history("/")
        } else {
            toast.error("you are fail give exam again");
        }
    }

    // Assuming listCourse?.course_details?.category_name might have spaces
    const categoryName = QuizAns?.result?.course_details?.category_name.replace(/ /g, '-');

    const handlePress = (data) => {
        setSearch(data)
        getCourses(); // Call the function you want to execute on Enter key press
        setIs_search(true)
        history("/courses-explore")
        window.scrollTo(0, 500);
    };

    const currentBadge = badges[currentBadgeIndex]; // Get the current badge to display

    const handleContinue = () => {
        if (currentBadgeIndex < badges.length - 1) {
            // Move to the next badge
            setCurrentBadgeIndex(currentBadgeIndex + 1);
        } else {
            // No more badges, close the modal and perform any additional actions
            setModalShow(false);
            setNoteClose(true); // Assuming setNoteClose is another state setter you have
        }
    };

    const handleClose = (badgeId) => {
        // Add the badgeId to the list of closed badges
        setClosedBadges(prevClosedBadges => [...prevClosedBadges, badgeId]);
    };


    const [currentNumber, setCurrentNumber] = useState(0);
    const [animationRunning, setAnimationRunning] = useState(false);
    const animationRef = useRef(null);

    // Start the animation automatically when component mounts
    useEffect(() => {
        increaseNumberAnimation(100, 19); // Adjust endNumber and speed as needed
    }, []); // Empty dependency array ensures this effect runs only once

    // Function to start or repeat the number animation
    const increaseNumberAnimation = (endNumber, speed) => {
        if (animationRunning) return;

        setAnimationRunning(true);
        incNbrRec(0, endNumber, speed);
    }

    // Recursive function to increment the number
    const incNbrRec = (current, end, speed) => {
        if (current <= end) {
            setCurrentNumber(current);
            animationRef.current = setTimeout(() => {
                incNbrRec(current + 1, end, speed);
            }, speed);
        } else {
            setAnimationRunning(false);
            // Auto-repeat after a pause (e.g., 1 second)
            setTimeout(() => {
                increaseNumberAnimation(end, speed);
            }, 0);
        }
    }

    // Clean up on component unmount to prevent memory leaks
    useEffect(() => {
        return () => {
            clearTimeout(animationRef.current);
        };
    }, []);


    const onFeedbackClick = () => {
        if(token) {
            encryptAndStore('feedback', {
                id: QuizAns?.result?.course_details?.course_id
            });
            history("/feedback");
        } else {
            history("/sign-in",  { state: { from: "feedback" , course_id: QuizAns?.result?.course_details?.course_id } });
        }
    }

    return (
        <>

            <Modal show={modalShow} onHide={() => setModalShow(false)} className='modalCtmContent' centered scrollable size="lg">

                {currentBadge && modalShow &&
                    <Modal.Body className="p-0">
                        <div className='p-0'>
                            <img src="./svg/modalHeader.svg" alt='' width={'100%'} />
                            <div className='d-flex justify-content-center'>
                                <img src={getImageBaseUrl() + currentBadge?.image} width={'180px'} height={'180px'} style={{ marginTop: '-100px' }} alt='' />
                            </div>
                        </div>
                        <div className='px-5 pb-5 text-center justify-content-center'>
                            <p className='congoText'>Congrats!</p>
                            <div className='d-flex justify-content-center'>
                                <p className='congoSubText text-center'>Congratulations for earing  {currentBadge.name} badge! </p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <p className='congoSubText1'> {currentBadge.description}</p>
                            </div>
                            <div className='mb-5 mt-3'>
                                <p>Share with friends</p>
                                <div className='d-flex justify-content-center gap-3'>
                                    <Link to="https://www.linkedin.com/company/unicorso">
                                        <img src="/svg/linkedin.svg" alt='' /></Link>
                                    <img src="/svg/instagram.svg" alt='' />
                                    <img src="/svg/facebook.svg" alt='' />
                                    <img src="/svg/xicon.svg" alt='' />
                                    <img src="/svg/email.svg" alt='' />
                                </div>
                            </div>
                            <a class="btn-primary rounded-2 w-auto mt-5 cursor-pointer"
                                // href="/#"
                                onClick={handleContinue}
                            >
                                Continue
                                <span class="ps-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="14" viewBox="0 0 22 14" fill="none">
                                        <path d="M1 6.99854H21L16 1" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                        <path d="M21 6.99805L16 12.9995" stroke="white" stroke-width="1.5" stroke-linecap="round"></path>
                                    </svg>
                                </span>
                            </a>
                        </div>

                        {/* </div> */}
                    </Modal.Body>
                }

            </Modal>



            <Header />

            {loader ? (
                <div className="container">
                    {/* <BreadcrumbSkeleton />
                    <TitleBadgeSkeleton />
                    <div className="row">
                        <div className="col-lg-8">
                            <ContentSkeleton />
                            <ContentSkeleton />
                            <ContentSkeleton />
                            <ContentSkeleton />
                            <ContentSkeleton />
                            <ButtonSkeleton />
                        </div>
                    </div> */}
                    <div className="row g-4 g-md-3 g-lg-4 g-xl-5 py-4 py-md-5">
                        <div className="col-12">
                            <div className="card featured-card linear-gradient rounded-3 overflow-hidden border-0">
                                <div className="card-body px-4 pt-5">
                                    <div className="d-flex justify-content-center ai-step-imge loading-imag position-relative mb-0">
                                        <img
                                            src="/png/loading.png"
                                            alt=""
                                        />

                                        <div className='loading-text'>
                                            <h2>Loading...</h2>
                                            <img
                                                src="/png/loading-animation.gif"
                                                alt=""
                                            />
                                            <h3>Please Wait  <span id="nbr">({currentNumber}%)</span></h3>
                                        </div>
                                    </div>
                                    <p className='wait-title'>Hi there! Please wait for a moment. It should not take more than 30 seconds usually. We are currently grading your answer. Best greetings from your unicorso team</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            ) : (
                <section>
                    <div className="featured-main">
                        <div className="container">
                            {Boolean(noteClose) &&
                                <>
                                    {badges?.length && badges.map((data) => (
                                        // Only render if the badge has not been closed
                                        !closedBadges.includes(data.badge_id) && (
                                            <div className='d-flex bg-note p-3 gap-3 mb-2' key={data.badge_id}>
                                                <div> <img src="/svg/right.svg" alt='' /></div>
                                                <div className='d-flex justify-content-between w-100 '>
                                                    <div>
                                                        <p className='note-1'>You are {data.name} now!</p>
                                                        <p className='note-2'>The badge has already been added to your profile. Check it out now.</p>
                                                        <button className='viewProfileBtn py-2 px-3' onClick={() => history('/customer-home')}>View Profile</button>
                                                    </div>
                                                    <div>
                                                        <img src="/svg/close.svg" className='px-2 mt-2' onClick={() => handleClose(data.badge_id)} alt='' />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    ))}
                                </>
                            }
                            <div className="breadcrumb-main">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-3 mb-md-5">
                                        <li className="breadcrumb-item">
                                            <Link to="/">Home</Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <Link to={`/courses-category/${categoryName}`} >{QuizAns?.result?.course_details?.category_name}
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <Link to="">{QuizAns?.result?.course_details && QuizAns?.result?.course_details?.type_name} Course</Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            {QuizAns?.result?.course_details?.name}
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                            <div className="row g-4 g-md-3 g-lg-4 g-xl-5">
                                <div className="col-md-12 col-lg-8">
                                    <div className="d-flex align-items-center gap-3 flex-wrap mb-3">
                                        <span onClick={() => handlePress(QuizAns?.result?.course_details?.tags_list[0].name)} className="badge badge-black-2 text-white rounded-2 fw-400 fs-7 md-0 mb-md-4">
                                            {QuizAns?.result?.course_details?.tags_list.length && QuizAns?.result?.course_details?.tags_list[0].name}
                                        </span>
                                        <span className={
                                            QuizAns?.result?.course_details?.type_name === "Bronze" ? "badge badge-bronze rounded-2 fw-400 fs-7 md-0 mb-md-4" :
                                                QuizAns?.result?.course_details?.type_name === "Silver" ? "badge badge-silver rounded-2 fw-400 fs-7 md-0 mb-md-4" :
                                                    QuizAns?.result?.course_details?.type_name === "Gold" ? "badge badge-gold rounded-2 fw-400 md-0 mb-md-4" :
                                                        QuizAns?.result?.course_details?.type_name === "Free" ? "badge badge-light text-black rounded-2 fw-400 fs-7 md-0 mb-md-4" :
                                                            ""
                                        }>
                                            {QuizAns?.result?.course_details?.type_name}
                                        </span>
                                    </div>
                                    <h2 className="text-black fw-400 mb-4 mb-md-5 mb-lg-6">
                                        {QuizAns?.result?.course_details?.name}
                                    </h2>
                                    <div className="col-xl-10 m-auto px-0">
                                        <div className="card featured-card linear-gradient girlimge rounded-3 border-0 position-relative">
                                            <div className="card-body px-4 pt-5">
                                                <div className="d-flex justify-content-center">
                                                    <img
                                                        src={`featured-courses/${QuizAns?.result?.passed ? "girl-party.jpg" : "failImage.png"}`}
                                                        alt=""
                                                    />
                                                </div>
                                                <h2 className="text-center pt-4 pt-md-5 fw-600 text-black">
                                                    You {QuizAns?.result?.passed ? "passed" : "failed"} Score
                                                </h2>
                                                <h6 className="text-center fw-600 text-black fail-text">
                                                    {/* You  {QuizAns?.result?.passed ? "passed" : "failed"}  Score */}
                                                    {chatGptAnswer?.grade || "Fail"}
                                                </h6>
                                                {/* <div className="text-center display-2 fw-600 text-black pt-3">
                                                    {QuizAns?.result?.score}%
                                                </div> */}
                                                <p className="text-center text-black text-black pt-0 fs-5 mb-0 fw-600 d-flex justify-content-center align-items-center gap-2">
                                                    Grade Explanation
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={<Tooltip id="tooltip-example" className='custom-tooltip'>
                                                            <p className='mb-0'>There are four grades:</p>
                                                            <p className='mb-0'>- Passed with 3 stars</p>
                                                            <p className='mb-0'>- Passed with 2 stars</p>
                                                            <p className='mb-0'>- Passed with 1 star</p>
                                                            <p className='mb-0'>- Fail</p>
                                                        </Tooltip>}
                                                    >
                                                        <span className="d-inline-block">
                                                            <img src='png/questionMark.png' alt='' style={{ height: "20px", width: "20px" }} />
                                                        </span>
                                                    </OverlayTrigger>
                                                </p>
                                                <p className="text-center text-black text-black pt-4 fs-5 mb-0 fw-600">
                                                    Time taken
                                                </p>
                                                {/* <CertificateComponent name={user?.username}/> */}

                                                <div className="row justify-content-center g-0">
                                                    <div className="col-12 col-md-7 col-lg-7 col-xl-5">
                                                        <button
                                                            type="button"
                                                            className="btn-explore p-sm-15 text-white text-center rounded-2 w-100 mt-2 mb-3"
                                                        >
                                                            {formatDuration(QuizAns?.result?.time_taken)}
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className='d-flex justify-content-center'>
                                                    {!QuizAns?.result?.passed &&
                                                        <button
                                                            type="button"
                                                            className="btn-primary p-sm-15 text-white text-center rounded-2 w-auto mt-2 mb-5"
                                                            onClick={retake}
                                                        >
                                                            Retake
                                                        </button>
                                                    }
                                                </div>

                                            </div>
                                            {chatGptAnswer.grade !== "Fail" && showHorseGif &&
                                                <div className='horse-gif'>
                                                    <img
                                                        src="/png/3.gif"
                                                        alt=""
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </div>

                                    {
                                        <div className="col-xl-10 m-auto px-0">
                                            <h2 className="text-center text-black fw-500 mt-3 fs-1">
                                                You got {QuizAns?.result?.score / 10} out of 10 questions right
                                            </h2>
                                            <h4 className="text-center text-black fw-400 mt-3 fs-3">
                                                More details on grade page
                                            </h4>
                                            <p className='text-center mt-3'>
                                                You got it {QuizAns?.result?.score}% right. The answer was well written and had all the basic concepts of
                                                the fraud prevention. You only missed out on...
                                            </p>
                                            {chatGptAnswer && Object.keys(chatGptAnswer).length > 0 &&
                                                <>
                                                    <p>
                                                        Grading Breakdown:
                                                    </p>
                                                    <ul className='gpt-details '>
                                                        <li> <span className='fw-600'>Content Knowledge: </span> {chatGptAnswer?.content_knowledge?.score_out_of_25}/24 {chatGptAnswer?.content_knowledge?.exploration}
                                                        </li>
                                                        <li> <span className='fw-600'>Completeness: </span> {chatGptAnswer?.completeness?.score_out_of_10}/10 {chatGptAnswer?.completeness?.exploration}
                                                        </li>
                                                        <li> <span className='fw-600'>Understanding: </span> {chatGptAnswer?.understanding?.score_out_of_10}/10 {chatGptAnswer?.understanding?.exploration}
                                                        </li>
                                                        <li> <span className='fw-600'>Structure/Organization: </span> {chatGptAnswer?.structure_organization?.score_out_of_10}/10  {chatGptAnswer?.structure_organization?.exploration}
                                                        </li>
                                                        <li> <span className='fw-600'>Use of Evidence: </span> {chatGptAnswer?.use_of_evidence?.score_out_of_10}/10  {chatGptAnswer?.use_of_evidence?.exploration}
                                                        </li>
                                                        <li> <span className='fw-600'>Writing Quality: </span> {chatGptAnswer?.writing_quality?.score_out_of_10}/10 {chatGptAnswer?.writing_quality?.exploration}
                                                        </li>
                                                        <li> <span className='fw-600'>Creativity/Originality: </span> {chatGptAnswer?.creativity_originality?.score_out_of_25}/25 {chatGptAnswer?.creativity_originality?.exploration}
                                                        </li>
                                                        <li> <span className='fw-600'>Bonus points for creativity or deep understanding or content knowledge: </span> {chatGptAnswer?.bonus_points_for_creativity_or_deep_understanding?.score_out_of_40}/40 {chatGptAnswer?.bonus_points_for_creativity_or_deep_understanding?.exploration}
                                                        </li>
                                                    </ul>

                                                    <p> <span className='fw-600'>Total: </span> {chatGptAnswer.total_score_out_of_140 || 0}/140 </p>
                                                    <p> <span className='fw-600'>Grade: </span> {chatGptAnswer.grade} </p>

                                                    <br />

                                                    <p>
                                                        Detailed feedback:
                                                    </p>
                                                    <p>
                                                        {chatGptAnswer.detailed_feedback}
                                                    </p>
                                                </>
                                            }
                                        </div>
                                    }

                                    {
                                        QuizAns?.result?.passed &&

                                        <div className="card border-0">
                                            <div className="card-body">
                                                <div className="px-0 px-md-4 py-4 py-md-5">
                                                    <span className="text-primary text-center fw-500 fs-6 d-block">
                                                        Join the learning adventure
                                                    </span>

                                                    {token &&

                                                        <h2 className="text-center text-black fw-400 mt-3 fs-1 col-12 col-xl-9 m-auto">
                                                            You want a certificate? It is free.
                                                        </h2>
                                                    }
                                                    {!token &&
                                                        <>
                                                            <h2 className="text-center text-black fw-400 mt-3 fs-1 col-12 col-xl-9 m-auto">
                                                                You want a certificate? It is free. You just need to sign up.
                                                            </h2>
                                                            <h4 className="text-black pt-3 pt-md-4 text-center fs-sm-5 col-12 col-xl-10 fw-400 m-auto">
                                                                Creating an account is quick, easy, and completely free!
                                                            </h4>
                                                        </>
                                                    }

                                                    <p className="text-black fw-400 fs-18 fs-sm-6 mt-3 mt-md-4 text-center">
                                                        Do you want a certificate that you can show to your employer
                                                        and show on LinkedIn for example?
                                                    </p>

                                                    {!token ?
                                                        <div className="d-flex justify-content-center">
                                                            <Link
                                                                to="/sign-up"
                                                                className="btn-primary btn-light-warning text-black m-auto rounded-2 mt-4 fw-600"
                                                            >
                                                                Sign Up
                                                                <span className='ps-2'>
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={22}
                                                                        height={14}
                                                                        viewBox="0 0 22 14"
                                                                        fill="none"
                                                                    >
                                                                        <path
                                                                            d="M1 6.99854H21L16 1"
                                                                            stroke="#000C2A"
                                                                            strokeWidth="1.5"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        />
                                                                        <path
                                                                            d="M21 6.99805L16 12.9995"
                                                                            stroke="#000C2A"
                                                                            strokeWidth="1.5"
                                                                            strokeLinecap="round"
                                                                        />
                                                                    </svg>
                                                                </span>
                                                            </Link>
                                                        </div> :

                                                        <div className="d-flex justify-content-center">
                                                            <Link
                                                                // to="/sign-up"
                                                                onClick={getCertificate}
                                                                className="btn-primary btn-light-warning text-black m-auto rounded-2 mt-4 fw-600"
                                                            >
                                                                Get certificate for free
                                                                <span className='ps-2'>
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={22}
                                                                        height={14}
                                                                        viewBox="0 0 22 14"
                                                                        fill="none"
                                                                    >
                                                                        <path
                                                                            d="M1 6.99854H21L16 1"
                                                                            stroke="#000C2A"
                                                                            strokeWidth="1.5"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        />
                                                                        <path
                                                                            d="M21 6.99805L16 12.9995"
                                                                            stroke="#000C2A"
                                                                            strokeWidth="1.5"
                                                                            strokeLinecap="round"
                                                                        />
                                                                    </svg>
                                                                </span>
                                                            </Link>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    <div className='d-flex justify-content-center'>
                                        <button className='btn-primary text-white text-center rounded-2 mt-5 w-auto feedback-btn'
                                            onClick={onFeedbackClick}>
                                            Feedback
                                        </button>
                                    </div>
                                </div>
                                {/* <div className="col-md-12 col-lg-4">
                                    <div className="row justify-content-between align-items-start g-0 mb-2">
                                        <p className="col-6 col-md-7 fw-500 text-black mb-0 fs-7 fs-sm-7">
                                            Advanced Achievers
                                        </p>
                                        <div className="col-6 col-md-5 d-flex align-items-center">
                                            <ul className="rectangle rectangle2 d-flex align-items-center gap-2 gap-md-3 flex-wrap mb-0 p-0">
                                                <li className="active" />
                                                <li className="active" />
                                                <li className="active" />
                                                <li className="active" />
                                            </ul>
                                        </div>

                                        <div class="d-flex align-items-center gap-3 flex-wrap mt-3">
                                            {QuizAns?.result?.course_details?.tags_list.length && QuizAns?.result?.course_details?.tags_list?.map((tagList, index) => (
                                                <span onClick={() => handlePress(tagList.name)} key={index} class="badge badge-black-2 text-white rounded-2 fw-400 fs-7">{tagList.name}</span>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="row justify-content-end align-items-center g-0 mb-2 pt-4 mb-4 mb-md-5 mb-lg-6">
                                        <p className="col-6 col-md-7 fw-400 text-black mb-0 fs-7 fs-sm-7 fs-sm-7">
                                            Duration
                                        </p>
                                        <div className="col-6 col-md-5 d-flex justify-content-end align-items-center gap-2">
                                            <img
                                                src="./featured-courses/hourglass.png"
                                                className="wh-20"
                                                alt=""
                                            />
                                            <p className="mb-0 text-black fw-500 fs-7 fs-sm-7"> {QuizAns?.result?.quiz_details?.max_allowed_time ? formatDuration(QuizAns?.result?.quiz_details?.max_allowed_time) : "0  minutes"}  </p>
                                        </div>
                                    </div>
                                    <CourseSidebar course_id={QuizAns?.result?.course_details?.course_id} is_bookmarked={QuizAns?.is_bookmarked} token={token} name={QuizAns?.result?.course_details?.name} type={QuizAns?.result?.course_details && QuizAns?.result?.course_details?.type_name} />
                                </div> */}
                            </div>
                        </div>
                    </div>
                </section>
            )}


            <Footer />
        </>
    )
}

export default QuizeResults
