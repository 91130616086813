import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';

import Header from '../layout/Header';
import Footer from '../layout/Footer';

function CourseLevel() {
    const [activeButton, setActiveButton] = useState('btn-1');

    const handleButtonClick = (buttonName) => {
        setActiveButton(buttonName);
    };
    return (
        <>
            <Header />
            <section>
                <div className="courses-main">
                    <div className="container">
                        <div className="courses-categories bg-secondary d-flex justify-content-center align-items-center align-items-md-start  align-items-xl-center rounded-4 position-relative overflow-hidden">
                            <div className="courses-home z-3">
                                <p className="text-black text-center fw-400 fs-19 pb-2 col-10 col-md-10 col-lg-12 col-xl-12 m-auto">
                                    Mini Courses
                                </p>
                                <h2 className="text-black text-center fw-400 mb-5 fs-1 col-10 col-md-10 col-lg-12 col-xl-12 m-auto">
                                    Quick Bites of Knowledge
                                </h2>
                                <div className="d-md-flex justify-content-center m-auto mt-4 col-10 col-md-10 col-lg-12 col-xl-12">
                                    <div className="subscribe-box d-md-flex justify-content-center">
                                        <div className="col-12 col-md-6 col-lg-9 col-xl-10">
                                            <input
                                                type="text"
                                                className="form-control rounded-start-2 rounded-end-0 border-0 h-100 ps-4 bg-white"
                                                placeholder="Search the course"
                                            />
                                        </div>
                                        <div className="col-12 col-md-6 col-xl-4 col-xl-6">
                                            <button
                                                type="button"
                                                className="btn-explore bg-warning text-black text-center gap-3 sm-w-100 rounded-start-0 rounded-end-2 mt-4 mt-md-0"
                                            >
                                                Let’s Search
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={25}
                                                    height={24}
                                                    viewBox="0 0 25 24"
                                                    fill="none"
                                                >
                                                    <mask
                                                        id="mask0_168_1865"
                                                        style={{ maskType: "alpha" }}
                                                        maskUnits="userSpaceOnUse"
                                                        x={0}
                                                        y={0}
                                                        width={25}
                                                        height={24}
                                                    >
                                                        <rect x="0.5" width={24} height={24} fill="#D9D9D9" />
                                                    </mask>
                                                    <g mask="url(#mask0_168_1865)">
                                                        <path
                                                            d="M6.9 18L5.5 16.6L15.1 7H6.5V5H18.5V17H16.5V8.4L6.9 18Z"
                                                            fill="#000C2A"
                                                        />
                                                    </g>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <img
                                src="./featured-courses/ezgif.com-rotate.gif"
                                className="courses-gift"
                                alt=""
                            />
                            <img
                                src="./svg/courses-vector-light-1.svg"
                                className="courses-vector-1"
                                alt=""
                            />
                            <img
                                src="./svg/courses-vector-light-2.svg"
                                className="courses-vector-2"
                                alt=""
                            />
                            <img
                                src="./featured-courses/juicy-online-presentation.svg"
                                className="juicy-online"
                                alt=""
                            />
                        </div>
                    </div>
                </div>

            </section>
            <section>
                <div className="container">
                    <div className="card bg-transparent border-0">
                        <div className="card-body px-0">
                            <ul className="footer-btn-list btn-active mt-4 mt-lg-4 mt-xl-4 p-0">
                                <li>
                                    <button
                                        type="button"
                                        className={`btn-primary bg-transparent d-flex align-items-center text-primary gap-2 rounded-2 ${activeButton === 'btn-1' ? 'active' : ''}`}
                                        onClick={() => handleButtonClick('btn-1')}
                                    >
                                        Technology and Innovation
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={24}
                                            height={24}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                        >
                                            <mask
                                                id="mask0_250_9727"
                                                style={{ maskType: "alpha" }}
                                                maskUnits="userSpaceOnUse"
                                                x={0}
                                                y={0}
                                                width={24}
                                                height={24}
                                            >
                                                <rect width={24} height={24} fill="#D9D9D9" />
                                            </mask>
                                            <g mask="url(#mask0_250_9727)">
                                                <path
                                                    d="M6.4 18L5 16.6L14.6 7H6V5H18V17H16V8.4L6.4 18Z"
                                                    fill="#002FA7"
                                                ></path>
                                            </g>
                                        </svg>
                                    </button>
                                </li>
                                <li>
                                    <button
                                        type="button"
                                        className={`btn-primary bg-transparent d-flex align-items-center text-primary gap-2 rounded-2 ${activeButton === 'btn-2' ? 'active' : ''}`}
                                        onClick={() => handleButtonClick('btn-2')}
                                    >
                                        Leadership and Management
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={24}
                                            height={24}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                        >
                                            <mask
                                                id="mask0_250_9727"
                                                style={{ maskType: "alpha" }}
                                                maskUnits="userSpaceOnUse"
                                                x={0}
                                                y={0}
                                                width={24}
                                                height={24}
                                            >
                                                <rect width={24} height={24} fill="#D9D9D9" />
                                            </mask>
                                            <g mask="url(#mask0_250_9727)">
                                                <path
                                                    d="M6.4 18L5 16.6L14.6 7H6V5H18V17H16V8.4L6.4 18Z"
                                                    fill="#002FA7"
                                                ></path>
                                            </g>
                                        </svg>
                                    </button>
                                </li>
                                <li>
                                    <button
                                        type="button"
                                        className={`btn-primary bg-transparent d-flex align-items-center text-primary gap-2 rounded-2 ${activeButton === 'btn-3' ? 'active' : ''}`}
                                        onClick={() => handleButtonClick('btn-3')}
                                    >
                                        Technical and Security
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={24}
                                            height={24}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                        >
                                            <mask
                                                id="mask0_250_9727"
                                                style={{ maskType: "alpha" }}
                                                maskUnits="userSpaceOnUse"
                                                x={0}
                                                y={0}
                                                width={24}
                                                height={24}
                                            >
                                                <rect width={24} height={24} fill="#D9D9D9" />
                                            </mask>
                                            <g mask="url(#mask0_250_9727)">
                                                <path
                                                    d="M6.4 18L5 16.6L14.6 7H6V5H18V17H16V8.4L6.4 18Z"
                                                    fill="#002FA7"
                                                ></path>
                                            </g>
                                        </svg>
                                    </button>
                                </li>
                                <li>
                                    <button
                                        type="button"
                                        className={`btn-primary bg-transparent d-flex align-items-center text-primary gap-2 rounded-2 ${activeButton === 'btn-4' ? 'active' : ''}`}
                                        onClick={() => handleButtonClick('btn-4')}
                                    >
                                        Personal Development
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={24}
                                            height={24}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                        >
                                            <mask
                                                id="mask0_250_9727"
                                                style={{ maskType: "alpha" }}
                                                maskUnits="userSpaceOnUse"
                                                x={0}
                                                y={0}
                                                width={24}
                                                height={24}
                                            >
                                                <rect width={24} height={24} fill="#D9D9D9" />
                                            </mask>
                                            <g mask="url(#mask0_250_9727)">
                                                <path
                                                    d="M6.4 18L5 16.6L14.6 7H6V5H18V17H16V8.4L6.4 18Z"
                                                    fill="#002FA7"
                                                ></path>
                                            </g>
                                        </svg>
                                    </button>
                                </li>
                                <li>
                                    <button
                                        type="button"
                                        className={`btn-primary bg-transparent d-flex align-items-center text-primary gap-2 rounded-2 ${activeButton === 'btn-5' ? 'active' : ''}`}
                                        onClick={() => handleButtonClick('btn-5')}
                                    >
                                        Health and Wellness
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={24}
                                            height={24}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                        >
                                            <mask
                                                id="mask0_250_9727"
                                                style={{ maskType: "alpha" }}
                                                maskUnits="userSpaceOnUse"
                                                x={0}
                                                y={0}
                                                width={24}
                                                height={24}
                                            >
                                                <rect width={24} height={24} fill="#D9D9D9" />
                                            </mask>
                                            <g mask="url(#mask0_250_9727)">
                                                <path
                                                    d="M6.4 18L5 16.6L14.6 7H6V5H18V17H16V8.4L6.4 18Z"
                                                    fill="#002FA7"
                                                ></path>
                                            </g>
                                        </svg>
                                    </button>
                                </li>
                                <li>
                                    <button
                                        type="button"
                                        className={`btn-primary bg-transparent d-flex align-items-center text-primary gap-2 rounded-2 ${activeButton === 'btn-6' ? 'active' : ''}`}
                                        onClick={() => handleButtonClick('btn-6')}
                                    >
                                        Environmental Sustainability
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={24}
                                            height={24}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                        >
                                            <mask
                                                id="mask0_250_9727"
                                                style={{ maskType: "alpha" }}
                                                maskUnits="userSpaceOnUse"
                                                x={0}
                                                y={0}
                                                width={24}
                                                height={24}
                                            >
                                                <rect width={24} height={24} fill="#D9D9D9" />
                                            </mask>
                                            <g mask="url(#mask0_250_9727)">
                                                <path
                                                    d="M6.4 18L5 16.6L14.6 7H6V5H18V17H16V8.4L6.4 18Z"
                                                    fill="#002FA7"
                                                ></path>
                                            </g>
                                        </svg>
                                    </button>
                                </li>
                                <li>
                                    <button
                                        type="button"
                                        className={`btn-primary bg-transparent d-flex align-items-center text-primary gap-2 rounded-2 ${activeButton === 'btn-7' ? 'active' : ''}`}
                                        onClick={() => handleButtonClick('btn-7')}
                                    >
                                        Education
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={24}
                                            height={24}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                        >
                                            <mask
                                                id="mask0_250_9727"
                                                style={{ maskType: "alpha" }}
                                                maskUnits="userSpaceOnUse"
                                                x={0}
                                                y={0}
                                                width={24}
                                                height={24}
                                            >
                                                <rect width={24} height={24} fill="#D9D9D9" />
                                            </mask>
                                            <g mask="url(#mask0_250_9727)">
                                                <path
                                                    d="M6.4 18L5 16.6L14.6 7H6V5H18V17H16V8.4L6.4 18Z"
                                                    fill="#002FA7"
                                                ></path>
                                            </g>
                                        </svg>
                                    </button>
                                </li>
                                <li>
                                    <button
                                        type="button"
                                        className={`btn-primary bg-transparent d-flex align-items-center text-primary gap-2 rounded-2 ${activeButton === 'btn-8' ? 'active' : ''}`}
                                        onClick={() => handleButtonClick('btn-8')}
                                    >
                                        Employment and Legal
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={24}
                                            height={24}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                        >
                                            <mask
                                                id="mask0_250_9727"
                                                style={{ maskType: "alpha" }}
                                                maskUnits="userSpaceOnUse"
                                                x={0}
                                                y={0}
                                                width={24}
                                                height={24}
                                            >
                                                <rect width={24} height={24} fill="#D9D9D9" />
                                            </mask>
                                            <g mask="url(#mask0_250_9727)">
                                                <path
                                                    d="M6.4 18L5 16.6L14.6 7H6V5H18V17H16V8.4L6.4 18Z"
                                                    fill="#002FA7"
                                                ></path>
                                            </g>
                                        </svg>
                                    </button>
                                </li>
                                <li>
                                    <button
                                        type="button"
                                        className={`btn-primary bg-transparent d-flex align-items-center text-primary gap-2 rounded-2 ${activeButton === 'btn-9' ? 'active' : ''}`}
                                        onClick={() => handleButtonClick('btn-9')}
                                    >
                                        Social Issues and Ethics
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={24}
                                            height={24}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                        >
                                            <mask
                                                id="mask0_250_9727"
                                                style={{ maskType: "alpha" }}
                                                maskUnits="userSpaceOnUse"
                                                x={0}
                                                y={0}
                                                width={24}
                                                height={24}
                                            >
                                                <rect width={24} height={24} fill="#D9D9D9" />
                                            </mask>
                                            <g mask="url(#mask0_250_9727)">
                                                <path
                                                    d="M6.4 18L5 16.6L14.6 7H6V5H18V17H16V8.4L6.4 18Z"
                                                    fill="#002FA7"
                                                ></path>
                                            </g>
                                        </svg>
                                    </button>
                                </li>
                                <li>
                                    <button
                                        type="button"
                                        className={`btn-primary bg-transparent d-flex align-items-center text-primary gap-2 rounded-2 ${activeButton === 'btn-10' ? 'active' : ''}`}
                                        onClick={() => handleButtonClick('btn-10')}
                                    >
                                        Marketing and Sales
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={24}
                                            height={24}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                        >
                                            <mask
                                                id="mask0_250_9727"
                                                style={{ maskType: "alpha" }}
                                                maskUnits="userSpaceOnUse"
                                                x={0}
                                                y={0}
                                                width={24}
                                                height={24}
                                            >
                                                <rect width={24} height={24} fill="#D9D9D9" />
                                            </mask>
                                            <g mask="url(#mask0_250_9727)">
                                                <path
                                                    d="M6.4 18L5 16.6L14.6 7H6V5H18V17H16V8.4L6.4 18Z"
                                                    fill="#002FA7"
                                                ></path>
                                            </g>
                                        </svg>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="accordion-images-main">
                        <div className="position-relative">
                            <div className="row">
                                <div className="col-12">
                                    <Accordion
                                        className="accordion position-relative accordionmain"
                                        id="accordionExample" defaultActiveKey="0"
                                    >
                                        <Accordion.Item className="mb-4 border-0 rounded-3 py-0" eventKey="0">
                                            <Accordion.Header className="text-primary bg-transparent rounded-0 fw-500 fs-6 px-2 border-0">Web Design Essentials</Accordion.Header>
                                            <Accordion.Body className="px-0 px-md-4 bg-body pt-5">
                                                <div className="row g-0">
                                                    <div className="col-12 mb-5">
                                                        <div className="card featured-card overflow-hidden border-0 position-relative accordion-items-shadow">
                                                            <div className="row g-0">
                                                                <div className="col-12 col-lg-6">
                                                                    <div className="card h-100 border-0 rounded-end-0">
                                                                        <img
                                                                            src="./featured-courses/featured01.jpg"
                                                                            className="rounded-start-2 accordion-image"
                                                                            alt=""
                                                                        />
                                                                        <span className="badge badge-warning bg-warning position-absolute top-0 start-0">
                                                                            Gold
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-lg-6">
                                                                    <div className="card h-100 border-0 rounded-end-2 rounded-start-0 bg-body">
                                                                        <div className="card-body p-3 p-md-4 p-lg-4 p-xl-5">
                                                                            <span className="badge badge-light rounded-2 fw-400 fs-6">
                                                                                Marketing and Sales
                                                                            </span>
                                                                            <h5>A Guide to Fraud Prevention</h5>
                                                                            <p className="d-flex align-items-center gap-2 fw-500 text-dark mt-3 mb-4">
                                                                                <svg
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    width={30}
                                                                                    height={30}
                                                                                    viewBox="0 0 30 30"
                                                                                    fill="none"
                                                                                >
                                                                                    <circle
                                                                                        cx={15}
                                                                                        cy={15}
                                                                                        r={15}
                                                                                        fill="#D9D9D9"
                                                                                    />
                                                                                </svg>
                                                                                Professor Sophia Lee
                                                                            </p>
                                                                            <div className="row justify-content-between align-items-center mt-4 mb-2">
                                                                                <p className="fw-400 FS-7 text-black mb-0 col-12 col-md-6 col-lg-7 mb-3 mb-md-0">
                                                                                    Beginner Boost
                                                                                </p>
                                                                                <div className="col-12 col-md-6 col-lg-5 d-flex justify-content-between align-items-center mb-3 mb-md-0">
                                                                                    <ul className="rectangle rectangle3 d-flex align-items-center gap-2 gap-md-3 flex-wrap mb-0 p-0">
                                                                                        <li className="active" />
                                                                                        <li />
                                                                                        <li />
                                                                                        <li />
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row justify-content-between align-items-center mb-3 mb-md-2">
                                                                                <p className="fw-400 FS-7 text-black col-12 col-md-6 col-7 col-lg-7 mb-3 mb-md-0">
                                                                                    Pro Membership
                                                                                </p>
                                                                                <div className="d-flex align-items-center gap-2 col-12 col-md-6 col-lg-5 col-5">
                                                                                    <img
                                                                                        src="./featured-courses/piggy-bank.jpg"
                                                                                        className="wh-25"
                                                                                        alt=""
                                                                                    />
                                                                                    <p className="mb-0 text-black fw-500 fs-7">
                                                                                        Premium Pass
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row justify-content-between align-items-center mb-2">
                                                                                <p className="fw-400 fs-7 text-black col-12 col-md-6 col-lg-7 mb-3 mb-md-0">
                                                                                    Duration
                                                                                </p>
                                                                                <div className="d-flex align-items-center gap-2 col-12 col-md-6 col-lg-5 col-5">
                                                                                    <img
                                                                                        src="./featured-courses/piggy-bank.jpg"
                                                                                        className="wh-25"
                                                                                        alt=""
                                                                                    />
                                                                                    <p className="mb-0 text-black fw-500 fs-7">
                                                                                        5 Hours
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                            <button
                                                                                type="button"
                                                                                className="btn-primary d-flex align-items-center gap-3 rounded-2 mt-4"
                                                                            >
                                                                                Enroll Today
                                                                                <svg
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    width={22}
                                                                                    height={14}
                                                                                    viewBox="0 0 22 14"
                                                                                    fill="none"
                                                                                >
                                                                                    <path
                                                                                        d="M1 6.99854H21L16 1"
                                                                                        stroke="white"
                                                                                        strokeWidth="1.5"
                                                                                        strokeLinecap="round"
                                                                                        strokeLinejoin="round"
                                                                                    />
                                                                                    <path
                                                                                        d="M21 6.99854L16 13"
                                                                                        stroke="white"
                                                                                        strokeWidth="1.5"
                                                                                        strokeLinecap="round"
                                                                                    />
                                                                                </svg>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item className="mb-4 border-0 rounded-3 py-0" eventKey="1">
                                            <Accordion.Header className="text-primary bg-transparent rounded-0 fw-500 fs-6 px-2 border-0">Web Design Essentials</Accordion.Header>
                                            <Accordion.Body className="px-0 px-md-4 bg-body pt-5">
                                                <div className="row g-0">
                                                    <div className="col-12 mb-5">
                                                        <div className="card featured-card overflow-hidden border-0 position-relative accordion-items-shadow">
                                                            <div className="row g-0">
                                                                <div className="col-12 col-lg-6">
                                                                    <div className="card h-100 border-0 rounded-end-0">
                                                                        <img
                                                                            src="./featured-courses/featured01.jpg"
                                                                            className="rounded-start-2 accordion-image"
                                                                            alt=""
                                                                        />
                                                                        <span className="badge badge-warning bg-warning position-absolute top-0 start-0">
                                                                            Gold
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-lg-6">
                                                                    <div className="card h-100 border-0 rounded-end-2 rounded-start-0 bg-body">
                                                                        <div className="card-body p-3 p-md-4 p-lg-4 p-xl-5">
                                                                            <span className="badge badge-light rounded-2 fw-400 fs-6">
                                                                                Marketing and Sales
                                                                            </span>
                                                                            <h5>A Guide to Fraud Prevention</h5>
                                                                            <p className="d-flex align-items-center gap-2 fw-500 text-dark mt-3 mb-4">
                                                                                <svg
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    width={30}
                                                                                    height={30}
                                                                                    viewBox="0 0 30 30"
                                                                                    fill="none"
                                                                                >
                                                                                    <circle
                                                                                        cx={15}
                                                                                        cy={15}
                                                                                        r={15}
                                                                                        fill="#D9D9D9"
                                                                                    />
                                                                                </svg>
                                                                                Professor Sophia Lee
                                                                            </p>
                                                                            <div className="row justify-content-between align-items-center mt-4 mb-2">
                                                                                <p className="fw-400 FS-7 text-black mb-0 col-12 col-md-6 col-lg-7 mb-3 mb-md-0">
                                                                                    Beginner Boost
                                                                                </p>
                                                                                <div className="col-12 col-md-6 col-lg-5 d-flex justify-content-between align-items-center mb-3 mb-md-0">
                                                                                    <ul className="rectangle rectangle3 d-flex align-items-center gap-2 gap-md-3 flex-wrap mb-0 p-0">
                                                                                        <li className="active" />
                                                                                        <li />
                                                                                        <li />
                                                                                        <li />
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row justify-content-between align-items-center mb-3 mb-md-2">
                                                                                <p className="fw-400 FS-7 text-black col-12 col-md-6 col-7 col-lg-7 mb-3 mb-md-0">
                                                                                    Pro Membership
                                                                                </p>
                                                                                <div className="d-flex align-items-center gap-2 col-12 col-md-6 col-lg-5 col-5">
                                                                                    <img
                                                                                        src="./featured-courses/piggy-bank.jpg"
                                                                                        className="wh-25"
                                                                                        alt=""
                                                                                    />
                                                                                    <p className="mb-0 text-black fw-500 fs-7">
                                                                                        Premium Pass
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row justify-content-between align-items-center mb-2">
                                                                                <p className="fw-400 fs-7 text-black col-12 col-md-6 col-lg-7 mb-3 mb-md-0">
                                                                                    Duration
                                                                                </p>
                                                                                <div className="d-flex align-items-center gap-2 col-12 col-md-6 col-lg-5 col-5">
                                                                                    <img
                                                                                        src="./featured-courses/piggy-bank.jpg"
                                                                                        className="wh-25"
                                                                                        alt=""
                                                                                    />
                                                                                    <p className="mb-0 text-black fw-500 fs-7">
                                                                                        5 Hours
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                            <button
                                                                                type="button"
                                                                                className="btn-primary d-flex align-items-center gap-3 rounded-2 mt-4"
                                                                            >
                                                                                Enroll Today
                                                                                <svg
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    width={22}
                                                                                    height={14}
                                                                                    viewBox="0 0 22 14"
                                                                                    fill="none"
                                                                                >
                                                                                    <path
                                                                                        d="M1 6.99854H21L16 1"
                                                                                        stroke="white"
                                                                                        strokeWidth="1.5"
                                                                                        strokeLinecap="round"
                                                                                        strokeLinejoin="round"
                                                                                    />
                                                                                    <path
                                                                                        d="M21 6.99854L16 13"
                                                                                        stroke="white"
                                                                                        strokeWidth="1.5"
                                                                                        strokeLinecap="round"
                                                                                    />
                                                                                </svg>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item className="mb-4 border-0 rounded-3 py-0" eventKey="2">
                                            <Accordion.Header className="text-primary bg-transparent rounded-0 fw-500 fs-6 px-2 border-0">Web Design Essentials</Accordion.Header>
                                            <Accordion.Body className="px-0 px-md-4 bg-body pt-5">
                                                <div className="row g-0">
                                                    <div className="col-12 mb-5">
                                                        <div className="card featured-card overflow-hidden border-0 position-relative accordion-items-shadow">
                                                            <div className="row g-0">
                                                                <div className="col-12 col-lg-6">
                                                                    <div className="card h-100 border-0 rounded-end-0">
                                                                        <img
                                                                            src="./featured-courses/featured01.jpg"
                                                                            className="rounded-start-2 accordion-image"
                                                                            alt=""
                                                                        />
                                                                        <span className="badge badge-warning bg-warning position-absolute top-0 start-0">
                                                                            Gold
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-lg-6">
                                                                    <div className="card h-100 border-0 rounded-end-2 rounded-start-0 bg-body">
                                                                        <div className="card-body p-3 p-md-4 p-lg-4 p-xl-5">
                                                                            <span className="badge badge-light rounded-2 fw-400 fs-6">
                                                                                Marketing and Sales
                                                                            </span>
                                                                            <h5>A Guide to Fraud Prevention</h5>
                                                                            <p className="d-flex align-items-center gap-2 fw-500 text-dark mt-3 mb-4">
                                                                                <svg
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    width={30}
                                                                                    height={30}
                                                                                    viewBox="0 0 30 30"
                                                                                    fill="none"
                                                                                >
                                                                                    <circle
                                                                                        cx={15}
                                                                                        cy={15}
                                                                                        r={15}
                                                                                        fill="#D9D9D9"
                                                                                    />
                                                                                </svg>
                                                                                Professor Sophia Lee
                                                                            </p>
                                                                            <div className="row justify-content-between align-items-center mt-4 mb-2">
                                                                                <p className="fw-400 FS-7 text-black mb-0 col-12 col-md-6 col-lg-7 mb-3 mb-md-0">
                                                                                    Beginner Boost
                                                                                </p>
                                                                                <div className="col-12 col-md-6 col-lg-5 d-flex justify-content-between align-items-center mb-3 mb-md-0">
                                                                                    <ul className="rectangle rectangle3 d-flex align-items-center gap-2 gap-md-3 flex-wrap mb-0 p-0">
                                                                                        <li className="active" />
                                                                                        <li />
                                                                                        <li />
                                                                                        <li />
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row justify-content-between align-items-center mb-3 mb-md-2">
                                                                                <p className="fw-400 FS-7 text-black col-12 col-md-6 col-7 col-lg-7 mb-3 mb-md-0">
                                                                                    Pro Membership
                                                                                </p>
                                                                                <div className="d-flex align-items-center gap-2 col-12 col-md-6 col-lg-5 col-5">
                                                                                    <img
                                                                                        src="./featured-courses/piggy-bank.jpg"
                                                                                        className="wh-25"
                                                                                        alt=""
                                                                                    />
                                                                                    <p className="mb-0 text-black fw-500 fs-7">
                                                                                        Premium Pass
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row justify-content-between align-items-center mb-2">
                                                                                <p className="fw-400 fs-7 text-black col-12 col-md-6 col-lg-7 mb-3 mb-md-0">
                                                                                    Duration
                                                                                </p>
                                                                                <div className="d-flex align-items-center gap-2 col-12 col-md-6 col-lg-5 col-5">
                                                                                    <img
                                                                                        src="./featured-courses/piggy-bank.jpg"
                                                                                        className="wh-25"
                                                                                        alt=""
                                                                                    />
                                                                                    <p className="mb-0 text-black fw-500 fs-7">
                                                                                        5 Hours
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                            <button
                                                                                type="button"
                                                                                className="btn-primary d-flex align-items-center gap-3 rounded-2 mt-4"
                                                                            >
                                                                                Enroll Today
                                                                                <svg
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    width={22}
                                                                                    height={14}
                                                                                    viewBox="0 0 22 14"
                                                                                    fill="none"
                                                                                >
                                                                                    <path
                                                                                        d="M1 6.99854H21L16 1"
                                                                                        stroke="white"
                                                                                        strokeWidth="1.5"
                                                                                        strokeLinecap="round"
                                                                                        strokeLinejoin="round"
                                                                                    />
                                                                                    <path
                                                                                        d="M21 6.99854L16 13"
                                                                                        stroke="white"
                                                                                        strokeWidth="1.5"
                                                                                        strokeLinecap="round"
                                                                                    />
                                                                                </svg>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item className="mb-4 border-0 rounded-3 py-0" eventKey="3">
                                            <Accordion.Header className="text-primary bg-transparent rounded-0 fw-500 fs-6 px-2 border-0">Web Design Essentials</Accordion.Header>
                                            <Accordion.Body className="px-0 px-md-4 bg-body pt-5">
                                                <div className="row g-0">
                                                    <div className="col-12 mb-5">
                                                        <div className="card featured-card overflow-hidden border-0 position-relative accordion-items-shadow">
                                                            <div className="row g-0">
                                                                <div className="col-12 col-lg-6">
                                                                    <div className="card h-100 border-0 rounded-end-0">
                                                                        <img
                                                                            src="./featured-courses/featured01.jpg"
                                                                            className="rounded-start-2 accordion-image"
                                                                            alt=""
                                                                        />
                                                                        <span className="badge badge-warning bg-warning position-absolute top-0 start-0">
                                                                            Gold
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-lg-6">
                                                                    <div className="card h-100 border-0 rounded-end-2 rounded-start-0 bg-body">
                                                                        <div className="card-body p-3 p-md-4 p-lg-4 p-xl-5">
                                                                            <span className="badge badge-light rounded-2 fw-400 fs-6">
                                                                                Marketing and Sales
                                                                            </span>
                                                                            <h5>A Guide to Fraud Prevention</h5>
                                                                            <p className="d-flex align-items-center gap-2 fw-500 text-dark mt-3 mb-4">
                                                                                <svg
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    width={30}
                                                                                    height={30}
                                                                                    viewBox="0 0 30 30"
                                                                                    fill="none"
                                                                                >
                                                                                    <circle
                                                                                        cx={15}
                                                                                        cy={15}
                                                                                        r={15}
                                                                                        fill="#D9D9D9"
                                                                                    />
                                                                                </svg>
                                                                                Professor Sophia Lee
                                                                            </p>
                                                                            <div className="row justify-content-between align-items-center mt-4 mb-2">
                                                                                <p className="fw-400 FS-7 text-black mb-0 col-12 col-md-6 col-lg-7 mb-3 mb-md-0">
                                                                                    Beginner Boost
                                                                                </p>
                                                                                <div className="col-12 col-md-6 col-lg-5 d-flex justify-content-between align-items-center mb-3 mb-md-0">
                                                                                    <ul className="rectangle rectangle3 d-flex align-items-center gap-2 gap-md-3 flex-wrap mb-0 p-0">
                                                                                        <li className="active" />
                                                                                        <li />
                                                                                        <li />
                                                                                        <li />
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row justify-content-between align-items-center mb-3 mb-md-2">
                                                                                <p className="fw-400 FS-7 text-black col-12 col-md-6 col-7 col-lg-7 mb-3 mb-md-0">
                                                                                    Pro Membership
                                                                                </p>
                                                                                <div className="d-flex align-items-center gap-2 col-12 col-md-6 col-lg-5 col-5">
                                                                                    <img
                                                                                        src="./featured-courses/piggy-bank.jpg"
                                                                                        className="wh-25"
                                                                                        alt=""
                                                                                    />
                                                                                    <p className="mb-0 text-black fw-500 fs-7">
                                                                                        Premium Pass
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row justify-content-between align-items-center mb-2">
                                                                                <p className="fw-400 fs-7 text-black col-12 col-md-6 col-lg-7 mb-3 mb-md-0">
                                                                                    Duration
                                                                                </p>
                                                                                <div className="d-flex align-items-center gap-2 col-12 col-md-6 col-lg-5 col-5">
                                                                                    <img
                                                                                        src="./featured-courses/piggy-bank.jpg"
                                                                                        className="wh-25"
                                                                                        alt=""
                                                                                    />
                                                                                    <p className="mb-0 text-black fw-500 fs-7">
                                                                                        5 Hours
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                            <button
                                                                                type="button"
                                                                                className="btn-primary d-flex align-items-center gap-3 rounded-2 mt-4"
                                                                            >
                                                                                Enroll Today
                                                                                <svg
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    width={22}
                                                                                    height={14}
                                                                                    viewBox="0 0 22 14"
                                                                                    fill="none"
                                                                                >
                                                                                    <path
                                                                                        d="M1 6.99854H21L16 1"
                                                                                        stroke="white"
                                                                                        strokeWidth="1.5"
                                                                                        strokeLinecap="round"
                                                                                        strokeLinejoin="round"
                                                                                    />
                                                                                    <path
                                                                                        d="M21 6.99854L16 13"
                                                                                        stroke="white"
                                                                                        strokeWidth="1.5"
                                                                                        strokeLinecap="round"
                                                                                    />
                                                                                </svg>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default CourseLevel