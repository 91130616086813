import React from 'react'
import Header from '../layout/Header'
import CommunityHomeBanner from '../components/CommunityHomeBanner'
import CommunitySidbar from '../components/CommunitySidbar'
import Footer from '../layout/Footer'
import CommunitySidbarModal from '../components/CommunitySidbarModal'
import { Link } from 'react-router-dom'

const CommunityHelpCenterTopic = () => {
    return (
        <>
            <Header />
            <CommunityHomeBanner />
            <section>
                <div className="featured-courses-main">
                    <div className="container">
                        <div className="row featured-courses-row">
                            <div className="col-md-4 col-lg-3 col-xl-2 d-none d-md-block">
                                <CommunitySidbar />
                            </div>
                            <div className="col-md-8 col-lg-9 col-xl-10">
                                <div className="row justify-content-center mb-4">
                                    <div className="col-12 col-lg-11">
                                        <div className="section-title col-md-12 col-lg-12 m-auto">
                                            <div className="breadcrumb-main mt-4">
                                                <nav aria-label="breadcrumb">
                                                    <ol className="breadcrumb align-items-center mb-3">
                                                        <li className="breadcrumb-item">
                                                            <Link to="" className="fw-400 fs-7">
                                                                Community
                                                            </Link>
                                                        </li>
                                                        <li className="breadcrumb-item">
                                                            <Link to="/community-help" className="fw-400 fs-7">
                                                                Help Center
                                                            </Link>
                                                        </li>
                                                        <li
                                                            className="breadcrumb-item fw-400 fs-7 active"
                                                            aria-current="page"
                                                        >
                                                            Help Center Topics
                                                        </li>
                                                    </ol>
                                                </nav>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 d-flex justify-content-md-end justify-content-between align-items-center mb-4 mb-md-0">
                                        <CommunitySidbarModal />
                                    </div>
                                </div>
                                <div className="section-title col-md-12 col-lg-11 m-auto">
                                    <div className="d-flex align-items-center gap-3 help-card mb-4">
                                        <img
                                            src="./community/help-center-1.png"
                                            className=""
                                            alt=""
                                        />
                                        <h2 className="text-start text-black my-0">
                                            Account Registration and Login
                                        </h2>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-md-12 col-lg-11">
                                        <p className="fs-6 fw-400 text-muted">
                                            This topic covers everything you need to know about creating an account,
                                            logging in, and managing your account settings on Unicorso. Whether
                                            you're a new user looking to get started or an existing user with
                                            account-related questions, this section has you covered.
                                        </p>
                                        <ul className="help-center-topic-list-1 w-100 bg-transparent ps-3">
                                            <li className="topic-list pb-0">
                                                Creating an Account
                                                <ul className="help-center-topic-option gray-dots w-100 bg-transparent ps-4 mt-3">
                                                    <li className="text-muted">
                                                            How to register for a new account:
                                                    </li>
                                                    <p className="mb-0 text-muted">
                                                            To create a new account, simply provide your
                                                            basic information, such as name, email, and password. For further assistance, email
                                                            support@unicorso.com.
                                                    </p>
                                                    <li className="text-muted">
                                                            Required information during registration
                                                    </li>
                                                    <p className="mb-0 text-muted"> You will need to provide essential details
                                                        like your name, email address, and a password to successfully register. If you need
                                                        help, contact support@unicorso.com.
                                                    </p>
                                                    <li className="text-muted">
                                                        Account verification process
                                                    </li>
                                                    <p className="mb-0 text-muted">
                                                        Verify your account through the email confirmation
                                                        process to ensure your account is secure and ready to use. Any questions? Reach
                                                        out to support@unicorso.com.
                                                    </p>
                                                </ul>
                                            </li>
                                            <li className="topic-list pb-0">
                                                Logging In
                                                <ul className="help-center-topic-option gray-dots w-100 bg-transparent ps-4 mt-3">
                                                    <li className="text-muted">How to log in to your account</li>
                                                    <p className='mb-0 text-muted'>
                                                        Use your registered email and password to log in to
                                                        your account. If you encounter issues, email support@unicorso.com.
                                                    </p>
                                                    <li className="text-muted">Troubleshooting login issues</li>
                                                    <p className='mb-0 text-muted'>
                                                        Solve common login problems, such as incorrect
                                                        passwords or browser issues. If you need further assistance, email
                                                        support@unicorso.com.
                                                    </p>
                                                    <li className="text-muted">Password reset and recovery</li>
                                                    <p className='mb-0 text-muted'>
                                                        Reset your password if forgotten, or recover your
                                                        account if there are security concerns. For more help, contact
                                                        support@unicorso.com.
                                                    </p>
                                                </ul>
                                            </li>
                                            <li className="topic-list pb-0">
                                                Account Settings
                                                <ul className="help-center-topic-option gray-dots w-100 bg-transparent ps-4 mt-3">
                                                    <li className="text-muted">Updating personal information</li>
                                                    <p className='mb-0 text-muted'>
                                                        Update your profile details, including name, contact
                                                        information, and preferences. For assistance, email support@unicorso.com.
                                                    </p>
                                                    <li className="text-muted">Changing your password</li>
                                                    <p className='mb-0 text-muted'>
                                                        Change your password regularly to maintain account
                                                        security. For further help, contact support@unicorso.com.
                                                    </p>
                                                    <li className="text-muted">Managing email preferences</li>
                                                    <p className='mb-0 text-muted'>
                                                        Adjust your email notification settings to receive course
                                                        updates, promotions, and important announcements. Need help? Email
                                                        support@unicorso.com.
                                                    </p>
                                                </ul>
                                            </li>
                                            <li className="topic-list pb-0">
                                                Security and Privacy
                                                <ul className="help-center-topic-option gray-dots w-100 bg-transparent ps-4 mt-3">
                                                    <li className="text-muted">Tips for keeping your account secure</li>
                                                    <p className='mb-0 text-muted'>
                                                        Maintain your account's security by creating
                                                        strong passwords and recognizing phishing attempts. For additional tips, email
                                                        support@unicorso.com.
                                                    </p>
                                                    <li className="text-muted">Privacy settings and options</li>
                                                    <p className='mb-0 text-muted'>
                                                        Control your privacy settings to protect your personal
                                                        information and manage visibility on the platform. For more details, contact
                                                        support@unicorso.com.
                                                    </p>
                                                    {/* <li className="text-muted">Two-factor authentication (2FA)</li> */}
                                                </ul>
                                            </li>
                                            <li className="topic-list pb-0">
                                                Account Deactivation or Closure
                                                <ul className="help-center-topic-option gray-dots w-100 bg-transparent ps-4 mt-3">
                                                    <li className="text-muted">
                                                        How to deactivate or delete your account
                                                    </li>
                                                    <p className='mb-0 text-muted'>
                                                        Temporarily deactivate or permanently
                                                        delete your account if you no longer wish to use our services. For assistance, email
                                                        support@unicorso.com.
                                                    </p>
                                                    <li className="text-muted">Implications of closing your account</li>
                                                    <p className='mb-0 text-muted'>
                                                        Understand the consequences of account
                                                        closure, such as losing access to courses and data, and any potential recovery
                                                        options. Questions? Contact support@unicorso.com.
                                                    </p>
                                                    <li className="text-muted">Data retention policies</li>
                                                    <p className='mb-0 text-muted'>
                                                        Understand the consequences of account
                                                        closure, such as losing access to courses and data, and any potential recovery
                                                        options. Questions? Contact support@unicorso.com.
                                                    </p>
                                                </ul>
                                            </li>
                                            <li className="topic-list pb-0">
                                                Common Account Issues
                                                <ul className="help-center-topic-option gray-dots w-100 bg-transparent ps-4 mt-3">
                                                    <li className="text-muted">Account lockouts</li>
                                                    <p className='mb-0 text-muted'>
                                                        Resolve issues if you are locked out of your account due to
                                                        multiple failed login attempts. For help, contact support@unicorso.com.
                                                    </p>
                                                    <li className="text-muted">Suspicious activity alerts</li>
                                                    <p className='mb-0 text-muted'>
                                                        Respond to alerts about unusual activity on your account
                                                        and secure it if you suspect
                                                    </p>
                                                    <li className="text-muted">Unauthorized access reports</li>
                                                    <p className='mb-0 text-muted'>
                                                        Report unauthorized access and understand the
                                                        actions we take to protect your account and resolve the issue. For further assistance,
                                                        contact support@unicorso.com.
                                                    </p>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default CommunityHelpCenterTopic
