
import React, { useEffect, useState } from 'react';

// export const Is_Login = () => {
//   const [isLoggedIn, setIsLoggedIn] = useState(false);

//   useEffect(() => {
//     // Check if the user is logged in by reading the local storage
//     const loggedInUser = localStorage.getItem('token');
//     setIsLoggedIn(!!loggedInUser);

//     // Set up an event listener to check for changes in local storage
//     const handleStorageChange = (e) => {
//       if (e.key === 'token') {
//         setIsLoggedIn(!!localStorage.getItem('token'));
//       }
//     };

//     window.addEventListener('storage', handleStorageChange);

//     // Cleanup event listener on unmount
//     return () => {
//       window.removeEventListener('storage', handleStorageChange);
//     };
//   }, []);

//   return isLoggedIn;
// };

export const Is_Login = () => {
  // Set the initial state directly based on the token's presence in local storage
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token'));

  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === 'token') {
        // Update the state based on the current value in local storage
        setIsLoggedIn(!!localStorage.getItem('token'));
      }
    };

    // Listen for storage changes to update the login status accordingly
    window.addEventListener('storage', handleStorageChange);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return isLoggedIn;
};
