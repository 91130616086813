import React from 'react'

const CourseNotFound = () => {
  return (
    <div>
    <img
        src="../svg/no-corce-found.svg"
        className="no-corce-found"
        alt=""
    />
</div>
  )
}

export default CourseNotFound