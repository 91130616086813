// SkeletonLayouts.js
import { SkeletonBox, SkeletonLine } from './SkeletonElements';

export const CardLayout = () => (
  <div className='loading' style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
    <SkeletonBox width="100%" height="200px" />
    <SkeletonLine width="60%" />
    <SkeletonLine width="80%" />
    {/* Add more elements as needed */}
  </div>
);

export const ListLayout = () => (
  <div className='loading' style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
    <SkeletonLine width="100%" />
    <SkeletonLine width="90%" />
    <SkeletonLine width="100%" />
    {/* Add more elements as needed */}
  </div>
);

export const BreadcrumbLayout = () => (
  <div className='loading'>
    <SkeletonLine width="100%" />
    <SkeletonLine width="80%" />
    <SkeletonLine width="60%" />
  </div>
);

export const TitleBadgeLayout = () => (
  <div className='loading'>
    <SkeletonBox width="70%" height="30px" />
    <SkeletonBox width="50%" height="20px" style={{ marginTop: '10px' }} />
  </div>
);

export const ContentLayout = () => (
  <div className='loading'>
    <SkeletonLine width="100%" />
    <SkeletonLine width="90%" />
    <SkeletonLine width="85%" />
    {/* Add more lines as needed */}
  </div>
);

export const BreadcrumbSkeleton = () => (
  <div className='loading' style={{ padding: '10px 0', display: 'flex', alignItems: 'center', gap: '10px' }}>
    <div style={{ width: '5%', height: '10px', backgroundColor: '#ccc' }}></div>
    <div style={{ width: '10%', height: '10px', backgroundColor: '#ccc' }}></div>
    <div style={{ width: '15%', height: '10px', backgroundColor: '#ccc' }}></div>
  </div>
);


export const TitleBadgeSkeleton = () => (
  <div className='loading' style={{ padding: '10px 0' }}>
    <div style={{ width: '60%', height: '30px', backgroundColor: '#ccc', marginBottom: '10px' }}></div>
    <div style={{ width: '30%', height: '20px', backgroundColor: '#ccc', marginBottom: '10px', borderRadius: '5px' }}></div>
    <div style={{ width: '20%', height: '20px', backgroundColor: '#ccc', borderRadius: '5px' }}></div>
  </div>
);

export const ContentSkeleton = () => (
  <div className='loading' style={{ padding: '20px 0' }}>
    <div style={{ width: '100%', height: '20px', backgroundColor: '#ccc', marginBottom: '10px' }}></div>
    <div style={{ width: '90%', height: '20px', backgroundColor: '#ccc', marginBottom: '10px' }}></div>
    <div style={{ width: '80%', height: '20px', backgroundColor: '#ccc' }}></div>
    {/* Repeat or adjust for content length */}
  </div>
);

export const SidebarSkeleton = () => (
  <div className='loading' style={{ padding: '20px 0' }}>
    <div style={{ width: '100%', height: '150px', backgroundColor: '#ccc', marginBottom: '20px', borderRadius: '5px' }}></div>
    <div style={{ width: '100%', height: '40px', backgroundColor: '#ccc', marginBottom: '10px', borderRadius: '5px' }}></div>
    <div style={{ width: '100%', height: '40px', backgroundColor: '#ccc', marginBottom: '10px', borderRadius: '5px' }}></div>
    <div style={{ width: '100%', height: '40px', backgroundColor: '#ccc', borderRadius: '5px' }}></div>
  </div>
);


export const ButtonSkeleton = () => (
  <div className='loading' style={{ padding: '10px 0' }}>
    <div style={{ width: '30%', height: '40px', backgroundColor: '#ccc', borderRadius: '5px' }}></div>
  </div>
);

export const CarouselItemSkeleton = () => (
  <div className="item loading" style={{ position: 'relative' }}> {/* Ensure the parent div is positioned relatively. */}
    <div className="card rounded-1 border-0 overflow-hidden shadow-sm">
      <div style={{ width: '100%', height: '250px', backgroundColor: '#ccc' }}></div>
      <div className="card-body p-4">
        <div style={{ width: '50%', height: '20px', backgroundColor: '#ccc', marginBottom: '10px' }}></div>
        <div style={{ width: '80%', height: '20px', backgroundColor: '#ccc', marginBottom: '10px' }}></div>
        <div style={{ width: '100%', height: '20px', backgroundColor: '#ccc', marginBottom: '20px' }}></div>
      </div>
      {/* Button Placeholder at the bottom */}
      <div style={{
        width: '40px',
        height: '40px',
        backgroundColor: '#ccc',
        borderRadius: '50%',
        position: 'absolute', // Position the button absolutely.
        bottom: '80px', // Position it 20px from the bottom of the item.
        right: '20px', // Position it 20px from the right of the item.
      }}></div>
    </div>
  </div>
);



export const CourseCardSkeleton = () => (
  <div className="card rounded-1 border-0 overflow-hidden shadow-sm loading">
    <div style={{ width: '100%', height: '180px', backgroundColor: '#E0E0E0' }}></div>
    <div className="card-body p-4">
      <div style={{ width: '50%', height: '20px', backgroundColor: '#E0E0E0', marginBottom: '10px', borderRadius: '4px' }}></div>
      <div style={{ width: '90%', height: '30px', backgroundColor: '#E0E0E0', marginBottom: '10px', borderRadius: '4px' }}></div>
      <div style={{ width: '100%', height: '20px', backgroundColor: '#E0E0E0', marginBottom: '5px', borderRadius: '4px' }}></div>
      <div className='bg-transparent' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ width: '30%', height: '20px', backgroundColor: '#E0E0E0', marginBottom: '10px', borderRadius: '4px' }}></div>
        <div style={{ width: '30%', height: '20px', backgroundColor: '#E0E0E0', marginBottom: '10px', borderRadius: '4px' }}></div>
      </div>
      <div style={{ width: '60%', height: '40px', backgroundColor: '#E0E0E0', borderRadius: '20px' }}></div>
    </div>
  </div>
);


export const QuizCardSkeleton = () => (
  <div className="col-md-12 col-lg-8">
    <div className='loading card' style={{ backgroundColor: 'white', borderRadius: '4px', overflow: 'hidden' }}>
      <div className="card-body px-4 pt6" style={{ position: 'relative', }}>
        {/* Placeholder for the question number at the top left */}
        <SkeletonBox width="100px" height="20px" style={{ position: 'absolute', top: '15px', left: '15px', borderRadius: '4px', backgroundColor: '#e0e0e0' }} />
        {/* Placeholder for the timer at the top right */}
        <SkeletonBox width="100px" height="20px" style={{ position: 'absolute', top: '15px', right: '15px', borderRadius: '4px', backgroundColor: '#e0e0e0' }} />
        {/* Placeholder for the question text */}
        <SkeletonLine width="100%" height="20px" style={{ marginBottom: '24px', borderRadius: '4px', backgroundColor: '#e0e0e0' }} />
        {/* Placeholders for the answer options */}
        <div className='bg-transparent' style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          {[...Array(4)].map((_, index) => (
            <div className='bg-transparent mb-4' key={index} style={{ display: 'flex', alignItems: 'center' }}>
              <SkeletonBox width="24px" height="24px" style={{ borderRadius: '50%', backgroundColor: '#e0e0e0' }} />
              <SkeletonLine width="calc(100% - 40px)" height="20px" style={{ marginLeft: '16px', borderRadius: '4px', backgroundColor: '#e0e0e0' }} />
            </div>
          ))}
        </div>

        {/* Placeholder for the Next button */}
        <div className='d-flex justify-content-between bg-transparent mt-3'>
          <SkeletonBox width="120px" height="40px" style={{ borderRadius: '4px', backgroundColor: '#e0e0e0' }} />
          <SkeletonBox width="120px" height="40px" style={{ borderRadius: '4px', backgroundColor: '#e0e0e0' }} />
        </div>
      </div>
    </div>
  </div>
);

// Inside SkeletonLayouts.js

export const DropdownSkeleton = () => (
  <div className='loading' style={{ padding: '10px', display: 'flex', flexDirection: 'column', gap: '5px' }}>
    {/* Simulate several dropdown items */}
    {[...Array(5)].map((_, index) => (
      <div key={index} style={{ width: '100%', height: '20px', backgroundColor: '#ccc', borderRadius: '4px' }}></div>
    ))}
  </div>
);


export const MultiStepFormSkeleton = () => (
  <div className="card border-0 mb-5 loading">
    <div className="card-body">

      <div className="d-flex justify-content-between" style={{ marginBottom: '10px' }}>
        <SkeletonBox width="50px" height="50px" style={{ borderRadius: '50%' }} />
        <SkeletonBox width="50px" height="50px" style={{ borderRadius: '50%' }} />
        <SkeletonBox width="50px" height="50px" style={{ borderRadius: '50%' }} />
      </div>

      {/* Header for "Upload Employee Information" */}
      <SkeletonBox width="80%" height="30px" style={{ marginBottom: '12px', borderRadius: '4px' }} />
      {/* Download link skeleton */}
      <SkeletonLine width="20%" height="20px" style={{ marginBottom: '24px' }} />
      {/* Progress bar skeleton */}
      <SkeletonBox width="100%" height="20px" style={{ marginBottom: '20px', borderRadius: '4px' }} />
      {/* Step indicators skeleton */}

      {/* Text labels for steps */}
      <div className="d-flex justify-content-between text-center">
        <SkeletonLine width="20%" height="20px" />
        <SkeletonLine width="20%" height="20px" />
        <SkeletonLine width="20%" height="20px" />
      </div>
      
      {/* File upload action skeleton */}
      <SkeletonBox width="100%" height="100px" style={{ marginTop: '40px', borderRadius: '4px' }} />
      
      {/* Footer text skeleton */}
      <SkeletonLine width="50%" height="20px" style={{ marginTop: '40px' }} />
      <SkeletonLine width="50%" height="20px" style={{ marginTop: '40px' }} />

    </div>
    <div className="card-footer button-footer pt-3 mt-5">
      <div className='d-flex justify-content-end gap-3'>
        {/* Buttons skeleton */}
        <SkeletonBox width="90px" height="40px" style={{ borderRadius: '4px' }} />
        <SkeletonBox width="90px" height="40px" style={{ borderRadius: '4px' }} />
      </div>
    </div>
  </div>
);
