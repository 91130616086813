import React, { useEffect, useState } from "react";

import { Link, useLocation, useNavigate } from "react-router-dom";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import { Card } from "react-bootstrap";
import api from "../../helper/api";
import { getServerURL } from "../../helper/envConfig";
import {
    decryptFromStoreLoc,
    encryptAndStoreLoc,
} from "../../helper/localStore";

const PaymentDetails = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const serverURL = getServerURL();
    const [state, setState] = useState(location.state || {});
    const [selectedMethod, setSelectedMethod] = useState("paypal"); // Default selected method

    const handleChange = (method) => {
        setSelectedMethod(method);
    };

    const createPaymentObj = async () => {
        try {
            let obj = {
                plan_type: "two-two",
                price: state?.userPlan?.subscriptionAmount,
                duration: 1,
                trail_ids: [],
                duration_type: "year",
                total_amount: 0,
                description: "Tier Two - Two Trails",
            };
            obj.plan_type =
                state?.name === "one"
                    ? "one-one"
                    : state?.name === "two"
                    ? "two-two"
                    : "three-three-gold";
            obj.trail_ids = state.courseTrails;
            obj.total_amount = state?.userPlan?.subscriptionAmount;
            obj.description = state?.plan;

            var response = await api.postWithToken(
                `${serverURL}payments/payment/`,
                obj
            );
            if (response.data.success) {
                window.location.href = response.data.payment_url;
            } else {
                console.error("Error:", response.data.message);
            }
        } catch (error) {
            console.error("Error creating payment object", error);
        }
    };

    useEffect(() => {
        if (state.name) {
            encryptAndStoreLoc("state", state);
        } else {
            const data = decryptFromStoreLoc("state");
            if (data) {
                setState(data);
            } else {
                navigate("/subscription-plans");
            }
        }
    }, []);

    return (
        <>
            <Header />
            <section>
                <div className="container-fluid">
                    <div className="sign-up-main individual-main">
                        <div className="row justify-content-between align-items-start h-100">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-6 position-relative bg-primary d-flex justify-content-center align-items-center sign-up-col sticky-top top-0">
                                <img
                                    src="./login/sing-vector-1.png"
                                    className="sing-vector-1"
                                    alt=""
                                />
                                <img
                                    src="./login/money-online.png"
                                    className="man-develops"
                                    alt=""
                                />
                                <img
                                    src="./login/sing-vector-2.png"
                                    className="sing-vector-2"
                                    alt=""
                                />
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 col-xl-6 position-relative d-flex justify-content-center align-items-start align-items-md-center">
                                <div className="row g-4 ps-xl-5 selectcard pt-4 pt-md-5 py-lg-0 payment-information-card w-100">
                                    <div className="col-12 pt-lg-5">
                                        <h3 className="welcome-title">
                                            Payment Details
                                        </h3>
                                        <p className="welcome-description max-width-100">
                                            Review your plan and enter payment
                                            information. You can change your
                                            plan or payment method at any time.
                                        </p>
                                        <Card>
                                            <Card.Body className="summary-details-list py-5 px-4">
                                                {state.name ===
                                                    "two" && (
                                                    <div className="d-flex justify-content-between align-items-start gap-3">
                                                        <div>
                                                            <p className="fs-4 fw-600 mb-0">
                                                                Tier Two - Two
                                                                Trails
                                                            </p>
                                                            <p className="mb-0">
                                                                Several trails
                                                                with basic
                                                                courses on
                                                                Compliance, Data
                                                                Privacy and
                                                                Information
                                                                Security
                                                            </p>
                                                        </div>
                                                        <div className="text-end">
                                                            <h4>
                                                                <span>€12</span>
                                                                /year
                                                            </h4>
                                                        </div>
                                                    </div>
                                                )}
                                                {state.name ===
                                                    "three" && (
                                                    <div className="d-flex justify-content-between align-items-start gap-3">
                                                        <div>
                                                            <p className="fs-4 fw-600 mb-0">
                                                                Tier Three-Gold
                                                                - All Trails
                                                            </p>
                                                            <p className="mb-0">
                                                                All the courses
                                                                with a lot more
                                                                courses on
                                                                Compliance, Data
                                                                Privacy,
                                                                Information
                                                                Security and
                                                                also on skills
                                                                and fun topics
                                                            </p>
                                                        </div>
                                                        <div className="text-end">
                                                            <h4>
                                                                <span>
                                                                    €120
                                                                </span>
                                                                /year
                                                            </h4>
                                                        </div>
                                                    </div>
                                                )}
                                            </Card.Body>
                                        </Card>
                                        <Card>
                                            <Card.Body className="summary-details-list py-5 px-4">
                                                <h5 className="fs-4 pb-4">
                                                    Summary
                                                </h5>
                                                {state.name ===
                                                    "two" && (
                                                    <>
                                                        <div className="d-flex justify-content-between align-items-center gap-3 border-bottom gap-3 pb-3 mb-3">
                                                            <div>
                                                                <p className="fs-20 mb-0">
                                                                    Tier Two -
                                                                    Two Trails
                                                                </p>
                                                            </div>
                                                            <div className="text-end">
                                                                <h4>
                                                                    <span>
                                                                        €12
                                                                    </span>
                                                                    /year
                                                                </h4>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex justify-content-between align-items-center gap-3">
                                                            <div>
                                                                <h5 className="fs-2">
                                                                    Total
                                                                </h5>
                                                            </div>
                                                            <div className="total">
                                                                <h4>
                                                                    <span>
                                                                        €12
                                                                    </span>
                                                                </h4>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                                {state.name ===
                                                    "three" && (
                                                    <>
                                                        <div className="d-flex justify-content-between align-items-center gap-3 border-bottom gap-3 pb-3 mb-3">
                                                            <div>
                                                                <p className="fs-20 mb-0">
                                                                    Tier
                                                                    Three-Gold -
                                                                    All Trails
                                                                </p>
                                                            </div>
                                                            <div className="text-end">
                                                                <h4>
                                                                    <span>
                                                                        €120
                                                                    </span>
                                                                    /year
                                                                </h4>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex justify-content-between align-items-center gap-3">
                                                            <div>
                                                                <h5 className="fs-2">
                                                                    Total
                                                                </h5>
                                                            </div>
                                                            <div className="total">
                                                                <h4>
                                                                    <span>120
                                                                        €
                                                                    </span>
                                                                </h4>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </Card.Body>
                                        </Card>
                                        {/* <Card>
                                            <Card.Body>
                                                <div className='d-flex align-items-start gap-3'>
                                                    <label className='d-flex align-items-center mb-3 input-radio-box-title mt-3'>
                                                        <input
                                                            type="radio"
                                                            className="input-radio-box on"
                                                            name="pilih"
                                                            checked={selectedMethod === 'card'}
                                                            onChange={() => handleChange('card')}
                                                        />
                                                    </label>
                                                    <div>
                                                        <h5>Credit or Debit Card</h5>
                                                        <div className='d-flex align-items-center gap-2'>
                                                            <img src="/png/american.png" width={20} height={20} alt="" />
                                                            <img src="/png/mastercard.png" width={20} height={20} alt="" />
                                                            <img src="/png/visa.png" width={20} height={20} alt="" />
                                                        </div>
                                                        {selectedMethod === 'card' && (
                                                            <form className="manage-family debit-card-form row g-3 mt-4 mb-5">
                                                                <div className="col-12">
                                                                    <input
                                                                        className="form-control"
                                                                        placeholder="Card Number"
                                                                        type="text"
                                                                        name="iban"
                                                                        defaultValue=""
                                                                    />
                                                                </div>
                                                                <div className="col-12">
                                                                    <input
                                                                        className="form-control"
                                                                        placeholder="Card Holder’s Name"
                                                                        type="text"
                                                                        name="bankName"
                                                                        defaultValue=""
                                                                    />
                                                                </div>
                                                                <div className="col-6">
                                                                    <input
                                                                        className="form-control"
                                                                        placeholder="CNN"
                                                                        type="text"
                                                                        name="accountHolder"
                                                                        defaultValue=""
                                                                    />
                                                                </div>
                                                                <div className="col-6">
                                                                    <input
                                                                        className="form-control"
                                                                        placeholder="Expiration Date (MM/YY)"
                                                                        type="date"
                                                                        name="accountHolder"
                                                                        defaultValue=""
                                                                    />
                                                                </div>
                                                                <div className="form-check d-flex align-items-center gap-3 ms-2 pt-4 manage-family-checkbox">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        id="flexCheckChecked1"
                                                                        defaultChecked=""
                                                                    />


                                                                    <label className="form-check-label" htmlFor="flexCheckChecked1">
                                                                        Save Card Details
                                                                    </label>
                                                                </div>
                                                            </form>
                                                        )}
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card> */}
                                        {/* <Card>
                                            <Card.Body>
                                                <div className='d-flex align-items-start gap-3'>
                                                    <label className='d-flex align-items-center mb-3 input-radio-box-title mt-3'>
                                                        <input
                                                            type="radio"
                                                            className="input-radio-box on"
                                                            name="pilih"
                                                            checked={selectedMethod === 'paypal'}
                                                            onChange={() => handleChange('paypal')}
                                                        />
                                                    </label>
                                                    <div>
                                                        <h5>Paypal</h5>
                                                        <img src="/png/paypal.png" width={72} height={20} alt="" />
                                                        {selectedMethod === 'paypal' && (
                                                            <div className="mt-3">
                                                                <p className='fw-400 fs-7 text-black'>We’ll redirect you to PayPal to complete your purchese.</p>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card> */}
                                        {/* <Card>
                                            <Card.Body>
                                                <div className="form-check d-flex align-items-center gap-3 ms-2 manage-family-checkbox available-trails-cards ">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="flexCheckChecked1"
                                                        defaultChecked=""
                                                    />
                                                    <label className="form-check-label fw-400 fs-7 text-bl" htmlFor="flexCheckChecked1">
                                                        Save Card Details
                                                    </label>
                                                </div>
                                            </Card.Body>
                                        </Card> */}
                                        <div className="d-flex justify-content-end align-items-center mt-4 mb-4 mb-md-5">
                                            <button
                                                to="/processed"
                                                className="btn-explore d-flex align-items-center gap-3 rounded-2 fw-400"
                                                 onClick={createPaymentObj}
                                            >
                                                Proceed
                                                <div className="ps-0 ps-md-5">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={22}
                                                        height={14}
                                                        viewBox="0 0 22 14"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M1 6.99854H21L16 1"
                                                            stroke="white"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path
                                                            d="M21 6.99854L16 13"
                                                            stroke="white"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                        />
                                                    </svg>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default PaymentDetails;
