import React, { useContext, useState } from "react";
import TopMenuList from "./TopMenuList";
import AdminSidebar from "./AdminSidebar";
import "../../styles/admin.css";
import Dropdown from "react-bootstrap/Dropdown";
import { useNavigate } from "react-router-dom";
import { CourseContext } from "../../context/CourseContext";

function AdminLayout({ children }) {
    const { user } = useContext(CourseContext);
    const navigate = useNavigate()
    const [isSearchBoxVisible, setSearchBoxVisible] = useState(false);

    const toggleSearchBox = () => {
        setSearchBoxVisible(!isSearchBoxVisible);
    };

    const handleLogout = () => {
        localStorage.clear()
        navigate('/admin-login')
        window.location.reload()
    }

    return (
        <>
            <div className="d-flex">
                <AdminSidebar />
                <aside className="w-100">
                    <header id="header">
                        <div className="top-menu-list-box">
                            <TopMenuList />
                        </div>
                        <div className="d-flex align-items-center gap-3">
                            <div className="search-container position-relative">
                                <div
                                    className="round-btn"
                                    id="show-search-box"
                                    onClick={toggleSearchBox}
                                >
                                    <img
                                        src="./admin/search.png"
                                        className="cursor-pointer"
                                        width={20}
                                        height={20}
                                        alt=""
                                    />
                                </div>
                                {isSearchBoxVisible && (
                                    <div className="search-bar-inout-box">
                                        <form action="" className="w-100">
                                            <input
                                                type="search"
                                                className="form-control"
                                            />
                                        </form>
                                    </div>
                                )}
                            </div>

                            <Dropdown className="logout-dropdown" align={{ lg: 'end' }}>
                                <div className="d-flex align-items-center gap-2">
                                    <Dropdown.Toggle
                                        align={{ lg: 'end' }}
                                        className="p-0 bg-transparent d-flex align-items-center gap-1 gap-md-2 text-start"
                                        id="dropdown-basic"
                                    >
                                        {/* <img
                                            src="./admin/avatar.png"
                                            width={40}
                                            height={40}
                                            alt=""
                                        /> */}
                                        <div className="profile-initials">
                                            {user?.username?.charAt(0).toUpperCase()}
                                        </div>
                                        <div className="d-flex align-items-center gap-3">
                                            <div className="logout-dropdown-names">
                                                <h6>{user?.username}</h6>
                                                <p>{user?.role_name}</p>
                                            </div>
                                        </div>
                                    </Dropdown.Toggle>
                                </div>

                                <Dropdown.Menu className="mt-3">
                                    <Dropdown.Item className="d-flex align-items-center gap-2" onClick={handleLogout}>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="25"
                                            height="25"
                                            fill="currentColor"
                                            className="bi bi-box-arrow-in-right"
                                            viewBox="0 0 16 16"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0z"
                                            />
                                            <path
                                                fill-rule="evenodd"
                                                d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z"
                                            />
                                        </svg>
                                        Logout
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </header>
                    <div className="page-main">{children}</div>
                </aside>
            </div>
        </>
    );
}

export default AdminLayout;
