import React from 'react'
import { getImageBaseUrl } from '../../../../helper/envConfig';
import AdminDragAndDrop from './AdminDragAndDrop';
import AdminimageUpload from './AdminimageUpload';
function AdminUploadFile({ currentFiles, setCurrentFiles, setIsFileLoading, isFileLoading, fileProgress, setFileProgress }) {

    const courseFile = getImageBaseUrl() + "/media/employee_excel/sample_file_of_course_upload.xlsx";
    const sampleType = getImageBaseUrl() + "/media/employee_excel/course_type_and_category.xlsx";

    return (
        <>
            <div className="row">
                <div className="col-12 col-xl-9 pe-5">
                    <p className='upload-text'>Upload an Excel file with course details such as course title, course chapters, category, subcategory, type, notes, quiz and tags.</p>
                </div>
            </div>
            <div className="card border-0">
                <div className="card-body px-0">
                    {/* Directly use <a> tag for the download link */}
                    <div className='mt-1'>
                        {/* <AdminDragAndDrop fileProgress={fileProgress} setFileProgress={setFileProgress} currentFiles={currentFiles} setCurrentFiles={setCurrentFiles} isFileLoading={isFileLoading} setIsFileLoading={setIsFileLoading} /> */}
                        <AdminimageUpload  currentFiles={currentFiles} setCurrentFiles={setCurrentFiles} />
                    </div>
                    <a href={courseFile} download className='d-flex justify-content-start align-items-center gap-2 text-primary mt-4 mb-2'>
                        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.09 9H15.5V4C15.5 3.45 15.05 3 14.5 3H10.5C9.95 3 9.5 3.45 9.5 4V9H7.91C7.02 9 6.57 10.08 7.2 10.71L11.79 15.3C12.18 15.69 12.81 15.69 13.2 15.3L17.79 10.71C18.42 10.08 17.98 9 17.09 9ZM5.5 19C5.5 19.55 5.95 20 6.5 20H18.5C19.05 20 19.5 19.55 19.5 19C19.5 18.45 19.05 18 18.5 18H6.5C5.95 18 5.5 18.45 5.5 19Z" fill="#002FA7" />
                        </svg>
                        <p className='mb-0 fs-6 fw-400'>Download the sample file of course</p>
                    </a>
                    <a href={sampleType} download className='d-flex justify-content-start align-items-center gap-2 text-primary mt-2 mb-2'>
                        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.09 9H15.5V4C15.5 3.45 15.05 3 14.5 3H10.5C9.95 3 9.5 3.45 9.5 4V9H7.91C7.02 9 6.57 10.08 7.2 10.71L11.79 15.3C12.18 15.69 12.81 15.69 13.2 15.3L17.79 10.71C18.42 10.08 17.98 9 17.09 9ZM5.5 19C5.5 19.55 5.95 20 6.5 20H18.5C19.05 20 19.5 19.55 19.5 19C19.5 18.45 19.05 18 18.5 18H6.5C5.95 18 5.5 18.45 5.5 19Z" fill="#002FA7" />
                        </svg>
                        <p className='mb-0 fs-6 fw-400'>Download the file of course types and categories list</p>
                    </a>
                    <p className='format-text'>Format accepted is .xlsx</p>
                    {/* <p className='text-muted fw-400 mb-2'>Up to 50 files</p> */}
                </div>
            </div>
        </>
    )
}

export default AdminUploadFile