import React from 'react'
import Header from './../layout/Header';
import CommunityHomeBanner from '../components/CommunityHomeBanner';
import Footer from '../layout/Footer';
import CommunitySidbar from '../components/CommunitySidbar';
import { Link } from 'react-router-dom';
import CommunitySidbarModal from '../components/CommunitySidbarModal';

const CommunityHelp = () => {
    return (
        <>
            <Header />
            <CommunityHomeBanner />
            <section>
                <div className="featured-courses-main">
                    <div className="container">
                        <div className="row featured-courses-row">
                            <div className="col-md-4 col-lg-3 col-xl-2 d-none d-md-block">
                                <CommunitySidbar />
                            </div>
                            <div className="col-md-8 col-lg-9 col-xl-10">
                                <div className="row justify-content-center mb-4">
                                    <div className="col-12 col-lg-11">
                                        <div className="section-title col-md-12 col-lg-12 m-auto">
                                            <div className="breadcrumb-main mt-4">
                                                <nav aria-label="breadcrumb">
                                                    <ol className="breadcrumb align-items-center mb-3">
                                                        <li className="breadcrumb-item">
                                                            <Link to="" className="fw-400 fs-7">
                                                                Community
                                                            </Link>
                                                        </li>
                                                        <li
                                                            className="breadcrumb-item active fw-400 fs-7"
                                                            aria-current="page"
                                                        >
                                                            Help Center
                                                        </li>
                                                    </ol>
                                                </nav>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 d-flex justify-content-md-end justify-content-between align-items-center mb-4 mb-md-0">
                                        <CommunitySidbarModal />
                                    </div>
                                </div>
                                {/* FEATURED COURSES CATRGORY TITLE SECTION END */}
                                <div className="section-title col-md-12 col-lg-11 m-auto">
                                    <h2 className="text-start text-black display-6">Here are Our Guides</h2>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-md-12 col-lg-11">
                                        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-3 g-4 g-xl-5">
                                            <div className="col">
                                                <Link to="/community-help-center-topic">
                                                    <div className="card help-card border-0 shadow-sm h-100">
                                                        <div className="card-body p-5">
                                                            <img
                                                                src="./community/help-center-1.png"
                                                                className="card-img-top"
                                                                alt="..."
                                                            />
                                                            <h4 className="text-black fw-400 mt-4">
                                                                Account Registration and Login
                                                            </h4>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="col">
                                                <Link to="/community-help-center-topic">
                                                    <div className="card help-card border-0 shadow-sm h-100">
                                                        <div className="card-body p-5">
                                                            <img
                                                                src="./community/help-center-2.png"
                                                                className="card-img-top"
                                                                alt="..."
                                                            />
                                                            <h4 className="text-black fw-400 mt-4">Getting Started</h4>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="col">
                                                <Link to="/community-help-center-topic">
                                                    <div className="card help-card border-0 shadow-sm h-100">
                                                        <div className="card-body p-5">
                                                            <img
                                                                src="./community/help-center-3.png"
                                                                className="card-img-top"
                                                                alt="..."
                                                            />
                                                            <h4 className="text-black fw-400 mt-4">Pricing and Billing</h4>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="col">
                                                <Link to="/community-help-center-topic">
                                                    <div className="card help-card border-0 shadow-sm h-100">
                                                        <div className="card-body p-5">
                                                            <img
                                                                src="./community/help-center-4.png"
                                                                className="card-img-top"
                                                                alt="..."
                                                            />
                                                            <h4 className="text-black fw-400 mt-4">Course Levels</h4>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="col">
                                                <Link to="/community-help-center-topic">
                                                    <div className="card help-card border-0 shadow-sm h-100">
                                                        <div className="card-body p-5">
                                                            <img
                                                                src="./community/help-center-5.png"
                                                                className="card-img-top"
                                                                alt="..."
                                                            />
                                                            <h4 className="text-black fw-400 mt-4">
                                                                Course Completion and Certificates
                                                            </h4>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="col">
                                                <Link to="/community-help-center-topic">
                                                    <div className="card help-card border-0 shadow-sm h-100">
                                                        <div className="card-body p-5">
                                                            <img
                                                                src="./community/help-center-6.png"
                                                                className="card-img-top"
                                                                alt="..."
                                                            />
                                                            <h4 className="text-black fw-400 mt-4">Course Enrollment</h4>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="col">
                                                <Link to="/community-help-center-topic">
                                                    <div className="card help-card border-0 shadow-sm h-100">
                                                        <div className="card-body p-5">
                                                            <img
                                                                src="./community/help-center-7.png"
                                                                className="card-img-top"
                                                                alt="..."
                                                            />
                                                            <h4 className="text-black fw-400 mt-4">Rewards</h4>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="col">
                                                <Link to="/community-help-center-topic">
                                                    <div className="card help-card border-0 shadow-sm h-100">
                                                        <div className="card-body p-5">
                                                            <img
                                                                src="./community/help-center-8.png"
                                                                className="card-img-top"
                                                                alt="..."
                                                            />
                                                            <h4 className="text-black fw-400 mt-4">learning groups</h4>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="col">
                                                <Link to="/community-help-center-topic">
                                                    <div className="card help-card border-0 shadow-sm h-100">
                                                        <div className="card-body p-5">
                                                            <img
                                                                src="./community/help-center-9.png"
                                                                className="card-img-top"
                                                                alt="..."
                                                            />
                                                            <h4 className="text-black fw-400 mt-4">
                                                                Reporting issues or concerns
                                                            </h4>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default CommunityHelp
