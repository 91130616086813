import React from 'react';
import BusinessSidebar from '../components/BusinessSidebar'
import AdminHeader from './../layout/AdminHeader';
import Footer from '../layout/Footer';
import TopPerformers from '../components/TopPerformers';
function BusinessLeaderboard() {


    return (
        <>
            <AdminHeader />
            <section>
                <div className="dashbaord-home-main">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-3 col-md-4 col-lg-3 col-xxl-3 d-none d-xl-block px-4 bg-white sidebar-mian">
                                <BusinessSidebar />
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 col-xxl-9 bg-body">
                                <div className="row ps-0 ps-lg-5 dashbaord-right-side justify-content-md-center justify-content-lg-between pt-5">
                                    <div className="col-12 col-md-11 col-lg-12 right-side">
                                        <h2 className="fw-600 text-black mb-5">Your Top Performers</h2>
                                        <div className="row mb-5 g-4">
                                        <TopPerformers />

                                            {/* <div className="col-12 col-lg-4">
                                                <div className="card border-0">
                                                    <div className="px-3 pt-4 d-flex justify-content-between align-items-center">
                                                        <p className="fs-18 fw-600 mb-0">Leaderboard</p>
                                                    </div>
                                                    <div className="card-body p-3">
                                                        <div className="row mb-4 align-items-center">
                                                            <div className="col-8">
                                                                <div className="d-flex align-items-center gap-3">
                                                                    <img
                                                                        src="admin-images/png/toppers1.png"
                                                                        className="toppers"
                                                                        alt=""
                                                                    />
                                                                    <p className="text-muted-light fs-7 fw-500 mb-0">
                                                                        Annette Watson
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="col-4">
                                                                <div className="d-flex justify-content-end">
                                                                    <p className="fs-7 fw-600 text-black mb-0">9.3</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-4 align-items-center">
                                                            <div className="col-8">
                                                                <div className="d-flex align-items-center gap-3">
                                                                    <img
                                                                        src="admin-images/png/toppers2.png"
                                                                        className="toppers"
                                                                        alt=""
                                                                    />
                                                                    <p className="text-muted-light fs-7 fw-500 mb-0">You</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-4">
                                                                <div className="d-flex justify-content-end">
                                                                    <p className="fs-7 fw-500 text-black mb-0">8.9</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-4 align-items-center">
                                                            <div className="col-8">
                                                                <div className="d-flex align-items-center gap-3">
                                                                    <img
                                                                        src="admin-images/png/toppers3.png"
                                                                        className="toppers"
                                                                        alt=""
                                                                    />
                                                                    <p className="text-muted-light fs-7 fw-500 mb-0">
                                                                        Ralph Richards
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="col-4">
                                                                <div className="d-flex justify-content-end">
                                                                    <p className="fs-7 fw-500 text-black mb-0">8.7</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-4 align-items-center">
                                                            <div className="col-8">
                                                                <div className="d-flex align-items-center gap-3">
                                                                    <img
                                                                        src="admin-images/png/toppers3.png"
                                                                        className="toppers"
                                                                        alt=""
                                                                    />
                                                                    <p className="text-muted-light fs-7 fw-500 mb-0">
                                                                        Bernard Murphy
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="col-4">
                                                                <div className="d-flex justify-content-end">
                                                                    <p className="fs-7 fw-500 text-black mb-0">8.2</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-4 align-items-center">
                                                            <div className="col-8">
                                                                <div className="d-flex align-items-center gap-3">
                                                                    <img
                                                                        src="admin-images/png/toppers3.png"
                                                                        className="toppers"
                                                                        alt=""
                                                                    />
                                                                    <p className="text-muted-light fs-7 fw-500 mb-0">
                                                                        Arlene Robertson
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="col-4">
                                                                <div className="d-flex justify-content-end">
                                                                    <p className="fs-7 fw-500 text-black mb-0">8.2</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-4 align-items-center">
                                                            <div className="col-8">
                                                                <div className="d-flex align-items-center gap-3">
                                                                    <img
                                                                        src="admin-images/png/toppers3.png"
                                                                        className="toppers"
                                                                        alt=""
                                                                    />
                                                                    <p className="text-muted-light fs-7 fw-500 mb-0">
                                                                        Jane Lane
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="col-4">
                                                                <div className="d-flex justify-content-end">
                                                                    <p className="fs-7 fw-500 text-black mb-0">8.1</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-4 align-items-center">
                                                            <div className="col-8">
                                                                <div className="d-flex align-items-center gap-3">
                                                                    <img
                                                                        src="admin-images/png/toppers3.png"
                                                                        className="toppers"
                                                                        alt=""
                                                                    />
                                                                    <p className="text-muted-light fs-7 fw-500 mb-0">
                                                                        Pat mckinney
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="col-4">
                                                                <div className="d-flex justify-content-end">
                                                                    <p className="fs-7 fw-500 text-black mb-0">7.9</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            <Footer />
        </>
    )
}

export default BusinessLeaderboard