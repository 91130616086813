import React from "react";

const RejectKid = () => {
    return (
        <section>
            <div className="p-4 p-md-5">
                <header className="top-title-header">
                    <h1>Unicorso</h1>
                    <p>Lifelong Learning for Kids</p>
                </header>
                <main className="main-body">
                    <h2>Why Unicorso?</h2>
                    <p>
                        It would be cool if parents make their kids use
                        Unicorso. Here are some reasons why:
                    </p>
                    <ul className="ps-0">
                        <li>
                            <strong>Lifelong Learning:</strong>
                            <p>
                                Unicorso provides opportunities for lifelong
                                learning.
                            </p>
                        </li>
                        <li>
                            <strong>Modern Life Skills:</strong>
                            <p>
                                Kids learn essential life skills in a modern
                                world, including getting familiar with the
                                chances and risks of using ChatGPT, AI,
                                Instagram, TikTok, and more.
                            </p>
                        </li>
                        <li>
                            <strong>Compliance and Safety:</strong>
                            <p>
                                Investing in compliance so that your kids do not
                                end up in trouble; for example, photos can be
                                tricky quickly.
                            </p>
                        </li>
                        <li>
                            <strong>Affordable:</strong>
                            <p>
                                It is cheap. See our
                                {"{"}" "{"}"}
                                <a href="#pricing">pricing page</a>.
                            </p>
                        </li>
                        <li>
                            <strong>Flexible:</strong>
                            <p>You can cancel anytime.</p>
                        </li>
                        <li>
                            <strong>Free Trial:</strong>
                            <p>You can test it for free for 3 months.</p>
                        </li>
                        <li>
                            <strong>Data Control:</strong>
                            <p>You are in full control of your data.</p>
                        </li>
                    </ul>
                </main>
                <footer>
                    <p>© 2024 Unicorso. All rights reserved.</p>
                </footer>
            </div>
        </section>
    );
};

export default RejectKid;
