import React, {  useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const CommunitySidbar = () => {

    const [ communityActiveTab, setCommunityActiveTab ] = useState("");

    const location = useLocation();

     useEffect(()=> {
         if (location.pathname === '/announcements' || location.pathname === '/faq-questions' || location.pathname === '/community-help' || location.pathname === '/community-help-center-topic'){
             setCommunityActiveTab(location.pathname)
         } else {
            setCommunityActiveTab('/announcements')
         }
     },[location.pathname])

    const handleTabClick = (to) => {
        setCommunityActiveTab(to);
    };

    return (
        <>
            <ul className="community-letf-side rounded-3 overflow-hidden shadow-right px-0">
                <li className={`border-bottom-list ${communityActiveTab === '/announcements' ? 'active' : ''}`}>
                    <Link
                        to="/announcements"
                        className="d-flex align-items-center gap-3"
                        onClick={() => handleTabClick('/announcements')}
                    >
                        <img
                            src="./community/community-leftside-1.png"
                            className="wh-25"
                            alt=""
                        />
                        <p className="text-black fs-6 fw-400 mb-0">Announcements</p>
                    </Link>
                </li>
                <li className={`border-bottom-list ${communityActiveTab === '/faq-questions' ? 'active' : ''}`}>
                    <Link
                        to="/faq-questions"
                        className="d-flex align-items-center gap-3"
                        onClick={() => handleTabClick('/faq-questions')}
                    >
                        <img
                            src="./community/community-leftside-2.png"
                            className="wh-25"
                            alt=""
                        />
                        <p className="text-black fs-6 fw-400 mb-0">Frequently Asked Questions</p>
                    </Link>
                </li>
                <li className={`border-bottom-list ${(communityActiveTab === '/community-help' || communityActiveTab === '/community-help-center-topic') ? 'active' : ''}`}>
                    <Link
                        to="/community-help"
                        className="d-flex align-items-center gap-3"
                        onClick={() => handleTabClick('/community-help')}
                    >
                        <img
                            src="./community/community-leftside-3.png"
                            className="wh-25"
                            alt=""
                        />
                        <p className="text-black fs-6 fw-400 mb-0">Help Center</p>
                    </Link>
                </li>
            </ul>
        </>
    )
}

export default CommunitySidbar
