import React, { useState } from 'react';
import AdminLayout from '../../components/Admin/AdminLayout'
import { Tab, Table, Tabs } from 'react-bootstrap';
import MarketingLineChart from '../../components/Admin/Marketing/MarketingLineChart';
import MarketingColumnChart from '../../components/Admin/Marketing/MarketingColumnChart';
import MarketingBudgetTracking from '../../components/Admin/Marketing/MarketingBudgetTracking';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

function AdminMarketing() {
    const [activeKey, setActiveKey] = useState('home');
    const handleSelect = (key) => {
        setActiveKey(key);
    };


    ChartJS.register(ArcElement, Tooltip, Legend);

    const data = {
        labels: ['SEO', 'Other Activities', 'SEA'],
        datasets: [
            {
                label: 'My First Dataset',
                data: [300, 50, 100],
                backgroundColor: [
                    '#B8CCFF',
                    '#FDC93A',
                    '#FF5A59',
                ],
                borderColor: '#fff',  // Optional: Add border color if needed
                borderWidth: 10,  // Optional: Add border width if needed
                borderRadius: 10, // Set border radius here
                hoverOffset: 4,
                borderColor: [
                    '#fff'
                ],
            },
        ],
    };

    const options = {
        plugins: {
            legend: {
                display: false, // Hide the legend
            },
            tooltip: {
                enabled: true, // Make sure tooltips are enabled
                backgroundColor: '#fff', // Change the tooltip background color
                titleColor: '#000', // Optional: Change title color
                bodyColor: '#000', // Optional: Change body text color
                borderColor: '#f5f5f5', // Optional: Change border color
                borderWidth: 1, // Optional: Change border width
            },
            // This will remove the labels from the chart
            datalabels: {
                display: false
            },
        },
    };

    return (
        <AdminLayout>
            <h2 className='page-title'>Marketing</h2>
            <div className="row marketing-srollbar">
                <div className="col-12">
                    <div className='teblas-tabbar setting-tabbar'>
                        <Tabs
                            id="myTab"
                            activeKey={activeKey} // Sets the current active tab
                            onSelect={handleSelect} // Event handler for tab selection
                            className="mb-4"
                            variant="pills" // Using 'pills' and custom CSS to underline
                        >
                            <Tab eventKey="home" title="SEO">
                                <div id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex={0}>
                                    <p className='fs-20 fw-600 text-bl mb-0'>Performance Metrics</p>
                                    <div className='user-management-table mt-3 overflow-auto'>
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <div className='d-flex align-items-center gap-2 pb-1 cursor-pointer'>
                                                            <p className='text-capitalize'>Page URL</p>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div className='d-flex align-items-center gap-2 pb-1 cursor-pointer'>
                                                            <p className='text-capitalize'>Traffic</p>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div className='d-flex align-items-center gap-2 pb-1 cursor-pointer'>
                                                            <p className='text-capitalize'>Conversions</p>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div className='d-flex align-items-center gap-2 pb-1 cursor-pointer'>
                                                            <p className='text-capitalize'>Bounce Rate</p>
                                                        </div>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>http://www.example.com</td>
                                                    <td>10,000 visits</td>
                                                    <td>500 conversions</td>
                                                    <td>40% bounce rate</td>
                                                </tr>

                                            </tbody>
                                        </Table>
                                    </div>

                                    <div className="card rounded-4 mt-4">
                                        <div className="card-body p-4">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <p className="text-black fw-600 fs-18 mb-4">Traffic and Conversions</p>
                                            </div>
                                            <MarketingLineChart />
                                        </div>
                                    </div>
                                </div>
                            </Tab>



                            <Tab eventKey="profile" title="SEA">
                                <div id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabIndex={0}>
                                    <div className='user-management-table mt-3'>
                                        <p className='fs-20 fw-600 text-bl mb-0'>SEA Campaigns</p>
                                        <div className='user-management-table mt-3 overflow-auto'>
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            <div className='d-flex align-items-center gap-2 pb-1 cursor-pointer'>
                                                                <p className='text-capitalize'>Campaign Name</p>
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className='d-flex align-items-center gap-2 pb-1 cursor-pointer'>
                                                                <p className='text-capitalize'>Spend</p>
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className='d-flex align-items-center gap-2 pb-1 cursor-pointer'>
                                                                <p className='text-capitalize'>Impressions</p>
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className='d-flex align-items-center gap-2 pb-1 cursor-pointer'>
                                                                <p className='text-capitalize'>Clicks</p>
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className='d-flex align-items-center gap-2 pb-1 cursor-pointer'>
                                                                <p className='text-capitalize'>Conversions</p>
                                                            </div>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Spring Sale Campaign</td>
                                                        <td>$5,000</td>
                                                        <td>100,000</td>
                                                        <td>10,000</td>
                                                        <td>500 conversions</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>

                                        <div className="card rounded-4 mt-4">
                                            <div className="card-body p-4">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <p className="text-black fw-600 fs-18 mb-4">Course Completion Rates</p>
                                                </div>
                                                <MarketingColumnChart />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab>

                            <Tab eventKey="contact" title="Marketing Budget  ">
                                <div id="contact-tab-pane" role="tabpanel" aria-labelledby="contact-tab" tabIndex={0}>
                                    <div className='user-management-table mt-3'>
                                        <p className='fs-20 fw-600 text-bl mb-0'>Marketing Budget</p>
                                        <div className="row">
                                            <div className="col-12 col-xl-8">
                                                <div className='user-management-table mt-3 overflow-auto'>
                                                    <Table>
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    <div className='d-flex align-items-center gap-2 pb-1 cursor-pointer'>
                                                                        <p className='text-capitalize'>Activity Type</p>
                                                                    </div>
                                                                </th>
                                                                <th>
                                                                    <div className='d-flex align-items-center gap-2 pb-1 cursor-pointer'>
                                                                        <p className='text-capitalize'>Budget Allocated</p>
                                                                    </div>
                                                                </th>
                                                                <th>
                                                                    <div className='d-flex align-items-center gap-2 pb-1 cursor-pointer'>
                                                                        <p className='text-capitalize'>Current Spend</p>
                                                                    </div>
                                                                </th>
                                                                <th>
                                                                    <div className='d-flex align-items-center gap-2 pb-1 cursor-pointer'>
                                                                        <p className='text-capitalize'>Budget Remaining</p>
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>SEO</td>
                                                                <td>$10,000</td>
                                                                <td>$7,500</td>
                                                                <td>$2,500</td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-8 col-lg-6 col-xl-4">
                                                <div className="card rounded-4 mt-4">
                                                    <div className="card-body p-4">
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <p className="text-black fw-600 fs-18 mb-4">Budget Tracking</p>
                                                        </div>
                                                        <Pie data={data} options={options} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        </AdminLayout>
    )
}

export default AdminMarketing