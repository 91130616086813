import React, { useEffect, useState } from "react";
import AdminLayout from "../../components/Admin/AdminLayout";
import { Tabs, Tab, Table, Row, Col, Modal } from "react-bootstrap";
import DoughnutChart from "../../components/DoughnutChart";
import AdminLineChart from "../../components/Admin/UserManagement/AdminLineChart";
import api from "../../helper/api";
import { getServerURL } from "../../helper/envConfig";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import CommonLoader from "../../components/Admin/CommonLoader";
// import api from '../../helper/api';
// import { getServerURL } from '../../helper/envConfig';

function UserProfileManagement() {
    const serverURL = getServerURL();
    const [showLoader, setShowLoader] = useState(true);
    const { state } = useLocation();
    const [show, setShow] = useState(false);
    const [activeKey, setActiveKey] = useState("home");
    const [roles, setRoles] = useState([]);
    const [usersData, setUsersData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        is_active: null,
        address: "",
        role_name: "",
        role_id: 0,
    });
    const [editUsersData, setEditUsersData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        is_active: null,
        address: "",
        role_name: "",
        role_id: 0,
    });

    const [activityLogs, setActivityLogs] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalActivityLogs, setTotalActivityLogs] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [enrollmentCourse, setEnrollmentCourse] = useState([]);
    const [userProgressData, setUserProgressData] = useState([]);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setEditUsersData(usersData);
        setShow(true);
    };

    const handleButtonClick = (buttonType) => {
        setEditUsersData({
            ...usersData,
            is_active: !usersData.is_active,
        });
    };

    const getUserProfileData = async () => {
        try {
            const res = await api.postWithToken(
                `${serverURL}admin-panel/user-management/user-details`,
                { user_id: state.user_id }
            );
            setUsersData({
                first_name: res.data.data.first_name || "",
                last_name: res.data.data.last_name || "",
                email: res.data.data.email,
                is_active: res.data.data.is_active,
                address: res.data.data.address || "",
                role_name: res.data.data.role_name,
                role_id: res.data.data.role_id,
            });
            setShowLoader(false)
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getUserProfileData();
    }, []);

    const getUserRoles = async () => {
        try {
            const response = await api.getWithToken(
                `${serverURL}admin-panel/user-management/roles-list`
            );
            setRoles(response.data.data);
        } catch (error) {
            setRoles([]);
            console.error(error);
        }
    };

    useEffect(() => {
        getUserRoles();
    }, []);

    const handleSelect = (key) => {
        setActiveKey(key);
    };

    const handleRoleChange = (role) => {
        setEditUsersData({ ...editUsersData, role_id: role });
    };

    const handleUserDataChange = (e) => {
        setEditUsersData({ ...editUsersData, [e.target.name]: e.target.value });
    };

    const handleEditUser = async () => {
        try {
            const response = await api.patchWithToken(
                `${serverURL}admin-panel/user-management/user-details`,
                {
                    user_id: state.user_id,
                    first_name: editUsersData.first_name,
                    last_name: editUsersData.last_name,
                    role_id: editUsersData.role_id,
                    is_active: editUsersData.is_active,
                    address: editUsersData.address,
                }
            );
            toast.success(response.data.message);
            getUserProfileData();
            handleClose();
        } catch (error) {
            console.log(error);
        }
    };

    const getActivityLogs = async () => {
        try {
            const response = await api.getWithToken(
                `${serverURL}admin-panel/user-management/activity-logs?page=${page}&per_page=${pageSize}&user_id=${state.user_id}`
            );
            setActivityLogs(response.data.results.activity_log);
            setTotalActivityLogs(response.data.total);
            setTotalPages(response.data.total_pages);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getActivityLogs();
    }, []);

    const getEnrollmentCourse = async () => {
        try {
            const response = await api.postWithToken(
                `${serverURL}admin-panel/user-management/user-course-enrollment`,
                { user_id: state.user_id }
            );
            setEnrollmentCourse(response.data.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getEnrollmentCourse();
    }, []);

    const getUserProgressDetails = async () => {
        try {
            const response = await api.postWithToken(
                `${serverURL}admin-panel/user-management/user-progress-details`,
                { user_id: state.user_id }
            );
            setUserProgressData(response.data.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (usersData?.role_id === 6) {
            getUserProgressDetails();
        }
    }, [usersData.role_id]);

    return (
        <AdminLayout>
             {showLoader ? (
                <CommonLoader />
            ) : (
                <>
                 <h2 className="page-title">User Profile Management</h2>
                <nav className='admin-breadcrumb' aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/admin-user-management">User Management </Link></li>
                        <li className="breadcrumb-item active" aria-current="page">User Profile Management</li>
                    </ol>
                </nav>
                <div className="user-profile-management-main mt-5">
                    <div className="card border-0 rounded-4">
                        <div className="card-body p-5">
                            <div className="d-flex justify-content-between align-items-start">
                                <div className="d-flex align-items-start">
                                    <div className="user-profile-img">
                                        <img
                                            src="./admin/profile.png"
                                            className="w-100 h-100"
                                            alt=""
                                        />
                                    </div>
                                    <div className="ps-5">
                                        <h5>Personal Info</h5>
                                        <div className="d-flex align-items-start gap-5">
                                            <div>
                                                <p>
                                                    <span>Name: </span>
                                                    {usersData.first_name}
                                                </p>
                                                <p>
                                                    <span>Second Name: </span>
                                                    {usersData.last_name}
                                                </p>
                                                <p>
                                                    <span>Role: </span>
                                                    {usersData.role_name}
                                                </p>
                                                {/* <button className="change-role-btn">
                                                    Change Role
                                                </button> */}
                                            </div>
                                            <div>
                                                <p>
                                                    <span>Email: </span>
                                                    {usersData.email}
                                                </p>
                                                <p>
                                                    <span>Location: </span>
                                                    {usersData.address}
                                                </p>
                                                <p>
                                                    <span>Status: </span>{" "}
                                                    {usersData.is_active
                                                        ? "Activate"
                                                        : "Suspend"}
                                                </p>
                                                {/* <div className="activates-buttons d-flex align-items-center">
                                                    <button
                                                        className={`w-100 ${
                                                            activeButton ===
                                                            "suspend"
                                                                ? "active"
                                                                : ""
                                                        }`}
                                                        onClick={() =>
                                                            handleButtonClick(
                                                                "suspend"
                                                            )
                                                        }
                                                    >
                                                        Suspend
                                                    </button>
                                                    <button
                                                        className={`w-100 ${
                                                            activeButton ===
                                                            "activate"
                                                                ? "active"
                                                                : ""
                                                        }`}
                                                        onClick={() =>
                                                            handleButtonClick(
                                                                "activate"
                                                            )
                                                        }
                                                    >
                                                        Activate
                                                    </button>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button className="apply-btn" onClick={handleShow}>
                                    Edit Profile
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="teblas-tabbar">
                    <Tabs
                        id="myTab"
                        activeKey={activeKey} // Sets the current active tab
                        onSelect={handleSelect} // Event handler for tab selection
                        className="mb-3 mt-5"
                        variant="pills" // Using 'pills' and custom CSS to underline
                    >
                        <Tab eventKey="home" title="Activity Logs">
                            <div
                                id="home-tab-pane"
                                role="tabpanel"
                                aria-labelledby="home-tab"
                                tabIndex={0}
                            >
                                <div className="user-management-table user-management-table-2 mt-5 overflow-auto bg-white p-4 rounded-4">
                                    <p className="fs-18 fw-600 text-bl">
                                        Activity Logs
                                    </p>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>
                                                    <div className="d-flex align-items-center gap-2">
                                                        <p className="mb-0 d-flex align-items-center gap-2">
                                                            Activity Type
                                                        </p>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className="d-flex align-items-center gap-2 pb-1">
                                                        <p>Details </p>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className="d-flex align-items-center gap-2 pb-1">
                                                        <p>Date & Time </p>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className="d-flex align-items-center gap-2 pb-1">
                                                        <p>Device Used </p>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className="d-flex align-items-center gap-2 pb-1">
                                                        <p>Location</p>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {activityLogs.length > 0 &&
                                                activityLogs.map((item, i) => (
                                                    <tr key={i}>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-2">
                                                                {item.activity_type}
                                                            </div>
                                                        </td>
                                                        <td className="px-2">
                                                            {item.details}
                                                        </td>
                                                        <td className="px-2">
                                                            {item.date_time}
                                                        </td>
                                                        <td className="px-2">
                                                            {item.browser}
                                                        </td>
                                                        <td className="px-2">
                                                            {item.device}
                                                        </td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </Tab>

                        <Tab eventKey="profile" title="Course Enrollments">
                            <div
                                id="profile-tab-pane"
                                role="tabpanel"
                                aria-labelledby="profile-tab"
                                tabIndex={0}
                            >
                                <div className="user-management-table user-management-table-2 mt-5 overflow-auto bg-white p-4 rounded-4">
                                    <p className="fs-18 fw-600 text-bl">
                                        Course Enrollments
                                    </p>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>
                                                    <div className="d-flex align-items-center gap-2">
                                                        <p className="mb-0 d-flex align-items-center gap-2">
                                                            Course Name{" "}
                                                        </p>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className="d-flex align-items-center gap-2 pb-1">
                                                        <p>Category</p>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className="d-flex align-items-center gap-2 pb-1">
                                                        <p>Grade</p>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className="d-flex align-items-center gap-2 pb-1">
                                                        <p>Certificates No</p>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {enrollmentCourse.length > 0 &&
                                                enrollmentCourse.map((course) => {
                                                    return (
                                                        <tr>
                                                            <td>
                                                                <div className="d-flex align-items-center gap-3">
                                                                    <img
                                                                        src="./admin/table-image.png"
                                                                        alt=""
                                                                        width={62}
                                                                        height={38}
                                                                        className="rounded-2"
                                                                    />
                                                                    <div>
                                                                        <p className="mb-0 text-light-400 fs-8 fw-400">
                                                                            {
                                                                                course.course_name
                                                                            }
                                                                        </p>
                                                                        <p className="mb-0 text-light-500 fs-8 fw-400">
                                                                            {
                                                                                course.type
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className="px-2">
                                                                {course.category}
                                                            </td>
                                                            <td className="px-2">
                                                                {course.grade}
                                                            </td>
                                                            <td className="px-2">
                                                                {course.certificate}
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </Tab>

                        {usersData.role_id === 6 && (
                            <Tab eventKey="contact" title="Progress Charts">
                                <div
                                    id="contact-tab-pane"
                                    role="tabpanel"
                                    aria-labelledby="contact-tab"
                                    tabIndex={0}
                                >
                                    <Row className="g-4 mt-2">
                                        <Col className="col-12 col-md-12 col-lg-6">
                                            <div className="card border-0 h-100">
                                                <div className="card-body p-4">
                                                    <p className="text-black fw-600 fs-21 mb-4">
                                                        Course Completion Status
                                                    </p>
                                                    <div className="row align-items-center h-100">
                                                        <div className="col-5 d-flex justify-content-center ps-5">
                                                            <DoughnutChart
                                                                height={"100"}
                                                                width={100}
                                                                labels={[
                                                                    "completed",
                                                                    "in-progress",
                                                                    "not-started",
                                                                ]}
                                                                data={[
                                                                    userProgressData
                                                                        ?.course_completion_status
                                                                        ?.completed,
                                                                    userProgressData
                                                                        ?.course_completion_status
                                                                        ?.in_progress,
                                                                    userProgressData
                                                                        ?.course_completion_status
                                                                        ?.not_started,
                                                                ]}
                                                                centerFieldName={
                                                                    "Total"
                                                                }
                                                                centerFieldValue={
                                                                    userProgressData
                                                                        ?.course_completion_status
                                                                        ?.completed +
                                                                    userProgressData
                                                                        ?.course_completion_status
                                                                        ?.in_progress +
                                                                    userProgressData
                                                                        ?.course_completion_status
                                                                        ?.not_started
                                                                }
                                                            />
                                                        </div>
                                                        <div className="col-7">
                                                            <div className="chart-listing ps-5">
                                                                <div className="d-flex align-items-center gap-3 mb-3">
                                                                    <div className="dot-1 dots"></div>
                                                                    <p>Completed</p>
                                                                    <span>
                                                                        {
                                                                            userProgressData
                                                                                ?.course_completion_status
                                                                                ?.completed
                                                                        }
                                                                    </span>
                                                                </div>
                                                                <div className="d-flex align-items-center gap-3 mb-3">
                                                                    <div className="dot-2 dots"></div>
                                                                    <p>
                                                                        In Progress
                                                                    </p>
                                                                    <span>
                                                                        {
                                                                            userProgressData
                                                                                ?.course_completion_status
                                                                                ?.in_progress
                                                                        }
                                                                    </span>
                                                                </div>
                                                                <div className="d-flex align-items-center gap-3 mb-3">
                                                                    <div className="dot-3 dots"></div>
                                                                    <p>
                                                                        Not Started
                                                                    </p>
                                                                    <span>
                                                                        {
                                                                            userProgressData
                                                                                ?.course_completion_status
                                                                                ?.not_started
                                                                        }
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col className="col-12 col-md-12 col-lg-6">
                                            <div className="card border-0 h-100">
                                                <div className="card-body p-4">
                                                    <p className="text-black fw-600 fs-21 mb-4">
                                                        Percentage of Completed
                                                        Courses
                                                    </p>
                                                    <h3 className="chart-listing">
                                                        {
                                                            userProgressData?.completed_percentage
                                                        }
                                                    </h3>
                                                    {/* <div className="chart-month-numbers d-flex align-items-center gap-2">
                                                        <img
                                                            src="./admin/arrow-down.png"
                                                            alt=""
                                                        />
                                                        <span>2.1%</span>{" "}
                                                        <p className="mb-0">
                                                            vs last month
                                                        </p>
                                                    </div> */}
                                                    <div className="mt-4">
                                                        <AdminLineChart
                                                            values={
                                                                (userProgressData?.completed_courses_month_wise &&
                                                                    Object.values(
                                                                        userProgressData?.completed_courses_month_wise
                                                                    )) ||
                                                                []
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Tab>
                        )}
                    </Tabs>
                </div>
                </>
            )}
           

            <Modal centered size="lg" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Profile</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form action="">
                        <div className="row g-4">
                            <div className="col-12 col-md-6">
                                <label htmlFor="" className="mb-2">
                                    Name:
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter your name"
                                    name="first_name"
                                    onChange={handleUserDataChange}
                                    value={editUsersData.first_name}
                                />
                            </div>
                            <div className="col-12 col-md-6">
                                <label htmlFor="" className="mb-2">
                                    Second Name:
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter second name"
                                    onChange={handleUserDataChange}
                                    name="last_name"
                                    value={editUsersData.last_name}
                                />
                            </div>
                            <div className="col-12 col-md-6">
                                <label htmlFor="" className="mb-2">
                                    Email:
                                </label>
                                <input
                                    disabled
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter your email"
                                    name="email"
                                    value={editUsersData.email}
                                />
                            </div>
                            <div className="col-12 col-md-6">
                                <label htmlFor="" className="mb-2">
                                    Location:
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter your location"
                                    name="address"
                                    onChange={handleUserDataChange}
                                    value={editUsersData.address}
                                />
                            </div>
                            <div className="col-12 col-md-6">
                                <label htmlFor="" className="mb-2">
                                    Status:
                                </label>
                                <div className="activates-buttons d-flex align-items-center">
                                    <button
                                        className={`w-100 ${!editUsersData.is_active
                                            ? "active"
                                            : ""
                                            }`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleButtonClick();
                                        }}
                                    >
                                        Suspend
                                    </button>
                                    <button
                                        className={`w-100 ${editUsersData.is_active
                                            ? "active"
                                            : ""
                                            }`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleButtonClick();
                                        }}
                                    >
                                        Activate
                                    </button>
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <label htmlFor="" className="mb-2">
                                    Role:
                                </label>
                                <select
                                    className="form-select role-select"
                                    aria-label="Default select example"
                                    value={editUsersData.role_id}
                                    onChange={(e) =>
                                        handleRoleChange(e.target.value)
                                    }
                                >
                                    {roles.length > 0 &&
                                        roles.map((role) => {
                                            return (
                                                <option
                                                    key={role.role_id}
                                                    value={role.role_id}
                                                >
                                                    {role.role_name}
                                                </option>
                                            );
                                        })}
                                </select>
                            </div>
                        </div>

                        <div className="d-flex justify-content-end gap-3 pricing-management-buttons pt-5">
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleClose();
                                }}
                            >
                                Cancel
                            </button>
                            <button
                                onClick={(e) => {
                                    console.log("nnnnnnnnnnnnnnnnnn");
                                    e.preventDefault();
                                    handleEditUser();
                                }}
                            >
                                Save
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </AdminLayout>
    );
}

export default UserProfileManagement;
