import React from 'react';
import AdminHeader from './../layout/AdminHeader';
import Footer from '../layout/Footer';
import SliderHomeSec from './../components/SliderHomeSec';
import ContentSidbar from '../components/ContentSidbar';
import AreaChart from '../components/AreaChart';
import DoughnutChart from '../components/DoughnutChart';

function ContentHome() {


    
    return (
        <>
            <AdminHeader />
            <section>
                <div className="dashbaord-home-main">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-3 col-md-4 col-lg-3 col-xxl-3 d-none d-xl-block px-4 bg-white sidebar-mian">
                                <ContentSidbar />
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 col-xxl-9 bg-body">
                                <div className="row ps-0 ps-lg-4 pe-lg-4 ps-xl-5 dashbaord-right-side justify-content-md-center justify-content-lg-between">
                                    <div className="col-12 col-md-11 col-lg-12 right-side">
                                        <SliderHomeSec />
                                        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4 mb-5 pt-5 pt-md-0">
                                            <div className="col">
                                                <div className="card border-0 h-100 rounded-3">
                                                    <div className="card-body p-4 pb-0">
                                                        <div className="icons-crile mb-4">
                                                            <img
                                                                src="./admin-images/png/trophy2.png"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <p className="fw-12 fw-400 text-muted mb-4">
                                                            Courses taken this year
                                                        </p>
                                                    </div>
                                                    <div className="card-footer bg-transparent border-0 pt-0 px-4">
                                                        <h2 className="fw-400 text-black">250</h2>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="card border-0 h-100 rounded-3">
                                                    <div className="card-body p-4 pb-0">
                                                        <div className="icons-crile mb-4">
                                                            <img
                                                                src="./admin-images/png/desktop.png"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <p className="fw-12 fw-400 text-muted mb-4">
                                                            Mandatory Courses for all employees
                                                        </p>
                                                    </div>
                                                    <div className="card-footer bg-transparent border-0 pt-0 px-4">
                                                        <h2 className="fw-400 text-black">20</h2>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="card border-0 h-100 rounded-3">
                                                    <div className="card-body p-4 pb-0">
                                                        <div className="icons-crile mb-4">
                                                            <img
                                                                src="./admin-images/png/clock2.png"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <p className="fw-12 fw-400 text-muted mb-4">
                                                            Overdue mandatory courses for all employees
                                                        </p>
                                                    </div>
                                                    <div className="card-footer bg-transparent border-0 pt-0 px-4">
                                                        <h2 className="fw-400 text-black">3</h2>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="card border-0 h-100 rounded-3">
                                                    <div className="card-body p-4 pb-0">
                                                        <div className="icons-crile mb-4">
                                                            <img
                                                                src="./admin-images/png/employee.png"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <p className="fw-12 fw-400 text-muted mb-4">
                                                            Total Sign Up
                                                        </p>
                                                    </div>
                                                    <div className="card-footer bg-transparent border-0 pt-0 px-4">
                                                        <h2 className="fw-400 text-black">200</h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row g-4 pb-5">
                                            <div className="col-12 col-lg-6">
                                                <div className="card border-0">
                                                    <div className="card-header bg-transparent border-0 pb-0 d-flex align-items-center py-4 position-relative">
                                                        <div className="d-flex justify-content-between align-items-center w-100">
                                                            <p className="text-black fw-600 fs-18 mb-0">
                                                            Monthly Sign Ups
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="card-body p-4">
                                                        <div className="overflow-auto">
                                                            <div className="barchart">
                                                                <AreaChart />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-6">
                                                <div className="card border-0">
                                                    <div className="card-body p-4">
                                                        <div className="d-md-flex justify-content-between align-items-center">
                                                            <div>
                                                                <p className="text-black fw-600 fs-18 mb-4">
                                                                    Types Of Courses Taken
                                                                </p>
                                                                <div className="d-flex align-items-start gap-2">
                                                                    <h4 className="text-black fw-400 mb-0">8</h4>
                                                                    <span className="text-black fs-6 fw-400 mb-0">
                                                                        courses
                                                                    </span>
                                                                </div>
                                                                <p className="text-muted fs-7 fw-400">of 20,050</p>
                                                            </div>
                                                            <div className="chart-demo d-flex d-md-block justify-content-center">
                                                                <DoughnutChart />
                                                            </div>
                                                        </div>
                                                        <div className="progress-main-2 mt-4">
                                                            <div className="d-flex justify-content-between align-items-center mb-3">
                                                                <p className="text-muted fw-400 fs-7 mb-0">
                                                                    Compliance
                                                                </p>
                                                                <p className="text-muted fw-400 fs-7 mb-0">
                                                                    3 courses
                                                                </p>
                                                            </div>
                                                            <div
                                                                className="progress progress-height01 mb-4"
                                                                role="progressbar"
                                                                aria-label="Basic example"
                                                                aria-valuenow={0}
                                                                aria-valuemin={0}
                                                                aria-valuemax={100}
                                                            >
                                                                <div className="progress-bar progress01" />
                                                            </div>
                                                            <div className="d-flex justify-content-between align-items-center mb-3">
                                                                <p className="text-muted fw-400 fs-7 mb-0">GDPR</p>
                                                                <p className="text-muted fw-400 fs-7 mb-0">
                                                                    2 courses
                                                                </p>
                                                            </div>
                                                            <div
                                                                className="progress progress-height01 mb-4"
                                                                role="progressbar"
                                                                aria-label="Basic example"
                                                                aria-valuenow={0}
                                                                aria-valuemin={0}
                                                                aria-valuemax={100}
                                                            >
                                                                <div className="progress-bar progress02" />
                                                            </div>
                                                            <div className="d-flex justify-content-between align-items-center mb-3">
                                                                <p className="text-muted fw-400 fs-7 mb-0">Skills</p>
                                                                <p className="text-muted fw-400 fs-7 mb-0">
                                                                    2 course
                                                                </p>
                                                            </div>
                                                            <div
                                                                className="progress progress-height01 mb-4"
                                                                role="progressbar"
                                                                aria-label="Basic example"
                                                                aria-valuenow={0}
                                                                aria-valuemin={0}
                                                                aria-valuemax={100}
                                                            >
                                                                <div className="progress-bar progress03" />
                                                            </div>
                                                            <div className="d-flex justify-content-between align-items-center mb-3">
                                                                <p className="text-muted fw-400 fs-7 mb-0">Fun</p>
                                                                <p className="text-muted fw-400 fs-7 mb-0">
                                                                    1 course
                                                                </p>
                                                            </div>
                                                            <div
                                                                className="progress progress-height01 mb-4"
                                                                role="progressbar"
                                                                aria-label="Basic example"
                                                                aria-valuenow={0}
                                                                aria-valuemin={0}
                                                                aria-valuemax={100}
                                                            >
                                                                <div className="progress-bar progress04" />
                                                            </div>
                                                            <div className="d-flex justify-content-between align-items-center mb-3">
                                                                <p className="text-muted fw-400 fs-7 mb-0">Other</p>
                                                                <p className="text-muted fw-400 fs-7 mb-0">
                                                                    1 course
                                                                </p>
                                                            </div>
                                                            <div
                                                                className="progress progress-height01 mb-4"
                                                                role="progressbar"
                                                                aria-label="Basic example"
                                                                aria-valuenow={0}
                                                                aria-valuemin={0}
                                                                aria-valuemax={100}
                                                            >
                                                                <div className="progress-bar progress04" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="row mb-5 g-4">
                                            <div className="col-12 col-lg-12">
                                                <div className="card border-0">
                                                    <div className="card-body p-4 p-md-5">
                                                        <p className="fw-600 fs-18 text-black mb-3">
                                                            Top Performers
                                                        </p>
                                                        <div className="table-responsive">
                                                            <table className="table top-performers-table-main align-middle border-secondary">
                                                                <thead>
                                                                    <tr>
                                                                        <th className="table-th-width-50 ps-0">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="checkbox"
                                                                                defaultValue=""
                                                                                id="flexCheckDefault"
                                                                            />
                                                                        </th>
                                                                        <th className="table-th-width-158">
                                                                            <div className="d-flex justify-content-between align-items-center">
                                                                                <p className="ps-0 ps-md-2">Student</p>
                                                                                <svg
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    width={24}
                                                                                    height={24}
                                                                                    viewBox="0 0 24 24"
                                                                                    fill="none"
                                                                                >
                                                                                    <path
                                                                                        d="M12 5V19"
                                                                                        stroke="black"
                                                                                        strokeWidth={2}
                                                                                        strokeLinecap="round"
                                                                                        strokeLinejoin="round"
                                                                                    />
                                                                                    <path
                                                                                        d="M19 12L12 19L5 12"
                                                                                        stroke="black"
                                                                                        strokeWidth={2}
                                                                                        strokeLinecap="round"
                                                                                        strokeLinejoin="round"
                                                                                    />
                                                                                </svg>
                                                                            </div>
                                                                        </th>
                                                                        <th className="table-th-width-100">
                                                                            <p className="ps-0 ps-md-2">Status</p>
                                                                        </th>
                                                                        <th className="table-th-width-158">
                                                                            <p className="ps-0 ps-md-2">Course</p>
                                                                        </th>
                                                                        <th className="table-th-width-100">
                                                                            <p className="ps-0 ps-md-2">Progress</p>
                                                                        </th>
                                                                        <th className="table-th-width-100">
                                                                            <p className="ps-0 ps-md-2">Rating</p>
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <th scope="row">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="checkbox"
                                                                                defaultValue=""
                                                                                id="flexCheckDefault"
                                                                            />
                                                                        </th>
                                                                        <td>
                                                                            <div className="row">
                                                                                <div className="col-12 overflow-hidden">
                                                                                    <div className="d-flex align-items-center gap-2">
                                                                                        <img
                                                                                            src="./admin-images/png/toppers1.png"
                                                                                            alt=""
                                                                                            className="toppers"
                                                                                        />
                                                                                        <div className="student-details">
                                                                                            <p className="fw-400 fs-7 text-bl mb-1">
                                                                                                GuyHawkins
                                                                                            </p>
                                                                                            <span>
                                                                                                I neglected to mention that I
                                                                                                definitely won’t be coming to the
                                                                                                party on Saturday
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <button className="status-btn-1">
                                                                                Enrolled
                                                                            </button>
                                                                        </td>
                                                                        <td>
                                                                            <div className="course-content">
                                                                                <p>UI Masterclass</p>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="d-flex align-items-center gap-1">
                                                                                <p className="loder-text mb-0">75</p>
                                                                                <div
                                                                                    className="global table-progress-loder"
                                                                                    data-pie='{ "percent": 75 }'
                                                                                    data-pie-index={1}
                                                                                    style={{ width: 200, height: 200 }}
                                                                                >
                                                                                    <svg
                                                                                        role="progressbar"
                                                                                        width={200}
                                                                                        height={200}
                                                                                        viewBox="0 0 100 100"
                                                                                        aria-valuemin={0}
                                                                                        aria-valuemax={100}
                                                                                        aria-valuenow={75}
                                                                                    >
                                                                                        <circle
                                                                                            cx="50%"
                                                                                            cy="50%"
                                                                                            r={42}
                                                                                            shapeRendering="geometricPrecision"
                                                                                            fill="none"
                                                                                            stroke="#f2f7c5"
                                                                                            strokeWidth={10}
                                                                                        />
                                                                                        <circle
                                                                                            cx="50%"
                                                                                            cy="50%"
                                                                                            r={42}
                                                                                            shapeRendering="geometricPrecision"
                                                                                            className="global-circle-1"
                                                                                            fill="none"
                                                                                            strokeWidth={10}
                                                                                            strokeDashoffset={66}
                                                                                            strokeDasharray={264}
                                                                                            strokeLinecap="round"
                                                                                            style={{
                                                                                                transform: "rotate(-90deg)",
                                                                                                transformOrigin: "50% 50%",
                                                                                                transition:
                                                                                                    "stroke-dashoffset 1s ease-out"
                                                                                            }}
                                                                                            stroke="#D2E23D"
                                                                                            data-angel={75}
                                                                                        />
                                                                                    </svg>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <span className="rating-badge rounded-2 p-2">
                                                                                <svg
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    width={18}
                                                                                    height={18}
                                                                                    viewBox="0 0 18 18"
                                                                                    fill="none"
                                                                                >
                                                                                    <g clipPath="url(#clip0_1823_999)">
                                                                                        <path
                                                                                            d="M13.5171 16.9931C13.3229 16.9931 13.1297 16.9334 12.9634 16.8147L9.05288 14.0103L5.14239 16.8147C4.98037 16.9313 4.78566 16.9937 4.58607 16.993C4.38649 16.9923 4.19222 16.9285 4.03101 16.8109C3.86965 16.6939 3.7493 16.529 3.68707 16.3396C3.62485 16.1503 3.62392 15.9461 3.68443 15.7562L5.14287 11.0492L1.26747 8.31835C1.10686 8.19982 0.98758 8.03379 0.926505 7.84375C0.86543 7.65371 0.86565 7.44928 0.927134 7.25937C0.989104 7.06992 1.10904 6.90474 1.27 6.78717C1.43096 6.6696 1.6248 6.60559 1.82412 6.60418L6.6239 6.59697L8.15205 2.00677C8.21511 1.81767 8.33607 1.65321 8.49778 1.53667C8.6595 1.42013 8.85378 1.35742 9.05312 1.35742C9.25245 1.35742 9.44673 1.42013 9.60845 1.53667C9.77017 1.65321 9.89112 1.81767 9.95419 2.00677L11.4564 6.59697L16.2807 6.60418C16.4802 6.6053 16.6744 6.66929 16.8355 6.78703C16.9966 6.90478 17.1165 7.0703 17.1782 7.26009C17.2399 7.44988 17.2402 7.65428 17.1791 7.84425C17.118 8.03423 16.9986 8.20012 16.8378 8.31835L12.9624 11.0492L14.4208 15.7562C14.4815 15.9461 14.4807 16.1502 14.4185 16.3396C14.3564 16.5289 14.2361 16.6939 14.0747 16.8109C13.913 16.9295 13.7177 16.9933 13.5171 16.9931Z"
                                                                                            fill="#FFAC33"
                                                                                        />
                                                                                    </g>
                                                                                    <defs>
                                                                                        <clipPath id="clip0_1823_999">
                                                                                            <rect
                                                                                                width="17.3052"
                                                                                                height="17.3052"
                                                                                                fill="white"
                                                                                                transform="translate(0.400391 0.347656)"
                                                                                            />
                                                                                        </clipPath>
                                                                                    </defs>
                                                                                </svg>
                                                                                <svg
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    width={18}
                                                                                    height={18}
                                                                                    viewBox="0 0 18 18"
                                                                                    fill="none"
                                                                                >
                                                                                    <g clipPath="url(#clip0_1823_999)">
                                                                                        <path
                                                                                            d="M13.5171 16.9931C13.3229 16.9931 13.1297 16.9334 12.9634 16.8147L9.05288 14.0103L5.14239 16.8147C4.98037 16.9313 4.78566 16.9937 4.58607 16.993C4.38649 16.9923 4.19222 16.9285 4.03101 16.8109C3.86965 16.6939 3.7493 16.529 3.68707 16.3396C3.62485 16.1503 3.62392 15.9461 3.68443 15.7562L5.14287 11.0492L1.26747 8.31835C1.10686 8.19982 0.98758 8.03379 0.926505 7.84375C0.86543 7.65371 0.86565 7.44928 0.927134 7.25937C0.989104 7.06992 1.10904 6.90474 1.27 6.78717C1.43096 6.6696 1.6248 6.60559 1.82412 6.60418L6.6239 6.59697L8.15205 2.00677C8.21511 1.81767 8.33607 1.65321 8.49778 1.53667C8.6595 1.42013 8.85378 1.35742 9.05312 1.35742C9.25245 1.35742 9.44673 1.42013 9.60845 1.53667C9.77017 1.65321 9.89112 1.81767 9.95419 2.00677L11.4564 6.59697L16.2807 6.60418C16.4802 6.6053 16.6744 6.66929 16.8355 6.78703C16.9966 6.90478 17.1165 7.0703 17.1782 7.26009C17.2399 7.44988 17.2402 7.65428 17.1791 7.84425C17.118 8.03423 16.9986 8.20012 16.8378 8.31835L12.9624 11.0492L14.4208 15.7562C14.4815 15.9461 14.4807 16.1502 14.4185 16.3396C14.3564 16.5289 14.2361 16.6939 14.0747 16.8109C13.913 16.9295 13.7177 16.9933 13.5171 16.9931Z"
                                                                                            fill="#FFAC33"
                                                                                        />
                                                                                    </g>
                                                                                    <defs>
                                                                                        <clipPath id="clip0_1823_999">
                                                                                            <rect
                                                                                                width="17.3052"
                                                                                                height="17.3052"
                                                                                                fill="white"
                                                                                                transform="translate(0.400391 0.347656)"
                                                                                            />
                                                                                        </clipPath>
                                                                                    </defs>
                                                                                </svg>
                                                                                <svg
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    width={18}
                                                                                    height={18}
                                                                                    viewBox="0 0 18 18"
                                                                                    fill="none"
                                                                                >
                                                                                    <g clipPath="url(#clip0_1823_999)">
                                                                                        <path
                                                                                            d="M13.5171 16.9931C13.3229 16.9931 13.1297 16.9334 12.9634 16.8147L9.05288 14.0103L5.14239 16.8147C4.98037 16.9313 4.78566 16.9937 4.58607 16.993C4.38649 16.9923 4.19222 16.9285 4.03101 16.8109C3.86965 16.6939 3.7493 16.529 3.68707 16.3396C3.62485 16.1503 3.62392 15.9461 3.68443 15.7562L5.14287 11.0492L1.26747 8.31835C1.10686 8.19982 0.98758 8.03379 0.926505 7.84375C0.86543 7.65371 0.86565 7.44928 0.927134 7.25937C0.989104 7.06992 1.10904 6.90474 1.27 6.78717C1.43096 6.6696 1.6248 6.60559 1.82412 6.60418L6.6239 6.59697L8.15205 2.00677C8.21511 1.81767 8.33607 1.65321 8.49778 1.53667C8.6595 1.42013 8.85378 1.35742 9.05312 1.35742C9.25245 1.35742 9.44673 1.42013 9.60845 1.53667C9.77017 1.65321 9.89112 1.81767 9.95419 2.00677L11.4564 6.59697L16.2807 6.60418C16.4802 6.6053 16.6744 6.66929 16.8355 6.78703C16.9966 6.90478 17.1165 7.0703 17.1782 7.26009C17.2399 7.44988 17.2402 7.65428 17.1791 7.84425C17.118 8.03423 16.9986 8.20012 16.8378 8.31835L12.9624 11.0492L14.4208 15.7562C14.4815 15.9461 14.4807 16.1502 14.4185 16.3396C14.3564 16.5289 14.2361 16.6939 14.0747 16.8109C13.913 16.9295 13.7177 16.9933 13.5171 16.9931Z"
                                                                                            fill="#FFAC33"
                                                                                        />
                                                                                    </g>
                                                                                    <defs>
                                                                                        <clipPath id="clip0_1823_999">
                                                                                            <rect
                                                                                                width="17.3052"
                                                                                                height="17.3052"
                                                                                                fill="white"
                                                                                                transform="translate(0.400391 0.347656)"
                                                                                            />
                                                                                        </clipPath>
                                                                                    </defs>
                                                                                </svg>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="checkbox"
                                                                                defaultValue=""
                                                                                id="flexCheckDefault"
                                                                            />
                                                                        </th>
                                                                        <td>
                                                                            <div className="row">
                                                                                <div className="col-12 overflow-hidden">
                                                                                    <div className="d-flex align-items-center gap-2">
                                                                                        <img
                                                                                            src="./admin-images/png/toppers2.png"
                                                                                            alt=""
                                                                                            className="toppers"
                                                                                        />
                                                                                        <div className="student-details">
                                                                                            <p className="fw-400 fs-7 text-bl mb-1">
                                                                                                Devon Lane
                                                                                            </p>
                                                                                            <span>
                                                                                                I neglected to mention that I
                                                                                                definitely won’t be coming to the
                                                                                                party on Saturday
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <button className="status-btn-2">
                                                                                Pending
                                                                            </button>
                                                                        </td>
                                                                        <td>
                                                                            <div className="course-content">
                                                                                <p>Backend Development</p>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="d-flex align-items-center gap-1">
                                                                                <p className="loder-text mb-0">50</p>
                                                                                <div
                                                                                    className="global table-progress-loder"
                                                                                    data-pie='{ "percent": 50 }'
                                                                                    data-pie-index={2}
                                                                                    style={{ width: 200, height: 200 }}
                                                                                >
                                                                                    <svg
                                                                                        role="progressbar"
                                                                                        width={200}
                                                                                        height={200}
                                                                                        viewBox="0 0 100 100"
                                                                                        aria-valuemin={0}
                                                                                        aria-valuemax={100}
                                                                                        aria-valuenow={50}
                                                                                    >
                                                                                        <circle
                                                                                            cx="50%"
                                                                                            cy="50%"
                                                                                            r={42}
                                                                                            shapeRendering="geometricPrecision"
                                                                                            fill="none"
                                                                                            stroke="#f2f7c5"
                                                                                            strokeWidth={10}
                                                                                        />
                                                                                        <circle
                                                                                            cx="50%"
                                                                                            cy="50%"
                                                                                            r={42}
                                                                                            shapeRendering="geometricPrecision"
                                                                                            className="global-circle-2"
                                                                                            fill="none"
                                                                                            strokeWidth={10}
                                                                                            strokeDashoffset={132}
                                                                                            strokeDasharray={264}
                                                                                            strokeLinecap="round"
                                                                                            style={{
                                                                                                transform: "rotate(-90deg)",
                                                                                                transformOrigin: "50% 50%",
                                                                                                transition:
                                                                                                    "stroke-dashoffset 1s ease-out"
                                                                                            }}
                                                                                            stroke="#D2E23D"
                                                                                            data-angel={50}
                                                                                        />
                                                                                    </svg>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <span className="rating-badge rounded-2 p-2">
                                                                                <svg
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    width={18}
                                                                                    height={18}
                                                                                    viewBox="0 0 18 18"
                                                                                    fill="none"
                                                                                >
                                                                                    <g clipPath="url(#clip0_1823_999)">
                                                                                        <path
                                                                                            d="M13.5171 16.9931C13.3229 16.9931 13.1297 16.9334 12.9634 16.8147L9.05288 14.0103L5.14239 16.8147C4.98037 16.9313 4.78566 16.9937 4.58607 16.993C4.38649 16.9923 4.19222 16.9285 4.03101 16.8109C3.86965 16.6939 3.7493 16.529 3.68707 16.3396C3.62485 16.1503 3.62392 15.9461 3.68443 15.7562L5.14287 11.0492L1.26747 8.31835C1.10686 8.19982 0.98758 8.03379 0.926505 7.84375C0.86543 7.65371 0.86565 7.44928 0.927134 7.25937C0.989104 7.06992 1.10904 6.90474 1.27 6.78717C1.43096 6.6696 1.6248 6.60559 1.82412 6.60418L6.6239 6.59697L8.15205 2.00677C8.21511 1.81767 8.33607 1.65321 8.49778 1.53667C8.6595 1.42013 8.85378 1.35742 9.05312 1.35742C9.25245 1.35742 9.44673 1.42013 9.60845 1.53667C9.77017 1.65321 9.89112 1.81767 9.95419 2.00677L11.4564 6.59697L16.2807 6.60418C16.4802 6.6053 16.6744 6.66929 16.8355 6.78703C16.9966 6.90478 17.1165 7.0703 17.1782 7.26009C17.2399 7.44988 17.2402 7.65428 17.1791 7.84425C17.118 8.03423 16.9986 8.20012 16.8378 8.31835L12.9624 11.0492L14.4208 15.7562C14.4815 15.9461 14.4807 16.1502 14.4185 16.3396C14.3564 16.5289 14.2361 16.6939 14.0747 16.8109C13.913 16.9295 13.7177 16.9933 13.5171 16.9931Z"
                                                                                            fill="#FFAC33"
                                                                                        />
                                                                                    </g>
                                                                                    <defs>
                                                                                        <clipPath id="clip0_1823_999">
                                                                                            <rect
                                                                                                width="17.3052"
                                                                                                height="17.3052"
                                                                                                fill="white"
                                                                                                transform="translate(0.400391 0.347656)"
                                                                                            />
                                                                                        </clipPath>
                                                                                    </defs>
                                                                                </svg>
                                                                                <svg
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    width={18}
                                                                                    height={18}
                                                                                    viewBox="0 0 18 18"
                                                                                    fill="none"
                                                                                >
                                                                                    <g clipPath="url(#clip0_1823_999)">
                                                                                        <path
                                                                                            d="M13.5171 16.9931C13.3229 16.9931 13.1297 16.9334 12.9634 16.8147L9.05288 14.0103L5.14239 16.8147C4.98037 16.9313 4.78566 16.9937 4.58607 16.993C4.38649 16.9923 4.19222 16.9285 4.03101 16.8109C3.86965 16.6939 3.7493 16.529 3.68707 16.3396C3.62485 16.1503 3.62392 15.9461 3.68443 15.7562L5.14287 11.0492L1.26747 8.31835C1.10686 8.19982 0.98758 8.03379 0.926505 7.84375C0.86543 7.65371 0.86565 7.44928 0.927134 7.25937C0.989104 7.06992 1.10904 6.90474 1.27 6.78717C1.43096 6.6696 1.6248 6.60559 1.82412 6.60418L6.6239 6.59697L8.15205 2.00677C8.21511 1.81767 8.33607 1.65321 8.49778 1.53667C8.6595 1.42013 8.85378 1.35742 9.05312 1.35742C9.25245 1.35742 9.44673 1.42013 9.60845 1.53667C9.77017 1.65321 9.89112 1.81767 9.95419 2.00677L11.4564 6.59697L16.2807 6.60418C16.4802 6.6053 16.6744 6.66929 16.8355 6.78703C16.9966 6.90478 17.1165 7.0703 17.1782 7.26009C17.2399 7.44988 17.2402 7.65428 17.1791 7.84425C17.118 8.03423 16.9986 8.20012 16.8378 8.31835L12.9624 11.0492L14.4208 15.7562C14.4815 15.9461 14.4807 16.1502 14.4185 16.3396C14.3564 16.5289 14.2361 16.6939 14.0747 16.8109C13.913 16.9295 13.7177 16.9933 13.5171 16.9931Z"
                                                                                            fill="#FFAC33"
                                                                                        />
                                                                                    </g>
                                                                                    <defs>
                                                                                        <clipPath id="clip0_1823_999">
                                                                                            <rect
                                                                                                width="17.3052"
                                                                                                height="17.3052"
                                                                                                fill="white"
                                                                                                transform="translate(0.400391 0.347656)"
                                                                                            />
                                                                                        </clipPath>
                                                                                    </defs>
                                                                                </svg>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="checkbox"
                                                                                defaultValue=""
                                                                                id="flexCheckDefault"
                                                                            />
                                                                        </th>
                                                                        <td>
                                                                            <div className="row">
                                                                                <div className="col-12 overflow-hidden">
                                                                                    <div className="d-flex align-items-center gap-2">
                                                                                        <img
                                                                                            src="./admin-images/png/toppers3.png"
                                                                                            alt=""
                                                                                            className="toppers"
                                                                                        />
                                                                                        <div className="student-details">
                                                                                            <p className="fw-400 fs-7 text-bl mb-1">
                                                                                                Darlene R.
                                                                                            </p>
                                                                                            <span>
                                                                                                I neglected to mention that I
                                                                                                definitely won’t be coming to the
                                                                                                party on Saturday
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <button className="status-btn-1">
                                                                                Enrolled
                                                                            </button>
                                                                        </td>
                                                                        <td>
                                                                            <div className="course-content">
                                                                                <p>Frontend Development</p>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="d-flex align-items-center gap-1">
                                                                                <p className="loder-text mb-0">25</p>
                                                                                <div
                                                                                    className="global table-progress-loder"
                                                                                    data-pie='{ "percent": 25 }'
                                                                                    data-pie-index={3}
                                                                                    style={{ width: 200, height: 200 }}
                                                                                >
                                                                                    <svg
                                                                                        role="progressbar"
                                                                                        width={200}
                                                                                        height={200}
                                                                                        viewBox="0 0 100 100"
                                                                                        aria-valuemin={0}
                                                                                        aria-valuemax={100}
                                                                                        aria-valuenow={25}
                                                                                    >
                                                                                        <circle
                                                                                            cx="50%"
                                                                                            cy="50%"
                                                                                            r={42}
                                                                                            shapeRendering="geometricPrecision"
                                                                                            fill="none"
                                                                                            stroke="#f2f7c5"
                                                                                            strokeWidth={10}
                                                                                        />
                                                                                        <circle
                                                                                            cx="50%"
                                                                                            cy="50%"
                                                                                            r={42}
                                                                                            shapeRendering="geometricPrecision"
                                                                                            className="global-circle-3"
                                                                                            fill="none"
                                                                                            strokeWidth={10}
                                                                                            strokeDashoffset={198}
                                                                                            strokeDasharray={264}
                                                                                            strokeLinecap="round"
                                                                                            style={{
                                                                                                transform: "rotate(-90deg)",
                                                                                                transformOrigin: "50% 50%",
                                                                                                transition:
                                                                                                    "stroke-dashoffset 1s ease-out"
                                                                                            }}
                                                                                            stroke="#D2E23D"
                                                                                            data-angel={25}
                                                                                        />
                                                                                    </svg>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <span className="rating-badge rounded-2 p-2">
                                                                                <svg
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    width={18}
                                                                                    height={18}
                                                                                    viewBox="0 0 18 18"
                                                                                    fill="none"
                                                                                >
                                                                                    <g clipPath="url(#clip0_1823_999)">
                                                                                        <path
                                                                                            d="M13.5171 16.9931C13.3229 16.9931 13.1297 16.9334 12.9634 16.8147L9.05288 14.0103L5.14239 16.8147C4.98037 16.9313 4.78566 16.9937 4.58607 16.993C4.38649 16.9923 4.19222 16.9285 4.03101 16.8109C3.86965 16.6939 3.7493 16.529 3.68707 16.3396C3.62485 16.1503 3.62392 15.9461 3.68443 15.7562L5.14287 11.0492L1.26747 8.31835C1.10686 8.19982 0.98758 8.03379 0.926505 7.84375C0.86543 7.65371 0.86565 7.44928 0.927134 7.25937C0.989104 7.06992 1.10904 6.90474 1.27 6.78717C1.43096 6.6696 1.6248 6.60559 1.82412 6.60418L6.6239 6.59697L8.15205 2.00677C8.21511 1.81767 8.33607 1.65321 8.49778 1.53667C8.6595 1.42013 8.85378 1.35742 9.05312 1.35742C9.25245 1.35742 9.44673 1.42013 9.60845 1.53667C9.77017 1.65321 9.89112 1.81767 9.95419 2.00677L11.4564 6.59697L16.2807 6.60418C16.4802 6.6053 16.6744 6.66929 16.8355 6.78703C16.9966 6.90478 17.1165 7.0703 17.1782 7.26009C17.2399 7.44988 17.2402 7.65428 17.1791 7.84425C17.118 8.03423 16.9986 8.20012 16.8378 8.31835L12.9624 11.0492L14.4208 15.7562C14.4815 15.9461 14.4807 16.1502 14.4185 16.3396C14.3564 16.5289 14.2361 16.6939 14.0747 16.8109C13.913 16.9295 13.7177 16.9933 13.5171 16.9931Z"
                                                                                            fill="#FFAC33"
                                                                                        />
                                                                                    </g>
                                                                                    <defs>
                                                                                        <clipPath id="clip0_1823_999">
                                                                                            <rect
                                                                                                width="17.3052"
                                                                                                height="17.3052"
                                                                                                fill="white"
                                                                                                transform="translate(0.400391 0.347656)"
                                                                                            />
                                                                                        </clipPath>
                                                                                    </defs>
                                                                                </svg>
                                                                                <svg
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    width={18}
                                                                                    height={18}
                                                                                    viewBox="0 0 18 18"
                                                                                    fill="none"
                                                                                >
                                                                                    <g clipPath="url(#clip0_1823_999)">
                                                                                        <path
                                                                                            d="M13.5171 16.9931C13.3229 16.9931 13.1297 16.9334 12.9634 16.8147L9.05288 14.0103L5.14239 16.8147C4.98037 16.9313 4.78566 16.9937 4.58607 16.993C4.38649 16.9923 4.19222 16.9285 4.03101 16.8109C3.86965 16.6939 3.7493 16.529 3.68707 16.3396C3.62485 16.1503 3.62392 15.9461 3.68443 15.7562L5.14287 11.0492L1.26747 8.31835C1.10686 8.19982 0.98758 8.03379 0.926505 7.84375C0.86543 7.65371 0.86565 7.44928 0.927134 7.25937C0.989104 7.06992 1.10904 6.90474 1.27 6.78717C1.43096 6.6696 1.6248 6.60559 1.82412 6.60418L6.6239 6.59697L8.15205 2.00677C8.21511 1.81767 8.33607 1.65321 8.49778 1.53667C8.6595 1.42013 8.85378 1.35742 9.05312 1.35742C9.25245 1.35742 9.44673 1.42013 9.60845 1.53667C9.77017 1.65321 9.89112 1.81767 9.95419 2.00677L11.4564 6.59697L16.2807 6.60418C16.4802 6.6053 16.6744 6.66929 16.8355 6.78703C16.9966 6.90478 17.1165 7.0703 17.1782 7.26009C17.2399 7.44988 17.2402 7.65428 17.1791 7.84425C17.118 8.03423 16.9986 8.20012 16.8378 8.31835L12.9624 11.0492L14.4208 15.7562C14.4815 15.9461 14.4807 16.1502 14.4185 16.3396C14.3564 16.5289 14.2361 16.6939 14.0747 16.8109C13.913 16.9295 13.7177 16.9933 13.5171 16.9931Z"
                                                                                            fill="#FFAC33"
                                                                                        />
                                                                                    </g>
                                                                                    <defs>
                                                                                        <clipPath id="clip0_1823_999">
                                                                                            <rect
                                                                                                width="17.3052"
                                                                                                height="17.3052"
                                                                                                fill="white"
                                                                                                transform="translate(0.400391 0.347656)"
                                                                                            />
                                                                                        </clipPath>
                                                                                    </defs>
                                                                                </svg>
                                                                                <svg
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    width={18}
                                                                                    height={18}
                                                                                    viewBox="0 0 18 18"
                                                                                    fill="none"
                                                                                >
                                                                                    <g clipPath="url(#clip0_1823_999)">
                                                                                        <path
                                                                                            d="M13.5171 16.9931C13.3229 16.9931 13.1297 16.9334 12.9634 16.8147L9.05288 14.0103L5.14239 16.8147C4.98037 16.9313 4.78566 16.9937 4.58607 16.993C4.38649 16.9923 4.19222 16.9285 4.03101 16.8109C3.86965 16.6939 3.7493 16.529 3.68707 16.3396C3.62485 16.1503 3.62392 15.9461 3.68443 15.7562L5.14287 11.0492L1.26747 8.31835C1.10686 8.19982 0.98758 8.03379 0.926505 7.84375C0.86543 7.65371 0.86565 7.44928 0.927134 7.25937C0.989104 7.06992 1.10904 6.90474 1.27 6.78717C1.43096 6.6696 1.6248 6.60559 1.82412 6.60418L6.6239 6.59697L8.15205 2.00677C8.21511 1.81767 8.33607 1.65321 8.49778 1.53667C8.6595 1.42013 8.85378 1.35742 9.05312 1.35742C9.25245 1.35742 9.44673 1.42013 9.60845 1.53667C9.77017 1.65321 9.89112 1.81767 9.95419 2.00677L11.4564 6.59697L16.2807 6.60418C16.4802 6.6053 16.6744 6.66929 16.8355 6.78703C16.9966 6.90478 17.1165 7.0703 17.1782 7.26009C17.2399 7.44988 17.2402 7.65428 17.1791 7.84425C17.118 8.03423 16.9986 8.20012 16.8378 8.31835L12.9624 11.0492L14.4208 15.7562C14.4815 15.9461 14.4807 16.1502 14.4185 16.3396C14.3564 16.5289 14.2361 16.6939 14.0747 16.8109C13.913 16.9295 13.7177 16.9933 13.5171 16.9931Z"
                                                                                            fill="#FFAC33"
                                                                                        />
                                                                                    </g>
                                                                                    <defs>
                                                                                        <clipPath id="clip0_1823_999">
                                                                                            <rect
                                                                                                width="17.3052"
                                                                                                height="17.3052"
                                                                                                fill="white"
                                                                                                transform="translate(0.400391 0.347656)"
                                                                                            />
                                                                                        </clipPath>
                                                                                    </defs>
                                                                                </svg>
                                                                                <svg
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    width={18}
                                                                                    height={18}
                                                                                    viewBox="0 0 18 18"
                                                                                    fill="none"
                                                                                >
                                                                                    <g clipPath="url(#clip0_1823_999)">
                                                                                        <path
                                                                                            d="M13.5171 16.9931C13.3229 16.9931 13.1297 16.9334 12.9634 16.8147L9.05288 14.0103L5.14239 16.8147C4.98037 16.9313 4.78566 16.9937 4.58607 16.993C4.38649 16.9923 4.19222 16.9285 4.03101 16.8109C3.86965 16.6939 3.7493 16.529 3.68707 16.3396C3.62485 16.1503 3.62392 15.9461 3.68443 15.7562L5.14287 11.0492L1.26747 8.31835C1.10686 8.19982 0.98758 8.03379 0.926505 7.84375C0.86543 7.65371 0.86565 7.44928 0.927134 7.25937C0.989104 7.06992 1.10904 6.90474 1.27 6.78717C1.43096 6.6696 1.6248 6.60559 1.82412 6.60418L6.6239 6.59697L8.15205 2.00677C8.21511 1.81767 8.33607 1.65321 8.49778 1.53667C8.6595 1.42013 8.85378 1.35742 9.05312 1.35742C9.25245 1.35742 9.44673 1.42013 9.60845 1.53667C9.77017 1.65321 9.89112 1.81767 9.95419 2.00677L11.4564 6.59697L16.2807 6.60418C16.4802 6.6053 16.6744 6.66929 16.8355 6.78703C16.9966 6.90478 17.1165 7.0703 17.1782 7.26009C17.2399 7.44988 17.2402 7.65428 17.1791 7.84425C17.118 8.03423 16.9986 8.20012 16.8378 8.31835L12.9624 11.0492L14.4208 15.7562C14.4815 15.9461 14.4807 16.1502 14.4185 16.3396C14.3564 16.5289 14.2361 16.6939 14.0747 16.8109C13.913 16.9295 13.7177 16.9933 13.5171 16.9931Z"
                                                                                            fill="#FFAC33"
                                                                                        />
                                                                                    </g>
                                                                                    <defs>
                                                                                        <clipPath id="clip0_1823_999">
                                                                                            <rect
                                                                                                width="17.3052"
                                                                                                height="17.3052"
                                                                                                fill="white"
                                                                                                transform="translate(0.400391 0.347656)"
                                                                                            />
                                                                                        </clipPath>
                                                                                    </defs>
                                                                                </svg>
                                                                                <svg
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    width={18}
                                                                                    height={18}
                                                                                    viewBox="0 0 18 18"
                                                                                    fill="none"
                                                                                >
                                                                                    <g clipPath="url(#clip0_1823_999)">
                                                                                        <path
                                                                                            d="M13.5171 16.9931C13.3229 16.9931 13.1297 16.9334 12.9634 16.8147L9.05288 14.0103L5.14239 16.8147C4.98037 16.9313 4.78566 16.9937 4.58607 16.993C4.38649 16.9923 4.19222 16.9285 4.03101 16.8109C3.86965 16.6939 3.7493 16.529 3.68707 16.3396C3.62485 16.1503 3.62392 15.9461 3.68443 15.7562L5.14287 11.0492L1.26747 8.31835C1.10686 8.19982 0.98758 8.03379 0.926505 7.84375C0.86543 7.65371 0.86565 7.44928 0.927134 7.25937C0.989104 7.06992 1.10904 6.90474 1.27 6.78717C1.43096 6.6696 1.6248 6.60559 1.82412 6.60418L6.6239 6.59697L8.15205 2.00677C8.21511 1.81767 8.33607 1.65321 8.49778 1.53667C8.6595 1.42013 8.85378 1.35742 9.05312 1.35742C9.25245 1.35742 9.44673 1.42013 9.60845 1.53667C9.77017 1.65321 9.89112 1.81767 9.95419 2.00677L11.4564 6.59697L16.2807 6.60418C16.4802 6.6053 16.6744 6.66929 16.8355 6.78703C16.9966 6.90478 17.1165 7.0703 17.1782 7.26009C17.2399 7.44988 17.2402 7.65428 17.1791 7.84425C17.118 8.03423 16.9986 8.20012 16.8378 8.31835L12.9624 11.0492L14.4208 15.7562C14.4815 15.9461 14.4807 16.1502 14.4185 16.3396C14.3564 16.5289 14.2361 16.6939 14.0747 16.8109C13.913 16.9295 13.7177 16.9933 13.5171 16.9931Z"
                                                                                            fill="#FFAC33"
                                                                                        />
                                                                                    </g>
                                                                                    <defs>
                                                                                        <clipPath id="clip0_1823_999">
                                                                                            <rect
                                                                                                width="17.3052"
                                                                                                height="17.3052"
                                                                                                fill="white"
                                                                                                transform="translate(0.400391 0.347656)"
                                                                                            />
                                                                                        </clipPath>
                                                                                    </defs>
                                                                                </svg>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="checkbox"
                                                                                defaultValue=""
                                                                                id="flexCheckDefault"
                                                                            />
                                                                        </th>
                                                                        <td>
                                                                            <div className="row">
                                                                                <div className="col-12 overflow-hidden">
                                                                                    <div className="d-flex align-items-center gap-2">
                                                                                        <img
                                                                                            src="./admin-images/png/toppers3.png"
                                                                                            alt=""
                                                                                            className="toppers"
                                                                                        />
                                                                                        <div className="student-details">
                                                                                            <p className="fw-400 fs-7 text-bl mb-1">
                                                                                                Darlene R.
                                                                                            </p>
                                                                                            <span>
                                                                                                I neglected to mention that I
                                                                                                definitely won’t be coming to the
                                                                                                party on Saturday
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <button className="status-btn-1">
                                                                                Enrolled
                                                                            </button>
                                                                        </td>
                                                                        <td>
                                                                            <div className="course-content">
                                                                                <p>Frontend Development</p>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="d-flex align-items-center gap-1">
                                                                                <p className="loder-text mb-0">25</p>
                                                                                <div
                                                                                    className="global table-progress-loder"
                                                                                    data-pie='{ "percent": 25 }'
                                                                                    data-pie-index={4}
                                                                                    style={{ width: 200, height: 200 }}
                                                                                >
                                                                                    <svg
                                                                                        role="progressbar"
                                                                                        width={200}
                                                                                        height={200}
                                                                                        viewBox="0 0 100 100"
                                                                                        aria-valuemin={0}
                                                                                        aria-valuemax={100}
                                                                                        aria-valuenow={25}
                                                                                    >
                                                                                        <circle
                                                                                            cx="50%"
                                                                                            cy="50%"
                                                                                            r={42}
                                                                                            shapeRendering="geometricPrecision"
                                                                                            fill="none"
                                                                                            stroke="#f2f7c5"
                                                                                            strokeWidth={10}
                                                                                        />
                                                                                        <circle
                                                                                            cx="50%"
                                                                                            cy="50%"
                                                                                            r={42}
                                                                                            shapeRendering="geometricPrecision"
                                                                                            className="global-circle-4"
                                                                                            fill="none"
                                                                                            strokeWidth={10}
                                                                                            strokeDashoffset={198}
                                                                                            strokeDasharray={264}
                                                                                            strokeLinecap="round"
                                                                                            style={{
                                                                                                transform: "rotate(-90deg)",
                                                                                                transformOrigin: "50% 50%",
                                                                                                transition:
                                                                                                    "stroke-dashoffset 1s ease-out"
                                                                                            }}
                                                                                            stroke="#D2E23D"
                                                                                            data-angel={25}
                                                                                        />
                                                                                    </svg>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <span className="rating-badge rounded-2 p-2">
                                                                                <svg
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    width={18}
                                                                                    height={18}
                                                                                    viewBox="0 0 18 18"
                                                                                    fill="none"
                                                                                >
                                                                                    <g clipPath="url(#clip0_1823_999)">
                                                                                        <path
                                                                                            d="M13.5171 16.9931C13.3229 16.9931 13.1297 16.9334 12.9634 16.8147L9.05288 14.0103L5.14239 16.8147C4.98037 16.9313 4.78566 16.9937 4.58607 16.993C4.38649 16.9923 4.19222 16.9285 4.03101 16.8109C3.86965 16.6939 3.7493 16.529 3.68707 16.3396C3.62485 16.1503 3.62392 15.9461 3.68443 15.7562L5.14287 11.0492L1.26747 8.31835C1.10686 8.19982 0.98758 8.03379 0.926505 7.84375C0.86543 7.65371 0.86565 7.44928 0.927134 7.25937C0.989104 7.06992 1.10904 6.90474 1.27 6.78717C1.43096 6.6696 1.6248 6.60559 1.82412 6.60418L6.6239 6.59697L8.15205 2.00677C8.21511 1.81767 8.33607 1.65321 8.49778 1.53667C8.6595 1.42013 8.85378 1.35742 9.05312 1.35742C9.25245 1.35742 9.44673 1.42013 9.60845 1.53667C9.77017 1.65321 9.89112 1.81767 9.95419 2.00677L11.4564 6.59697L16.2807 6.60418C16.4802 6.6053 16.6744 6.66929 16.8355 6.78703C16.9966 6.90478 17.1165 7.0703 17.1782 7.26009C17.2399 7.44988 17.2402 7.65428 17.1791 7.84425C17.118 8.03423 16.9986 8.20012 16.8378 8.31835L12.9624 11.0492L14.4208 15.7562C14.4815 15.9461 14.4807 16.1502 14.4185 16.3396C14.3564 16.5289 14.2361 16.6939 14.0747 16.8109C13.913 16.9295 13.7177 16.9933 13.5171 16.9931Z"
                                                                                            fill="#FFAC33"
                                                                                        />
                                                                                    </g>
                                                                                    <defs>
                                                                                        <clipPath id="clip0_1823_999">
                                                                                            <rect
                                                                                                width="17.3052"
                                                                                                height="17.3052"
                                                                                                fill="white"
                                                                                                transform="translate(0.400391 0.347656)"
                                                                                            />
                                                                                        </clipPath>
                                                                                    </defs>
                                                                                </svg>
                                                                                <svg
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    width={18}
                                                                                    height={18}
                                                                                    viewBox="0 0 18 18"
                                                                                    fill="none"
                                                                                >
                                                                                    <g clipPath="url(#clip0_1823_999)">
                                                                                        <path
                                                                                            d="M13.5171 16.9931C13.3229 16.9931 13.1297 16.9334 12.9634 16.8147L9.05288 14.0103L5.14239 16.8147C4.98037 16.9313 4.78566 16.9937 4.58607 16.993C4.38649 16.9923 4.19222 16.9285 4.03101 16.8109C3.86965 16.6939 3.7493 16.529 3.68707 16.3396C3.62485 16.1503 3.62392 15.9461 3.68443 15.7562L5.14287 11.0492L1.26747 8.31835C1.10686 8.19982 0.98758 8.03379 0.926505 7.84375C0.86543 7.65371 0.86565 7.44928 0.927134 7.25937C0.989104 7.06992 1.10904 6.90474 1.27 6.78717C1.43096 6.6696 1.6248 6.60559 1.82412 6.60418L6.6239 6.59697L8.15205 2.00677C8.21511 1.81767 8.33607 1.65321 8.49778 1.53667C8.6595 1.42013 8.85378 1.35742 9.05312 1.35742C9.25245 1.35742 9.44673 1.42013 9.60845 1.53667C9.77017 1.65321 9.89112 1.81767 9.95419 2.00677L11.4564 6.59697L16.2807 6.60418C16.4802 6.6053 16.6744 6.66929 16.8355 6.78703C16.9966 6.90478 17.1165 7.0703 17.1782 7.26009C17.2399 7.44988 17.2402 7.65428 17.1791 7.84425C17.118 8.03423 16.9986 8.20012 16.8378 8.31835L12.9624 11.0492L14.4208 15.7562C14.4815 15.9461 14.4807 16.1502 14.4185 16.3396C14.3564 16.5289 14.2361 16.6939 14.0747 16.8109C13.913 16.9295 13.7177 16.9933 13.5171 16.9931Z"
                                                                                            fill="#FFAC33"
                                                                                        />
                                                                                    </g>
                                                                                    <defs>
                                                                                        <clipPath id="clip0_1823_999">
                                                                                            <rect
                                                                                                width="17.3052"
                                                                                                height="17.3052"
                                                                                                fill="white"
                                                                                                transform="translate(0.400391 0.347656)"
                                                                                            />
                                                                                        </clipPath>
                                                                                    </defs>
                                                                                </svg>
                                                                                <svg
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    width={18}
                                                                                    height={18}
                                                                                    viewBox="0 0 18 18"
                                                                                    fill="none"
                                                                                >
                                                                                    <g clipPath="url(#clip0_1823_999)">
                                                                                        <path
                                                                                            d="M13.5171 16.9931C13.3229 16.9931 13.1297 16.9334 12.9634 16.8147L9.05288 14.0103L5.14239 16.8147C4.98037 16.9313 4.78566 16.9937 4.58607 16.993C4.38649 16.9923 4.19222 16.9285 4.03101 16.8109C3.86965 16.6939 3.7493 16.529 3.68707 16.3396C3.62485 16.1503 3.62392 15.9461 3.68443 15.7562L5.14287 11.0492L1.26747 8.31835C1.10686 8.19982 0.98758 8.03379 0.926505 7.84375C0.86543 7.65371 0.86565 7.44928 0.927134 7.25937C0.989104 7.06992 1.10904 6.90474 1.27 6.78717C1.43096 6.6696 1.6248 6.60559 1.82412 6.60418L6.6239 6.59697L8.15205 2.00677C8.21511 1.81767 8.33607 1.65321 8.49778 1.53667C8.6595 1.42013 8.85378 1.35742 9.05312 1.35742C9.25245 1.35742 9.44673 1.42013 9.60845 1.53667C9.77017 1.65321 9.89112 1.81767 9.95419 2.00677L11.4564 6.59697L16.2807 6.60418C16.4802 6.6053 16.6744 6.66929 16.8355 6.78703C16.9966 6.90478 17.1165 7.0703 17.1782 7.26009C17.2399 7.44988 17.2402 7.65428 17.1791 7.84425C17.118 8.03423 16.9986 8.20012 16.8378 8.31835L12.9624 11.0492L14.4208 15.7562C14.4815 15.9461 14.4807 16.1502 14.4185 16.3396C14.3564 16.5289 14.2361 16.6939 14.0747 16.8109C13.913 16.9295 13.7177 16.9933 13.5171 16.9931Z"
                                                                                            fill="#FFAC33"
                                                                                        />
                                                                                    </g>
                                                                                    <defs>
                                                                                        <clipPath id="clip0_1823_999">
                                                                                            <rect
                                                                                                width="17.3052"
                                                                                                height="17.3052"
                                                                                                fill="white"
                                                                                                transform="translate(0.400391 0.347656)"
                                                                                            />
                                                                                        </clipPath>
                                                                                    </defs>
                                                                                </svg>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            <Footer />
        </>
    )
}

export default ContentHome