import React from 'react'

function TostMessage( {setShow, msg}) {
    return (
        <>
            <div className="tost-message">
                <div className='tost-message-box'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div className='d-flex align-items-center gap-3'>
                            <img src="./admin/check-circle.png" alt="" />
                            <p className='mb-0'>
                               {msg}
                            </p>
                        </div>
                        <img src="./admin/close-light.png" width={24} height={24} className='h-100' alt="" onClick={()=> setShow(false)}/>
                    </div>

                </div>
            </div>
        </>
    )
}

export default TostMessage
