import React, { useEffect, useState } from 'react';
import { Nav, Tab, Row, Col } from 'react-bootstrap';
import AdminHeader from '../layout/AdminHeader';
import BusinessSidebar from '../components/BusinessSidebar';
import Footer from '../layout/Footer';
import { Link } from 'react-router-dom';
import ComplianceScoreChart from '../components/ComplianceScoreChart';
import DoughnutChart2 from '../components/DoughnutChart2';
import Section1 from '../components/EmployeeProfile/Section1';
import Section2 from '../components/EmployeeProfile/Section2';
import { getServerURL } from '../helper/envConfig';
import api from '../helper/api';
import { decryptFromStore } from '../helper/sessionStore';

function EmployeeProfile() {

    const [activeKey, setActiveKey] = useState('1');
    const serverURL = getServerURL();
    const [dataEployee, setDataEployee] = useState([]);
    const [emploree_id, setEmploree_id] = useState(() => {
        const submitStatus = decryptFromStore('employee_id'); // Assuming 'isSubmit' is the key used to store the submission status
        return submitStatus ? submitStatus : null;
    });

    const handleSelect = (key) => {
        setActiveKey(key);
    };


    const getDataEployee = async () => {

        try {

            const response = await api.postWithToken(`${serverURL}users/business-owner/compliance-employee-score/`, {
                "employee_id": emploree_id
            });

            setDataEployee(response.data.data)
        } catch (error) {
            console.error(error);
        };

    }


    // Effect for fetching quizzes
    useEffect(() => {
        // Decrypt and retrieve isTrue from storage
        const data = decryptFromStore('employee_id');
        if (data !== undefined) {
            setEmploree_id(data);
        }
        getDataEployee();
    }, [activeKey]);
 


    return (
        <>
            <AdminHeader />
            <section>
                <div className="dashbaord-home-main">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-3 col-md-4 col-lg-3 col-xxl-3 d-none d-xl-block px-4 bg-white sidebar-mian">
                                <BusinessSidebar />
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 col-xxl-9 bg-body">
                                <div className="row ps-0 ps-lg-4 pe-lg-4 ps-xl-5 dashbaord-right-side justify-content-md-center justify-content-lg-between pt-5">
                                    <div className="col-12 col-md-11 col-lg-12 right-side">
                                        <div className="d-md-flex justify-content-between align-items-center gap-3 pt-5 pt-md-0 pb-0 pb-md-4">
                                            <h2 className="text-black fw-600">Employee Profile</h2>
                                        </div>
                                        <div className="row g-4 pb-0 pb-md-4">
                                            {/* <div className='col-lg-4'>
                                                <div className="card border-0 rounded-3">
                                                    <div className="card-body p-5">
                                                        <div className='d-flex justify-content-center align-items-center mb-4'>
                                                            <div className='profile-image p-1'>
                                                                <img src="admin-images/png/user-image.png" alt="" />
                                                            </div>
                                                        </div>
                                                        <h5 className='fs-20 fw-600 text-center mb-3'>Dante Lucca</h5>
                                                        <p className='fs-16 fw-400 text-center mb-2'>Sales Department</p>
                                                        <p className='fs-16 fw-400 text-center mb-2'>Financial Analyst</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-12 col-lg-8">
                                                <Row className='g-4'>
                                                    <Col className="col-12 col-md-6 col-lg-6">
                                                        <div className="card border-0 rounded-3 h-100">
                                                            <div className="card-body p-4">
                                                                <h4 className='fs-20 fw-600 text-light-1 text-capitalize'>Total Courses Mandatory</h4>
                                                                <p className='display-6 fw-600 mb-2'>150</p>
                                                                <p className='text-light-2 fw-400'>Courses</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col className="col-12 col-md-6 col-lg-6">
                                                        <div className="card border-0 rounded-3 h-100">
                                                            <div className="card-body p-4">
                                                                <h4 className='fs-20 fw-600 text-light-1 text-capitalize'>Total mandatory courses completed</h4>
                                                                <p className='display-6 fw-600 mb-2'>130</p>
                                                                <p className='text-light-2 fw-400'>Courses</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className='g-4 mt-2'>
                                                    <Col className="col-12 col-md-12 col-lg-12">
                                                        <div className="card border-0 rounded-3 h-100">
                                                            <div className="card-body p-4">
                                                                <p className='text-light-1 fs-20 fw-600'>Total Points</p>
                                                                <h5 className='display-3 fw-600 text-center mt-5 naturenova'>79</h5>
                                                                <p className='text-center fs-20 fw-600 mt-3'>Compliance Score</p>
                                                                <div className='compliance-score-progress d-flex mt-5'>
                                                                    <p className='very-low' style={{ width: '20%' }}></p>
                                                                    <p className='low' style={{ width: '20%' }}></p>
                                                                    <p className='medium' style={{ width: '20%' }}></p>
                                                                    <p className='high' style={{ width: '20%' }}></p>
                                                                    <p className='very-high' style={{ width: '20%' }}></p>
                                                                </div>
                                                                <ul className="px-0 point-list mt-5 d-flex flex-wrap align-items-center gap-4">
                                                                    <li><span className='very-low'></span><b>1-20</b> Very low</li>
                                                                    <li><span className='low'></span><b>21-40</b> Low</li>
                                                                    <li><span className='medium'></span><b>41-60</b> Medium</li>
                                                                    <li><span className='high'></span><b>61-80</b> High</li>
                                                                    <li><span className='very-high'></span><b>81-100</b> Very high</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col className="col-12 col-md-12 col-lg-12">
                                                        <div className="card border-0 rounded-3 h-100">
                                                            <div className="card-body p-4">
                                                                <p className='text-light-1 fs-20 fw-600'>Overall Compliance Score</p>
                                                                <h3 className='fw-600'>75/100</h3>
                                                                <div className='mb-4'>
                                                                    <img src="admin-images/png/arrow-up.png" alt="" />
                                                                    <span className='text-light-4 fw-600 ps-2'>2.1%</span>
                                                                    <span className='text-light-2 fw-400'> vs last month</span>
                                                                </div>
                                                                <ComplianceScoreChart />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div> */}
                                            <Tab.Container activeKey={activeKey} onSelect={handleSelect}>
                                                <div className="overflow-auto">
                                                    <Nav className="nav-pills mb-2 group-view-list gap-4 col-12 col-md-7 col-lg-5 flex-nowrap" id="pills-tab" role="tablist">
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="1" className="fw-600 fs-6 text-black">Employee Compliance Score</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="2" className="fw-600 fs-6 text-black">Employee Report</Nav.Link>
                                                        </Nav.Item>
                                                    </Nav>
                                                </div>
                                                <Tab.Content className='mb-5 pb-0 pb-md-4'>
                                                    <Tab.Pane eventKey="1">
                                                        <Section1  dataEployee={dataEployee}/>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="2">
                                                        <Section2 dataEployee={dataEployee} emploree_id={emploree_id} />
                                                    </Tab.Pane>
                                                </Tab.Content>
                                            </Tab.Container>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section >
            <Footer />
        </>
    )
}

export default EmployeeProfile
