import React from 'react';
import AdminHeader from '../layout/AdminHeader';
import Footer from '../layout/Footer';
import SliderHomeSec from '../components/SliderHomeSec';
import ContentSidbar from '../components/ContentSidbar';
import { Link } from 'react-router-dom';

function ContentPreview() {
    return (
        <>
            <AdminHeader />
            <section>
                <div className="dashbaord-home-main">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-3 col-md-4 col-lg-3 col-xxl-3 d-none d-xl-block px-4 bg-white sidebar-mian">
                                <ContentSidbar />
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 col-xxl-9 bg-body">
                                <div className="row ps-0 ps-lg-4 pe-lg-4 ps-xl-5 dashbaord-right-side justify-content-md-center justify-content-lg-between">
                                    <div className="col-12 col-md-11 col-lg-12 right-side">
                                    <SliderHomeSec />
                                        <div className="row g-4 mb-5 rounded-2">
                                            <div className="col-12">
                                                <h2 className="fw-600 text-black pt-5 pb-4 pb-md-0">
                                                    Preview of the Course
                                                </h2>
                                                <div className="row g-4">
                                                    <div className="col-12">
                                                        <div className="featured-main pb-0">
                                                            <div className="row justify-content-between align-items-center mb-4 mb-md-5 mb-lg-6">
                                                                <div className="col-12">
                                                                    <span className="badge badge-light rounded-2 fw-400 fs-6 mb-4">
                                                                        IT Security
                                                                    </span>
                                                                    <h2 className="text-black fw-400">
                                                                        The Art of Outsmarting: A Guide to Fraud Prevention
                                                                    </h2>
                                                                </div>
                                                            </div>
                                                            <div className="row g-4 g-md-3 g-lg-4 g-xl-5">
                                                                <div className="col-12">
                                                                    <div className="card featured-card overflow-hidden bg-transparent border-0 mb-0 mb-md-4 mb-lg-5">
                                                                        <img
                                                                            src="featured-courses/featured-details-01.jpg"
                                                                            className="featured-details-img"
                                                                            alt=""
                                                                        />
                                                                        <div className="card-body px-0 pt-4 pt-md-5">
                                                                            <h3 className="text-black fw-500 mb-4">
                                                                                Two Eiffel Towers
                                                                            </h3>
                                                                            <p className="text-muted fw-400 fs-6">
                                                                                Welcome to the captivating world of Data Analytics and
                                                                                Visualization! In this course, you'll embark on a
                                                                                thrilling journey through the realm of data, learning
                                                                                how to harness its power to uncover valuable insights
                                                                                and tell compelling stories. Whether you're a curious
                                                                                beginner or a seasoned data enthusiast, our course is
                                                                                designed to cater to all levels of learners.
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex justify-content-end mb-0 mb-md-0">
                                                                    <div className="col-6 col-md-3 col-lg-2">
                                                                        <Link
                                                                            to="/conten-questions"
                                                                            className="btn-explore d-flex justify-content-between align-items-center gap-3 rounded-2 docs-creator"
                                                                        >
                                                                            Save
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width={22}
                                                                                height={14}
                                                                                viewBox="0 0 22 14"
                                                                                fill="none"
                                                                            >
                                                                                <path
                                                                                    d="M1 6.99854H21L16 1"
                                                                                    stroke="white"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                ></path>
                                                                                <path
                                                                                    d="M21 6.99854L16 13"
                                                                                    stroke="white"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                ></path>
                                                                            </svg>
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section >
            <Footer />
        </>
    )
}

export default ContentPreview