import React from 'react'
import { Link } from 'react-router-dom';
import DragAndDrop from './../DragAndDrop';
import { getImageBaseUrl } from '../../helper/envConfig';

function UploadFile({ currentFiles, setCurrentFiles, setIsFileLoading, isFileLoading, fileProgress, setFileProgress }) {

    const filePath = getImageBaseUrl() +  "/media/employee_excel/unicorso-sample-employees-file.xlsx";

    return (
        <>
            <div className="row justify-content-center">
                <div className="col-lg-9 col-xl-7">
                    <h4 className='fw-600 text-bl text-center mb-3'>Upload Employee Information</h4>
                    <p className='text-center text-muted fw-400 mb-0'>Download a template, add your data, then upload it here for processing.</p>
                    <div className="card border-0">
                        <div className="card-body px-0 px-md-4">
                            {/* Directly use <a> tag for the download link */}
                            <a href={filePath} download className='d-flex justify-content-center align-items-center gap-2 text-primary'>
                                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.09 9H15.5V4C15.5 3.45 15.05 3 14.5 3H10.5C9.95 3 9.5 3.45 9.5 4V9H7.91C7.02 9 6.57 10.08 7.2 10.71L11.79 15.3C12.18 15.69 12.81 15.69 13.2 15.3L17.79 10.71C18.42 10.08 17.98 9 17.09 9ZM5.5 19C5.5 19.55 5.95 20 6.5 20H18.5C19.05 20 19.5 19.55 19.5 19C19.5 18.45 19.05 18 18.5 18H6.5C5.95 18 5.5 18.45 5.5 19Z" fill="#002FA7"/>
                                </svg>
                                <p className='mb-0 fs-6 fw-400'>Download the sample file</p>
                            </a>
                            <div className='mt-4'>
                                <DragAndDrop fileProgress={fileProgress} setFileProgress={setFileProgress} currentFiles={currentFiles} setCurrentFiles={setCurrentFiles} isFileLoading={isFileLoading} setIsFileLoading={setIsFileLoading} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UploadFile