

import { Navigate, useLocation } from 'react-router-dom';
import { decryptFromStoreLoc } from './localStore';

function PrivateRoute({ children, role, ...rest }) {
    let location = useLocation();
    const user = decryptFromStoreLoc('user');

    // Check if the user is logged in
    let isLoggedIn = !!localStorage.getItem('token');

    // Determine the effective role of the user
    let effectiveRole = user?.main_role !== undefined ? user?.main_role : user?.role;


    // Check if the user has any role assigned
    let hasRole = !!effectiveRole;

    // Check if the user has the required role if a specific role is required
    let hasRequiredRole = role ? effectiveRole === role : hasRole;

    // Redirect to sign-in if not logged in or doesn't have the required role (or any role if no specific role is required)
    return isLoggedIn && hasRequiredRole ? children : <Navigate to="/sign-in" state={{ from: location }} replace />;
}

export default PrivateRoute;

