import React, { useRef, useState, useEffect, useContext } from 'react'
import Header from '../layout/Header'
import Footer from '../layout/Footer'
import CourseSidebar from '../components/CourseSidebar'
import { Link } from 'react-router-dom'
import api from "../helper/api";
import { getServerURL } from '../helper/envConfig';
import { useParams } from 'react-router-dom';
import axios from 'axios'
import { keys } from '@mui/system'
import { CourseContext } from '../context/CourseContext'
import { encryptAndStore, decryptFromStore } from '../helper/sessionStore'; // Replace './encryptionUtils' with the correct path to your file
import Loader from '../components/Loader'
import { SkeletonLoader } from '../components/SkeletonLoader'
import { BreadcrumbLayout, BreadcrumbSkeleton, ButtonSkeleton, CardLayout, ContentLayout, ContentSkeleton, SidebarSkeleton, TitleBadgeLayout, TitleBadgeSkeleton } from '../components/SkeletonLayouts'
import { useNavigate } from 'react-router-dom';
import { Is_Login } from '../helper/IsLogin'

function CourseDetails({ is_bookmarked, course_id }) {

    const { setQuizeData, setQuize, setSearch, setIs_search, loder, setLoder, manageBookMark, setIsWishlist, isWishlist } = useContext(CourseContext);
    const navigate = useNavigate()
    const [listCourse, setListCourse] = useState({ results: [], page_size: 0 });
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(9);
    const [chapterNo, setChapterNo] = useState(0)
    const [chapterDetails, setChapterDetails] = useState({})
    const [totalChapters, setTotalChapters] = useState(0)
    const [showReference, setShowReference] = useState(false)
    const serverURL = getServerURL();
    // const [loder, setLoder] = useState(false);
    const { id } = useParams(); // This retrieves the ID parameter from the URL
    const isLoggedIn = Is_Login();

    const [isBookmarked, setIsBookmarked] = useState(false)

    const getCourses = async () => {
        try {
            setLoder(true)
            const apiTyp = isLoggedIn ? api.postWithToken : api.post;
            const response = await apiTyp(`${serverURL}courses/course-details/`, { "course_id": id });
            setListCourse(response.data);
            setIsBookmarked(response.data.is_bookmarked)
            setChapterDetails(response.data.chapters[0])
            setTotalChapters(response.data.chapters.length)
            setLoder(false)
            window.scrollTo(0, 0);
        } catch (error) {
            console.log(error);
            setLoder(false)
            window.scrollTo(0, 0);
        }
    };

    useEffect(() => {
        getCourses();
    }, [isLoggedIn]);


    const setQuiz = (ids) => {
        sessionStorage.removeItem('endTime');
        sessionStorage.removeItem('remainingTime');
        sessionStorage.removeItem('quiz');
        sessionStorage.removeItem('QuestionNo');
        sessionStorage.removeItem('answers');
        sessionStorage.removeItem('answers_index');
        sessionStorage.removeItem('answers_count');
        sessionStorage.removeItem('FinalAnswers');
        sessionStorage.removeItem('chatGptResult');
        sessionStorage.removeItem('isSubmit');
        sessionStorage.removeItem('isTrue');
        sessionStorage.removeItem('true_id');
        sessionStorage.removeItem('remainingPathColor');
        sessionStorage.removeItem('feedback');


        // Encrypt and store sensitive information
        encryptAndStore('quiz', ids);
    }

    // Assuming listCourse?.course_details?.category_name might have spaces
    const categoryName = listCourse?.course_details?.category_name.replace(/ /g, '-');

    const handlePress = (data) => {
        setSearch(data)
        getCourses(); // Call the function you want to execute on Enter key press
        setIs_search(true)
        navigate("/courses-explore")
        window.scrollTo(0, 500);
    };

    useEffect(() => {
        if (listCourse?.chapters?.length > 0) {
            if (chapterNo <= totalChapters - 1) {
                setChapterDetails(listCourse.chapters[chapterNo])
            } else {
                setShowReference(true)
            }
            window.scrollTo(0, 0);
        }
    }, [chapterNo])

    const bookMark = () => {
        if (!isLoggedIn) {
            navigate("/sign-in");
        } else {
            manageBookMark(id);
        }
    };

    useEffect(() => {
        setIsWishlist(isBookmarked);
    }, [isBookmarked]);


    return (
        <>
            <Header />

            {loder ? (
                <div className="container">
                    <BreadcrumbSkeleton />
                    <TitleBadgeSkeleton />
                    <div className="row">
                        <div className="col-lg-8">
                            <ContentSkeleton />
                            <ContentSkeleton />
                            <ContentSkeleton />
                            <ContentSkeleton />
                            <ContentSkeleton />
                            <ButtonSkeleton />
                        </div>
                        <div className="col-lg-4">
                            <SidebarSkeleton />
                            <SidebarSkeleton />

                        </div>
                    </div>
                </div>
            ) : (

                <section>
                    <div className="featured-main">
                        <div className="container">
                            <div className="breadcrumb-main">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-3 mb-md-5">
                                        <li className="breadcrumb-item">
                                            <Link to="/">Home</Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <Link to={`/courses-category/${categoryName}`} >{listCourse?.course_details?.category_name}</Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <Link to="">{listCourse?.course_details && listCourse?.course_details.type_name} Course</Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            {listCourse?.course_details?.name}
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                            {/* <div className="row justify-content-between align-items-start mb-4 mb-md-5 mb-lg-6">
                                <div className="col-12 col-md-12 col-lg-6">
                                    <div className="d-flex align-items-center gap-3 flex-wrap mb-3">
                                        <span onClick={ () => handlePress(listCourse?.course_details?.tags_list[0].name)} className="badge badge-black-2 text-white rounded-2 fw-400 fs-7 md-0 mb-md-4">
                                            {listCourse?.course_details?.tags_list?.length && listCourse?.course_details?.tags_list[0].name}
                                        </span>
                                        <span className={
                                            listCourse?.course_details?.type_name === "Bronze" ? "badge badge-bronze rounded-2 fw-400 fs-7 md-0 mb-md-4" :
                                                listCourse?.course_details?.type_name === "Silver" ? "badge badge-silver rounded-2 fw-400 fs-7 md-0 mb-md-4" :
                                                    listCourse?.course_details?.type_name === "Gold" ? "badge badge-gold rounded-2 fw-400 fs-7 md-0 mb-md-4" :
                                                        listCourse?.course_details?.type_name === "Free" ? "badge badge-light text-black rounded-2 fw-400 fs-7 md-0 mb-md-4" :
                                                            ""
                                        }>
                                            {listCourse?.course_details?.type_name}
                                        </span>
                                    </div>
                                    <h2 className="text-black fw-400">
                                        {listCourse?.course_details?.name}
                                    </h2>
                                </div>
                                <div className="col-12 col-md-12 col-lg-6 mt-4 mt-md-0">
                                    <div className="row justify-content-end">
                                        <div className="col-lg-12 col-xl-8">
                                            <div className="row justify-content-between align-items-center g-0 mb-2">
                                                <p className="col-6 col-md-7 fw-500 text-black mb-0 fs-7 fs-sm-7">
                                                    Advanced Achievers
                                                </p>
                                                <div className="col-6 col-md-5 d-flex align-items-center">
                                                    <ul className="rectangle rectangle2 d-flex align-items-center gap-2 gap-md-3 flex-wrap mb-0 p-0">
                                                        <li className="active" />
                                                        <li className="active" />
                                                        <li className="active" />
                                                        <li className="active" />
                                                    </ul>
                                                </div>
                                                <div class="d-flex align-items-center gap-3 flex-wrap mt-3">
                                                    {listCourse?.course_details?.tags_list?.length && listCourse?.course_details?.tags_list?.map((tagList, index) => (
                                                        <span onClick={() => handlePress(tagList.name)} key={index} class="badge badge-black-2 text-white rounded-2 fw-400 fs-7">{tagList.name}</span>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className="row g-4 g-md-3 g-lg-4">
                                <div className="col-md-12 col-lg-8">
                                    <div className="d-flex justify-content-between align-items-start gap-3 flex-wrap">
                                        <div className='d-flex align-items-center gap-3 flex-wrap'>
                                            <span onClick={() => handlePress(listCourse?.course_details?.tags_list[0].name)} className="badge badge-black-2 text-white rounded-2 fw-400 fs-7 md-0 mb-md-4">
                                                {listCourse?.course_details?.tags_list?.length && listCourse?.course_details?.tags_list[0].name}
                                            </span>
                                            <span className={
                                                listCourse?.course_details?.type_name === "Bronze" ? "badge badge-bronze rounded-2 fw-400 fs-7 md-0 mb-md-4" :
                                                    listCourse?.course_details?.type_name === "Silver" ? "badge badge-silver rounded-2 fw-400 fs-7 md-0 mb-md-4" :
                                                        listCourse?.course_details?.type_name === "Gold" ? "badge badge-gold rounded-2 fw-400 fs-7 md-0 mb-md-4" :
                                                            listCourse?.course_details?.type_name === "Free" ? "badge badge-light text-black rounded-2 fw-400 fs-7 md-0 mb-md-4" :
                                                                ""
                                            }>
                                                {listCourse?.course_details?.type_name}
                                            </span>
                                        </div>

                                        <button
                                            type="button"
                                            onClick={bookMark}
                                            className={`${isWishlist ? "btn-explore" : "btn-primary"} d-flex align-items-center gap-3 rounded-2 md-0 mb-md-4 bookmark-btn`}
                                        >
                                            {isWishlist ? "Bookmarked" : "Bookmark"}
                                            <img
                                                src={isWishlist ? "../featured-courses/bookmark_added4.png" : "../featured-courses/bookmark_added.png"}
                                                className="wh-25"
                                                alt=""
                                            />
                                        </button>

                                    </div>
                                    <h2 className="text-black fw-400 mb-4 mb-md-5 mb-lg-6">
                                        {listCourse?.course_details?.name}
                                    </h2>
                                    <div>

                                        {chapterNo === 0 && listCourse.introductions && listCourse.introductions.map((data, index) => (
                                            <>
                                                <p className='text-muted fw-400 mt-3 mb-5 fs-6'> {data.description} </p>
                                            </>
                                        ))}

                                        {/* {listCourse?.chapters && listCourse?.chapters.map((data, index) => (
                                            <>
                                                <h4 key={index} className="text-black fw-500">{data.name}</h4>
                                                {data?.chapter_description && data?.chapter_description.map((description, descIndex) => (
                                                    <>
                                                        {description?.title && <p key={descIndex}>{descIndex + 1}. {description?.title}</p>}
                                                        <p className='text-muted fw-400 mt-3 mb-5 fs-6'>
                                                            {!description?.title ? `${descIndex + 1}. ${description.description}` : description.description}
                                                        </p>
                                                    </>
                                                ))}
                                            </>
                                        ))} */}

                                        {chapterDetails && !showReference &&
                                            <>
                                                <h4 className="text-black fw-500">{chapterDetails.name}</h4>
                                                {chapterDetails?.chapter_description && chapterDetails?.chapter_description.map((description, descIndex) => (
                                                    <>
                                                        {description?.title && <p key={descIndex}>{descIndex + 1}. {description?.title}</p>}
                                                        <p className='text-muted fw-400 mt-3 mb-5 fs-6'>
                                                            {!description?.title ? `${descIndex + 1}. ${description.description}` : description.description}
                                                        </p>
                                                    </>
                                                ))}
                                            </>
                                        }

                                        {showReference &&
                                            <>
                                                <b className="text-muted pt-5 d-block fs-6">References:</b>
                                                <ul className="references-list mt-4 ps-3">

                                                    {listCourse?.references && listCourse?.references.map((data, index) => (

                                                        <>
                                                            <li key={index}>
                                                                <q className="text-muted">
                                                                    {data.description}
                                                                </q>
                                                            </li>
                                                        </>

                                                    ))}

                                                </ul>

                                                {listCourse.notes && listCourse.notes.map((data, index) => (
                                                    <>
                                                        <p className="text-muted mt-5 fs-7">
                                                            {data.note}
                                                        </p>
                                                    </>
                                                ))
                                                }
                                            </>
                                        }

                                    </div>
                                    <div className="row justify-content-between align-items-center mb-2 my-md-4 my-lg-5 g-2">
                                        <div className="col-6 col-md-4 col-lg-4 col-xl-3">
                                            {/* <Link
                                            // to="/sri-lanka-course"
                                            to="/"
                                            type="button"
                                            className="btn-explore p-sm-15 bg-secondary text-black d-flex justify-content-between align-items-center gap-3 rounded-2 w-100 mt-4"
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width={22}
                                                height={14}
                                                viewBox="0 0 22 14"
                                                fill="none"
                                            >
                                                <path
                                                    d="M21 6.99854H1L6 1"
                                                    stroke="#000C2A"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M1 6.99805L6 12.9995"
                                                    stroke="#000C2A"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                />
                                            </svg>
                                            Previous
                                        </Link> */}
                                        </div>
                                        {showReference ?
                                            <div className="col-6 col-md-4 col-lg-4 col-xl-3"

                                            >
                                                <Link
                                                    to={`/quiz`}
                                                    type="button"
                                                    className="btn-explore p-sm-15 d-flex justify-content-between align-items-center gap-3 rounded-2 w-100 mt-4"
                                                    onClick={() => setQuiz({ quiz_id: listCourse?.quiz_id, question_id: listCourse?.first_question_id, name: listCourse?.course_details?.name })}
                                                >
                                                    Start quiz
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={22}
                                                        height={14}
                                                        viewBox="0 0 22 14"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M1 6.99854H21L16 1"
                                                            stroke="white"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path
                                                            d="M21 6.99805L16 12.9995"
                                                            stroke="white"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                        />
                                                    </svg>
                                                </Link>
                                            </div> :
                                            <div className="col-6 col-md-4 col-lg-4 col-xl-3">
                                                <button
                                                    type="button"
                                                    className="btn-explore p-sm-15 d-flex justify-content-between align-items-center gap-3 rounded-2 w-100 mt-4"
                                                    onClick={() => setChapterNo(chapterNo + 1)}
                                                >
                                                    Next
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={22}
                                                        height={14}
                                                        viewBox="0 0 22 14"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M1 6.99854H21L16 1"
                                                            stroke="white"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path
                                                            d="M21 6.99805L16 12.9995"
                                                            stroke="white"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                        />
                                                    </svg>
                                                </button>
                                            </div>}
                                    </div>
                                </div>
                                {/* <div className="col-md-12 col-lg-4">
                                    <div className="row justify-content-between align-items-center g-0 mb-4 mb-md-5 mb-lg-6">
                                        <p className="col-6 col-md-7 fw-500 text-black mb-0 fs-7 fs-sm-7">
                                            Advanced Achievers
                                        </p>
                                        <div className="col-6 col-md-5 d-flex justify-content-end align-items-start">
                                            <ul className="rectangle rectangle2 d-flex align-items-center gap-2 gap-md-3 flex-wrap mb-0 p-0">
                                                <li className="active" />
                                                <li className="active" />
                                                <li className="active" />
                                                <li className="active" />
                                            </ul>
                                        </div>
                                        <div class="d-flex align-items-center gap-3 flex-wrap mt-3">
                                            {listCourse?.course_details?.tags_list?.length && listCourse?.course_details?.tags_list?.map((tagList, index) => (
                                                <span onClick={() => handlePress(tagList.name)} key={index} class="badge badge-black-2 text-white rounded-2 fw-400 fs-7">{tagList.name}</span>
                                            ))}
                                        </div>
                                    </div>
                                    <CourseSidebar course_id={listCourse?.course_details?.course_id} is_bookmarked={listCourse?.is_bookmarked} name={listCourse?.course_details?.name} type={listCourse?.course_details && listCourse?.course_details.type_name}
                                    />
                                </div> */}
                            </div>
                        </div>
                    </div>
                </section>
            )}
            <Footer />
        </>
    )
}

export default CourseDetails