import { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link, useLocation, useNavigate } from "react-router-dom";

function TopMenuList() {
    const location = useLocation();
    const navigate = useNavigate();

    const menuitems = [
        {
            id: 1,
            src: "./admin/dashboard.svg",
            label: "Dashboard",
            to: "/admin-dashboard",
        },
        {
            id: 2,
            src: "./admin/user.svg",
            label: "Users",
            to: "/admin-user-management",
        },
        {
            id: 3,
            src: "./admin/courses.svg",
            label: "Courses",
            to: "/admin-course-management",
        },
        {
            id: 4,
            src: "./admin/analytics.svg",
            label: "Analytics",
            to: "/admin-analytics",
        },
        {
            id: 5,
            src: "./admin/marketing.svg",
            label: "Marketing",
            to: "/admin-marketing",
        },
        // { id: 6, src: './admin/analytics.svg', label: 'Analytics' },
        { id: 7, src: "./admin/sales.svg", label: "Sales", to: "/admin-sales" },
        {
            id: 8,
            src: "./admin/compliance.svg",
            label: "Compliance",
            to: "/admin-compliance",
        },
        {
            id: 9,
            src: "./admin/family.svg",
            label: "Family",
            to: "/admin-family",
        },
        {
            id: 10,
            src: "./admin/settings.svg",
            label: "Settings",
            to: "/admin-setting",
        },
        // Add more items as needed
    ];

    const sidemenuitems = [
        {
            id: 1,
            src: "./admin/feedback.png",
            label: "Feedback",
            to: "/admin-feedback",
        },
        {
            id: 2,
            src: "./admin/grades.png",
            label: "Grades",
            to: "/admin-grades",
        },
        {
            id: 3,
            src: "./admin/performance.png",
            label: "Performance Scale",
            to: "/admin-performance-scale",
        },
        {
            id: 4,
            src: "./admin/empty.png",
            label: "System Status Updates",
            to: "/admin-system",
        },
        { id: 5, src: "./admin/support.png", label: "Support", to: "/admin-dashboard" },
        {
            id: 6,
            src: "./admin/documentation.png",
            label: "documentation",
            to: "/admin-documentation",
        },
        // Add more items as needed
    ];

    // const [activeItemId, setActiveItemId] = useState(null);
    const [activeItemId, setActiveItemId] = useState(location.pathname);

    const toggleActive = (id) => {
        setActiveItemId(activeItemId === id ? null : id);
    };

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleItemClick = (to) => {
        navigate(to);
    };

    return (
        <>
            <div className="d-flex align-items-center gap-4 top-menu-list">
                {menuitems.map((item) => (
                    <Link
                        to={item.to}
                        key={item.id}
                        className={`d-flex align-items-center gap-2 menuitems ${activeItemId === item.to ||
                            (activeItemId === "/user-profile-management" &&
                                item.to === "/admin-user-management") ||
                            (activeItemId === "/admin-course-creation-wizard" &&
                                item.to === "/admin-course-management")
                            ? "active"
                            : ""
                            }`}
                        onClick={() => handleItemClick(item.to)}
                    >
                        <img src={item.src} alt={item.label} />
                        <p>{item.label}</p>
                    </Link>
                ))}
            </div>

            <button
                className="bg-transparent toggle-top d-none border-0"
                onClick={handleShow}
            >
                <img src="./admin/toggle.png" alt="" />
            </button>

            <Offcanvas show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton className="border-bottom">
                    <Offcanvas.Title>
                        <img src="/admin/logo.png" alt="" />
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="admin-sidebar">
                    {menuitems.map((item) => (
                        <Link
                            to={item.to}
                            key={item.id}
                            className={`d-flex align-items-center gap-2 menuitems ${activeItemId === item.to ? "active" : ""
                                }`}
                            onClick={() => handleItemClick(item.to)}
                        >
                            <img src={item.src} alt={item.label} />
                            <p>{item.label}</p>
                        </Link>
                    ))}
                    <div className="sidebar_line"></div>
                    <div className="admin-left-menu">
                        {sidemenuitems.map((item) => (
                            <Link
                                to={item.to}
                                key={item.id}
                                className={`d-flex align-items-center gap-2 menuitems ${activeItemId === item.to ? "active" : ""
                                    }`}
                                onClick={() => handleItemClick(item.to)}
                            >
                                <img src={item.src} alt={item.label} />
                                <p>{item.label}</p>
                            </Link>
                        ))}
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}

export default TopMenuList;
