import React, { useRef, useState, useEffect, useContext } from 'react'
import AdminHeader from './../layout/AdminHeader';
import Footer from '../layout/Footer';
import CustomerSidbar from '../components/CustomerSidbar';
import { CourseContext } from '../context/CourseContext';
import { getImageBaseUrl } from '../helper/envConfig';
import TopPerformers from '../components/TopPerformers';

function CustomerLeaderboard() {


    return (
        <>
            <AdminHeader />
            <section>
                <div className="dashbaord-home-main">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-3 col-md-4 col-lg-3 col-xxl-3 d-none d-xl-block px-4 bg-white sidebar-mian">
                                <CustomerSidbar />
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 col-xxl-9 bg-body">
                                <div className="row ps-0 ps-lg-5 dashbaord-right-side justify-content-md-center justify-content-lg-between pt-5">
                                    <div className="col-12 col-md-11 col-lg-12 right-side">
                                        <h2 className="fw-600 text-black mb-5">Your Top Performers</h2>
                                        <div className="row mb-5 g-4">
                                           <TopPerformers />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            <Footer />
        </>
    )
}

export default CustomerLeaderboard