import React from 'react';
import AdminHeader from '../layout/AdminHeader';
import BusinessSidebar from '../components/BusinessSidebar';
import Footer from '../layout/Footer';
import AreaChart from './../components/AreaChart';
import BarChart2 from '../components/BarChart2';
import BarChart1 from '../components/BarChart1';

function BusinessAnalytics() {
    return (
        <>
            <AdminHeader />
            <section>
                <div className="dashbaord-home-main">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-3 col-md-4 col-lg-3 col-xxl-3 d-none d-xl-block px-4 bg-white sidebar-mian">
                                <BusinessSidebar />
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 col-xxl-9 bg-body">
                                <div className="row ps-0 ps-lg-4 pe-lg-4 ps-xl-5 dashbaord-right-side justify-content-md-center justify-content-lg-between">
                                    <div className="col-12 col-md-11 col-lg-12 right-side">
                                        <div className="d-flex align-items-center gap-4 mt-5 mb-4 g-4 flex-wrap">
                                            <select className="form-select p-3 py-2 bg-transparent border-2 border-secondary w-auto pe-5">
                                                <option selected="">November</option>
                                                <option value={1}>One</option>
                                                <option value={2}>Two</option>
                                                <option value={3}>Three</option>
                                            </select>
                                            <select className="form-select p-3 py-2 bg-transparent border-2 border-secondary w-auto pe-5">
                                                <option selected="">Technology and Innovation</option>
                                                <option value={1}>One</option>
                                                <option value={2}>Two</option>
                                                <option value={3}>Three</option>
                                            </select>
                                        </div>
                                        <div className="d-flex align-items-center gap-3 flex-wrap mb-5">
                                            <span className="badge bg-black rounded-5 fw-400 fs-7 py-2 px-3">
                                                November 2023
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={16}
                                                    height={14}
                                                    viewBox="0 0 16 14"
                                                    fill="none"
                                                >
                                                    <mask
                                                        id="mask0_628_4566"
                                                        style={{ maskType: "alpha" }}
                                                        maskUnits="userSpaceOnUse"
                                                        x={0}
                                                        y={0}
                                                        width={16}
                                                        height={14}
                                                    >
                                                        <rect
                                                            width="15.4375"
                                                            height={13}
                                                            transform="matrix(-1 0 0 1 15.4375 0.5)"
                                                            fill="#D9D9D9"
                                                        />
                                                    </mask>
                                                    <g mask="url(#mask0_628_4566)">
                                                        <path
                                                            d="M10.4727 10.7913C10.6532 10.7913 10.8225 10.7507 10.9805 10.6695C11.1385 10.5882 11.2671 10.4754 11.3664 10.3309L13.7227 6.99967L11.3664 3.66842C11.2671 3.52398 11.1385 3.41113 10.9805 3.32988C10.8225 3.24863 10.6532 3.20801 10.4727 3.20801H2.70877C2.41085 3.20801 2.15582 3.31408 1.94366 3.52624C1.73151 3.73839 1.62543 3.99342 1.62543 4.29134V9.70801C1.62543 10.0059 1.73151 10.261 1.94366 10.4731C2.15582 10.6853 2.41085 10.7913 2.70877 10.7913H10.4727ZM8.26988 9.16634L6.86155 7.75801L5.45321 9.16634L4.69488 8.40801L6.10321 6.99967L4.69488 5.59134L5.45321 4.83301L6.86155 6.24134L8.26988 4.83301L9.02821 5.59134L7.61988 6.99967L9.02821 8.40801L8.26988 9.16634Z"
                                                            fill="white"
                                                        />
                                                    </g>
                                                </svg>
                                            </span>
                                            <span className="badge bg-black rounded-5 fw-400 fs-7 py-2 px-3">
                                                Technology and Innovation
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={16}
                                                    height={14}
                                                    viewBox="0 0 16 14"
                                                    fill="none"
                                                >
                                                    <mask
                                                        id="mask0_628_4566"
                                                        style={{ maskType: "alpha" }}
                                                        maskUnits="userSpaceOnUse"
                                                        x={0}
                                                        y={0}
                                                        width={16}
                                                        height={14}
                                                    >
                                                        <rect
                                                            width="15.4375"
                                                            height={13}
                                                            transform="matrix(-1 0 0 1 15.4375 0.5)"
                                                            fill="#D9D9D9"
                                                        />
                                                    </mask>
                                                    <g mask="url(#mask0_628_4566)">
                                                        <path
                                                            d="M10.4727 10.7913C10.6532 10.7913 10.8225 10.7507 10.9805 10.6695C11.1385 10.5882 11.2671 10.4754 11.3664 10.3309L13.7227 6.99967L11.3664 3.66842C11.2671 3.52398 11.1385 3.41113 10.9805 3.32988C10.8225 3.24863 10.6532 3.20801 10.4727 3.20801H2.70877C2.41085 3.20801 2.15582 3.31408 1.94366 3.52624C1.73151 3.73839 1.62543 3.99342 1.62543 4.29134V9.70801C1.62543 10.0059 1.73151 10.261 1.94366 10.4731C2.15582 10.6853 2.41085 10.7913 2.70877 10.7913H10.4727ZM8.26988 9.16634L6.86155 7.75801L5.45321 9.16634L4.69488 8.40801L6.10321 6.99967L4.69488 5.59134L5.45321 4.83301L6.86155 6.24134L8.26988 4.83301L9.02821 5.59134L7.61988 6.99967L9.02821 8.40801L8.26988 9.16634Z"
                                                            fill="white"
                                                        />
                                                    </g>
                                                </svg>
                                            </span>
                                        </div>
                                        <div className="row g-4 pb-5">
                                            <div className="col-12 col-lg-6">
                                                <div className="card border-0">
                                                    <div className="card-header bg-transparent border-0 p-4 pb-0">
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <p className="text-black fw-600 fs-18 mb-0">
                                                                Monthly Compliance Grade
                                                            </p>
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={24}
                                                                height={24}
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                            >
                                                                <mask
                                                                    id="mask0_361_16694"
                                                                    style={{ maskType: "alpha" }}
                                                                    maskUnits="userSpaceOnUse"
                                                                    x={0}
                                                                    y={0}
                                                                    width={24}
                                                                    height={24}
                                                                >
                                                                    <rect width={24} height={24} fill="#002FA7" />
                                                                </mask>
                                                                <g mask="url(#mask0_361_16694)">
                                                                    <path
                                                                        d="M12 16L7 11L8.4 9.55L11 12.15V4H13V12.15L15.6 9.55L17 11L12 16ZM6 20C5.45 20 4.97917 19.8042 4.5875 19.4125C4.19583 19.0208 4 18.55 4 18V15H6V18H18V15H20V18C20 18.55 19.8042 19.0208 19.4125 19.4125C19.0208 19.8042 18.55 20 18 20H6Z"
                                                                        fill="#000C2A"
                                                                    />
                                                                </g>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    <div className="card-body p-4">
                                                        <div className="overflow-auto">
                                                            <div className="barchart">
                                                                <BarChart1 />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card bg-transparent border-0 pt-2">
                                                    <div className="card-body px-0 pb-0">
                                                        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-2 g-4 pt-md-0">
                                                            <div className="col">
                                                                <div className="card border-0 h-100">
                                                                    <div className="card-body p-4 pb-0">
                                                                        <div className="icons-crile mb-4">
                                                                            <img
                                                                                src="admin-images/png/desktop.png"
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                        <p className="fw-12 fw-400 text-muted mb-4">
                                                                            Mandatory Courses for all employees
                                                                        </p>
                                                                    </div>
                                                                    <div className="card-footer bg-transparent border-0 pt-0 px-4">
                                                                        <h2 className="fw-400 text-black">20</h2>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="card border-0 h-100">
                                                                    <div className="card-body p-4 pb-0">
                                                                        <div className="icons-crile mb-4">
                                                                            <img
                                                                                src="admin-images/png/clock2.png"
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                        <p className="fw-12 fw-400 text-muted mb-4">
                                                                            Overdue mandatory courses for all employees
                                                                        </p>
                                                                    </div>
                                                                    <div className="card-footer bg-transparent border-0 pt-0 px-4">
                                                                        <h2 className="fw-400 text-black">3</h2>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="card border-0 h-100">
                                                                    <div className="card-body p-4 pb-0">
                                                                        <div className="icons-crile mb-4">
                                                                            <img
                                                                                src="admin-images/png/teach.png"
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                        <p className="fw-12 fw-400 text-muted mb-4">
                                                                            Learning Groups
                                                                        </p>
                                                                    </div>
                                                                    <div className="card-footer bg-transparent border-0 pt-0 px-4">
                                                                        <h2 className="fw-400 text-black">20</h2>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="card border-0 h-100">
                                                                    <div className="card-body p-4 pb-0">
                                                                        <div className="icons-crile mb-4">
                                                                            <img
                                                                                src="admin-images/png/boys-group.png"
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                        <p className="fw-12 fw-400 text-muted mb-4">Enrolled</p>
                                                                    </div>
                                                                    <div className="card-footer bg-transparent border-0 pt-0 px-4">
                                                                        <h2 className="fw-400 text-black">200</h2>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-6">
                                                <div className="card border-0">
                                                    <div className="card-header bg-transparent border-0 p-4 pb-0">
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <p className="text-black fw-600 fs-18 mb-0">
                                                                Monthly GDPR Compliance Grade
                                                            </p>
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={24}
                                                                height={24}
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                            >
                                                                <mask
                                                                    id="mask0_361_16694"
                                                                    style={{ maskType: "alpha" }}
                                                                    maskUnits="userSpaceOnUse"
                                                                    x={0}
                                                                    y={0}
                                                                    width={24}
                                                                    height={24}
                                                                >
                                                                    <rect width={24} height={24} fill="#002FA7" />
                                                                </mask>
                                                                <g mask="url(#mask0_361_16694)">
                                                                    <path
                                                                        d="M12 16L7 11L8.4 9.55L11 12.15V4H13V12.15L15.6 9.55L17 11L12 16ZM6 20C5.45 20 4.97917 19.8042 4.5875 19.4125C4.19583 19.0208 4 18.55 4 18V15H6V18H18V15H20V18C20 18.55 19.8042 19.0208 19.4125 19.4125C19.0208 19.8042 18.55 20 18 20H6Z"
                                                                        fill="#000C2A"
                                                                    />
                                                                </g>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    <div className="card-body p-4">
                                                        <div className="overflow-auto">
                                                            <div className="barchart">
                                                                <BarChart2 />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card border-0 mt-4">
                                                    <div className="card-body p-4">
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <p className="text-black fw-600 fs-18 mb-4">Monthly Sign Ups</p>
                                                        </div>
                                                        <div className="overflow-auto">
                                                            <div className="areachart">
                                                                <AreaChart />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            <Footer />
        </>
    );
}

export default BusinessAnalytics;
