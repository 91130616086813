import React from 'react'
import Header from '../layout/Header'
import Footer from '../layout/Footer'
import CourseSidebar from '../components/CourseSidebar'
import { Link } from 'react-router-dom'

function FeaturedGoldCourseQuize2() {
  return (
    <>
      <Header />
      <section>
        <div className="featured-main">
          <div className="container">
            <div className="breadcrumb-main">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-3 mb-md-5">
                  <li className="breadcrumb-item">
                    <Link to="">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="">Technology</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="">Mini Course</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Fraud Prevention
                  </li>
                </ol>
              </nav>
            </div>
            <div className="row justify-content-between align-items-center mb-4 mb-md-5 mb-lg-6">
              <div className="col-12 col-md-12 col-lg-6">
                <div className="d-flex align-items-center gap-3">
                  <span className="badge badge-black text-white rounded-2 fw-400 fs-7 mb-4">
                    IT Security
                  </span>
                  <span class="badge badge-light text-black rounded-2 fw-400 fs-7 mb-4">Free</span>
                </div>
                <h2 className="text-black fw-400">
                  Learning to Work with Figma
                </h2>
              </div>
              <div className="col-12 col-md-12 col-lg-6 mt-4">
                <div className="row justify-content-end">
                  <div className="col-lg-12 col-xl-8">
                    <div className="row justify-content-between align-items-center g-0 mb-2">
                      <p className="col-6 col-md-7 fw-500 text-black mb-0 fs-7 fs-sm-7">
                        Advanced Achievers
                      </p>
                      <div className="col-6 col-md-5 d-flex align-items-center">
                        <ul className="rectangle rectangle2 d-flex align-items-center gap-2 gap-md-3 flex-wrap mb-0 p-0">
                          <li className="active" />
                          <li className="active" />
                          <li className="active" />
                          <li className="active" />
                        </ul>
                      </div>
                    </div>
                    <div className="row justify-content-between align-items-center g-0 mb-2">
                      <p className="col-6 col-md-7 fw-400 text-black mb-0 fs-7 fs-sm-7">
                        Pro Membership
                      </p>
                      <div className="col-6 col-md-5 d-flex align-items-center gap-2">
                        <img
                          src="./featured-courses/diamond.jpg"
                          className="wh-20"
                          alt=""
                        />
                        <p className="mb-0 text-black fw-500 fs-7 fs-sm-7">
                          Premium Pass
                        </p>
                      </div>
                    </div>
                    <div className="row justify-content-end align-items-center g-0 mb-2">
                      <p className="col-6 col-md-7 fw-400 text-black mb-0 fs-7 fs-sm-7">
                        Duration
                      </p>
                      <div className="col-6 col-md-5 d-flex align-items-center gap-2">
                        <img
                          src="./featured-courses/hourglass.png"
                          className="wh-20"
                          alt=""
                        />
                        <p className="mb-0 text-black fw-500 fs-7 fs-sm-7">5 Hours</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-4 g-md-3 g-lg-4 g-xl-5">
              <div className="col-md-12 col-lg-8">
                <div className="d-md-flex justify-content-between align-items-center mb-4">
                  <div className="d-flex align-items-center gap-3 mb-3 mb-md-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <mask
                        id="mask0_283_1291"
                        style={{ maskType: "alpha" }}
                        maskUnits="userSpaceOnUse"
                        x={0}
                        y={0}
                        width={24}
                        height={24}
                      >
                        <rect width={24} height={24} fill="#D9D9D9" />
                      </mask>
                      <g mask="url(#mask0_283_1291)">
                        <path
                          d="M5 21V4H14L14.4 6H20V16H13L12.6 14H7V21H5ZM14.65 14H18V8H12.75L12.35 6H7V12H14.25L14.65 14Z"
                          fill="#1C1B1F"
                        />
                      </g>
                    </svg>
                    <p className="text-black fw-400 fs-7 mb-0">Question 02</p>
                  </div>
                  <div className="d-flex align-items-center gap-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <mask
                        id="mask0_283_1279"
                        style={{ maskType: "alpha" }}
                        maskUnits="userSpaceOnUse"
                        x={0}
                        y={0}
                        width={24}
                        height={24}
                      >
                        <rect width={24} height={24} fill="#D9D9D9" />
                      </mask>
                      <g mask="url(#mask0_283_1279)">
                        <path
                          d="M12.0001 21.9996C10.7501 21.9996 9.57927 21.7621 8.4876 21.2871C7.39593 20.8121 6.44593 20.1704 5.6376 19.3621C4.82926 18.5538 4.1876 17.6038 3.7126 16.5121C3.2376 15.4204 3.0001 14.2496 3.0001 12.9996C3.0001 11.7496 3.2376 10.5788 3.7126 9.48711C4.1876 8.39544 4.82926 7.44544 5.6376 6.63711C6.44593 5.82878 7.39593 5.18711 8.4876 4.71211C9.57927 4.23711 10.7501 3.99961 12.0001 3.99961C13.2501 3.99961 14.4209 4.23711 15.5126 4.71211C16.6043 5.18711 17.5543 5.82878 18.3626 6.63711C19.1709 7.44544 19.8126 8.39544 20.2876 9.48711C20.7626 10.5788 21.0001 11.7496 21.0001 12.9996C21.0001 14.2496 20.7626 15.4204 20.2876 16.5121C19.8126 17.6038 19.1709 18.5538 18.3626 19.3621C17.5543 20.1704 16.6043 20.8121 15.5126 21.2871C14.4209 21.7621 13.2501 21.9996 12.0001 21.9996ZM14.8001 17.1996L16.2001 15.7996L13.0001 12.5996V7.99961H11.0001V13.3996L14.8001 17.1996ZM5.6001 2.34961L7.0001 3.74961L2.7501 7.99961L1.3501 6.59961L5.6001 2.34961ZM18.4001 2.34961L22.6501 6.59961L21.2501 7.99961L17.0001 3.74961L18.4001 2.34961ZM12.0001 19.9996C13.9501 19.9996 15.6043 19.3204 16.9626 17.9621C18.3209 16.6038 19.0001 14.9496 19.0001 12.9996C19.0001 11.0496 18.3209 9.39544 16.9626 8.03711C15.6043 6.67878 13.9501 5.99961 12.0001 5.99961C10.0501 5.99961 8.39593 6.67878 7.0376 8.03711C5.67926 9.39544 5.0001 11.0496 5.0001 12.9996C5.0001 14.9496 5.67926 16.6038 7.0376 17.9621C8.39593 19.3204 10.0501 19.9996 12.0001 19.9996Z"
                          fill="#1C1B1F"
                        />
                      </g>
                    </svg>
                    <p className="text-black fw-400 fs-7 mb-0">Time Elapsed</p>
                    <p className="text-primary fw-400 fs-18 mb-0">00 : 05</p>
                  </div>
                </div>
                <div className="card featured-card overflow-hidden border-0 mb-0 mb-md-4 mb-lg-5">
                  <div className="card-body px-4 pt-5">
                    <h3 className="text-black fw-400 fs-18 mb-4">
                      How can team members collaborate in Figma?
                    </h3>
                    <ul className="answer-list">
                      <li className="fw-400 fs-18">Email attachments</li>
                      <li className="fw-400 fs-18">Download and upload</li>
                      <li className="fw-400 fs-18">Real-time collaboration</li>
                      <li className="fw-400 fs-18">Only through comments</li>
                    </ul>
                  </div>
                </div>
                <div className="row justify-content-between align-items-center mb-2 my-md-4 my-lg-5 g-2">
                  <div className="col-6 col-md-4 col-lg-4 col-xl-3">
                    <Link
                      to="/gold-course-quize-1"
                      type="button"
                      className="btn-explore p-sm-15 bg-secondary text-black d-flex justify-content-between align-items-center gap-3 rounded-2 w-100 mt-4"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={22}
                        height={14}
                        viewBox="0 0 22 14"
                        fill="none"
                      >
                        <path
                          d="M21 6.99854H1L6 1"
                          stroke="#000C2A"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 6.99805L6 12.9995"
                          stroke="#000C2A"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                      </svg>
                      Previous
                    </Link>
                  </div>
                  <div className="col-6 col-md-4 col-lg-4 col-xl-3">
                    <Link
                      to="/quize-results"
                      type="button"
                      className="btn-explore p-sm-15 d-flex justify-content-between align-items-center gap-3 rounded-2 w-100 mt-4"
                    >
                      Next
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={22}
                        height={14}
                        viewBox="0 0 22 14"
                        fill="none"
                      >
                        <path
                          d="M1 6.99854H21L16 1"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M21 6.99805L16 12.9995"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                      </svg>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-4">
                <CourseSidebar />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default FeaturedGoldCourseQuize2