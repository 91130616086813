import React, { useEffect, useState } from "react";
import api from "../helper/api";
import { getServerURL } from "../helper/envConfig";
import { useLocation, useNavigate } from "react-router-dom";
import { decryptToken, encryptAndStoreLoc } from "../helper/localStore";
import { toast } from "react-toastify";

const StripeRedirect = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const serverURL = getServerURL();
    const [paymentInfo, setPaymentInfo] = useState({});

    const verifyPayment = async (paymentId, status) => {
        try {
            var response = await api.postWithToken(
                `${serverURL}payments/verify-payment/`,
                { payment_id: paymentId, status: status }
            );

            if (response.data.success) {
                localStorage.removeItem("state");
                const paymentData = { status: true }
                encryptAndStoreLoc("paymentData", paymentData)

                setTimeout(() => {
                    navigate("/processed", {
                        state: { role: response.data?.data?.role_id },
                    });
                    toast.success(response.data.message)
                    window.location.reload()
                }, 2000);
            }

            if (status === "false") {
                setTimeout(() => {
                    navigate("/business-user-payment");
                }, 2000);
            }
        } catch (error) {
            if (error?.response?.data?.data?.role_id === 12) {
                setTimeout(() => {
                    navigate("/business-user-payment");
                    toast.error(error.response.data.message);
                }, 2000);
            }
            if (error?.response?.data?.data?.role_id === 2) {
                setTimeout(() => {
                    navigate("/individual-payment-details");
                    toast.error(error.response.data.message);
                }, 2000);
            }
            if (error?.response?.data?.data?.role_id === 13) {
                setTimeout(() => {
                    navigate("/family-user-payment-details");
                    toast.error(error.response.data.message);
                }, 2000);
            }
        }
    };

    useEffect(() => {
        // Step 1: Extract the token from the URL
        const params = new URLSearchParams(location.search);
        const token = params.get("token");

        if (token) {
            // Step 2: Decode the token (if needed)
            const decodedToken = decodeURIComponent(token);
            const tokens = decodedToken.replace(/ /g, "+");
            const obj = decryptToken(tokens);
            const newObj = JSON.parse(obj.replaceAll("'", '"'));
            setPaymentInfo(newObj);
            verifyPayment(newObj.payment_id, newObj.status);
        }
    }, [location]);

    return (
        <div>
            <div className="container">
                {paymentInfo?.status === "true" && (
                    <div className="row justify-content-center align-items-center vh-100">
                        <div className="col-md-5">
                            <div className="message-box _success">
                                <svg
                                    viewBox="0 0 24 24"
                                    class="text-green-600 w-16 h-16 mx-auto my-6"
                                    width="55px"
                                    height="55px"
                                >
                                    <path
                                        fill="#28a745"
                                        d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"
                                    ></path>
                                </svg>
                                <h2> Your payment was successful </h2>
                                <p> Thank you for your payment.</p>
                            </div>
                        </div>
                    </div>
                )}
                {paymentInfo?.status === "false" && (
                    <div className="row justify-content-center align-items-center vh-100">
                        <div className="col-md-5">
                            <div className="message-box _success _failed">
                                <svg
                                    width="70px"
                                    class="mx-auto mb-5"
                                    height="70px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g
                                        id="SVGRepo_bgCarrier"
                                        stroke-width="0"
                                    ></g>
                                    <g
                                        id="SVGRepo_tracerCarrier"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    ></g>
                                    <g id="SVGRepo_iconCarrier">
                                        {" "}
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM8.96963 8.96965C9.26252 8.67676 9.73739 8.67676 10.0303 8.96965L12 10.9393L13.9696 8.96967C14.2625 8.67678 14.7374 8.67678 15.0303 8.96967C15.3232 9.26256 15.3232 9.73744 15.0303 10.0303L13.0606 12L15.0303 13.9696C15.3232 14.2625 15.3232 14.7374 15.0303 15.0303C14.7374 15.3232 14.2625 15.3232 13.9696 15.0303L12 13.0607L10.0303 15.0303C9.73742 15.3232 9.26254 15.3232 8.96965 15.0303C8.67676 14.7374 8.67676 14.2625 8.96965 13.9697L10.9393 12L8.96963 10.0303C8.67673 9.73742 8.67673 9.26254 8.96963 8.96965Z"
                                            fill="#dc3545"
                                        ></path>{" "}
                                    </g>
                                </svg>

                                <h2> Your payment failed </h2>
                                <p> Try again later </p>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default StripeRedirect;
