import React, { useState, useEffect } from 'react';
import 'animate.css/animate.min.css';
import 'react-circular-progressbar/dist/styles.css';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import api from '../../../../helper/api';
import AdminUploadFile from './AdminUploadFile';
import { getServerURL } from '../../../../helper/envConfig';

const AdminUpload = ({ currentFiles, setCurrentFiles }) => {

    const navigate = useNavigate();
    const [currentStep, setCurrentStep] = useState(1);
    const [isFileLoading, setIsFileLoading] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [fileProgress, setFileProgress] = useState({});
    const [record, setRecord] = useState([]);
    const serverURL = getServerURL();
    const [progressWidth, setProgressWidth] = useState('0%');
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isfileLode, setIsfileLode] = useState(false);
    const [progress, setProgress] = useState(0);
    const [stepClasses, setStepClasses] = useState({
        1: 'd-block',
        2: 'd-none',
        3: 'd-none'
    });

    const [lastActiveStep, setLastActiveStep] = useState(1);

    useEffect(() => {
        updateStepClasses();
    }, [currentStep]);

    useEffect(() => {
        const progressPercentage = ((currentStep - 1) / 2) * 100;
        setProgressWidth(`${progressPercentage}%`);
    }, [currentStep]);

    const updateStepClasses = () => {
        setStepClasses({
            1: currentStep === 1 ? 'd-block' : 'd-none animate__animated animate__fadeInRight',
            2: currentStep === 2 ? 'd-block animate__animated animate__fadeInRight' : 'd-none',
            3: currentStep === 3 ? 'd-block animate__animated animate__fadeInRight' : 'd-none'
        });
        setLastActiveStep(currentStep);
    };



    const nextStep = () => {
        setCurrentPage(1)
        if (currentStep < 3) {

            if (currentStep + 1 == 2) {
                handleSubmit()
            } else if (currentStep + 1 == 3) {
                postData()
            }
            else {
                setCurrentStep(currentStep + 1);
            }
        }

    };

    const assignCourse = () => {
        navigate('/assign-course');
    };

    const prevStep = () => {
        if (currentStep > 1) {
            setCurrentStep(currentStep - 1);
            setCurrentPage(1)
        }
    };

    const [percentage, setPercentage] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            if (percentage < 100) {
                setPercentage(percentage + 1);
            }
        }, 50);
    }, [percentage]);


    const handleSubmit = async () => {

        setIsUploading(true)

        // Create an instance of FormData
        const formData = new FormData();
        for (let file of currentFiles) {
            formData.append('files', file); // 'files' as per your API specification
        }

        try {
            // Replace `serverURL` with your actual server URL and endpoint
            const response = await api.postWithToken(`${serverURL}users/business-owner/upload-excel/`, formData);
            toast.success(response?.data?.message || 'Files uploaded successfully');
            getData()
            setIsUploading(false)
            setCurrentStep(currentStep + 1)

        } catch (error) {
            setIsUploading(false)
            console.error(error);
            toast.error(error.response?.data.message || 'An error occurred during file upload.');
        }
    };

    const getData = async () => {

        setIsUploading(true)

        try {
            // Replace `serverURL` with your actual server URL and endpoint
            const response = await api.getWithToken(`${serverURL}users/business-owner/upload-excel/?page=${currentPage}&page_size=${rowsPerPage}`);
            setIsUploading(false)
            setRecord(response.data)

        } catch (error) {
            setIsUploading(false)
            console.error(error);
        }
    };

    const postData = async () => {
        setCurrentStep(currentStep + 1);
        setIsfileLode(true)
        try {
            // Replace `serverURL` with your actual server URL and endpoint
            const response = await api.postWithToken(`${serverURL}users/business-owner/import-excel-data/`);
            setIsfileLode(false)
            // setCurrentStep(4);
        } catch (error) {
            setIsfileLode(false)
            console.error(error);
        }
    };

    const deleterecord = async (email) => {
        // setIsUploading(true)
        try {
            const response = await api.postWithToken(`${serverURL}users/business-owner/delete-excel-employee/`, { email: email });
            // setIsUploading(false)
            toast.success(response?.data?.message);
            if (record.results?.employees.length == 1 && currentPage !== 1) {
                setCurrentPage(currentPage - 1)
            }

            if (currentPage == 1 && record.results?.employees.length == 1) {
                setCurrentStep(1)
            }

            getData()
        } catch (error) {
            // setIsUploading(false)
            toast.error(error.response?.data.message);
            console.error(error);
        }
    };

    useEffect(() => {

        if (currentStep === 2) {
            getData()
        }

    }, [currentPage, rowsPerPage]);


    return (
        <>
            <form id="multi-step-form" className='d-flex justify-content-start'>
                <div className={`step step-1 ${stepClasses[1]}`}>
                    <AdminUploadFile fileProgress={fileProgress} setFileProgress={setFileProgress} currentFiles={currentFiles} setCurrentFiles={setCurrentFiles} isFileLoading={isFileLoading} setIsFileLoading={setIsFileLoading} />
                </div>
            </form>
        </>
    )
}

export default AdminUpload
