import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { Link } from 'react-router-dom';


const Faq = () => {
    return (
        <>
            <section>
                <div className="faq-main position-relative">
                    <div className="container">
                        <div className="section-title col-md-12 col-lg-12 m-auto">
                            <span className="d-block text-start">Frequently Asked Questions</span>
                            <h2 className="text-start text-black display-6">Got Questions? We’ve Got Answers!</h2>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <Accordion className="accordion" defaultActiveKey="0">
                                    <Accordion.Item className='mb-4 border-0 rounded-3 px-3' eventKey="0">
                                        <Accordion.Header className="accordion-header">How do I sign up for an account?</Accordion.Header>
                                        <Accordion.Body>
                                        <p className="text-muted fw-400">
                                            Signing up is easy! Just click on the 'Sign Up' button, enter your email
                                            and a secure password, and you'll be all set. You can also sign up using
                                            your Google or Facebook account for added convenience.
                                        </p>
                                        </Accordion.Body>
                                    </Accordion.Item>

                                    <Accordion.Item className='mb-4 border-0 rounded-3 px-3' eventKey="2">
                                            <Accordion.Header>Are there any free courses available?</Accordion.Header>
                                            <Accordion.Body>
                                                <p className="text-muted fw-400">
                                                    Yes. We keep half of our courses freely available on the internet without signup. 
                                                </p>
                                            </Accordion.Body>
                                    </Accordion.Item>

                                     <Accordion.Item className='mb-4 border-0 rounded-3 px-3' eventKey="3">
                                         <Accordion.Header>Can I access my courses on multiple devices?</Accordion.Header>
                                         <Accordion.Body>
                                             <p className="text-muted fw-400">
                                                 Currently, we operate on a mobile friendly website. We do not support iOS or Android
                                                 apps for now. Comes later.
                                             </p>
                                         </Accordion.Body>
                                     </Accordion.Item>

                                     <Accordion.Item className='mb-4 border-0 rounded-3 px-3' eventKey="4">
                                         <Accordion.Header>Can I download course materials for offline access?</Accordion.Header>
                                         <Accordion.Body>
                                             <p className="text-muted fw-400">
                                                 If you want you can copy the text.
                                             </p>
                                         </Accordion.Body>
                                     </Accordion.Item>

                                     <Accordion.Item className='mb-4 border-0 rounded-3 px-3' eventKey="5">
                                         <Accordion.Header>How long do I have access to a course after enrollment?</Accordion.Header>
                                         <Accordion.Body>
                                             <p className="text-muted fw-400">
                                                 Whenever you want.
                                             </p>
                                         </Accordion.Body>
                                     </Accordion.Item>

                                     <Accordion.Item className='mb-4 border-0 rounded-3 px-3' eventKey="6">
                                         <Accordion.Header>Can I get a refund if I'm not satisfied with a course?</Accordion.Header>
                                         <Accordion.Body>
                                             <p className="text-muted fw-400">
                                                 Yes. Write as an email at I-am-not-happy@unicorso.com
                                             </p>
                                         </Accordion.Body>
                                     </Accordion.Item>

                                     <Accordion.Item className='mb-4 border-0 rounded-3 px-3' eventKey="7">
                                         <Accordion.Header>Are the courses taught by real instructors?</Accordion.Header>
                                         <Accordion.Body>
                                             <p className="text-muted fw-400">
                                                 If you want to get a real instructor, sign up for silver or gold courses. 
                                             </p>
                                         </Accordion.Body>
                                     </Accordion.Item>
                                </Accordion>
                                <div className="mt-5">
                                    <h4 className="text-black mb-4 fw-400 text-center text-md-start">Is your question not listed
                                        here?
                                        Don't worry, we're here to
                                        help!</h4>
                                    <p className="text-muted fw-400 text-center text-md-start">
                                        We're here to assist you! You can reach our friendly customer support team through
                                        the 'Contact Us' page or by sending an email to <Link to="" className="text-primary fw-400">support@example.com.</Link> We aim to
                                        respond to all inquiries promptly.
                                    </p>
                                    <div className="d-md-flex mt-4">
                                        <Link to="/faq-questions" className="btn-primary fw-500 rounded-2 sm-w-100 bg-primary d-block position-relative">
                                            Go To Our FAQs Page
                                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                                                <mask id="mask0_250_9685" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x={0} y={0} width={24} height={24}>
                                                    <rect width={24} height={24} fill="#D9D9D9" />
                                                </mask>
                                                <g mask="url(#mask0_250_9685)">
                                                    <path d="M6.4 18L5 16.6L14.6 7H6V5H18V17H16V8.4L6.4 18Z" fill="white" />
                                                </g>
                                            </svg>
                                        </Link>
                                        <Link to="/community-help" className="btn-secondary text-primary fw-500 rounded-2 sm-w-100 mt-3 mt-md-0 bg-secondary d-block position-relative mx-0 mx-md-3">
                                            Visit Our Help Center
                                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                                                <mask id="mask0_250_9690" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x={0} y={0} width={24} height={24}>
                                                    <rect width={24} height={24} fill="#D9D9D9" />
                                                </mask>
                                                <g mask="url(#mask0_250_9690)">
                                                    <path d="M6.4 18L5 16.6L14.6 7H6V5H18V17H16V8.4L6.4 18Z" fill="#002FA7" />
                                                </g>
                                            </svg>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Faq;
