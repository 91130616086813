

// check for token error 

import axios from "axios";


const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 403) {
      localStorage.removeItem('token');
      window.location.href = "/sign-in";

    }
    return Promise.reject(error);
  }
);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  get: async (URL) => axiosInstance.get(URL),
  delete: async (URL) => axiosInstance.delete(URL),
  post: async (URL, DATA) => axiosInstance.post(URL, DATA),

  postWithCustomToken: async (URL, token, data) =>
    axiosInstance.post(URL,data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),

  getWithToken: async (URL) =>
    axiosInstance.get(URL, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
    
  deleteWithToken: async (URL) =>
    axiosInstance.delete(URL, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  postWithToken: async (URL, DATA) =>
    axiosInstance.post(URL, DATA, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  putWithToken: async (URL, DATA) =>
    axiosInstance.put(URL, DATA, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),   
    patchWithToken: async (URL, DATA) => 
      axiosInstance.patch(URL, DATA, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`, // Include the token in headers
        },
      }),

  };
