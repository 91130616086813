import React, { useRef, useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom';
import { CourseContext } from '../../context/CourseContext';
import CourseNotFound from '../CourseNotFound';

function SelectCourse({ selectedCourses, setSelectedCourses }) {

    const { loder, setIs_search, getSearchCourses, setSearch, searchex, setSearchex, listCourse, currentPage, setCurrentPage, pageSize, setPageSize, getCourses } = useContext(CourseContext);


    // Toggle selection of a course
    const toggleCourseSelection = (courseId) => {
        const isSelected = selectedCourses.includes(courseId);
        if (isSelected) {
            setSelectedCourses(selectedCourses.filter(id => id !== courseId));
        } else {
            setSelectedCourses([...selectedCourses, courseId]);
        }
    };


    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearchex(value);
        setSearch("")

    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevents the default action of the enter key in a form
            getCourses(); // Call the function you want to execute on Enter key press
            setIs_search(true)
            window.scrollTo(0, 500);
        }
    };



    return (
        <>
            <div className="admin-header-form">
                <div className="row justify-content-center mt-5">
                    <div className="col-6">
                        <form className="form-control sm-none d-flex justify-content-center align-items-center border-0 bg-secondary w-100 rounded-3 overflow-hidden py-0">
                            <Link to="" className="search-icon">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={22}
                                    height={22}
                                    viewBox="0 0 22 22"
                                    fill="none"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M16.4004 10.1416C16.4004 13.4602 13.7102 16.1504 10.3916 16.1504C7.07304 16.1504 4.38281 13.4602 4.38281 10.1416C4.38281 6.82304 7.07304 4.13281 10.3916 4.13281C13.7102 4.13281 16.4004 6.82304 16.4004 10.1416ZM16.4278 14.9638C17.485 13.6422 18.1172 11.9657 18.1172 10.1416C18.1172 5.87488 14.6583 2.41602 10.3916 2.41602C6.12488 2.41602 2.66602 5.87488 2.66602 10.1416C2.66602 14.4083 6.12488 17.8672 10.3916 17.8672C12.2157 17.8672 13.8922 17.235 15.2138 16.1778L17.5102 18.4742C17.8454 18.8094 18.3889 18.8094 18.7242 18.4742C19.0594 18.1389 19.0594 17.5954 18.7242 17.2602L16.4278 14.9638Z"
                                        fill="#002FA7"
                                    />
                                </svg>
                            </Link>
                            <p className="mb-0 ps-2 text-primary"></p>
                            <input
                                className="form-control border-0 rounded-2 bg-secondary px-0"
                                type="text"
                                placeholder="Course name"
                                value={searchex}
                                onChange={handleSearchChange}
                                onKeyPress={handleKeyPress}
                            />
                        </form>
                    </div>
                </div>
                {
                    listCourse?.results?.length > 0 ?
                        <div className="row justify-content-center mt-5 g-4 select-course-row">
                            {/* Map loop to generate course cards */}
                            {listCourse?.results?.map(course => (
                                <div className={`col-12 col-md-9 ${selectedCourses.includes(course.course_id) ? 'active' : ''}`} key={course.course_id}>
                                    <div className="form-check select-course px-0">
                                        <label className="form-check-label card border-0 cursor-pointer" htmlFor={`flexCheck${course.course_id}`}>
                                            <div className="card-body">
                                                <div className='d-flex flex-column flex-md-row align-items-start gap-3'>
                                                    <div>
                                                        <input
                                                            className="form-check-input m-0"
                                                            type="checkbox"
                                                            value={course.course_id}
                                                            id={`flexCheck${course.course_id}`}
                                                            checked={selectedCourses.includes(course.course_id)}
                                                            onChange={() => toggleCourseSelection(course.course_id)}
                                                        />
                                                    </div>

                                                    <div className='d-flex flex-column flex-md-row justify-content-between align-items-start w-100 gap-3'>
                                                        <div>
                                                            <h2>{course.name}</h2>
                                                        </div>
                                                        <div>
                                                            <div className='badge badge-warning bg-warning border-0 rounded-2 cursor-pointer'>{course.type_name}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            ))}
                        </div>
                        :
                        <>
                            <CourseNotFound />
                        </>
                }
            </div>
        </>
    );
}

export default SelectCourse;
