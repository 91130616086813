import { React, useEffect, useState } from "react";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom"; // Import useHistory for redirection
import api from "../helper/api";
import { getServerURL } from "../helper/envConfig";
import { encryptAndStoreLoc } from "../helper/localStore";

const KidsAgeConfirm = () => {
    const history = useNavigate(); // Get the history object for redirection
    const [confirm, setConfirm] = useState();
    const serverURL = getServerURL();
    const [token, setToken] = useState("")

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (confirm) {
            if (confirm === "no") {
                history("/parent-details");
            } else if (confirm === "yes") {
                const res = await api.postWithCustomToken(
                    `${serverURL}users/family-user/kids-age-verification/`,
                    token,
                    {}
                );
                if (res.data.success) {
                    localStorage.removeItem("userId")
                    localStorage.setItem("token", res?.data?.token.access);
                    encryptAndStoreLoc("user", res?.data?.data);
                    history("/update-associated-code");
                }
                localStorage.removeItem("kidsToken");
                history("/update-associated-code");
            }
        } else {
            toast.error("Please confirm your age!");
        }
    };

    useEffect(() => {
        if (localStorage.getItem("kidsToken")) {
            setToken(localStorage.getItem("kidsToken"))
        }
    }, []);

    return (
        <>
            <Header />
            <section>
                <div className="container-fluid">
                    <div className="sign-up-main">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-12 col-md-6 position-relative bg-primary d-flex justify-content-center align-items-center sign-up-col vh-md-100">
                                <img
                                    src="./login/individual-user-vector-1.png"
                                    className="sing-vector-1"
                                    alt=""
                                />
                                <img
                                    src="./login/woman-home.png"
                                    className="project-team"
                                    alt=""
                                />
                                <img
                                    src="./login/individual-user-vector-2.png"
                                    className="sing-vector-2"
                                    alt=""
                                />
                            </div>
                            <div className="col-12 col-md-6 position-relative d-flex justify-content-center align-items-start align-items-md-center sign-up-col sign-up-col-sm vh-md-100">
                                <div className="col-12 col-md-12 col-lg-10 col-xl-9">
                                    <form
                                        className="row g-2 form-main"
                                        style={{
                                            color: "#002FA7",
                                            fontFamily: "noto sans",
                                        }}
                                        onSubmit={handleSubmit}
                                    >
                                        <div className="col-md-12">
                                            <div className="d-flex justify-content-center mb-1">
                                                <span
                                                    className="fw-700"
                                                    style={{
                                                        fontSize: "35.96px",
                                                    }}
                                                >
                                                    {" "}
                                                    Are you 16 year old?
                                                </span>
                                            </div>
                                            <div className="d-flex justify-content-center">
                                                <div className="otpContainer py-1 mt-2">
                                                    <div className="gap-3">
                                                        <label className="form-check-label me-4">
                                                            <input
                                                                className="form-check-input me-2"
                                                                type="radio"
                                                                name="yesNo"
                                                                value="yes"
                                                                onChange={(e) =>
                                                                    setConfirm(
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }
                                                            />
                                                            Yes
                                                        </label>
                                                        <label className="form-check-label me-2">
                                                            <input
                                                                className="form-check-input me-2"
                                                                type="radio"
                                                                name="yesNo"
                                                                value="no"
                                                                onChange={(e) =>
                                                                    setConfirm(
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }
                                                            />
                                                            No
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-center">
                                                <button
                                                    type="submit"
                                                    className="text-white btn-secondary border-0 mt-1 my-3 otpVerifybutton"
                                                >
                                                    Confirm
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default KidsAgeConfirm;
