import React, { useState } from "react";
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

const DatePicker = ({ setFilteredDate, type, handleToggle }) => {
    const [state, setState] = useState({
        startDate: new Date(),
        endDate: addDays(new Date(), 7),
        key: "selection",
    });

    const handleSelect = (ranges) => {
        setState(ranges.selection);
    };

    const handleApply = () => {
        setFilteredDate({ startDate: state.startDate, endDate: state.endDate });
        handleToggle(type)
    };

    return (
        <div className="date-range-picker">
            <DateRangePicker
                useWeekdaysShort={true}
                ranges={[state]}
                onChange={handleSelect}
                moveRangeOnFirstSelection={false}
                direction="horizontal"
                rdrDefinedRangesWrapper={false}
            />
            <div className="apply-button" onClick={handleApply}>
                <button>Apply</button>
            </div>
        </div>
    );
};
export default DatePicker;

// import React, { useState } from "react";
// import { DateRange } from "react-date-range";
// import "react-date-range/dist/styles.css";
// import "react-date-range/dist/theme/default.css";

// export default function App() {
//     const [state, setState] = useState([
//         {
//             startDate: new Date(),
//             endDate: null,
//             key: "selection"
//         }
//     ]);
//     return (
//         <DateRange onChange={item => setState([item.selection])} moveRangeOnFirstSelection={false} ranges={state} />
//     );
// }
