import React from 'react'
import Header from '../layout/Header'
import Footer from '../layout/Footer'
import { Link } from 'react-router-dom'

const BusinessSubscription = () => {
    return (
        <>
            <Header />
            <section>
                <div className="container-fluid">
                    <div className="sign-up-main individual-main">
                        <div className="row justify-content-between align-items-center h-100">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-6 position-relative bg-primary d-flex justify-content-center align-items-center sign-up-col">
                                <img
                                    src="./login/sing-vector-1.png"
                                    className="sing-vector-1"
                                    alt=""
                                />
                                <img
                                    src="./login//man-develops.png"
                                    className="man-develops"
                                    alt=""
                                />
                                <img
                                    src="./login/sing-vector-2.png"
                                    className="sing-vector-2"
                                    alt=""
                                />
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 col-xl-6 position-relative d-flex justify-content-center align-items-start align-items-md-center sign-up-col-sm">
                                <div className="row g-4 ps-xl-5 selectcard py-0 py-md-5 py-xl-0">
                                    <div className="col-12">
                                        <label className="d-block">
                                            <input
                                                type="radio"
                                                name="product"
                                                className="card-input-element d-none"
                                            />
                                            <div className="card card-input border-0 w-100">
                                                <div className="card-body p-4">
                                                    <div className="d-flex align-items-start gap-3">
                                                        <div className="mt-3">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={20}
                                                                height={20}
                                                                viewBox="0 0 20 20"
                                                                fill="none"
                                                            >
                                                                <circle cx={10} cy={10} r={10} fill="#DBE5FF" />
                                                            </svg>
                                                        </div>
                                                        <div>
                                                            <p className="fs-18 text-black fw-600 mb-0">
                                                                Team Mastery
                                                            </p>
                                                            <p className="fs-18 text-primary fw-600 mb-0">
                                                                €‎1 Per Month / User
                                                            </p>
                                                            <ul className="ps-3 mt-4">
                                                                <li className="fe-7 fw-400 text-black mb-3">
                                                                    You will soon be compliant to all rules.
                                                                </li>
                                                                <li className="fe-7 fw-400 text-black mb-3">
                                                                    Your employees have fun with courses.
                                                                </li>
                                                                <li className="fe-7 fw-400 text-black mb-3">
                                                                    They can choose any course.
                                                                </li>
                                                                <li className="fe-7 fw-400 text-black mb-3">
                                                                    You can get any new courses you want.
                                                                </li>
                                                                <li className="fe-7 fw-400 text-black mb-3">
                                                                    You can get certificates
                                                                </li>
                                                                <li className="fe-7 fw-400 text-black">
                                                                    It helps you limit your liability in the case of
                                                                    non-compliance of an employee.
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="col-12">
                                        <label className="d-block">
                                            <input
                                                type="radio"
                                                name="product"
                                                className="card-input-element d-none"
                                            />
                                            <div className="card card-input border-0 w-100">
                                                <div className="card-body p-md-4">
                                                    <div className="d-flex align-items-center gap-4">
                                                        <div>
                                                            <h5 className="fs-6 fw-400 text-black">
                                                                No. Of Users in your company
                                                            </h5>
                                                            <p className="fw-400 text-black fs-10">
                                                                E.g. if your company has 1000 employees, but only 800
                                                                will need access to this data base with compliance and
                                                                fun courses, then you choose 800 .
                                                            </p>
                                                        </div>
                                                        <img
                                                            src="./login/frame.svg "
                                                            height="46px"
                                                            className="rounded-2"
                                                            alt=""
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="col-12 mt-5">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="d-flex align-items-center gap-4">
                                                <p className="mb-0 text-black fs-6">Total</p>
                                                <h2 className="mb-0 text-black display-5 fw-600">€‎3</h2>
                                            </div>
                                            <Link
                                                to="/business-payment"
                                                className="btn-explore d-flex align-items-center gap-3 rounded-2 fw-400"
                                            >
                                                Proceed
                                                <div className="ps-0 ps-md-5">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={22}
                                                        height={14}
                                                        viewBox="0 0 22 14"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M1 6.99854H21L16 1"
                                                            stroke="white"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path
                                                            d="M21 6.99854L16 13"
                                                            stroke="white"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                        />
                                                    </svg>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default BusinessSubscription