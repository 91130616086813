import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getServerURL } from "../../helper/envConfig";
import api from "../../helper/api";
import { encryptAndStoreLoc } from "../../helper/localStore";

function AdminLogin() {
    const serverURL = getServerURL();
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        email: "",
        password: "",
    });

    const handleFormDataChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmitLogin = async (e) => {
        try {
            e.preventDefault();

            if (!formData.email || !formData.password) {
                toast.error("Please fill in all the fields");
                return;
            }

            // if (formData.password.length < 8) {
            //     toast.error("Password should be at least 8 characters long");
            //     return;
            // }

            if (
                !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
                    formData.email
                )
            ) {
                toast.error("Enter valid email");
                return;
            }

            const res = await api.post(
                serverURL + `admin-panel/admin-login`,
                formData
            );
            localStorage.setItem("token", res.data.token.access);
            encryptAndStoreLoc("user", res.data.data);
            toast.success(res.message);
            setFormData({
                email: "",
                password: "",
            });
            setTimeout(() => {
                navigate("/admin-dashboard")
                window.location.reload()
            }, 2000);
        } catch (error) {
            toast.error(error.response.data.message);
        }
    };

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            navigate("/admin-dashboard");
        }
    }, []);

    return (
        <>
            <section className="background-radial-gradient overflow-hidden d-flex justify-content-center align-items-center">
                <div className="container px-4 py-5 px-md-5 text-center text-lg-start my-5">
                    <div className="row gx-lg-5 justify-content-center align-items-center mb-5">
                        <div className="col-lg-6 mb-5 mb-lg-0 position-relative">
                            <div
                                id="radius-shape-1"
                                className="position-absolute rounded-circle shadow-5-strong"
                            />
                            <div
                                id="radius-shape-2"
                                className="position-absolute shadow-5-strong"
                            />
                            <div className="card bg-glass">
                                <div className="card-body px-4 py-5 px-md-5">
                                    <h3 className="mb-3 text-center fw-500">Admin Login</h3>
                                    <form
                                        className="admin-login"
                                        onSubmit={handleSubmitLogin}
                                    >
                                        {/* Email input */}
                                        <div className="form-outline mb-4">
                                            <label
                                                className="form-label"
                                                htmlFor="form3Example3"
                                            >
                                                Email
                                            </label>
                                            <input
                                                type="email"
                                                id="form3Example3"
                                                className="form-control"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleFormDataChange}
                                            />
                                        </div>
                                        {/* Password input */}
                                        <div className="form-outline mb-4">
                                            <label
                                                className="form-label"
                                                htmlFor="form3Example4"
                                            >
                                                Password
                                            </label>
                                            <input
                                                type="password"
                                                id="form3Example4"
                                                className="form-control"
                                                name="password"
                                                value={formData.password}
                                                onChange={handleFormDataChange}
                                            />
                                        </div>
                                        {/* Checkbox */}
                                        <button
                                            type="submit"
                                            data-mdb-button-init=""
                                            data-mdb-ripple-init=""
                                            className="login-btn text-decoration-none d-flex justify-content-center align-items-center"
                                        >
                                            Login
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AdminLogin;
