import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import CoursesVoluntary from './CoursesVoluntary'
import CourseMandatory from './CourseMandatory'
import { getServerURL } from '../../helper/envConfig';
import api from '../../helper/api';

function Section2({ emploree_id }) {

    const serverURL = getServerURL();
    const [dataEployee, setDataEployee] = useState([]);

    const getDataEployee = async () => {
        if (emploree_id) {
            try {
                const response = await api.postWithToken(`${serverURL}users/employee/report/`, { employee_id: emploree_id });
                setDataEployee(response.data.data)
            } catch (error) {
                console.error(error);
            };
        }
    }

    // Effect for fetching quizzes
    useEffect(() => {
        // Decrypt and retrieve isTrue from storage
        getDataEployee();
    }, [emploree_id]);
    
    const getDataPdf = async () => {
        if (emploree_id) {
            try {
                const response = await api.postWithToken(`${serverURL}users/employee/report-pdf/`, { employee_id: emploree_id });
                const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
                const pdfUrl = URL.createObjectURL(pdfBlob);
    
                // If you want to open PDF in a new window
                window.open(pdfUrl);
    
                // Or if you want to embed it in an iframe or an object tag in your React component, you can set state here
                // this.setState({ pdfUrl: pdfUrl });
            } catch (error) {
                console.error(error);
            }
        }
    }


    return (
        <>
            <div className="d-md-flex justify-content-end align-items-center gap-3 pt-5 pt-md-0 pb-0 pb-md-5">
                <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center gap-3 mt-4 mt-md-0">
                    <Link
                        onClick={getDataPdf}
                        className="border-primary-btn d-flex align-items-center gap-2 docs-creator"
                    // to="/bulk-upload"
                    >
                        Download Report
                        <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M7.83333 12L12 16.375M12 16.375L16.1667 12M12 16.375V5M7 19H17"
                                stroke="#002FA7"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </Link>
                </div>
            </div>
            <div className="row mb-4 mb-md-5">

                <div className="col-lg-8">
                    <CourseMandatory emploree_id={emploree_id} />
                    <CoursesVoluntary emploree_id={emploree_id} />

                </div>

                <div className="col-lg-4">
                    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-1 g-4 pt-md-5">
                        <div className="col pt-4 pt-md-0 pt-lg-4">
                            <div className="card border-0 h-100">
                                <div className="card-body p-4 pb-0">
                                    <p className="fs-16 fw-600 text-black">
                                        Courses Mandatory Done
                                    </p>
                                </div>
                                <div className="card-footer bg-transparent border-0 pt-0 px-4">
                                    <h2 className="fw-600 text-black">{dataEployee?.total_mandatory_courses_done ? dataEployee?.total_mandatory_courses_done : 0}</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card border-0 h-100">
                                <div className="card-body p-4 pb-0">
                                    <p className="fs-16 fw-600 text-black">
                                        Courses Voluntary Done
                                    </p>
                                </div>
                                <div className="card-footer bg-transparent border-0 pt-0 px-4">
                                    <h2 className="fw-600 text-black">{dataEployee?.total_volunteer_courses_done ? dataEployee?.total_volunteer_courses_done : 0}</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card border-0 h-100">
                                <div className="card-body p-4 pb-0">
                                    <p className="fs-16 fw-600 text-black">Completion Rate</p>
                                </div>
                                <div className="card-footer bg-transparent border-0 pt-0 px-4">
                                    <h2 className="fw-600 text-black">{dataEployee?.completion_rate ? dataEployee?.completion_rate : "0%"}</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card border-0 h-100">
                                <div className="card-body p-4 pb-0">
                                    <p className="fs-16 fw-600 text-black">Compilance Rate</p>
                                </div>
                                <div className="card-footer bg-transparent border-0 pt-0 px-4">
                                    <h2 className="fw-600 text-black">{dataEployee?.compilance_rate ? dataEployee?.compilance_rate : "0%"}</h2>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </>
    )
}

export default Section2