import { React, useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Header from '../../layout/Header'
import Footer from '../../layout/Footer'
import { decryptFromStore, encryptAndStore } from '../../helper/sessionStore'
import { decryptFromStoreLoc, encryptAndStoreLoc } from '../../helper/localStore'
import { validate } from './RegisterSchema';
import { getServerURL } from '../../helper/envConfig'
import { useNavigate } from 'react-router-dom'; // Import useHistory for redirection
import api from '../../helper/api'
// import 'toastr/build/toastr.min.css';
import { toast } from 'react-toastify';
import { CourseContext } from '../../context/CourseContext'

const BusinessUserDetails = () => {

    const { email } = useContext(CourseContext);


    const [role, setRole] = useState();
    const serverURL = getServerURL();
    const history = useNavigate(); // Get the history object for redirection

    useEffect(() => {
        // Retrieve and decrypt stored information 
        const decryptedInfoRole = decryptFromStore('role');
        const decryptedInfoSubRole = decryptFromStore('sub-role');

        if (decryptedInfoSubRole !== null) {
            setRole(decryptedInfoSubRole);
        } else {
            setRole(decryptedInfoRole);
        }

    }, []);

    const initialValues = {
        username: "",
        email: email || "",
        password: "",
        is_active: true
    };

    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [submitCount, setSubmitCount] = useState(0);

    const handleChange = (e) => {
        const { name, value, checked, type } = e.target;
        let newValue = type === "checkbox" ? checked : value;

        if (submitCount > 0) {
            const validationErrors = validate({ ...values, [name]: newValue });
            setErrors(validationErrors);

            // Remove error message for the specific field if it is valid
            if (Object.keys(validationErrors).length === 0) {
                delete errors[name];
            }
        }

        setValues((prevValues) => ({
            ...prevValues,
            [name]: newValue,
        }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        const updatedValues = { ...values }; // Create a copy of the values object

        const validationErrors = validate(updatedValues);
        setErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            updatedValues.role = role
            updatedValues.is_active = true
            try {
                const response = await api.post(`${serverURL}users/register/`, updatedValues);
                if (response.data) {
                    // localStorage.setItem("token", response.data.token.access);
                    if (response.data?.data?.is_one_time_password) {
                        history("/change-password")
                    }
                    encryptAndStoreLoc("user", response?.data?.data);
                    toast.success(response.data.message);
                    history("/verify-email")
                }

            } catch (error) {
                console.log(error);
                toast.error(error.response.data.message,);
            }
        }
    };


    return (
        <>
            <Header />
            <section>

                <div className="container-fluid">
                    <div className="sign-up-main">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-12 col-md-6 position-relative bg-primary d-flex justify-content-center align-items-center sign-up-col vh-md-100">
                                <img
                                    src="./login/individual-user-vector-1.png"
                                    className="sing-vector-1"
                                    alt=""
                                />
                                <img
                                    src="./login/woman-home.png"
                                    className="project-team"
                                    alt=""
                                />
                                <img
                                    src="./login/individual-user-vector-2.png"
                                    className="sing-vector-2"
                                    alt=""
                                />
                            </div>
                            <div className="col-12 col-md-6 position-relative d-flex justify-content-center align-items-start align-items-md-center sign-up-col sign-up-col-sm vh-md-100">
                                <div className="col-12 col-md-12 col-lg-10 col-xl-9">
                                    <form className="row g-3 form-main" >
                                        <div className="col-md-12">
                                            {/* <div className="col-md-12 col-lg-12 col-xl-8 mb-5">
                                                <label htmlFor="inputState" className="form-label">
                                                    What’s your role?
                                                </label>
                                                <select id="inputState" className="form-select">
                                                    <option>Choose...</option>
                                                    <option selected="">Manager</option>
                                                </select>
                                            </div>
                                            <div className="col-md-12 col-lg-12 col-xl-8 mb-5">
                                                <label htmlFor="inputState" className="form-label">
                                                    What topic/category would you like to explore?
                                                </label>
                                                <select id="inputState" className="form-select">
                                                    <option>Choose...</option>
                                                    <option selected="">IT and Security</option>
                                                </select>
                                            </div> */}
                                            <div className="col-md-12 col-lg-12 col-xl-8 mb-5">
                                                <label className="form-label">User Name</label>
                                                <input
                                                    className="form-control"
                                                    placeholder="Enter your user name"
                                                    name="username"
                                                    onChange={handleChange}
                                                    value={values.username}
                                                    type='text'
                                                />
                                                <div className='error' >{errors?.username}</div>
                                            </div>
                                            <div className="col-md-12 col-lg-12 col-xl-8 mb-5">
                                                <label className="form-label">Email Address</label>
                                                <input
                                                    className="form-control"
                                                    placeholder="Enter your email address"
                                                    name="email"
                                                    onChange={handleChange}
                                                    value={values.email}
                                                    type='email'
                                                />
                                                <div className='error' >{errors?.email}</div>
                                            </div>
                                            <div className="col-md-12 col-lg-12 col-xl-8 mb-5">
                                                <label className="form-label">Password</label>
                                                <input
                                                    type="password"
                                                    name='password' value={values.password}
                                                    className="form-control"
                                                    id="inputPassword4"
                                                    onChange={handleChange}
                                                    placeholder="Enter your password"
                                                />
                                                <div className='error' >{errors?.password}</div>
                                            </div>
                                            {/* <div className="col-md-12 col-lg-12 col-xl-8 d-flex justify-content-end">
                                                <Link
                                                    type="button"
                                                    to="/sign-in"
                                                    // onClick={(e) => (handleSubmit(e), setSubmitCount(1))
                                                    // }
                                                    className="btn-explore d-flex align-items-center fw-400 gap-3 rounded-2"
                                                >
                                                    SignIn
                                                </Link>
                                            </div> */}
                                            <div className="col-md-12 col-lg-12 col-xl-8 d-flex justify-content-end">
                                                <Link
                                                    type="button"
                                                    // to="/subscription"
                                                    onClick={(e) => (handleSubmit(e), setSubmitCount(1))
                                                    }
                                                    className="btn-explore d-flex align-items-center fw-400 gap-3 rounded-2"
                                                >
                                                    Submit
                                                </Link>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default BusinessUserDetails