import React from 'react';
import { Bar } from 'react-chartjs-2';

function BarChart2() {
  const labels2 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const bar2_data = {
    labels: labels2,
    datasets: [
      {
        label: 'My First Dataset',
        data: [19, 16, 19, 16, 15, 24, 30, 10, 5, 12],
        backgroundColor: [
          '#B8CCFF',
          '#D2E23D',
          '#B8CCFF',
          '#D2E23D',
          '#B8CCFF',
          '#D2E23D',
          '#B8CCFF',
        ],
        borderRadius: 100,
        barThickness: 20,
        borderSkipped: false,
        barPercentage: 0.25,
        categoryPercentage: 0.5,
      },
    ],
  };

  const bar2_options = {
    scales: {
      y: {
        beginAtZero: true,
        stepSize: 5,
        border: {
          dash: [10, 10],
          color: '#ffff',
        },
        grid: {
          color: '#B8CCFF',
        },
      },
      x: {
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };


  return (
    <>
      <Bar height={200} data={bar2_data} options={bar2_options} />
    </>
  )
}

export default BarChart2 