import React, { useEffect, useState } from "react";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import { Card, Col, Row, Modal } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import api from "../../helper/api";
import { getServerURL } from "../../helper/envConfig";
import {
    decryptFromStoreLoc,
    encryptAndStoreLoc,
} from "../../helper/localStore";
import { toast } from "react-toastify";

function BusinessUserPayment() {
    const location = useLocation();
    const navigate = useNavigate();
    const serverURL = getServerURL();
    const [state, setState] = useState(location.state || {});
    const [total, setTotal] = useState(0);

    // State to track the dark mode status
    const [isDarkMode, setIsDarkMode] = useState(false);

    // Function to handle the toggle switch
    const handleToggle = () => {
        setIsDarkMode(!isDarkMode);
    };

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [selectedMethod, setSelectedMethod] = useState("sepa"); // Default selected method

    const handleChange = (method) => {
        setSelectedMethod(method);
    };

    const calculateTotal = (state) => {
        let totalPrice = 0;
        if (state?.userPlan?.subscriptionAmount) {
            totalPrice += state?.userPlan?.subscriptionAmount;
        }

        if (state?.supportPlan) {
            totalPrice += state?.supportPlan.supportPlanAmount;
        }

        if (state?.sharedCourses?.length > 0) {
            const sharedCourses = state?.sharedCourses;
            sharedCourses.map((item) => {
                totalPrice += item.price;
            });
        }

        if (state?.blendedLearning?.length > 0) {
            state?.blendedLearning.map((item) => {
                totalPrice += item.total;
            });
        }

        setTotal(totalPrice);
    };
    const createPaymentObj = async () => {
        try {
            let obj = {
                plan_type: "two-two",
                number_of_employees: 0,
                per_employee_price: 12,
                duration: 1,
                duration_type: "year",
                trail_ids: [],
                support_plan_type: "email-support",
                support_plan_price: 0,
                support_plan_duration: 0,
                support_plan_duration_type: "month",
                number_of_own_course: 0,
                per_own_course_price: 0,
                number_of_shared_course: 0,
                per_shared_course_price: 0,
                MBT_per_hour_price: 0,
                MBT_total_hour: 0,
                ETT_per_hour_price: 0,
                ETT_total_hour: 0,
                OFDW_per_hour_price: 0,
                OFDW_total_hour: 0,
                total_amount: 0,
                description: "Tier Two - Two Trails",
            };
            obj.plan_type =
                state.name === "one"
                    ? "one-one"
                    : state.name === "two"
                    ? "two-two"
                    : "three-three-gold";
            obj.number_of_employees = Number(state.userPlan?.employee) || 0;
            obj.per_employee_price = state.userPlan?.perEmployeePrice || 0;
            obj.trail_ids = state.courseTrails;
            obj.support_plan_type = state.supportPlan?.supportPlanName
                ? state.supportPlan?.supportPlanName
                : "";
            obj.support_plan_price = state.supportPlan?.supportPlanAmount
                ? state.supportPlan?.supportPlanAmount
                : 0;
            obj.number_of_own_course = 0;
            obj.per_own_course_price = 0;
            obj.number_of_shared_course = 0;
            obj.per_shared_course_price = 0;
            obj.MBT_per_hour_price = 0;
            obj.MBT_total_hour = 0;
            obj.ETT_per_hour_price = 0;
            obj.ETT_total_hour = 0;
            obj.OFDW_per_hour_price = 0;
            obj.OFDW_total_hour = 0;
            obj.total_amount = total;
            obj.description = state.plan;

            if (state?.blendedLearning?.length > 0) {
                state.blendedLearning.map((item) => {
                    if (item.name === "MBT") {
                        obj.MBT_per_hour_price = item.perPrice;
                        obj.MBT_total_hour = item.quantity;
                    }
                    if (item.name === "ETT") {
                        obj.ETT_per_hour_price = item.perPrice;
                        obj.ETT_total_hour = item.quantity;
                    }
                    if (item.name === "OFDW") {
                        obj.OFDW_per_hour_price = item.perPrice;
                        obj.OFDW_total_hour = item.quantity;
                    }
                });
            }

            if (state?.sharedCourses?.length > 0) {
                const sharedCourses = state.sharedCourses;
                sharedCourses.map((item) => {
                    if (item.name === "Own course") {
                        obj.number_of_own_course =  Number(item.employee);
                        obj.per_own_course_price = item.perPrice;
                    }

                    if (item.name === "Shared Courses") {
                        obj.number_of_shared_course =  Number(item.employee);
                        obj.per_shared_course_price = item.perPrice;
                    }
                });
            }

            const response = await api.postWithToken(
                `${serverURL}payments/payment/`,
                obj
            );
            if (response.data.success) {
                if(response.data.payment_url) {
                    window.location.href = response.data.payment_url;
                } else {
                    toast.success(response.data.message)
                    navigate("/business-home")
                }
            } else {
                console.error("Error:", response.data.message);
            }
        } catch (error) {
            console.error("Error creating payment object", error);
        }
    };

    useEffect(() => {
        if (state.name) {
            encryptAndStoreLoc("state", state);
            calculateTotal(state);
        } else {
            const data = decryptFromStoreLoc("state");
            if (data) {
                setState(data);
                calculateTotal(data);
            } else {
                navigate("/subscription-plans");
            }
        }
    }, []);
    return (
        <>
            <Header />
            <section className="payment-flow-section">
                <div className="container">
                    <Row className="justify-content-between payment-information-card">
                        <Col lg={6} xl={6}>
                            <h2>Selected Plan</h2>
                            <p>
                                Review your plan and provide the number of
                                employees.
                            </p>
                            {(state.name === "two" ||
                                state.name === "three") && (
                                <Card>
                                    <Card.Body className="p-4">
                                        <div className="d-md-flex justify-content-between align-items-center gap-4">
                                            <div>
                                                <h5 className="fw-500 fs-6">
                                                    How many employees will take
                                                    those courses approximately?
                                                </h5>
                                                <p>
                                                    The total price will be
                                                    calculated according to the
                                                    number of employees. The
                                                    price per employee is €
                                                    {
                                                        state?.userPlan
                                                            ?.perEmployeePrice
                                                    }
                                                    /year.
                                                </p>
                                                {/* <h5 className='fs-7'>See Full Price Table <img src="../png/questionmark2.png" alt="" /></h5> */}
                                            </div>
                                            {/* <div className='mt-4 mt-md-0'>
                                            <input className='input-box' type="text" />
                                        </div> */}
                                            <div className="mt-4 mt-md-0">
                                                <h4>
                                                    {state?.userPlan?.employee}{" "}
                                                    Employee
                                                </h4>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            )}
                            {
                                <Card>
                                    <Card.Body>
                                        <div className="d-md-flex justify-content-between align-items-start gap-4">
                                            <div>
                                                <h5>{state?.plan}</h5>
                                                <p>
                                                    Several trails with basic
                                                    courses on Compliance, Data
                                                    Privacy and Information
                                                    Security
                                                </p>
                                            </div>
                                            <div className="mt-4 mt-md-0">
                                                <h4>
                                                    <span>
                                                        €
                                                        {
                                                            state?.userPlan
                                                                ?.perEmployeePrice
                                                        }
                                                    </span>
                                                    /year <br />
                                                </h4>
                                                <h4 className="text-nowrap">
                                                    per Employee
                                                </h4>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            }
                            {state.supportPlan && (
                                <Card>
                                    <Card.Body>
                                        <div className="d-md-flex justify-content-between align-items-center">
                                            <div>
                                                <h5 className="mb-0">
                                                    {
                                                        state?.supportPlan
                                                            ?.supportPlanName
                                                    }
                                                </h5>
                                            </div>
                                            <div className="mt-3 mt-md-0">
                                                <h4>
                                                    <span>
                                                        €
                                                        {
                                                            state?.supportPlan
                                                                ?.supportPlanAmount
                                                        }
                                                    </span>
                                                    /month
                                                </h4>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            )}
                            {/* <Card>
                                <Card.Body >
                                    <h6 className='mb-4'>Select one of the course categories that will be available to you for e-learning</h6>
                                    <label className='d-flex align-items-center mb-3 input-radio-box-title' >
                                        <input type="radio" class="input-radio-box on" name="pilih" checked /> Compliance

                                    </label>
                                    <label className='d-flex align-items-center mb-3 input-radio-box-title' >
                                        <input type="radio" class="input-radio-box on" name="pilih" /> Information Security
                                    </label>
                                </Card.Body>
                            </Card> */}
                            {/* <Card>
                                <Card.Body>
                                    <div className='d-flex justify-content-between align-items-start'>
                                        <div>
                                            <h6 className='mb-0'>Number of Employees</h6>
                                            <p className='fs-10'>Enter the number of your employees to calculate the total amount</p>
                                        </div>
                                        <input className='input-box' type="text" />
                                    </div>
                                </Card.Body>
                            </Card> */}
                            {/* <Card className='tailored-mandatory-card'>
                                <Card.Body>
                                    <div className='d-flex justify-content-between align-items-start'>
                                        <div>
                                            <div className='mb-3 d-flex justify-content-between align-items-center'>
                                                <div className='d-flex align-items-center gap-2'>
                                                    <img src="/admin/dimond.png" alt="" />
                                                    <h3 className='mb-0'>Add Tailored Mandatory Courses</h3>
                                                </div>

                                                <div className={`cont ${isDarkMode ? 'dark-mode' : ''}`} onClick={handleShow}>
                                                    <div className="toggle">
                                                        <input
                                                            type="checkbox"
                                                            id="mode-toggle"
                                                            className="toggle__input"
                                                            checked={isDarkMode}
                                                            onChange={handleToggle}
                                                        />
                                                        <label htmlFor="mode-toggle" className="toggle__label"></label>
                                                    </div>
                                                </div>

                                            </div>
                                            <p className='fs-12 mb-0'>Customize mandatory courses for your business by industry, country, and department. Enhance compliance with tailored training specific to your unique needs.</p>
                                            <p className='fs-12 fw-700 learn-more-text'>Learn more</p>
                                            <p><span>Price: €10,000</span> per year + <span>€10 </span> per employee per year</p>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card> */}
                        </Col>
                        <Col xl={1} className="d-none d-xl-block"></Col>
                        <Col lg={6} xl={5} className="mt-4 mt-lg-0">
                            <h2>Enter Payment Information</h2>
                            <p>
                                Select a payment method and choose your billing
                                frequency. You can change your plan or payment
                                method at any time.
                            </p>
                            {/* <Card>
                                <Card.Body>
                                    <div className='d-md-flex align-items-start gap-3'>
                                        <label className='input-radio-box-title mt-4 mt-md-4'>
                                            <input
                                                type="radio"
                                                className="input-radio-box on"
                                                name="pilih"
                                                checked={selectedMethod === 'sepa'}
                                                onChange={() => handleChange('sepa')}
                                            />
                                        </label>
                                        <div className='mt-3 mt-md-0'>
                                            <h5>SEPA Bank Draft</h5>
                                            <img src="/admin/sepa.png" alt="" />
                                            {selectedMethod === 'sepa' && (
                                                <form className="manage-family debit-card-form row g-3 mt-0 mt-md-4 mb-5">
                                                    <div className="col-12">
                                                        <input
                                                            className="form-control"
                                                            placeholder="IBAN"
                                                            type="text"
                                                            name="iban"
                                                            defaultValue=""
                                                        />
                                                    </div>
                                                    <div className="col-12">
                                                        <input
                                                            className="form-control"
                                                            placeholder="Bank Name"
                                                            type="text"
                                                            name="bankName"
                                                            defaultValue=""
                                                        />
                                                    </div>
                                                    <div className="col-12">
                                                        <input
                                                            className="form-control"
                                                            placeholder="Account Holder Name"
                                                            type="text"
                                                            name="accountHolder"
                                                            defaultValue=""
                                                        />
                                                    </div>
                                                    <div className="form-check d-flex align-items-center gap-3 ms-2 pt-4">
                                                        <input
                                                            className="form-check-input wh-25"
                                                            type="checkbox"
                                                            id="flexCheckChecked1"
                                                            defaultChecked=""
                                                        />
                                                        <label className="form-check-label" htmlFor="flexCheckChecked1">
                                                            I authorize the direct debit of my account via SEPA.
                                                        </label>
                                                    </div>
                                                </form>
                                            )}
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Body>
                                    <div className='d-flex align-items-start gap-1 gap-md-3'>
                                        <label className='d-flex align-items-center mb-3 input-radio-box-title mt-3'>
                                            <input
                                                type="radio"
                                                className="input-radio-box on"
                                                name="pilih"
                                                checked={selectedMethod === 'card'}
                                                onChange={() => handleChange('card')}
                                            />
                                        </label>
                                        <div>
                                            <h5>Credit or Debit Card</h5>
                                            <div className='d-flex align-items-center gap-2'>
                                                <img src="/png/american.png" width={20} height={20} alt="" />
                                                <img src="/png/mastercard.png" width={20} height={20} alt="" />
                                                <img src="/png/visa.png" width={20} height={20} alt="" />
                                            </div>
                                            {selectedMethod === 'card' && (
                                                <form className="manage-family debit-card-form row g-3 mt-4 mb-5">
                                                    <div className="col-12">
                                                        <input
                                                            className="form-control"
                                                            placeholder="Card Number"
                                                            type="text"
                                                            name="iban"
                                                            defaultValue=""
                                                        />
                                                    </div>
                                                    <div className="col-12">
                                                        <input
                                                            className="form-control"
                                                            placeholder="Card Holder’s Name"
                                                            type="text"
                                                            name="bankName"
                                                            defaultValue=""
                                                        />
                                                    </div>
                                                    <div className="col-6">
                                                        <input
                                                            className="form-control"
                                                            placeholder="CNN"
                                                            type="text"
                                                            name="accountHolder"
                                                            defaultValue=""
                                                        />
                                                    </div>
                                                    <div className="col-6">
                                                        <input
                                                            className="form-control"
                                                            placeholder="Expiration Date (MM/YY)"
                                                            type="date"
                                                            name="accountHolder"
                                                            defaultValue=""
                                                        />
                                                    </div>
                                                    <div className="form-check d-flex align-items-center gap-3 ms-2 pt-4">
                                                        <input
                                                            className="form-check-input wh-25"
                                                            type="checkbox"
                                                            id="flexCheckChecked1"
                                                            defaultChecked=""
                                                        />
                                                        <label className="form-check-label" htmlFor="flexCheckChecked1">
                                                            Save Card Details
                                                        </label>
                                                    </div>
                                                </form>
                                            )}
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card> */}
                            {/* <Card>
                                <Card.Body>
                                    <div className="d-flex align-items-start gap-1 gap-md-3">
                                        <label className="d-flex align-items-center mb-3 input-radio-box-title mt-3">
                                            <input
                                                type="radio"
                                                className="input-radio-box on"
                                                name="pilih"
                                                checked={true}
                                                onChange={() =>
                                                    handleChange("paypal")
                                                }
                                            />
                                        </label>
                                        <div>
                                            <h5>Paypal</h5>
                                            <img
                                                src="/png/paypal.png"
                                                width={72}
                                                height={20}
                                                alt=""
                                            />
                                            {selectedMethod === "paypal" && (
                                                <div className="mt-3">
                                                    <p className="fw-400 fs-7 text-black">
                                                        We’ll redirect you to
                                                        PayPal to complete your
                                                        purchese.
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card> */}
                            <Card>
                                <Card.Body className="summary-details-list py-4 py-md-5 px-4">
                                    <h5 className="fs-4 pb-4">Summary</h5>
                                    <div className="d-flex justify-content-between align-items-center gap-3 mb-3 border-bottom pb-2">
                                        <div>
                                            <h5 className="fs-20 fw-400">
                                                {state?.plan} (for{" "}
                                                {state?.userPlan?.employee}{" "}
                                                Employees)
                                            </h5>
                                        </div>
                                        <div>
                                            <h4>
                                                <span>
                                                    €
                                                    {
                                                        state?.userPlan
                                                            ?.subscriptionAmount
                                                    }
                                                </span>
                                                /year
                                            </h4>
                                        </div>
                                    </div>
                                    {state?.supportPlan && (
                                        <div className="d-flex justify-content-between align-items-center gap-3 mb-3 border-bottom pb-2">
                                            <div>
                                                <h5 className="fs-20 fw-400">
                                                    {
                                                        state?.supportPlan
                                                            .supportPlanName
                                                    }
                                                </h5>
                                            </div>
                                            <div>
                                                <h4>
                                                    <span>
                                                        €
                                                        {
                                                            state?.supportPlan
                                                                .supportPlanAmount
                                                        }
                                                    </span>
                                                    /month
                                                </h4>
                                            </div>
                                        </div>
                                    )}
                                    {state?.blendedLearning?.length > 0 &&
                                        state?.blendedLearning.map((item) => {
                                            return (
                                                <>
                                                    <div className="d-flex justify-content-between align-items-center gap-3 mb-3 border-bottom pb-2">
                                                        <div>
                                                            <h5 className="fs-20 fw-400">
                                                                {item.title}
                                                            </h5>
                                                        </div>
                                                        <div>
                                                            <h4>
                                                                <span>
                                                                    €
                                                                    {item.total}
                                                                </span>
                                                            </h4>
                                                        </div>
                                                    </div>
                                                </>
                                            );
                                        })}
                                    {state?.sharedCourses?.length > 0 &&
                                        state.sharedCourses.map((item) => {
                                            return (
                                                <>
                                                    <div className="d-flex justify-content-between align-items-center gap-3 mb-3 border-bottom pb-2">
                                                        <div>
                                                            <h5 className="fs-20 fw-400">
                                                                {item.name}
                                                            </h5>
                                                        </div>
                                                        <div>
                                                            <h4>
                                                                <span>
                                                                    €
                                                                    {item.price}
                                                                </span>
                                                            </h4>
                                                        </div>
                                                    </div>
                                                </>
                                            );
                                        })}
                                    <div className="d-flex justify-content-between align-items-center gap-3">
                                        <div>
                                            <h5 className="fs-2">Total</h5>
                                        </div>
                                        <div className="total">
                                            <h4>
                                                <span>€{total}</span>
                                            </h4>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                            <button
                                to="/processed"
                                onClick={createPaymentObj}
                                className="btn-explore p-sm-15 rounded-2 w-100 fw-400 d-block mt-4 text-center"
                            >
                                Proceed Payment
                            </button>
                        </Col>
                    </Row>
                </div>
            </section>
            <Footer />

            <Modal
                show={show}
                size="xl"
                onHide={handleClose}
                className="tailored-mandatory-courses"
            >
                <Modal.Header closeButton className="border-0">
                    <Modal.Title>
                        What’s Included in Tailored Mandatory Courses?
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        This premium add-on allows you to request tailored
                        mandatory courses designed to meet the specific
                        requirements of your industry, country regulations, and
                        internal policies. You can choose up to 5 main
                        departments, ensuring each team gets the training they
                        need.
                    </p>
                    <h6>Key Features:</h6>
                    <ul>
                        <li>
                            Industry-Specific: Training modules relevant to your
                            industry’s regulations and compliance standards.
                        </li>
                        <li>
                            Country-Specific: Content aligned with local laws
                            and guidelines.
                        </li>
                        <li>
                            Department-Specific: Tailored courses for up to 5
                            departments within your organization.
                        </li>
                    </ul>
                    <h6>Pricing:</h6>
                    <ul>
                        <li>€10,000 per year + €10 per employee per year</li>
                        <li>
                            Custom courses will be automatically applied to all
                            enrolled employees across selected departments.
                        </li>
                    </ul>
                </Modal.Body>
                <Modal.Footer className="border-0">
                    <div className="d-flex justify-content-end">
                        <button
                            className="btn-explore p-sm-15 rounded-2 fw-500 text-white"
                            onClick={handleClose}
                        >
                            Back
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default BusinessUserPayment;
