import React, { useState } from 'react';
import AdminLayout from '../../components/Admin/AdminLayout';
import ReactApexChart from 'react-apexcharts'; // Make sure to import this if you are using ApexCharts

function AdminSystem() {
    const [state] = useState({
        lineChartOptions: {
            chart: {
                height: 350,
                type: 'line',
                toolbar: {
                    show: false,
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth',
                width: 3,
            },
            grid: {
                show: true,
                borderColor: '#edebeb',
                xaxis: {
                    lines: {
                        show: true
                    }
                },
                yaxis: {
                    lines: {
                        show: false
                    }
                }
            },
            xaxis: {
                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                axisBorder: {
                    show: false
                },
                labels: {
                    style: {
                        colors: '#A3A3A3',
                        fontSize: '12.96px',
                        fontWeight: 600,
                    },
                },
                axisTicks: {
                    show: false
                }
            },
            yaxis: {
                labels: {
                    formatter: function (value) {
                        return `${value}%`;
                    },
                    style: {
                        colors: ['#A3A3A3'],
                        fontSize: '11.88px',
                        fontWeight: 500,
                    },
                },
                min: 0,
                max: 100,
                tickAmount: 5,
                tickValues: [0, 20, 30, 40, 60, 80, 100], // Custom tick values
            },
            colors: ["#2d9cdb", "#ff5b5b"],
            legend: {
                position: 'top',
                horizontalAlign: 'right',
                fontSize: '12px',
                fontWeight: 500,
                fontFamily: 'Poppins, sans-serif',
                labels: {
                    colors: '#A3A3A3',
                },
                markers: {
                    size: 6,
                    shape: 'round',
                },
            },
        },
        columnChartOptions: {
            chart: {
                height: 350,
                type: 'bar',
                toolbar: {
                    show: false,
                },
            },
            dataLabels: {
                enabled: false
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    borderRadius: 8, // Added border radius for bar
                }
            },
            grid: {
                show: true,
                borderColor: '#edebeb',
                xaxis: {
                    lines: {
                        show: true
                    }
                },
                yaxis: {
                    lines: {
                        show: false
                    }
                }
            },
            xaxis: {
                categories: ['2021', '2022', '2023', '2024'],
                axisBorder: {
                    show: false
                },
                labels: {
                    style: {
                        colors: '#A3A3A3',
                        fontSize: '12.96px',
                        fontWeight: 600,
                    },
                },
                axisTicks: {
                    show: false
                }
            },
            yaxis: {
                labels: {
                    formatter: function (value) {
                        return `${value}%`;
                    },
                    style: {
                        colors: ['#A3A3A3'],
                        fontSize: '11.88px',
                        fontWeight: 500,
                    },
                },
                min: 0,
                max: 100,
                tickAmount: 5,
                tickValues: [0, 20, 30, 40, 60, 80, 100], // Custom tick values
            },
            colors: ["#D2E23D", "#ff5b5b"],
            legend: {
                position: 'top',
                horizontalAlign: 'right',
                fontSize: '12px',
                fontWeight: 500,
                fontFamily: 'Poppins, sans-serif',
                labels: {
                    colors: '#A3A3A3',
                },
                markers: {
                    size: 6,
                    shape: 'round',
                },
            },
        },
        lineChartSeries: [{
            name: '2023',
            data: [10, 17, 16, 20, 32, 38, 32, 22, 20, 24, 26, 25],
        }],
        columnChartSeries: [{
            name: '2023',
            data: [40, 82, 76, 90],
        }],
    });

    return (
        <AdminLayout>
            <h2 className="page-title">System Status Updates</h2>
            <div className="row g-4 mt-1">
                <div className="col-12 col-lg-7">
                    <div className="card border-0 p-3 position-relative chart-card-shadow rounded-4">
                        <div className="card-body">
                            <h2 className='fs-18 text-bl fw-600 ps-3'>Monthly Uptime</h2>
                            <ReactApexChart options={state.lineChartOptions} series={state.lineChartSeries} type="line" height={350} />
                        </div>
                        <p className='position-absolute top-50 start-0 rotate-text'>Uptime percentage</p>
                    </div>
                </div>
                <div className="col-12 col-lg-5">
                    <div className="card border-0 p-3 position-relative chart-card-shadow rounded-4">
                        <div className="card-body">
                            <h2 className='fs-18 text-bl fw-600 ps-3'>Yearly Uptime</h2>
                            <ReactApexChart options={state.columnChartOptions} series={state.columnChartSeries} type="bar" height={350} />
                        </div>
                        <p className='position-absolute top-50 start-0 rotate-text'>Uptime percentage</p>
                    </div>
                </div>
            </div>
        </AdminLayout>
    );
}

export default AdminSystem;
