import React from 'react'
import Faq from '../components/Faq'
import StudentTestimonials from '../components/StudentTestimonials'
import CategoriesCard from '../components/CategoriesCard';
import ComparisonTable from '../components/ComparisonTable';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import { Link } from 'react-router-dom';

function ComplianceOfficer() {
    return (
        <>
            <Header />
            <section>
                <div className="team-pricing bg-secondary overflow-hidden d-flex justify-content-center align-items-center">
                    <div className="container">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-12 col-md-6 col-lg-6 order-2 order-md-1">
                                <p className="fw-600 fs-18 text-start mb-3 text-primary">
                                    Why Unicorso?
                                </p>
                                <h2 className="display-5 text-start text-black fw-400 mb-3">
                                    Fun, Skills and Compliance Courses for Compliance Officer
                                </h2>
                                <p className="text-start text-black fs-6 fw-400 mb-4">
                                    Here you can get all the necessary training for your company's team
                                    in relation to compliance, for example in the areas of money
                                    laundering, fraud, corruption, antitrust law or GDPR!
                                </p>
                                <button
                                    type="button"
                                    className="btn btn-warning text-black d-flex align-items-center gap-3 fw-600 fs-18"
                                >
                                    Enroll Now
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={22}
                                        height={14}
                                        viewBox="0 0 22 14"
                                        fill="none"
                                    >
                                        <path
                                            d="M1 6.99854H21L16 1"
                                            stroke="#000C2A"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M21 6.99854L16 13"
                                            stroke="#000C2A"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                        />
                                    </svg>
                                </button>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 position-relative d-flex justify-content-end order-1 order-md-2">
                                <div>
                                    <img
                                        src="./featured-courses/vector-circle.png"
                                        className="team-pricing-vector-3"
                                        alt=""
                                    />
                                    <img
                                        src="./individual-pricing/boy-and-girl.png"
                                        className="boy-and-girl position-relative z-1"
                                        alt=""
                                    />
                                    <img
                                        src="./featured-courses/vector-circle2.png"
                                        className="team-pricing-vector-4"
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="learn-pricing-sec">
                    <div className="container">
                        <div className="row justify-content-between align-items-center gx-3">
                            <div className="col-12 col-md-12 col-lg-6 position-relative d-flex justify-content-center justify-content-lg-start order-1 order-md-1">
                                <div className="position-relative">
                                    <img
                                        src="./featured-courses/desktop-fram.png"
                                        className="desktop-fram z-1 pe-0 pe-md-5"
                                        alt=""
                                    />
                                    <img
                                        src="./featured-courses/studio-display-front.png"
                                        className="studio-display-front z-1 pe-0 pe-md-5"
                                        alt=""
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-6 order-2 order-md-2">
                                <div className="px-lg-4">
                                    <p className="fw-400 fs-6 text-start mb-3 text-primary">
                                        Fun, Skills Compliance Course
                                    </p>
                                    <h2 className="display-5 text-start fw-400 mb-3 text-black">
                                        More engaging way to learn
                                    </h2>
                                    <p className="text-start fs-6 fw-400 mb-3 text-black">
                                        There are also a lot of interesting courses here that you might
                                        want to do, for example about the history of chess or the Olympic
                                        Games or something like that. Or courses on rhetoric or Microsoft
                                        Skills.
                                    </p>
                                    <p className="text-start fs-6 fw-400 mb-4 text-black">
                                        You complete the required training for the purposes of the GDPR
                                        and corruption or fraud prevention.
                                    </p>
                                    <p className="text-start fs-6 fw-400 mb-4 text-black">
                                        You as a company also receive a Compliance Employee Awareness
                                        Score!
                                    </p>
                                    <button
                                        type="button"
                                        className="btn btn-warning text-black d-flex align-items-center gap-3 fw-600 fs-18"
                                    >
                                        Enroll Now
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={22}
                                            height={14}
                                            viewBox="0 0 22 14"
                                            fill="none"
                                        >
                                            <path
                                                d="M1 6.99854H21L16 1"
                                                stroke="#000C2A"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M21 6.99854L16 13"
                                                stroke="#000C2A"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                            />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ComparisonTable />
            <CategoriesCard />
            <div className="d-flex justify-content-center mb-5 pb-2 pb-md-4">
                <Link
                    to="/sign-up"
                    className="btn btn-warning text-black fw-600 fs-18 px-4 py-3 docs-creator"
                >
                    Sign Up for Free Test
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={22}
                        height={14}
                        viewBox="0 0 22 14"
                        fill="none"
                    >
                        <path
                            d="M1 6.99854H21L16 1"
                            stroke="#000C2A"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M21 6.99854L16 13"
                            stroke="#000C2A"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                        ></path>
                    </svg>
                </Link>
            </div>
            <StudentTestimonials />
            <Faq />
            <Footer />
        </>
    )
}

export default ComplianceOfficer