import { React, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import api from "../helper/api";
import { getServerURL } from "../helper/envConfig";
import { decryptToken } from "../helper/localStore";

import { useNavigate } from "react-router-dom"; // Import useHistory for redirection

const ParentApproval = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const queryObj = {};

    for (const [key, value] of searchParams.entries()) {
        queryObj[key] = value;
    }

    const serverURL = getServerURL();

    const history = useNavigate();

    const statusUpdate = async (status, parents_email, token) => {
        try {
            const res = await api.postWithCustomToken(
                `${serverURL}users/family-user/parent-accept-or-reject-request/`,
                token,
                {
                    status: status,
                    parents_email: parents_email,
                }
            );
            if (res.data.success) {
                if (status === "accepted") {
                    return history("/sign-in");
                } else {
                    return history("/parentsshouldallowkidstobepartofunicorso");
                }
            }
        } catch (error) {
            return history("/");
        }
    };

    useEffect(() => {
        if (queryObj.data) {
            const url = queryObj.data.replaceAll(" ", "+");
            const decrypt = decryptToken(url);
            if (!decrypt) {
                return history("/");
            }
            const obj = JSON.parse(decrypt.replaceAll("'", '"'));
            statusUpdate(obj.status, obj.parents_email, obj.token);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryObj.data]);

    return (
        <>
            <Header />
            <h1> Wait...</h1>
            <Footer />
        </>
    );
};

export default ParentApproval;
