import React, { useState } from 'react';
import AdminLayout from '../../components/Admin/AdminLayout';
import AdminUpload from '../../components/Admin/CourseManagement/UploadFlow/AdminUpload';

function AdminLegalDocumentEditor() {

    const [currentFiles, setCurrentFiles] = useState([])


    return (
        <AdminLayout>
            <h2 className="page-title">Unicorso Documentation</h2>
            <div className="row g-4 mt-1">
                <div className="col-12">
                    <div className="card border-0 rounded-3">
                        {/* <div className="card-header bg-transparent fs-20 fw-600 text-bl p-3">
                            Course Details
                        </div> */}
                        <div className="card-body p-4 p-lg-5">
                            {/* <div className="row">
                                <div className="col-12">
                                    <AdminUpload currentFiles={currentFiles} setCurrentFiles={setCurrentFiles} />
                                </div>
                            </div> */}
                            <div className="overflow-auto">
                                <div className="documentation-iframe-box">
                                    <iframe className='documentation-iframe' src="https://docs.google.com/document/d/1iWF7iu6CUpvrm005juhUR7UmGHAQhH4wURzoqlWy_3o/preview" scrolling="yes" frameborder="0" marginheight="100" marginwidth="0px" width="100%" allowfullscreen>
                                    </iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AdminLayout>
    );
}

export default AdminLegalDocumentEditor;
