import React, { useState, useEffect, useContext } from "react";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import { Link } from "react-router-dom";
import api from "../helper/api";
import { getServerURL } from "../helper/envConfig";
import { useNavigate } from "react-router-dom"; // Import useHistory for redirection
import { CourseContext } from "../context/CourseContext";
import { toast } from "react-toastify";
import {
    BreadcrumbSkeleton,
    ButtonSkeleton,
    ContentSkeleton,
    SidebarSkeleton,
    TitleBadgeSkeleton,
} from "./SkeletonLayouts";
import { Is_Login } from "../helper/IsLogin";
import { Rating } from "react-simple-star-rating";
import { decryptFromStore } from "../helper/sessionStore";

const Feedback = () => {
    const {
        loder,
        setLoder,
        setIs_search,
        setSearch,
        manageBookMark,
        setIsWishlist,
        isWishlist,
    } = useContext(CourseContext);

    const [courseDetails, setCourseDetails] = useState();
    const serverURL = getServerURL();
    const history = useNavigate();
    const isLoggedIn = Is_Login();
    const [isBookmarked, setIsBookmarked] = useState(false)
    const [feedbackText , setFeedbackText] = useState("")
    const [rating, setRating] = useState(0);


    const getCourses = async () => {
        try {
            setLoder(true);

            const feedback = decryptFromStore("feedback");
            if (feedback.id) {
                const response = await api.postWithToken(
                    `${serverURL}courses/course-details/`,
                    { course_id: feedback.id }
                );
                setCourseDetails(response.data);
                setIsBookmarked(response?.data?.is_bookmarked)

                setLoder(false);
            } else {
                history("/sign-in");
            }
        } catch (error) {
            setLoder(false);
        }
    };

    useEffect(() => {
        getCourses();
    }, [isLoggedIn]);

    // Assuming listCourse?.course_details?.category_name might have spaces
    const categoryName = courseDetails?.course_details?.category_name.replace(
        / /g,
        "-"
    );


    const handlePress = (data) => {
        setSearch(data);
        getCourses(); // Call the function you want to execute on Enter key press
        setIs_search(true);
        history("/courses-explore");
        window.scrollTo(0, 500);
    };


    const handleRating = (rate) => {
        setRating(rate);
    };

    const sendFeedback = async () => {
        try {
            if(!feedbackText) {
                toast.error("Please enter feedback text");
                return;
            }
            const response = await api.postWithToken(
                `${serverURL}courses/course-feedback/`,
                {
                    course_id: courseDetails?.course_details?.course_id,
                    rating: rating,
                    feedback: feedbackText,
                }
            );
            sessionStorage.removeItem('feedback');
            toast.success(response?.data?.message);
            history("/")
        } catch (error) {
            toast.error("Failed to send ");
        }
    };

    const bookMark = () => {
        if (!isLoggedIn) {
            history("/sign-in");
        } else {
            manageBookMark(courseDetails?.course_details?.course_id);
        }
    };

    useEffect(() => {
        setIsWishlist(isBookmarked);
    }, [isBookmarked]);

    return (
        <>
            <Header />

            {loder ? (
                <div className="container">
                    <BreadcrumbSkeleton />
                    <TitleBadgeSkeleton />
                    <div className="row">
                        <div className="col-lg-8">
                            <ContentSkeleton />
                            <ContentSkeleton />
                            <ContentSkeleton />
                            <ContentSkeleton />
                            <ContentSkeleton />
                            <ButtonSkeleton />
                        </div>
                        <div className="col-lg-4">
                            <SidebarSkeleton />
                            <SidebarSkeleton />
                        </div>
                    </div>
                </div>
            ) : (
                <section>
                    <div className="featured-main">
                        <div className="container">
                            <div className="breadcrumb-main">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-3 mb-md-5">
                                        <li className="breadcrumb-item">
                                            <Link to="/">Home</Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <Link
                                                to={`/courses-category/${categoryName}`}
                                            >
                                                {
                                                    courseDetails
                                                        ?.course_details
                                                        ?.category_name
                                                }
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <Link to="">
                                                {courseDetails?.course_details &&
                                                    courseDetails
                                                        ?.course_details
                                                        ?.type_name}{" "}
                                                Course
                                            </Link>
                                        </li>
                                        <li
                                            className="breadcrumb-item active"
                                            aria-current="page"
                                        >
                                            {
                                                courseDetails?.course_details
                                                    ?.name
                                            }
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                            <div className="row g-4 g-md-3 g-lg-4 g-xl-5">
                                <div className="col-md-12 col-lg-8">
                                    <div className="d-flex justify-content-between  align-items-start gap-3 flex-wrap mb-3">
                                        <div className="d-flex align-items-center gap-3 flex-wrap mb-3">
                                            <span
                                                onClick={() =>
                                                    handlePress(
                                                        courseDetails
                                                            ?.course_details
                                                            ?.tags_list[0].name
                                                    )
                                                }
                                                className="badge badge-black-2 text-white rounded-2 fw-400 fs-7 md-0 mb-md-4"
                                            >
                                                {courseDetails?.course_details
                                                    ?.tags_list.length &&
                                                    courseDetails?.course_details
                                                        ?.tags_list[0].name}
                                            </span>
                                            <span
                                                className={
                                                    courseDetails?.course_details
                                                        ?.type_name === "Bronze"
                                                        ? "badge badge-bronze rounded-2 fw-400 fs-7 md-0 mb-md-4"
                                                        : courseDetails
                                                            ?.course_details
                                                            ?.type_name ===
                                                        "Silver"
                                                        ? "badge badge-silver rounded-2 fw-400 fs-7 md-0 mb-md-4"
                                                        : courseDetails
                                                            ?.course_details
                                                            ?.type_name === "Gold"
                                                        ? "badge badge-gold rounded-2 fw-400 md-0 mb-md-4"
                                                        : courseDetails
                                                            ?.course_details
                                                            ?.type_name === "Free"
                                                        ? "badge badge-light text-black rounded-2 fw-400 fs-7 md-0 mb-md-4"
                                                        : ""
                                                }
                                            >
                                                {
                                                    courseDetails?.course_details
                                                        ?.type_name
                                                }
                                            </span>
                                        </div>

                                        <button
                                            type="button"
                                            onClick={bookMark}
                                            className={`${
                                                isWishlist
                                                    ? "btn-explore"
                                                    : "btn-primary"
                                            } d-flex align-items-center gap-3 rounded-2 md-0 mb-md-4 bookmark-btn`}
                                        >
                                            {isWishlist ? "Bookmarked" : "Bookmark"}
                                            <img
                                                src={
                                                    isWishlist
                                                        ? "../featured-courses/bookmark_added4.png"
                                                        : "../featured-courses/bookmark_added.png"
                                                }
                                                className="wh-25"
                                                alt=""
                                            />
                                        </button>
                                    </div>

                                    <h2 className="text-black fw-400 mb-4 mb-md-5 mb-lg-6">
                                        {courseDetails?.course_details?.name}
                                    </h2>
                                </div>
                            </div>
                            <div className="row g-4 g-md-3 g-lg-4 g-xl-5">
                                <div className="col-md-12 col-lg-6">
                                    <div className="card featured-card linear-gradient rounded-3 overflow-hidden border-0">
                                        <div className="card-body px-4 pt-5">
                                            <h2 className="ai-step-title">
                                                How was the course?
                                            </h2>
                                            <div className="d-flex justify-content-center ai-step-imge">
                                                <img
                                                    src="/png/ai-step.png"
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-6">
                                    <h3 className="ai-step-title-2 mb-3">
                                        How was the course {courseDetails?.course_details?.name} ?
                                    </h3>
                                    <p className="fw-400 fs-18 text-black mb-0">
                                        Your rating
                                    </p>
                                    <div className="star-rating">
                                        <Rating
                                            ratingValue={rating}
                                            size={30}
                                            transition
                                            fillColor="orange"
                                            emptyColor="gray"
                                            onClick={handleRating}
                                        />
                                    </div>
                                    <textarea
                                        rows="10"
                                        className="form-control pt-2 mt-3 ai-textarea"
                                        placeholder="Please tell us your reasons for giving this score here..."
                                        id="floatingTextarea2"
                                        defaultValue={""}
                                        onChange={(e)=> setFeedbackText(e.target.value)}
                                    />

                                    <div className="row justify-content-end mt-5">
                                        <div className="col-6 col-md-4 col-lg-5">
                                            <button
                                                type="button"
                                                onClick={sendFeedback}
                                                className="btn-explore p-sm-15 d-flex justify-content-between align-items-center gap-3 rounded-2 w-100 mt-4"
                                            >
                                                Send Feedback
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={22}
                                                    height={14}
                                                    viewBox="0 0 22 14"
                                                    fill="none"
                                                >
                                                    <path
                                                        d="M1 6.99854H21L16 1"
                                                        stroke="white"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path
                                                        d="M21 6.99805L16 12.9995"
                                                        stroke="white"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )}

            <Footer />
        </>
    );
};

export default Feedback;
