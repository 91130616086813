import React from 'react'
import { Link } from 'react-router-dom'

const CommunitySec = () => {
    return (
        <>
            <div className="row justify-content-between align-content-center g-0 pt-1 pt-md-2 pt-lg-3">
                <div className="col-lg-12 col-xl-6">
                    <div className="card border-0 shadow-none bg-black h-100 rounded-start-2 rounded-end-0 d-flex justify-content-center align-items-center">
                        <img
                            src="../unique-edge/learning1.png"
                            className="w-100"
                            alt=""
                        />
                    </div>
                </div>
                <div className="col-lg-12 col-xl-6">
                    <div className="card border-0 shadow-sm h-100 rounded-start-0 rounded-end-2 d-flex justify-content-center align-items-center">
                        <div className="card-body text-start p-4 p-md-5">
                            <div className="section-title">
                                <span className="d-block text-start">Our Community</span>
                                <h2 className="text-start mb-4">Cultivating Connections</h2>
                            </div>
                            <p className="fw-400 fs-6 text-muted mb-5 mb-md-3">
                                Engage in a delightful learning experience! Our courses are designed
                                to be both fun and effective, combining interactive elements and
                                gamified learning techniques to keep you motivated and excited
                                throughout your educational journey. Say goodbye to mundane lessons
                                and embrace a dynamic way to gain knowledge. Explore captivating
                                quizzes, interactive exercises, and engaging challenges that make
                                learning an enjoyable adventure.
                            </p>
                            <Link
                                to="/announcements"
                                type="button"
                                className="btn-primary gap-3 rounded-2 bg-primary position-relative"
                            >
                                Community
                                <span className="px-2">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={22}
                                        height={14}
                                        viewBox="0 0 22 14"
                                        fill="none"
                                    >
                                        <path
                                            d="M1 6.99854H21L16 1"
                                            stroke="white"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M21 6.99854L16 13"
                                            stroke="white"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                        />
                                    </svg>
                                </span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CommunitySec