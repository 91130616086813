import React, { useEffect, useState } from 'react'
import { CircularProgressbar } from 'react-circular-progressbar'

function Import({ percentage, lastActiveStep, isfileLode,progress,setProgress,setCurrentStep }) {

    const [targetProgress, setTargetProgress] = useState(null);

    
    useEffect(() => {
        let intervalId;
    
        // Function to get a random interval duration between min and max (inclusive)
        const getRandomInterval = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;
    
        // If we're on the correct step, the file is loading, and targetProgress hasn't been set, initialize it
        if (lastActiveStep === 3 && isfileLode && targetProgress === null) {
            const randomTarget = Math.floor(Math.random() * (90 - 80 + 1)) + 80;
            setTargetProgress(randomTarget);
            setProgress(1); // Start from 1% progress
        }
    
        // Conditionally set the interval for progress incrementation
        if (lastActiveStep === 3 && ((isfileLode && progress < targetProgress) || (!isfileLode && progress < 100))) {
            intervalId = setInterval(() => {
                setProgress((prevProgress) => prevProgress + 1);
            }, getRandomInterval(50, 200)); // Varying the interval speed randomly between 50ms to 200ms
        }
    
        // Cleanup function to clear the interval
        return () => clearInterval(intervalId);
    }, [isfileLode, progress, targetProgress, lastActiveStep]);
    

    useEffect(() => {
        // Check if progress has reached 100
        if (progress >= 100) {
            // Call setCurrentStep to move to step 4
            setCurrentStep(4);
        }
    }, [progress, setCurrentStep]);

    return (
        <>


            {lastActiveStep === 3 ? (<div className="row justify-content-center">
                <div className="col-11">
                    <h4 className='fw-600 text-bl text-center mb-3'>Importing your file</h4>
                    <p className='text-center text-muted fw-400 pb-3'>Please wait...</p>
                    <div className='d-flex justify-content-center'>
                        <div style={{ textAlign: "center" }}>
                            <div style={{ width: 200 }}>
                                <CircularProgressbar value={progress} text={`${progress}%`} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>) : (lastActiveStep === 4) ? (
                <div className="row justify-content-center">
                    <div className="col-lg-9 col-xl-7">
                        <h4 className='fw-600 text-bl text-center mb-3'>File successfully imported</h4>
                        <p className='text-center text-muted fw-400 mb-0'>Now you can assign courses to your employees. They will receive email notification.</p>
                        <div className="card border-0">
                            <div className="card-body px-0 px-md-4 d-flex justify-content-center">
                                <div className='main-animation position-relative'>
                                    <div class="circle1 animation-1"></div>
                                    <div class="circle2 animation-1"></div>
                                    <div class="circle3 animation-1"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                null // or some other default content if needed for other steps
            )
            }
        </>
    )
}

export default Import