import React from 'react'
import Header from '../../layout/Header'
import Footer from '../../layout/Footer'
import { Card, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

function FamilyUserFreeTrails() {

    const navigate = useNavigate()
    const courses = [
        {
            id: 1,
            title: "What to Know About Social Media",
            description: "Learn how to safely and effectively navigate social media for personal and professional use.",
            imgSrc: "/admin/shared-courses-1.png",
        },
        {
            id: 2,
            title: "What to Know About AI",
            description: "Discover the basics of artificial intelligence and its everyday applications.",
            imgSrc: "/admin/shared-courses-1.png",
        },
        {
            id: 3,
            title: "How to Improve My Communication Skills",
            description: "Develop better communication techniques to improve relationships and workplace interactions.",
            imgSrc: "/admin/shared-courses-1.png",
        },
    ];


     const handleNavigate = () => {
        navigate('/family-user-payment-details')
     }

    return (
        <>
            <Header />
            <section className='payment-flow-section'>
                <div className="container">
                    <div>
                        <h3 className='welcome-title max-width-100'>Free



                            <OverlayTrigger
                                placement="bottom"
                                overlay={
                                    <Tooltip id="tooltip-bottom">
                                        Trails are life long educational courses with fun. A trail is a combination of bronze courses and case studies for one topic. It will give you an overview about one topic (e.g. data privacy) and then you will receive further courses on this topic for you whole life time until you de-register from unicorso. It is life long education with fun.
                                    </Tooltip>
                                }
                            >

                                <span className="tooltip-hover px-1 fw-400 tooltip-text">
                                    trails
                                </span>
                            </OverlayTrigger>



                            for Kids</h3>
                        <p className='welcome-description plan-description'>Try our courses free of charge before committing to a paid subscription. Each trial includes 3 bronze courses and 3 scenarios, with 2 new courses and 2 case studies added annually.</p>

                        <Row className='free-trials-cards g-4'>
                            {courses.map(course => (
                                <Col md={6} lg={4} key={course.id}>
                                    <Card className='h-100'>
                                        <img src={course.imgSrc} height={177} alt="" />
                                        <Card.Body>
                                            <h3>{course.title}</h3>
                                            <p>{course.description}</p>
                                        </Card.Body>
                                        <Card.Footer className='bg-transparent border-0 pt-0'>
                                            <button className="btn-primary p-sm-15 rounded-2 w-100" onClick={handleNavigate}>
                                                Start Free Trial
                                            </button>
                                        </Card.Footer>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </div>
                    <div className='pt-5 pb-4 pb-lg-5'>
                        <h3 className='welcome-title max-width-100'>Free


                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip-top">
                                        Trails are life long educational courses with fun. A trail is a combination of bronze courses and case studies for one topic. It will give you an overview about one topic (e.g. data privacy) and then you will receive further courses on this topic for you whole life time until you de-register from unicorso. It is life long education with fun.
                                    </Tooltip>
                                }
                            >

                                <span className="tooltip-hover px-1 fw-400 tooltip-text">
                                    trails
                                </span>
                            </OverlayTrigger>




                            for Individuals</h3>
                        <p className='welcome-description plan-description'>Try our courses free of charge before committing to a paid subscription. With a free trial you will receive these trails:</p>

                        <Row className='free-trials-cards g-4'>
                            {courses.map(course => (
                                <Col md={6} lg={4} key={course.id}>
                                    <Card className='h-100'>
                                        <img src={course.imgSrc} height={177} alt="" />
                                        <Card.Body>
                                            <h3>{course.title}</h3>
                                            <p>{course.description}</p>
                                        </Card.Body>
                                        <Card.Footer className='bg-transparent border-0 pt-0'>
                                            <button className="btn-primary p-sm-15 rounded-2 w-100" onClick={handleNavigate}>
                                                Start Free Trial
                                            </button>
                                        </Card.Footer>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </div>
                </div>
            </section >
            <Footer />
        </>
    )
}

export default FamilyUserFreeTrails
