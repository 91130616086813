import React from 'react';
import { Accordion } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const SidbarCourses = () => {
    return (
        <>
            <ul className='ps-0'>
                {/* <li className="border-bottom-list">
                    <Link
                        to=""
                        className="d-flex justify-content-between align-items-center gap-3"
                    >
                        <p className="text-black fs-6 fw-400 mb-0">
                            Personal Development
                        </p>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={22}
                            height={16}
                            viewBox="0 0 22 16"
                            fill="none"
                        >
                            <path
                                d="M1 8H21L13 0.5"
                                stroke="#00237D"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M21 8L13 15.5"
                                stroke="#00237D"
                                strokeLinecap="round"
                            />
                        </svg>
                    </Link>
                </li>
                <li className="border-bottom-list">
                    <Link
                        to=""
                        className="d-flex justify-content-between align-items-center gap-3"
                    >
                        <p className="text-black fs-6 fw-400 mb-0">History</p>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={22}
                            height={16}
                            viewBox="0 0 22 16"
                            fill="none"
                        >
                            <path
                                d="M1 8H21L13 0.5"
                                stroke="#00237D"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M21 8L13 15.5"
                                stroke="#00237D"
                                strokeLinecap="round"
                            />
                        </svg>
                    </Link>
                </li>
                <li className="border-bottom-list">
                    <Link
                        to=""
                        className="d-flex justify-content-between align-items-center gap-3"
                    >
                        <p className="text-black fs-6 fw-400 mb-0">Legal Topics</p>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={22}
                            height={16}
                            viewBox="0 0 22 16"
                            fill="none"
                        >
                            <path
                                d="M1 8H21L13 0.5"
                                stroke="#00237D"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M21 8L13 15.5"
                                stroke="#00237D"
                                strokeLinecap="round"
                            />
                        </svg>
                    </Link>
                </li>
                <li className="border-bottom-list">
                    <Link
                        to=""
                        className="d-flex justify-content-between align-items-center gap-3"
                    >
                        <p className="text-black fs-6 fw-400 mb-0">IT Security</p>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={22}
                            height={16}
                            viewBox="0 0 22 16"
                            fill="none"
                        >
                            <path
                                d="M1 8H21L13 0.5"
                                stroke="#00237D"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M21 8L13 15.5"
                                stroke="#00237D"
                                strokeLinecap="round"
                            />
                        </svg>
                    </Link>
                </li>
                <li className="border-bottom-list">
                    <Link
                        to=""
                        className="d-flex justify-content-between align-items-center gap-3"
                    >
                        <p className="text-black fs-6 fw-400 mb-0">Philosophy</p>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={22}
                            height={16}
                            viewBox="0 0 22 16"
                            fill="none"
                        >
                            <path
                                d="M1 8H21L13 0.5"
                                stroke="#00237D"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M21 8L13 15.5"
                                stroke="#00237D"
                                strokeLinecap="round"
                            />
                        </svg>
                    </Link>
                </li>
                <li className="border-bottom-list">
                    <Link
                        to=""
                        className="d-flex justify-content-between align-items-center gap-3"
                    >
                        <p className="text-black fs-6 fw-400 mb-0">Technology</p>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={22}
                            height={16}
                            viewBox="0 0 22 16"
                            fill="none"
                        >
                            <path
                                d="M1 8H21L13 0.5"
                                stroke="#00237D"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M21 8L13 15.5"
                                stroke="#00237D"
                                strokeLinecap="round"
                            />
                        </svg>
                    </Link>
                </li>
                <li className="border-bottom-list">
                    <Link
                        to="/gold-courses"
                        className="d-flex justify-content-between align-items-center gap-3"
                    >
                        <p className="text-black fs-6 fw-400 mb-0">Compilance</p>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={22}
                            height={16}
                            viewBox="0 0 22 16"
                            fill="none"
                        >
                            <path
                                d="M1 8H21L13 0.5"
                                stroke="#00237D"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M21 8L13 15.5"
                                stroke="#00237D"
                                strokeLinecap="round"
                            />
                        </svg>
                    </Link>
                </li>
                <li className="border-bottom-list">
                    <Link
                        to=""
                        className="d-flex justify-content-between align-items-center gap-3"
                    >
                        <p className="text-black fs-6 fw-400 mb-0">
                            Employment Law and HR
                        </p>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={22}
                            height={16}
                            viewBox="0 0 22 16"
                            fill="none"
                        >
                            <path
                                d="M1 8H21L13 0.5"
                                stroke="#00237D"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M21 8L13 15.5"
                                stroke="#00237D"
                                strokeLinecap="round"
                            />
                        </svg>
                    </Link>
                </li>
                <li className="border-bottom-list">
                    <Link
                        to=""
                        className="d-flex justify-content-between align-items-center gap-3"
                    >
                        <p className="text-black fs-6 fw-400 mb-0">Startup Topics</p>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={22}
                            height={16}
                            viewBox="0 0 22 16"
                            fill="none"
                        >
                            <path
                                d="M1 8H21L13 0.5"
                                stroke="#00237D"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M21 8L13 15.5"
                                stroke="#00237D"
                                strokeLinecap="round"
                            />
                        </svg>
                    </Link>
                </li>
                <li className="border-bottom-list">
                    <Link
                        to=""
                        className="d-flex justify-content-between align-items-center gap-3"
                    >
                        <p className="text-black fs-6 fw-400 mb-0">
                            Personal Well-being
                        </p>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={22}
                            height={16}
                            viewBox="0 0 22 16"
                            fill="none"
                        >
                            <path
                                d="M1 8H21L13 0.5"
                                stroke="#00237D"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M21 8L13 15.5"
                                stroke="#00237D"
                                strokeLinecap="round"
                            />
                        </svg>
                    </Link>
                </li>
                <li className="border-bottom-list">
                    <Link
                        to=""
                        className="d-flex justify-content-between align-items-center gap-3"
                    >
                        <p className="text-black fs-6 fw-400 mb-0">
                            Marketing and Sales
                        </p>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={22}
                            height={16}
                            viewBox="0 0 22 16"
                            fill="none"
                        >
                            <path
                                d="M1 8H21L13 0.5"
                                stroke="#00237D"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M21 8L13 15.5"
                                stroke="#00237D"
                                strokeLinecap="round"
                            />
                        </svg>
                    </Link>
                </li>
                <li>
                    <Link
                        to=""
                        className="d-flex justify-content-between align-items-center gap-3"
                    >
                        <p className="text-black fs-6 fw-400 mb-0">Social Issues</p>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={22}
                            height={16}
                            viewBox="0 0 22 16"
                            fill="none"
                        >
                            <path
                                d="M1 8H21L13 0.5"
                                stroke="#00237D"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M21 8L13 15.5"
                                stroke="#00237D"
                                strokeLinecap="round"
                            />
                        </svg>
                    </Link>
                </li> */}

                <Accordion className='sidebar-category-main' defaultActiveKey="0">
                    <Accordion.Item eventKey="0" className='border-0'>
                        <Accordion.Header>Compliance</Accordion.Header>
                        <Accordion.Body className='p-0'>
                            <p className='p-3 mb-0'>Core Compliance</p>
                            <p className='p-3 accordion-item-border-top-2 mb-0'>Data Protection Compliance</p>
                            <p className='p-3 accordion-item-border-top-2 mb-0'>Other Compliance and Law</p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1" className='border-0 accordion-item-border-top'>
                        <Accordion.Header>Skills</Accordion.Header>
                        <Accordion.Body className='p-0'>
                            <p className='p-3 mb-0'>Business Skills</p>
                            <p className='p-3 accordion-item-border-top-2 mb-0'>Tech Skills</p>
                            <p className='p-3 accordion-item-border-top-2 mb-0'>Personal Development Skills</p>
                            <p className='p-3 accordion-item-border-top-2 mb-0'>Startup Skills</p>
                            <p className='p-3 accordion-item-border-top-2 mb-0'>Leadership Skills</p>
                            <p className='p-3 accordion-item-border-top-2 mb-0'>Other Skills</p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2" className='border-0 accordion-item-border-top'>
                        <Accordion.Header className='text-wrap'>Fun/General Knowledge/ <br /> Miscellaneous</Accordion.Header>
                        <Accordion.Body className='p-0'>
                            <p className='p-3 mb-0'>General Knowledge</p>
                            <p className='p-3 accordion-item-border-top-2 mb-0'>World Knowledge</p>
                            <p className='p-3 accordion-item-border-top-2 mb-0'>History Knowledge</p>
                            <p className='p-3 accordion-item-border-top-2 mb-0'>Courses for Kids</p>
                            <p className='p-3 accordion-item-border-top-2 mb-0'>Courses for Parents</p>
                            <p className='p-3 accordion-item-border-top-2 mb-0'>Courses for Teachers</p>
                            <p className='p-3 accordion-item-border-top-2 mb-0'>Animals</p>
                            <p className='p-3 accordion-item-border-top-2 mb-0'>Art</p>
                            <p className='p-3 accordion-item-border-top-2 mb-0'>Entertainment</p>
                            <p className='p-3 accordion-item-border-top-2 mb-0'>Sports</p>
                            <p className='p-3 accordion-item-border-top-2 mb-0'>Other</p>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </ul>
        </>
    );
}

export default SidbarCourses;
