import React, { useRef, useState, useEffect, useContext } from 'react'
import AdminHeader from './../layout/AdminHeader';
import Footer from '../layout/Footer';
import CustomerSidbar from '../components/CustomerSidbar';
import Nav from 'react-bootstrap/Nav';
import MiniGears from '../components/MiniGears';
import Bronze from '../components/Bronze';
import Silver from '../components/Silver';
import Gold from '../components/Gold';
import { getServerURL } from '../helper/envConfig';
import { CourseContext } from '../context/CourseContext';
import api from '../helper/api';
import MyBookmarks from '../components/MyBookmarks';
import CourseNotFound from '../components/CourseNotFound';


function CustomerBookmarks() {

    const { loder, setLoder, is_search, setIs_search, getSearchCourses, setSearch, searchex, setSearchex, listCourse, currentPage, setCurrentPage, pageSize, setPageSize, getCourses, mainLoder, setMainLoder } = useContext(CourseContext);

    const [activeTab, setActiveTab] = useState('bronze');
    const [carouselItems, setCarouselItems] = useState([]);
    const [typeCorseList, setTypeCorseList] = useState([]);
    const [loderFilter, setLoderFilter] = useState(false);
    const serverURL = getServerURL();

    const handleNavItemClick = (eventKey) => {
        setActiveTab(eventKey);
    };

    useEffect(() => {
        getCoursesFilter()
    }, [currentPage, pageSize, activeTab]);

    const getCoursesFilter = async () => {

        try {
            setLoderFilter(true)
            const response = await api.getWithToken(`${serverURL}courses/course-my-bookmark-courses/?page_size=${pageSize}&type__name=${activeTab}&page=${currentPage}`);
            setTypeCorseList(response.data);
            // setPageSize
            // setCurrentPage
            setLoderFilter(false)
        } catch (error) {
            // if(search || searchex) toast.error(error.response?.data?.message);
            // setListCourse([])
            setTypeCorseList([])
            console.log(error);
            setLoderFilter(false)
        }
        
    };


    return (
        <>
            <AdminHeader />
            <section>
                <div className="dashbaord-home-main">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-3 col-md-4 col-lg-3 col-xxl-3 d-none d-xl-block px-4 bg-white sidebar-mian">
                                <CustomerSidbar />
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 col-xxl-9 bg-body">
                                <div className="row ps-0 ps-lg-4 pe-lg-4 ps-xl-5 dashbaord-right-side justify-content-md-center justify-content-lg-between pt-5">
                                    <div className="col-12 col-md-11 col-lg-12 right-side">
                                        <h2 className="fw-600 text-black mb-4">Favorite Courses</h2>
                                        <div className="courses-tabs-bar mb-3">
                                            <Nav variant="pills" className="overflow-x-scroll d-flex align-items-center gap-3 flex-nowrap w-100 bg-transparent" activeKey={activeTab} onSelect={handleNavItemClick}>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="bronze">Bronze</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="silver">Silver</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="gold">Gold</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </div>
                                                                                {/* {activeTab === 'mini-courses' && <MiniGears />} */}
                                        {/* {activeTab === 'bronze' && <Bronze />}
                                        {activeTab === 'silver' && <Silver />}
                                        {activeTab === 'gold' && <Gold />} */}
                                        {typeCorseList?.results?.length > 0 ?

                                        <MyBookmarks loderFilter={loderFilter} data={typeCorseList} />
                                        :
                                        // Show "Course Not Found"
                                        <CourseNotFound />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            <Footer />
        </>
    )
}

export default CustomerBookmarks