import { Navigate, useLocation, React } from 'react-router-dom';
import { decryptFromStoreLoc } from './localStore';
function ProtecdtedOtp({ children, ...rest }) {
    let location = useLocation();
    // Retrieve and check stored answers
    const storedData = decryptFromStoreLoc('user')
    const hasQuizId = storedData && storedData.user_id;

    return hasQuizId ? children : <Navigate to="/" state={{ from: location }} replace />;
}

export default ProtecdtedOtp;



