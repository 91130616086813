import { useEffect, useState } from "react";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import { Card, Col, Row } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { decryptFromStoreLoc, encryptAndStoreLoc } from "../../helper/localStore";
import api from "../../helper/api";
import { getImageBaseUrl, getServerURL } from "../../helper/envConfig";
import { toast } from "react-toastify";

function BusinessUserCourseTrail() {
    const location = useLocation();
    const navigate = useNavigate();
    const serverURL = getServerURL();
    const imageUrl = getImageBaseUrl()
    const [user, setUser] = useState({});
    const [trailsData, setTrailsData] = useState([]);
    const trailsData2 = [
        {
            id: 1,
            title: "Data Privacy",
            description: "How to keep personal data safe",
            imageSrc: "/png/trails-1.png",
        },
        {
            id: 2,
            title: "Information Security",
            description: "How to keep company information safe",
            imageSrc: "/png/trails-2.png",
        },
        {
            id: 3,
            title: "Compliance",
            description: "How to stay compliant as a company",
            imageSrc: "/png/trails-3.png",
        },
        {
            id: 4,
            title: "Sustainability",
            description: "How to stay sustainable as a company",
            imageSrc: "/png/trails-4.png",
        },
        {
            id: 5,
            title: "What should we do",
            description: "What should we do",
            imageSrc: "/png/trails-5.png",
        },
        {
            id: 6,
            title: "Mental health",
            description: "How to stay sane",
            imageSrc: "/png/trails-6.png",
        },
        {
            id: 7,
            title: "Diversity",
            description: "How to have a diverse work force",
            imageSrc: "/png/trails-7.png",
        },
        {
            id: 8,
            title: "Communication",
            description: "How to communicate well",
            imageSrc: "/png/trails-8.png",
        },
        {
            id: 9,
            title: "Happiness",
            description: "How to be happy",
            imageSrc: "/png/trails-9.png",
        },
        {
            id: 10,
            title: "Health",
            description: "How to live a healthy life",
            imageSrc: "/png/trails-10.png",
        },
        {
            id: 11,
            title: "Time Management",
            description: "How to work effectively",
            imageSrc: "/png/trails-11.png",
        },
        {
            id: 12,
            title: "Wealth",
            description: "How to be wealthy",
            imageSrc: "/png/trails-12.png",
        },
        {
            id: 13,
            title: "Career",
            description: "How to progress in your job",
            imageSrc: "/png/trails-13.png",
        },
        {
            id: 14,
            title: "Confidence",
            description: "How to be confident",
            imageSrc: "/png/trails-14.png",
        },
        {
            id: 15,
            title: "Food",
            description: "How to have healthy eating habits",
            imageSrc: "/png/trails-15.png",
        },
        {
            id: 16,
            title: "Social Media",
            description: "How to master social media safely",
            imageSrc: "/png/trails-16.png",
        },
        // Add more items as needed
    ];
    const [activeTrails, setActiveTrails] = useState([]);

    const getTrails = async () => {
        try {
            var response = await api.get(
                `${serverURL}courses/course-trails/`
            );
            if(response.data.success) {
                setTrailsData(response.data.data);
            } else {
                console.error("Error:", response.data.message);
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    useEffect(() => {
        getTrails()
    }, []);

    useEffect(() => {
        const decryptedInfoResult = decryptFromStoreLoc("user");
        setUser(decryptedInfoResult);
    }, []);

    // const handleCheckboxChange = (id) => {
    //     if (activeTrails.includes(id)) {
    //         setActiveTrails(activeTrails.filter((trailId) => trailId !== id));
    //     } else {
    //         if (activeTrails.length < 1 && location.state.name === "one") {
    //             setActiveTrails([...activeTrails, id]);
    //         }
    //         if (activeTrails.length < 2 && location.state.name === "two") {
    //             setActiveTrails([...activeTrails, id]);
    //         }
    //     }
    // };

    const handleCheckboxChange = (id) => {
        if (activeTrails.includes(id)) {
            // If the checkbox is already checked, remove it
            setActiveTrails(activeTrails.filter((trailId) => trailId !== id));
        } else {
            // If the checkbox is not checked
            if (location.state.name === "one") {
                if (activeTrails.length >= 1) {
                    // Remove the first trail if already one is selected
                    setActiveTrails([id]);
                } else {
                    // If no trail is selected, just add the new one
                    setActiveTrails([...activeTrails, id]);
                }
            } else if (location.state.name === "two") {
                if (activeTrails.length >= 2) {
                    // If there are already two trails, replace the first one with the new one
                    setActiveTrails([...activeTrails.slice(1), id]);
                } else {
                    // If less than two trails are selected, just add the new one
                    setActiveTrails([...activeTrails, id]);
                }
            }
        }
    };

    const handleNavigate = async () => {
        if(location?.state?.name === "one") {
            if(activeTrails.length === 0) {
                toast.error("Please select one trail");
                return;
            }
        }

        if(location?.state?.name === "two") {
            if(activeTrails.length !== 2) {
                toast.error("Please select two trail");
                return;
            }
        }


        if (user.role === 13) {
            navigate("/family-blended-learning", {
                state: {
                    name: location?.state?.name,
                    plan: location?.state?.plan,
                    userPlan: location?.state?.userPlan,
                    courseTrails: activeTrails,
                },
            });
        }

        if (user.role === 12) {
            if (location?.state?.name === "one") {
                try {
                    let obj = {
                        plan_type : "one-one",
                        price: 0,
                        duration: 0,
                        trail_ids: [],
                        duration_type: "",
                        total_amount: 0,
                        description: "Tier One - One Trails",
                    };
                    obj.trail_ids = activeTrails;
                    obj.total_amount = 0;
                    obj.description = location?.state?.plan;

                    var response = await api.postWithToken(
                        `${serverURL}payments/payment/`,
                        obj
                    );
                    if (response.data.success) {
                        const paymentData = { status: true }
                        encryptAndStoreLoc("paymentData", paymentData)
                        toast.success(response.data.message)
                        setTimeout(() => {
                            navigate("/business-home", { replace: true });
                            window.location.reload()
                        }, 1000);
                    } else {
                        console.error("Error:", response.data.message);
                    }
                } catch (error) {
                    console.error("Error creating payment object", error);
                }

            } else {
                navigate("/business-support-plans", {
                    state: {
                        name: location?.state?.name,
                        plan: location?.state?.plan,
                        userPlan: location?.state?.userPlan,
                        courseTrails: activeTrails,
                    },
                });
            }
        }

        if (user.role === 2) {
            if (location?.state?.name === "one") {
                try {
                    let obj = {
                        plan_type : "one-one",
                        price: location?.state?.userPlan?.subscriptionAmount,
                        duration: 1,
                        trail_ids: [],
                        duration_type: "year",
                        total_amount: 0,
                        description: "Tier Two - Two Trails",
                    };
                    obj.trail_ids = activeTrails;
                    obj.total_amount = 0;
                    obj.description = location?.state?.plan;

                    const response = await api.postWithToken(
                        `${serverURL}payments/payment/`,
                        obj
                    );
                    if (response.data.success) {
                        const paymentData = { status: true }
                        encryptAndStoreLoc("paymentData", paymentData)
                        toast.success(response.data.message)
                        setTimeout(() => {
                            navigate("/customer-home");
                            window.location.reload()
                        }, 1000);
                    } else {
                        console.error("Error:", response.data.message);
                    }
                } catch (error) {
                    console.error("Error creating payment object", error);
                }

            } else {
                navigate("/individual-payment-details", {
                    state: {
                        name: location?.state?.name,
                        plan: location?.state?.plan,
                        userPlan: location?.state?.userPlan,
                        courseTrails: activeTrails,
                    },
                });
            }
        }

        // if(location.state.name === "one") {
        //     navigate("/customer-home")
        // }
        // if(location.state.name === "two") {
        //     navigate("/individual-payment-details",{
        //         state: {
        //             name: "two",
        //             plan: "Select two course trails",
        //         }
        //     })
        // }
        // if(location.state.name === "all") {
        //     navigate("/individual-payment-details",{
        //         state: {
        //             name: "all",
        //             plan: "Select three course trails",
        //         }
        //     })
        // }
    };

    return (
        <>
            <Header />
            <section className="payment-flow-section">
                <div className="container">
                    <div className="available-trails pb-md-5 pt-0">
                        <h3 className="welcome-title max-width-100">
                            {location?.state?.plan}
                        </h3>
                        {location.state.name === "one" && (
                            <>
                                {user.role === 2 && (
                                    <p className="welcome-description plan-description">
                                        You have selected Tier One - One trail.
                                        Select one course trail which you want
                                        to take.
                                    </p>
                                )}
                                {user.role === 12 && (
                                    <p className="welcome-description plan-description">
                                        You have selected Tier One - One trail.
                                        Select one course trail which you want
                                        your employees should take.
                                    </p>
                                )}
                            </>
                        )}
                        {location.state.name === "two" && (
                            <>
                                {user.role === 2 && (
                                    <p className="welcome-description plan-description">
                                        You have selected Tier Two - Two trails.
                                        Select two course trails which you want
                                        to take.
                                    </p>
                                )}
                                {user.role === 12 && (
                                    <p className="welcome-description plan-description">
                                        You have selected Tier Two - Two trails.
                                        Select two course trails which you want
                                        your employees should take
                                    </p>
                                )}
                            </>
                        )}

                        <Row className="available-trails-cards g-3">
                            {trailsData.map((trail) => (
                                <Col key={trail.trail_id} md={6} lg={6} xl={4}>
                                    <Card
                                        className={
                                            activeTrails.includes(trail.trail_id)
                                                ? "active"
                                                : ""
                                        }
                                    >
                                        <Card.Body>
                                            <div className="d-flex align-items-center gap-3">
                                                <div className="manage-family-checkbox">
                                                    <div className="form-check mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            value={trail.trail_id}
                                                            id={`flexCheck${trail.trail_id}`}
                                                            name="trail"
                                                            onChange={() =>
                                                                handleCheckboxChange(
                                                                    trail.trail_id
                                                                )
                                                            }
                                                            checked={activeTrails.includes(
                                                                trail.trail_id
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <img
                                                    width={100}
                                                    height={100}
                                                    src={imageUrl + trail.icon}
                                                    alt={trail.name}
                                                />
                                                <div>
                                                    <h5>{trail.name}</h5>
                                                    <p>{trail.description}</p>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                        <div className="d-flex justify-content-end pt-4 pb-5">
                            <button
                                to="/processed"
                                className="btn-explore p-sm-15 d-flex justify-content-between align-items-center gap-3 rounded-2 mt-4"
                                onClick={handleNavigate}
                            >
                                Proceed
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={22}
                                    height={14}
                                    viewBox="0 0 22 14"
                                    fill="none"
                                    className="ms-5"
                                >
                                    <path
                                        d="M1 6.99854H21L16 1"
                                        stroke="white"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M21 6.99805L16 12.9995"
                                        stroke="white"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default BusinessUserCourseTrail;
