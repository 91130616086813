import React, { useEffect, useState } from 'react';
import AdminLayout from '../../components/Admin/AdminLayout'
import DatePicker from '../../components/DatePicker'
import { Table, Modal, Button } from 'react-bootstrap';
import SendCommunication from '../../components/Admin/UserManagement/SendCommunication';
import SelectUserStatusModal from '../../components/Admin/UserManagement/SelectUserStatusModal';
import SelectRoleModal from '../../components/Admin/UserManagement/SelectRoleModal';
import ExportDataModal from '../../components/Admin/UserManagement/ExportDataModal';
import UpdatePermissions from '../../components/Admin/UserManagement/UpdatePermissions';
import { getServerURL } from '../../helper/envConfig';
import api from '../../helper/api';
import moment from 'moment/moment';


function Compliance() {
    const serverURL = getServerURL();
    const [users, setUsers] = useState([])
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [totalUsers, setTotalUsers] = useState(0)
    const [totalPages, setTotalPages] = useState(0)
    const [roles, setRoles] = useState([])
    const [isActive, setIsActive] = useState(true)
    const [search, setSearch] = useState('')
    const [userStatusFilter, setUserStatusFilter] = useState()
    const [filteredDate, setFilteredDate] = useState()
    const [searchFilterText, setSearchFilterText] = useState("")
    const [filterRoles, setFilterRoles] = useState(null)
    const [shortBy, setShortBy] = useState("")
    const [showRegFilterModel, setShowRegFilterModel] = useState(false)
    const [showUserStatusModel, setShowUserStatusFilterModel] = useState(false)
    const [showUserRoleFilterModel, setShowUserRoleFilterModel] = useState(false)
    const [showLastActiveFilterModel, setShowLastActiveFilterModel] = useState(false)
    const [activeItems, setActiveItems] = useState({});
    const [selectedUsers, setSelectedUsers] = useState([])
    const [allSelected, setAllSelected] = useState(false)


    const getUserList = async () => {
        try {
            const queryParams = new URLSearchParams({
                page,
                page_size: pageSize,
                ...(filteredDate
                    ? {
                        form_date: moment(filteredDate.startDate).format("YYYY-MM-DD"),
                        to_date: moment(filteredDate.endDate).format("YYYY-MM-DD"),
                    }
                    : {}),
                ...(searchFilterText ? { search: searchFilterText } : {}),
                ...(shortBy ? { ordering: shortBy } : {}),
                ...(filterRoles ? { roles: filterRoles } : {}),
            }).toString();
            const response = await api.getWithToken(`${serverURL}admin-panel/user-management/users-list?${queryParams}`);
            setUsers(response.data.results.users);
            setTotalUsers(response.data.total)
            setTotalPages(response.data.total_pages)
        } catch (error) {
            setUsers([]);
            console.error(error);
        }
    };

    useEffect(() => {
        getUserList()
    }, [page, filteredDate?.startDate, filteredDate?.endDate, searchFilterText, shortBy, filterRoles])



    const getUserRoles = async () => {
        try {
            const response = await api.getWithToken(`${serverURL}admin-panel/user-management/roles-list`);
            setRoles(response.data.data);
        } catch (error) {
            setRoles([]);
            console.error(error);
        }
    }

    useEffect(() => {
        getUserRoles()
    }, [])

    const handleToggle = (key) => {
        if (key === "registration") {
            setShowRegFilterModel(!showRegFilterModel);
            setShowLastActiveFilterModel(false);
            setShowUserStatusFilterModel(false);
            setShowUserRoleFilterModel(false)

            setActiveItems({
                registration: true,
            });
        } else if (key === "userStatus") {
            setShowUserStatusFilterModel(!showUserStatusModel);
            setShowRegFilterModel(false)
            setShowUserRoleFilterModel(false)
            setShowLastActiveFilterModel(false)
            setActiveItems({
                userStatus: true,
            });
        } else if (key === "userRole") {
            setShowUserRoleFilterModel(!showUserRoleFilterModel);
            setShowRegFilterModel(false)
            setShowUserStatusFilterModel(false);
            setShowLastActiveFilterModel(false)
            setActiveItems({
                userRole: true,
            });
        } else if (key === "LastActive") {
            setShowLastActiveFilterModel(!showLastActiveFilterModel);
            setShowUserStatusFilterModel(false);
            setShowRegFilterModel(false)
            setShowUserRoleFilterModel(false)
            setActiveItems({
                LastActive: true,
            });
        }
    };

    // const handleClickOutside = (event) => {
    //     Object.values(containerRefs).forEach((ref) => {
    //         if (ref.current && !ref.current.contains(event.target)) {
    //             setActiveItems((prevActiveItems) => ({
    //                 ...prevActiveItems,
    //                 [Object.keys(containerRefs).find(key => ref.current === containerRefs[key].current)]: false,
    //             }));
    //         }
    //     });
    // };

    // useEffect(() => {
    //     document.addEventListener('click', handleClickOutside);
    //     return () => {
    //         document.removeEventListener('click', handleClickOutside);
    //     };
    // }, []);


    // Handle checkbox change
    const handleCheckboxChange = (id) => {
        setSelectedUsers((prevCheckedRows) => {
            if (prevCheckedRows.includes(id)) {
                // If already checked, remove it from the list
                return prevCheckedRows.filter((rowId) => rowId !== id);
            } else {
                // If not checked, add it to the list
                return [...prevCheckedRows, id];
            }
        });
    };

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            setSelectedUsers(users.map((user) => user.user_id))
            setAllSelected(true)
        } else {
            setSelectedUsers([])
            setAllSelected(false)
        }
    }

    const handleSearchText = (e) => {
        setSearch(e.target.value)
    }

    const onPressEnterKey = (e) => {
        if (e.key === 'Enter') {
            setSearchFilterText(search.trim())
        }
    }

    const handleNextPage = () => {
        if (totalPages > page) {
            setPage(page + 1);
        }
    }

    const handlePrevPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    }

    const handleShortBy = (field) => {
        if (field === shortBy) {
            setShortBy("-" + field)
        } else {
            setShortBy(field)
        }
    }

    const handleResetFilter = () => {
        setPage(1);
        setFilteredDate(null);
        setUserStatusFilter(null)
        setSearch("")
        setSearchFilterText("")
        setShortBy("")
        setFilterRoles(null)
        setSelectedUsers([])
        setAllSelected(false)
    }


    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <AdminLayout>
            <h2 className='page-title'>Compliance</h2>
            <div className="row mt-5">
                <div className="col-12 col-md-6">
                    <div className='table-search-input d-flex align-items-center gap-1'>
                        <img src="./admin/search-dark.png" alt="" />
                        <input type="text" className='form-control border-0' placeholder='Search by ID, name, or others...' onChange={handleSearchText} onKeyDown={onPressEnterKey} />
                    </div>
                </div>
            </div>
            <div className='overflow-auto overflow-y-hidden'>
                <div className='user-management-scroll'>
                    <div className='table-top-filter-box mt-4 d-inline-flex'>
                        <div className='p-4 border-right-200'>
                            <img src="./admin/filter.png" width={29} height={29} alt="" />
                        </div>
                        <div className='p-4 border-right-200'>
                            <p className='mb-0 fs-14 fw-500'>Filter By</p>
                        </div>

                        <div
                            className='p-4 border-right-200 filter-items'
                        >
                            <p
                                className={activeItems.registration ? 'mb-0 fs-14 fw-500 active text-light-200  d-flex align-items-center gap-3 cursor-pointer' : 'mb-0 fs-14 fw-500  d-flex align-items-center gap-3 cursor-pointer'}
                                onClick={() => handleToggle('registration')}
                            >
                                Score Range
                                <img className={activeItems.registration ? 'd-none' : 'd-block'} src="./admin/bottom-arrow.png" alt="" />
                                <img className={activeItems.registration ? 'd-block' : 'd-none'} src="./admin/bottom-arrow-dark.png" alt="" />
                            </p>
                            {showRegFilterModel && <div className={activeItems.registration ? 'active' : ''}>
                                <DatePicker setFilteredDate={setFilteredDate} />
                            </div>}
                        </div>

                        <div
                            className='p-4 border-right-200 d-flex align-items-center gap-3 select-user-status-main position-relative'
                        >
                            <p
                                className={activeItems.userStatus ? 'mb-0 fs-14 fw-500 active text-light-200  d-flex align-items-center gap-3 cursor-pointer' : 'mb-0 fs-14 fw-500  d-flex align-items-center gap-3 cursor-pointer'}
                                onClick={() => handleToggle('userStatus')}
                            >
                                Industry
                                <img className={activeItems.userStatus ? 'd-none' : 'd-block'} src="./admin/bottom-arrow.png" alt="" />
                                <img className={activeItems.userStatus ? 'd-block' : 'd-none'} src="./admin/bottom-arrow-dark.png" alt="" />
                            </p>
                            {showUserStatusModel && <div className={activeItems.userStatus ? 'active' : ''}>
                                <SelectUserStatusModal setUserStatusFilter={setUserStatusFilter} />
                            </div>}
                        </div>

                        <div
                            className='p-4 border-right-200 d-flex align-items-center gap-3 select-user-status-main position-relative'
                        >
                            <p
                                className={activeItems.userRole ? 'mb-0 fs-14 fw-500 active text-light-200  d-flex align-items-center gap-3 cursor-pointer' : 'mb-0 fs-14 fw-500  d-flex align-items-center gap-3 cursor-pointer'}
                                onClick={() => handleToggle('userRole')}
                            >
                                Region
                                <img className={activeItems.userRole ? 'd-none' : 'd-block'} src="./admin/bottom-arrow.png" alt="" />
                                <img className={activeItems.userRole ? 'd-block' : 'd-none'} src="./admin/bottom-arrow-dark.png" alt="" />
                            </p>
                            {showUserRoleFilterModel && <div className={activeItems.userRole ? 'active' : ''}>
                                <SelectRoleModal roles={roles} setFilterRoles={setFilterRoles} />
                            </div>}
                        </div>
                        <div
                            className='p-4 border-right-200 d-flex align-items-center gap-3 filter-items'
                        >
                            <p
                                className={activeItems.LastActive ? 'mb-0 fs-14 fw-500 active text-light-200  d-flex align-items-center gap-3 cursor-pointer' : 'mb-0 fs-14 fw-500  d-flex align-items-center gap-3 cursor-pointer'}
                                onClick={() => handleToggle('LastActive')}
                            >
                                Compliance Status
                                <img className={activeItems.LastActive ? 'd-none' : 'd-block'} src="./admin/bottom-arrow.png" alt="" />
                                <img className={activeItems.LastActive ? 'd-block' : 'd-none'} src="./admin/bottom-arrow-dark.png" alt="" />
                            </p>
                            {showLastActiveFilterModel && <div className={activeItems.LastActive ? 'active' : ''}>
                                <DatePicker />
                            </div>}
                        </div>
                        <div className='p-4 d-flex align-items-center gap-3 cursor-pointer' onClick={handleResetFilter}>
                            <img src="./admin/reset-filter.png" alt="" />
                            <p className='mb-0 fs-14 fw-500 reset-filter'>Reset Filter</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-xl-6">
                            <div className='user-management-table mt-4 overflow-auto'>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>
                                                <div className='d-flex align-items-center gap-2 pb-1 cursor-pointer' onClick={() => handleShortBy("username")}>
                                                    <p>Company Name</p> <img src="./admin/arrows-down-up.png" alt="" />
                                                </div>
                                            </th>
                                            <th>
                                                <div className='d-flex align-items-center gap-2 pb-1 cursor-pointer' onClick={() => handleShortBy("email")}>
                                                    <p>Compliance Score</p> <img src="./admin/arrows-down-up.png" alt="" />
                                                </div>
                                            </th>
                                            <th>
                                                <div className='d-flex align-items-center gap-2 pb-1 cursor-pointer' onClick={() => handleShortBy("role")}>
                                                    <p>Rank Adjustment</p> <img src="./admin/arrows-down-up.png" alt="" />
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {users && users.length > 0 && users?.map((item, index) => (
                                            <tr>
                                                <td>{item.email}</td>
                                                <td>{item.role}</td>
                                                <td>
                                                    <button className='adjust-btn' onClick={handleShow}>Adjust</button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                            <div className='admin-table-pagination'>
                                <p>Showing {(page - 1) * pageSize + 1}-{Math.min(page * pageSize, totalUsers)} of {totalUsers}</p>
                                <div className='admin-table-pagination-arrows'>
                                    <div className='pagination-arrows-box' onClick={handlePrevPage}>
                                        <img src="./admin/pagination-left-arrow.png" alt="" />
                                    </div>
                                    <div className='pagination-arrows-box' onClick={handleNextPage}>
                                        <img src="./admin/pagination-right-arrow.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={show} centered size='lg' className='adjust-modal' onHide={handleClose}>
                <Modal.Header closeButton className='border-0 pb-0'>
                </Modal.Header>
                <Modal.Body className='p-4'>
                    <h5 className='mb-4'>ABC Corp</h5>
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <p>Compliance Overview</p>
                            <p className='current-score-title'>Current Score: <span>85</span></p>
                        </div>
                        <div className="col-12 col-md-4">
                            <p>Current Rank</p>
                            <input type='text' className='current-score' placeholder='00' />
                        </div>
                    </div>

                    <div className='user-management-table mt-4 overflow-auto'>
                        <Table>
                            <thead>
                                <tr>
                                    <th>
                                        <div className='d-flex align-items-center gap-2 pb-1 cursor-pointer'>
                                            <p className='text-capitalize'>Metric Name</p>
                                        </div>
                                    </th>
                                    <th>
                                        <div className='d-flex align-items-center gap-2 pb-1 cursor-pointer'>
                                            <p className='text-capitalize'>Current Value</p>
                                        </div>
                                    </th>
                                    <th>
                                        <div className='d-flex align-items-center gap-2 pb-1 cursor-pointer'>
                                            <p className='text-capitalize'>Target Value</p>
                                        </div>
                                    </th>
                                    <th>
                                        <div className='d-flex align-items-center gap-2 pb-1 cursor-pointer'>
                                            <p className='text-capitalize'>Status</p>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Training Completion Rate</td>
                                    <td>95%</td>
                                    <td>100%</td>
                                    <td>
                                        On Track
                                    </td>
                                </tr>
                                <tr>
                                    <td>Audit Results</td>
                                    <td>85%</td>
                                    <td>90%</td>
                                    <td width={150}>
                                        Needs Improvement
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>

                    <div className='d-flex justify-content-end gap-3 pricing-management-buttons pt-5'>
                        <button>Cancel</button>
                        <button>Save</button>
                    </div>
                </Modal.Body>
            </Modal>
        </AdminLayout>
    )
}

export default Compliance