import React from 'react'
import AdminLayout from '../../components/Admin/AdminLayout'
import MarketingColumnChart from '../../components/Admin/Marketing/MarketingColumnChart'
import MarketingLineChart from '../../components/Admin/Marketing/MarketingLineChart';
function AdminSales() {


    const coursesData = [
        { id: 1, imgSrc: './admin/dashboard-1.png', title: 'Number of Active Courses', count: 250 },
        { id: 2, imgSrc: './admin/dashboard-2.png', title: 'Recent Uploads', count: 20 },
        { id: 3, imgSrc: './admin/dashboard-3.png', title: 'Total Sign Up', count: 200 },
        { id: 4, imgSrc: './admin/dashboard-3.png', title: 'Active User Counts', count: 125 },
        // Add more items as needed
    ];


    const progressData = [
        { category: 'Compliance', courses: 3, progressClass: 'progress02' },
        { category: 'Skills', courses: 2, progressClass: 'progress03' },
        { category: 'Fun', courses: 1, progressClass: 'progress04' },
    ];



    return (
        <AdminLayout>
            <h2 className='page-title'>Sales</h2>
            <div className="row g-4">
                <div className='col-12'>
                    <div className="card border-0 mt-4">
                        <div className="card-body p-4">
                            <div className="d-flex justify-content-between align-items-center">
                                <p className="text-black fw-600 fs-18 mb-4">Total Revenue</p>
                            </div>
                            <MarketingLineChart />
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="card border-0">
                        <div className="card-body p-4">
                            <div className="d-flex justify-content-between align-items-center">
                                <p className="text-black fw-600 fs-18 mb-4">Course Completion Rates</p>
                            </div>
                            <MarketingColumnChart />
                        </div>
                    </div>
                </div>
            </div>
        </AdminLayout>
    )
}

export default AdminSales