import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { toast } from 'react-toastify'

function PreviewoftheCourse({ courseData, setCourseData, isEdit, setIsEdit, handleCancel }) {
    const [title, setTitle]= useState(courseData?.title)
    const [introduction, setIntroduction]= useState(courseData?.introduction)
    const [courseTags, setCourseTags]= useState(courseData?.course_tags)
    const [courseType, setCourseType]= useState(courseData?.course_type)
    const [sectionData, setSectionData] = useState(courseData?.sections)
    const [questionData, setQuestionData] = useState(courseData?.quiz)
    const [referencesData, setReferencesData] =  useState(courseData?.references)
    const [notesData, setNotesData] =  useState(courseData?.notes)
    const handleEdit = () =>  setIsEdit(!isEdit)

    const handleSectionDataChange = (sectionKey, index, field, value) => {
         console.log("sectionKey, index, field, value",sectionKey, index, field, value)
        setSectionData(prevData => {
            // Create a deep copy of the sectionData
            const newData = { ...prevData };

            // If changing section name
            if (field === 'sectionName') {
                // Rename the section key
                const { [sectionKey]: oldSection, ...rest } = newData;
                const updatedData = {
                    ...rest,
                    [value]: oldSection
                };
                // Update the field within the newly renamed section
                updatedData[value] = updatedData[value].map((item, i) => {
                    if (i === index) {
                        return { ...item, [field]: value };
                    }
                    return item;
                });
                return updatedData;
            }

            // Update the specific field within the section
            newData[sectionKey][index] = {
                ...newData[sectionKey][index],
                [field]: value
            };

            return newData;
        });
    };

    const handleCourseTagChange = (index, e) => {
        const newTags = [...courseTags]
        newTags[index] = e.target.value;
        setCourseTags(newTags);
    }

    const handleQuestionDataChange = (index, questionKey, key, value) => {
        if( key === "question") {
            const newQuestion = [...questionData]
            newQuestion[index][questionKey][key] = value;
            setQuestionData(newQuestion);
        } else if (key === "answer") {
            const newQuestion = [...questionData]
            newQuestion[index][questionKey].answer = value;
            setQuestionData(newQuestion);
        } else {
            const newQuestion = [...questionData]
            newQuestion[index][questionKey].choices[key] = value;
            setQuestionData(newQuestion);
        }
    }

    const handleReferenceDataChange = (index, value) => {
        const newRef = [...referencesData];
        newRef[index].description = value;
        setReferencesData(newRef);
    }

    const handleNotesDataChange = (index, value) => {
        const newNotes = [...notesData];
        newNotes[index].description = value;
        setNotesData(newNotes);
    }

    const handleConfirm = () => {
        if(!title) {
            toast.error("Course Title is required")
            return;
        }

        if(!courseType) {
            toast.error("Course Type is required")
            return;
        }

        if(!validateTagArray(courseTags)) {
            return;
        }

        if(!validateQuestions(questionData)) {
            return;
        }

        if(!validateReferencesData(referencesData)) {
            return;
        }

        if(!validateNotesData(notesData)) {
            return;
        }

        setIsEdit(false)
        setCourseData({
            ...courseData,
            title: title,
            introduction: introduction,
            course_type: courseType,
            course_tags: courseTags,
            sections: sectionData,
            quiz: questionData,
            references: referencesData,
            notes: notesData,
        })
    }

    const validateTagArray = (tags) => {
        // Check if any tag is an empty string or contains only whitespace
        const hasInvalidTag = tags.some(tag => !tag.trim());
        if (hasInvalidTag) {
            toast.error("All tags must be non-empty and valid.");
            return false; // Validation failed
        }
        return true; // Validation succeeded
    };

    const validateQuestions = (questions) => {
        let isValid = true;
        let validationErrors = [];
        // Iterate through each question
        questions.forEach((questionObject) => {
            Object.keys(questionObject).forEach((questionKey) => {
                const question = questionObject[questionKey];
                // Validate the question field
                if (!question.question || question.question.trim() === '') {
                    isValid = false;
                    validationErrors.push(`${questionKey} question is required.`);
                }
                // Validate the choices field
                Object.keys(question.choices).forEach((choiceKey) => {
                    const choiceValue = question.choices[choiceKey];
                    // Check if choiceValue is neither null, undefined, nor empty
                    if (choiceValue === null || choiceValue === undefined || choiceValue.toString().trim() === '') {
                        isValid = false;
                        validationErrors.push(`${questionKey} choices.${choiceKey} is required.`);
                    }
                });
                // Validate the answer field
                if (!Object.keys(question.choices).includes(question.answer)) {
                    isValid = false;
                    validationErrors.push(`${questionKey} answer is not a valid choice.`);
                }
            });
        });

        if (!isValid) {
            // Print validation errors
            validationErrors.forEach(error => toast.error(error));
        }
        return isValid;
    };

    const validateReferencesData = (refData) => {
        let isValid = true;
        let validationErrors = [];
        refData.forEach((item, index) => {
            const description = item.description;
            // Check if description is empty or just whitespace
            if (!description || description.trim() === '') {
                isValid = false;
                validationErrors.push(`Reference at index ${index + 1} has an empty description.`);
            }
        });
        if (!isValid) {
            // Print validation errors
            validationErrors.forEach(error => toast.error(error));
        }
        return isValid;
    };

    const validateNotesData = (notesData) => {
        let isValid = true;
        let validationErrors = [];
        notesData.forEach((item, index) => {
            const description = item.description;
            // Check if description is empty or just whitespace
            if (!description || description.trim() === '') {
                isValid = false;
                validationErrors.push(`Notes at index ${index + 1} has an empty description.`);
            }
        });
        if (!isValid) {
            // Print validation errors
            validationErrors.forEach(error => toast.error(error));
        }
        return isValid;
    };

    return (
        <>
            <div className='preview-course-top'>
                <div className='d-lg-flex justify-content-between align-items-center gap-3'>
                    <h2>Preview of the Course</h2>

                    <div className='d-md-flex align-items-center gap-3 preview-course-top-buttons mt-3 mt-xl-0'>
                        <button className='mb-3 ms-2' onClick={handleConfirm}>Confirm Upload</button>
                        <button className='mb-3 ms-2' onClick={handleEdit}>Edit Details</button>
                        <button className='mb-3 ms-2' onClick={handleCancel}>Cancel</button>
                    </div>
                </div>

                <div className='mt-4'>

                    <div id='1'>
                        {!isEdit && courseData && courseData.course_tags.length > 0 && courseData.course_tags.map((item, index) => {
                            return (
                                <span key={index} className="badge badge-black-2 text-white rounded-2 fw-400 fs-7 mx-1 mb-2">{item}</span>
                            )
                        })}

                        {isEdit && courseTags.length > 0 &&
                            <div className="row g-2 my-2">
                                {courseTags.map ((tag, i) => (
                                    <>
                                        <div className="col-2" key={i}>
                                            <input type="text" className='form-control' placeholder='' name="tags" value={tag} onChange={(e)=> handleCourseTagChange(i, e)} />
                                        </div>
                                    </>
                                ))}
                            </div>
                        }
                        {!isEdit && <span className={
                                                    (courseData?.course_type === "Bronze" ||  courseData?.course_type === "bronze")
                                                        ? "badge badge-bronze rounded-2 fw-400 fs-7 md-0 mb-md-4"
                                                        : courseData?.course_type
                                                             ===
                                                        "Silver"
                                                        ? "badge badge-silver rounded-2 fw-400 fs-7 md-0 mb-md-4"
                                                        : courseData?.course_type
                                                            === "Gold"
                                                        ? "badge badge-gold rounded-2 fw-400 md-0 mb-md-4"
                                                        : courseData?.course_type
                                                             === "Free"
                                                        ? "badge badge-light text-black rounded-2 fw-400 fs-7 md-0 mb-md-4"
                                                        : ""
                                                }>{courseData?.course_type}</span>}

                        {isEdit &&
                           <div className="col-12 col-lg-3 mt-3">
                                <Form.Select aria-label="select course type" value={courseType} onChange={((e)=> setCourseType(e.target.value))}>
                                    <option value="Bronze">Bronze</option>
                                    <option value="Silver">Silver</option>
                                    <option value="Gold">Gold</option>
                                    <option value="Free">Free</option>
                                </Form.Select>
                            </div>
                        }

                        {!isEdit &&
                            <>
                                <h2 className='text-black fw-400 mb-4 mb-md-5 mt-3'>{courseData?.title}</h2>
                                <p className="text-muted fw-400 mt-3 fs-6">
                                    {courseData?.introduction}
                                </p>
                            </>
                        }

                        {isEdit &&
                            <form action="">
                                <div className="row g-4 mt-3">
                                    <div className="col-12">
                                        <input type="text" className='form-control' placeholder='Title' name="title"  value={title} onChange={(e)=> setTitle(e.target.value)} />
                                    </div>
                                    <div className="col-12 col-lg-12 mb-3">
                                        <textarea className='form-control rounded-4' placeholder='Introduction' name="introduction" value={introduction} onChange={(e)=> setIntroduction(e.target.value)}></textarea>
                                    </div>
                                </div>
                            </form>
                        }

                        {!isEdit && courseData && courseData?.sections && Object.entries(courseData?.sections).map(([key, value], i) => {
                            return (
                                <div>
                                    <h4 className="text-black fw-500">{key}</h4>
                                    {value.map((v, index) => {
                                        return (
                                            <>
                                                {v.title && <p className="text-muted fw-400 fs-6">
                                                    {index + 1}. {v.title}
                                                </p>}
                                                <p className="text-muted fw-400 mt-3 mb-5 fs-6 break-all">
                                                    {v.description}
                                                </p>
                                            </>
                                        )
                                    })}
                                </div>
                            )
                        })}

                        {isEdit && sectionData && Object.entries(sectionData).map(([key, value], i) => {
                            return (
                                <div className='my-5'>
                                    <form action="">
                                        <div className="row g-4">
                                            <div className="col-12 mb-3">
                                                <input type="text" className='form-control' placeholder={"Enter section name"} disabled name={key} value={key} onChange={(e)=> handleSectionDataChange(key, null, 'sectionName', e.target.value)} />
                                            </div>
                                        </div>
                                    </form>
                                    {value.map((v, index) => {
                                        return (
                                            <>
                                                <div>
                                                <form className='ms-3'>
                                                    <div className="row g-3">
                                                        <div className="col-12">
                                                            <input type="text" className='form-control' placeholder={`Enter title`} name={`${key["title"]}`} value={v.title}  onChange={(e) => handleSectionDataChange(key, index, 'title', e.target.value)} />
                                                        </div>
                                                        <div className="col-12 col-lg-12 mb-3">
                                                            <textarea name={`${key["description"]}`} className='form-control rounded-4'  placeholder={`Enter description`} value={v.description}  onChange={(e) => handleSectionDataChange(key, index, 'description', e.target.value)} ></textarea>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                            </>
                                        )
                                    })}
                                </div>
                            )
                        })}
                    </div>
                </div>


                <h2 className='text-black fw-600 mt-4 mb-4'>Questions</h2>

                {!isEdit &&courseData && courseData?.quiz?.length > 0 && courseData?.quiz.map((question, index) => (
                    <div key={index} className="row mb-">
                        <div className="col-12">
                            <div className='d-flex align-items-center gap-2 mb-3 mt-3'>
                                <img src="/admin/emoji_flags.png" alt="" />
                                <span className='text-black fs-7 fw-400'>
                                    Question {index + 1}
                                </span>
                            </div>
                            <div className="card rounded-4 border-0">
                                <div className="card-body p-4 p-md-5">
                                    <h5 className='text-black fw-400'>
                                        {question[`question_${index + 1}`].question}
                                        {/* {question[`question_${i + 1}`]?.answer} */}
                                    </h5>
                                    <div className="row mt-1 g-4">
                                        {Object.entries(question[`question_${index + 1}`].choices).map(([key, value], i) => (
                                            <div key={i} className={`col-12 col-md-6 col-lg-3 fw-400 fs-18 ${key === question[`question_${index + 1}`].answer ? "text-success" : "text-black"}`}>
                                                {key}. {value}
                                            </div>
                                        ))}
                                    </div>

                                    <div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}

                {isEdit && questionData?.length > 0 && questionData.map((question, index) => (<div className="row mb-">
                    <div className="col-12">
                        <div className='d-flex align-items-center gap-2 mb-3 mt-3'>
                            <img src="/admin/emoji_flags.png" alt="" />
                            <span className='text-black fs-7 fw-400'>
                                Question {index + 1}
                            </span>
                        </div>
                        <div className="card rounded-4 border-0">
                            <div className="card-body p-4 p-md-5">
                                <form action="">
                                    <div className="row g-4">
                                        <div className="col-12 col-lg-12">
                                            <textarea className='form-control rounded-4' name={question[`question_${index + 1}`]} value={question[`question_${index + 1}`].question} onChange={(e)=> handleQuestionDataChange(index, `question_${index + 1}`, "question", e.target.value)} ></textarea>
                                        </div>
                                        {Object.entries(question[`question_${index + 1}`].choices).map(([key, value], i) => (
                                            <div key={i} className={`col-12 col-md-6 col-lg-3 fw-400 fs-18 d-flex gap-1 ${key === question[`question_${index + 1}`].answer ? "text-success" : "text-black"}`}>
                                                {key}. <input type="text" className='form-control' placeholder='' name={key} value={value} onChange={(e)=> handleQuestionDataChange(index, `question_${index + 1}`, key, e.target.value)}/>
                                            </div>
                                        ))}
                                        <div className="col-12 col-lg-4 d-flex">
                                            <label>Select the right answer:</label>
                                            <Form.Select aria-label="Default select example" value={question[`question_${index + 1}`].answer} onChange={(e)=> handleQuestionDataChange(index, `question_${index + 1}`, "answer", e.target.value)} >
                                                <option value="A">A</option>
                                                <option value="B">B</option>
                                                <option value="C">C</option>
                                                <option value="D">D</option>
                                            </Form.Select>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>))}


                {referencesData?.length > 0 && <h2 className='text-black fw-600 mb-4 mb-4 mt-3'>References</h2>}
                {!isEdit && courseData && courseData?.references && courseData?.references.map((item, i) => {
                    return (
                        <div>
                            <p className="text-muted fw-400 mt-3 mb-5 fs-6">
                                {item.description}
                            </p>
                        </div>
                    )
                })}

                {isEdit && referencesData?.length > 0 && referencesData.map((item, i) => {
                    return (
                        <form action="" key={i}>
                            <div className="row g-4">
                                <div className="col-12 col-lg-12">
                                    <textarea className='form-control rounded-4 mb-3' name={"description"} value={item.description} onChange={((e) => handleReferenceDataChange(i, e.target.value))}></textarea>
                                </div>
                            </div>
                    </form>
                    )
                })}


                {notesData.length > 0 && <h2 className='text-black fw-600 mb-4 mb-4 mt-3'>Notes</h2>}
                {!isEdit && courseData && courseData?.notes?.length > 0 && courseData?.notes.map((item, i) => {
                    return (
                        <div>
                            <p className="text-muted fw-400 mt-3 mb-5 fs-6">
                                {item.description}
                            </p>
                        </div>
                    )
                })}


                {isEdit && notesData.length > 0 && notesData.map((item, i) => {
                    return (
                        <form action="">
                            <div className="row g-4">
                                <div className="col-12 col-lg-12">
                                    <textarea className='form-control rounded-4 mb-3' name={"description"} value={item.description}  onChange={((e) => handleNotesDataChange(i, e.target.value))}></textarea>
                                </div>
                            </div>
                    </form>
                    )
                })}
            </div>
        </>
    )
}

export default PreviewoftheCourse
