import React from 'react'
import { Link } from 'react-router-dom'
import Header from '../layout/Header'
import Footer from '../layout/Footer'

const BusinessUserDetails = () => {
    return (
        <>
            <Header />
            <section>
                <div className="container-fluid">
                    <div className="sign-up-main">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-12 col-md-6 position-relative bg-primary d-flex justify-content-center align-items-center sign-up-col vh-md-100">
                                <img
                                    src="./login/individual-user-vector-1.png"
                                    className="sing-vector-1"
                                    alt=""
                                />
                                <img
                                    src="./login/woman-home.png"
                                    className="project-team"
                                    alt=""
                                />
                                <img
                                    src="./login/individual-user-vector-2.png"
                                    className="sing-vector-2"
                                    alt=""
                                />
                            </div>
                            <div className="col-12 col-md-6 position-relative d-flex justify-content-center align-items-start align-items-md-center sign-up-col sign-up-col-sm vh-md-100">
                                <div className="col-12 col-md-12 col-lg-10 col-xl-9">
                                    <form className="row g-3 form-main">
                                        <div className="col-md-12">
                                            <div className="col-md-12 col-lg-12 col-xl-8 mb-5">
                                                <label htmlFor="inputState" className="form-label">
                                                    What’s your role?
                                                </label>
                                                <select id="inputState" className="form-select">
                                                    <option>Choose...</option>
                                                    <option selected="">Manager</option>
                                                </select>
                                            </div>
                                            <div className="col-md-12 col-lg-12 col-xl-8 mb-5">
                                                <label htmlFor="inputState" className="form-label">
                                                    What topic/category would you like to explore?
                                                </label>
                                                <select id="inputState" className="form-select">
                                                    <option>Choose...</option>
                                                    <option selected="">IT and Security</option>
                                                </select>
                                            </div>
                                            <div className="col-md-12 col-lg-12 col-xl-8 mb-5">
                                                <label className="form-label">Name</label>
                                                <input
                                                    className="form-control"
                                                    placeholder="Enter your name"
                                                />
                                            </div>
                                            <div className="col-md-12 col-lg-12 col-xl-8 mb-5">
                                                <label className="form-label">Email Address</label>
                                                <input
                                                    className="form-control"
                                                    placeholder="Enter your email address"
                                                />
                                            </div>
                                            <div className="col-md-12 col-lg-12 col-xl-8 d-flex justify-content-end">
                                                <Link
                                                    to="/business-subscription"
                                                    className="btn-explore d-flex align-items-center fw-400 gap-3 rounded-2"
                                                >
                                                    Submit
                                                </Link>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default BusinessUserDetails