import React from 'react';
import AdminHeader from '../layout/AdminHeader';
import Footer from '../layout/Footer';
import SliderHomeSec from '../components/SliderHomeSec';
import ContentSidbar from '../components/ContentSidbar';
import { Link } from 'react-router-dom';

function ContentQuestions() {
    return (
        <>
            <AdminHeader />
            <section>
                <div className="dashbaord-home-main">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-3 col-md-4 col-lg-3 col-xxl-3 d-none d-xl-block px-4 bg-white sidebar-mian">
                                <ContentSidbar />
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 col-xxl-9 bg-body">
                                <div className="row ps-0 ps-lg-4 pe-lg-4 ps-xl-5 dashbaord-right-side justify-content-md-center justify-content-lg-between">
                                    <div className="col-12 col-md-11 col-lg-12 right-side">
                                        <SliderHomeSec />
                                        <div className="row g-4 mb-5 rounded-2">
                                            <div className="col-12">
                                                <h2 className="fw-600 text-black pt-5 pb-4 pb-md-0">Questions</h2>
                                                <div className="row g-4">
                                                    <div className="col-12">
                                                        <div className="featured-main pb-0">
                                                            <div className="row justify-content-between align-items-center mb-4">
                                                                <div className="col-12">
                                                                    <span className="badge badge-light rounded-2 fw-400 fs-6 mb-4">
                                                                        IT Security
                                                                    </span>
                                                                    <h2 className="text-black fw-400">
                                                                        The Art of Outsmarting: A Guide to Fraud Prevention
                                                                    </h2>
                                                                </div>
                                                            </div>
                                                            <div className="row g-4 g-md-3 g-lg-4">
                                                                <div className="col-12">
                                                                    <div className="d-flex align-items-center gap-2 mb-3">
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width={24}
                                                                            height={24}
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                        >
                                                                            <mask
                                                                                id="mask0_361_18372"
                                                                                style={{ maskType: "alpha" }}
                                                                                maskUnits="userSpaceOnUse"
                                                                                x={0}
                                                                                y={0}
                                                                                width={24}
                                                                                height={24}
                                                                            >
                                                                                <rect width={24} height={24} fill="#D9D9D9" />
                                                                            </mask>
                                                                            <g mask="url(#mask0_361_18372)">
                                                                                <path
                                                                                    d="M5 21V4H14L14.4 6H20V16H13L12.6 14H7V21H5ZM14.65 14H18V8H12.75L12.35 6H7V12H14.25L14.65 14Z"
                                                                                    fill="#1C1B1F"
                                                                                />
                                                                            </g>
                                                                        </svg>
                                                                        <p className="text-black fw-400 fs-7 mb-0">Question 01</p>
                                                                    </div>
                                                                    <div className="card featured-card overflow-hidden border-0 mb-0 mb-md-4 mb-lg-5">
                                                                        <div className="card-body p-4 p-md-5">
                                                                            <h6 className="text-black fw-400 mb-4 fs-18">
                                                                                In what year did the massive tsunami hit Sri Lanka?
                                                                            </h6>
                                                                            <ul className="answer-list d-md-flex justify-content-between align-items-center">
                                                                                <li className="p-md-0 fw-400">2001</li>
                                                                                <li className="p-md-0 fw-400">2002</li>
                                                                                <li className="p-md-0 fw-400">2004</li>
                                                                                <li className="p-md-0 fw-400">2006</li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className="d-flex align-items-center gap-2 mb-3">
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width={24}
                                                                            height={24}
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                        >
                                                                            <mask
                                                                                id="mask0_361_18372"
                                                                                style={{ maskType: "alpha" }}
                                                                                maskUnits="userSpaceOnUse"
                                                                                x={0}
                                                                                y={0}
                                                                                width={24}
                                                                                height={24}
                                                                            >
                                                                                <rect width={24} height={24} fill="#D9D9D9" />
                                                                            </mask>
                                                                            <g mask="url(#mask0_361_18372)">
                                                                                <path
                                                                                    d="M5 21V4H14L14.4 6H20V16H13L12.6 14H7V21H5ZM14.65 14H18V8H12.75L12.35 6H7V12H14.25L14.65 14Z"
                                                                                    fill="#1C1B1F"
                                                                                />
                                                                            </g>
                                                                        </svg>
                                                                        <p className="text-black fw-400 fs-7 mb-0">Question 02</p>
                                                                    </div>
                                                                    <div className="card featured-card overflow-hidden border-0 mb-0 mb-md-4 mb-lg-5">
                                                                        <div className="card-body p-4 p-md-5">
                                                                            <h6 className="text-black fw-400 mb-4 fs-18">
                                                                                In what year did the massive tsunami hit Sri Lanka?
                                                                            </h6>
                                                                            <ul className="answer-list">
                                                                                <li className="fw-400">2001</li>
                                                                                <li className="fw-400">2002</li>
                                                                                <li className="fw-400">2004</li>
                                                                                <li className="fw-400">2006</li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className="d-flex align-items-center gap-2 mb-3">
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width={24}
                                                                            height={24}
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                        >
                                                                            <mask
                                                                                id="mask0_361_18372"
                                                                                style={{ maskType: "alpha" }}
                                                                                maskUnits="userSpaceOnUse"
                                                                                x={0}
                                                                                y={0}
                                                                                width={24}
                                                                                height={24}
                                                                            >
                                                                                <rect width={24} height={24} fill="#D9D9D9" />
                                                                            </mask>
                                                                            <g mask="url(#mask0_361_18372)">
                                                                                <path
                                                                                    d="M5 21V4H14L14.4 6H20V16H13L12.6 14H7V21H5ZM14.65 14H18V8H12.75L12.35 6H7V12H14.25L14.65 14Z"
                                                                                    fill="#1C1B1F"
                                                                                />
                                                                            </g>
                                                                        </svg>
                                                                        <p className="text-black fw-400 fs-7 mb-0">Question 03</p>
                                                                    </div>
                                                                    <div className="card featured-card overflow-hidden border-0 mb-0 mb-md-4 mb-lg-5">
                                                                        <div className="card-body p-4 p-md-5">
                                                                            <h6 className="text-black fw-400 mb-4 fs-18">
                                                                                In what year did the massive tsunami hit Sri Lanka?
                                                                            </h6>
                                                                            <ul className="answer-list d-md-flex justify-content-between align-items-center">
                                                                                <li className="p-md-0 fw-400">2001</li>
                                                                                <li className="p-md-0 fw-400">2002</li>
                                                                                <li className="p-md-0 fw-400">2004</li>
                                                                                <li className="p-md-0 fw-400">2006</li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className="d-flex align-items-center gap-2 mb-3">
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width={24}
                                                                            height={24}
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                        >
                                                                            <mask
                                                                                id="mask0_361_18372"
                                                                                style={{ maskType: "alpha" }}
                                                                                maskUnits="userSpaceOnUse"
                                                                                x={0}
                                                                                y={0}
                                                                                width={24}
                                                                                height={24}
                                                                            >
                                                                                <rect width={24} height={24} fill="#D9D9D9" />
                                                                            </mask>
                                                                            <g mask="url(#mask0_361_18372)">
                                                                                <path
                                                                                    d="M5 21V4H14L14.4 6H20V16H13L12.6 14H7V21H5ZM14.65 14H18V8H12.75L12.35 6H7V12H14.25L14.65 14Z"
                                                                                    fill="#1C1B1F"
                                                                                />
                                                                            </g>
                                                                        </svg>
                                                                        <p className="text-black fw-400 fs-7 mb-0">Question 04</p>
                                                                    </div>
                                                                    <div className="card featured-card overflow-hidden border-0 mb-0 mb-md-4 mb-lg-5">
                                                                        <div className="card-body p-4 p-md-5">
                                                                            <h6 className="text-black fw-400 mb-4 fs-18">
                                                                                In what year did the massive tsunami hit Sri Lanka?
                                                                            </h6>
                                                                            <ul className="answer-list">
                                                                                <li className="fw-400">2001</li>
                                                                                <li className="fw-400">2002</li>
                                                                                <li className="fw-400">2004</li>
                                                                                <li className="fw-400">2006</li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className="d-flex align-items-center gap-2 mb-3">
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width={24}
                                                                            height={24}
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                        >
                                                                            <mask
                                                                                id="mask0_361_18372"
                                                                                style={{ maskType: "alpha" }}
                                                                                maskUnits="userSpaceOnUse"
                                                                                x={0}
                                                                                y={0}
                                                                                width={24}
                                                                                height={24}
                                                                            >
                                                                                <rect width={24} height={24} fill="#D9D9D9" />
                                                                            </mask>
                                                                            <g mask="url(#mask0_361_18372)">
                                                                                <path
                                                                                    d="M5 21V4H14L14.4 6H20V16H13L12.6 14H7V21H5ZM14.65 14H18V8H12.75L12.35 6H7V12H14.25L14.65 14Z"
                                                                                    fill="#1C1B1F"
                                                                                />
                                                                            </g>
                                                                        </svg>
                                                                        <p className="text-black fw-400 fs-7 mb-0">Question 05</p>
                                                                    </div>
                                                                    <div className="card featured-card overflow-hidden border-0 mb-0 mb-md-4 mb-lg-5">
                                                                        <div className="card-body p-4 p-md-5">
                                                                            <h6 className="text-black fw-400 mb-4 fs-18">
                                                                                In what year did the massive tsunami hit Sri Lanka?
                                                                            </h6>
                                                                            <ul className="answer-list d-md-flex justify-content-between align-items-center">
                                                                                <li className="p-md-0 fw-400">2001</li>
                                                                                <li className="p-md-0 fw-400">2002</li>
                                                                                <li className="p-md-0 fw-400">2004</li>
                                                                                <li className="p-md-0 fw-400">2006</li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className="d-flex align-items-center gap-2 mb-3">
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width={24}
                                                                            height={24}
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                        >
                                                                            <mask
                                                                                id="mask0_361_18372"
                                                                                style={{ maskType: "alpha" }}
                                                                                maskUnits="userSpaceOnUse"
                                                                                x={0}
                                                                                y={0}
                                                                                width={24}
                                                                                height={24}
                                                                            >
                                                                                <rect width={24} height={24} fill="#D9D9D9" />
                                                                            </mask>
                                                                            <g mask="url(#mask0_361_18372)">
                                                                                <path
                                                                                    d="M5 21V4H14L14.4 6H20V16H13L12.6 14H7V21H5ZM14.65 14H18V8H12.75L12.35 6H7V12H14.25L14.65 14Z"
                                                                                    fill="#1C1B1F"
                                                                                />
                                                                            </g>
                                                                        </svg>
                                                                        <p className="text-black fw-400 fs-7 mb-0">Question 06</p>
                                                                    </div>
                                                                    <div className="card featured-card overflow-hidden border-0 mb-0 mb-md-4 mb-lg-5">
                                                                        <div className="card-body p-4 p-md-5">
                                                                            <h6 className="text-black fw-400 mb-4 fs-18">
                                                                                In what year did the massive tsunami hit Sri Lanka?
                                                                            </h6>
                                                                            <ul className="answer-list d-md-flex justify-content-between align-items-center">
                                                                                <li className="p-md-0 fw-400">2001</li>
                                                                                <li className="p-md-0 fw-400">2002</li>
                                                                                <li className="p-md-0 fw-400">2004</li>
                                                                                <li className="p-md-0 fw-400">2006</li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className="d-flex align-items-center gap-2 mb-3">
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width={24}
                                                                            height={24}
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                        >
                                                                            <mask
                                                                                id="mask0_361_18372"
                                                                                style={{ maskType: "alpha" }}
                                                                                maskUnits="userSpaceOnUse"
                                                                                x={0}
                                                                                y={0}
                                                                                width={24}
                                                                                height={24}
                                                                            >
                                                                                <rect width={24} height={24} fill="#D9D9D9" />
                                                                            </mask>
                                                                            <g mask="url(#mask0_361_18372)">
                                                                                <path
                                                                                    d="M5 21V4H14L14.4 6H20V16H13L12.6 14H7V21H5ZM14.65 14H18V8H12.75L12.35 6H7V12H14.25L14.65 14Z"
                                                                                    fill="#1C1B1F"
                                                                                />
                                                                            </g>
                                                                        </svg>
                                                                        <p className="text-black fw-400 fs-7 mb-0">Question 07</p>
                                                                    </div>
                                                                    <div className="card featured-card overflow-hidden border-0 mb-0 mb-md-4 mb-lg-5">
                                                                        <div className="card-body p-4 p-md-5">
                                                                            <h6 className="text-black fw-400 mb-4 fs-18">
                                                                                In what year did the massive tsunami hit Sri Lanka?
                                                                            </h6>
                                                                            <ul className="answer-list d-md-flex justify-content-between align-items-center">
                                                                                <li className="p-md-0 fw-400">2001</li>
                                                                                <li className="p-md-0 fw-400">2002</li>
                                                                                <li className="p-md-0 fw-400">2004</li>
                                                                                <li className="p-md-0 fw-400">2006</li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className="d-flex align-items-center gap-2 mb-3">
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width={24}
                                                                            height={24}
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                        >
                                                                            <mask
                                                                                id="mask0_361_18372"
                                                                                style={{ maskType: "alpha" }}
                                                                                maskUnits="userSpaceOnUse"
                                                                                x={0}
                                                                                y={0}
                                                                                width={24}
                                                                                height={24}
                                                                            >
                                                                                <rect width={24} height={24} fill="#D9D9D9" />
                                                                            </mask>
                                                                            <g mask="url(#mask0_361_18372)">
                                                                                <path
                                                                                    d="M5 21V4H14L14.4 6H20V16H13L12.6 14H7V21H5ZM14.65 14H18V8H12.75L12.35 6H7V12H14.25L14.65 14Z"
                                                                                    fill="#1C1B1F"
                                                                                />
                                                                            </g>
                                                                        </svg>
                                                                        <p className="text-black fw-400 fs-7 mb-0">Question 08</p>
                                                                    </div>
                                                                    <div className="card featured-card overflow-hidden border-0 mb-0 mb-md-4 mb-lg-5">
                                                                        <div className="card-body p-4 p-md-5">
                                                                            <h6 className="text-black fw-400 mb-4 fs-18">
                                                                                In what year did the massive tsunami hit Sri Lanka?
                                                                            </h6>
                                                                            <ul className="answer-list d-md-flex justify-content-between align-items-center">
                                                                                <li className="p-md-0 fw-400">2001</li>
                                                                                <li className="p-md-0 fw-400">2002</li>
                                                                                <li className="p-md-0 fw-400">2004</li>
                                                                                <li className="p-md-0 fw-400">2006</li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className="d-flex align-items-center gap-2 mb-3">
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width={24}
                                                                            height={24}
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                        >
                                                                            <mask
                                                                                id="mask0_361_18372"
                                                                                style={{ maskType: "alpha" }}
                                                                                maskUnits="userSpaceOnUse"
                                                                                x={0}
                                                                                y={0}
                                                                                width={24}
                                                                                height={24}
                                                                            >
                                                                                <rect width={24} height={24} fill="#D9D9D9" />
                                                                            </mask>
                                                                            <g mask="url(#mask0_361_18372)">
                                                                                <path
                                                                                    d="M5 21V4H14L14.4 6H20V16H13L12.6 14H7V21H5ZM14.65 14H18V8H12.75L12.35 6H7V12H14.25L14.65 14Z"
                                                                                    fill="#1C1B1F"
                                                                                />
                                                                            </g>
                                                                        </svg>
                                                                        <p className="text-black fw-400 fs-7 mb-0">Question 09</p>
                                                                    </div>
                                                                    <div className="card featured-card overflow-hidden border-0 mb-0 mb-md-4 mb-lg-5">
                                                                        <div className="card-body p-4 p-md-5">
                                                                            <h6 className="text-black fw-400 mb-4 fs-18">
                                                                                In what year did the massive tsunami hit Sri Lanka?
                                                                            </h6>
                                                                            <ul className="answer-list d-md-flex justify-content-between align-items-center">
                                                                                <li className="p-md-0 fw-400">2001</li>
                                                                                <li className="p-md-0 fw-400">2002</li>
                                                                                <li className="p-md-0 fw-400">2004</li>
                                                                                <li className="p-md-0 fw-400">2006</li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className="d-flex align-items-center gap-2 mb-3">
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width={24}
                                                                            height={24}
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                        >
                                                                            <mask
                                                                                id="mask0_361_18372"
                                                                                style={{ maskType: "alpha" }}
                                                                                maskUnits="userSpaceOnUse"
                                                                                x={0}
                                                                                y={0}
                                                                                width={24}
                                                                                height={24}
                                                                            >
                                                                                <rect width={24} height={24} fill="#D9D9D9" />
                                                                            </mask>
                                                                            <g mask="url(#mask0_361_18372)">
                                                                                <path
                                                                                    d="M5 21V4H14L14.4 6H20V16H13L12.6 14H7V21H5ZM14.65 14H18V8H12.75L12.35 6H7V12H14.25L14.65 14Z"
                                                                                    fill="#1C1B1F"
                                                                                />
                                                                            </g>
                                                                        </svg>
                                                                        <p className="text-black fw-400 fs-7 mb-0">Question 10</p>
                                                                    </div>
                                                                    <div className="card featured-card overflow-hidden border-0 mb-0 mb-md-4 mb-lg-5">
                                                                        <div className="card-body p-4 p-md-5">
                                                                            <h6 className="text-black fw-400 mb-4 fs-18">
                                                                                In what year did the massive tsunami hit Sri Lanka?
                                                                            </h6>
                                                                            <ul className="answer-list d-md-flex justify-content-between align-items-center">
                                                                                <li className="p-md-0 fw-400">2001</li>
                                                                                <li className="p-md-0 fw-400">2002</li>
                                                                                <li className="p-md-0 fw-400">2004</li>
                                                                                <li className="p-md-0 fw-400">2006</li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex justify-content-end mb-0 mb-md-0 mt-4 mt-md-5">
                                                                    <div className="col-6 col-md-2">
                                                                        <Link
                                                                            to="/content-uploader-quizzes-multifile-preview"
                                                                            className="btn-explore d-flex justify-content-between align-items-center gap-3 rounded-2 docs-creator"
                                                                        >
                                                                            Save
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width={22}
                                                                                height={14}
                                                                                viewBox="0 0 22 14"
                                                                                fill="none"
                                                                            >
                                                                                <path
                                                                                    d="M1 6.99854H21L16 1"
                                                                                    stroke="white"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                ></path>
                                                                                <path
                                                                                    d="M21 6.99854L16 13"
                                                                                    stroke="white"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                ></path>
                                                                            </svg>
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            <Footer />
        </>
    )
}

export default ContentQuestions