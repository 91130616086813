import React from 'react';

const CoursesHomeSec = ({ handleKeyPress, handleSearchChange, search, setSearch ,handlePress}) => {


    return (
        <>
            <section>
                <div className="courses-main">
                    <div className="container">
                        <div className="courses-categories d-flex justify-content-center align-items-center align-items-md-start  align-items-xl-center rounded-4 position-relative overflow-hidden">
                            <div className="courses-home z-3">
                                <h2 className="text-white text-center fw-400 mb-3 fs-1 col-10 col-md-10 col-lg-12 col-xl-12 m-auto">
                                    Explore Our Extensive Course Categories
                                </h2>
                                <p className="text-white text-center fw-300 fs-19 pb-2 col-10 col-md-10 col-lg-12 col-xl-12 m-auto">
                                    Expand your knowledge and skills through our comprehensive courses.
                                </p>
                                <div className="d-md-flex justify-content-center m-auto mt-4 col-10 col-md-10 col-lg-12 col-xl-12">
                                    <div className="subscribe-box d-md-flex justify-content-center">
                                        <div className="col-12 col-md-6 col-lg-9 col-xl-10">
                                            <input
                                                type="text"
                                                className="form-control rounded-start-2 rounded-end-0 border-0 h-100 ps-4"
                                                placeholder="Search the course"
                                                value={search}
                                                onChange={handleSearchChange}
                                                onKeyPress={handleKeyPress}
                                            />
                                        </div>
                                        <div className="col-12 col-md-6 col-xl-4 col-xl-6">
                                            <button
                                                type="button"
                                                className="btn-explore bg-warning text-black text-center gap-3 sm-w-100 rounded-start-0 rounded-end-2 mt-4 mt-md-0"
                                                onClick={handlePress}
                                            >
                                                Let’s Search
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={25}
                                                    height={24}
                                                    viewBox="0 0 25 24"
                                                    fill="none"
                                                >
                                                    <mask
                                                        id="mask0_168_1865"
                                                        style={{ maskType: "alpha" }}
                                                        maskUnits="userSpaceOnUse"
                                                        x={0}
                                                        y={0}
                                                        width={25}
                                                        height={24}
                                                    >
                                                        <rect x="0.5" width={24} height={24} fill="#D9D9D9" />
                                                    </mask>
                                                    <g mask="url(#mask0_168_1865)">
                                                        <path
                                                            d="M6.9 18L5.5 16.6L15.1 7H6.5V5H18.5V17H16.5V8.4L6.9 18Z"
                                                            fill="#000C2A"
                                                        />
                                                    </g>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <img
                                src="../featured-courses/ezgif.com-rotate.gif"
                                className="courses-gift"
                                alt=""
                            />
                            <img
                                src="../featured-courses/courses-vector-1.svg"
                                className="courses-vector-1"
                                alt=""
                            />
                            <img
                                src="../featured-courses/courses-vector-2.svg"
                                className="courses-vector-2"
                                alt=""
                            />
                            <img
                                src="../featured-courses/juicy-online-presentation.svg"
                                className="juicy-online"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default CoursesHomeSec;
