import React, { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import { Card } from "react-bootstrap";
import moment from "moment";
import { toast } from "react-toastify";
import api from "../../helper/api";
import { getServerURL } from "../../helper/envConfig";
import {
    decryptFromStoreLoc,
    encryptAndStoreLoc,
} from "../../helper/localStore";

const FamilyUserPaymentDetails = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const serverURL = getServerURL();
    const [selectedMethod, setSelectedMethod] = useState("paypal"); // Default selected method
    const [state, setState] = useState(location.state || {});
    const [kidsCount, setKidsCount] = useState(0);
    const [adultCount, setAdultCount] = useState(0);
    const [kidsTotalAmount, setKidsTotalAmount] = useState(0);
    const [adultTotalAmount, setAdultTotalAmount] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const date = moment().add(1, "month").format("MMM DD, YYYY");

    const handleChangeKidCount = (e) => {
        const count = e.target.value;
        setKidsCount(count);
        const amount = count * 36;
        setKidsTotalAmount(amount);
    };
    const handleChangeAdultCount = (e) => {
        const count = e.target.value;
        setAdultCount(count);
        const amount = count * 72;
        setAdultTotalAmount(amount);
    };

    const handleChange = (method) => {
        setSelectedMethod(method);
    };

    useEffect(() => {
        const state = decryptFromStoreLoc("state");
        if (state) {
            setTotalAmount(state.totalAmount);
            setKidsCount(state.kidsCount);
            setAdultCount(state.adultCount);
        }
    }, []);

    const calculateTotal = (state) => {
        let totalPrice = 0;
        if (state?.userPlan?.perEmployeePrice) {
            totalPrice += state?.userPlan?.perEmployeePrice;
        }

        if (state?.blendedLearning?.length > 0) {
            state?.blendedLearning.map((item) => {
                totalPrice += item.total;
            });
        }

        if (kidsCount > 0) {
            totalPrice += kidsCount * 36;
        }

        if (adultCount > 0) {
            totalPrice += adultCount * 72;
        }

        setTotalAmount(totalPrice);
    };

    useEffect(() => {
        if (state.name) {
            const newState = { ...state };
            newState.kidsCount = kidsCount;
            newState.adultCount = adultCount;
            newState.kidsTotalAmount = kidsTotalAmount;
            newState.adultTotalAmount = adultTotalAmount;
            encryptAndStoreLoc("state", newState);
            calculateTotal(newState);
        } else {
            const data = decryptFromStoreLoc("state");
            if (data) {
                setState(data);
                calculateTotal(data);
                setKidsCount(data?.kidsCount || 0);
                setAdultCount(data?.adultCount || 0);
                setKidsTotalAmount(data?.kidsTotalAmount || 0);
                setAdultTotalAmount(data?.adultTotalAmount || 0);
            } else {
                navigate("/subscription-plans");
            }
        }
    }, [kidsCount, adultCount]);

    const handlePayment = async () => {
        try {
            if (kidsCount == 0 && adultCount == 0) {
                toast.error("Please select the number of kids and adults");
                return;
            }

            let paymentObj = {
                number_of_kids: Number(kidsCount) || 0,
                per_kid_price: 36,
                number_of_adults: Number(adultCount) || 0,
                per_adult_price: 72,
                total_amount: totalAmount,
                description: "Family plan",
                plan_type:
                    state?.name === "one"
                        ? "one-one"
                        : state?.name === "two"
                        ? "two-two"
                        : "three-three-gold",
                duration: 1,
                duration_type: "year",
                trail_ids: state.courseTrails,
                ETT_per_hour_price: 0,
                ETT_total_hour: 0,
                OFDW_per_hour_price: 0,
                OFDW_total_hour: 0,
            };

            if (state?.blendedLearning?.length > 0) {
                state.blendedLearning.map((item) => {
                    if (item.name === "ETT") {
                        paymentObj.ETT_per_hour_price = item.perPrice;
                        paymentObj.ETT_total_hour = item.quantity;
                    }
                    if (item.name === "OFDW") {
                        paymentObj.OFDW_per_hour_price = item.perPrice;
                        paymentObj.OFDW_total_hour = item.quantity;
                    }
                });
            }

            const response = await api.postWithToken(
                `${serverURL}payments/payment/`,
                paymentObj
            );
            if (response.data.success) {
                if (response.data.payment_url) {
                    window.location.href = response.data.payment_url;
                } else {
                    toast.success(response.data.message);
                    navigate("/business-family");
                }
            } else {
                console.error("Error:", response.data.message);
            }
        } catch (error) {
            console.error("Error creating payment object", error);
        }
    };

    return (
        <>
            <Header />
            <section>
                <div className="container-fluid">
                    <div className="sign-up-main individual-main">
                        <div className="row justify-content-between align-items-start h-100">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-6 position-relative bg-primary d-flex justify-content-center align-items-center sign-up-col sticky-top top-0">
                                <img
                                    src="./login/sing-vector-1.png"
                                    className="sing-vector-1"
                                    alt=""
                                />
                                <img
                                    src="./login/money-online.png"
                                    className="man-develops"
                                    alt=""
                                />
                                <img
                                    src="./login/sing-vector-2.png"
                                    className="sing-vector-2"
                                    alt=""
                                />
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 col-xl-6 position-relative d-flex justify-content-center align-items-start align-items-md-center">
                                <div className="row g-4 ps-xl-5 selectcard pt-4 pt-md-5 py-lg-0 payment-information-card w-100">
                                    <div className="col-12 pt-lg-5">
                                        <h3 className="welcome-title">
                                            Payment Details
                                        </h3>
                                        <Card>
                                            <Card.Body className="summary-details-list px-4">
                                                <div className="row align-items-center">
                                                    <div className="col-md-8">
                                                        <p className="fs-5 fw-600 mb-0">
                                                            Family Plan
                                                        </p>
                                                    </div>
                                                    <div className="text-start col-md-4 mt-3 mt-md-0">
                                                        <h4 className="mb-2 text_primary">
                                                            <span className="fw-600 text_primary">
                                                                €72
                                                            </span>
                                                            /year per adult
                                                        </h4>
                                                        <h4 className="text_primary">
                                                            <span className="fw-600 text_primary">
                                                                €36
                                                            </span>
                                                            /year per kid
                                                        </h4>
                                                    </div>
                                                </div>
                                                <ul className="family-plan-details mt-4">
                                                    <li>
                                                        €72/year per adult user
                                                    </li>
                                                    <li>
                                                        Kids' Special: €36 for
                                                        each child under 18
                                                    </li>
                                                    <li>
                                                        You can cancel anytime
                                                    </li>
                                                </ul>

                                                <div className="d-flex justify-content-between align-items-center mt-4">
                                                    <div>
                                                        <p className="fw-400 fs-6 text-black mb-0">
                                                            No. Of Kids
                                                        </p>
                                                    </div>
                                                    <input
                                                        value={kidsCount}
                                                        className="input-box"
                                                        type="number"
                                                        onChange={
                                                            handleChangeKidCount
                                                        }
                                                    />
                                                </div>
                                                <div className="d-flex justify-content-between align-items-center mt-3">
                                                    <div>
                                                        <p className="fw-400 fs-6 text-black mb-0">
                                                            No. Of Adults
                                                        </p>
                                                    </div>
                                                    <input
                                                        value={adultCount}
                                                        className="input-box"
                                                        type="number"
                                                        onChange={
                                                            handleChangeAdultCount
                                                        }
                                                    />
                                                </div>
                                                {/* <div class="d-flex justify-content-between align-items-center gap-3 mt-4">
                                                    <div>
                                                        <h5 className='fs-5'>Total</h5>
                                                    </div>
                                                    <div className='text-end'>
                                                        <h5 class="fs-2">€0</h5>
                                                        <p className='mb-0 fw-400 fs-7'>For 1 month</p>
                                                    </div>
                                                </div> */}

                                                <div class="d-flex justify-content-between align-items-center gap-3 mt-4">
                                                    <div>
                                                        <h5 className="fs-20 fw-400">
                                                            {state?.plan}
                                                        </h5>
                                                    </div>
                                                    <div>
                                                        <h4>
                                                            <span>
                                                                €
                                                                {
                                                                    state
                                                                        ?.userPlan
                                                                        ?.perEmployeePrice
                                                                }
                                                            </span>
                                                            /year
                                                        </h4>
                                                    </div>
                                                </div>

                                                {state?.blendedLearning
                                                    ?.length > 0 &&
                                                    state?.blendedLearning.map(
                                                        (item) => {
                                                            return (
                                                                <>
                                                                    <div class="d-flex justify-content-between align-items-center gap-3 mt-4">
                                                                        <div>
                                                                            <h5 className="fs-20 fw-400">
                                                                                {
                                                                                    item.title
                                                                                }
                                                                            </h5>
                                                                        </div>
                                                                        <div>
                                                                            <h4>
                                                                                <span>
                                                                                    €
                                                                                    {
                                                                                        item.total
                                                                                    }
                                                                                </span>
                                                                            </h4>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            );
                                                        }
                                                    )}

                                                {kidsCount > 0 && (
                                                    <div class="d-flex justify-content-between align-items-center gap-3 mt-4">
                                                        <div>
                                                            <h5 className="fs-20 fw-400">
                                                                Kids
                                                            </h5>
                                                        </div>
                                                        <div>
                                                            <h4>
                                                                <span>
                                                                    €
                                                                    {
                                                                        kidsTotalAmount
                                                                    }
                                                                </span>
                                                            </h4>
                                                        </div>
                                                    </div>
                                                )}

                                                {adultCount > 0 && (
                                                    <div class="d-flex justify-content-between align-items-center gap-3 mt-4">
                                                        <div>
                                                            <h5 className="fs-20 fw-400">
                                                                Adults
                                                            </h5>
                                                        </div>
                                                        <div>
                                                            <h4>
                                                                <span>
                                                                    €
                                                                    {
                                                                        adultTotalAmount
                                                                    }
                                                                </span>
                                                            </h4>
                                                        </div>
                                                    </div>
                                                )}

                                                <div class="d-flex justify-content-between align-items-center gap-3 mt-4">
                                                    <div>
                                                        <h5 className="fs-5">
                                                            After free trial
                                                        </h5>
                                                    </div>
                                                    <div className="text-end">
                                                        <h4 className="mb-2">
                                                            <span className="fs-2 fw-600 text-black">
                                                                €{totalAmount}
                                                            </span>
                                                            /year
                                                        </h4>
                                                    </div>
                                                </div>

                                                <div className="d-flex justify-content-between align-items-center gap-3 pt-3">
                                                    <h5>Start billing date</h5>
                                                    {/* <h5>Feb 23, 2024</h5> */}
                                                    <h5>{date}</h5>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                        {/* <Card>
                                            <Card.Body>
                                                <div className='d-flex align-items-start gap-3'>
                                                    <label className='d-flex align-items-center mb-3 input-radio-box-title mt-3'>
                                                        <input
                                                            type="radio"
                                                            className="input-radio-box on"
                                                            name="pilih"
                                                            checked={selectedMethod === 'card'}
                                                            onChange={() => handleChange('card')}
                                                        />
                                                    </label>
                                                    <div>
                                                        <h5>Credit or Debit Card</h5>
                                                        <div className='d-flex align-items-center gap-2'>
                                                            <img src="/png/american.png" width={20} height={20} alt="" />
                                                            <img src="/png/mastercard.png" width={20} height={20} alt="" />
                                                            <img src="/png/visa.png" width={20} height={20} alt="" />
                                                        </div>
                                                        {selectedMethod === 'card' && (
                                                            <form className="manage-family debit-card-form row g-3 mt-4 mb-5">
                                                                <div className="col-12 w-100">
                                                                    <input
                                                                        className="form-control"
                                                                        placeholder="Card Number"
                                                                        type="text"
                                                                        name="iban"
                                                                        defaultValue=""
                                                                    />
                                                                </div>
                                                                <div className="col-12 w-100">
                                                                    <input
                                                                        className="form-control"
                                                                        placeholder="Card Holder’s Name"
                                                                        type="text"
                                                                        name="bankName"
                                                                        defaultValue=""
                                                                    />
                                                                </div>
                                                                <div className="col-6">
                                                                    <input
                                                                        className="form-control"
                                                                        placeholder="CNN"
                                                                        type="text"
                                                                        name="accountHolder"
                                                                        defaultValue=""
                                                                    />
                                                                </div>
                                                                <div className="col-6">
                                                                    <input
                                                                        className="form-control"
                                                                        placeholder="Expiration Date (MM/YY)"
                                                                        type="date"
                                                                        name="accountHolder"
                                                                        defaultValue=""
                                                                    />
                                                                </div>
                                                                
                                                                <div className="form-check d-flex align-items-center gap-3 ms-2 pt-4 manage-family-checkbox">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        id="flexCheckChecked1"
                                                                        defaultChecked=""
                                                                    />
                                                                    <label className="form-check-label" htmlFor="flexCheckChecked1">
                                                                        Save Card Details
                                                                    </label>
                                                                </div>
                                                            </form>
                                                        )}
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card> */}
                                        {/* <Card>
                                            <Card.Body>
                                                <div className='d-flex align-items-start gap-3'>
                                                    <label className='d-flex align-items-center mb-3 input-radio-box-title mt-3'>
                                                        <input
                                                            type="radio"
                                                            className="input-radio-box on"
                                                            name="pilih"
                                                            checked={selectedMethod === 'paypal'}
                                                            onChange={() => handleChange('paypal')}
                                                        />
                                                    </label>
                                                    <div>
                                                        <h5>Paypal</h5>
                                                        <img src="/png/paypal.png" width={72} height={20} alt="" />
                                                        {selectedMethod === 'paypal' && (
                                                            <div className="mt-3">
                                                                <p className='fw-400 fs-7 text-black'>We’ll redirect you to PayPal to complete your purchese.</p>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card> */}
                                        {/* <Card>
                                            <Card.Body>
                                                <div className="form-check d-flex align-items-center gap-3 ms-2 manage-family-checkbox available-trails-cards ">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="flexCheckChecked1"
                                                        defaultChecked=""
                                                    />
                                                    <label className="form-check-label fw-400 fs-7 text-bl" htmlFor="flexCheckChecked1">
                                                        Save Card Details
                                                    </label>
                                                </div>
                                            </Card.Body>
                                        </Card> */}
                                        <div className="d-flex justify-content-end align-items-center mt-4 mb-5">
                                            <button
                                                onClick={handlePayment}
                                                className="btn-explore d-flex align-items-center gap-3 rounded-2 fw-400"
                                            >
                                                Proceed
                                                <div className="ps-0 ps-md-5">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={22}
                                                        height={14}
                                                        viewBox="0 0 22 14"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M1 6.99854H21L16 1"
                                                            stroke="white"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path
                                                            d="M21 6.99854L16 13"
                                                            stroke="white"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                        />
                                                    </svg>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default FamilyUserPaymentDetails;
