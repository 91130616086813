import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import { decryptFromStoreLoc } from '../helper/localStore';
import api from '../helper/api';
import { getServerURL } from '../helper/envConfig';
import { toast } from 'react-toastify';

function AssignCourseModal({ course_id }) {
    const [show, setShow] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [user, setUser] = useState({});

    const serverURL = getServerURL();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const initialValues = {
        completion_date: "",
    };

    const [values, setValues] = useState(initialValues);
    const [submitCount, setSubmitCount] = useState(0);
    const [dataEployee, setDataEployee] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);

    const [checkedItems, setCheckedItems] = useState({});

    // Function to handle checkbox changes
    // const handleCheckboxChange = (event) => {
    //     const { name, checked } = event.target;
    //     setCheckedItems({ ...checkedItems, [name]: checked });
    // };

    const handleSearchChange = (e) => {
        setSearchText(e.target.value);
    };

    const handleCheckboxChange = (event, id) => {
        if (event.target.checked) {
            setSelectedIds(prevIds => [...prevIds, id]); // Add ID
        } else {
            setSelectedIds(prevIds => prevIds.filter(itemId => itemId !== id)); // Remove ID
        }
    };



    useEffect(() => {
        const decryptedInfoResult = decryptFromStoreLoc('user');
        setUser(decryptedInfoResult)
    }, []);

    const handleChange = (e) => {

        const { name, value, checked, type } = e.target;
        let newValue = type === "checkbox" ? checked : value;

        setValues((prevValues) => ({
            ...prevValues,
            [name]: newValue,
        }));

    };
    // 

    const getDataEployee = async () => {
        try {
            const response = await api.getWithToken(`${serverURL}users/family-user/kids-list/`);
            setDataEployee(response.data.data)
        } catch (error) {
            console.error(error);
        };

    }
    
    const assignCourse = async () => {
        try {
            if(values.completion_date && selectedIds.length !== 0 ){
                const response = await api.postWithToken(`${serverURL}users/family-user/course-assign-to-kids/`,{
                    "kid_ids":  selectedIds,
                    "course_id":course_id,
                    "completion_date":values.completion_date

                });
                toast.success(response?.data?.message);
                handleClose()
            }else{
                toast.error("Select a persons and date");
            }
        } catch (error) {
            console.error(error);
        };

    }

    // Effect for fetching quizzes
    useEffect(() => {
        // Decrypt and retrieve isTrue from storage
        getDataEployee();
    }, []);

   

    return (
        <>

            {user?.role_name == "I'm a family member"
                &&
                <button
                    type="button"
                    className="btn-secondary d-flex justify-content-center align-items-center gap-3 fw-600 w-sm-100 rounded-2 "
                    onClick={handleShow}
                >
                    Assign course
                    <img
                        src="./svg/circle-arrow.svg"
                        alt=""
                        className='circle-arrow'
                    />

                </button>
            }


            <Modal show={show} size='lg' centered onHide={handleClose}>
                <Modal.Header className='border-0 p-4 pb-0 p-md-5 pb-md-0'>
                    <div>
                        <Modal.Title className='assign-course-title mb-3 text-bl'>Assign the course</Modal.Title>
                        <h4 className='fw-500'>Internet Driver License</h4>
                    </div>
                </Modal.Header>
                <Modal.Body className='pt-0 p-4 p-md-5 pt-md-0'>
                    <form className="row g-0 form-main">
                        <div className="mb-3 mt-3">
                            <label className="form-label fw-400 fs-18 text-bl">Select a date</label>
                            <input
                                onChange={handleChange}
                                type="date"
                                name='completion_date'
                                className="form-control date-select"
                                defaultValue=""
                                min={new Date().toISOString().split('T')[0]}
                            />
                        </div>
                        <label className='form-label fw-400 fs-18 mb-2 text-bl'>Select a person</label>
                        <div className="form-control sm-none d-flex justify-content-center align-items-center border-0 bg-secondary w-100 rounded-3 overflow-hidden py-0">
                            <Link to="" className="search-icon">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={22}
                                    height={22}
                                    viewBox="0 0 22 22"
                                    fill="none"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M16.4004 10.1416C16.4004 13.4602 13.7102 16.1504 10.3916 16.1504C7.07304 16.1504 4.38281 13.4602 4.38281 10.1416C4.38281 6.82304 7.07304 4.13281 10.3916 4.13281C13.7102 4.13281 16.4004 6.82304 16.4004 10.1416ZM16.4278 14.9638C17.485 13.6422 18.1172 11.9657 18.1172 10.1416C18.1172 5.87488 14.6583 2.41602 10.3916 2.41602C6.12488 2.41602 2.66602 5.87488 2.66602 10.1416C2.66602 14.4083 6.12488 17.8672 10.3916 17.8672C12.2157 17.8672 13.8922 17.235 15.2138 16.1778L17.5102 18.4742C17.8454 18.8094 18.3889 18.8094 18.7242 18.4742C19.0594 18.1389 19.0594 17.5954 18.7242 17.2602L16.4278 14.9638Z"
                                        fill="#002FA7"
                                    />
                                </svg>
                            </Link>
                            <p className="mb-0 ps-2 text-primary">|</p>
                            <input
                                className="form-control border-0 rounded-2 bg-secondary px-0"
                                type="text"
                                placeholder="Search or type"
                                onChange={handleSearchChange}
                            />

                        </div>
                        <div className='mt-4 select-person-list'>
                            {dataEployee.filter(item => item.username.toLowerCase().includes(searchText.toLowerCase())).map((item, index) => (
                                <div key={index} className="form-check mb-3 d-flex align-items-center gap-3">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={`checkbox-${item.user_id}`}
                                        name={`checkbox-${item.user_id}`}
                                        checked={selectedIds.includes(item.user_id)} // Check if ID is in selectedIds
                                        onChange={(e) => handleCheckboxChange(e, item.user_id)} // Pass item ID to handler
                                    />
                                    <label className="form-check-label" htmlFor={`checkbox-${item.user_id}`}>
                                        {item.username}
                                    </label>
                                </div>
                            ))}

                        </div>

                        <button type='button' className="btn-primary rounded-2 text-center w-100 mt-5" onClick={assignCourse}>
                            Assign
                        </button>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default AssignCourseModal;