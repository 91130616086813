import { Link } from 'react-router-dom'
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { decryptFromStoreLoc } from '../helper/localStore';

function CustomerSidbar() {
    const { pathname } = useLocation()
    const [activeLink, setActiveLink] = useState(pathname);


    // const handleLinkClick = (to) => {
    //     setActiveLink(to);
    // };
    const navigate = useNavigate();

    useEffect(() => {
        const maxLength = 12; // Set the character limit

        const scrollingTextElements = document.querySelectorAll('.scrolling-text');

        scrollingTextElements.forEach((element) => {
            const text = element.textContent;
            if (text.length > maxLength) {
                // Calculate animation duration based on text length (adjust speed if needed)
                const animationDuration = (text.length / maxLength) * 5 + 's';

                // Apply CSS styles for scrolling effect
                element.style.animation = `scroll ${animationDuration} linear infinite`;
                element.style.paddingLeft = '20px'; // Adjust this value according to your needs
            }
        });
    }, []);

    const [user, setUser] = useState({});

    useEffect(() => {
        const decryptedInfoResult = decryptFromStoreLoc('user');
        setUser(decryptedInfoResult)
    }, []);


    const handleLogout = () => {
        // console.log("api called");
        // window.history.pushState(null, '', "/");
        // // Your logout logic here
        // // navigate("/")
        sessionStorage.clear();
        localStorage.clear();
        window.location.href = "/";
    };


    return (
        <>
            <div className="d-flex justify-content-start justify-content-xl-end responsive-sidebar">
                <ul className="admin-left-side-menu mt-4">
                    {user?.role === 6 || user?.role === 11 || user?.role === 13 ?
                        <li>
                            <Link
                                to="/business-family"
                                className={`fw-700 ${activeLink === '/business-family' ? 'active' : ''}`}
                            >
                                <img src="./admin-images/png/home.png" alt="" />
                                <div className="scrolling-text-container">
                                    <div className="scrolling-text">Home</div>
                                </div>
                            </Link>
                        </li>
                        : user?.is_main_role === true && user?.role === 2 ?
                            <li>
                                <Link
                                    to="/customer-home"
                                    className={`fw-700 ${activeLink === '/customer-home' ? 'active' : ''}`}
                                >
                                    <img src="./admin-images/png/home.png" alt="" />
                                    <div className="scrolling-text-container">
                                        <div className="scrolling-text">Home</div>
                                    </div>
                                </Link>
                            </li>
                            :
                            <li>
                                <Link
                                    to="/business-home"
                                    className={`fw-700 ${activeLink === '/business-home' || activeLink === '/bulk-upload' || activeLink === '/assign-course' ? 'active' : ''}`}
                                >
                                    <img src="./admin-images/png/home.png" alt="" />
                                    <div className="scrolling-text-container">
                                        <div className="scrolling-text">Home</div>
                                    </div>
                                </Link>
                            </li>
                    }

                    {user?.role === 13 && <li>
                        <Link
                            to="/family"
                            className={`${activeLink === '/family' ? 'active' : ''}`}
                        >
                            <img src="./admin-images/png/family.png" alt="" />
                            <div className="scrolling-text-container">
                                <div className="scrolling-text">Family</div>
                            </div>
                        </Link>
                    </li>}

                    <li>
                        <Link
                            to="/customer-my-progress"
                            className={`${activeLink === '/customer-my-progress' ? 'active' : ''}`}
                        >
                            <img src="./admin-images/png/in-progress.png" alt="" />
                            <div className="scrolling-text-container">
                                <div className="scrolling-text">My Progress</div>
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="/customer-bookmarks"
                            className={`${activeLink === '/customer-bookmarks' ? 'active' : ''}`}
                        >
                            <img
                                src="./admin-images/png/bookmark.png"
                                alt=""
                            />
                            <div className="scrolling-text-container">
                                <div className="scrolling-text">Bookmarks</div>
                            </div>
                        </Link>
                    </li>
                    {/* <li>
                        <Link
                            to="/customer-leaderboard"
                            className={`${activeLink === '/customer-leaderboard' ? 'active' : ''}`}
                        >

                            <img
                                src="./admin-images/png/leaderboard.png"
                                alt=""
                            />
                            <div className="scrolling-text-container">
                                <div className="scrolling-text">Leaderboard</div>
                            </div>
                        </Link>
                    </li> */}
                    <p className="text-black fs-7 my-3">Learning</p>
                    <li>
                        <Link
                            to="/customer-courses"
                            className={`${activeLink === '/customer-courses' ? 'active' : ''}`}
                        >
                            <img
                                src="./admin-images/png/class-room.png"
                                alt=""
                            />
                            <div className="scrolling-text-container">
                                <div className="scrolling-text">Courses</div>
                            </div>
                        </Link>
                    </li>
                    {/* <li>
                        <Link
                            to="/customer-quizzes"
                            className={`${activeLink === '/customer-quizzes' ? 'active' : ''}`}
                        >
                            <img src="./admin-images/png/maybe.png" alt="" />
                            <div className="scrolling-text-container">
                                <div className="scrolling-text">Quizzes</div>
                            </div>
                        </Link>
                    </li> */}
                    <p className="text-black fs-7 my-3">Other</p>
                    <li>
                        <Link
                            to="/announcements"
                            className={`${activeLink === '/announcements' ? 'active' : ''}`}
                        >
                            <img
                                src="./admin-images/png/google-groups.png"
                                alt=""
                            />
                            <div className="scrolling-text-container">
                                <div className="scrolling-text">Community</div>
                            </div>
                        </Link>
                    </li>
                    {(user?.role === 13 || user?.role === 2 ) && <li>
                        <Link
                            to="/user-setting"
                            className={`${activeLink === '/user-setting' ? 'active' : ''}`}
                        >                            <img
                                src="./admin-images/png/setting.png"
                                alt=""
                            />
                            <div className="scrolling-text-container">
                                <div className="scrolling-text">Settings</div>
                            </div>
                        </Link>
                    </li>}

                    <li>
                        <Link
                            to="/"
                            className={`fw-700 ${activeLink === '/business-settings' ? 'active' : ''}`}
                            onClick={() => handleLogout()}
                        >                            <img
                                src="./admin-images/png/shutdown.png"
                                alt=""
                            />
                            <div className="scrolling-text-container">
                                <div className="scrolling-text">Logout</div>
                            </div>
                        </Link>
                    </li>
                </ul>
            </div>
        </>
    )
}

export default CustomerSidbar