import React from 'react'
import Header from '../../layout/Header'
import Footer from '../../layout/Footer'
import { Link } from 'react-router-dom'

function TestEnvironmentWelcome() {
    return (
        <>
            <Header />
            <section>
                <div className="container-fluid">
                    <div className="sign-up-main">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-12 col-md-6 position-relative bg-primary d-flex justify-content-center align-items-center sign-up-col vh-md-100">
                                <img
                                    src="./login/individual-user-vector-1.png"
                                    className="sing-vector-1"
                                    alt=""
                                />
                                <img
                                    src="./login/woman-home.png"
                                    className="project-team"
                                    alt=""
                                />
                                <img
                                    src="./login/individual-user-vector-2.png"
                                    className="sing-vector-2"
                                    alt=""
                                />
                            </div>
                            <div className="col-12 col-md-6 px-4 px-lg-0 position-relative d-flex justify-content-center align-items-center sign-up-col sign-up-col-sm vh-md-100 pb-md-5">
                                <div className="col-12 col-md-12 col-lg-10 col-xl-9">
                                    <h3 className='welcome-title'>Welcome to Your Free Test Environment</h3>
                                    <p className='welcome-description'>Explore our platform with full access for 2 months—no payment required.</p>
                                    <p className='welcome-description'>Experience all the features of our eLearning platform. Add employees, assign courses, and manage compliance with ease. After the trial period, you can choose a plan that fits your needs.</p>
                                    <form className="row form-main payment-buttons">
                                        <div className="col-md-12">
                                            <Link
                                                to="/subscription-plans"
                                                className="btn-explore rounded-2 fw-400 d-inline-block mb-4 mb-md-5"
                                            >
                                                Activate Free Test Environment
                                            </Link>
                                            <p className='welcome-description'>Ready to commit? Skip to Payment Details</p>
                                            <Link
                                                to="/subscription-plans"
                                                // to={"/business-home"}
                                                type='button'
                                                className="btn-primary rounded-2 fw-400"
                                            >
                                                Skip to Payment Details
                                            </Link>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default TestEnvironmentWelcome
