import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const DoughnutChart2 = ({ data }) => {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);

  useEffect(() => {
    if (!data || !data.course_completion_status) {
      console.log('Data is not available');
      return; // Ensure data is available
    }

    // Prepare chart data
    const chartData = {
      labels: ['Completed', 'In Progress', 'Not Started'],
      datasets: [{
        data: [
          data.course_completion_status.completed,
          data.course_completion_status['in-progress'],
          data.course_completion_status['not-started']
        ],
        backgroundColor: ['#FDC93A', '#B8CCFF', '#FF5A59'],
        borderColor: ['#FDC93A', '#B8CCFF', '#FF5A59'],
        borderWidth: 1
      }]
    };

    // Chart configuration
    const config = {
      type: 'doughnut',
      data: chartData,
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'right'
          },
          tooltip: {
            callbacks: {
              title: function (tooltipItem) {
                return chartData.labels[tooltipItem[0].dataIndex];
              },
              label: function (tooltipItem) {
                return `${tooltipItem.dataset.data[tooltipItem.dataIndex]} cases`;
              }
            }
          }
        },
        cutout: '80%',
        animation: {
          onComplete: function () {
            const ctx = this.ctx;
            ctx.font = '400 16px sans-serif'; // Reduced font size for visibility
            ctx.fillStyle = 'black';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            const totalText = `Total\n${data.course_completion_status.total}`;
            const textX = (this.chartArea.left + this.chartArea.right) / 2;
            const textY = (this.chartArea.top + this.chartArea.bottom) / 2;
            ctx.fillText(totalText, textX, textY);
          }
        }
      }
    };

    // Destroy existing chart if there
    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }

    // Create new chart instance
    const ctx = chartRef.current.getContext('2d');
    chartInstanceRef.current = new Chart(ctx, config);

    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
  }, [data]); // Depend on data

  return (
    <div className='test' style={{ width: '300px', height: '300px' }}>
      <canvas ref={chartRef}></canvas>
    </div>
  );
};

export default DoughnutChart2;
