import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Header from './../layout/Header';
import Footer from './../layout/Footer';
import { encryptAndStore } from '../helper/sessionStore'
import { useNavigate } from 'react-router-dom'; // Import useHistory for redirection
import api from '../helper/api';
import { getServerURL } from '../helper/envConfig';

const SignUp = () => {
    const navigate = useNavigate();
    const serverURL = getServerURL();
    const [mainRoles, setMainRoles] = useState([]);

    // Function to fetch quiz questions
    const getRoles = async () => {
        try {

            const response = await api.get(`${serverURL}users/main-roles/`);
            if (response.data) {
                setMainRoles(response.data.data);
                // window.scrollTo(0, 0);
            }

        } catch (error) {
            console.error(error);
        };
    }

    // Effect for fetching quizzes
    useEffect(() => {
        getRoles();
    }, []);

    const selectRole = (role) => {
        encryptAndStore('role', role);
        if (role == 2 ) {
            encryptAndStore('sub-role', null);
            setTimeout(() => {
                navigate("/user-details");
            }, 10); // Adjust the delay as needed
        } else if (role == 1 || role == 7) {
            setTimeout(() => {
                navigate("/users-sign-up");
            }, 10); // Adjust the delay as needed
        }
    }




    return (
        <>
            <Header />
            <section>
                <div className="container-fluid">
                    <div className="sign-up-main">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-12 col-md-6 position-relative bg-primary d-flex justify-content-center align-items-center sign-up-col">
                                <img
                                    src="./login/sing-vector-1.png"
                                    className="sing-vector-1"
                                    alt=""
                                />
                                <img
                                    src="./login/project-team.png"
                                    className="project-team"
                                    alt=""
                                />
                                <img
                                    src="./login/sing-vector-2.png"
                                    className="sing-vector-2"
                                    alt=""
                                />
                            </div>
                            <div className="col-12 col-md-6 position-relative d-flex justify-content-center align-items-start align-items-md-center sign-up-col sign-up-col-sm">
                                <div className="col-12 col-md-9">
                                    <p> I want to login as:</p>

                                    {mainRoles &&
                                        mainRoles?.map((data) => (
                                            <Link
                                                key={data.role_id} // Assuming role_id is unique
                                                // to="" // Uncomment this and set the correct path if needed
                                                onClick={() => selectRole(data.role_id)}
                                                className='d-block'
                                            >
                                                <div className="card border-0 shadow-sm rounded-2 mb-3 w-auto d-inline-flex">
                                                    <div className="d-flex align-items-center gap-3 p-4 " >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width={20}
                                                            height={20}
                                                            viewBox="0 0 20 20"
                                                            fill="none"
                                                        >
                                                            <circle cx={10} cy={10} r={10} fill="#DBE5FF" />
                                                        </svg>
                                                        <p className="text-black fw-400 fs-6 mb-0">
                                                            {data.role_name}
                                                        </p>
                                                    </div>
                                                </div>
                                            </Link>
                                        ))
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default SignUp