import React, { useContext, useEffect, useState } from "react";
import AdminHeader from "../layout/AdminHeader";
import Footer from "../layout/Footer";
import CustomerSidbar from "../components/CustomerSidbar";
import api from "../helper/api";
import { getImageBaseUrl, getServerURL } from "../helper/envConfig";
import { CourseContext } from "../context/CourseContext";
import { toast } from "react-toastify";

const Family = () => {
    const serverURL = getServerURL();
    const { user, token } = useContext(CourseContext);
    const [kids, setKids] = useState([]);
    const [kidDetails, setKidDetails] = useState({
        name: "",
        age: null,
        email: "",
    });
    const [parentDetails, setParentDetails] = useState({
        name: "",
        email: "",
    });
    const [error, setError] = useState({});
    const [parentError, setParentError] = useState({});

    const kidFields = [
        { label: "Kid Name", placeholder: "Name", type: "text", name: "name" },
        {
            label: "Kid Age",
            placeholder: "e.g. 11",
            type: "number",
            name: "age",
        },
        {
            label: "Kid Email",
            placeholder: "Email",
            type: "email",
            name: "email",
        },
    ];

    useEffect(() => {
        getFamilyKids();
    }, []);

    const getFamilyKids = async () => {
        try {
            const response = await api.getWithToken(
                `${serverURL}users/family-user/kids-list/`
            );
            if (response.data.success) {
                setKids(response.data.data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleKidDetailsChange = (event, field) => {
        setError({});
        setKidDetails({ ...kidDetails, [field]: event.target.value });
    };

    const onKidAddCall = async () => {
        try {
            if (!kidDetails.name) {
                setError({
                    ...error,
                    name: "Kid name require",
                });
                return;
            }
            if (!kidDetails.age) {
                setError({
                    ...error,
                    age: "Kid age is required",
                });
                return;
            }

            if (!kidDetails.email) {
                setError({
                    ...error,
                    email: "Kid email is required",
                });
                return;
            }
            // validate email
            if (kidDetails.email) {
                const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
                if (!emailRegex.test(kidDetails.email)) {
                    setError({
                        ...error,
                        email: "Invalid email format",
                    });
                    return;
                }
            }
            const res = await api.postWithToken(
                `${serverURL}users/family-user/kids-list/`,
                { ...kidDetails, type: "kid" }
            );
            if (res.data.success) {
                toast.success(res.data.message);
                getFamilyKids();
                setKidDetails({
                    name: "",
                    age: 0,
                    email: "",
                });
            }
        } catch (error) {
            toast.error(error.response?.data.message);
        }
    };

    const handleParentDetailsChange = (event, field) => {
        setParentError({});
        setParentDetails({ ...parentDetails, [field]: event.target.value });
    };

    const onParentAddCall = async () => {
        try {
            if (!parentDetails.name) {
                setParentError({
                    ...parentError,
                    name: "Parent name is required",
                })
                return;
            }
            if (!parentDetails.email) {
                setParentError({
                   ...parentError,
                    email: "Parent email is required",
                })
                return;
            }
            // validate email
            if (parentDetails.email) {
                const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
                if (!emailRegex.test(parentDetails.email)) {
                    setParentError({
                       ...parentError,
                        email: "Invalid email format",
                    });
                    return;
                }
            }

            const res = await api.postWithToken(
                `${serverURL}users/family-user/kids-list/`,
                {
                    ...parentDetails,
                    type: "parent",
                }
            );
            if (res.data.success) {
                toast.success(res.data.message);
                // getFamilyKids();
                setParentDetails({
                    name: "",
                    email: "",
                });
            }
        } catch (error) {
            toast.error(error.response?.data.message);
        }
    };

    return (
        <>
            <AdminHeader />
            <section>
                <div className="dashbaord-home-main">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-3 col-md-4 col-lg-3 col-xxl-3 d-none d-xl-block px-4 bg-white sidebar-mian">
                                <CustomerSidbar />
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 col-xxl-9 bg-body padding-bottom-70">
                                <div className="row ps-0 ps-lg-4 pe-lg-4 ps-xl-5 dashbaord-right-side justify-content-md-center justify-content-lg-between pt-5">
                                    <h3 className="fw-600 text-black mb-4">
                                        Manage Family Settings
                                    </h3>
                                    <div className="col-12 col-md-6">
                                        <h4 className="text-bl fw-500">
                                            Kids Accounts
                                        </h4>
                                        {kids.map((kid, index) => (
                                            <div
                                                key={index}
                                                className="d-flex align-items-center gap-3 mt-4"
                                            >
                                                {kid.avatar ? (
                                                    <img
                                                        src={
                                                            getImageBaseUrl() +
                                                            kid.avatar
                                                        }
                                                        className="rounded-circle kids-image overflow-hidden"
                                                        alt={kid.name}
                                                    />
                                                ) : (
                                                    <div className="profile-initials text-capitalize display-1 kids-image">
                                                        {kid?.username?.charAt(
                                                            0
                                                        )}
                                                    </div>
                                                )}
                                                <div className="lh-sm">
                                                    <p className="text-bl fw-500 fs-14 mb-0">
                                                        {kid.username}
                                                    </p>
                                                    <p className="text-black-300 fw-400 fs-14 mb-0">
                                                        {kid.email}
                                                    </p>
                                                </div>
                                            </div>
                                        ))}
                                        <h6 className="fw-500 fs-16 text-bl mt-4">
                                            Add Kids Accounts (Up to 7)
                                        </h6>
                                        <form className="manage-family row g-3 mt-1 mb-3">
                                            <div className="col-10">
                                                <label className="mb-2">
                                                    Kid Name
                                                </label>
                                                <input
                                                    className="form-control"
                                                    placeholder={"name"}
                                                    type="text"
                                                    name="name"
                                                    value={kidDetails.name}
                                                    onChange={(e) =>
                                                        handleKidDetailsChange(
                                                            e,
                                                            "name"
                                                        )
                                                    }
                                                />
                                                {error && error.name && (
                                                    <p style={{ color: "red" }}>
                                                        {error.name}
                                                    </p>
                                                )}
                                            </div>
                                            <div className="col-10">
                                                <label className="mb-2">
                                                    Kid Age
                                                </label>
                                                <input
                                                    className="form-control"
                                                    placeholder="e.g. 11"
                                                    type="number"
                                                    name="age"
                                                    onKeyDown={(e) =>
                                                        [
                                                            "e",
                                                            "E",
                                                            "+",
                                                            "-",
                                                            ".",
                                                        ].includes(e.key) &&
                                                        e.preventDefault()
                                                    }
                                                    value={kidDetails.age}
                                                    onChange={(e) =>
                                                        handleKidDetailsChange(
                                                            e,
                                                            "age"
                                                        )
                                                    }
                                                />
                                                {error && error.age && (
                                                    <p style={{ color: "red" }}>
                                                        {error.age}
                                                    </p>
                                                )}
                                            </div>
                                            <div className="col-10">
                                                <label className="mb-2">
                                                    Kid Email
                                                </label>
                                                <input
                                                    className="form-control"
                                                    placeholder="email"
                                                    type="email"
                                                    name="email"
                                                    value={kidDetails.email}
                                                    onChange={(e) =>
                                                        handleKidDetailsChange(
                                                            e,
                                                            "email"
                                                        )
                                                    }
                                                />
                                                {error && error.email && (
                                                    <p style={{ color: "red" }}>
                                                        {error.email}
                                                    </p>
                                                )}
                                            </div>
                                        </form>
                                        {/* <p className='fw-500 text-bl fs-6'>Permissions </p>
                                        <div className='manage-family-checkbox'>
                                            <div className="form-check mb-2">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    defaultValue=""
                                                    id="flexCheckDefault"
                                                />
                                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                                    View content
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    defaultValue=""
                                                    id="flexCheckChecked"
                                                    defaultChecked=""
                                                />
                                                <label className="form-check-label" htmlFor="flexCheckChecked">
                                                    Manage accounts
                                                </label>
                                            </div>
                                        </div> */}
                                        <button
                                            className="add-kids-btn mt-4"
                                            onClick={(e) => onKidAddCall()}
                                        >
                                            Add Kid
                                        </button>
                                    </div>
                                    <div className="col-12 col-md-6 mt-5 mt-md-0">
                                        <h4 className="text-bl fw-500">
                                            Parent Account
                                        </h4>
                                        {user?.user_id && token && (
                                            <div
                                                key={user.id}
                                                className="d-flex align-items-center gap-3 mt-4"
                                            >
                                                {user.user_profile ? (
                                                    <img
                                                        src={
                                                            getImageBaseUrl() +
                                                            user.user_profile
                                                        }
                                                        className="rounded-circle kids-image overflow-hidden"
                                                        alt={user.username}
                                                    />
                                                ) : (
                                                    <div className="profile-initials text-capitalize display-1 kids-image">
                                                        {user?.username?.charAt(
                                                            0
                                                        )}
                                                    </div>
                                                )}
                                                <div className="lh-sm">
                                                    <p className="text-bl fw-500 fs-14 mb-0">
                                                        {user.username}
                                                    </p>
                                                    <p className="text-black-300 fw-400 fs-14 mb-0">
                                                        {user.email}
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                        <h6 className="fw-500 fs-16 text-bl mt-4">
                                            Add Another Parent
                                        </h6>
                                        <form className="manage-family row g-3 mt-1 mb-3">
                                            <div className="col-10">
                                                <label className="mb-2">
                                                    Parent Name
                                                </label>
                                                <input
                                                    className="form-control"
                                                    placeholder="name"
                                                    type="text"
                                                    value={parentDetails.name}
                                                    onChange={(e) =>
                                                        handleParentDetailsChange(
                                                            e,
                                                            "name"
                                                        )
                                                    }
                                                />
                                                {parentError && parentError.name && (
                                                    <p style={{ color: "red" }}>
                                                        {parentError.name}
                                                    </p>
                                                )}
                                            </div>
                                            <div className="col-10">
                                                <label className="mb-2">
                                                    Parent Email
                                                </label>
                                                <input
                                                    className="form-control"
                                                    placeholder="Email"
                                                    type="email"
                                                    value={parentDetails.email}
                                                    onChange={(e) =>
                                                        handleParentDetailsChange(
                                                            e,
                                                            "email"
                                                        )
                                                    }
                                                />
                                                {parentError && parentError.email && (
                                                    <p style={{ color: "red" }}>
                                                        {parentError.email}
                                                    </p>
                                                )}
                                            </div>
                                        </form>
                                        {/* <p className='fw-500 text-bl fs-6'>Permissions </p> */}
                                        {/* <div className='manage-family-checkbox'>

                                            <div className="form-check mb-2">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    defaultValue=""
                                                    id="flexCheckDefault1"
                                                />
                                                <label className="form-check-label" htmlFor="flexCheckDefault1">
                                                    View content
                                                </label>
                                            </div>

                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    defaultValue=""
                                                    id="flexCheckChecked1"
                                                    defaultChecked=""
                                                />
                                                <label className="form-check-label" htmlFor="flexCheckChecked1">
                                                    Manage accounts
                                                </label>
                                            </div>

                                        </div> */}
                                        <button
                                            className="add-kids-btn mt-4"
                                            onClick={() => onParentAddCall()}
                                        >
                                            Add Parent
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default Family;
