import React, { useRef, useState, useEffect, useContext } from 'react'
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import { Link } from 'react-router-dom';


import { CourseContext } from '../context/CourseContext';
import CourseCard from '../components/CourseCard';
import AccessCourse from '../components/AccessCourse';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { getServerURL } from '../helper/envConfig';
import api from '../helper/api';
import Loader from '../components/Loader';
import { SkeletonLoader } from '../components/SkeletonLoader';
import { CardLayout, ListLayout } from '../components/SkeletonLayouts';

const Courses = () => {

    const { setQuizeData, setQuize } = useContext(CourseContext);
    const [courseList, setCourseList] = useState([]);
    const serverURL = getServerURL();
    const [loder, setLoder] = useState(false);


    const getCourses = async () => {
        try {
            setLoder(true)
            const response = await api.get(`${serverURL}courses/courses-list/`);
            setCourseList(response.data.data);
            setLoder(false)

        } catch (error) {
            setCourseList([])
            setLoder(false)

            console.log(error);
        }
    };

    useEffect(() => {
        getCourses()
    }, []);


    const ListLayoutSkeleton = ({ count }) => {
        const skeletonItem = () => (
            <div className="skeleton-list-item loading" style={{ display: 'flex', alignItems: 'start', marginBottom: '15px' }}>
                {/* Number */}
                <div style={{ minWidth: '20px', height: '20px', backgroundColor: '#ccc', marginRight: '10px' }}></div>
                {/* Title */}
                <div style={{ flex: 1, height: '60px', backgroundColor: '#ccc' }}></div>
            </div>

        );

        return (
            <>
                {Array.from({ length: count }, (_, index) => (
                    <React.Fragment key={index}>
                        {skeletonItem()}
                    </React.Fragment>
                ))}
            </>
        );
    };


    return (
        <>
            <Header />

            <section className='py-4'>
                <div className="container">
                    <div className='d-flex justify-content-center align-items-end gap-2 flex-column section-title'>
                        <img src="./png/graduation.png" className='graduation' alt="Graduation Cap" />
                        <h2 className='text-center text-bl pb-5 m-auto'>Courses Catalog</h2>
                    </div>
                    <Row>
                        {loder ? (
                            <>
                                <Col md={4}><ListLayoutSkeleton count={3} /></Col>
                                <Col md={4}><ListLayoutSkeleton count={3} /></Col>
                                <Col md={4}><ListLayoutSkeleton count={3} /></Col>
                                <Col md={4}><ListLayoutSkeleton count={3} /></Col>
                                <Col md={4}><ListLayoutSkeleton count={3} /></Col>
                                <Col md={4}><ListLayoutSkeleton count={3} /></Col>
                                <Col md={4}><ListLayoutSkeleton count={3} /></Col>
                                <Col md={4}><ListLayoutSkeleton count={3} /></Col>
                                <Col md={4}><ListLayoutSkeleton count={3} /></Col>
                                <Col md={4}><ListLayoutSkeleton count={3} /></Col>
                                <Col md={4}><ListLayoutSkeleton count={3} /></Col>
                                <Col md={4}><ListLayoutSkeleton count={3} /></Col>
                                <Col md={4}><ListLayoutSkeleton count={3} /></Col>
                                <Col md={4}><ListLayoutSkeleton count={3} /></Col>
                                <Col md={4}><ListLayoutSkeleton count={3} /></Col>
                                <Col md={4}><ListLayoutSkeleton count={3} /></Col>
                                <Col md={4}><ListLayoutSkeleton count={3} /></Col>
                                <Col md={4}><ListLayoutSkeleton count={3} /></Col>
                            </>) : (
                            courseList.map((item, index) => (
                                <Col key={index} className='col-12 col-md-6 col-lg-4'>
                                    <ul className='footer-btn-list mt-4 p-0'>
                                        <li className='d-flex align-items-start gap-2'>
                                            <p>{index + 1 + "."}</p>
                                            <Link to={`/course-details/${item.course_id}`} className='text-decoration-underline'>
                                                {item.name}
                                            </Link>
                                        </li>
                                    </ul>
                                </Col>
                            ))
                        )}
                    </Row>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default Courses;

