import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {

      series: [{
        name: '2023',
        data: [10, 17, 16, 20, 32, 38, 32, 22, 20, 24, 26, 25],
      }, {
        name: '2024',
        data: [22, 30, 12, 30, 37, 25, 30, 35, 25, 20, 25, 32],
      }],
      options: {
        chart: {
          height: 350,
          with: 100,
          type: 'line',
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth',
          width: 3,
        },
        grid: {
          show: true,
          borderColor: '#edebeb',
          xaxis: {
            lines: {
              show: true
            }
          },
          yaxis: {
            lines: {
              show: false
            }
          }
        },
        xaxis: {
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
        yaxis: {
          labels: {
            offsetX: -14,
            formatter: function (value) {
              return `$${value}k`;
            }
          },
          min: 10,
          max: 40,
          tickAmount: 3,
        },
        colors: ["#2d9cdb", "#ff5b5b"],
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          fontSize: '12px',
          fontWeight: 500,
          fontFamily: 'Poppins, sans-serif',
          labels: {
            colors: '#A3A3A3',
          },
          markers: {
            size: 6,
            shape: 'round',
          },
        },
      },
    };
  }

  render() {
    return (
      <ReactApexChart options={this.state.options} series={this.state.series} type="line" height={350} />
    );
  }
}

export default App;