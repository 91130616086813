import React from "react";

const DataPrivacy = () => {
    return (
        <div className="col-12 col-lg-11 pb-5">
            <div className=" overflow-hidden order-3">
                <div className=" p-4 p-md-5">
                    <h2 className="fw-600 text-black mb-2">
                        unicorso Datenschutzerklärung
                    </h2>
                    <p className="text-muted fw-400 mb-3 fs-7">
                        Willkommen bei UniCorso UG (haftungsbeschränkt). Der
                        Schutz Ihrer persönlichen Daten ist uns ein wichtiges
                        Anliegen. Diese Datenschutzerklärung informiert Sie
                        darüber, wie wir Ihre personenbezogenen Daten auf
                        unserer E-Learning-Plattform verarbeiten. Bitte lesen
                        Sie diese Erklärung sorgfältig durch, um zu verstehen,
                        wie wir mit Ihren Daten umgehen.
                    </p>
                    <h4 className="fw-500 pt-4">1. Verantwortlicher</h4>
                    <p className="fw-600 text-muted mb-2 fs-7 text-bl">
                        Verantwortlich für die Datenverarbeitung ist:
                    </p>
                    <div className="ps-3">
                        <p className="mb-0">
                            {" "}
                            UniCorso UG (haftungsbeschränkt){" "}
                        </p>
                        <p className="mb-0"> Alpenstrasse 18 </p>
                        <p className="mb-0"> 81541 München </p>
                        <p className="mb-0"> Deutschland </p>
                        <p className="mb-0">
                            {" "}
                            E-Mail: datenschutz@unicorso.de{" "}
                        </p>
                    </div>

                    <h4 className="fw-500 pt-4">
                        2. Datenerfassung und -verarbeitung
                    </h4>
                    <p className="fw-500 mb-0">a. Besuch der Website</p>
                    <div className="ps-3">
                        <p className="fw-600 text-muted mb-2 fs-7 text-bl">
                            Beim Besuch unserer Website werden automatisch
                            folgende Daten erhoben:
                        </p>
                        <p className="mb-1 text-bl">- IP-Adresse</p>
                        <p className="mb-1 text-bl">
                            - Datum und Uhrzeit des Zugriffs
                        </p>
                        <p className="mb-1 text-bl">
                            - Browsertyp und -version
                        </p>
                        <p className="mb-1 text-bl">
                            - Verwendetes Betriebssystem
                        </p>
                        <p className="mb-1 text-bl mb-3">
                            - Referrer URL (die zuvor besuchte Seite){" "}
                        </p>

                        <p className="mb-1 text-bl mb-4">
                            Diese Daten werden in Server-Logfiles gespeichert
                            und dienen der Gewährleistung eines reibungslosen
                            Betriebs der Website sowie der Sicherheit und
                            Verbesserung unseres Angebots. Rechtsgrundlage
                            hierfür ist Art. 6 Abs. 1 lit. f DSGVO.
                        </p>
                    </div>

                    <p className="fw-500 mb-0">
                        b. Registrierung und Nutzung der E-Learning-Plattform
                    </p>
                    <p className="fw-600 text-muted mb-2 fs-7 text-bl">
                        Bei der Registrierung und Nutzung unserer
                        E-Learning-Plattform verarbeiten wir folgende Daten:
                    </p>
                    <div className="ps-3 mb-4">
                        <p className="mb-1 text-bl">- Name</p>
                        <p className="mb-1 text-bl">- E-Mail-Adresse</p>
                        <p className="mb-1 text-bl">
                            - Nutzername und Passwort
                        </p>
                        <p className="mb-1 text-bl">
                            - Zahlungsinformationen (bei kostenpflichtigen
                            Kursen){" "}
                        </p>
                        <p className="mb-1 text-bl">
                            - Lernverhalten und Fortschritte
                        </p>
                    </div>

                    <p className="fw-500 mb-0">c. Verwendung von Cookiesorm</p>
                    <p className="mb-1 text-bl mb-3">
                        Wir verwenden nur notwendige Cookies, die für den
                        Betrieb der Website und die Bereitstellung unserer
                        Dienste erforderlich sind. Rechtsgrundlage hierfür ist
                        Art. 6 Abs. 1 lit. f DSGVO.
                    </p>

                    <p className="fw-500 mb-0">
                        d. Einsatz von Künstlicher Intelligenz (KI)
                    </p>
                    <p className="mb-1 text-bl pb-3">
                        Unsere Plattform nutzt KI-Dienste, die über eine API von
                        ChatGPT bereitgestellt werden. Diese KI-Dienste
                        unterstützen uns dabei, personalisierte Lerninhalte und
                        Interaktionen bereitzustellen. Die Verarbeitung Ihrer
                        Daten erfolgt hierbei ebenfalls zur Vertragserfüllung
                        gemäß Art. 6 Abs. 1 lit. b DSGVO.
                    </p>

                    <h4 className="fw-500 pt-4">3. Weitergabe von Daten</h4>
                    <p className="fw-600 text-muted mb-2 fs-7 text-bl">
                        Eine Weitergabe Ihrer personenbezogenen Daten an Dritte
                        erfolgt nur, wenn:
                    </p>

                    <div className="ps-3 mb-4">
                        <p className="mb-1 text-bl">
                            - Sie Ihre ausdrückliche Einwilligung dazu gegeben
                            haben (Art. 6 Abs. 1 lit. a DSGVO),
                        </p>
                        <p className="mb-1 text-bl">
                            - dies zur Erfüllung unserer vertraglichen Pflichten
                            erforderlich ist (Art. 6 Abs. 1 lit. b DSGVO),
                        </p>
                        <p className="mb-1 text-bl">
                            - eine gesetzliche Verpflichtung besteht (Art. 6
                            Abs. 1 lit. c DSGVO),
                        </p>
                        <p className="mb-1 text-bl">
                            - die Weitergabe zur Wahrung berechtigter Interessen
                            erforderlich ist und kein Grund zur Annahme besteht,
                            dass Sie ein überwiegendes schutzwürdiges Interesse
                            an der Nichtweitergabe Ihrer Daten haben (Art. 6
                            Abs. 1 lit. f DSGVO). ,
                        </p>
                    </div>

                    <h4 className="fw-500 pt-4">
                        4. Rechte der betroffenen Personen
                    </h4>
                    <p className="fw-600 text-muted mb-2 fs-7 text-bl">
                        Sie haben das Recht:
                    </p>
                    <div className="ps-3 mb-4">
                        <p className="mb-1 text-bl">
                            - auf Auskunft über die verarbeiteten
                            personenbezogenen Daten (Art. 15 DSGVO),
                        </p>
                        <p className="mb-1 text-bl">
                            - auf Berichtigung unrichtiger Daten (Art. 16
                            DSGVO),
                        </p>
                        <p className="mb-1 text-bl">
                            - auf Löschung Ihrer gespeicherten Daten (Art. 17
                            DSGVO),
                        </p>
                        <p className="mb-1 text-bl">
                            - auf Einschränkung der Verarbeitung (Art. 18
                            DSGVO),
                        </p>
                        <p className="mb-1 text-bl">
                            - auf Datenübertragbarkeit (Art. 20 DSGVO),
                        </p>
                        <p className="mb-1 text-bl">
                            - auf Widerspruch gegen die Verarbeitung (Art. 21
                            DSGVO),
                        </p>
                        <p className="mb-1 text-bl">
                            - Ihre Einwilligung jederzeit zu widerrufen, ohne
                            dass die Rechtmäßigkeit der
                        </p>
                        <p className="mb-1 text-bl">
                            - sich bei einer Aufsichtsbehörde zu beschweren
                            (Art. 77 DSGVO).
                        </p>
                    </div>

                    <h4 className="fw-500 pt-4">
                        5. Kontakt für Datenschutzanfragen
                    </h4>
                    <p className="text-muted mb-2 fs-7 text-bl">
                        Für Fragen zum Datenschutz können Sie uns unter den in
                        Abschnitt 1 genannten Kontaktdaten erreichen
                    </p>

                    <h4 className="fw-500 pt-4">
                        6. Änderungen der Datenschutzerklärung
                    </h4>
                    <p className="text-muted mb-2 fs-7 text-bl">
                        Wir behalten uns vor, diese Datenschutzerklärung
                        anzupassen, um stets den aktuellen rechtlichen
                        Anforderungen zu entsprechen oder Änderungen unserer
                        Dienstleistungen umzusetzen. Die jeweils aktuelle
                        Datenschutzerklärung können Sie jederzeit auf unserer
                        Website einsehen.Wir behalten uns vor, diese
                        Datenschutzerklärung anzupassen, um stets den aktuellen
                        rechtlichen Anforderungen zu entsprechen oder Änderungen
                        unserer Dienstleistungen umzusetzen. Die jeweils
                        aktuelle Datenschutzerklärung können Sie jederzeit auf
                        unserer Website einsehen. >Wir behalten uns vor, diese
                        Datenschutzerklärung anzupassen, um stets den aktuellen
                        rechtlichen Anforderungen zu entsprechen oder Änderungen
                        unserer Dienstleistungen umzusetzen. Die jeweils
                        aktuelle Datenschutzerklärung können Sie jederzeit auf
                        unserer Website einsehen.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default DataPrivacy;
