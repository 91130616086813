import React from 'react';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import CoursesHomeSec from '../components/CoursesHomeSec';
import SidbarCourses from '../components/SidbarCourses';
import CommunitySec from './../components/CommunitySec';
import CoursesListModal from '../components/CoursesListModal';
function CategoryExplore() {
    return (
        <>
            <Header />
            <CoursesHomeSec />
            <section>
                <div className="featured-courses-main">
                    <div className="container">
                        <div className="row featured-courses-row">
                            <div className="col-md-4 col-lg-3 col-xl-2 d-none d-md-block">
                                <SidbarCourses />
                            </div>
                            <div className="col-md-8 col-lg-9 col-xl-10">
                                <div className="row justify-content-between align-items-center">
                                    <div className="col-12">
                                        <div className="section-title col-md-12 col-lg-12 m-auto">
                                            <span className="d-block text-start">Featured Courses</span>
                                            <h2 className="text-start text-black display-6 sm-mb-3">
                                                Our Top Picks for You
                                            </h2>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-4 d-flex justify-content-md-end justify-content-between align-items-center mb-4 mb-md-0">
                                        <CoursesListModal />
                                    </div>
                                </div>
                                <div className="row row-cols-md-1 row-cols-lg-2 row-cols-xl-3 g-4 pb-5">
                                    <div className="col">
                                        <div className="card featured-card overflow-hidden border-0 position-relative accordion-items-shadow h-100">
                                            <img
                                                src="./featured-courses/featured01.jpg"
                                                className="featured-courses-img"
                                                alt=""
                                            />
                                            <span className="badge badge-warning bg-warning position-absolute top-0 start-0">
                                                Bronze
                                            </span>
                                            <div className="card-body">
                                                <span className="badge badge-light rounded-2 fw-400 fs-6">
                                                    Marketing and Sales
                                                </span>
                                                <h5>A Guide to Fraud Prevention</h5>
                                                <p className="d-flex align-items-center gap-2 fw-500 text-dark mt-3 mb-4">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={30}
                                                        height={30}
                                                        viewBox="0 0 30 30"
                                                        fill="none"
                                                    >
                                                        <circle cx={15} cy={15} r={15} fill="#D9D9D9" />
                                                    </svg>
                                                    Professor Sophia Lee
                                                </p>
                                                <div className="row justify-content-between align-items-center mt-4 mb-1">
                                                    <p className="fw-500 text-black mb-0 col-7">Beginner Boost</p>
                                                    <div className="col-5 d-flex justify-content-between align-items-center">
                                                        <ul className="rectangle d-flex align-items-center gap-2  flex-wrap mb-0 p-0">
                                                            <li className="active" />
                                                            <li className="active" />
                                                            <li className="active" />
                                                            <li />
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="row justify-content-between align-items-center">
                                                    <p className="fw-500 text-black col-6 mb-0">Starter Pack</p>
                                                    <div className="col-6 d-flex align-items-center gap-2">
                                                        <img
                                                            src="./featured-courses/piggy-bank.jpg"
                                                            className="wh-25"
                                                            alt=""
                                                        />
                                                        <p className="mb-0 text-black fw-600">Explore Free</p>
                                                    </div>
                                                </div>
                                                <button
                                                    type="button"
                                                    className="btn-primary d-flex align-items-center gap-3 rounded-2 mt-4"
                                                >
                                                    Enroll Today
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={22}
                                                        height={14}
                                                        viewBox="0 0 22 14"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M1 6.99854H21L16 1"
                                                            stroke="white"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path
                                                            d="M21 6.99854L16 13"
                                                            stroke="white"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                        />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card featured-card overflow-hidden border-0 position-relative accordion-items-shadow h-100">
                                            <img
                                                src="./featured-courses/featured02.jpg"
                                                className="featured-courses-img"
                                                alt=""
                                            />
                                            <span className="badge badge-warning bg-warning position-absolute top-0 start-0">
                                                Silver
                                            </span>
                                            <div className="card-body">
                                                <span className="badge badge-light rounded-2 fw-400 fs-6">
                                                    Marketing and Sales
                                                </span>
                                                <h5>Learning to Work with Figma</h5>
                                                <p className="d-flex align-items-center gap-2 fw-500 text-dark mt-3 mb-4">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={30}
                                                        height={30}
                                                        viewBox="0 0 30 30"
                                                        fill="none"
                                                    >
                                                        <circle cx={15} cy={15} r={15} fill="#D9D9D9" />
                                                    </svg>
                                                    Professor Sophia Lee
                                                </p>
                                                <div className="row justify-content-between align-items-center mt-4 mb-1">
                                                    <p className="fw-500 text-black mb-0 col-7">Beginner Boost</p>
                                                    <div className="col-5 d-flex justify-content-between align-items-center">
                                                        <ul className="rectangle d-flex align-items-center gap-2  flex-wrap mb-0 p-0">
                                                            <li className="active" />
                                                            <li className="active" />
                                                            <li className="active" />
                                                            <li />
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="row justify-content-between align-items-center">
                                                    <p className="fw-500 text-black col-6 mb-0">Starter Pack</p>
                                                    <div className="col-6 d-flex align-items-center gap-2">
                                                        <img
                                                            src="./featured-courses/piggy-bank.jpg"
                                                            className="wh-25"
                                                            alt=""
                                                        />
                                                        <p className="mb-0 text-black fw-600">Explore Free</p>
                                                    </div>
                                                </div>
                                                <button
                                                    type="button"
                                                    className="btn-secondary text-primary fw-600 d-flex align-items-center gap-3 rounded-2 mt-4"
                                                >
                                                    Unlock It
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={23}
                                                        height={14}
                                                        viewBox="0 0 23 14"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M1.3335 6.99854H21.3335L16.3335 1"
                                                            stroke="#00237D"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path
                                                            d="M21.3335 6.99854L16.3335 13"
                                                            stroke="#00237D"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                        />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card featured-card overflow-hidden border-0 position-relative accordion-items-shadow h-100">
                                            <img
                                                src="./featured-courses/featured03.jpg"
                                                className="featured-courses-img"
                                                alt=""
                                            />
                                            <span className="badge badge-warning bg-warning position-absolute top-0 start-0">
                                                Gold
                                            </span>
                                            <div className="card-body">
                                                <span className="badge badge-light rounded-2 fw-400 fs-6">
                                                    Marketing and Sales
                                                </span>
                                                <h5>Future of Web Design</h5>
                                                <p className="d-flex align-items-center gap-2 fw-500 text-dark mt-3 mb-4">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={30}
                                                        height={30}
                                                        viewBox="0 0 30 30"
                                                        fill="none"
                                                    >
                                                        <circle cx={15} cy={15} r={15} fill="#D9D9D9" />
                                                    </svg>
                                                    Professor Sophia Lee
                                                </p>
                                                <div className="row justify-content-between align-items-center mt-4 mb-1">
                                                    <p className="fw-500 text-black mb-0 col-7">Beginner Boost</p>
                                                    <div className="col-5 d-flex justify-content-between align-items-center">
                                                        <ul className="rectangle d-flex align-items-center gap-2  flex-wrap mb-0 p-0">
                                                            <li className="active" />
                                                            <li className="active" />
                                                            <li className="active" />
                                                            <li />
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="row justify-content-between align-items-center">
                                                    <p className="fw-500 text-black col-6 mb-0">Starter Pack</p>
                                                    <div className="col-6 d-flex align-items-center gap-2">
                                                        <img
                                                            src="./featured-courses/piggy-bank.jpg"
                                                            className="wh-25"
                                                            alt=""
                                                        />
                                                        <p className="mb-0 text-black fw-600">Explore Free</p>
                                                    </div>
                                                </div>
                                                <button
                                                    type="button"
                                                    className="btn-primary d-flex align-items-center gap-3 rounded-2 mt-4"
                                                >
                                                    Enroll Today
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={22}
                                                        height={14}
                                                        viewBox="0 0 22 14"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M1 6.99854H21L16 1"
                                                            stroke="white"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path
                                                            d="M21 6.99854L16 13"
                                                            stroke="white"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                        />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card featured-card overflow-hidden border-0 position-relative accordion-items-shadow h-100">
                                            <img
                                                src="./featured-courses/featured01.jpg"
                                                className="featured-courses-img"
                                                alt=""
                                            />
                                            <span className="badge badge-warning bg-warning position-absolute top-0 start-0">
                                                Bronze
                                            </span>
                                            <div className="card-body">
                                                <span className="badge badge-light rounded-2 fw-400 fs-6">
                                                    Marketing and Sales
                                                </span>
                                                <h5>A Guide to Fraud Prevention</h5>
                                                <p className="d-flex align-items-center gap-2 fw-500 text-dark mt-3 mb-4">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={30}
                                                        height={30}
                                                        viewBox="0 0 30 30"
                                                        fill="none"
                                                    >
                                                        <circle cx={15} cy={15} r={15} fill="#D9D9D9" />
                                                    </svg>
                                                    Professor Sophia Lee
                                                </p>
                                                <div className="row justify-content-between align-items-center mt-4 mb-1">
                                                    <p className="fw-500 text-black mb-0 col-7">Beginner Boost</p>
                                                    <div className="col-5 d-flex justify-content-between align-items-center">
                                                        <ul className="rectangle d-flex align-items-center gap-2  flex-wrap mb-0 p-0">
                                                            <li className="active" />
                                                            <li className="active" />
                                                            <li className="active" />
                                                            <li />
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="row justify-content-between align-items-center">
                                                    <p className="fw-500 text-black col-6 mb-0">Starter Pack</p>
                                                    <div className="col-6 d-flex align-items-center gap-2">
                                                        <img
                                                            src="./featured-courses/piggy-bank.jpg"
                                                            className="wh-25"
                                                            alt=""
                                                        />
                                                        <p className="mb-0 text-black fw-600">Explore Free</p>
                                                    </div>
                                                </div>
                                                <button
                                                    type="button"
                                                    className="btn-primary d-flex align-items-center gap-3 rounded-2 mt-4"
                                                >
                                                    Enroll Today
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={22}
                                                        height={14}
                                                        viewBox="0 0 22 14"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M1 6.99854H21L16 1"
                                                            stroke="white"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path
                                                            d="M21 6.99854L16 13"
                                                            stroke="white"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                        />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card featured-card overflow-hidden border-0 position-relative accordion-items-shadow h-100">
                                            <img
                                                src="./featured-courses/featured02.jpg"
                                                className="featured-courses-img"
                                                alt=""
                                            />
                                            <span className="badge badge-warning bg-warning position-absolute top-0 start-0">
                                                Silver
                                            </span>
                                            <div className="card-body">
                                                <span className="badge badge-light rounded-2 fw-400 fs-6">
                                                    Marketing and Sales
                                                </span>
                                                <h5>A Guide to Fraud Prevention</h5>
                                                <p className="d-flex align-items-center gap-2 fw-500 text-dark mt-3 mb-4">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={30}
                                                        height={30}
                                                        viewBox="0 0 30 30"
                                                        fill="none"
                                                    >
                                                        <circle cx={15} cy={15} r={15} fill="#D9D9D9" />
                                                    </svg>
                                                    Professor Sophia Lee
                                                </p>
                                                <div className="row justify-content-between align-items-center mt-4 mb-1">
                                                    <p className="fw-500 text-black mb-0 col-7">Beginner Boost</p>
                                                    <div className="col-5 d-flex justify-content-between align-items-center">
                                                        <ul className="rectangle d-flex align-items-center gap-2  flex-wrap mb-0 p-0">
                                                            <li className="active" />
                                                            <li className="active" />
                                                            <li className="active" />
                                                            <li />
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="row justify-content-between align-items-center">
                                                    <p className="fw-500 text-black col-6 mb-0">Starter Pack</p>
                                                    <div className="col-6 d-flex align-items-center gap-2">
                                                        <img
                                                            src="./featured-courses/piggy-bank.jpg"
                                                            className="wh-25"
                                                            alt=""
                                                        />
                                                        <p className="mb-0 text-black fw-600">Explore Free</p>
                                                    </div>
                                                </div>
                                                <button
                                                    type="button"
                                                    className="btn-secondary text-primary fw-600 d-flex align-items-center gap-3 rounded-2 mt-4"
                                                >
                                                    Unlock It
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={23}
                                                        height={14}
                                                        viewBox="0 0 23 14"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M1.3335 6.99854H21.3335L16.3335 1"
                                                            stroke="#00237D"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path
                                                            d="M21.3335 6.99854L16.3335 13"
                                                            stroke="#00237D"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                        />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card featured-card overflow-hidden border-0 position-relative accordion-items-shadow h-100">
                                            <img
                                                src="./featured-courses/featured03.jpg"
                                                className="featured-courses-img"
                                                alt=""
                                            />
                                            <span className="badge badge-warning bg-warning position-absolute top-0 start-0">
                                                Gold
                                            </span>
                                            <div className="card-body">
                                                <span className="badge badge-light rounded-2 fw-400 fs-6">
                                                    Marketing and Sales
                                                </span>
                                                <h5>A Guide to Fraud Prevention</h5>
                                                <p className="d-flex align-items-center gap-2 fw-500 text-dark mt-3 mb-4">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={30}
                                                        height={30}
                                                        viewBox="0 0 30 30"
                                                        fill="none"
                                                    >
                                                        <circle cx={15} cy={15} r={15} fill="#D9D9D9" />
                                                    </svg>
                                                    Professor Sophia Lee
                                                </p>
                                                <div className="row justify-content-between align-items-center mt-4 mb-1">
                                                    <p className="fw-500 text-black mb-0 col-7">Beginner Boost</p>
                                                    <div className="col-5 d-flex justify-content-between align-items-center">
                                                        <ul className="rectangle d-flex align-items-center gap-2  flex-wrap mb-0 p-0">
                                                            <li className="active" />
                                                            <li className="active" />
                                                            <li className="active" />
                                                            <li />
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="row justify-content-between align-items-center">
                                                    <p className="fw-500 text-black col-6 mb-0">Starter Pack</p>
                                                    <div className="col-6 d-flex align-items-center gap-2">
                                                        <img
                                                            src="./featured-courses/piggy-bank.jpg"
                                                            className="wh-25"
                                                            alt=""
                                                        />
                                                        <p className="mb-0 text-black fw-600">Explore Free</p>
                                                    </div>
                                                </div>
                                                <button
                                                    type="button"
                                                    className="btn-primary d-flex align-items-center gap-3 rounded-2 mt-4"
                                                >
                                                    Enroll Today
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={22}
                                                        height={14}
                                                        viewBox="0 0 22 14"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M1 6.99854H21L16 1"
                                                            stroke="white"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path
                                                            d="M21 6.99854L16 13"
                                                            stroke="white"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                        />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card featured-card overflow-hidden border-0 position-relative accordion-items-shadow h-100">
                                            <img
                                                src="./featured-courses/featured01.jpg"
                                                className="featured-courses-img"
                                                alt=""
                                            />
                                            <span className="badge badge-warning bg-warning position-absolute top-0 start-0">
                                                Bronze
                                            </span>
                                            <div className="card-body">
                                                <span className="badge badge-light rounded-2 fw-400 fs-6">
                                                    Marketing and Sales
                                                </span>
                                                <h5>A Guide to Fraud Prevention</h5>
                                                <p className="d-flex align-items-center gap-2 fw-500 text-dark mt-3 mb-4">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={30}
                                                        height={30}
                                                        viewBox="0 0 30 30"
                                                        fill="none"
                                                    >
                                                        <circle cx={15} cy={15} r={15} fill="#D9D9D9" />
                                                    </svg>
                                                    Professor Sophia Lee
                                                </p>
                                                <div className="row justify-content-between align-items-center mt-4 mb-1">
                                                    <p className="fw-500 text-black mb-0 col-7">Beginner Boost</p>
                                                    <div className="col-5 d-flex justify-content-between align-items-center">
                                                        <ul className="rectangle d-flex align-items-center gap-2  flex-wrap mb-0 p-0">
                                                            <li className="active" />
                                                            <li className="active" />
                                                            <li className="active" />
                                                            <li />
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="row justify-content-between align-items-center">
                                                    <p className="fw-500 text-black col-6 mb-0">Starter Pack</p>
                                                    <div className="col-6 d-flex align-items-center gap-2">
                                                        <img
                                                            src="./featured-courses/piggy-bank.jpg"
                                                            className="wh-25"
                                                            alt=""
                                                        />
                                                        <p className="mb-0 text-black fw-600">Explore Free</p>
                                                    </div>
                                                </div>
                                                <button
                                                    type="button"
                                                    className="btn-primary d-flex align-items-center gap-3 rounded-2 mt-4"
                                                >
                                                    Enroll Today
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={22}
                                                        height={14}
                                                        viewBox="0 0 22 14"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M1 6.99854H21L16 1"
                                                            stroke="white"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path
                                                            d="M21 6.99854L16 13"
                                                            stroke="white"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                        />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card featured-card overflow-hidden border-0 position-relative accordion-items-shadow h-100">
                                            <img
                                                src="./featured-courses/featured02.jpg"
                                                className="featured-courses-img"
                                                alt=""
                                            />
                                            <span className="badge badge-warning bg-warning position-absolute top-0 start-0">
                                                Silver
                                            </span>
                                            <div className="card-body">
                                                <span className="badge badge-light rounded-2 fw-400 fs-6">
                                                    Marketing and Sales
                                                </span>
                                                <h5>A Guide to Fraud Prevention</h5>
                                                <p className="d-flex align-items-center gap-2 fw-500 text-dark mt-3 mb-4">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={30}
                                                        height={30}
                                                        viewBox="0 0 30 30"
                                                        fill="none"
                                                    >
                                                        <circle cx={15} cy={15} r={15} fill="#D9D9D9" />
                                                    </svg>
                                                    Professor Sophia Lee
                                                </p>
                                                <div className="row justify-content-between align-items-center mt-4 mb-1">
                                                    <p className="fw-500 text-black mb-0 col-7">Beginner Boost</p>
                                                    <div className="col-5 d-flex justify-content-between align-items-center">
                                                        <ul className="rectangle d-flex align-items-center gap-2  flex-wrap mb-0 p-0">
                                                            <li className="active" />
                                                            <li className="active" />
                                                            <li className="active" />
                                                            <li />
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="row justify-content-between align-items-center">
                                                    <p className="fw-500 text-black col-6 mb-0">Starter Pack</p>
                                                    <div className="col-6 d-flex align-items-center gap-2">
                                                        <img
                                                            src="./featured-courses/piggy-bank.jpg"
                                                            className="wh-25"
                                                            alt=""
                                                        />
                                                        <p className="mb-0 text-black fw-600">Explore Free</p>
                                                    </div>
                                                </div>
                                                <button
                                                    type="button"
                                                    className="btn-secondary text-primary fw-600 d-flex align-items-center gap-3 rounded-2 mt-4"
                                                >
                                                    Unlock It
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={23}
                                                        height={14}
                                                        viewBox="0 0 23 14"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M1.3335 6.99854H21.3335L16.3335 1"
                                                            stroke="#00237D"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path
                                                            d="M21.3335 6.99854L16.3335 13"
                                                            stroke="#00237D"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                        />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card featured-card overflow-hidden border-0 position-relative accordion-items-shadow h-100">
                                            <img
                                                src="./featured-courses/featured03.jpg"
                                                className="featured-courses-img"
                                                alt=""
                                            />
                                            <span className="badge badge-warning bg-warning position-absolute top-0 start-0">
                                                Gold
                                            </span>
                                            <div className="card-body">
                                                <span className="badge badge-light rounded-2 fw-400 fs-6">
                                                    Marketing and Sales
                                                </span>
                                                <h5>A Guide to Fraud Prevention</h5>
                                                <p className="d-flex align-items-center gap-2 fw-500 text-dark mt-3 mb-4">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={30}
                                                        height={30}
                                                        viewBox="0 0 30 30"
                                                        fill="none"
                                                    >
                                                        <circle cx={15} cy={15} r={15} fill="#D9D9D9" />
                                                    </svg>
                                                    Professor Sophia Lee
                                                </p>
                                                <div className="row justify-content-between align-items-center mt-4 mb-1">
                                                    <p className="fw-500 text-black mb-0 col-7">Beginner Boost</p>
                                                    <div className="col-5 d-flex justify-content-between align-items-center">
                                                        <ul className="rectangle d-flex align-items-center gap-2  flex-wrap mb-0 p-0">
                                                            <li className="active" />
                                                            <li className="active" />
                                                            <li className="active" />
                                                            <li />
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="row justify-content-between align-items-center">
                                                    <p className="fw-500 text-black col-6 mb-0">Starter Pack</p>
                                                    <div className="col-6 d-flex align-items-center gap-2">
                                                        <img
                                                            src="./featured-courses/piggy-bank.jpg"
                                                            className="wh-25"
                                                            alt=""
                                                        />
                                                        <p className="mb-0 text-black fw-600">Explore Free</p>
                                                    </div>
                                                </div>
                                                <button
                                                    type="button"
                                                    className="btn-primary d-flex align-items-center gap-3 rounded-2 mt-4"
                                                >
                                                    Enroll Today
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={22}
                                                        height={14}
                                                        viewBox="0 0 22 14"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M1 6.99854H21L16 1"
                                                            stroke="white"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path
                                                            d="M21 6.99854L16 13"
                                                            stroke="white"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                        />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card featured-card overflow-hidden border-0 position-relative accordion-items-shadow h-100">
                                            <img
                                                src="./featured-courses/featured01.jpg"
                                                className="featured-courses-img"
                                                alt=""
                                            />
                                            <span className="badge badge-warning bg-warning position-absolute top-0 start-0">
                                                Bronze
                                            </span>
                                            <div className="card-body">
                                                <span className="badge badge-light rounded-2 fw-400 fs-6">
                                                    Marketing and Sales
                                                </span>
                                                <h5>A Guide to Fraud Prevention</h5>
                                                <p className="d-flex align-items-center gap-2 fw-500 text-dark mt-3 mb-4">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={30}
                                                        height={30}
                                                        viewBox="0 0 30 30"
                                                        fill="none"
                                                    >
                                                        <circle cx={15} cy={15} r={15} fill="#D9D9D9" />
                                                    </svg>
                                                    Professor Sophia Lee
                                                </p>
                                                <div className="row justify-content-between align-items-center mt-4 mb-1">
                                                    <p className="fw-500 text-black mb-0 col-7">Beginner Boost</p>
                                                    <div className="col-5 d-flex justify-content-between align-items-center">
                                                        <ul className="rectangle d-flex align-items-center gap-2  flex-wrap mb-0 p-0">
                                                            <li className="active" />
                                                            <li className="active" />
                                                            <li className="active" />
                                                            <li />
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="row justify-content-between align-items-center">
                                                    <p className="fw-500 text-black col-6 mb-0">Starter Pack</p>
                                                    <div className="col-6 d-flex align-items-center gap-2">
                                                        <img
                                                            src="./featured-courses/piggy-bank.jpg"
                                                            className="wh-25"
                                                            alt=""
                                                        />
                                                        <p className="mb-0 text-black fw-600">Explore Free</p>
                                                    </div>
                                                </div>
                                                <button
                                                    type="button"
                                                    className="btn-primary d-flex align-items-center gap-3 rounded-2 mt-4"
                                                >
                                                    Enroll Today
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={22}
                                                        height={14}
                                                        viewBox="0 0 22 14"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M1 6.99854H21L16 1"
                                                            stroke="white"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path
                                                            d="M21 6.99854L16 13"
                                                            stroke="white"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                        />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card featured-card overflow-hidden border-0 position-relative accordion-items-shadow h-100">
                                            <img
                                                src="./featured-courses/featured02.jpg"
                                                className="featured-courses-img"
                                                alt=""
                                            />
                                            <span className="badge badge-warning bg-warning position-absolute top-0 start-0">
                                                Silver
                                            </span>
                                            <div className="card-body">
                                                <span className="badge badge-light rounded-2 fw-400 fs-6">
                                                    Marketing and Sales
                                                </span>
                                                <h5>A Guide to Fraud Prevention</h5>
                                                <p className="d-flex align-items-center gap-2 fw-500 text-dark mt-3 mb-4">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={30}
                                                        height={30}
                                                        viewBox="0 0 30 30"
                                                        fill="none"
                                                    >
                                                        <circle cx={15} cy={15} r={15} fill="#D9D9D9" />
                                                    </svg>
                                                    Professor Sophia Lee
                                                </p>
                                                <div className="row justify-content-between align-items-center mt-4 mb-1">
                                                    <p className="fw-500 text-black mb-0 col-7">Beginner Boost</p>
                                                    <div className="col-5 d-flex justify-content-between align-items-center">
                                                        <ul className="rectangle d-flex align-items-center gap-2  flex-wrap mb-0 p-0">
                                                            <li className="active" />
                                                            <li className="active" />
                                                            <li className="active" />
                                                            <li />
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="row justify-content-between align-items-center">
                                                    <p className="fw-500 text-black col-6 mb-0">Starter Pack</p>
                                                    <div className="col-6 d-flex align-items-center gap-2">
                                                        <img
                                                            src="./featured-courses/piggy-bank.jpg"
                                                            className="wh-25"
                                                            alt=""
                                                        />
                                                        <p className="mb-0 text-black fw-600">Explore Free</p>
                                                    </div>
                                                </div>
                                                <button
                                                    type="button"
                                                    className="btn-secondary text-primary fw-600 d-flex align-items-center gap-3 rounded-2 mt-4"
                                                >
                                                    Unlock It
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={23}
                                                        height={14}
                                                        viewBox="0 0 23 14"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M1.3335 6.99854H21.3335L16.3335 1"
                                                            stroke="#00237D"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path
                                                            d="M21.3335 6.99854L16.3335 13"
                                                            stroke="#00237D"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                        />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card featured-card overflow-hidden border-0 position-relative accordion-items-shadow h-100">
                                            <img
                                                src="./featured-courses/featured03.jpg"
                                                className="featured-courses-img"
                                                alt=""
                                            />
                                            <span className="badge badge-warning bg-warning position-absolute top-0 start-0">
                                                Gold
                                            </span>
                                            <div className="card-body">
                                                <span className="badge badge-light rounded-2 fw-400 fs-6">
                                                    Marketing and Sales
                                                </span>
                                                <h5>A Guide to Fraud Prevention</h5>
                                                <p className="d-flex align-items-center gap-2 fw-500 text-dark mt-3 mb-4">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={30}
                                                        height={30}
                                                        viewBox="0 0 30 30"
                                                        fill="none"
                                                    >
                                                        <circle cx={15} cy={15} r={15} fill="#D9D9D9" />
                                                    </svg>
                                                    Professor Sophia Lee
                                                </p>
                                                <div className="row justify-content-between align-items-center mt-4 mb-1">
                                                    <p className="fw-500 text-black mb-0 col-7">Beginner Boost</p>
                                                    <div className="col-5 d-flex justify-content-between align-items-center">
                                                        <ul className="rectangle d-flex align-items-center gap-2  flex-wrap mb-0 p-0">
                                                            <li className="active" />
                                                            <li className="active" />
                                                            <li className="active" />
                                                            <li />
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="row justify-content-between align-items-center">
                                                    <p className="fw-500 text-black col-6 mb-0">Starter Pack</p>
                                                    <div className="col-6 d-flex align-items-center gap-2">
                                                        <img
                                                            src="./featured-courses/piggy-bank.jpg"
                                                            className="wh-25"
                                                            alt=""
                                                        />
                                                        <p className="mb-0 text-black fw-600">Explore Free</p>
                                                    </div>
                                                </div>
                                                <button
                                                    type="button"
                                                    className="btn-primary d-flex align-items-center gap-3 rounded-2 mt-4"
                                                >
                                                    Enroll Today
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={22}
                                                        height={14}
                                                        viewBox="0 0 22 14"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M1 6.99854H21L16 1"
                                                            stroke="white"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path
                                                            d="M21 6.99854L16 13"
                                                            stroke="white"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                        />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-center pb-5 py-lg-4 py-xl-5">
                                    <ul className="bg-transparent bottom-dots d-flex justify-content-center align-items-center gap-4">
                                        <li className="active"></li>
                                        <li></li>
                                        <li></li>
                                    </ul>
                                </div>
                                <CommunitySec />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default CategoryExplore