import React, { useRef, useState, useEffect, useContext } from 'react'
import Accordion from 'react-bootstrap/Accordion';
import { Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { CourseContext } from '../context/CourseContext';
import { Is_Login } from '../helper/IsLogin';

const CourseSidebar = ({ name, type, token ,is_bookmarked ,course_id}) => {

  const { manageBookMark, setIsWishlist, isWishlist } = useContext(CourseContext);
  const [showModal, setShowModal] = useState(false);
  const [showMain1, setShowMain1] = useState(true);
  const navigate = useNavigate()
  const isLoggedIn = Is_Login();

  const handleOpenModal = () => {
    setShowModal(true);
    setShowMain1(true);
  };

  const handleTalkBtnClick = () => {
    setShowMain1(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };


  const bookMark = () => {
    if (!isLoggedIn) {
      navigate("/sign-in");
    } else {
      manageBookMark(course_id);
    }
  };
  
     useEffect(() => {
      setIsWishlist(is_bookmarked);
     }, [is_bookmarked]);



  return (
    <>
      <div className="card border-0 position-lg-sticky">
        <div className="card-body p-0">
          <Accordion className='course-accordion' defaultActiveKey="0">
            <Accordion.Item className='accordion-item border-0 py-0' eventKey="0">
              <Accordion.Header>
                {name}
              </Accordion.Header>
              <Accordion.Body className='p-0'>
                <ul className="course-list-menu ps-0">
                  <li className={`row align-items-center gx-0 px-4 py-4 ${type == "Free" && "active"} `} >
                    <div className="col-8 col-md-9 col-xl-9 pe-3">
                      <Link to="" className="d-flex align-items-center">
                        <div>
                          <b className="text-black fs-6">Free :</b>
                          <span className="fs-7 fw-400 text-black">
                            Take the course for
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="col-4 col-md-3 col-xl-3">
                      <span className="badge badge-light text-black rounded-2 fw-400 fs-7 w-100">
                        Free
                      </span>
                    </div>
                  </li>
                  <li className={`row align-items-center gx-0 px-4 py-4 ${type == "Bronze" && "active"} `} >
                    <div className="col-8 col-md-9 col-xl-9 pe-3">
                      <Link to="" className="d-flex align-items-center">
                        <div>
                          <b className="text-black fs-6">Bronze :</b>
                          <span className="fs-7 fw-400 text-black">
                            Take the course for free as one of our members
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="col-4 col-md-3 col-xl-3">
                      <span className="badge badge-bronze rounded-2 fw-400 fs-7 w-100">
                        Bronze
                      </span>
                    </div>
                  </li>
                  <li className={`row align-items-center gx-0 px-4 py-4 ${type == "Silver" && "active"} `} >
                    <div className="col-8 col-md-9 col-xl-9 pe-3">
                      <Link to="" className="d-flex align-items-center">
                        <div>
                          <b className="text-black fs-6">Silver :</b>
                          <span className="fs-7 fw-400 text-black">
                            Take the course for free as one of our members
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="col-4 col-md-3 col-xl-3">
                      <span className="badge badge-silver rounded-2 fw-400 fs-7 w-100">
                        Silver
                      </span>
                    </div>
                  </li>
                  <li className={`row align-items-center gx-0 px-4 py-4 ${type == "Gold" && "active"} `} >
                    <div className="col-8 col-md-9 col-xl-9 pe-3">
                      <Link to="" className="d-flex align-items-center">
                        <div>
                          <b className="text-black fs-6">Gold :</b>
                          <span className="fs-7 fw-400 text-black">
                            Take the course for free as one of our members
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="col-4 col-md-3 col-xl-3">
                      <span className="badge badge-gold rounded-2 fw-400 fs-7 w-100">
                        Gold
                      </span>
                    </div>
                  </li>
                </ul>
                <button className="btn-primary w-100 text-center fw-400 fs-7 px-5">
                  Take a test for free with your score. You do not have to
                  sign up and it is free.
                </button>
                <button className="btn-primary bg-black w-100 text-center fw-400 fs-7 px-5" id="Btn-Open-model" onClick={handleOpenModal}>
                  Or you can get a 1-hour inspirational talk on this topic
                </button>
                <div className="px-4 py-5 border-bottom border-secondary">
                  <span className="text-primary fw-500 fs-6">
                    Join the learning adventure
                  </span>
                  <h4 className="fw-400 mt-3 text-black">
                    Creating an account is quick, easy, and completely free!
                  </h4>
                  <p className="text-black fw-400 fs-7 mt-4 pb-4">
                    Don't miss out on the opportunity to dive into our
                    diverse array of courses, engage with expert
                    instructors, and connect with a vibrant community of
                    learners. Whether you're a curious beginner or a
                    seasoned learner, our platform offers something for
                    everyone.
                  </p>
                {!isLoggedIn &&
                  <Link to="/sign-up"
                    className="btn-primary rounded-2 w-auto"
                  >
                    Sign Up
                    <span className='ps-2'>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={22}
                        height={14}
                        viewBox="0 0 22 14"
                        fill="none"
                      >
                        <path
                          d="M1 6.99854H21L16 1"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M21 6.99805L16 12.9995"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                      </svg>
                    </span>
                  </Link>
                }
              
                </div>
                <div className="px-4 py-5">
                  <span className="text-primary fw-500 fs-6">
                    Stay Organized
                  </span>
                  <h3 className="fw-400 mt-3 text-black-primary">
                    Save this course for later
                  </h3>
                  <p className="text-black-primary fw-400 fs-18 mt-4">
                    Bookmarking a course is a simple yet effective way to
                    organize your learning journey
                  </p>
                  <button
                    type="button"
                    onClick={bookMark}
                    className={`${isWishlist ? "btn-explore" : "btn-primary"} d-flex align-items-center gap-3 rounded-2 mt-4`}
                  >
                   {isWishlist ? "Bookmarked" : "Bookmark" } 
                    <img
                      src= {isWishlist ?  "../featured-courses/bookmark_added4.png" : "../featured-courses/bookmark_added.png"} 
                      className="wh-25"
                      alt=""
                    />
                  </button>

                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
      <Modal show={showModal} onHide={handleCloseModal} centered scrollable size="lg">
        <Modal.Header closeButton className="justify-content-end border-0 pb-0 talk-modal mt-2">
        </Modal.Header>
        <Modal.Body className="px-md-5 pb-md-5">
          {showMain1 ? (
            <div id="talk-div-main1">
              <div id="talk-div-main1">
                <p className="text-primary fs-7 fw-400">Book an inspirational speaker</p>
                <h2 className="fw-400 mb-4">Let’s have an Inspirational Talk</h2>
                <p className="fw-400 fs-6 text-bl">
                  You can book an inspirational speaker for 1-2 hours for the price of
                  approximately 1000+ Euros for small or large audiences. It can be online
                  or in-person.
                </p>
                <form className="row gy-3 inspirational-talk-popup">
                  <div className="col-md-12">
                    <label
                      htmlFor="inputPassword4"
                      className="form-label text-bl fs-6 fw-400"
                    >
                      Name
                    </label>
                    <input className="form-control" id="Name" placeholder="Add Name" />
                  </div>
                  <div className="col-md-12">
                    <label htmlFor="inputEmail4" className="form-label text-bl fs-6 fw-400">
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="inputEmail4"
                      placeholder="Email"
                    />
                  </div>
                  <div className="col-12">
                    <label
                      htmlFor="inputAddress"
                      className="form-label text-bl fs-6 fw-400"
                    >
                      Company Or Organization
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="inputAddress"
                      placeholder="AICM Inc"
                    />
                  </div>
                  <div className="col-12">
                    <label
                      htmlFor="inputAddress"
                      className="form-label text-bl fs-6 fw-400"
                    >
                      Preferred Course Delivery Method
                    </label>
                    <div className="bg-secondary rounded-2">
                      <div className="d-flex justify-content-between align-items-center pe-2 op-select">
                        <div id="flip">I'd like to schedule an Online Meeting.</div>
                        <i className="fa-solid fa-chevron-down text-primary" id="op-icon" />
                      </div>
                      <div className="option op-select">
                        I'd like to have a meeting in person.
                      </div>
                      <div className="option op-select">I haven't decided yet.</div>
                    </div>
                  </div>
                  <div className="col-12" id="location">
                    <label
                      htmlFor="inputAddress"
                      className="form-label text-bl fs-6 fw-400"
                    >
                      Let us know the location
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="inputAddress"
                      placeholder="PO Box 00, Munich, Germany"
                    />
                  </div>
                  <div className="col-12">
                    <label className="form-label text-bl fs-6 fw-400">
                      Any Further Wishes
                    </label>
                    <div className="form-floating">
                      <textarea
                        className="form-control rows pt-3"
                        placeholder="Need a 1hr session."
                        id="floatingTextarea2"
                        defaultValue={""}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="d-flex justify-content-start">
                      <button id="talk-btn" className="btn-primary btn-light-warning text-black rounded-2 mt-4 fw-600" onClick={handleTalkBtnClick}>
                        Let's Talk
                        <span className='ps-3'>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={22}
                            height={14}
                            viewBox="0 0 22 14"
                            fill="none"
                          >
                            <path
                              d="M1 6.99854H21L16 1"
                              stroke="#000C2A"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M21 6.99805L16 12.9995"
                              stroke="#000C2A"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                            />
                          </svg>
                        </span>

                      </button>
                    </div>
                  </div>
                </form>


              </div>
            </div>
          ) : (
            <div id="talk-div-main2">
              <p className="text-primary fs-7 fw-400 text-center">
                Book an inspirational speaker
              </p>
              <div className="d-flex justify-content-center">
                <img
                  src="../gif/talk.gif"
                  className="talk-gif mb-4"
                  alt=""
                />
              </div>
              <h2 className="fw-400 text-black text-center">Thank You!</h2>
              <p className="fs-16 fw-400 text-black text-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={13}
                  height={14}
                  viewBox="0 0 13 14"
                  fill="none"
                >
                  <circle cx="6.5" cy={7} r="6.5" fill="#D2E23D" />
                  <path
                    d="M5.30155 8.68181L3.49189 6.87086L2.88867 7.47451L5.30155 9.88911L10.472 4.71498L9.86879 4.11133L5.30155 8.68181Z"
                    fill="#000C2A"
                  />
                </svg>
                We received your request. We will get back to you as soon as possible.
              </p>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default CourseSidebar