import React from 'react';
import { getImageBaseUrl } from '../helper/envConfig';
import { useNavigate } from 'react-router-dom';

const CategorieData = ({ categories }) => {

    const history = useNavigate()

    const redirectSubcategories = (redirect) => {
        history(redirect)
    };


    return (
        <>
            <section>
                <div className="categories-main">
                    <div className="container">
                        <div className="section-title col-md-8 col-lg-8 col-xl-6 m-auto">
                            <span className="d-block">Knowledge Hub</span>
                            <h2>Explore Our Extensive Course Categories</h2>
                        </div>
                        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-3 categorie-row g-3">
                            {categories && categories.slice(0, 12).map((cat, index) => (
                                <div className="col" key={index} onClick={() => redirectSubcategories(`/courses-category/${cat.name.toLowerCase().replace(/ /g, '-')}`)}>
                                    <div className="card overflow-hidden rounded-2 border-0 h-100 p-0">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="categorie-card-left d-flex align-items-center w-100 gap-4 px-3 py-4">
                                                <img
                                                    src={getImageBaseUrl() + cat.icon}
                                                    className="categorie-img"
                                                    alt={cat.name}
                                                />
                                                <h5>{cat.name}</h5>
                                            </div>
                                            <div className="px-3">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="16" viewBox="0 0 26 16" fill="none">
                                                    <path d="M0.5 8H25.5L15.5 0.5" stroke="#00237D" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M25.5 8L15.5 15.5" stroke="#00237D" strokeLinecap="round" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}


                            {/* <div className="col">
                                <div className="card overflow-hidden rounded-2 border-0 h-100 p-0">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="categorie-card-left d-flex align-items-center w-100 gap-4 px-3 py-4">
                                            <img
                                                src="./knowledge-hub/categorie02.png"
                                                className="categorie-img"
                                                alt=""
                                            />
                                            <h5>History</h5>
                                        </div>
                                        <div className="px-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="16" viewBox="0 0 26 16" fill="none">
                                                <path d="M0.5 8H25.5L15.5 0.5" stroke="#00237D" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M25.5 8L15.5 15.5" stroke="#00237D" stroke-linecap="round" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card overflow-hidden rounded-2 border-0 h-100 p-0">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="categorie-card-left d-flex align-items-center w-100 gap-4 px-3 py-4">
                                            <img
                                                src="./knowledge-hub/categorie03.png"
                                                className="categorie-img"
                                                alt=""
                                            />
                                            <h5>Legal Topics</h5>
                                        </div>
                                        <div className="px-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="16" viewBox="0 0 26 16" fill="none">
                                                <path d="M0.5 8H25.5L15.5 0.5" stroke="#00237D" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M25.5 8L15.5 15.5" stroke="#00237D" stroke-linecap="round" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card overflow-hidden rounded-2 border-0 h-100 p-0">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="categorie-card-left d-flex align-items-center w-100 gap-4 px-3 py-4">
                                            <img
                                                src="./knowledge-hub/categorie04.png"
                                                className="categorie-img"
                                                alt=""
                                            />
                                            <h5>IT Security</h5>
                                        </div>
                                        <div className="px-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="16" viewBox="0 0 26 16" fill="none">
                                                <path d="M0.5 8H25.5L15.5 0.5" stroke="#00237D" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M25.5 8L15.5 15.5" stroke="#00237D" stroke-linecap="round" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card overflow-hidden rounded-2 border-0 h-100 p-0">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="categorie-card-left d-flex align-items-center w-100 gap-4 px-3 py-4">
                                            <img
                                                src="./knowledge-hub/categorie05.png"
                                                className="categorie-img"
                                                alt=""
                                            />
                                            <h5>Philosophy</h5>
                                        </div>
                                        <div className="px-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="16" viewBox="0 0 26 16" fill="none">
                                                <path d="M0.5 8H25.5L15.5 0.5" stroke="#00237D" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M25.5 8L15.5 15.5" stroke="#00237D" stroke-linecap="round" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card overflow-hidden rounded-2 border-0 h-100 p-0">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="categorie-card-left d-flex align-items-center w-100 gap-4 px-3 py-4">
                                            <img
                                                src="./knowledge-hub/categorie06.png"
                                                className="categorie-img"
                                                alt=""
                                            />
                                            <h5>Technology</h5>
                                        </div>
                                        <div className="px-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="16" viewBox="0 0 26 16" fill="none">
                                                <path d="M0.5 8H25.5L15.5 0.5" stroke="#00237D" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M25.5 8L15.5 15.5" stroke="#00237D" stroke-linecap="round" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card overflow-hidden rounded-2 border-0 h-100 p-0">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="categorie-card-left d-flex align-items-center w-100 gap-4 px-3 py-4">
                                            <img
                                                src="./knowledge-hub/categorie07.png"
                                                className="categorie-img"
                                                alt=""
                                            />
                                            <h5>Compliance</h5>
                                        </div>
                                        <div className="px-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="16" viewBox="0 0 26 16" fill="none">
                                                <path d="M0.5 8H25.5L15.5 0.5" stroke="#00237D" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M25.5 8L15.5 15.5" stroke="#00237D" stroke-linecap="round" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card overflow-hidden rounded-2 border-0 h-100 p-0">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="categorie-card-left d-flex align-items-center w-100 gap-4 px-3 py-4">
                                            <img
                                                src="./knowledge-hub/categorie08.png"
                                                className="categorie-img"
                                                alt=""
                                            />
                                            <h5>Employment Law and HR</h5>
                                        </div>
                                        <div className="px-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="16" viewBox="0 0 26 16" fill="none">
                                                <path d="M0.5 8H25.5L15.5 0.5" stroke="#00237D" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M25.5 8L15.5 15.5" stroke="#00237D" stroke-linecap="round" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card overflow-hidden rounded-2 border-0 h-100 p-0">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="categorie-card-left d-flex align-items-center w-100 gap-4 px-3 py-4">
                                            <img
                                                src="./knowledge-hub/categorie09.png"
                                                className="categorie-img"
                                                alt=""
                                            />
                                            <h5>Startup Topics</h5>
                                        </div>
                                        <div className="px-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="16" viewBox="0 0 26 16" fill="none">
                                                <path d="M0.5 8H25.5L15.5 0.5" stroke="#00237D" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M25.5 8L15.5 15.5" stroke="#00237D" stroke-linecap="round" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card overflow-hidden rounded-2 border-0 h-100 p-0">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="categorie-card-left d-flex align-items-center w-100 gap-4 px-3 py-4">
                                            <img
                                                src="./knowledge-hub/categorie10.png"
                                                className="categorie-img"
                                                alt=""
                                            />
                                            <h5>Personal Well-being</h5>
                                        </div>
                                        <div className="px-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="16" viewBox="0 0 26 16" fill="none">
                                                <path d="M0.5 8H25.5L15.5 0.5" stroke="#00237D" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M25.5 8L15.5 15.5" stroke="#00237D" stroke-linecap="round" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card overflow-hidden rounded-2 border-0 h-100 p-0">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="categorie-card-left d-flex align-items-center w-100 gap-4 px-3 py-4">
                                            <img
                                                src="./knowledge-hub/categorie11.png"
                                                className="categorie-img"
                                                alt=""
                                            />
                                            <h5>Marketing and Sales</h5>
                                        </div>
                                        <div className="px-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="16" viewBox="0 0 26 16" fill="none">
                                                <path d="M0.5 8H25.5L15.5 0.5" stroke="#00237D" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M25.5 8L15.5 15.5" stroke="#00237D" stroke-linecap="round" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card overflow-hidden rounded-2 border-0 h-100 p-0">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="categorie-card-left d-flex align-items-center w-100 gap-4 px-3 py-4">
                                            <img
                                                src="./knowledge-hub/categorie12.png"
                                                className="categorie-img"
                                                alt=""
                                            />
                                            <h5>Social Issues</h5>
                                        </div>
                                        <div className="px-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="16" viewBox="0 0 26 16" fill="none">
                                                <path d="M0.5 8H25.5L15.5 0.5" stroke="#00237D" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M25.5 8L15.5 15.5" stroke="#00237D" stroke-linecap="round" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default CategorieData;
