import React, { forwardRef, useImperativeHandle, useState } from 'react'

const PopularityModal = forwardRef(({ setFilterPopularity, handleToggle }, ref) => {
    // State to keep track of selected statuses
    const [selectedPopularity, setSelectedPopularity] = useState(null);

    // Function to handle button clicks
    const handleClick = (popularity) => {
        if (selectedPopularity && selectedPopularity.key === popularity.key) {
            setSelectedPopularity(null);
        } else  {
            setSelectedPopularity(popularity);
        }
    };

    const popularity = [
        { key: '1', label: 'From 1 to 20', value : { from_percentage : 1, to_percentage: 20 } },
        { key: '2', label: 'From 21 to 40', value : { from_percentage : 21, to_percentage: 40 }},
        { key: '3', label: 'From 41 to 60', value : { from_percentage : 41, to_percentage: 60 }},
        { key: '4', label: 'From 61 to 80', value : { from_percentage : 61, to_percentage: 80 }},
        { key: '5', label: 'From 81 to 100', value : { from_percentage : 81, to_percentage: 100 }},
    ];

    const onApplyClick = () => {
        if(selectedPopularity?.value) {
            setFilterPopularity(selectedPopularity.value);
        } else {
            setFilterPopularity(null);
        }
        handleToggle("popularity")
    };

    useImperativeHandle(ref, () => ({
        resetFilter: () => {
            setSelectedPopularity(null);
            setFilterPopularity(null);
        }
    }));

    return (
        <>
            <div className="select-user-status-modal">
                <div className='p-4'>
                    <h2>Select Popularity Percentage</h2>

                    <div className='d-flex align-items-center user-status-buttons gap-2 mt-4 flex-wrap'>
                        {popularity.map((status) => (
                            <button
                                key={status.key}
                                className={selectedPopularity?.key === status.key  ? 'active' : ''}
                                onClick={() => handleClick(status)}
                            >
                                {status.label}
                            </button>
                        ))}
                    </div>


                </div>
                <div className="p-4 d-flex justify-content-between align-items-start border-dark-top-100">
                    <p className='pb-0 text-light-300 fw-400 fs-7 fw-400 fs-7'>*You can choose single popularity percentage</p>
                    <button className='apply-btn' onClick={onApplyClick}>
                        Apply
                    </button>
                </div>
            </div>
        </>
    )
})

export default PopularityModal
