import React, { useContext, useEffect, useState } from 'react';
import { Nav, Tab, Row, Col } from 'react-bootstrap';
import AdminHeader from '../layout/AdminHeader';
import BusinessSidebar from '../components/BusinessSidebar';
import Footer from '../layout/Footer';
import { Link } from 'react-router-dom';
import ComplianceScoreChart from '../components/ComplianceScoreChart';
import DoughnutChart2 from '../components/DoughnutChart2';
import { decryptFromStoreLoc } from '../helper/localStore';
import { useNavigate } from 'react-router-dom'
import { CourseContext } from '../context/CourseContext';
import api from '../helper/api';
import { getImageBaseUrl, getServerURL } from '../helper/envConfig';
import DynamicPagination from "../components/DynamicPagination.js"
function EmployeesProgress() {

    const { token, loder, setLoder, getCourses, setIs_search, setSearch } = useContext(CourseContext);
    const [activeKey, setActiveKey] = useState('1');
    const [user, setUser] = useState({});
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    const serverURL = getServerURL();
    const history = useNavigate(); // Get the history object for redirection

    const handleSelect = (key) => {
        setActiveKey(key);
    };


    useEffect(() => {
        const decryptedInfoResult = decryptFromStoreLoc('user');
        if (decryptedInfoResult?.role == 8) {
            setUser(decryptedInfoResult)
        } else {
            history("/")
        }
    }, []);

    const getQuizesAns = async () => {
        try {
            setLoder(true)
            const response = await api.getWithToken(`${serverURL}users/line-manager/employee-progress/?page=${currentPage}`);
            setData(response.data)
            setLoder(false)

        } catch (error) {
            setLoder(false)
            console.log(error);
        }
    };

    useEffect(() => {
        // Call the function to get quiz results
        getQuizesAns();

    }, [currentPage]); // Add serverURL to the dependency array



    return (
        <>
            <AdminHeader />
            <section>
                <div className="dashbaord-home-main">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-3 col-md-4 col-lg-3 col-xxl-3 d-none d-xl-block px-4 bg-white sidebar-mian">
                                <BusinessSidebar />
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 col-xxl-9 bg-body">
                                <div className="row ps-0 ps-lg-4 pe-lg-4 ps-xl-5 dashbaord-right-side justify-content-md-center justify-content-lg-between pt-5">
                                    <div className="col-12 col-md-11 col-lg-12 right-side">
                                        <div className="d-md-flex justify-content-between align-items-center gap-3 pt-5 pt-md-0 pb-0 pb-md-4">
                                            <h2 className="text-black fw-600">Employees Progress</h2>
                                        </div>
                                        <div className="row g-4 mt-2 mt-md-0">
                                            <div className="col-12 col-lg-12">
                                                <div className="card border-0">
                                                    <div className="card-body">
                                                        <div className="table-responsive compliance-score-dashboard">
                                                            <table className="table align-middle">
                                                                <thead>
                                                                    <tr className="pb-5">
                                                                        <th className="table-th-width-350">
                                                                            <div className="d-flex justify-content-between align-items-center">
                                                                                <p className="fw-600 fs-6 text-bl mb-0 text-nowrap">Name</p>
                                                                            </div>
                                                                        </th>
                                                                        <th>
                                                                            <div className="d-flex justify-content-between align-items-center">
                                                                                <p className="fw-600 fs-6 text-bl mb-0 text-nowrap">
                                                                                    Mandatory Courses
                                                                                </p>
                                                                            </div>
                                                                        </th>
                                                                        <th>
                                                                            <p className="fw-600 fs-6 text-bl mb-0 text-nowrap">
                                                                                Mandatory Courses Done
                                                                            </p>
                                                                        </th>
                                                                        <th>
                                                                            <p className="fw-600 fs-6 text-bl mb-0 text-nowrap">
                                                                                Completion Rate
                                                                            </p>
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>

                                                                    {data.results?.employees.map((employee, index) => (

                                                                        <tr key={index}>
                                                                            <td className="rounded-start-4">
                                                                                <div className="row">
                                                                                    <div className="col-12 overflow-hidden">
                                                                                        <Link className="d-flex align-items-center gap-2">
                                                                                            <img
                                                                                                src={employee.image ? `${getImageBaseUrl()}${employee.image}` : "admin-images/png/toppers1.png"}
                                                                                                alt=""
                                                                                                className="toppers"
                                                                                            />


                                                                                            <div className="student-details">
                                                                                                <p className="fw-600 fs-7 text-bl mb-1 table-th-width-167">
                                                                                                    {employee.username}
                                                                                                </p>
                                                                                            </div>
                                                                                        </Link>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div className="d-flex align-items-center gap-1">
                                                                                    <p className="loder-text mb-0">{employee.mandatory_courses}</p>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div className="d-flex align-items-center gap-1">
                                                                                    <p className="loder-text mb-0">{employee.mandatory_completed_courses}</p>
                                                                                </div>
                                                                            </td>
                                                                            <td className="rounded-end-4">
                                                                                <p className="loder-text mb-0">{employee.completed_rate}</p>
                                                                            </td>
                                                                        </tr>

                                                                    ))

                                                                    }


                                                                    {/* <tr>
                                                                        <td className="rounded-start-4">
                                                                            <div className="row">
                                                                                <div className="col-12 overflow-hidden">
                                                                                    <Link to="/employee-profile" className="d-flex align-items-center gap-2">
                                                                                        <img
                                                                                            src="admin-images/png/toppers1.png"
                                                                                            alt=""
                                                                                            className="toppers"
                                                                                        />
                                                                                        <div className="student-details">
                                                                                            <p className="fw-600 fs-7 text-bl mb-1 table-th-width-167">
                                                                                                Glenn
                                                                                            </p>
                                                                                        </div>
                                                                                    </Link>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="d-flex align-items-center gap-1">
                                                                                <p className="loder-text mb-0">10</p>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="d-flex align-items-center gap-1">
                                                                                <p className="loder-text mb-0">9</p>
                                                                            </div>
                                                                        </td>
                                                                        <td className="rounded-end-4">
                                                                            <p className="loder-text mb-0">90%</p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="rounded-start-4">
                                                                            <div className="row">
                                                                                <div className="col-12 overflow-hidden">
                                                                                    <Link to="/employee-profile" className="d-flex align-items-center gap-2">
                                                                                        <img
                                                                                            src="admin-images/png/toppers1.png"
                                                                                            alt=""
                                                                                            className="toppers"
                                                                                        />
                                                                                        <div className="student-details">
                                                                                            <p className="fw-600 fs-7 text-bl mb-1 table-th-width-167">
                                                                                                Glenn
                                                                                            </p>
                                                                                        </div>
                                                                                    </Link>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="d-flex align-items-center gap-1">
                                                                                <p className="loder-text mb-0">10</p>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="d-flex align-items-center gap-1">
                                                                                <p className="loder-text mb-0">9</p>
                                                                            </div>
                                                                        </td>
                                                                        <td className="rounded-end-4">
                                                                            <p className="loder-text mb-0">90%</p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="rounded-start-4">
                                                                            <div className="row">
                                                                                <div className="col-12 overflow-hidden">
                                                                                    <Link to="/employee-profile" className="d-flex align-items-center gap-2">
                                                                                        <img
                                                                                            src="admin-images/png/toppers1.png"
                                                                                            alt=""
                                                                                            className="toppers"
                                                                                        />
                                                                                        <div className="student-details">
                                                                                            <p className="fw-600 fs-7 text-bl mb-1 table-th-width-167">
                                                                                                Glenn
                                                                                            </p>
                                                                                        </div>
                                                                                    </Link>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="d-flex align-items-center gap-1">
                                                                                <p className="loder-text mb-0">10</p>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="d-flex align-items-center gap-1">
                                                                                <p className="loder-text mb-0">9</p>
                                                                            </div>
                                                                        </td>
                                                                        <td className="rounded-end-4">
                                                                            <p className="loder-text mb-0">90%</p>
                                                                        </td>
                                                                    </tr> */}
                                                                </tbody>
                                                            </table>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                            {
                                                data.total_pages >= 2 &&
                                                <>
                                                    <DynamicPagination
                                                        currentPage={currentPage}
                                                        totalPages={data.total_pages}
                                                        onPageChange={(newPage) => {
                                                            setCurrentPage(newPage);
                                                        }}
                                                    />
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section >
            <Footer />
        </>
    )
}

export default EmployeesProgress
