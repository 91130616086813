import { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
function AlertSuccess({show, setShow,message}) {

    // const [show, setShow] = useState(true);

    return (
        <>
            <Alert show={show} variant="success" className='d-flex justify-content-between align-items-center gap-3 alert-success-bg border-0'>
                <Alert.Heading>
                    <img src="../png/check-green.png" alt="" />
                </Alert.Heading>
                <div className='d-flex justify-content-between align-items-start w-100'>
                    <p className='mb-0 fw-500 fs-6 success-text'>{message}</p>
                    <div className="d-flex justify-content-end">
                        <Button onClick={() => setShow(false)} className='bg-transparent p-0'>
                            <img src="../png/close-light.png" alt="" />
                        </Button>
                    </div>
                </div>
            </Alert>
        </>
    )
}

export default AlertSuccess