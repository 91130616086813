import React from 'react';
import AdminHeader from '../layout/AdminHeader';
import Footer from '../layout/Footer';
import SliderHomeSec from '../components/SliderHomeSec';
import ContentSidbar from '../components/ContentSidbar';
import { Link } from 'react-router-dom';
import FileDrop from '../components/FileDrop';

function ContentUpload() {
    return (
        <>
            <AdminHeader />
            <section>
                <div className="dashbaord-home-main">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-3 col-md-4 col-lg-3 col-xxl-3 d-none d-xl-block px-4 bg-white sidebar-mian">
                                <ContentSidbar />
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 col-xxl-9 bg-body">
                                <div className="row ps-0 ps-lg-4 pe-lg-4 ps-xl-5 dashbaord-right-side justify-content-md-center justify-content-lg-between">
                                    <div className="col-12 col-md-11 col-lg-12 right-side">
                                        <SliderHomeSec />
                                        <div className="row g-4 mb-5 bg-white rounded-2 mt-4 p-3 pt-2 pb-5">
                                            <div className="col-12">
                                                <div className="row">
                                                    <h4 className="fw-400 text-bl">Upload the course / s</h4>
                                                    <div className="card border-0">
                                                        <div className="card-body p-0 p-md-3">
                                                            <FileDrop />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-end mb-5 pe-3 pe-md-0">
                                            <div className="col-6 col-md-3 col-lg-2">
                                                <Link
                                                    to="/conten-preview"
                                                    className="btn-explore d-flex justify-content-between align-items-center gap-3 rounded-2"
                                                >
                                                    Save
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={22}
                                                        height={14}
                                                        viewBox="0 0 22 14"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M1 6.99854H21L16 1"
                                                            stroke="white"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path
                                                            d="M21 6.99854L16 13"
                                                            stroke="white"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                        />
                                                    </svg>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            <Footer />
        </>
    )
}

export default ContentUpload

