import React, { useState } from "react";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function BusinessUserSharedCourses() {
    const location = useLocation();
    const navigate = useNavigate();
    const [courses, setCourses] = useState([
        {
            id: 1,
            name: "Own course",
            title: "Make Unicorso create your own course for your company",
            description:
                "Have Unicorso design a custom course exclusively for your company. This could be a course about your company's history, culture, or unique processes, such as 'Example XYZ GmbH: Our Company and Its History,' ensuring your employees have a good learning experience.",
            price: "€2,000",
            imageUrl: "/admin/shared-courses-1.png",
            welcomeTitle: "Get your courses done for your company exclusively",
            welcomeDescription:
                "These courses are custom-made for your business and are not shared with other customers.",
            employees: 0,
            amount: 2000,
            buttonDisable: false,
        },
        {
            id: 2,
            name: "Shared Courses",
            title: "Create a course for your company that can be seen by other users of Unicorso",
            description:
                "Develop a course that not only benefits your company but is also available to other users on Unicorso. For example, you could create 'Using AI in the HR Department,' sharing your expertise and showcasing your company's knowledge to a broader audience.",
            price: "€800",
            imageUrl: "/admin/shared-courses-2.png",
            welcomeTitle: "Shared Courses",
            welcomeDescription:
                "These courses are available to other businesses and individual users.",
            employees: 0,
            amount: 800,
            buttonDisable: false,
        },
    ]);

    const handleEmployeeChange = (e, i) => {
        const newCourse = [...courses];
        newCourse[i].employees = e.target.value;
        setCourses(newCourse);
    };

    const handleAddCourse = (i) => {
        const newCourse = [...courses];

        if (newCourse[i].employees === 0 && !newCourse[i].buttonDisable) {
            toast.error("Please select number of employees");
            return;
        }
        newCourse[i].buttonDisable = !newCourse[i].buttonDisable;
        setCourses(newCourse);
    };

    const handleNavigate = () => {
        // const isSelect = courses.find(
        //     (course) => course.employees > 0 && course.buttonDisable
        // );
        // if (!isSelect) {
        //     toast.error("Please select at least one course");
        //     return;
        // }
        const sharedCourses = courses
            .filter((course) => course.employees > 0 && course.buttonDisable) // Filter the courses first
            .map((course) => ({
                name: course.name,
                employee: course.employees,
                price: course.employees * course.amount,
                perPrice: course.amount,
            }));
        navigate("/business-individualized-course", {
            state: {
                name: location?.state?.name,
                plan: location?.state?.plan,
                userPlan: location?.state?.userPlan,
                courseTrails: location?.state?.courseTrails,
                supportPlan: location?.state?.supportPlan,
                sharedCourses: sharedCourses || [],
            },
        });
    };

    return (
        <>
            <Header />
            <section className="payment-flow-section">
                <div className="container">
                    <div className="row align-items-end shared-courses-cards g-4 g-lg-4 g-xl-5">
                        {courses.map((course, i) => (
                            <div className="col-md-6" key={course.id}>
                                <h3 className="welcome-title max-width-100">
                                    {course.welcomeTitle}
                                </h3>
                                <p className="welcome-description plan-description">
                                    {course.welcomeDescription}
                                </p>
                                <div className="card border-0 h-100">
                                    <div className="card-header p-0 bg-transparent border-0 overflow-hidden">
                                        <img
                                            src={course.imageUrl}
                                            className="w-100 card-top-img"
                                            alt=""
                                        />
                                    </div>
                                    <div className="card-body">
                                        <h2>{course.title}</h2>
                                        <p>{course.description}</p>

                                        <div className="d-lg-flex justify-content-between align-items-start mt-4">
                                            <div>
                                                <h6 className="mb-0 fw-500 fs-18 text-black">
                                                    Number of Employees
                                                </h6>
                                                <p className="fs-12 fw-400 text-black">
                                                    Enter the number of your
                                                    employees to calculate the
                                                    total amount
                                                </p>
                                            </div>
                                            <input
                                                type="number"
                                                className="input-box mt-3 mt-lg-0"
                                                disabled={course.buttonDisable}
                                                onKeyDown={(e) =>
                                                    [
                                                        "e",
                                                        "E",
                                                        "+",
                                                        "-",
                                                        ".",
                                                    ].includes(e.key) &&
                                                    e.preventDefault()
                                                }
                                                value={course.employees}
                                                onChange={(e) =>
                                                    handleEmployeeChange(e, i)
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="card-footer bg-transparent border-0 pt-0">
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <div className="d-flex align-items-center gap-2">
                                                <img
                                                    src="/admin/piggy-bank.png"
                                                    alt=""
                                                />
                                                <p>Price</p>
                                            </div>
                                            <p>{course.price}</p>
                                        </div>
                                        <button
                                            className={`w-100 border-0 d-flex align-items-center justify-content-center gap-2 ${course.buttonDisable ? "button" : "button-added"} `}
                                            onClick={() => handleAddCourse(i)}
                                        >
                                            {course.buttonDisable
                                                ? "Added"
                                                : "Add"}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="d-flex justify-content-end pt-4 pb-5">
                        <button
                            className="btn-explore p-sm-15 d-flex justify-content-between align-items-center gap-3 rounded-2 mt-4"
                            onClick={handleNavigate}
                        >
                            Proceed
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={22}
                                height={14}
                                viewBox="0 0 22 14"
                                fill="none"
                                className="ms-5"
                            >
                                <path
                                    d="M1 6.99854H21L16 1"
                                    stroke="white"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M21 6.99805L16 12.9995"
                                    stroke="white"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default BusinessUserSharedCourses;
