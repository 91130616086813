import React from "react";

function CommonLoader() {
    return (
        <>
            <div className="loader-main d-flex justify-content-center align-items-center w-100 vh-100">
                <div class="loader"></div>
            </div>
        </>
    );
}

export default CommonLoader;
