import React from 'react'
import Header from '../layout/Header'
import Footer from '../layout/Footer'
import { Link } from 'react-router-dom'

const Payment = () => {
    return (
        <>
            <Header />
            <section>
                <div className="container-fluid">
                    <div className="sign-up-main individual-main">
                        <div className="row justify-content-between align-items-center h-100">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-6 position-relative bg-primary d-flex justify-content-center align-items-center sign-up-col">
                                <img
                                    src="./login/sing-vector-1.png"
                                    className="sing-vector-1"
                                    alt=""
                                />
                                <img
                                    src="./login/money-online.png"
                                    className="man-develops"
                                    alt=""
                                />
                                <img
                                    src="./login/sing-vector-2.png"
                                    className="sing-vector-2"
                                    alt=""
                                />
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 col-xl-6 position-relative d-flex justify-content-center align-items-start align-items-md-center sign-up-col-sm">
                                <div className="row g-4 ps-xl-5 selectcard py-0 py-md-5 py-xl-0">
                                    <div className="col-12">
                                        <label className="d-block">
                                            <input
                                                type="radio"
                                                name="product"
                                                className="card-input-element d-none"
                                            />
                                            <div className="card card-input border-0 w-100">
                                                <div className="card-body p-md-4">
                                                    <div className="d-flex align-items-start gap-3">
                                                        <div className="mt-3">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={20}
                                                                height={20}
                                                                viewBox="0 0 20 20"
                                                                fill="none"
                                                            >
                                                                <circle cx={10} cy={10} r={10} fill="#DBE5FF" />
                                                            </svg>
                                                        </div>
                                                        <div>
                                                            <p className="fs-18 text-black fw-600 mb-0">
                                                                Credit and Debit Card
                                                            </p>
                                                            <div className="d-flex align-items-center gap-3 mt-2">
                                                                <img
                                                                    src="./login/mastercard.png"
                                                                    alt=""
                                                                />
                                                                <img
                                                                    src="./login/visa.png"
                                                                    alt=""
                                                                />
                                                                <img
                                                                    src="./login/american-express.png"
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <form className="row g-3 form-main mt-3">
                                                                <div className="col-md-12 col-lg-12 col-xl-12">
                                                                    <input
                                                                        type="number"
                                                                        className="form-control"
                                                                        placeholder="Card No."
                                                                    />
                                                                </div>
                                                                <div className="col-md-12 col-lg-12 col-xl-12">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Card Holder’s Name"
                                                                    />
                                                                </div>
                                                                <div className="col-md-12 col-lg-6">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="CNN"
                                                                    />
                                                                </div>
                                                                <div className="col-md-12 col-lg-6">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Expiration Date"
                                                                    />
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="col-12">
                                        <label className="d-block">
                                            <input
                                                type="radio"
                                                name="product"
                                                className="card-input-element d-none"
                                            />
                                            <div className="card card-input border-0 w-100">
                                                <div className="card-body p-md-4">
                                                    <div className="d-flex align-items-start gap-3">
                                                        <div className="mt-3">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={20}
                                                                height={20}
                                                                viewBox="0 0 20 20"
                                                                fill="none"
                                                            >
                                                                <circle cx={10} cy={10} r={10} fill="#DBE5FF" />
                                                            </svg>
                                                        </div>
                                                        <div>
                                                            <p className="fs-18 text-black fw-600 mb-0">Paypal</p>
                                                            <div className="d-flex align-items-center gap-3 mt-2">
                                                                <img
                                                                    src="./login/mastercard.png"
                                                                    alt=""
                                                                />
                                                                <img
                                                                    src="./login/visa.png"
                                                                    alt=""
                                                                />
                                                                <img
                                                                    src="./login/american-express.png"
                                                                    alt=""
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="col-12">
                                        <label className="d-block">
                                            <input
                                                type="radio"
                                                name="product"
                                                className="card-input-element d-none"
                                            />
                                            <div className="card card-input border-0 w-100">
                                                <div className="card-body p-4">
                                                    <div className="d-flex align-items-start gap-3">
                                                        <div className="mt-3">
                                                            <img
                                                                src="./login/rectangle02.svg"
                                                                width="20px"
                                                                height="20px"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div>
                                                            <p className="fe-7 fw-400 text-black mt-3">
                                                                Save Card Details
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="col-12 mt-5">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="d-flex align-items-center gap-4">
                                                <p className="mb-0 text-black fs-6">Total</p>
                                                <h2 className="mb-0 text-black display-5 fw-600">€‎3</h2>
                                            </div>
                                            <Link
                                                to="/processed"
                                                className="btn-explore d-flex align-items-center gap-3 rounded-2 fw-400"
                                            >
                                                Proceed
                                                <div className="ps-0 ps-md-5">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={22}
                                                        height={14}
                                                        viewBox="0 0 22 14"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M1 6.99854H21L16 1"
                                                            stroke="white"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path
                                                            d="M21 6.99854L16 13"
                                                            stroke="white"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                        />
                                                    </svg>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Payment