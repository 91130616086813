import React from "react";

const TermsAndCondition = () => {
    return (
        <div className="col-12 col-lg-11 pb-5">
            <div className=" overflow-hidden order-3">
                <div className=" p-4 p-md-5">
                    <h2 className="fw-600 text-black mb-3">
                        Terms and Conditions unicorso UG (haftungsbeschränkt)
                    </h2>
                    <h3>1) Introduction</h3>
                    <p className="fw-400 text-muted mb-3 fs-7 text-bl">
                        Welcome to UniCorso. We are your partner for e-learning
                        and learning for both private and business customers.
                        The trust you place in us is very important to us.
                        Therefore, we place great value on clear formulation and
                        balanced design of our terms and conditions.
                    </p>
                    <h3>2) Scope and General Information</h3>
                    <p className="fw-400 text-muted mb-3 fs-7 text-bl">
                        These General Terms and Conditions ('Terms') govern the
                        use of our online courses and learning platform as well
                        as the additional services described in our offer
                        (collectively 'Products'). We are UniCorso UG
                        (haftungsbeschränkt), Alpenstraße 18, 81541 Munich
                        (hereinafter also referred to as 'we' or 'UniCorso').
                        Further information about our company can be found in
                        the imprint on our website.
                    </p>
                    <p className="fw-400 text-muted mb-3 fs-7 text-bl">
                        The legal relationship between you as a user of our
                        learning platform and UniCorso is exclusively governed
                        by these Terms. We do not recognize any conditions
                        deviating from these Terms unless we have expressly
                        agreed to their validity.
                    </p>
                    <p className="fw-400 text-muted mb-3 fs-7 text-bl">
                        We hereby inform you how you can use our Products and
                        what rules apply. Our Products are intended exclusively
                        for natural or legal persons who use them in their
                        commercial or self-employed professional activity.
                    </p>
                    <p className="fw-400 text-muted mb-3 fs-7 text-bl">
                        If you wish to use our offers for your employees, you
                        commit to informing your employees about these Terms and
                        ensuring their compliance
                    </p>
                    <p className="fw-400 text-muted mb-3 fs-7 text-bl">
                        UniCorso reserves the right to amend or supplement these
                        Terms with effect for the future if the amendment is
                        reasonable for the customer considering the interests of
                        UniCorso or if it becomes necessary due to legal or
                        regulatory requirements. The authorization to amend does
                        not apply to essential provisions of the contract,
                        particularly concerning the nature and scope of the
                        mutually agreed services. If you are already a customer
                        of UniCorso, we will inform you about any changes and
                        additions to the Terms with a reasonable period in
                        advance in text form (e.g., by email or notification
                        when logging into the learning platform), at least,
                        however, five (5) weeks before the planned effective
                        date of the changes/additions. The changes/additions are
                        deemed accepted by you if you do not object to the
                        changes/additions in text form within five (5) weeks of
                        receipt of the notification. If you object in accordance
                        with the previous sentence, the contract will continue
                        under the previous conditions until further notice.
                        However, UniCorso has the right to terminate the
                        contract extraordinarily within two (2) weeks of
                        receiving the objection from the customer with a notice
                        period of a further four (4) weeks. UniCorso will
                        specifically point out the right to object and the legal
                        consequences of silence in the amendment notice.
                    </p>

                    <h3>3) Definitions</h3>
                    <p className="fw-500 mb-0">
                        a) General Terms and Conditions (Terms)
                    </p>
                    <p className="mb-1 text-bl pb-3">
                        The Terms of UniCorso are available on the website at:
                        https://www.unicorso.com/en/terms/.
                    </p>

                    <p className="fw-500 mb-0">b)Offer</p>
                    <p className="mb-1 text-bl pb-3">
                        A specially created offer for the customer by UniCorso,
                        including online training and possible additional
                        services.
                    </p>

                    <p className="fw-500 mb-0">c) Authorized Users</p>
                    <p className="mb-1 text-bl pb-3">
                        Natural persons authorized under the customer's usage
                        rights to use the online training and additional
                        services. This includes the customer's employees or
                        those in a similar relationship with the customer.
                    </p>

                    <p className="fw-500 mb-0">d) Force Majeure</p>
                    <p className="mb-1 text-bl pb-3">
                        Unforeseeable events or circumstances beyond UniCorso's
                        control that cannot be avoided or overcome with
                        reasonable care. This includes, among others, wars,
                        natural disasters, epidemics, official orders, and
                        technical disruptions not caused by UniCorso.
                    </p>

                    <p className="fw-500 mb-0">e) Customer</p>
                    <p className="mb-1 text-bl pb-3">
                        The person or company named in the offer who uses
                        UniCorso's products commercially or professionally.
                    </p>

                    <p className="fw-500 mb-0">f) Learning Platform</p>
                    <p className="mb-1 text-bl pb-3">
                        The platform provided by UniCorso for accessing online
                        training.
                    </p>

                    <p className="fw-500 mb-0">g) Customer LMS</p>
                    <p className="mb-1 text-bl pb-3">
                        A learning platform used by the customer not operated by
                        UniCorso.
                    </p>

                    <p className="fw-500 mb-0">h) Licenses</p>
                    <p className="mb-1 text-bl pb-3">
                        Usage rights that grant the customer the right to use
                        the online training and additional services specified in
                        the offer.
                    </p>

                    <p className="fw-500 mb-0">i) License Period</p>
                    <p className="mb-1 text-bl pb-3">
                        The duration for which the licenses are valid, starting
                        with the provision of the first training, usually twelve
                        months unless otherwise agreed.
                    </p>

                    <p className="fw-500 mb-0">j) Minimum Term</p>
                    <p className="mb-1 text-bl pb-3">
                        The basic term of the contract specified in the offer,
                        which automatically renews if not terminated in due
                        time.
                    </p>

                    <p className="fw-500 mb-0">k) Online Training/Training</p>
                    <p className="mb-1 text-bl pb-3">
                        The training courses defined in UniCorso's offers.
                    </p>

                    <p className="fw-500 mb-0">l) Product</p>
                    <p className="mb-1 text-bl pb-3">
                        The entirety of the services offered by UniCorso,
                        including online training and possible additional
                        services.
                    </p>

                    <p className="fw-500 mb-0">m) Contract</p>
                    <p className="mb-1 text-bl pb-3">
                        The agreement between UniCorso and the customer based on
                        the offer, including these Terms and any specific
                        conditions.
                    </p>

                    <p className="fw-500 mb-0">n) Confidential Information</p>
                    <p className="mb-1 text-bl pb-3">
                        Information exchanged between the parties that is
                        considered confidential, including but not limited to
                        trade secrets and operational details explicitly marked
                        as confidential or that must be considered confidential
                        due to their nature.
                    </p>

                    <h3>4) Subject Matter and Conclusion of the Contract</h3>
                    <p className="fw-400 text-muted mb-3 fs-7 text-bl">
                        We offer practice-oriented courses on topics such as
                        compliance, skills, etc.
                    </p>
                    <p className="fw-400 text-muted mb-3 fs-7 text-bl">
                        You can use all bronze courses if you are registered.
                    </p>
                    <p className="fw-400 text-muted mb-3 fs-7 text-bl">
                        For additional bookings during the contract term, the
                        conditions agreed upon at the time of contract
                        conclusion apply. Additional bookings extend the
                        existing contract.
                    </p>

                    <h3>5) Prices and Payment Terms</h3>
                    <p className="fw-400 text-muted mb-3 fs-7 text-bl">
                        The prices for our online courses and additional
                        services are stated in the respective offers. Payment is
                        made according to the offered payment methods. We
                        reserve the right to exclude certain payment methods.
                    </p>
                    <p className="fw-400 text-muted mb-3 fs-7 text-bl">
                        Our invoices are payable within 14 days. Payment is
                        considered made only when we can dispose of the amount.
                    </p>

                    <h3>6) Term and Termination</h3>
                    <p className="fw-400 text-muted mb-3 fs-7 text-bl">
                        The agreement remains effective until it is terminated
                        by you or by UniCorso.
                    </p>
                    <p className="fw-400 text-muted mb-3 fs-7 text-bl">
                        The agreement can be terminated by both UniCorso and you
                        at any time with a notice period of at least 14 days.
                    </p>
                    <p className="fw-400 text-muted mb-3 fs-7 text-bl">
                        The contract term is automatically extended unless
                        terminated in due time.
                    </p>

                    <h3>7) Usage Rights</h3>
                    <p className="fw-400 text-muted mb-3 fs-7 text-bl">
                        You receive a simple right limited to the booked usage
                        to use our products under these Terms. You are
                        particularly not authorized to make the product
                        available to unauthorized third parties or to modify it.
                    </p>

                    <h3>8) Liability and Warranty</h3>
                    <p className="fw-500 mb-0">
                        a) Liability Disclaimer and Warranty
                    </p>
                    <p className="mb-1 text-bl pb-3">
                        We do not guarantee that the use of our products will
                        achieve the goals you aim for. In particular, we do not
                        guarantee specific successes, results, or other intended
                        impacts. Our online courses do not serve as individual
                        legal advice or legal design. Therefore, it is your
                        responsibility to apply the learned content and, if
                        necessary, subject it to your own legal review.
                    </p>

                    <p className="fw-500 mb-0">b) Limitation of Liability</p>
                    <p className="mb-1 text-bl pb-3">
                        We are not liable for damages that are outside our
                        control or that are caused by improper use of our
                        products. We are only liable for damages caused by
                        intent or gross negligence and for damages resulting
                        from the negligent injury of life, body, or health or
                        from the breach of essential contractual obligations
                        that are essential for the proper execution of the
                        contract. In case of a breach of such essential
                        contractual obligations, our liability is limited to the
                        foreseeable damage typical for the contract. Any further
                        liability is excluded. The statutory claims under the
                        Product Liability Act remain unaffected.
                    </p>

                    <h3>9) Force Majeure</h3>
                    <p className="fw-400 text-muted mb-3 fs-7 text-bl">
                        If we are prevented from fulfilling our contractual
                        obligations in whole or in part due to force majeure, we
                        are released from fulfilling these obligations for the
                        period during which the force majeure impairs the
                        performance. We will inform you as our customer
                        immediately about the existence of such a situation.
                    </p>

                    <h3>10) References</h3>
                    <p className="fw-400 text-muted mb-3 fs-7 text-bl">
                        If we are prevented from fulfilling our contractual
                        obligations in whole or in part due to force majeure, we
                        are released from fulfilling these obligations for the
                        period during which the force majeure impairs the
                        performance. We will inform you as our customer
                        immediately about the existence of such a situation.
                    </p>

                    <h3>11) Data Protection</h3>
                    <p className="fw-400 text-muted mb-3 fs-7 text-bl">
                        Personal data is processed in the fulfillment of the
                        contract. The data protection principles can be found in
                        our privacy policy.
                    </p>

                    <h3>12) Data Processing for Corporate Customers</h3>
                    <p className="fw-400 text-muted mb-3 fs-7 text-bl">
                        If we are prevented from fulfilling our contractual
                        obligations in whole or in part due to force majeure, we
                        are released from fulfilling these obligations for the
                        period during which the force majeure impairs the
                        performance. We will inform you as our customer
                        immediately about the existence of such a situation.
                    </p>

                    <h3>13)Final Provisions</h3>
                    <p className="fw-400 text-muted mb-3 fs-7 text-bl">
                        German law exclusively applies to our Terms. The
                        exclusive place of jurisdiction is Munich.
                    </p>
                    <p className="fw-400 text-muted mb-3 fs-7 text-bl">
                        May 2024
                    </p>
                </div>
            </div>
        </div>
    );
};

export default TermsAndCondition;
