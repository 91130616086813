import React from 'react'
import { Nav, Tab, Row, Col } from 'react-bootstrap';
import ComplianceScoreChart from '../ComplianceScoreChart';
import { getImageBaseUrl } from '../../helper/envConfig';

function Section1({ dataEployee }) {



    return (
        <>
            <div className="row g-4 mt-3 mb-5 pb-0 pb-md-4">
                <div className='col-lg-4'>
                    <div className="card border-0 rounded-3">
                        <div className="card-body p-5">
                            <div className='d-flex justify-content-center align-items-center mb-4'>
                                <div className='profile-image p-1'>
                                    <img src={dataEployee.image ? `${getImageBaseUrl()}${dataEployee.image}` : "admin-images/png/user-image.png" }
                                         alt="" />
                                </div>
                            </div>
                            <h5 className='fs-20 fw-600 text-center mb-3'>{dataEployee?.username}</h5>
                            <p className='fs-16 fw-400 text-center mb-2'>{dataEployee?.department}</p>
                            {/* <p className='fs-16 fw-400 text-center mb-2'>Financial Analyst</p> */}
                        </div>
                    </div>
                </div>

                <div className="col-12 col-lg-8">
                    <Row className='g-4'>
                        <Col className="col-12 col-md-6 col-lg-6">
                            <div className="card border-0 rounded-3 h-100">
                                <div className="card-body p-4">
                                    <h4 className='fs-20 fw-600 text-light-1 text-capitalize'>Total Courses Mandatory</h4>
                                    <p className='display-6 fw-600 mb-2'>{dataEployee?.total_mandatory_courses}</p>
                                    <p className='text-light-2 fw-400'>Courses</p>
                                </div>
                            </div>
                        </Col>
                        <Col className="col-12 col-md-6 col-lg-6">
                            <div className="card border-0 rounded-3 h-100">
                                <div className="card-body p-4">
                                    <h4 className='fs-20 fw-600 text-light-1 text-capitalize'>Total mandatory courses completed</h4>
                                    <p className='display-6 fw-600 mb-2'>{dataEployee?.total_completed_courses}</p>
                                    <p className='text-light-2 fw-400'>Courses</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className='g-4 mt-2'>
                        {/* <Col className="col-12 col-md-12 col-lg-12">
                            <div className="card border-0 rounded-3 h-100">
                                <div className="card-body p-4">
                                    <p className='text-light-1 fs-20 fw-600'>Total Points</p>
                                    <h5 className='display-3 fw-600 text-center mt-5 naturenova'>79</h5>
                                    <p className='text-center fs-20 fw-600 mt-3'>Compliance Score</p>
                                    <div className='compliance-score-progress d-flex mt-5'>
                                        <p className='very-low' style={{ width: '20%' }}></p>
                                        <p className='low' style={{ width: '20%' }}></p>
                                        <p className='medium' style={{ width: '20%' }}></p>
                                        <p className='high' style={{ width: '20%' }}></p>
                                        <p className='very-high' style={{ width: '20%' }}></p>
                                    </div>
                                    <ul className="px-0 point-list mt-5 d-flex flex-wrap align-items-center gap-4">
                                        <li><span className='very-low'></span><b>1-20</b> Very low</li>
                                        <li><span className='low'></span><b>21-40</b> Low</li>
                                        <li><span className='medium'></span><b>41-60</b> Medium</li>
                                        <li><span className='high'></span><b>61-80</b> High</li>
                                        <li><span className='very-high'></span><b>81-100</b> Very high</li>
                                    </ul>
                                </div>
                            </div>
                        </Col> */}
                        <Col className="col-12 col-md-12 col-lg-12">
                            <div className="card border-0 rounded-3 h-100">
                                <div className="card-body p-4">
                                    <p className='text-light-1 fs-20 fw-600'>Overall Compliance Score</p>
                                    <h3 className='fw-600'>{dataEployee.overall_complaince_score}/100</h3>
                                    <div className='mb-4'>
                                        {/* <img src="admin-images/png/arrow-up.png" alt="" />
                                        <span className='text-light-4 fw-600 ps-2'>2.1%</span>
                                        <span className='text-light-2 fw-400'> vs last month</span> */}
                                    </div>
                                    <ComplianceScoreChart data={dataEployee} />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}

export default Section1