import { React, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Header from '../../layout/Header'
import Footer from '../../layout/Footer'
import { Button } from 'react-bootstrap'
import { getServerURL } from '../../helper/envConfig'
import { useNavigate } from 'react-router-dom'
import api from '../../helper/api'
import { toast } from 'react-toastify';
import { decryptFromStoreLoc, encryptAndStoreLoc } from '../../helper/localStore'

const UpdateAssociatedCode = () => {

    const serverURL = getServerURL();
    const history = useNavigate(); // Get the history object for redirection
    const [userData, setUserData] = useState();

    const initialValues = {
        associated_code: "",
        user_id: userData?.user_id
    };

    // console.log(userData,"userData");

    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [submitCount, setSubmitCount] = useState(0);

    useEffect(() => {
        const decryptedInfoResult = decryptFromStoreLoc('user');
        setUserData(decryptedInfoResult)
        if (!decryptedInfoResult?.is_email_verified) {
            history("/")
        }
    }, []);

    useEffect(() => {
        // Check if userData and userData.user_id is not undefined
        if (userData && userData.user_id) {
            // Spread existing values and update user_id
            setValues(prevValues => ({
                ...prevValues,
                user_id: userData?.user_id
            }));
        }
    }, [userData]); // Depend on userData to rerun this effect when userData changes


    const handleChange = (e) => {

        const { name, value, checked, type } = e.target;
        let newValue = type === "checkbox" ? checked : value;
        setValues((prevValues) => ({
            ...prevValues,
            [name]: newValue,
        }));

    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSubmit(e)
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (values.associated_code && userData?.user_id) {
            try {

                var response2 = await api.postWithToken(`${serverURL}users/update-associated-code/`, {
                    "user_id": values.user_id,
                    "associated_code": values.associated_code
                });

                localStorage.removeItem('result');
                localStorage.removeItem('quiz');
                localStorage.removeItem('subcat');
                sessionStorage.clear();

                toast.success(response2?.data.message);


                if (userData?.main_role && userData?.main_role !== undefined && userData?.is_main_role) {
                    if (userData?.main_role == 1) {
                        setTimeout(() => {
                            history("/business-home")
                            window.location.reload()
                        }, 2000); // 2000 milliseconds = 2 seconds
                    } else if (userData?.main_role == 2) {
                        setTimeout(() => {
                            history("/customer-home")
                            window.location.reload()
                        }, 2000); // 2000 milliseconds = 2 seconds
                    } else if (userData?.main_role == 7) {
                        setTimeout(() => {
                            history("/business-family")
                            window.location.reload()
                        }, 2000); // 2000 milliseconds = 2 seconds
                    }
                } else {
                    if (userData?.role == 1 || userData?.role == 10 || userData?.role == 12 || userData?.role == 8 || userData?.role == 9) {
                        setTimeout(() => {
                            history("/business-home")
                            window.location.reload()
                        }, 2000); // 2000 milliseconds = 2 seconds
                    } else if (userData?.role == 2) {
                        setTimeout(() => {

                            history("/customer-home")
                            window.location.reload()
                        }, 2000); // 2000 milliseconds = 2 seconds
                    } else if (userData?.role == 6 || userData?.role == 11 || userData?.role == 13) {
                        setTimeout(() => {
                            history("/business-family")
                            window.location.reload()
                        }, 2000); // 2000 milliseconds = 2 seconds
                    }
                }

            } catch (error) {
                toast.error(error.response?.data.message);
            }
        } else {
            toast.error("Please enter associated code ");
        }
    };

    const skipPage = () => {


        if (userData.main_role && userData.main_role !== undefined && userData?.is_main_role) {

            if (userData?.main_role === 1) {
                history("/business-home")
                window.location.reload()
            } else if (userData?.main_role === 2) {
                history("/customer-home")
                window.location.reload()
            } else if (userData?.main_role === 7) {
                console.log("if");
                history("/business-family")
                window.location.reload()
            }

        } else {

            if (userData?.role == 1 || userData?.role == 10 || userData?.role == 12 || userData?.role == 8 || userData?.role == 9) {
                history("/business-home")
                window.location.reload()
            } else if (userData?.role == 2) {
                history("/customer-home")
                window.location.reload()
            } else if (userData?.role == 6 || userData?.role == 11 || userData?.role == 13) {

                history("/business-family")
                window.location.reload()
            }
        }

    }


    return (
        <>
            <Header />
            <section>
                <div className="container-fluid">
                    <div className="sign-up-main">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-12 col-md-6 position-relative bg-primary d-flex justify-content-center align-items-center sign-up-col vh-md-100">
                                <img
                                    src="./login/individual-user-vector-1.png"
                                    className="sing-vector-1"
                                    alt=""
                                />
                                <img
                                    src="./login/woman-home.png"
                                    className="project-team"
                                    alt=""
                                />
                                <img
                                    src="./login/individual-user-vector-2.png"
                                    className="sing-vector-2"
                                    alt=""
                                />
                            </div>
                            <div className="col-12 col-md-6 position-relative d-flex justify-content-center align-items-start align-items-md-center sign-up-col sign-up-col-sm vh-md-100 pb-md-5">
                                <div className="col-12 col-md-12 col-lg-10 col-xl-9">
                                    <form className="row g-3 form-main">
                                        <div className="col-md-12">
                                            <div className="col-md-12 col-lg-12 col-xl-8 mb-5">
                                                <label className="form-label">Associated Code</label>
                                                <input
                                                    type="text"
                                                    name='associated_code'
                                                    className="form-control"
                                                    onChange={(e) => (handleChange(e))}
                                                    onKeyPress={handleKeyPress}
                                                    placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
                                                />
                                                <div className='error' >{errors?.password}</div>
                                            </div>
                                            <div className="col-md-12 col-lg-12 col-xl-8 d-flex justify-content-end gap-3 pt-md-5">
                                                <button
                                                    type='button'
                                                    className="btn-explore rounded-2 fw-400"
                                                    // onClick={(e) => (handleSubmit(e), setSubmitCount(1))
                                                    // }
                                                    onClick={() => skipPage()}
                                                >
                                                    Skip
                                                </button>
                                                <button
                                                    type='button'
                                                    className="btn-primary rounded-2 fw-400"
                                                    onClick={(e) => (handleSubmit(e), setSubmitCount(1))
                                                    }>
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default UpdateAssociatedCode