import { React, useEffect, useState } from "react";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import api from "../helper/api";
import { getServerURL } from "../helper/envConfig";
import { decryptFromStoreLoc } from "../helper/localStore";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom"; // Import useHistory for redirection

const ParentDetail = () => {
    const initialValues = {
        first_name: "",
        last_name: "",
        email: "",
    };
    const [values, setValues] = useState(initialValues);
    const serverURL = getServerURL();
    const history = useNavigate(); // Get the history object for redirection
    const [formData, setFormData] = useState(initialValues);

    useEffect(() => {
        // Retrieve and decrypt stored information
        const decryptedInfo = decryptFromStoreLoc("userId");
        setValues(decryptedInfo);
    }, []);

    const handleFormData = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData.first_name) {
            toast.error("First name is required");
            return;
        }
        if (!formData.last_name) {
            toast.error("Last name is required");
            return;
        }
        if (!formData.email) {
            toast.error("Email is required");
            return;
        }
        if (!/\S+@\S+\.\S+/.test(formData.email)) {
            toast.error("Email is required");
            return;
        }
        try {
            var response = await api.post(
                `${serverURL}users/family-user/sent-kids-verification-mail/`,
                { ...formData, kids_id: values.user_id }
            );

            if(response.data?.success){
                localStorage.removeItem("user")
                localStorage.removeItem("token")
                localStorage.removeItem("userId")
                toast.success("Request send successful to your parent ")
                history("/sign-in")
            }
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message);
        }
    };

    return (
        <>
            <Header />
            <section>
                <div className="container-fluid">
                    <div className="sign-up-main">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-12 col-md-6 position-relative bg-primary d-flex justify-content-center align-items-center sign-up-col vh-md-100">
                                <img
                                    src="./login/individual-user-vector-1.png"
                                    className="sing-vector-1"
                                    alt=""
                                />
                                <img
                                    src="./login/woman-home.png"
                                    className="project-team"
                                    alt=""
                                />
                                <img
                                    src="./login/individual-user-vector-2.png"
                                    className="sing-vector-2"
                                    alt=""
                                />
                            </div>
                            <div className="col-12 col-md-6 position-relative d-flex justify-content-center align-items-start align-items-md-center sign-up-col sign-up-col-sm vh-md-100">
                                <div className="col-12 col-md-12 col-lg-10 col-xl-9">
                                    <form
                                        className="row g-3 form-main"
                                        onSubmit={handleSubmit}
                                    >
                                        <div className="col-md-12">
                                            <div className="col-md-12 col-lg-12 col-xl-8 mb-5">
                                                <label className="form-label">
                                                    First Name
                                                </label>
                                                <input
                                                    className="form-control"
                                                    placeholder="Enter your first name"
                                                    name="first_name"
                                                    onChange={handleFormData}
                                                    value={formData.first_name}
                                                    type="text"
                                                />
                                            </div>
                                            <div className="col-md-12 col-lg-12 col-xl-8 mb-5">
                                                <label className="form-label">
                                                    Last Name
                                                </label>
                                                <input
                                                    type="text"
                                                    name="last_name"
                                                    value={formData.last_name}
                                                    className="form-control"
                                                    onChange={handleFormData}
                                                    placeholder="Enter your last name"
                                                />
                                            </div>
                                            <div className="col-md-12 col-lg-12 col-xl-8 mb-5">
                                                <label className="form-label">
                                                    Email Address
                                                </label>
                                                <input
                                                    className="form-control"
                                                    placeholder="Enter your email"
                                                    name="email"
                                                    onChange={handleFormData}
                                                    value={formData.email}
                                                    type="email"
                                                />
                                            </div>
                                            <div className="col-md-12 col-lg-12 col-xl-8 d-flex justify-content-end">
                                                <button
                                                    type="submit"
                                                    className="btn-explore d-flex align-items-center fw-400 gap-3 rounded-2"
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default ParentDetail;
