import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import AdminLayout from "../../components/Admin/AdminLayout";
import api from "../../helper/api";
import { getServerURL } from "../../helper/envConfig";
import CommonLoader from "../../components/Admin/CommonLoader";

function AdminPerformanceScale() {
    const serverURL = getServerURL();
    const [performanceData, setPerformanceData] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalPerformance, setTotalPerformance] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [showLoader, setShowLoader] = useState(true);


    const getFeedbackList = async () => {
        try {
            const queryParams = new URLSearchParams({
                page,
                page_size: pageSize,
            }).toString();
            const response = await api.getWithToken(
                `${serverURL}admin-panel/feedback/courses-performance-scale?${queryParams}`
            );
            setPerformanceData(response.data.results.scales);
            setTotalPerformance(response.data.total);
            setTotalPages(response.data.total_pages);
            setShowLoader(false)
        } catch (error) {
            setPerformanceData([]);
            console.error(error);
        }
    };

    useEffect(() => {
        getFeedbackList();
    }, [page]);

    const handleNextPage = () => {
        if (totalPages > page) {
            setPage(page + 1);
        }
    };

    const handlePrevPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    return (
        <AdminLayout>

            {showLoader ? (
                <CommonLoader />
            ) : (
                <>
                <h2 className="page-title">Performance Scale</h2>
                <div className="user-management-table mt-4 overflow-auto">
                    <Table>
                        <thead>
                            <tr>
                                <th>
                                    <p className="text-capitalize text-nowrap">No</p>
                                </th>
                                <th>
                                    <p className="text-capitalize text-nowrap">Course</p>
                                </th>
                                <th>
                                    <p className="text-capitalize text-nowrap">Scale</p>
                                </th>
                                <th>
                                    <p className="text-capitalize text-nowrap">
                                        Feedback Text (comes from ChatGPT)
                                    </p>
                                </th>
                                <th>
                                    <p className="text-capitalize text-nowrap">Date</p>
                                </th>
                                <th>
                                    <div>
                                        <p className="text-capitalize text-nowrap">Time</p>
                                    </div>
                                </th>
                                <th>
                                    <div>
                                        <p className="text-capitalize text-nowrap">User</p>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {performanceData.length > 0 &&
                                performanceData.map((item, i) => {
                                    return (
                                        <>
                                            <tr>
                                                <td>
                                                    <p className="text-light-400 fs-7 fw-500 mb-0">
                                                        {(page - 1) * pageSize +
                                                            i +
                                                            1}
                                                    </p>
                                                </td>
                                                <td className="px-4">
                                                    {item.course_name}
                                                </td>
                                                <td className="px-4">
                                                    {item.scale}
                                                </td>
                                                <td className="px-4">
                                                    {item.chat_gpt_feedback}
                                                </td>
                                                <td className="px-4 text-nowrap">
                                                    {item.date}
                                                </td>
                                                <td className="px-4 text-nowrap">
                                                    {item.time}
                                                </td>
                                                <td className="px-4">
                                                    {item.user_name}
                                                </td>
                                            </tr>
                                        </>
                                    );
                                })}
                        </tbody>
                    </Table>
                </div>
                <div className="admin-table-pagination">
                    <p>
                        Showing {(page - 1) * pageSize + 1}-
                        {Math.min(page * pageSize, totalPerformance)} of{" "}
                        {totalPerformance}
                    </p>
                    <div className="admin-table-pagination-arrows">
                        <div
                            className="pagination-arrows-box"
                            onClick={handlePrevPage}
                        >
                            <img src="./admin/pagination-left-arrow.png" alt="" />
                        </div>
                        <div className="pagination-arrows-box">
                            <img
                                src="./admin/pagination-right-arrow.png"
                                alt=""
                                onClick={handleNextPage}
                            />
                        </div>
                    </div>
                </div>
                </>
            )}
        </AdminLayout>
    );
}

export default AdminPerformanceScale;
