import React, { useState, useEffect, useContext } from 'react'
import BusinessSidebar from '../components/BusinessSidebar'
import AdminHeader from './../layout/AdminHeader';
import Footer from './../layout/Footer';
import SelectCourse from '../components/assign-course-main/SelectCourse';
import DynamicPagination from '../components/DynamicPagination'
import SetCourse from '../components/assign-course-main/SetCourse';
import { CourseContext } from "../context/CourseContext";

const AssignCourse = () => {


    const {ShowSuccess, setShowSuccess, is_search, listCourse, currentPage, setCurrentPage, pageSize, setPageSize, getCourses, mainLoder, setMainLoder } = useContext(CourseContext);

    const [currentStep, setCurrentStep] = useState(1);
    const [selectedCourses, setSelectedCourses] = useState([]);
    const [stepClasses, setStepClasses] = useState({
        1: 'd-block',
        2: 'd-none',
        3: 'd-none',
    });

    const [shouldSubmit, setShouldSubmit] = useState(false);
    const [employeeCounts, setEmployeeCounts] = useState(0);

    const [lastActiveStep, setLastActiveStep] = useState(1);

    useEffect(() => {
        updateProgressBar();
        updateStepClasses();
    }, [currentStep]);

    const updateProgressBar = () => {
        const progressPercentage = ((currentStep - 1) / 1) * 100;
        document.querySelector('.progress-bar').style.width = `${progressPercentage}%`;
    };

    const updateStepClasses = () => {
        setStepClasses({
            1: currentStep === 1 ? 'd-block' : 'd-none animate__animated animate__fadeInRight',
            2: currentStep === 2 ? 'd-block animate__animated animate__fadeInRight' : 'd-none',
        });
        setLastActiveStep(currentStep);
    };

    const nextStep = () => {
        if (currentStep < 2) {
            setCurrentStep(currentStep + 1);
        }
    };

    const prevStep = () => {
        if (currentStep > 1) {
            setCurrentStep(currentStep - 1);
        }
    };


    useEffect(() => {
        getCourses();
    }, [currentPage, pageSize, is_search]);

    const handleAssignCourseClick = () => {
        // Instead of directly calling submitData, we set shouldSubmit to true
        setShouldSubmit(true);
      };
    
      useEffect(() => {
        
        if(shouldSubmit == true){
            setShouldSubmit(false);
        }

    }, [shouldSubmit]);


    return (
        <>
            <AdminHeader />
            <section>
                <div className="dashbaord-home-main">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-3 col-md-4 col-lg-3 col-xxl-3 d-none d-xl-block px-4 bg-white sidebar-mian">
                                <BusinessSidebar />
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 col-xxl-9 bg-body">
                                <div className="row ps-0 ps-lg-4 pe-lg-4 ps-xl-5 dashbaord-right-side justify-content-md-center justify-content-lg-between pt-5">
                                    <div className="col-12 col-md-11 col-lg-12 right-side">
                                        <div className="row justify-content-between align-items-center mb-5">
                                            {/* <AlertSuccess show={ShowSuccess} setShow={setShowSuccess} /> */}
                                            <div className="col-12 col-md-6 col-lg-8">
                                                <h2 className="fw-600 text-black">Assign Course</h2>
                                            </div>
                                        </div>
                                        <div className="card border-0 mb-5 bg-transparent">
                                            <div className="card-body">
                                                <div className="row justify-content-center pt-4">
                                                    <div className="col-12 col-md-11 col-lg-4">
                                                        <div className="progress mx-4 ms-5" style={{ height: '2px' }}>
                                                            <div className="progress-bar" role="progressbar" style={{ width: '0%' }} aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                                        </div>
                                                        <div className="step-container d-flex justify-content-between px-3 ps-15">
                                                            <div className={lastActiveStep === 1 ? 'active-step' : lastActiveStep > 1 ? 'active-step confirm' : ''} onClick={() => setCurrentStep(1)}>
                                                                <span>01</span>
                                                            </div>
                                                            <div className={lastActiveStep === 2 ? 'active-step' : lastActiveStep > 2 ? 'active-step confirm' : ''} onClick={() => {
                                                                if (selectedCourses.length >= 1 && (lastActiveStep !== 2 && lastActiveStep !== 3) ) {
                                                                    setCurrentStep(2);
                                                                }
                                                            }} >
                                                                <span>02</span>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex justify-content-between text-center step-text-list">
                                                            <div className={lastActiveStep >= 1 ? 'active-step' : ''}>Select course</div>
                                                            <div className={lastActiveStep >= 2 ? 'active-step' : ''}>Settings</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <form id="multi-step-form">

                                                    <div className={`step step-1 ${stepClasses[1]}`}>
                                                        <SelectCourse  listCourse={listCourse} selectedCourses={selectedCourses} setSelectedCourses={setSelectedCourses} />
                                                        <div className='mt-5'>

                                                            {listCourse.total_pages >= 2 &&
                                                                <DynamicPagination
                                                                    currentPage={currentPage}
                                                                    totalPages={listCourse.total_pages}
                                                                    onPageChange={(newPage) => {
                                                                        setCurrentPage(newPage);
                                                                    }}
                                                                />
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className={`step step-2 ${lastActiveStep === 2 ? stepClasses[2] : lastActiveStep === 3 ? stepClasses[3] : ''}`}>

                                                        {selectedCourses.length >= 1 && (currentStep == 2 || currentStep == 3)   &&
                                                            <SetCourse setCurrentStep={setCurrentStep} employeeCounts={employeeCounts} setEmployeeCounts={setEmployeeCounts} shouldSubmit={shouldSubmit} selectedCourses={selectedCourses} currentStep={currentStep} />
                                                        }

                                                    </div>
                                                </form>
                                            </div>
                                            <div className="card-footer pt-3 mt-5 bg-transparent border-0">
                                                <div className='d-flex justify-content-end gap-3'>

                                                    {currentStep == 1 ? (<><button type="button" className="btn-cancel bg-transparent" onClick={prevStep}>Cancel</button><button type="button" className="btn-upload" disabled={selectedCourses?.length <= 0} onClick={nextStep}>Next</button></>) : currentStep == 2 || currentStep == 3 ?

                                                        (<><button type="button" className="btn-cancel" onClick={prevStep}>Back</button><button type="button" className="btn-upload" disabled={employeeCounts == 0} onClick={() => (handleAssignCourseClick()) }>Assign Course</button></>) :

                                                        <button type="button" className="btn-upload" >Start Assigning Courses</button>}

                                                    {/* {!show && <button className='text-white' >Show Alert</button>} */}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default AssignCourse
