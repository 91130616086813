import { useState } from "react";
import { Modal } from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import TostMessage from "./TostMessage";
import api from "../../../helper/api";
import { getServerURL } from "../../../helper/envConfig";

function SendCommunication({ user_ids }) {
    const serverURL = getServerURL();
    const [show, setShow] = useState(false);
    const [message, setMessage] = useState("");

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [isTostMessageVisible, setIsTostMessageVisible] = useState(false);

    const handleSendCommunication = async () => {
        try {
            const response = await api.postWithToken(
                `${serverURL}admin-panel/user-management/send-communication`,
                {
                    users_ids_list: user_ids,
                    message: message,
                }
            );
            setIsTostMessageVisible(true);
            setShow(false)
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            <button className="send-communication" onClick={handleShow}>
                <img src="./admin/send-communication.png" alt="" />
                Send Communication
            </button>
            <Modal
                show={show}
                className="table-bottom-modal"
                onHide={handleClose}
                size="lg"
                centered
            >
                <Modal.Header closeButton className="border-0 pb-0 px-4">
                    <Modal.Title className="pt-4">
                        Send Communication to Selected Users
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-4 pt-0">
                    <p>Write your message</p>
                    <FloatingLabel
                        controlId="floatingTextarea2"
                        label="Message..."
                    >
                        <Form.Control
                            as="textarea"
                            placeholder="Message..."
                            style={{ height: "200px" }}
                            onChange={(e) => {
                                setMessage(e.target.value);
                            }}
                        />
                    </FloatingLabel>
                    <div className="d-flex justify-content-end">
                        <button
                            className="apply-btn mt-4"
                            onClick={handleSendCommunication}
                        >
                            Send Communication
                        </button>
                    </div>
                </Modal.Body>
            </Modal>

            <div className={isTostMessageVisible ? "d-block" : "d-none"}>
                <TostMessage setShow={setIsTostMessageVisible} msg={"Message has been successfully sent"} />
            </div>
        </>
    );
}

export default SendCommunication;
