import React from 'react'
import AdminHeader from '../layout/AdminHeader';
import Footer from '../layout/Footer';
import ContentSidbar from '../components/ContentSidbar';

function CustomerCourses() {
    return (
        <>
            <AdminHeader />
            <section>
                <div className="dashbaord-home-main">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-3 col-md-4 col-lg-3 col-xxl-3 d-none d-xl-block px-4 bg-white sidebar-mian">
                                <ContentSidbar />
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 col-xxl-9 bg-body">
                                <div className="row ps-0 ps-lg-5 dashbaord-right-side justify-content-md-center justify-content-lg-between pt-5">
                                    <div className="col-12 col-md-11 col-lg-12 right-side">
                                        <div className="row justify-content-between align-items-center">
                                            <div className="col-12 col-md-6 col-lg-8">
                                                <h2 className="fw-600 text-black mb-5">Courses List</h2>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center gap-4 mb-4 g-4 flex-wrap">
                                            <select className="form-select p-3 py-2 bg-transparent border-2 border-secondary w-auto pe-5">
                                                <option selected="">November</option>
                                                <option value={1}>One</option>
                                                <option value={2}>Two</option>
                                                <option value={3}>Three</option>
                                            </select>
                                            <select className="form-select p-3 py-2 bg-transparent border-2 border-secondary w-auto pe-5">
                                                <option selected="">Technology and Innovation</option>
                                                <option value={1}>One</option>
                                                <option value={2}>Two</option>
                                                <option value={3}>Three</option>
                                            </select>
                                        </div>
                                        <div className="d-flex align-items-center gap-3 flex-wrap mb-5">
                                            <span className="badge bg-black rounded-5 fw-400 fs-7 py-2 px-3">
                                                Bronze
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={16}
                                                    height={14}
                                                    viewBox="0 0 16 14"
                                                    fill="none"
                                                >
                                                    <mask
                                                        id="mask0_628_4566"
                                                        style={{ maskType: "alpha" }}
                                                        maskUnits="userSpaceOnUse"
                                                        x={0}
                                                        y={0}
                                                        width={16}
                                                        height={14}
                                                    >
                                                        <rect
                                                            width="15.4375"
                                                            height={13}
                                                            transform="matrix(-1 0 0 1 15.4375 0.5)"
                                                            fill="#D9D9D9"
                                                        />
                                                    </mask>
                                                    <g mask="url(#mask0_628_4566)">
                                                        <path
                                                            d="M10.4727 10.7913C10.6532 10.7913 10.8225 10.7507 10.9805 10.6695C11.1385 10.5882 11.2671 10.4754 11.3664 10.3309L13.7227 6.99967L11.3664 3.66842C11.2671 3.52398 11.1385 3.41113 10.9805 3.32988C10.8225 3.24863 10.6532 3.20801 10.4727 3.20801H2.70877C2.41085 3.20801 2.15582 3.31408 1.94366 3.52624C1.73151 3.73839 1.62543 3.99342 1.62543 4.29134V9.70801C1.62543 10.0059 1.73151 10.261 1.94366 10.4731C2.15582 10.6853 2.41085 10.7913 2.70877 10.7913H10.4727ZM8.26988 9.16634L6.86155 7.75801L5.45321 9.16634L4.69488 8.40801L6.10321 6.99967L4.69488 5.59134L5.45321 4.83301L6.86155 6.24134L8.26988 4.83301L9.02821 5.59134L7.61988 6.99967L9.02821 8.40801L8.26988 9.16634Z"
                                                            fill="white"
                                                        />
                                                    </g>
                                                </svg>
                                            </span>
                                            <span className="badge bg-black rounded-5 fw-400 fs-7 py-2 px-3">
                                                Technology and Innovation
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={16}
                                                    height={14}
                                                    viewBox="0 0 16 14"
                                                    fill="none"
                                                >
                                                    <mask
                                                        id="mask0_628_4566"
                                                        style={{ maskType: "alpha" }}
                                                        maskUnits="userSpaceOnUse"
                                                        x={0}
                                                        y={0}
                                                        width={16}
                                                        height={14}
                                                    >
                                                        <rect
                                                            width="15.4375"
                                                            height={13}
                                                            transform="matrix(-1 0 0 1 15.4375 0.5)"
                                                            fill="#D9D9D9"
                                                        />
                                                    </mask>
                                                    <g mask="url(#mask0_628_4566)">
                                                        <path
                                                            d="M10.4727 10.7913C10.6532 10.7913 10.8225 10.7507 10.9805 10.6695C11.1385 10.5882 11.2671 10.4754 11.3664 10.3309L13.7227 6.99967L11.3664 3.66842C11.2671 3.52398 11.1385 3.41113 10.9805 3.32988C10.8225 3.24863 10.6532 3.20801 10.4727 3.20801H2.70877C2.41085 3.20801 2.15582 3.31408 1.94366 3.52624C1.73151 3.73839 1.62543 3.99342 1.62543 4.29134V9.70801C1.62543 10.0059 1.73151 10.261 1.94366 10.4731C2.15582 10.6853 2.41085 10.7913 2.70877 10.7913H10.4727ZM8.26988 9.16634L6.86155 7.75801L5.45321 9.16634L4.69488 8.40801L6.10321 6.99967L4.69488 5.59134L5.45321 4.83301L6.86155 6.24134L8.26988 4.83301L9.02821 5.59134L7.61988 6.99967L9.02821 8.40801L8.26988 9.16634Z"
                                                            fill="white"
                                                        />
                                                    </g>
                                                </svg>
                                            </span>
                                        </div>
                                        <div className="row row-cols-md-1 row-cols-lg-2 row-cols-xl-2 g-4 pb-5">
                                            <div className="col">
                                                <div className="card featured-card overflow-hidden border-0 position-relative accordion-items-shadow">
                                                    <img
                                                        src="./featured-courses/featured01.jpg"
                                                        className="featured-courses-img"
                                                        alt=""
                                                    />
                                                    <span className="badge badge-warning bg-warning position-absolute top-0 start-0">
                                                        Bronze
                                                    </span>
                                                    <div className="card-body">
                                                        <span className="badge badge-light rounded-2 fw-400 fs-6">
                                                            Marketing and Sales
                                                        </span>
                                                        <h5>Strategies for Selling Success</h5>
                                                        <p className="d-flex align-items-center gap-2 fw-400 text-black mt-3 mb-4 fs-6">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={30}
                                                                height={30}
                                                                viewBox="0 0 30 30"
                                                                fill="none"
                                                            >
                                                                <circle cx={15} cy={15} r={15} fill="#D9D9D9" />
                                                            </svg>
                                                            Professor Sophia Lee
                                                        </p>
                                                        <div className="row justify-content-between align-items-center mt-4 mb-4 mb-md-1">
                                                            <p className="fw-500 text-black mb-4 mb-md-0 col-12 col-md-8 col-lg-6 col-xl-7">
                                                                Beginner Boost
                                                            </p>
                                                            <div className="col-12 col-md-4 col-lg-6 col-xl-5 d-flex justify-content-between align-items-center">
                                                                <ul className="rectangle d-flex align-items-center gap-2  flex-wrap mb-0 p-0">
                                                                    <li className="active" />
                                                                    <li className="active" />
                                                                    <li className="active" />
                                                                    <li />
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="row justify-content-between align-items-center mb-3">
                                                            <p className="fw-500 text-black col-12 col-md-8 col-lg-6 col-xl-7 mb-3 mb-md-0">
                                                                Starter Pack
                                                            </p>
                                                            <div className="col-12 col-md-4 col-lg-6 col-xl-5 d-flex align-items-center gap-2">
                                                                <img
                                                                    src="./featured-courses/piggy-bank.jpg"
                                                                    className="wh-25"
                                                                    alt=""
                                                                />
                                                                <p className="mb-0 text-black fw-600">Explore Free</p>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex align-items-center gap-4">
                                                            <span className="badge badge-light rounded-2 fs-5 fw-600 px-4">
                                                                1K
                                                            </span>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <img
                                                                    src="./featured-courses/calibration.jpg"
                                                                    className="wh-25"
                                                                    alt=""
                                                                />
                                                                <p className="mb-0 text-black fw-400 fs-7">
                                                                    Graduated with Glory
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex align-items-center gap-4 mt-3">
                                                            <span className="badge badge-light rounded-2 fs-5 fw-600">
                                                                213
                                                            </span>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <img
                                                                    src="/featured-courses/desktop.jpg"
                                                                    className="wh-25"
                                                                    alt=""
                                                                />
                                                                <p className="mb-0 text-black fw-400 fs-7">
                                                                    Signed Up for Success
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <button
                                                            type="button"
                                                            className="btn-primary d-flex align-items-center gap-3 rounded-2 mt-4"
                                                        >
                                                            Enroll Today
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={22}
                                                                height={14}
                                                                viewBox="0 0 22 14"
                                                                fill="none"
                                                            >
                                                                <path
                                                                    d="M1 6.99854H21L16 1"
                                                                    stroke="white"
                                                                    strokeWidth="1.5"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                                <path
                                                                    d="M21 6.99854L16 13"
                                                                    stroke="white"
                                                                    strokeWidth="1.5"
                                                                    strokeLinecap="round"
                                                                />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="card featured-card overflow-hidden border-0 position-relative accordion-items-shadow">
                                                    <img
                                                        src="./featured-courses/featured02.jpg"
                                                        className="featured-courses-img"
                                                        alt=""
                                                    />
                                                    <span className="badge badge-warning bg-warning position-absolute top-0 start-0">
                                                        Silver
                                                    </span>
                                                    <div className="card-body">
                                                        <span className="badge badge-light rounded-2 fw-400 fs-6">
                                                            Marketing and Sales
                                                        </span>
                                                        <h5>Strategies for Selling Success</h5>
                                                        <p className="d-flex align-items-center gap-2 fw-400 text-black mt-3 mb-4 fs-6">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={30}
                                                                height={30}
                                                                viewBox="0 0 30 30"
                                                                fill="none"
                                                            >
                                                                <circle cx={15} cy={15} r={15} fill="#D9D9D9" />
                                                            </svg>
                                                            Professor Sophia Lee
                                                        </p>
                                                        <div className="row justify-content-between align-items-center mt-4 mb-4 mb-md-1">
                                                            <p className="fw-500 text-black mb-4 mb-md-0 col-12 col-md-8 col-lg-6 col-xl-7">
                                                                Beginner Boost
                                                            </p>
                                                            <div className="col-12 col-md-4 col-lg-6 col-xl-5 d-flex justify-content-between align-items-center">
                                                                <ul className="rectangle d-flex align-items-center gap-2  flex-wrap mb-0 p-0">
                                                                    <li className="active" />
                                                                    <li className="active" />
                                                                    <li className="active" />
                                                                    <li />
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="row justify-content-between align-items-center mb-3">
                                                            <p className="fw-500 text-black col-12 col-md-8 col-lg-6 col-xl-7 mb-3 mb-md-0">
                                                                Starter Pack
                                                            </p>
                                                            <div className="col-12 col-md-4 col-lg-6 col-xl-5 d-flex align-items-center gap-2">
                                                                <img
                                                                    src="./featured-courses/piggy-bank.jpg"
                                                                    className="wh-25"
                                                                    alt=""
                                                                />
                                                                <p className="mb-0 text-black fw-600">Explore Free</p>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex align-items-center gap-4">
                                                            <span className="badge badge-light rounded-2 fs-5 fw-600 px-4">
                                                                1K
                                                            </span>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <img
                                                                    src="./featured-courses/calibration.jpg"
                                                                    className="wh-25"
                                                                    alt=""
                                                                />
                                                                <p className="mb-0 text-black fw-400 fs-7">
                                                                    Graduated with Glory
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex align-items-center gap-4 mt-3">
                                                            <span className="badge badge-light rounded-2 fs-5 fw-600">
                                                                213
                                                            </span>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <img
                                                                    src="/featured-courses/desktop.jpg"
                                                                    className="wh-25"
                                                                    alt=""
                                                                />
                                                                <p className="mb-0 text-black fw-400 fs-7">
                                                                    Signed Up for Success
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <button
                                                            type="button"
                                                            className="btn-secondary text-black fw-600 d-flex align-items-center gap-3 rounded-2 mt-4"
                                                        >
                                                            Unlock It
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={22}
                                                                height={14}
                                                                viewBox="0 0 22 14"
                                                                fill="none"
                                                            >
                                                                <path
                                                                    d="M1 6.99854H21L16 1"
                                                                    stroke="#000C2A"
                                                                    strokeWidth="1.5"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                                <path
                                                                    d="M21 6.99854L16 13"
                                                                    stroke="#000C2A"
                                                                    strokeWidth="1.5"
                                                                    strokeLinecap="round"
                                                                />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="card featured-card overflow-hidden border-0 position-relative accordion-items-shadow">
                                                    <img
                                                        src="./featured-courses/featured01.jpg"
                                                        className="featured-courses-img"
                                                        alt=""
                                                    />
                                                    <span className="badge badge-warning bg-warning position-absolute top-0 start-0">
                                                        Bronze
                                                    </span>
                                                    <div className="card-body">
                                                        <span className="badge badge-light rounded-2 fw-400 fs-6">
                                                            Marketing and Sales
                                                        </span>
                                                        <h5>Strategies for Selling Success</h5>
                                                        <p className="d-flex align-items-center gap-2 fw-400 text-black mt-3 mb-4 fs-6">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={30}
                                                                height={30}
                                                                viewBox="0 0 30 30"
                                                                fill="none"
                                                            >
                                                                <circle cx={15} cy={15} r={15} fill="#D9D9D9" />
                                                            </svg>
                                                            Professor Sophia Lee
                                                        </p>
                                                        <div className="row justify-content-between align-items-center mt-4 mb-4 mb-md-1">
                                                            <p className="fw-500 text-black mb-4 mb-md-0 col-12 col-md-8 col-lg-6 col-xl-7">
                                                                Beginner Boost
                                                            </p>
                                                            <div className="col-12 col-md-4 col-lg-6 col-xl-5 d-flex justify-content-between align-items-center">
                                                                <ul className="rectangle d-flex align-items-center gap-2  flex-wrap mb-0 p-0">
                                                                    <li className="active" />
                                                                    <li className="active" />
                                                                    <li className="active" />
                                                                    <li />
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="row justify-content-between align-items-center mb-3">
                                                            <p className="fw-500 text-black col-12 col-md-8 col-lg-6 col-xl-7 mb-3 mb-md-0">
                                                                Starter Pack
                                                            </p>
                                                            <div className="col-12 col-md-4 col-lg-6 col-xl-5 d-flex align-items-center gap-2">
                                                                <img
                                                                    src="./featured-courses/piggy-bank.jpg"
                                                                    className="wh-25"
                                                                    alt=""
                                                                />
                                                                <p className="mb-0 text-black fw-600">Explore Free</p>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex align-items-center gap-4">
                                                            <span className="badge badge-light rounded-2 fs-5 fw-600 px-4">
                                                                1K
                                                            </span>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <img
                                                                    src="./featured-courses/calibration.jpg"
                                                                    className="wh-25"
                                                                    alt=""
                                                                />
                                                                <p className="mb-0 text-black fw-400 fs-7">
                                                                    Graduated with Glory
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex align-items-center gap-4 mt-3">
                                                            <span className="badge badge-light rounded-2 fs-5 fw-600">
                                                                213
                                                            </span>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <img
                                                                    src="/featured-courses/desktop.jpg"
                                                                    className="wh-25"
                                                                    alt=""
                                                                />
                                                                <p className="mb-0 text-black fw-400 fs-7">
                                                                    Signed Up for Success
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <button
                                                            type="button"
                                                            className="btn-primary d-flex align-items-center gap-3 rounded-2 mt-4"
                                                        >
                                                            Enroll Today
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={22}
                                                                height={14}
                                                                viewBox="0 0 22 14"
                                                                fill="none"
                                                            >
                                                                <path
                                                                    d="M1 6.99854H21L16 1"
                                                                    stroke="white"
                                                                    strokeWidth="1.5"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                                <path
                                                                    d="M21 6.99854L16 13"
                                                                    stroke="white"
                                                                    strokeWidth="1.5"
                                                                    strokeLinecap="round"
                                                                />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="card featured-card overflow-hidden border-0 position-relative accordion-items-shadow">
                                                    <img
                                                        src="./featured-courses/featured02.jpg"
                                                        className="featured-courses-img"
                                                        alt=""
                                                    />
                                                    <span className="badge badge-warning bg-warning position-absolute top-0 start-0">
                                                        Silver
                                                    </span>
                                                    <div className="card-body">
                                                        <span className="badge badge-light rounded-2 fw-400 fs-6">
                                                            Marketing and Sales
                                                        </span>
                                                        <h5>Strategies for Selling Success</h5>
                                                        <p className="d-flex align-items-center gap-2 fw-400 text-black mt-3 mb-4 fs-6">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={30}
                                                                height={30}
                                                                viewBox="0 0 30 30"
                                                                fill="none"
                                                            >
                                                                <circle cx={15} cy={15} r={15} fill="#D9D9D9" />
                                                            </svg>
                                                            Professor Sophia Lee
                                                        </p>
                                                        <div className="row justify-content-between align-items-center mt-4 mb-4 mb-md-1">
                                                            <p className="fw-500 text-black mb-4 mb-md-0 col-12 col-md-8 col-lg-6 col-xl-7">
                                                                Beginner Boost
                                                            </p>
                                                            <div className="col-12 col-md-4 col-lg-6 col-xl-5 d-flex justify-content-between align-items-center">
                                                                <ul className="rectangle d-flex align-items-center gap-2  flex-wrap mb-0 p-0">
                                                                    <li className="active" />
                                                                    <li className="active" />
                                                                    <li className="active" />
                                                                    <li />
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="row justify-content-between align-items-center mb-3">
                                                            <p className="fw-500 text-black col-12 col-md-8 col-lg-6 col-xl-7 mb-3 mb-md-0">
                                                                Starter Pack
                                                            </p>
                                                            <div className="col-12 col-md-4 col-lg-6 col-xl-5 d-flex align-items-center gap-2">
                                                                <img
                                                                    src="./featured-courses/piggy-bank.jpg"
                                                                    className="wh-25"
                                                                    alt=""
                                                                />
                                                                <p className="mb-0 text-black fw-600">Explore Free</p>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex align-items-center gap-4">
                                                            <span className="badge badge-light rounded-2 fs-5 fw-600 px-4">
                                                                1K
                                                            </span>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <img
                                                                    src="./featured-courses/calibration.jpg"
                                                                    className="wh-25"
                                                                    alt=""
                                                                />
                                                                <p className="mb-0 text-black fw-400 fs-7">
                                                                    Graduated with Glory
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex align-items-center gap-4 mt-3">
                                                            <span className="badge badge-light rounded-2 fs-5 fw-600">
                                                                213
                                                            </span>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <img
                                                                    src="/featured-courses/desktop.jpg"
                                                                    className="wh-25"
                                                                    alt=""
                                                                />
                                                                <p className="mb-0 text-black fw-400 fs-7">
                                                                    Signed Up for Success
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <button
                                                            type="button"
                                                            className="btn-secondary text-black fw-600 d-flex align-items-center gap-3 rounded-2 mt-4"
                                                        >
                                                            Unlock It
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={22}
                                                                height={14}
                                                                viewBox="0 0 22 14"
                                                                fill="none"
                                                            >
                                                                <path
                                                                    d="M1 6.99854H21L16 1"
                                                                    stroke="#000C2A"
                                                                    strokeWidth="1.5"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                                <path
                                                                    d="M21 6.99854L16 13"
                                                                    stroke="#000C2A"
                                                                    strokeWidth="1.5"
                                                                    strokeLinecap="round"
                                                                />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="card featured-card overflow-hidden border-0 position-relative accordion-items-shadow">
                                                    <img
                                                        src="./featured-courses/featured01.jpg"
                                                        className="featured-courses-img"
                                                        alt=""
                                                    />
                                                    <span className="badge badge-warning bg-warning position-absolute top-0 start-0">
                                                        Bronze
                                                    </span>
                                                    <div className="card-body">
                                                        <span className="badge badge-light rounded-2 fw-400 fs-6">
                                                            Marketing and Sales
                                                        </span>
                                                        <h5>Strategies for Selling Success</h5>
                                                        <p className="d-flex align-items-center gap-2 fw-400 text-black mt-3 mb-4 fs-6">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={30}
                                                                height={30}
                                                                viewBox="0 0 30 30"
                                                                fill="none"
                                                            >
                                                                <circle cx={15} cy={15} r={15} fill="#D9D9D9" />
                                                            </svg>
                                                            Professor Sophia Lee
                                                        </p>
                                                        <div className="row justify-content-between align-items-center mt-4 mb-4 mb-md-1">
                                                            <p className="fw-500 text-black mb-4 mb-md-0 col-12 col-md-8 col-lg-6 col-xl-7">
                                                                Beginner Boost
                                                            </p>
                                                            <div className="col-12 col-md-4 col-lg-6 col-xl-5 d-flex justify-content-between align-items-center">
                                                                <ul className="rectangle d-flex align-items-center gap-2  flex-wrap mb-0 p-0">
                                                                    <li className="active" />
                                                                    <li className="active" />
                                                                    <li className="active" />
                                                                    <li />
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="row justify-content-between align-items-center mb-3">
                                                            <p className="fw-500 text-black col-12 col-md-8 col-lg-6 col-xl-7 mb-3 mb-md-0">
                                                                Starter Pack
                                                            </p>
                                                            <div className="col-12 col-md-4 col-lg-6 col-xl-5 d-flex align-items-center gap-2">
                                                                <img
                                                                    src="./featured-courses/piggy-bank.jpg"
                                                                    className="wh-25"
                                                                    alt=""
                                                                />
                                                                <p className="mb-0 text-black fw-600">Explore Free</p>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex align-items-center gap-4">
                                                            <span className="badge badge-light rounded-2 fs-5 fw-600 px-4">
                                                                1K
                                                            </span>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <img
                                                                    src="./featured-courses/calibration.jpg"
                                                                    className="wh-25"
                                                                    alt=""
                                                                />
                                                                <p className="mb-0 text-black fw-400 fs-7">
                                                                    Graduated with Glory
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex align-items-center gap-4 mt-3">
                                                            <span className="badge badge-light rounded-2 fs-5 fw-600">
                                                                213
                                                            </span>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <img
                                                                    src="/featured-courses/desktop.jpg"
                                                                    className="wh-25"
                                                                    alt=""
                                                                />
                                                                <p className="mb-0 text-black fw-400 fs-7">
                                                                    Signed Up for Success
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <button
                                                            type="button"
                                                            className="btn-primary d-flex align-items-center gap-3 rounded-2 mt-4"
                                                        >
                                                            Enroll Today
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={22}
                                                                height={14}
                                                                viewBox="0 0 22 14"
                                                                fill="none"
                                                            >
                                                                <path
                                                                    d="M1 6.99854H21L16 1"
                                                                    stroke="white"
                                                                    strokeWidth="1.5"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                                <path
                                                                    d="M21 6.99854L16 13"
                                                                    stroke="white"
                                                                    strokeWidth="1.5"
                                                                    strokeLinecap="round"
                                                                />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="card featured-card overflow-hidden border-0 position-relative accordion-items-shadow">
                                                    <img
                                                        src="./featured-courses/featured02.jpg"
                                                        className="featured-courses-img"
                                                        alt=""
                                                    />
                                                    <span className="badge badge-warning bg-warning position-absolute top-0 start-0">
                                                        Silver
                                                    </span>
                                                    <div className="card-body">
                                                        <span className="badge badge-light rounded-2 fw-400 fs-6">
                                                            Marketing and Sales
                                                        </span>
                                                        <h5>Strategies for Selling Success</h5>
                                                        <p className="d-flex align-items-center gap-2 fw-400 text-black mt-3 mb-4 fs-6">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={30}
                                                                height={30}
                                                                viewBox="0 0 30 30"
                                                                fill="none"
                                                            >
                                                                <circle cx={15} cy={15} r={15} fill="#D9D9D9" />
                                                            </svg>
                                                            Professor Sophia Lee
                                                        </p>
                                                        <div className="row justify-content-between align-items-center mt-4 mb-4 mb-md-1">
                                                            <p className="fw-500 text-black mb-4 mb-md-0 col-12 col-md-8 col-lg-6 col-xl-7">
                                                                Beginner Boost
                                                            </p>
                                                            <div className="col-12 col-md-4 col-lg-6 col-xl-5 d-flex justify-content-between align-items-center">
                                                                <ul className="rectangle d-flex align-items-center gap-2  flex-wrap mb-0 p-0">
                                                                    <li className="active" />
                                                                    <li className="active" />
                                                                    <li className="active" />
                                                                    <li />
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="row justify-content-between align-items-center mb-3">
                                                            <p className="fw-500 text-black col-12 col-md-8 col-lg-6 col-xl-7 mb-3 mb-md-0">
                                                                Starter Pack
                                                            </p>
                                                            <div className="col-12 col-md-4 col-lg-6 col-xl-5 d-flex align-items-center gap-2">
                                                                <img
                                                                    src="./featured-courses/piggy-bank.jpg"
                                                                    className="wh-25"
                                                                    alt=""
                                                                />
                                                                <p className="mb-0 text-black fw-600">Explore Free</p>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex align-items-center gap-4">
                                                            <span className="badge badge-light rounded-2 fs-5 fw-600 px-4">
                                                                1K
                                                            </span>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <img
                                                                    src="./featured-courses/calibration.jpg"
                                                                    className="wh-25"
                                                                    alt=""
                                                                />
                                                                <p className="mb-0 text-black fw-400 fs-7">
                                                                    Graduated with Glory
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex align-items-center gap-4 mt-3">
                                                            <span className="badge badge-light rounded-2 fs-5 fw-600">
                                                                213
                                                            </span>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <img
                                                                    src="/featured-courses/desktop.jpg"
                                                                    className="wh-25"
                                                                    alt=""
                                                                />
                                                                <p className="mb-0 text-black fw-400 fs-7">
                                                                    Signed Up for Success
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <button
                                                            type="button"
                                                            className="btn-secondary text-black fw-600 d-flex align-items-center gap-3 rounded-2 mt-4"
                                                        >
                                                            Unlock It
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={22}
                                                                height={14}
                                                                viewBox="0 0 22 14"
                                                                fill="none"
                                                            >
                                                                <path
                                                                    d="M1 6.99854H21L16 1"
                                                                    stroke="#000C2A"
                                                                    strokeWidth="1.5"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                                <path
                                                                    d="M21 6.99854L16 13"
                                                                    stroke="#000C2A"
                                                                    strokeWidth="1.5"
                                                                    strokeLinecap="round"
                                                                />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            <Footer />
        </>
    )
}

export default CustomerCourses