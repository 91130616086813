import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";

class MarketingColumnChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            series: [
                {
                    name: 'Compliance',
                    group: 'Compliance',
                    data: [80, 85, 60, 80, 50, 50, 80]
                },
                {
                    group: 'Compliance',
                    data: [100, 100, 100, 100, 100, 100, 100]
                },
                {
                    name: 'Skills',
                    group: 'Skills',
                    data: [100, 62, 78, 100, 100, 100, 50]
                },
                {
                    group: 'Skills',
                    data: [0, 100, 100, 0, 0, 0, 100]
                },
                {
                    name: 'Fun',
                    group: 'Fun',
                    data: [60, 78, 20, 58, 75, 78, 30]
                },
                {
                    group: 'Fun',
                    data: [100, 100, 100, 100, 100, 100, 100]
                },
            ],
            options: {
                chart: {
                    type: 'bar',
                    height: 300,
                    stacked: true,
                    toolbar: {
                        show: false,
                    }
                },
                plotOptions: {
                    bar: {
                        columnWidth: '30%',
                        borderRadius: 5,
                        borderRadiusWhenStacked: 'all',
                    }
                },
                dataLabels: {
                    enabled: false
                },
                colors: ['#d2e23d', '#EDF2F7', '#b8ccff', '#EDF2F7', '#ff5a59', '#EDF2F7', '#d2e23d', '#EDF2F7', '#b8ccff', '#EDF2F7', '#ff5a59', '#EDF2F7'],
                grid: {
                    show: false,
                },
                xaxis: {
                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    },
                    labels: {
                        style: {
                            colors: '#718096',
                            fontSize: '12.96px',
                            fontWeight: 600,
                        },
                    },
                },
                yaxis: {
                    title: {
                        show: false,
                    },
                    labels: {
                        formatter: function (value) {
                            return `${value}K`;
                        },
                        style: {
                            colors: '#425466',
                            fontSize: '11.88px',
                            fontWeight: 600,
                        },
                    },
                    min: 0,
                    max: 100,
                    tickAmount: 4,
                },
                fill: {
                    opacity: 1
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'left',
                    fontSize: '14px',
                    fontWeight: 500,
                    fontFamily: 'Poppins, sans-serif',
                    labels: {
                        colors: '#425466',
                    },
                    markers: {
                        size: 6,
                        shape: 'round',
                    },
                },
            },
        };
    }

    render() {
        return (
            <div className="course-chart">
                <ReactApexChart options={this.state.options} series={this.state.series} type="bar" height={350} />
            </div>
        );
    }
}
export default MarketingColumnChart;