import React from 'react';

const Loader = ({  ismain }) => {


    return (
        <div className={`loader-css ${ismain}`}>
            <img src="../gif/unicorso.gif" alt="" width="100px" />
        </div>
    );
};

export default Loader;
