/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import AdminLayout from '../../components/Admin/AdminLayout'
import { getServerURL } from '../../helper/envConfig';
import api from '../../helper/api';
import CommonLoader from '../../components/Admin/CommonLoader';


function AdminGrades() {

    const serverURL = getServerURL();
    const [grades, setGrades] = useState([])
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [totalGrade, setTotalGrade] = useState(0)
    const [totalPages, setTotalPages] = useState(0)
    const [showLoader, setShowLoader] = useState(true);

    const getFeedbackList = async () => {
        try {
            const queryParams = new URLSearchParams({
                page,
                page_size: pageSize,
            }).toString();
            const response = await api.getWithToken(`${serverURL}admin-panel/feedback/courses-grades-list?${queryParams}`);
            setGrades(response.data.results.grades);
            setTotalGrade(response.data.total)
            setTotalPages(response.data.total_pages)
            setShowLoader(false)
        } catch (error) {
            setGrades([]);
            console.error(error);
        }
    };

    useEffect(() => {
        getFeedbackList()
    }, [page])

    const handleNextPage = () => {
        if (totalPages > page) {
            setPage(page + 1);
        }
    }

    const handlePrevPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    }



    return (
        <AdminLayout>
            {showLoader ? (
                <CommonLoader />
            ) : (
                <>
                    <h2 className="page-title">Grades </h2>

                    <div className='user-management-table mt-4 overflow-auto'>
                        <Table>
                            <thead>
                                <tr>
                                    <th>
                                        <p className='text-capitalize text-nowrap'>No</p>
                                    </th>
                                    <th>
                                        <p className='text-capitalize text-nowrap'>Course</p>
                                    </th>
                                    <th>
                                        <p className='text-capitalize text-nowrap'>Grade</p>
                                    </th>
                                    <th>
                                        <p className='text-capitalize text-nowrap'>Feedback Text (comes from ChatGPT)</p>
                                    </th>
                                    <th>
                                        <p className='text-capitalize text-nowrap'>Date</p>
                                    </th>
                                    <th>
                                        <div>
                                            <p className='text-capitalize'>Time</p>
                                        </div>
                                    </th>
                                    <th>
                                        <div>
                                            <p className='text-capitalize'>User</p>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {grades.length > 0 && grades.map((grade, i) => {
                                    return <tr key={i}>
                                        <td>
                                            <p className='text-light-400 fs-7 fw-500 mb-0'>{(page - 1) * pageSize + i + 1}</p>
                                        </td>
                                        <td className='px-4'>{grade.course_name}</td>
                                        <td className='px-4'>{grade.grade_status}</td>
                                        <td className='px-4'>{grade.chat_gpt_feedback}</td>
                                        <td className='px-4 text-nowrap'>{grade.date}</td>
                                        <td className='px-4 text-nowrap'>{grade.time}</td>
                                        <td className='px-4'>{grade.user_name}</td>
                                    </tr>
                                })}
                            </tbody>
                        </Table>
                    </div>
                    <div className='admin-table-pagination'>
                        <p>Showing {(page - 1) * pageSize + 1}-{Math.min(page * pageSize, totalGrade)} of {totalGrade}</p>
                        <div className='admin-table-pagination-arrows'>
                            <div className='pagination-arrows-box' onClick={handlePrevPage}>
                                <img src="./admin/pagination-left-arrow.png" alt="" />
                            </div>
                            <div className='pagination-arrows-box'>
                                <img src="./admin/pagination-right-arrow.png" alt="" onClick={handleNextPage} />
                            </div>
                        </div>
                    </div>
                </>
            )}

            
        </AdminLayout>
    )
}

export default AdminGrades
