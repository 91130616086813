import React, { useState, useEffect } from 'react';

const CustomPagination = ({ currentPage, totalPages, onPageChange }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const handleClick = (page) => {
    if (onPageChange) onPageChange(page);
  };

  // const renderPageNumbers = () => {
  //   const maxPagesToShow = 5; // Number of pages to show around the current page
  //   const pages = [];
  //   let startPage = 1;
  //   let endPage = totalPages;
  
  //   if (totalPages > maxPagesToShow) {
  //     const halfPagesToShow = Math.floor(maxPagesToShow / 2);
  //     if (currentPage <= halfPagesToShow + 1) {
  //       startPage = 1;
  //       endPage = maxPagesToShow;
  //     } else if (currentPage >= totalPages - halfPagesToShow) {
  //       endPage = totalPages;
  //       startPage = totalPages - maxPagesToShow + 1;
  //     } else {
  //       startPage = currentPage - halfPagesToShow;
  //       endPage = currentPage + halfPagesToShow;
  //     }
  //   }
  

  //   if (startPage > 1) {
  //     pages.push(
  //       <li key={1} className={`page-item ${currentPage === 1 ? 'active' : ''}`}>
  //         <a className="page-link" href="#" onClick={() => handleClick(1)}>
  //           1
  //         </a>
  //       </li>
  //     );
  //     if (startPage > 2) {
  //       pages.push(
  //         <li key="ellipsis_start" className="page-item disabled">
  //           <span className="page-link">...</span>
  //         </li>
  //       );
  //     }
  //   }
  
  //   for (let i = startPage; i <= endPage; i++) {
  //     pages.push(
  //       <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
  //         <a className="page-link" href="#" onClick={() => handleClick(i)}>
  //           {i}
  //         </a>
  //       </li>
  //     );
  //   }
  
  //   if (endPage < totalPages) {
  //     if (endPage < totalPages - 1) {
  //       pages.push(
  //         <li key="ellipsis_end" className="page-item disabled">
  //           <span className="page-link">...</span>
  //         </li>
  //       );
  //     }
  //     pages.push(
  //       <li key={totalPages} className={`page-item ${currentPage === totalPages ? 'active' : ''}`}>
  //         <a className="page-link" href="#" onClick={() => handleClick(totalPages)}>
  //           {totalPages}
  //         </a>
  //       </li>
  //     );
  //   }
  
  //   return pages;
  // };
  

  const renderPageNumbers = () => {
    const maxPagesToShow = isMobile ? 2 : 5; // 2 items for mobile, 5 for desktop
    const pages = [];
    let startPage = 1;
    let endPage = totalPages;
  
    if (totalPages > maxPagesToShow) {
      const halfPagesToShow = Math.floor(maxPagesToShow / 2);
      startPage = Math.max(currentPage - halfPagesToShow, 1);
      endPage = Math.min(startPage + maxPagesToShow - 1, totalPages);
  
      if (currentPage > totalPages - halfPagesToShow) {
        startPage = totalPages - maxPagesToShow + 1;
        endPage = totalPages;
      }
    }
  
    if (startPage > 1) {
      pages.push(
        <li key={1} className={`page-item ${currentPage === 1 ? 'active' : ''}`}>
          <a className="page-link" href="#" onClick={(e) => { e.preventDefault(); handleClick(1); }}>
            1
          </a>
        </li>
      );
  
      // Conditionally render the ellipsis based on the mobile flag
      if (!isMobile && startPage > 2) {
        pages.push(
          <li key="ellipsis_start" className="page-item disabled">
            <span className="page-link">...</span>
          </li>
        );
      }
    }
  
    // Add pages. On mobile, this would only add the current page
    for (let i = startPage; i <= endPage; i++) {
      if (!isMobile || i === currentPage) {
        pages.push(
          <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
            <a className="page-link" href="#" onClick={(e) => { e.preventDefault(); handleClick(i); }}>
              {i}
            </a>
          </li>
        );
      }
    }
  
    // Conditionally render the ending ellipsis based on the mobile flag
    if (!isMobile && endPage < totalPages - 1) {
      pages.push(
        <li key="ellipsis_end" className="page-item disabled">
          <span className="page-link">...</span>
        </li>
      );
    }
  
    if (endPage < totalPages) {
      pages.push(
        <li key={totalPages} className={`page-item ${currentPage === totalPages ? 'active' : ''}`}>
          <a className="page-link" href="#" onClick={(e) => { e.preventDefault(); handleClick(totalPages); }}>
            {totalPages}
          </a>
        </li>
      );
    }
  
    return pages;
  };
  

  return (
    <div className='d-flex justify-content-center'>
      <nav aria-label="Page navigation example pagination">
        <ul className="pagination table-pagination bg-transparent w-auto">
          {/* <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <a className="page-link border-0" href="#" aria-label="Previous" onClick={() => handleClick(currentPage - 1)}>
              <span aria-hidden="true">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.726 12L12.666 11.06L9.61268 8L12.666 4.94L11.726 4L7.72602 8L11.726 12Z" fill="#333333"/>
                <path d="M7.33344 12L8.27344 11.06L5.2201 8L8.27344 4.94L7.33344 4L3.33344 8L7.33344 12Z" fill="#333333"/>
              </svg>

              </span>
            </a>
          </li> */}
          <li className={`page-item left-arrow-pagination ${currentPage === 1 ? 'disabled' : ''}`}>
            <a className="page-link border-0" href="#" aria-label="Previous" onClick={() => handleClick(currentPage - 1)}>
              <span aria-hidden="true">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.06 12L11 11.06L7.94667 8L11 4.94L10.06 4L6.06 8L10.06 12Z" fill="black"/>
              </svg>
              </span>
            </a>
          </li>
          {renderPageNumbers()}
          <li className={`page-item right-arrow-pagination ${currentPage === totalPages ? 'disabled' : ''}`}>
            <a className="page-link border-0" href="#" aria-label="Next" onClick={() => handleClick(currentPage + 1)}>
              <span aria-hidden="true">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.94 4L6 4.94L9.05333 8L6 11.06L6.94 12L10.94 8L6.94 4Z" fill="black"/>
              </svg>
              </span>
            </a>
          </li>
          {/* <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
            <a className="page-link border-0" href="#" aria-label="Next" onClick={() => handleClick(currentPage + 1)}>
              <span aria-hidden="true">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.27398 4L3.33398 4.94L6.38732 8L3.33398 11.06L4.27398 12L8.27398 8L4.27398 4Z" fill="black"/>
                <path d="M8.66656 4L7.72656 4.94L10.7799 8L7.72656 11.06L8.66656 12L12.6666 8L8.66656 4Z" fill="black"/>
              </svg>
              </span>
            </a>
          </li> */}
        </ul>
      </nav>
    </div>
  );
};

export default CustomPagination;
