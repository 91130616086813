import { Link } from 'react-router-dom'
import React, { useState ,useEffect } from 'react';

function ContentSidbar() {
    const [activeLink, setActiveLink] = useState('/business-home');

    const handleLinkClick = (to) => {
        setActiveLink(to);
    };

    useEffect(() => {
        const maxLength = 12; // Set the character limit

        const scrollingTextElements = document.querySelectorAll('.scrolling-text');

        scrollingTextElements.forEach((element) => {
            const text = element.textContent;
            if (text.length > maxLength) {
                // Calculate animation duration based on text length (adjust speed if needed)
                const animationDuration = (text.length / maxLength) * 5 + 's';

                // Apply CSS styles for scrolling effect
                element.style.animation = `scroll ${animationDuration} linear infinite`;
                element.style.paddingLeft = '20px'; // Adjust this value according to your needs
            }
        });
    }, []);

    return (
        <>
            <div className="d-flex justify-content-start justify-content-xl-end responsive-sidebar">
                <ul className="admin-left-side-menu mt-4">
                    <li>
                        <Link
                            to="/conten-home"
                            className={`${activeLink === '/conten-home' ? 'active' : ''}`}
                            onClick={() => handleLinkClick('/conten-home')}
                        >
                            <img src="./admin-images/png/home.png" alt="" />
                            <div className="scrolling-text-container">
                                <div className="scrolling-text">Home</div>
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="/conten-upload"
                            className={`${activeLink === '/conten-upload' ? 'active' : ''}`}
                            onClick={() => handleLinkClick('/conten-upload')}
                        >
                            <img src="./admin-images/png/upload.png" alt="" />
                            <div className="scrolling-text-container">
                                <div className="scrolling-text">Upload</div>
                            </div>
                        </Link>
                    </li>
                    <p className="text-black fs-7 my-3">Learning</p>
                    <li>
                        <Link
                            to="/content-courses"
                            className={`${activeLink === '/content-courses' ? 'active' : ''}`}
                            onClick={() => handleLinkClick('/content-courses')}
                        >
                            <img
                                src="./admin-images/png/class-room.png"
                                alt=""
                            />
                            <div className="scrolling-text-container">
                                <div className="scrolling-text">Courses</div>
                            </div>
                        </Link>
                    </li>
                    {/* <li>
                        <Link
                            to="/content-quizzes"
                            className={`${activeLink === '/content-quizzes' ? 'active' : ''}`}
                            onClick={() => handleLinkClick('/content-quizzes')}
                        >
                            <img src="./admin-images/png/maybe.png" alt="" />
                            <div className="scrolling-text-container">
                                <div className="scrolling-text">Quizzes</div>
                            </div>
                        </Link>
                    </li> */}
                    <p className="text-black fs-7 my-3">Other</p>
                    <li>
                        <Link
                            to=""
                            className={`${activeLink === '' ? 'active' : ''}`}
                            onClick={() => handleLinkClick('')}
                        >
                            <img
                                src="./admin-images/png/google-groups.png"
                                alt=""
                            />
                            <div className="scrolling-text-container">
                                <div className="scrolling-text">Community</div>
                            </div>
                        </Link>
                    </li>
                    {/* <li>
                        <Link
                            to=""
                            className={`${activeLink === '' ? 'active' : ''}`}
                            onClick={() => handleLinkClick('')}
                        >                            <img
                                src="./admin-images/png/setting.png"
                                alt=""
                            />
                            <div className="scrolling-text-container">
                                <div className="scrolling-text">Settings</div>
                            </div>
                        </Link>
                    </li> */}
                </ul>
            </div>
        </>
    )
}

export default ContentSidbar