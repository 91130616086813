import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link } from 'react-router-dom';

function CommunitySidbarModal() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [activeTab, setActiveTab] = useState('/announcements');

    const handleTabClick = (to) => {
        setActiveTab(to);
    };
    
    return (
        <>
            <Button
                className="fs-3 d-md-none offcanvasleft courses-list-left-sidebar rounded-2 col-2 w-auto" variant="primary" onClick={handleShow}
            >
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" fill="#000" y="0px" width="25" height="25" viewBox="0 0 50 50"><path d="M 5 8 A 2.0002 2.0002 0 1 0 5 12 L 45 12 A 2.0002 2.0002 0 1 0 45 8 L 5 8 z M 5 23 A 2.0002 2.0002 0 1 0 5 27 L 45 27 A 2.0002 2.0002 0 1 0 45 23 L 5 23 z M 5 38 A 2.0002 2.0002 0 1 0 5 42 L 45 42 A 2.0002 2.0002 0 1 0 45 38 L 5 38 z"></path></svg>

            </Button>

            <Offcanvas show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton className='border-bottom'>
                    <Offcanvas.Title>
                        <h4 class="fs-20">Community</h4>
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className='px-0'>
                    <div className='featured-courses-main py-0'>
                        <ul className="community-letf-side shadow-right px-0">
                            <li className={`border-bottom-list ${activeTab === '/announcements' ? 'active' : ''}`}>
                                <Link
                                    to="/announcements"
                                    className="d-flex align-items-center gap-3"
                                    onClick={() => handleTabClick('/announcements')}
                                >
                                    <img
                                        src="./community/community-leftside-1.png"
                                        className="wh-25"
                                        alt=""
                                    />
                                    <p className="text-black fs-6 fw-400 mb-0">Announcements</p>
                                </Link>
                            </li>
                            <li className={`border-bottom-list ${activeTab === '/faq-questions' ? 'active' : ''}`}>
                                <Link
                                    to="/faq-questions"
                                    className="d-flex align-items-center gap-3"
                                    onClick={() => handleTabClick('/faq-questions')}
                                >
                                    <img
                                        src="./community/community-leftside-2.png"
                                        className="wh-25"
                                        alt=""
                                    />
                                    <p className="text-black fs-6 fw-400 mb-0">Frequently Asked Questions</p>
                                </Link>
                            </li>
                            <li className={`border-bottom-list ${activeTab === '/community-help' ? 'active' : ''}`}>
                                <Link
                                    to="/community-help"
                                    className="d-flex align-items-center gap-3"
                                    onClick={() => handleTabClick('/community-help')}
                                >
                                    <img
                                        src="./community/community-leftside-3.png"
                                        className="wh-25"
                                        alt=""
                                    />
                                    <p className="text-black fs-6 fw-400 mb-0">Help Center</p>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default CommunitySidbarModal