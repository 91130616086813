import React, { useState } from 'react';

const StarRating = ({ratingData }) => {
    // State to keep track of the selected rating
    // const [rating, setRating] = useState(ratingData);

    // Handler for star click
    const handleStarClick = (index) => {
        // setRating(index + 1); // Update rating based on the clicked star index
    };

    return (
        <div className='d-flex align-items-center gap-2'>
            {[...Array(5)].map((_, index) => (
                <img
                    key={index}
                    src={index < ratingData ? "./admin/star-dark.png" : "./admin/star-light.png"}
                    alt={`Star ${index + 1}`}
                    // onClick={() => handleStarClick(index)}
                    style={{ cursor: 'pointer' }} // Optional: to indicate that the stars are clickable
                    height={20}
                    width={20}
                />
            ))}
        </div>
    );
};

export default StarRating;
