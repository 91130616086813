import React from 'react'
import Header from '../layout/Header'
import Faq from '../components/Faq'
import Footer from '../layout/Footer'

const IndividualPricing = () => {
    return (
        <>
            <Header />
            <main>
                <section>
                    <div className="individual-pricing bg-black overflow-hidden d-flex justify-content-center align-items-center">
                        <div className="container">
                            <div className="row justify-content-between align-items-center">
                                <div className="col-12 col-md-6 col-lg-6 order-2 order-md-1">
                                    <p className="fw-500 fs-18 text-start mb-3 text-white">Pricing</p>
                                    <h2 className="display-4 text-start text-white fw-400 mb-3">
                                        Individual Pricing
                                    </h2>
                                    <p className="text-start text-white fs-6 fw-300 mb-4">
                                        We offer flexible and affordable pricing plans designed to cater
                                        to your unique learning needs. Choose the plan that suits you best
                                        and embark on a fulfilling learning journey with access to a vast
                                        array of courses, expert instructors, and engaging learning
                                        resources.
                                    </p>
                                    <button
                                        type="button"
                                        className="btn btn-warning text-black d-flex align-items-center gap-3"
                                    >
                                        Start Learning Today
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={22}
                                            height={14}
                                            viewBox="0 0 22 14"
                                            fill="none"
                                        >
                                            <path
                                                d="M1 6.99854H21L16 1"
                                                stroke="#000C2A"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M21 6.99854L16 13"
                                                stroke="#000C2A"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                            />
                                        </svg>
                                    </button>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 position-relative d-flex justify-content-end order-1 order-md-2">
                                    <div>
                                        <img
                                            src="./individual-pricing/individual-pricing-vector-1.png"
                                            className="individual-pricing-vector-1"
                                            alt=""
                                        />
                                        <img
                                            src="./individual-pricing/girl.png"
                                            className="home-girl-png position-relative z-1"
                                            alt=""
                                        />
                                        <img
                                            src="./individual-pricing/individual-pricing-vector-2.png"
                                            className="individual-pricing-vector-2"
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="pricing-main sec-pt-5 position-relative pb-0">
                        <div className="container">
                            <div className="section-title col-md-12 col-lg-7 m-auto">
                                <span className="d-block">Pricing</span>
                                <h2>Affordable Learning, Priceless Knowledge!</h2>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-12 col-xl-11">
                                    <div className="card border-0">
                                        <div className="card-body px-4 px-lg-5">
                                            <div className="table-responsive table-responsive-xxl pricing-table">
                                                <table className="table align-middle table-sm">
                                                    <thead>
                                                        <tr className="border-bottom-0 border-white">
                                                            <th />
                                                            <th>Stater Pack</th>
                                                            <th>Pro Mastery</th>
                                                            <th>Family Fusion</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="ps-4">
                                                                <p className="fw-600">Courses</p>
                                                            </td>
                                                            <td>
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width={41}
                                                                    height={40}
                                                                    viewBox="0 0 41 40"
                                                                    fill="none"
                                                                >
                                                                    <mask
                                                                        id="mask0_257_338"
                                                                        style={{ maskType: "alpha" }}
                                                                        maskUnits="userSpaceOnUse"
                                                                        x={0}
                                                                        y={0}
                                                                        width={41}
                                                                        height={40}
                                                                    >
                                                                        <rect
                                                                            x="0.166748"
                                                                            width={40}
                                                                            height={40}
                                                                            fill="#D9D9D9"
                                                                        />
                                                                    </mask>
                                                                    <g mask="url(#mask0_257_338)">
                                                                        <path
                                                                            d="M16.0835 30.0002L6.5835 20.5002L8.9585 18.1252L16.0835 25.2502L31.3752 9.9585L33.7502 12.3335L16.0835 30.0002Z"
                                                                            fill="#D2E23D"
                                                                        />
                                                                    </g>
                                                                </svg>
                                                            </td>
                                                            <td>
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width={41}
                                                                    height={40}
                                                                    viewBox="0 0 41 40"
                                                                    fill="none"
                                                                >
                                                                    <mask
                                                                        id="mask0_257_338"
                                                                        style={{ maskType: "alpha" }}
                                                                        maskUnits="userSpaceOnUse"
                                                                        x={0}
                                                                        y={0}
                                                                        width={41}
                                                                        height={40}
                                                                    >
                                                                        <rect
                                                                            x="0.166748"
                                                                            width={40}
                                                                            height={40}
                                                                            fill="#D9D9D9"
                                                                        />
                                                                    </mask>
                                                                    <g mask="url(#mask0_257_338)">
                                                                        <path
                                                                            d="M16.0835 30.0002L6.5835 20.5002L8.9585 18.1252L16.0835 25.2502L31.3752 9.9585L33.7502 12.3335L16.0835 30.0002Z"
                                                                            fill="#D2E23D"
                                                                        />
                                                                    </g>
                                                                </svg>
                                                            </td>
                                                            <td>
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width={41}
                                                                    height={40}
                                                                    viewBox="0 0 41 40"
                                                                    fill="none"
                                                                >
                                                                    <mask
                                                                        id="mask0_257_338"
                                                                        style={{ maskType: "alpha" }}
                                                                        maskUnits="userSpaceOnUse"
                                                                        x={0}
                                                                        y={0}
                                                                        width={41}
                                                                        height={40}
                                                                    >
                                                                        <rect
                                                                            x="0.166748"
                                                                            width={40}
                                                                            height={40}
                                                                            fill="#D9D9D9"
                                                                        />
                                                                    </mask>
                                                                    <g mask="url(#mask0_257_338)">
                                                                        <path
                                                                            d="M16.0835 30.0002L6.5835 20.5002L8.9585 18.1252L16.0835 25.2502L31.3752 9.9585L33.7502 12.3335L16.0835 30.0002Z"
                                                                            fill="#D2E23D"
                                                                        />
                                                                    </g>
                                                                </svg>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="ps-4">
                                                                <p className="fw-600">LinkedIn Achievement Badge</p>
                                                            </td>
                                                            <td>
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width={41}
                                                                    height={40}
                                                                    viewBox="0 0 41 40"
                                                                    fill="none"
                                                                >
                                                                    <mask
                                                                        id="mask0_257_338"
                                                                        style={{ maskType: "alpha" }}
                                                                        maskUnits="userSpaceOnUse"
                                                                        x={0}
                                                                        y={0}
                                                                        width={41}
                                                                        height={40}
                                                                    >
                                                                        <rect
                                                                            x="0.166748"
                                                                            width={40}
                                                                            height={40}
                                                                            fill="#D9D9D9"
                                                                        />
                                                                    </mask>
                                                                    <g mask="url(#mask0_257_338)">
                                                                        <path
                                                                            d="M16.0835 30.0002L6.5835 20.5002L8.9585 18.1252L16.0835 25.2502L31.3752 9.9585L33.7502 12.3335L16.0835 30.0002Z"
                                                                            fill="#D2E23D"
                                                                        />
                                                                    </g>
                                                                </svg>
                                                            </td>
                                                            <td>
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width={41}
                                                                    height={40}
                                                                    viewBox="0 0 41 40"
                                                                    fill="none"
                                                                >
                                                                    <mask
                                                                        id="mask0_257_338"
                                                                        style={{ maskType: "alpha" }}
                                                                        maskUnits="userSpaceOnUse"
                                                                        x={0}
                                                                        y={0}
                                                                        width={41}
                                                                        height={40}
                                                                    >
                                                                        <rect
                                                                            x="0.166748"
                                                                            width={40}
                                                                            height={40}
                                                                            fill="#D9D9D9"
                                                                        />
                                                                    </mask>
                                                                    <g mask="url(#mask0_257_338)">
                                                                        <path
                                                                            d="M16.0835 30.0002L6.5835 20.5002L8.9585 18.1252L16.0835 25.2502L31.3752 9.9585L33.7502 12.3335L16.0835 30.0002Z"
                                                                            fill="#D2E23D"
                                                                        />
                                                                    </g>
                                                                </svg>
                                                            </td>
                                                            <td>
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width={41}
                                                                    height={40}
                                                                    viewBox="0 0 41 40"
                                                                    fill="none"
                                                                >
                                                                    <mask
                                                                        id="mask0_257_338"
                                                                        style={{ maskType: "alpha" }}
                                                                        maskUnits="userSpaceOnUse"
                                                                        x={0}
                                                                        y={0}
                                                                        width={41}
                                                                        height={40}
                                                                    >
                                                                        <rect
                                                                            x="0.166748"
                                                                            width={40}
                                                                            height={40}
                                                                            fill="#D9D9D9"
                                                                        />
                                                                    </mask>
                                                                    <g mask="url(#mask0_257_338)">
                                                                        <path
                                                                            d="M16.0835 30.0002L6.5835 20.5002L8.9585 18.1252L16.0835 25.2502L31.3752 9.9585L33.7502 12.3335L16.0835 30.0002Z"
                                                                            fill="#D2E23D"
                                                                        />
                                                                    </g>
                                                                </svg>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="ps-4">
                                                                <p className="fw-600">Leaderboard and Analytics</p>
                                                            </td>
                                                            <td>
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width={41}
                                                                    height={40}
                                                                    viewBox="0 0 41 40"
                                                                    fill="none"
                                                                >
                                                                    <mask
                                                                        id="mask0_257_368"
                                                                        style={{ maskType: "alpha" }}
                                                                        maskUnits="userSpaceOnUse"
                                                                        x={0}
                                                                        y={0}
                                                                        width={41}
                                                                        height={40}
                                                                    >
                                                                        <rect
                                                                            x="0.166748"
                                                                            width={40}
                                                                            height={40}
                                                                            fill="#D9D9D9"
                                                                        />
                                                                    </mask>
                                                                    <g mask="url(#mask0_257_368)">
                                                                        <path
                                                                            d="M10.8333 31.6668L8.5 29.3335L17.8333 20.0002L8.5 10.6668L10.8333 8.3335L20.1667 17.6668L29.5 8.3335L31.8333 10.6668L22.5 20.0002L31.8333 29.3335L29.5 31.6668L20.1667 22.3335L10.8333 31.6668Z"
                                                                            fill="#FFBACC"
                                                                        />
                                                                    </g>
                                                                </svg>
                                                            </td>
                                                            <td>
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width={41}
                                                                    height={40}
                                                                    viewBox="0 0 41 40"
                                                                    fill="none"
                                                                >
                                                                    <mask
                                                                        id="mask0_257_338"
                                                                        style={{ maskType: "alpha" }}
                                                                        maskUnits="userSpaceOnUse"
                                                                        x={0}
                                                                        y={0}
                                                                        width={41}
                                                                        height={40}
                                                                    >
                                                                        <rect
                                                                            x="0.166748"
                                                                            width={40}
                                                                            height={40}
                                                                            fill="#D9D9D9"
                                                                        />
                                                                    </mask>
                                                                    <g mask="url(#mask0_257_338)">
                                                                        <path
                                                                            d="M16.0835 30.0002L6.5835 20.5002L8.9585 18.1252L16.0835 25.2502L31.3752 9.9585L33.7502 12.3335L16.0835 30.0002Z"
                                                                            fill="#D2E23D"
                                                                        />
                                                                    </g>
                                                                </svg>
                                                            </td>
                                                            <td>
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width={41}
                                                                    height={40}
                                                                    viewBox="0 0 41 40"
                                                                    fill="none"
                                                                >
                                                                    <mask
                                                                        id="mask0_257_338"
                                                                        style={{ maskType: "alpha" }}
                                                                        maskUnits="userSpaceOnUse"
                                                                        x={0}
                                                                        y={0}
                                                                        width={41}
                                                                        height={40}
                                                                    >
                                                                        <rect
                                                                            x="0.166748"
                                                                            width={40}
                                                                            height={40}
                                                                            fill="#D9D9D9"
                                                                        />
                                                                    </mask>
                                                                    <g mask="url(#mask0_257_338)">
                                                                        <path
                                                                            d="M16.0835 30.0002L6.5835 20.5002L8.9585 18.1252L16.0835 25.2502L31.3752 9.9585L33.7502 12.3335L16.0835 30.0002Z"
                                                                            fill="#D2E23D"
                                                                        />
                                                                    </g>
                                                                </svg>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="ps-4">
                                                                <p className="fw-600">
                                                                    Allow access to multiple users
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width={41}
                                                                    height={40}
                                                                    viewBox="0 0 41 40"
                                                                    fill="none"
                                                                >
                                                                    <mask
                                                                        id="mask0_257_368"
                                                                        style={{ maskType: "alpha" }}
                                                                        maskUnits="userSpaceOnUse"
                                                                        x={0}
                                                                        y={0}
                                                                        width={41}
                                                                        height={40}
                                                                    >
                                                                        <rect
                                                                            x="0.166748"
                                                                            width={40}
                                                                            height={40}
                                                                            fill="#D9D9D9"
                                                                        />
                                                                    </mask>
                                                                    <g mask="url(#mask0_257_368)">
                                                                        <path
                                                                            d="M10.8333 31.6668L8.5 29.3335L17.8333 20.0002L8.5 10.6668L10.8333 8.3335L20.1667 17.6668L29.5 8.3335L31.8333 10.6668L22.5 20.0002L31.8333 29.3335L29.5 31.6668L20.1667 22.3335L10.8333 31.6668Z"
                                                                            fill="#FFBACC"
                                                                        />
                                                                    </g>
                                                                </svg>
                                                            </td>
                                                            <td>
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width={41}
                                                                    height={40}
                                                                    viewBox="0 0 41 40"
                                                                    fill="none"
                                                                >
                                                                    <mask
                                                                        id="mask0_257_368"
                                                                        style={{ maskType: "alpha" }}
                                                                        maskUnits="userSpaceOnUse"
                                                                        x={0}
                                                                        y={0}
                                                                        width={41}
                                                                        height={40}
                                                                    >
                                                                        <rect
                                                                            x="0.166748"
                                                                            width={40}
                                                                            height={40}
                                                                            fill="#D9D9D9"
                                                                        />
                                                                    </mask>
                                                                    <g mask="url(#mask0_257_368)">
                                                                        <path
                                                                            d="M10.8333 31.6668L8.5 29.3335L17.8333 20.0002L8.5 10.6668L10.8333 8.3335L20.1667 17.6668L29.5 8.3335L31.8333 10.6668L22.5 20.0002L31.8333 29.3335L29.5 31.6668L20.1667 22.3335L10.8333 31.6668Z"
                                                                            fill="#FFBACC"
                                                                        />
                                                                    </g>
                                                                </svg>
                                                            </td>
                                                            <td>
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width={41}
                                                                    height={40}
                                                                    viewBox="0 0 41 40"
                                                                    fill="none"
                                                                >
                                                                    <mask
                                                                        id="mask0_257_338"
                                                                        style={{ maskType: "alpha" }}
                                                                        maskUnits="userSpaceOnUse"
                                                                        x={0}
                                                                        y={0}
                                                                        width={41}
                                                                        height={40}
                                                                    >
                                                                        <rect
                                                                            x="0.166748"
                                                                            width={40}
                                                                            height={40}
                                                                            fill="#D9D9D9"
                                                                        />
                                                                    </mask>
                                                                    <g mask="url(#mask0_257_338)">
                                                                        <path
                                                                            d="M16.0835 30.0002L6.5835 20.5002L8.9585 18.1252L16.0835 25.2502L31.3752 9.9585L33.7502 12.3335L16.0835 30.0002Z"
                                                                            fill="#D2E23D"
                                                                        />
                                                                    </g>
                                                                </svg>
                                                            </td>
                                                        </tr>
                                                        <tr className="border-bottom-0 border-white">
                                                            <td />
                                                            <td>
                                                                <button
                                                                    type="button"
                                                                    className="btn-primary rounded-2"
                                                                >
                                                                    Enroll for Free Today
                                                                </button>
                                                            </td>
                                                            <td className="d-flex justify-content-center">
                                                                <button
                                                                    type="button"
                                                                    className="btn-primary rounded-2 mx-3 mx-md-0"
                                                                >
                                                                    3 €‎ / Month
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <button
                                                                    type="button"
                                                                    className="btn-primary rounded-2"
                                                                >
                                                                    6 €‎ / Month
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="pricing-main sec-pt-5 position-relative">
                        <div className="container">
                            <div className="section-title col-md-12 col-lg-7 m-auto">
                                <span className="d-block">Comparison Table</span>
                                <h2>Find Your Perfect Fit!</h2>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-12 col-xl-11">
                                    <div className="card border-0">
                                        <div className="card-body px-4 px-lg-5">
                                            <div className="table-responsive table-responsive-xxl pricing-table">
                                                <table className="table align-middle table-sm">
                                                    <thead>
                                                        <tr className="border-bottom-0 border-white">
                                                            <th />
                                                            <th>Stater Pack</th>
                                                            <th>Pro Mastery</th>
                                                            <th>Family Fusion</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="ps-4">
                                                                <p className="fw-600">Access to Mini Course</p>
                                                                <p className="fw-400">
                                                                    Dive into bite-sized lessons designed to introduce
                                                                    you to exciting topics and kickstart your learning
                                                                    journey.
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width={41}
                                                                    height={40}
                                                                    viewBox="0 0 41 40"
                                                                    fill="none"
                                                                >
                                                                    <mask
                                                                        id="mask0_257_338"
                                                                        style={{ maskType: "alpha" }}
                                                                        maskUnits="userSpaceOnUse"
                                                                        x={0}
                                                                        y={0}
                                                                        width={41}
                                                                        height={40}
                                                                    >
                                                                        <rect
                                                                            x="0.166748"
                                                                            width={40}
                                                                            height={40}
                                                                            fill="#D9D9D9"
                                                                        />
                                                                    </mask>
                                                                    <g mask="url(#mask0_257_338)">
                                                                        <path
                                                                            d="M16.0835 30.0002L6.5835 20.5002L8.9585 18.1252L16.0835 25.2502L31.3752 9.9585L33.7502 12.3335L16.0835 30.0002Z"
                                                                            fill="#D2E23D"
                                                                        />
                                                                    </g>
                                                                </svg>
                                                            </td>
                                                            <td>
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width={41}
                                                                    height={40}
                                                                    viewBox="0 0 41 40"
                                                                    fill="none"
                                                                >
                                                                    <mask
                                                                        id="mask0_257_338"
                                                                        style={{ maskType: "alpha" }}
                                                                        maskUnits="userSpaceOnUse"
                                                                        x={0}
                                                                        y={0}
                                                                        width={41}
                                                                        height={40}
                                                                    >
                                                                        <rect
                                                                            x="0.166748"
                                                                            width={40}
                                                                            height={40}
                                                                            fill="#D9D9D9"
                                                                        />
                                                                    </mask>
                                                                    <g mask="url(#mask0_257_338)">
                                                                        <path
                                                                            d="M16.0835 30.0002L6.5835 20.5002L8.9585 18.1252L16.0835 25.2502L31.3752 9.9585L33.7502 12.3335L16.0835 30.0002Z"
                                                                            fill="#D2E23D"
                                                                        />
                                                                    </g>
                                                                </svg>
                                                            </td>
                                                            <td>
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width={41}
                                                                    height={40}
                                                                    viewBox="0 0 41 40"
                                                                    fill="none"
                                                                >
                                                                    <mask
                                                                        id="mask0_257_338"
                                                                        style={{ maskType: "alpha" }}
                                                                        maskUnits="userSpaceOnUse"
                                                                        x={0}
                                                                        y={0}
                                                                        width={41}
                                                                        height={40}
                                                                    >
                                                                        <rect
                                                                            x="0.166748"
                                                                            width={40}
                                                                            height={40}
                                                                            fill="#D9D9D9"
                                                                        />
                                                                    </mask>
                                                                    <g mask="url(#mask0_257_338)">
                                                                        <path
                                                                            d="M16.0835 30.0002L6.5835 20.5002L8.9585 18.1252L16.0835 25.2502L31.3752 9.9585L33.7502 12.3335L16.0835 30.0002Z"
                                                                            fill="#D2E23D"
                                                                        />
                                                                    </g>
                                                                </svg>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="ps-4">
                                                                <p className="fw-600">LinkedIn Achievement Badge</p>
                                                                <p className="fw-400">
                                                                    The right to publish course completion on LinkedIn
                                                                    for one year, highlighting your commitment to
                                                                    lifelong learning and compliance.
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width={41}
                                                                    height={40}
                                                                    viewBox="0 0 41 40"
                                                                    fill="none"
                                                                >
                                                                    <mask
                                                                        id="mask0_257_338"
                                                                        style={{ maskType: "alpha" }}
                                                                        maskUnits="userSpaceOnUse"
                                                                        x={0}
                                                                        y={0}
                                                                        width={41}
                                                                        height={40}
                                                                    >
                                                                        <rect
                                                                            x="0.166748"
                                                                            width={40}
                                                                            height={40}
                                                                            fill="#D9D9D9"
                                                                        />
                                                                    </mask>
                                                                    <g mask="url(#mask0_257_338)">
                                                                        <path
                                                                            d="M16.0835 30.0002L6.5835 20.5002L8.9585 18.1252L16.0835 25.2502L31.3752 9.9585L33.7502 12.3335L16.0835 30.0002Z"
                                                                            fill="#D2E23D"
                                                                        />
                                                                    </g>
                                                                </svg>
                                                            </td>
                                                            <td>
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width={41}
                                                                    height={40}
                                                                    viewBox="0 0 41 40"
                                                                    fill="none"
                                                                >
                                                                    <mask
                                                                        id="mask0_257_338"
                                                                        style={{ maskType: "alpha" }}
                                                                        maskUnits="userSpaceOnUse"
                                                                        x={0}
                                                                        y={0}
                                                                        width={41}
                                                                        height={40}
                                                                    >
                                                                        <rect
                                                                            x="0.166748"
                                                                            width={40}
                                                                            height={40}
                                                                            fill="#D9D9D9"
                                                                        />
                                                                    </mask>
                                                                    <g mask="url(#mask0_257_338)">
                                                                        <path
                                                                            d="M16.0835 30.0002L6.5835 20.5002L8.9585 18.1252L16.0835 25.2502L31.3752 9.9585L33.7502 12.3335L16.0835 30.0002Z"
                                                                            fill="#D2E23D"
                                                                        />
                                                                    </g>
                                                                </svg>
                                                            </td>
                                                            <td>
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width={41}
                                                                    height={40}
                                                                    viewBox="0 0 41 40"
                                                                    fill="none"
                                                                >
                                                                    <mask
                                                                        id="mask0_257_338"
                                                                        style={{ maskType: "alpha" }}
                                                                        maskUnits="userSpaceOnUse"
                                                                        x={0}
                                                                        y={0}
                                                                        width={41}
                                                                        height={40}
                                                                    >
                                                                        <rect
                                                                            x="0.166748"
                                                                            width={40}
                                                                            height={40}
                                                                            fill="#D9D9D9"
                                                                        />
                                                                    </mask>
                                                                    <g mask="url(#mask0_257_338)">
                                                                        <path
                                                                            d="M16.0835 30.0002L6.5835 20.5002L8.9585 18.1252L16.0835 25.2502L31.3752 9.9585L33.7502 12.3335L16.0835 30.0002Z"
                                                                            fill="#D2E23D"
                                                                        />
                                                                    </g>
                                                                </svg>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="ps-4">
                                                                <p className="fw-600">Leaderboard and Analytics</p>
                                                                <p className="fw-400">
                                                                    Stay inspired by monitoring your progress and
                                                                    comparing your performance with fellow learners.
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width={41}
                                                                    height={40}
                                                                    viewBox="0 0 41 40"
                                                                    fill="none"
                                                                >
                                                                    <mask
                                                                        id="mask0_257_368"
                                                                        style={{ maskType: "alpha" }}
                                                                        maskUnits="userSpaceOnUse"
                                                                        x={0}
                                                                        y={0}
                                                                        width={41}
                                                                        height={40}
                                                                    >
                                                                        <rect
                                                                            x="0.166748"
                                                                            width={40}
                                                                            height={40}
                                                                            fill="#D9D9D9"
                                                                        />
                                                                    </mask>
                                                                    <g mask="url(#mask0_257_368)">
                                                                        <path
                                                                            d="M10.8333 31.6668L8.5 29.3335L17.8333 20.0002L8.5 10.6668L10.8333 8.3335L20.1667 17.6668L29.5 8.3335L31.8333 10.6668L22.5 20.0002L31.8333 29.3335L29.5 31.6668L20.1667 22.3335L10.8333 31.6668Z"
                                                                            fill="#FFBACC"
                                                                        />
                                                                    </g>
                                                                </svg>
                                                            </td>
                                                            <td>
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width={41}
                                                                    height={40}
                                                                    viewBox="0 0 41 40"
                                                                    fill="none"
                                                                >
                                                                    <mask
                                                                        id="mask0_257_338"
                                                                        style={{ maskType: "alpha" }}
                                                                        maskUnits="userSpaceOnUse"
                                                                        x={0}
                                                                        y={0}
                                                                        width={41}
                                                                        height={40}
                                                                    >
                                                                        <rect
                                                                            x="0.166748"
                                                                            width={40}
                                                                            height={40}
                                                                            fill="#D9D9D9"
                                                                        />
                                                                    </mask>
                                                                    <g mask="url(#mask0_257_338)">
                                                                        <path
                                                                            d="M16.0835 30.0002L6.5835 20.5002L8.9585 18.1252L16.0835 25.2502L31.3752 9.9585L33.7502 12.3335L16.0835 30.0002Z"
                                                                            fill="#D2E23D"
                                                                        />
                                                                    </g>
                                                                </svg>
                                                            </td>
                                                            <td>
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width={41}
                                                                    height={40}
                                                                    viewBox="0 0 41 40"
                                                                    fill="none"
                                                                >
                                                                    <mask
                                                                        id="mask0_257_338"
                                                                        style={{ maskType: "alpha" }}
                                                                        maskUnits="userSpaceOnUse"
                                                                        x={0}
                                                                        y={0}
                                                                        width={41}
                                                                        height={40}
                                                                    >
                                                                        <rect
                                                                            x="0.166748"
                                                                            width={40}
                                                                            height={40}
                                                                            fill="#D9D9D9"
                                                                        />
                                                                    </mask>
                                                                    <g mask="url(#mask0_257_338)">
                                                                        <path
                                                                            d="M16.0835 30.0002L6.5835 20.5002L8.9585 18.1252L16.0835 25.2502L31.3752 9.9585L33.7502 12.3335L16.0835 30.0002Z"
                                                                            fill="#D2E23D"
                                                                        />
                                                                    </g>
                                                                </svg>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="ps-4">
                                                                <p className="fw-600">
                                                                    Allow access to multiple users
                                                                </p>
                                                                <p className="fw-400">
                                                                    All the benefits of paid access to every member of
                                                                    your family.&nbsp;
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width={41}
                                                                    height={40}
                                                                    viewBox="0 0 41 40"
                                                                    fill="none"
                                                                >
                                                                    <mask
                                                                        id="mask0_257_368"
                                                                        style={{ maskType: "alpha" }}
                                                                        maskUnits="userSpaceOnUse"
                                                                        x={0}
                                                                        y={0}
                                                                        width={41}
                                                                        height={40}
                                                                    >
                                                                        <rect
                                                                            x="0.166748"
                                                                            width={40}
                                                                            height={40}
                                                                            fill="#D9D9D9"
                                                                        />
                                                                    </mask>
                                                                    <g mask="url(#mask0_257_368)">
                                                                        <path
                                                                            d="M10.8333 31.6668L8.5 29.3335L17.8333 20.0002L8.5 10.6668L10.8333 8.3335L20.1667 17.6668L29.5 8.3335L31.8333 10.6668L22.5 20.0002L31.8333 29.3335L29.5 31.6668L20.1667 22.3335L10.8333 31.6668Z"
                                                                            fill="#FFBACC"
                                                                        />
                                                                    </g>
                                                                </svg>
                                                            </td>
                                                            <td>
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width={41}
                                                                    height={40}
                                                                    viewBox="0 0 41 40"
                                                                    fill="none"
                                                                >
                                                                    <mask
                                                                        id="mask0_257_338"
                                                                        style={{ maskType: "alpha" }}
                                                                        maskUnits="userSpaceOnUse"
                                                                        x={0}
                                                                        y={0}
                                                                        width={41}
                                                                        height={40}
                                                                    >
                                                                        <rect
                                                                            x="0.166748"
                                                                            width={40}
                                                                            height={40}
                                                                            fill="#D9D9D9"
                                                                        />
                                                                    </mask>
                                                                    <g mask="url(#mask0_257_338)">
                                                                        <path
                                                                            d="M16.0835 30.0002L6.5835 20.5002L8.9585 18.1252L16.0835 25.2502L31.3752 9.9585L33.7502 12.3335L16.0835 30.0002Z"
                                                                            fill="#D2E23D"
                                                                        />
                                                                    </g>
                                                                </svg>
                                                            </td>
                                                            <td>
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width={41}
                                                                    height={40}
                                                                    viewBox="0 0 41 40"
                                                                    fill="none"
                                                                >
                                                                    <mask
                                                                        id="mask0_257_338"
                                                                        style={{ maskType: "alpha" }}
                                                                        maskUnits="userSpaceOnUse"
                                                                        x={0}
                                                                        y={0}
                                                                        width={41}
                                                                        height={40}
                                                                    >
                                                                        <rect
                                                                            x="0.166748"
                                                                            width={40}
                                                                            height={40}
                                                                            fill="#D9D9D9"
                                                                        />
                                                                    </mask>
                                                                    <g mask="url(#mask0_257_338)">
                                                                        <path
                                                                            d="M16.0835 30.0002L6.5835 20.5002L8.9585 18.1252L16.0835 25.2502L31.3752 9.9585L33.7502 12.3335L16.0835 30.0002Z"
                                                                            fill="#D2E23D"
                                                                        />
                                                                    </g>
                                                                </svg>
                                                            </td>
                                                        </tr>
                                                        <tr className="border-bottom-0 border-white">
                                                            <td />
                                                            <td>
                                                                <button
                                                                    type="button"
                                                                    className="btn-primary rounded-2 mx-2"
                                                                >
                                                                    Enroll for Free Today
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <button
                                                                    type="button"
                                                                    className="btn-primary rounded-2 mx-2"
                                                                >
                                                                    3 €‎ / Month
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <button
                                                                    type="button"
                                                                    className="btn-primary rounded-2"
                                                                >
                                                                    6 €‎ / Month
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Faq />
            </main>
            <Footer/>

        </>
    )
}

export default IndividualPricing