import React, { useRef, useState, useEffect, useContext } from 'react'
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import CoursesHomeSec from '../components/CoursesHomeSec';
import SidbarCourses from '../components/SidbarCourses';
import CommunitySec from './../components/CommunitySec';
import CoursesListModal from '../components/CoursesListModal';
// import api from "../helper/api";
// import { getServerURL } from '../helper/envConfig';
import DynamicPagination from '../components/DynamicPagination';
import { Link } from 'react-router-dom';
import { CourseContext } from '../context/CourseContext';
import CourseCard from '../components/CourseCard';
import CourseNotFound from '../components/CourseNotFound';
import { CarouselItemSkeleton } from '../components/SkeletonLayouts';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { decryptFromStoreLoc, encryptAndStoreLoc } from '../helper/localStore';

function CoursesExplore() {
    
    const location = useLocation();
    const { slug } = useParams();

    const { loder, setIs_search, getSearchCourses, setSearch, searchex, setSearchex, listCourse, currentPage, setCurrentPage, pageSize, setPageSize, getCourses } = useContext(CourseContext);

    useEffect(() => {
        getCourses();
    }, [currentPage, pageSize, location.pathname]);


    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearchex(value);
        setSearch("")
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevents the default action of the enter key in a form
            getCourses(); // Call the function you want to execute on Enter key press
            setIs_search(true)
            window.scrollTo(0, 500);
        }
    };

    const handlePress = (e) => {
        e.preventDefault(); // Prevents the default action of the enter key in a form
        getCourses(); // Call the function you want to execute on Enter key press
        setIs_search(true)
        window.scrollTo(0, 500);
    };

    
    const storeSubCategorys = async (subcat) => {
        let existingSubcats = decryptFromStoreLoc("subcat");

        if (!Array.isArray(existingSubcats)) {
            existingSubcats = [];
        }

        if (!existingSubcats.includes(subcat)) {
            existingSubcats.push(subcat);

            if (existingSubcats.length > 4) {
                existingSubcats.shift();
            }

            encryptAndStoreLoc("subcat", existingSubcats);
        }
    };

    useEffect(() => {
        if (slug) {
            storeSubCategorys(slug.toLowerCase()); // Convert to lowercase if needed
        }
    }, [slug]);



    return (
        <>
            <Header />
            <CoursesHomeSec handleKeyPress={handleKeyPress} handleSearchChange={handleSearchChange}
                search={searchex}
                setSearch={setSearchex}
                handlePress={handlePress}
            />

            <section>
                <div className="featured-courses-main">
                    <div className="container">
                        <div className="row featured-courses-row">
                            {/* <div className="col-md-4 col-lg-3 col-xl-2 d-none d-md-block">
                                <SidbarCourses />
                            </div> */}
                            {/* <div className="col-md-8 col-lg-9 col-xl-10"> */}
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                {loder ?
                                    // Show skeleton cards
                                    <div className="row row-cols-md-1 row-cols-lg-2 row-cols-xl-3 g-4 pb-5">
                                        {Array.from({ length: 6 }, (_, index) => (
                                            <div key={index} className="col">
                                                <CarouselItemSkeleton />
                                            </div>
                                        ))}
                                    </div>
                                    :
                                    (listCourse?.results?.length > 0 ?
                                        // Show courses
                                        <>
                                            <div className="row justify-content-between align-items-center">
                                                <div className="col-12">
                                                    <div className="section-title col-md-12 col-lg-12 m-auto">
                                                        <span className="d-block text-start">Featured Courses</span>
                                                        <h2 className="text-start text-black display-6 sm-mb-3">
                                                            Our Top Picks for You
                                                        </h2>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-4 d-flex justify-content-md-end justify-content-between align-items-center mb-4 mb-md-0">
                                                    {/* <CoursesListModal /> */}
                                                </div>
                                            </div>

                                            <div className="row row-cols-md-1 row-cols-lg-2 row-cols-xl-3 g-4 pb-5">
                                                {listCourse?.results?.map((data, index) => (
                                                    <div key={index} className="col">
                                                        <CourseCard
                                                            tags_list={data?.tags_list[0].name}
                                                            name={data.name} type_name={data.type_name}
                                                            is_accessible={data.is_accessible}
                                                            course_id={data?.course_id} index={index}
                                                            image={data?.image} />
                                                    </div>
                                                ))}
                                            </div>

                                            {listCourse.total_pages >= 2 &&
                                                <DynamicPagination
                                                    currentPage={currentPage}
                                                    totalPages={listCourse.total_pages}
                                                    onPageChange={(newPage) => {
                                                        setCurrentPage(newPage);
                                                    }}
                                                />
                                            }

                                            <div className="d-flex justify-content-center pb-5 py-lg-4 py-xl-5">
                                                {/* Any additional content or footer here */}
                                            </div>
                                        </>
                                        :
                                        // Show "Course Not Found"
                                        <CourseNotFound />
                                    )
                                }
                                <CommunitySec />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default CoursesExplore