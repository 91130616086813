import React from 'react'
import CommunityHomeBanner from '../components/CommunityHomeBanner'
import CommunitySidbar from '../components/CommunitySidbar'
import Header from './../layout/Header';
import Footer from './../layout/Footer';
import { Link } from 'react-router-dom';
import CommunitySidbarModal from '../components/CommunitySidbarModal';

const CommunityAnnouncements = () => {
    return (
        <>
            <Header />
            <CommunityHomeBanner />
            <section>
                <div className="featured-courses-main">
                    <div className="container">
                        <div className="row featured-courses-row">
                            <div className="col-md-4 col-lg-3 col-xl-2 d-none d-md-block">
                                <CommunitySidbar />
                            </div>
                            <div className="col-md-8 col-lg-9 col-xl-10">
                                <div className="row justify-content-center mb-4">
                                    <div className="col-12 col-lg-11">
                                        <div className="section-title col-md-12 col-lg-12 m-auto">
                                            <div className="breadcrumb-main mt-4">
                                                <nav aria-label="breadcrumb">
                                                    <ol className="breadcrumb align-items-center mb-3">
                                                        <li className="breadcrumb-item">
                                                            <Link to="" className="fw-400 fs-7">
                                                                Community
                                                            </Link>
                                                        </li>
                                                        <li className="breadcrumb-item">
                                                            <Link to="" className="fw-400 fs-7">
                                                                Announcements
                                                            </Link>
                                                        </li>
                                                        <li
                                                            className="breadcrumb-item active fw-400 fs-7 mb-0"
                                                            aria-current="page"
                                                        >
                                                            New Features and Enhancements
                                                        </li>
                                                    </ol>
                                                </nav>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 d-flex justify-content-md-end justify-content-between align-items-center mb-4 mb-md-0">
                                        <CommunitySidbarModal />
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-12 col-lg-11 pb-5">
                                        <div className="card border-0 overflow-hidden order-3">
                                            <div className="card-body p-4 p-md-5">
                                                <h2 className="fw-400 text-black mb-5">
                                                    New Features and Enhancements!
                                                </h2>
                                                <p className="text-muted fw-400 mb-3 mb-md-5 fs-7">
                                                    Dear Learners,
                                                </p>
                                                {/* <p className="fw-400 text-muted mb-3 mb-md-4 fs-7">
                                                    We are thrilled to announce some amazing updates and
                                                    enhancements coming your way at [Your Platform Name].
                                                    We've been hard at work to make your learning experience
                                                    even more enjoyable and impactful, and we can't wait to
                                                    share what's in store for you!
                                                </p> */}
                                                <p className="fw-400 text-muted mb-3 mb-md-4 fs-7">
                                                    We are thrilled to announce some amazing updates and enhancements
                                                    coming up soon.
                                                </p>
                                                <ul className="community-text-box w-100 ps-3 fs-7">
                                                    <li className="mb-3 mb-md-5">
                                                        <b>Interactive Learning Experience:</b> Get ready for a more immersive and
                                                        engaging learning journey! Our platform now offers interactive lessons,
                                                        quizzes, and exercises that will bring your learning to life. There is more to
                                                        come.
                                                    </li>
                                                    <li className="mb-3 mb-md-5">
                                                        <b>Learner Groups</b> - Connect and Grow: We understand the value of community
                                                        and collaboration in the learning process. That's why we're introducing learner
                                                        groups soon.
                                                    </li>
                                                    <li className="mb-3 mb-md-5">
                                                        <b>Content -</b> Soon you will be able to create your own content.
                                                    </li>
                                                </ul>
                                                <p className="fw-400 text-muted mb-3 mb-md-5 fs-7">
                                                    Happy Learning!
                                                </p>
                                                {/* <div className="pt-4">
                                                    <div className="d-flex align-items-center gap-3">
                                                        <img
                                                            src="./community/fake-img.png"
                                                            className="wh-40"
                                                            alt=""
                                                        />
                                                        <p className="text-black fw-600 fs-6 mb-0">
                                                            Jhon Smith
                                                        </p>
                                                    </div>
                                                    <p className="fw-400 text-start text-black mt-4">
                                                        Let me know your suggestions in here
                                                    </p>
                                                    <div className="d-flex justify-content-between align-items-center mt-4">
                                                        <div className="d-flex align-items-center gap-3">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={24}
                                                                height={24}
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                            >
                                                                <mask
                                                                    id="mask0_250_6717"
                                                                    style={{ maskType: "alpha" }}
                                                                    maskUnits="userSpaceOnUse"
                                                                    x={0}
                                                                    y={0}
                                                                    width={24}
                                                                    height={24}
                                                                >
                                                                    <rect width={24} height={24} fill="#D9D9D9" />
                                                                </mask>
                                                                <g mask="url(#mask0_250_6717)">
                                                                    <path
                                                                        d="M18 21H7V8L14 1L15.25 2.25C15.3667 2.36667 15.4625 2.525 15.5375 2.725C15.6125 2.925 15.65 3.11667 15.65 3.3V3.65L14.55 8H21C21.5333 8 22 8.2 22.4 8.6C22.8 9 23 9.46667 23 10V12C23 12.1167 22.9833 12.2417 22.95 12.375C22.9167 12.5083 22.8833 12.6333 22.85 12.75L19.85 19.8C19.7 20.1333 19.45 20.4167 19.1 20.65C18.75 20.8833 18.3833 21 18 21ZM9 19H18L21 12V10H12L13.35 4.5L9 8.85V19ZM7 8V10H4V19H7V21H2V8H7Z"
                                                                        fill="#002FA7"
                                                                    />
                                                                </g>
                                                            </svg>
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={24}
                                                                height={24}
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                            >
                                                                <mask
                                                                    id="mask0_250_6720"
                                                                    style={{ maskType: "alpha" }}
                                                                    maskUnits="userSpaceOnUse"
                                                                    x={0}
                                                                    y={0}
                                                                    width={24}
                                                                    height={24}
                                                                >
                                                                    <rect width={24} height={24} fill="#D9D9D9" />
                                                                </mask>
                                                                <g mask="url(#mask0_250_6720)">
                                                                    <path
                                                                        d="M2 22V4C2 3.45 2.19583 2.97917 2.5875 2.5875C2.97917 2.19583 3.45 2 4 2H20C20.55 2 21.0208 2.19583 21.4125 2.5875C21.8042 2.97917 22 3.45 22 4V16C22 16.55 21.8042 17.0208 21.4125 17.4125C21.0208 17.8042 20.55 18 20 18H6L2 22ZM5.15 16H20V4H4V17.125L5.15 16Z"
                                                                        fill="#002FA7"
                                                                    />
                                                                </g>
                                                            </svg>
                                                        </div>
                                                        <p className="text-muted fw-400 fs-8 mb-0">
                                                            4 days ago
                                                        </p>
                                                    </div>
                                                    <div className="bg-secondary mt-3 p-4 py-4 rounded-2 mt-4">
                                                        <div className="d-flex justify-content-between align-items-center gap-4">
                                                            <input
                                                                type="text"
                                                                className="form-control text-black-placeholder border-0 bg-transparent text-black"
                                                                placeholder="Your Comment"
                                                            />
                                                            <div className="bg-black rounded-3 h-50 p-2">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width={24}
                                                                    height={24}
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                >
                                                                    <mask
                                                                        id="mask0_250_6728"
                                                                        style={{ maskType: "alpha" }}
                                                                        maskUnits="userSpaceOnUse"
                                                                        x={0}
                                                                        y={0}
                                                                        width={24}
                                                                        height={24}
                                                                    >
                                                                        <rect width={24} height={24} fill="#D9D9D9" />
                                                                    </mask>
                                                                    <g mask="url(#mask0_250_6728)">
                                                                        <path
                                                                            d="M3 20V4L22 12L3 20ZM5 17L16.85 12L5 7V10.5L11 12L5 13.5V17Z"
                                                                            fill="white"
                                                                        />
                                                                    </g>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-11 mt-2 mt-md-3 mt-lg-4">
                                        <div className="card border-0">
                                            <div className="card-body">
                                                <div className="px-0 px-md-4 py-4 py-md-5">
                                                    <span className="text-primary text-center fw-500 fs-6 d-block">
                                                        Help Center
                                                    </span>
                                                    <h2 className="text-center text-black fw-400 mt-3 col-12 col-xl-9 m-auto">
                                                        Still have a question? Let us know
                                                    </h2>
                                                    <p className="text-black fw-400 fs-18 fs-sm-6 mt-3 mt-md-4 text-center">
                                                        Don't miss out on the opportunity to dive into our
                                                        diverse array of courses, engage with expert
                                                        instructors, and connect with a vibrant community of
                                                        learners. Whether you're a curious beginner or a
                                                        seasoned learner, our platform offers something for
                                                        everyone.
                                                    </p>
                                                    <button
                                                        type="button"
                                                        className="btn-primary m-auto d-flex align-items-center gap-3 rounded-2 mt-4 fw-600"
                                                    >
                                                        Help Center
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width={22}
                                                            height={14}
                                                            viewBox="0 0 22 14"
                                                            fill="none"
                                                        >
                                                            <path
                                                                d="M1 6.99854H21L16 1"
                                                                stroke="white"
                                                                strokeWidth="1.5"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                            <path
                                                                d="M21 6.99854L16 13"
                                                                stroke="white"
                                                                strokeWidth="1.5"
                                                                strokeLinecap="round"
                                                            />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default CommunityAnnouncements
