import React, { useRef, useState, useEffect, useContext } from 'react'
import Header from '../layout/Header'
import StudentTestimonials from '../components/StudentTestimonials'
import KnowledgeHubCategories from '../components/KnowledgeHubCategories';
import Faq from '../components/Faq'
import CategoriesCard from '../components/CategoriesCard';
import ComparisonTable from '../components/ComparisonTable';
import Footer from '../layout/Footer';
import { CourseContext } from '../context/CourseContext';

const Teams = () => {

    const { getCategorysIndex, categoriesIndex, setCategoriesIndex, loder, token, setToken, user, setUser, is_search, setSearch, search, searchex, listCourse, currentPage, setCurrentPage, pageSize, setPageSize, getCourses, setLoder } = useContext(CourseContext);


    useEffect(() => {
        getCategorysIndex();
    }, []);

    return (
        <>
            <Header />
            <main>
                <section>
                    <div className="team-pricing overflow-hidden d-flex justify-content-center align-items-center">
                        <div className="container">
                            <div className="row justify-content-between align-items-center">
                                <div className="col-12 col-md-6 col-lg-6 order-2 order-md-1">
                                    <p className="fw-600 fs-18 text-start mb-3 text-white">Pricing</p>
                                    <h2 className="display-5 text-start text-white fw-400 mb-3">
                                        Team Pricing
                                    </h2>
                                    <p className="text-start text-white fs-6 fw-300 mb-4">
                                        We offer flexible and affordable pricing plans designed to cater
                                        to your unique learning needs. Choose the plan that suits you best
                                        and embark on a fulfilling learning journey with access to a vast
                                        array of courses, expert instructors, and engaging learning
                                        resources.
                                    </p>
                                    <button
                                        type="button"
                                        className="btn btn-warning text-black d-flex align-items-center gap-3 fw-600 fs-18"
                                    >
                                        Start this unicors
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={22}
                                            height={14}
                                            viewBox="0 0 22 14"
                                            fill="none"
                                        >
                                            <path
                                                d="M1 6.99854H21L16 1"
                                                stroke="#000C2A"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M21 6.99854L16 13"
                                                stroke="#000C2A"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                            />
                                        </svg>
                                    </button>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 position-relative d-flex justify-content-end order-1 order-md-2">
                                    <div>
                                        <img
                                            src="./individual-pricing/team-pricing-vector-3.png"
                                            className="team-pricing-vector-3"
                                            alt=""
                                        />
                                        <img
                                            src="./individual-pricing/boy-and-girl.png"
                                            className="boy-and-girl position-relative z-1"
                                            alt=""
                                        />
                                        <img
                                            src="./individual-pricing/team-pricing-vector-4.png"
                                            className="team-pricing-vector-4"
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="learn-pricing-sec">
                        <div className="container">
                            <div className="row justify-content-between align-items-center gx-3">
                                <div className="col-12 col-md-6 col-lg-6 position-relative order-1 order-md-1">
                                    <div>
                                        <img
                                            src="./individual-pricing/online-zoom-meeting.png"
                                            className="boy-and-girl position-relative z-1 pe-0 pe-md-5"
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 order-2 order-md-2">
                                    <div className="px-lg-4">
                                        <p className="fw-500 fs-18 text-start mb-3">Pricing</p>
                                        <h2 className="display-5 text-start fw-400 mb-3 text-black">
                                            More engaging way to learn
                                        </h2>
                                        <p className="text-start fs-6 fw-400 mb-4 text-black">
                                            Ready to revolutionize your team's learning journey? Our team
                                            pricing plans go beyond just courses; they empower your team
                                            with an engaging and transformational learning experience! Watch
                                            your team thrive as they unlock new skills, collaborate on
                                            exciting projects, and grow together with the support of our
                                            innovative platform.
                                        </p>
                                        <button
                                            type="button"
                                            className="btn btn-warning text-black d-flex align-items-center gap-3 fw-600 fs-18"
                                        >
                                            Start this unicors
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width={22}
                                                height={14}
                                                viewBox="0 0 22 14"
                                                fill="none"
                                            >
                                                <path
                                                    d="M1 6.99854H21L16 1"
                                                    stroke="#000C2A"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M21 6.99854L16 13"
                                                    stroke="#000C2A"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className='cate-teams'>
                    <KnowledgeHubCategories  categories={categoriesIndex} />
                </div>
                <CategoriesCard />
                <StudentTestimonials />
                <ComparisonTable />
                <Faq />
            </main>
            <Footer />
        </>
    )
}

export default Teams
